export const GET_WIN_LOSS_REPORT = "GET_WIN_LOSS_REPORT";
export const GET_WIN_LOSS_REPORT_SUCCESS = "GET_WIN_LOSS_REPORT_SUCCESS";
export const GET_WIN_LOSS_REPORT_FAILED = "GET_WIN_LOSS_REPORT_FAILED";

export const GET_WIN_LOSS_REPORT_BY_PRODUCT = "GET_WIN_LOSS_REPORT_BY_PRODUCT";
export const GET_WIN_LOSS_REPORT_BY_PRODUCT_SUCCESS =
    "GET_WIN_LOSS_REPORT_BY_PRODUCT_SUCCESS";
export const GET_WIN_LOSS_REPORT_BY_PRODUCT_FAILED =
    "GET_WIN_LOSS_REPORT_BY_PRODUCT_FAILED";

export const GET_GENERATED_REPORTS = "GET_GENERATED_REPORTS";
export const GET_GENERATED_REPORTS_SUCCESS = "GET_GENERATED_REPORTS_SUCCESS";
export const GET_GENERATED_REPORTS_FAILED = "GET_GENERATED_REPORTS_FAILED";

export const GENERATE_BATCH_PAYOUT_REPORT = "GENERATE_BATCH_PAYOUT_REPORT";
export const GENERATE_BATCH_PAYOUT_REPORT_SUCCESS =
    "GENERATE_BATCH_PAYOUT_REPORT_SUCCESS";
export const GENERATE_BATCH_PAYOUT_REPORT_FAILED =
    "GENERATE_BATCH_PAYOUT_REPORT_FAILED";

export const GET_LOGIN_LOGS = "GET_LOGIN_LOGS";
export const GET_LOGIN_LOGS_SUCCESS = "GET_LOGIN_LOGS_SUCCESS";
export const GET_LOGIN_LOGS_FAILED = "GET_LOGIN_LOGS_FAILED";

export const GET_MULTIPLE_IP_DATA = "GET_MULTIPLE_IP_DATA";
export const GET_MULTIPLE_IP_DATA_SUCCESS = "GET_MULTIPLE_IP_DATA_SUCCESS";
export const GET_MULTIPLE_IP_DATA_FAILED = "GET_MULTIPLE_IP_DATA_FAILED";
