import { InputActionChecker, InputTextGroup } from "@/components/atoms";
import { confirmation } from "@/components/constants/common";
import { usernameValidation } from "@/constants";
import { updateUserUsername } from "@/store/actions";
import { useFormik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import { FormGroup } from "reactstrap";
import { ErrorAlert } from "@/components/atoms";

const UsernameUpdateInput = ({ profile, errors }) => {
    const dispatch = useDispatch();

    const showConfirmation = () => {
        confirmation({
            title: "Confirmation Message",
            text: "Are you sure you want to save?",
            icon: "warning",
            confirmButtonText: "Save",
            action: () => validation.handleSubmit(),
        });
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            username: profile.name,
        },
        validationSchema: usernameValidation,
        onSubmit: (values) => {
            dispatch(updateUserUsername(values, validation));
        },
    });

    return (
        <>
            <ErrorAlert errors={errors} validation={validation} />
            <InputActionChecker
                label="Username"
                value={profile.name}
                isEdit={true}>
                <FormGroup className="mb-3">
                    <InputTextGroup
                        label="Username"
                        name="username"
                        buttonIcon="ri-pencil-line"
                        buttonAction={showConfirmation}
                        validation={validation}
                    />
                </FormGroup>
            </InputActionChecker>
        </>
    );
};

export default UsernameUpdateInput;
