import { getPath, Routes } from "@/constants";

export const getUserProfileLink = (user, userType = null) => {
    let path = "";
    let userCollection = userType ? userType : user?.role.slug;
    let userAccountNo = userType ? user : user?.user_profile?.account_no;

    switch (userCollection) {
        case "operator":
            path = getPath(Routes.OPERATOR_PROFILE).replace(
                ":accountNo",
                userAccountNo
            );
            break;
        case "master-agent":
            path = getPath(Routes.SUPER_AGENT_PROFILE).replace(
                ":accountNo",
                userAccountNo
            );
            break;
        case "agent":
            path = getPath(Routes.AGENT_PROFILE).replace(
                ":accountNo",
                userAccountNo
            );
            break;
        case "player":
            path = getPath(Routes.PLAYER_PROFILE).replace(
                ":accountNo",
                userAccountNo
            );
            break;
        case "admin":
            path = "/";
            break;
        default:
            path = "/";
            break;
    }
    return path;
};

export const getAgentReferralLink = (referralCode) => {
    return (
        process.env.REACT_APP_GAMELOBBY_URL + "/?referralCode=" + referralCode
    );
};

export const getResetPasswordLink = () => {
    return (
        process.env.REACT_APP_BACKOFFICE_URL +
        getPath(Routes.RESET_PASSWORD).replace(":token", "")
    );
};

export const getPlayerResetPasswordLink = () => {
    return process.env.REACT_APP_GAMELOBBY_URL + "/password-reset/";
};

export const gamelobbyLinks = [
    {
        name: "Metabets.vip",
        link: "https://metabets.vip",
    },
    {
        name: "Metabets.fun",
        link: "https://metabets.fun",
    },
];
