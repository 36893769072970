import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { uploadTeamPhoto } from "@/store/actions";
import { FileInputForm } from "@/components/molecules";

const AddHotMatchSettingsTeamPhoto = ({
  isOpen,
  setIsOpen,
  data,
  initialize,
}) => {
  const isMounted = useRef(false);

  const { isUpdateFeaturedGameSuccess, isLoadingUploadTeamPhoto, errors } =
    useSelector((state) => state.hotMatchesSetting);

  const dispatch = useDispatch();
  useEffect(() => {
    if (isMounted.current) {
      if (isUpdateFeaturedGameSuccess) {
        // initialize();
        setIsOpen(false);
      }
    }
    isMounted.current = true;
  }, [isUpdateFeaturedGameSuccess, setIsOpen]);

  const onUpdate = (value) => {
    dispatch(uploadTeamPhoto(data?.key_token, value, initialize));
  };
  return (
    <Modal
      id="updateFeaturedModal"
      isOpen={isOpen}
      toggle={() => setIsOpen(!isOpen)}
    >
      <ModalHeader>
        <div className="modal-title">{data?.name}</div>
      </ModalHeader>
      <ModalBody>
        <FileInputForm
          isLoading={isLoadingUploadTeamPhoto}
          field="team_photo"
          defaultImage={data?.team_photo}
          label="Banner"
          isCreate={!data?.team_photo}
          onSubmit={(values) => onUpdate(values)}
        />
      </ModalBody>
    </Modal>
  );
};

export default AddHotMatchSettingsTeamPhoto;
