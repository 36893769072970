import { applyMiddleware, compose, createStore } from "redux";
import { createLogger } from "redux-logger";
import createSagaMiddleware from "redux-saga";
import persistedReducer from "./reducers";
import { watchSagas } from "./sagas";

const middleware = [];
const saga = createSagaMiddleware();

middleware.push(saga);

// if (process.env.REACT_APP_ENV !== "production") {
//     middleware.push(createLogger());
// }

const composeEnhancers =
    process.env.REACT_APP_ENV === "local" &&
    typeof window === "object" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
        : compose;
const enhancer = composeEnhancers(applyMiddleware(...middleware));

const store = createStore(persistedReducer, enhancer);

saga.run(watchSagas);

export default store;
