import { IdFrontPlaceholder } from "@/resources/images";
import { updateFullyVerificationStatus } from "@/store/actions";
import classnames from "classnames";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    Card,
    CardBody,
    Col,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
} from "reactstrap";
import Swal from "sweetalert2";
import { roleBaseSettings } from "@/hooks";

const FullyVerificationModal = ({
    userProfileDocuments,
    isLoading,
    isOpen,
    setIsOpen,
    profile,
}) => {
    const dispatch = useDispatch();
    const { user, errors } = useSelector((state) => state.user);

    const isAdmin =
        user?.role.slug === "super-admin" || user?.role.slug === "admin"
            ? true
            : false;

    const canUpdateUserStatus = !isAdmin
        ? roleBaseSettings("to_verify_player", "allow")
        : isAdmin;

    const [passedSteps, setPassedSteps] = useState([1]);
    const [activeStep, setActiveStep] = useState(1);
    function toggleStep(tab) {
        if (activeStep !== tab) {
            var modifiedSteps = [...passedSteps, tab];

            if (tab >= 4 && tab <= 8) {
                setActiveStep(tab);
                setPassedSteps(modifiedSteps);
            }
        }
    }

    const confirmHandler = (type) => {
        Swal.fire({
            input: type === "reject" ? "textarea" : "",
            title: `${
                type === "verify"
                    ? "Approve?"
                    : "Are you sure you want to reject?"
            }`,
            inputPlaceholder: "Reject reason...",
            showCancelButton: true,
            confirmButtonText: "Confirm",
            reverseButtons: true,
            preConfirm: (value) => {
                if (!value && type === "reject") {
                    Swal.showValidationMessage(
                        '<i class="fa fa-info-circle"></i> Reject reason is required'
                    );
                }
            },
        }).then((result) => {
            if (result.isConfirmed) {
                let payload = {};
                if (type === "reject") {
                    payload = {
                        reject_reason: result.value,
                    };
                } else {
                    payload = {
                        remarks: "Approved.",
                    };
                }
                dispatch(
                    updateFullyVerificationStatus(
                        payload,
                        type,
                        profile.userProfilekeyToken
                    )
                );
            }
        });
    };

    const StepOneData = () => {
        const filteredDocuments = userProfileDocuments.filter((item) =>
            ["source_of_funds", "proof_of_residency"].includes(item.file_key)
        );
        return (
            <>
                <Row className="mb-3 gap-3">
                    {filteredDocuments.map((item, index) => (
                        <Fragment key={index}>
                            <Col md="12">
                                <Label>{item?.document_type?.name}</Label>
                            </Col>
                            <Col md="12">
                                {item?.extension !== "pdf" && item?.file ? (
                                    <img
                                        src={item.file}
                                        className="img-fluid img-thumbnail w-full"
                                        alt={item?.document_type?.name}
                                    />
                                ) : (
                                    <a
                                        className="fw-bold text-primary text-decoration-underline"
                                        href={item?.file}
                                        target="_blank"
                                        rel="noreferrer"
                                        download={item?.file_name}>
                                        <div>
                                            <span className="ri-download-2-line" />{" "}
                                            {item?.file_name}
                                        </div>
                                    </a>
                                )}
                            </Col>
                        </Fragment>
                    ))}
                </Row>
                {canUpdateUserStatus && profile?.verificationStep === 7 && (
                    <Row>
                        <Col md="12" className="text-end">
                            <Button
                                color="danger"
                                className="me-2"
                                onClick={() => confirmHandler("reject")}
                                disabled={isLoading}>
                                Reject
                            </Button>
                            <Button
                                color="success"
                                onClick={() => confirmHandler("verify")}
                                disabled={isLoading}>
                                Approve
                            </Button>
                        </Col>
                    </Row>
                )}
            </>
        );
    };

    const StepTwoData = () => {
        return (
            <div className="text-center">
                <div className="mt-4">
                    <h4 className="mb-3">
                        {profile.verificationStep === 9 && (
                            <div className="d-flex flex-column align-items-center">
                                <div className="avatar-md mb-3">
                                    <span className="avatar-title bg-soft-success rounded-circle fs-1">
                                        <i className="ri-checkbox-circle-line text-success"></i>
                                    </span>
                                </div>
                                <span>This Account is Fully Verified.</span>
                            </div>
                        )}
                        {profile.verificationStep === 8 && (
                            <div className="d-flex flex-column align-items-center">
                                <div className="avatar-md mb-3">
                                    <span className="avatar-title bg-soft-danger rounded-circle fs-1">
                                        <i className="ri-close-circle-line text-danger"></i>
                                    </span>
                                </div>
                                <span>This Account is Rejected.</span>
                            </div>
                        )}
                    </h4>
                    <p className="text-muted mb-4">
                        {profile.verificationStatus === "rejected" && (
                            <span>Reason: {profile.rejectReason}</span>
                        )}
                    </p>
                </div>
            </div>
        );
    };

    const stepsData = [
        {
            step: 7,
            verificationStatus: "for_fully_verification",
            title: "Proof of Income",
            icon: "la la-file",
            component: StepOneData,
        },
        {
            step: 8,
            verificationStatus: "fully_verified",
            title: "Proof of Income Verification",
            icon: "la la-file",
            component: StepTwoData,
        },
    ];

    useEffect(() => {
        setActiveStep(
            profile?.verificationStep >= 8 ? 8 : profile?.verificationStep
        );
    }, [profile]);

    return (
        <Modal
            id="playerVerificationModal"
            isOpen={isOpen}
            toggle={() => setIsOpen(!isOpen)}
            size="lg">
            <ModalHeader>
                <div className="modal-title">Proof of Income Verification</div>
            </ModalHeader>
            <ModalBody>
                <Card>
                    <CardBody className="checkout-tab">
                        <div className="step-arrow-nav mt-n3 mx-n3 mb-3">
                            <Nav
                                className="nav-pills nav-justified custom-nav"
                                role="tablist">
                                {stepsData.map((item, key) => (
                                    <NavItem role="presentation" key={key}>
                                        <NavLink
                                            href="#"
                                            className={classnames(
                                                {
                                                    active:
                                                        activeStep ===
                                                        item.step,
                                                    done:
                                                        activeStep <= 8 &&
                                                        activeStep >= 4,
                                                },
                                                "p-3"
                                            )}
                                            onClick={() => {
                                                item.step <=
                                                profile?.verificationStep
                                                    ? toggleStep(item.step)
                                                    : console.log(
                                                          "Step is not complete yet."
                                                      );
                                            }}
                                            style={{
                                                cursor:
                                                    item.step <=
                                                    profile?.verificationStep
                                                        ? "pointer"
                                                        : "not-allowed",
                                            }}>
                                            <i
                                                className={`${
                                                    item.icon
                                                } fs-16 p-2 ${
                                                    profile?.verificationStep >=
                                                    item.step
                                                        ? "bg-soft-success text-success"
                                                        : "bg-soft-primary text-primary"
                                                } rounded-circle align-middle me-2`}></i>
                                            <span
                                                className={
                                                    profile?.verificationStep >=
                                                    item.step
                                                        ? "text-success"
                                                        : "text-primary"
                                                }>
                                                {item.title}
                                            </span>
                                            {profile?.verificationStatus !==
                                                "fully_rejected" && (
                                                <i
                                                    className={`${
                                                        profile?.verificationStep >=
                                                        item.step
                                                            ? "ri-checkbox-circle-line text-success"
                                                            : "ri-loader-2-line text-primary"
                                                    } fs-16 p-2 align-middle me-2`}></i>
                                            )}
                                            {profile?.verificationStatus ===
                                                "fully_rejected" && (
                                                <i
                                                    className={`${
                                                        profile?.verificationStep >=
                                                        item.step
                                                            ? "ri-close-circle-line text-danger"
                                                            : "ri-loader-2-line text-primary"
                                                    } fs-16 p-2 align-middle me-2`}></i>
                                            )}
                                        </NavLink>
                                    </NavItem>
                                ))}
                            </Nav>
                        </div>
                        <TabContent activeTab={activeStep}>
                            {stepsData.map((value, key) => (
                                <TabPane
                                    tabId={value.step}
                                    id="pills-bill-info"
                                    key={key}>
                                    <div>{value.component()}</div>
                                </TabPane>
                            ))}
                        </TabContent>
                    </CardBody>
                </Card>
                <Button
                    color="light"
                    className="float-end"
                    onClick={() => setIsOpen(false)}>
                    Close
                </Button>
            </ModalBody>
        </Modal>
    );
};

export default FullyVerificationModal;
