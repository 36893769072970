import React from "react";
import { Label, Input, FormGroup } from "reactstrap";

const InputActionChecker = ({ isEdit, label, value, children }) => {
    if (!isEdit) {
        return (
            <>
                <FormGroup className="mb-3">
                    <Label>{label}</Label>
                    <Input
                        type="text"
                        className="form-control"
                        value={value ? value : ""}
                        disabled
                    />
                </FormGroup>
            </>
        );
    }
    return children;
};

export default InputActionChecker;
