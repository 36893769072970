import { Select, StatusBadge, Table, Title } from "@/components/atoms";
import BreadCrumb from "@/components/common/BreadCrumb";
import {
    Routes,
    affiliateRequestStatus,
    getPath,
    getPathTitle,
} from "@/constants";
import { userRole } from "@/hooks";
import { getAgentList } from "@/store/actions";
import { defaultDateTimeFormat } from "@/utils";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { AffiliateRequestViewModal } from "@/components/molecules";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row,
} from "reactstrap";

const AffiliateRequest = () => {
    const dispatch = useDispatch();
    const { list, isLoading } = useSelector((state) => state.agent);

    const [isOpen, setIsOpen] = useState(false);
    const [details, setDetails] = useState({});

    const [search, setSearch] = useState("");
    const [sortBy, setSortBy] = useState({
        field: "",
        type: "asc",
    });

    const userType = "affiliate-request";

    const [data, setData] = useState({
        user_type: userType,
        per_page: 10,
        page: 1,
        search: "",
        sort_by:
            sortBy.field && sortBy.type ? `${sortBy.field} ${sortBy.type}` : "",
        "filter_by[affiliate_requests.status]": "",
        "date_range[affiliate_requests.created_at][from]": "",
        "date_range[affiliate_requests.created_at][to]": "",
    });

    const tableTheadData = [
        {
            label: "Request No.",
            field: "request_no",
            sortable: true,
        },
        {
            label: "First Name",
            field: "first_name",
            sortable: true,
        },
        {
            label: "Last Name",
            field: "last_name",
            sortable: true,
        },
        {
            label: "Email",
            field: "email",
            sortable: true,
        },
        {
            label: "Mobile No",
            field: "mobile_no",
            sortable: true,
        },
        {
            label: "Status",
            field: "status",
            sortable: false,
            render: (item) => (
                <StatusBadge type="affiliateRequest" status={item.status} />
            ),
        },
        {
            label: "Created At",
            field: "created_at",
            sortable: true,
            render: (item) => defaultDateTimeFormat(item.created_at),
        },
        {
            label: "Action",
            field: "action",
            sortable: false,
            render: (item) => (
                <Button
                    color="success"
                    size="sm"
                    onClick={() => {
                        setDetails(item);
                        setIsOpen(true);
                    }}
                >
                    <i className="ri-eye-line" />
                </Button>
            ),
        },
    ];

    const initialize = useCallback(() => {
        dispatch(getAgentList(data));
    }, [dispatch, data]);

    useEffect(() => {
        initialize();
    }, [initialize]);

    return (
        <React.Fragment>
            <Title title={getPathTitle(Routes.AFFILIATES_REQUEST_LIST)} />
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb
                        parent={{
                            isParent: true,
                            pageTitle: getPathTitle(
                                Routes.AFFILIATES_REQUEST_LIST
                            ),
                            pageLink: getPath(Routes.AFFILIATES_REQUEST_LIST),
                        }}
                    />
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardHeader className="align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">
                                        {getPathTitle(
                                            Routes.AFFILIATES_REQUEST_LIST
                                        )}
                                    </h4>
                                    {userRole()?.isMasterAgent ? (
                                        <div className="flex-shrink-0">
                                            <Link
                                                to={getPath(
                                                    Routes.AFFILIATE_CREATE
                                                )}
                                            >
                                                <Button
                                                    color="success"
                                                    size="sm"
                                                >
                                                    <i className="ri-add-fill me-1 align-bottom"></i>
                                                    {getPathTitle(
                                                        Routes.AFFILIATE_CREATE
                                                    )}
                                                </Button>
                                            </Link>
                                        </div>
                                    ) : null}
                                </CardHeader>
                                <CardBody>
                                    <Row className="mb-3">
                                        <Col md={6}>
                                            <Select
                                                label="Status"
                                                isClearable={true}
                                                value={
                                                    data[
                                                        "filter_by[affiliate_requests.status]"
                                                    ]
                                                }
                                                options={[
                                                    ...[
                                                        {
                                                            label: "All",
                                                            value: "",
                                                        },
                                                    ],
                                                    ...affiliateRequestStatus,
                                                ]}
                                                onChange={(event) =>
                                                    setData({
                                                        ...data,
                                                        "filter_by[affiliate_requests.status]":
                                                            event.target.value,
                                                        page: 1,
                                                    })
                                                }
                                            />
                                        </Col>
                                    </Row>
                                    <Table
                                        search={search}
                                        handlerSearch={setSearch}
                                        sortBy={sortBy}
                                        handlerSortBy={setSortBy}
                                        thead={tableTheadData}
                                        handlerChangeData={setData}
                                        data={data}
                                        collection={list}
                                        isLoading={isLoading}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            {isOpen && (
                <AffiliateRequestViewModal
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    details={details}
                    setDetails={setDetails}
                    initialize={initialize}
                />
            )}
        </React.Fragment>
    );
};

export default AffiliateRequest;
