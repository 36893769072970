import { successToast, errorToast } from "@/components/constants/toast";
import * as API from "@/network/apis/request";
import { convert2dErrorsArray } from "@/utils";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import * as ACTIONS from "./actions";
import * as TYPES from "./actionTypes";
import { showLoading, hideLoading } from "@/components/constants/common";

function* getRequestData({ payload, requestType }) {
    try {
        const response = yield call(API.getRequestData, payload, requestType);

        if (response.status === 200) {
            yield put(ACTIONS.setRequestData(response.data.response));
        }
    } catch (error) {
        yield put(ACTIONS.setRequestDataError());
    }
}

function* getRequestInfo({ payload }) {
    try {
        const response = yield call(API.getRequestInfo, payload);

        if (response.status === 200) {
            yield put(ACTIONS.setRequestInfo(response.data.response));
        }
    } catch (error) {
        yield put(ACTIONS.setRequestInfoError());
    }
}

function* updateRequestStatus({ payload, requestType, keyToken }) {
    try {
        showLoading();
        const response = yield call(
            API.updateRequestStatus,
            payload,
            requestType,
            keyToken
        );
        if (response.status === 200) {
            yield put(
                ACTIONS.updateRequestStatusSuccess(response.data.response)
            );
            successToast(
                `Successfully  ${
                    requestType === "cancel"
                        ? "Cancelled."
                        : requestType === "complete"
                        ? "Approved."
                        : "Released"
                }`
            );
        }
    } catch (error) {
        let errors = [error.response.data.message];
        yield put(ACTIONS.updateRequestStatusError(errors));
        errorToast(errors);
    }
    hideLoading();
}

function* bulkRelease({ payload }) {
    try {
        showLoading();
        const response = yield call(API.bulkRelease, payload);
        if (response.status === 200) {
            yield put(ACTIONS.onBulkReleaseSuccess(response.data.response));
            successToast(`Transaction requests successfully released.`);
        }
    } catch (error) {
        let errors = [error.response.data.message];
        yield put(ACTIONS.onBulkReleaseFailed(errors));
        errorToast(errors);
    }
    hideLoading();
}

function* bulkPayout({ payload }) {
    try {
        const response = yield call(API.bulkPayout, payload);
        if (response.status === 200) {
            yield put(ACTIONS.onBulkPayoutSuccess());
            successToast(`Batch payout successfully requested.`);
        }
    } catch (error) {
        let errors = [error.response.data.message];
        yield put(ACTIONS.onBulkPayoutFailed());
        errorToast(errors);
    }
}

function* getBulkPayoutProgress({ payload }) {
    try {
        const response = yield call(API.getBulkPayoutProgress, payload);
        if (response.status === 200) {
            yield put(
                ACTIONS.onGetBulkPayoutProgressSuccess(response.data.response)
            );

            if (response?.data?.response?.progress === 100) {
                successToast(`Batch payout completed.`);
            }
        }
    } catch (error) {
        yield put(ACTIONS.onGetBulkPayoutProgressFailed());
    }
}

function* importPayoutAsync(action) {
    try {
        const response = yield call(API.importPayout, action.payload);

        if (response.status === 200) {
            yield put(
                ACTIONS.onImportPayoutSuccess(response.data.response.data)
            );
            successToast(response.data.message);
        }
    } catch (error) {
        const errors = !Array.isArray(error?.response?.data?.response)
            ? convert2dErrorsArray(error?.response?.data?.response)
            : [error.response.data.message];
        yield put(ACTIONS.onImportPayoutFailed(errors));
        switch (error.response.data.code) {
            case 422:
                errorToast(error.response.data.message);
                break;
            default:
                break;
        }
    }
}

function* getPublicPaymentMethods() {
    try {
        const response = yield call(API.getPublicPaymentMethod);
        if (response.status === 200) {
            yield put(
                ACTIONS.setPublicPaymentMethods(response.data.response)
            );
        }
    } catch (error) {
        errorToast(error?.response?.data?.message ?? "Something went wrong.");
    }
}

function* watchGetRequestData() {
    yield takeLatest(TYPES.GET_REQUEST_DATA, getRequestData);
}
function* watchGetRequestInfo() {
    yield takeLatest(TYPES.GET_REQUEST_INFO, getRequestInfo);
}
function* watchUpdateRequestStatus() {
    yield takeLatest(TYPES.UPDATE_REQUEST_STATUS, updateRequestStatus);
}
function* watchBulkRelease() {
    yield takeLatest(TYPES.BULK_RELEASE, bulkRelease);
}
function* watchBulkPayout() {
    yield takeLatest(TYPES.BULK_PAYOUT, bulkPayout);
}
function* watchGetBulkPayoutProgress() {
    yield takeLatest(TYPES.GET_BULK_PAYOUT_PROGRESS, getBulkPayoutProgress);
}
function* watchImportPayout() {
    yield takeLatest(TYPES.IMPORT_PAYOUT, importPayoutAsync);
}

function* watchGetPublicPaymentMethods() {
    yield takeLatest(TYPES.GET_PUBLIC_PAYMENT_METHODS, getPublicPaymentMethods);
}
function* requestSagas() {
    yield all([
        fork(watchGetRequestData),
        fork(watchGetRequestInfo),
        fork(watchUpdateRequestStatus),
        fork(watchBulkRelease),
        fork(watchBulkPayout),
        fork(watchGetBulkPayoutProgress),
        fork(watchImportPayout),
        fork(watchGetPublicPaymentMethods),
    ]);
}

export default requestSagas;
