import { RequestTab } from "@/components/molecules";
import React from "react";
import ProfileLayout from "../Layout";
const Request = () => {
    return (
        <ProfileLayout>
            <RequestTab />
        </ProfileLayout>
    );
};

export default Request;
