import { promotionState } from "@/types/states";
import * as types from "./actionTypes";

const initialState = promotionState;
const promotion = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_PROMOTION_CATEGORY_LIST:
        case types.UPDATE_PROMOTION_CATEGORY_STATUS:
        case types.GET_PROMOTION_LIST:
        case types.GET_PROMOTION_TRANSACTION_LIST:
        case types.GET_PROMOTION_TRANSACTION_VIEW:
        case types.UPDATE_PROMOTION_STATUS:
            state = {
                ...state,
                isLoading: true,
                selectedPromotion: promotionState.selectedPromotion,
                selectedPromotionCategory:
                    promotionState.selectedPromotionCategory,
            };
            break;
        case types.UPDATE_PROMOTION:
            state = {
                ...state,
                isLoading: true,
            };
            break;
        case types.GET_PROMOTION_CATEGORY_BY_KEY_TOKEN:
            state = {
                ...state,
                selectedPromotionCategory:
                    promotionState.selectedPromotionCategory,
            };
            break;
        case types.GET_PROMOTION_BY_NO:
            state = {
                ...state,
                isLoading: true,
                selectedPromotion: promotionState.selectedPromotion,
                selectedPromotionCategory:
                promotionState.selectedPromotionCategory,
            };
            break;
        case types.CREATE_PROMOTION:
            state = {
                ...state,
                isSubmit: true,
            };
            break;
        case types.SET_PROMOTION_CATEGORY_LIST:
            state = {
                ...state,
                promotionCategoryList: action.payload,
                isLoading: false,
            };
            break;
        case types.SET_PROMOTION_LIST:
            state = {
                ...state,
                promotionList: action.payload,
                isLoading: false,
            };
            break;
        case types.SET_PROMOTION_TRANSACTION_LIST:
            state = {
                ...state,
                promotionTransactionList: action.payload,
                isLoading: false,
            };
            break;
        case types.SET_PROMOTION_TRANSACTION_VIEW:
            state = {
                ...state,
                promotionTransactionViewData: action.payload,
                isLoading: false,
            };
            break;
        case types.SET_PROMOTION_BY_NO:
            state = {
                ...state,
                selectedPromotion: action.payload,
                isLoading: false,
            };
            break;
        case types.SET_PROMOTION_CATEGORY_BY_KEY_TOKEN:
            state = {
                ...state,
                selectedPromotionCategory: action.payload,
                isLoading: false,
            };
            break;
        case types.SET_PROMOTION_CATEGORY_LIST_ERROR:
        case types.UPDATE_PROMOTION_CATEGORY_STATUS_ERROR:
        case types.SET_PROMOTION_LIST_ERROR:
        case types.SET_PROMOTION_TRANSACTION_LIST_ERROR:
        case types.SET_PROMOTION_TRANSACTION_VIEW_ERROR:
        case types.SET_PROMOTION_CATEGORY_BY_KEY_TOKEN_ERROR:
        case types.SET_PROMOTION_BY_NO_ERROR:
        case types.UPDATE_PROMOTION_STATUS_ERROR:
        case types.UPDATE_PROMOTION_ERROR:
            state = {
                ...state,
                isLoading: false,
            };
            break;
        case types.UPDATE_PROMOTION_CATEGORY_STATUS_SUCCESS:
        case types.UPDATE_PROMOTION_STATUS_SUCCESS:
        case types.UPDATE_PROMOTION_SUCCESS:
            state = {
                ...state,
                isLoading: false,
                errors: promotionState.errors,
            };
            break;
        case types.CREATE_PROMOTION_SUCCESS:
            state = {
                ...state,
                isSubmit: false,
                errors: promotionState.errors,
                selectedPromotionCategory:
                    promotionState.selectedPromotionCategory,
            };
            break;
        case types.CREATE_PROMOTION_ERROR:
            state = {
                ...state,
                isSubmit: false,
                errors: action.payload,
            };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default promotion;
