import {
    CreatableSelect,
    FlatPickerDate,
    InputReactSelect,
    Table,
    Title,
  } from "@/components/atoms";
  import BreadCrumb from "@/components/common/BreadCrumb";
  import {
    Routes,
    getPath,
    getPathTitle,
  } from "@/constants";
  import { dateFilterOptions } from "@/constants/users";
  import { useUserTypeOptions } from "@/hooks";
  import { getMultipleIPData } from "@/store/actions";
  import {
    arrayToString,
    filterDateFormat,
    defaultDateTimeFormat,
  } from "@/utils";
  import { useFormik } from "formik";
  import React, { useEffect, useRef, useState } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Row,
  } from "reactstrap";
  
  const LoginIPs = () => {
    const dateFilterRef = useRef();
    const { multipleIPDetectedData, isGetmultipleIPDetectedDataLoading } = useSelector(
      (state) => state.report
    );
    const dispatch = useDispatch();
  
    const [search, setSearch] = useState("");
    const [sortBy, setSortBy] = useState({
      field: "",
      type: "asc",
    });
  
    const { data: filteredUserTypeOptions } = useUserTypeOptions(true);
  
    const defaultParams = {
      account_no: "",
      per_page: 10,
      page: 1,
      search: "",
      sort_by:
        sortBy.field && sortBy.type ? `${sortBy.field} ${sortBy.type}` : "",
      "date_range[transaction_request_ip_logs.created_at][from]": dateFilterOptions[0].value[0],
      "date_range[transaction_request_ip_logs.created_at][to]": dateFilterOptions[0].value[1],
    };
  
    const [data, setData] = useState(defaultParams);
  
    const [selectedDate, setSelectedDate] = useState(dateFilterOptions[0]);
  
    const form = useFormik({
      enableReinitialize: true,
      initialValues: {
        dateFilter: dateFilterOptions[0].value,
        roleName: "",
        accountNumber: "",
      },
      onSubmit: (values) => {
        console.log(values?.roleName);
        setData({
          ...data,
          page: 1,
          "date_range[transaction_request_ip_logs.created_at][from]": values.dateFilter
            ? values.dateFilter[0]
            : data["date_range[transaction_request_ip_logs.created_at][from]"],
          "date_range[transaction_request_ip_logs.created_at][to]": values.dateFilter
            ? values.dateFilter[1]
            : data["date_range[transaction_request_ip_logs.created_at][to]"],
          "filter_by[roles.name]": values?.roleName["value"] ?? null,
          "filter_by[user_profiles.account_no]": values.accountNumber
          ? arrayToString(
                values.accountNumber.map((item) => {
                    return item.value;
                })
            )
          : null,
        });
      },
    });
  
    const tableTheadData = [
      {
        label: "Role",
        field: "role_name",
        sortable: true,
      },
      {
        label: "Account No.",
        field: "account_no",
        sortable: true,
      },
      {
        label: "First Name",
        field: "first_name",
        sortable: true,
      },
      {
        label: "Last Name",
        field: "last_name",
        sortable: true,
      },
      {
        label: "Total Transaction",
        field: "total_transaction",
        sortable: true,
      },
      {
        label: "IP Address",
        field: "ip_address",
        sortable: true,
      },
      {
        label: "User Agent UUID",
        field: "user_agent_uuid",
        sortable: true,
      },
      {
        label: "Date/Time",
        field: "created_at",
        sortable: true,
        render: (item) => defaultDateTimeFormat(item.created_at),
      },
    ];
  
    const handleClear = () => {
      setData(defaultParams);
      setSelectedDate(dateFilterOptions[0]);
      form.resetForm();
      form.submitForm();
    };
  
    useEffect(() => {
      dispatch(getMultipleIPData(data));
    }, [dispatch, data]);
  
    return (
      <React.Fragment>
        <Title title={getPathTitle(Routes.MULTIPLE_IP_DETECTED)} />
        <div className="page-content">
          <Container fluid>
            <BreadCrumb
              parent={{
                isParent: false,
                pageTitle: getPathTitle(Routes.MULTIPLE_IP_DETECTED),
                pageLink: getPath(Routes.MULTIPLE_IP_DETECTED),
              }}
            />
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <Row className="mb-3">
                      <Col md={3}>
                        <InputReactSelect
                          label="Role Name"
                          isClearable={false}
                          value={form.values.roleName}
                          options={filteredUserTypeOptions}
                          onChange={(option) =>
                            form.setFieldValue("roleName", option)
                          }
                        />
                      </Col>
                      <Col md={3}>
                      <CreatableSelect
                        label="Account No."
                        placeholder="Add Account Number"
                        isClearable
                        value={form.values.accountNumber}
                        isMulti
                        onChange={(options) =>
                          form.setFieldValue("accountNumber", options)
                        }
                      />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md={12} className="pt-2">
                        <div className="w-100 tw-flex tw-gap-1 tw-flex-col lg:tw-flex-row">
                          {dateFilterOptions.map((item, index) => (
                            <Button
                              key={index}
                              color={
                                selectedDate.label === item.label
                                  ? "primary"
                                  : "light"
                              }
                              onClick={() => {
                                setSelectedDate(item);
                                if (item.value) {
                                  form.setFieldValue("dateFilter", item.value);
                                }
                              }}
                            >
                              {item.label}
                            </Button>
                          ))}
                          <div className="tw-w-full lg:tw-w-1/5">
                            <FlatPickerDate
                              ref={dateFilterRef}
                              name="dateFilter"
                              mode="range"
                              placeholder="From Date - To Date"
                              maxDate={null}
                              value={selectedDate.value}
                              onChange={(date) => {
                                if (date.length >= 2) {
                                  form.setFieldValue(
                                    "dateFilter",
                                    date.map((value) => filterDateFormat(value))
                                  );
                                }
                              }}
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} className="text-center">
                        <Button
                          color="primary"
                          className="mx-1"
                          onClick={() => handleClear()}
                        >
                          Clear
                        </Button>
                        <Button
                          color="success"
                          className="mx-1"
                          onClick={() => form.handleSubmit()}
                        >
                          Filter
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Card>
                  <CardBody>
                    <Table
                      search={search}
                      handlerSearch={setSearch}
                      sortBy={sortBy}
                      handlerSortBy={setSortBy}
                      thead={tableTheadData}
                      handlerChangeData={setData}
                      data={data}
                      collection={multipleIPDetectedData}
                      isLoading={isGetmultipleIPDetectedDataLoading}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  };
  
  export default LoginIPs;
  