import {
    loadingToast,
    removeToast,
    successToast,
    errorToast,
} from "@/components/constants/toast";
import * as API from "@/network/apis/game";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import * as ACTIONS from "./actions";
import * as TYPES from "./actionTypes";
import { convert2dErrorsArray } from "@/utils";

function* getCasinoGameProvider() {
    try {
        const response = yield call(API.getCasinoGameProvider);

        if (response.status === 200) {
            const collection = response.data.response;
            const data = collection.map((item) => ({
                value: item.id,
                label: item.title,
                slug: item.slug,
                key_token: item.key_token,
                is_active: item.is_active,
            }));
            yield put(ACTIONS.setCasinoGameProvider(data));
        }
    } catch (error) {
        yield put(ACTIONS.setCasinoGameProviderError());
    }
}

function* getCasinoGameType() {
    try {
        const response = yield call(API.getCasinoGameType);

        if (response.status === 200) {
            const collection = response.data.response;
            const data = collection.map((item) => ({
                value: item.id,
                label: item.name,
                slug: item.slug,
                key_token: item.key_token,
                is_active: item.is_active,
                description: item.description,
            }));
            yield put(ACTIONS.setCasinoGameType(data));
        }
    } catch (error) {
        yield put(ACTIONS.setCasinoGameTypeError());
    }
}

function* getCasinoGameList({ payload }) {
    try {
        const response = yield call(API.getCasinoGameList, payload);

        if (response.status === 200) {
            yield put(
                ACTIONS.setCasinoGameList({
                    ...response.data.response,
                    isFeaturedGames:
                        payload["filter_by[featured_game_types.key_name]"] !==
                        null,
                })
            );
        }
    } catch (error) {
        yield put(ACTIONS.setCasinoGameListError());
    }
}

function* syncCasinoGameProvider({ payload }) {
    try {
        loadingToast("Syncing...");
        const response = yield call(API.syncCasinoGameProvider, payload);

        if (response.status === 200) {
            yield put(
                ACTIONS.syncCasinoGameProviderSuccess(response.data.response)
            );
            removeToast();
            successToast("Syncing Completed.");
        }
    } catch (error) {
        yield put(ACTIONS.syncCasinoGameProviderError());
        removeToast();
    }
}

function* updateGameStatus({ gameType, keyToken, reloadData }) {
    try {
        loadingToast("Updating...");
        const response = yield call(API.updateGameStatus, gameType, keyToken);

        if (response.status === 200) {
            let data = response.data.response;
            yield put(ACTIONS.updateGameStatusSuccess(data));
            removeToast();
            successToast("Successfully Updated.");
            reloadData();
        }
    } catch (error) {
        yield put(ACTIONS.updateGameStatusError());
        removeToast();
    }
}

function* getFeaturedGameTypesAsync({ payload }) {
    try {
        const response = yield call(API.getFeaturedGameTypes, payload);

        if (response.status === 200) {
            yield put(
                ACTIONS.onGetFeaturedGameTypesSuccess(response.data.response)
            );
        }
    } catch (error) {
        yield put(ACTIONS.onGetFeatureGameTypesFailed());
    }
}

function* addFeaturedGameAsync({ payload }) {
    try {
        const response = yield call(API.addFeaturedGame, payload);

        if (response.status === 200) {
            yield put(ACTIONS.onAddFeaturedGameSuccess());
            successToast("Featured Game Successfully Added.");
        }
    } catch (error) {
        let errors = !Array.isArray(error?.response?.data?.response)
            ? convert2dErrorsArray(error?.response?.data?.response)
            : [error.response.data.message];
        yield put(ACTIONS.onAddFeaturedGameFailed(errors));
    }
}

function* updateFeaturedGameAsync({ payload }) {
    try {
        const params = payload.params;
        const uuid = payload.uuid;
        const response = yield call(API.updateFeaturedGame, params, uuid);

        if (response.status === 200) {
            yield put(ACTIONS.onUpdateFeaturedGameSuccess());
            successToast("Featured Game Successfully Updated.");
        }
    } catch (error) {
        let errors = !Array.isArray(error?.response?.data?.response)
            ? convert2dErrorsArray(error?.response?.data?.response)
            : [error.response.data.message];
        yield put(ACTIONS.onUpdateFeaturedGameFailed(errors));
    }
}

function* deleteFeaturedGameAsync({ payload }) {
    try {
        const response = yield call(API.deleteFeaturedGame, payload);

        if (response.status === 200) {
            yield put(ACTIONS.onDeleteFeaturedGameSuccess());
            successToast("Featured Game Successfully Deleted.");
        }
    } catch (error) {
        yield put(ACTIONS.onDeleteFeaturedGameFailed());
    }
}

function* updateFeaturedGameOrderAsync({ payload, keyToken }) {
    try {
        const response = yield call(
            API.updateFeaturedGameOrder,
            payload,
            keyToken
        );

        if (response.status === 200) {
            yield put(ACTIONS.onUpdateFeaturedGameOrderSuccess());
            successToast("Featured Game Order Successfully Updated.");
        }
    } catch (error) {
        yield put(ACTIONS.onUpdateFeaturedGameOrderFailed());
        errorToast(error?.response?.data?.response.order_by[0]);
    }
}

function* getGameOfferingsAsync(action) {
    try {
        const response = yield call(API.getGameOfferings, action.payload);

        if (response.status === 200) {
            yield put(
                ACTIONS.onGetGameOfferingsSuccess(response.data.response)
            );
        }
    } catch (error) {
        yield put(ACTIONS.onGetGameOfferingsFailed());
    }
}

function* updateGameProviderOrder({ payload }) {
    try {
        const response = yield call(API.updateGameProviderOrder, payload);

        if (response.status === 200) {
            const collection = response.data.response;
            const data = collection.map((item) => ({
                value: item.id,
                label: item.title,
                slug: item.slug,
                key_token: item.key_token,
                is_active: item.is_active,
            }));
            
            yield put(ACTIONS.onUpdateGameProviderOrderSuccess(data));
            successToast("Successfully Updated.");
        }
    } catch (error) {
        yield put(ACTIONS.onUpdateGameProviderOrderError());
        removeToast();
    }
}

function* watchGetCasinoGameProvider() {
    yield takeLatest(TYPES.GET_CASINO_GAME_PROVIDER, getCasinoGameProvider);
}
function* watchGetCasinoGameType() {
    yield takeLatest(TYPES.GET_CASINO_GAME_TYPE, getCasinoGameType);
}
function* watchGetCasinoGameList() {
    yield takeLatest(TYPES.GET_CASINO_GAME_LIST, getCasinoGameList);
}
function* watchSyncCasinoGameProvider() {
    yield takeLatest(TYPES.SYNC_CASINO_GAME_PROVIDER, syncCasinoGameProvider);
}
function* watchUpdateGameStatus() {
    yield takeLatest(TYPES.UPDATE_GAME_STATUS, updateGameStatus);
}
function* watchGetFeaturedGameTypes() {
    yield takeLatest(TYPES.GET_FEATURED_GAME_TYPES, getFeaturedGameTypesAsync);
}
function* watchAddFeaturedGame() {
    yield takeLatest(TYPES.ADD_FEATURED_GAME, addFeaturedGameAsync);
}

function* watchUpdateFeaturedGame() {
    yield takeLatest(TYPES.UPDATE_FEATURED_GAME, updateFeaturedGameAsync);
}


function* watchDeleteFeaturedGame() {
    yield takeLatest(TYPES.DELETE_FEATURED_GAME, deleteFeaturedGameAsync);
}
function* watchUpdateFeaturedGameOrder() {
    yield takeLatest(
        TYPES.UPDATE_FEATURED_GAME_ORDER,
        updateFeaturedGameOrderAsync
    );
}
function* watchGetGameOfferings() {
    yield takeLatest(TYPES.GET_GAME_OFFERINGS, getGameOfferingsAsync);
}

function* watchUpdateGameProviderOrder() {
    yield takeLatest(TYPES.UPDATE_GAME_PROVIDER_ORDER, updateGameProviderOrder);
}

function* gameSagas() {
    yield all([
        fork(watchGetCasinoGameProvider),
        fork(watchGetCasinoGameType),
        fork(watchGetCasinoGameList),
        fork(watchSyncCasinoGameProvider),
        fork(watchUpdateGameStatus),
        fork(watchGetFeaturedGameTypes),
        fork(watchAddFeaturedGame),
        fork(watchDeleteFeaturedGame),
        fork(watchUpdateFeaturedGameOrder),
        fork(watchGetGameOfferings),
        fork(watchUpdateGameProviderOrder),
        fork(watchUpdateFeaturedGame)
    ]);
}

export default gameSagas;
