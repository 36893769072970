import * as types from "./actionTypes";
import { reportState } from "@/types/states";

const initialState = reportState;

const report = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_WIN_LOSS_REPORT:
            state = {
                ...state,
                isGetWinLossReportLoading: true,
            };
            break;
        case types.GET_WIN_LOSS_REPORT_SUCCESS:
            state = {
                ...state,
                winLossReport: action.payload,
                isGetWinLossReportLoading: false,
            };
            break;
        case types.GET_WIN_LOSS_REPORT_FAILED:
            state = {
                ...state,
                isGetWinLossReportLoading: false,
            };
            break;
        case types.GET_WIN_LOSS_REPORT_BY_PRODUCT:
            state = {
                ...state,
                isGetWinLossByProductReportLoading: true,
            };
            break;
        case types.GET_WIN_LOSS_REPORT_BY_PRODUCT_SUCCESS:
            state = {
                ...state,
                isGetWinLossByProductReportLoading: false,
                winLossByProductReport: action.payload,
            };
            break;
        case types.GET_WIN_LOSS_REPORT_BY_PRODUCT_FAILED:
            state = {
                ...state,
                isGetWinLossByProductReportLoading: false,
            };
            break;
        case types.GET_GENERATED_REPORTS:
            state = {
                ...state,
                isGetGeneratedReportLoading: true,
            };
            break;
        case types.GET_GENERATED_REPORTS_SUCCESS:
            state = {
                ...state,
                generatedReports: action.payload,
            };
            break;
        case types.GET_GENERATED_REPORTS_FAILED:
            state = {
                ...state,
                isGetGeneratedReportLoading: false,
            };
            break;
        case types.GENERATE_BATCH_PAYOUT_REPORT:
            state = {
                ...state,
                isGenerateBatchPayoutReportLoading: true,
            };
            break;
        case types.GENERATE_BATCH_PAYOUT_REPORT_SUCCESS:
            state = {
                ...state,
                isGenerateBatchPayoutReportLoading: false,
                isGenerateBatchPayoutReportSuccess: true,
            };
            break;
        case types.GENERATE_BATCH_PAYOUT_REPORT_FAILED:
            state = {
                ...state,
                isGenerateBatchPayoutReportLoading: false,
                isGenerateBatchPayoutReportSuccess: false,
            };
            break;
        case types.GET_LOGIN_LOGS: 
            state = {
                ...state,
                isGetloginLogsLoading: true,
            }
            break;
        case types.GET_LOGIN_LOGS_SUCCESS: 
            state = {
                ...state,
                loginLogs: action.payload,
                isGetloginLogsLoading: false,
            }
            break;
        case types.GET_LOGIN_LOGS_FAILED: 
            state = {
                ...state,
                isGetloginLogsLoading: false,
            }
            break;
        case types.GET_MULTIPLE_IP_DATA:
            state = {
                ...state,
                isGetmultipleIPDetectedDataLoading: true,
            };
            break;
        case types.GET_MULTIPLE_IP_DATA_SUCCESS:
            state = {
                ...state,
                multipleIPDetectedData: action.payload,
                isGetmultipleIPDetectedDataLoading: false,
            };
            break;
        case types.GET_MULTIPLE_IP_DATA_FAILED:
            state = {
                ...state,
                isGetmultipleIPDetectedDataLoading: false,
            };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default report;
