import React from "react";
import { EditInput } from "@/components/atoms";
import { useDispatch } from "react-redux";
import { updateReferrer } from "@/store/actions";

const UpdateReferrerInput = ({ profile, initialize }) => {
  const dispatch = useDispatch();

  const onSave = (value) => {
    dispatch(
      updateReferrer({
        payload: { account_no: value },
        keyToken: profile?.userProfilekeyToken,
        initialize
      })
    );
  };

  return (
    <div>
      <EditInput
        inputType="text"
        title="Direct Referrer"
        showEdit
        resetOnSubmit
        nullValueOnEdit={true}
        defaultValue={profile?.referrer?.[Object.keys(profile?.referrer)[0]]?.account_no}
        onSave={onSave}
      />
    </div>
  );
};

export default UpdateReferrerInput;
