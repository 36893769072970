import {
    CommissionRateUpdateInput,
    EmailUpdateInput,
    MobileNoUpdateInput,
    PasswordResetButton,
    PersonalInformationForm,
    StatusUpdateDropdown,
    UpdateRoleTagSelect,
    UpdateReferrerInput,
} from "@/components/molecules";
import { roleBaseSettings } from "@/hooks";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import ProfileLayout from "../Layout";
import { commissionRatePerGameOffering } from "@/constants";
import { getSetting } from "@/hooks";
import { useParams } from "react-router-dom";
import { getAgentProfile } from "@/store/actions";


const Profile = () => {
    const dispatch = useDispatch();
    const { accountNo } = useParams();
    const {
        user,
        isLoading: isLoadingUser,
        errors: userErrors,
    } = useSelector((state) => state.user);

    const isAdmin =
        user?.role.slug === "super-admin" || user?.role.slug === "admin"
            ? true
            : false;

    const canUpdateUserStatus = !isAdmin
        ? roleBaseSettings("to_verify_player", "allow")
        : isAdmin;

    const { isLoading, errors, commissionRateErrors, profile } = useSelector(
        (state) => state.agent
    );

    const vtoCommissionRateEnabled =
        getSetting("enable_valid_turnover_commission_rate") === "1";

    const filteredCommissionRatePerGameOffering =
        commissionRatePerGameOffering.filter((item) => {
            if (!vtoCommissionRateEnabled) {
                return !item.value.includes("vto");
            } else {
                return true;
            }
        });

    const initialize = () => {
        dispatch(getAgentProfile(accountNo));
    };
    return (
        <ProfileLayout>
            <Row className="mb-3">
                <Col md={12}>
                    {canUpdateUserStatus && (
                        <StatusUpdateDropdown
                            profile={profile}
                            isLoading={isLoadingUser}
                        />
                    )}
                    <PasswordResetButton
                        isLoading={isLoadingUser}
                        email={profile.email}
                        userType="agent"
                    />
                </Col>
            </Row>
            <hr />
            {user?.role.slug === "super-admin" && (
                <Row>
                    <Col>
                        <UpdateReferrerInput initialize={initialize} profile={profile} />
                    </Col>
                </Row>
            )}
            <Row className="mb-3">
                <Col md="6">
                    <EmailUpdateInput
                        errors={userErrors}
                        profile={profile}
                        isAdmin={isAdmin}
                        userType="agent"
                    />
                </Col>
                <Col md="6">
                    <MobileNoUpdateInput
                        errors={userErrors}
                        profile={profile}
                        isAdmin={isAdmin}
                    />
                </Col>
                {/* {filteredCommissionRatePerGameOffering.map((item, index) => {
                    if (item.balanceCategory === "merchant") {
                        return (
                            <Col key={index} md="6">
                                <CommissionRateUpdateInput
                                    name={item.label}
                                    errors={commissionRateErrors}
                                    type={item.value}
                                    commissionRate={profile[item.value]}
                                    userProfilekeyToken={
                                        profile.userProfilekeyToken
                                    }
                                    isEdit={user?.role.slug === "super-admin"}
                                    userType="agent"
                                />
                            </Col>
                        );
                    } else {
                        return (
                            <Col key={index} md="6">
                                <CommissionRateUpdateInput
                                    name={item.label}
                                    errors={commissionRateErrors}
                                    type={item.value}
                                    commissionRate={profile[item.value]}
                                    userProfilekeyToken={
                                        profile.userProfilekeyToken
                                    }
                                    isEdit={user?.role.slug === "operator"}
                                    userType="agent"
                                />
                            </Col>
                        );
                    }
                })} */}
                <Col md="6">
                    <UpdateRoleTagSelect profile={profile} />
                </Col>
            </Row>

            <PersonalInformationForm
                profile={profile}
                userType="agent"
                isLoading={isLoading}
                isAdmin={isAdmin}
                errors={errors}
            />
        </ProfileLayout>
    );
};

export default Profile;
