import {
    errorToast,
    loadingToast,
    removeToast,
    successToast,
} from "@/components/constants/toast";
import * as API from "@/network/apis/announcement";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import * as TYPES from "./actionTypes";
import * as ACTIONS from "./actions";
import { convert2dErrorsArray } from "@/utils";

function* sendAnnouncement({ payload, result }) {
    try {
        loadingToast("Sending..");
        const response = yield call(API.sendAnnouncement, payload);

        if (response.status === 200) {
            yield put(ACTIONS.sendAnnouncementSuccess());
            removeToast();
            successToast("Successfully Sent.");
            result(true);
        }
    } catch (error) {
        removeToast();
        errorToast(error.response.data.message);
        let errors = !Array.isArray(error?.response?.data?.response)
        ? convert2dErrorsArray(error?.response?.data?.response)
        : [error.response.data.message];
        yield put(ACTIONS.sendAnnouncementError(errors));
        result(false);
    }
}

function* getMessageList({ payload }) {
    try {
        const response = yield call(API.getMessageList, payload);

        if (response.status === 200) {
            yield put(ACTIONS.getMessageSuccess(response.data.response));
        }
    } catch (error) {
        errorToast(error.response.data.message);
        let errors = !Array.isArray(error?.response?.data?.response)
        ? convert2dErrorsArray(error?.response?.data?.response)
        : [error.response.data.message];
        yield put(ACTIONS.getMessageError(errors));
    }
}

function* updateMessageStatus({ payload, keyToken, initialize }) {
    loadingToast("Updating..");

    try {
        const response = yield call(API.updateMessageStatus, payload, keyToken);

        if (response.status === 200) {
            removeToast();
            yield put(ACTIONS.updateMessageStatusSuccess(response.data.response));
            successToast("Status updated successfully.");

        }
    } catch (error) {
        removeToast();
        errorToast(error.response.data.message);
        let errors = !Array.isArray(error?.response?.data?.response)
        ? convert2dErrorsArray(error?.response?.data?.response)
        : [error.response.data.message];
        yield put(ACTIONS.updateMessageStatusError(errors));
    } finally {
        initialize();
    }
}

function* getMessageTypes() {
    try {
        const response = yield call(API.getMessageTypes);

        if (response.status === 200) {
            yield put(ACTIONS.getMessageTypesSuccess(response.data.response));

        }
    } catch (error) {
        errorToast(error.response.data.message);
        let errors = !Array.isArray(error?.response?.data?.response)
        ? convert2dErrorsArray(error?.response?.data?.response)
        : [error.response.data.message];
        yield put(ACTIONS.getMessageTypesError(errors));
    }
}

function* createMessage({payload, result}) {
    try {
        loadingToast("Processing..");

        const response = yield call(API.createMessage, payload);

        if (response.status === 200) {
            yield put(ACTIONS.createMessageSuccess(response.data.response));
            result(true);
            removeToast();
            successToast(response.data.message);
        }
    } catch (error) {
        removeToast();
        errorToast(error.response.data.message);
        // let errors = !Array.isArray(error?.response?.data?.response)
        // ? convert2dErrorsArray(error?.response?.data?.response)
        // : [error.response.data.message];
        yield put(ACTIONS.createMessageError({}));
        result(false);
    }
}

function* updateMessageInformation({payload, keyToken, result}) {
    try {
        loadingToast("Processing..");
        
        const response = yield call(API.updateMessageInformation, payload, keyToken);

        if (response.status === 200) {
            yield put(ACTIONS.updateMessageInformationSuccess());
            removeToast();
            successToast("Successfully Updated.");
            result(true);
        }
    } catch (error) {
        removeToast();
        errorToast(error?.response?.data?.message);
        yield put(ACTIONS.updateMessageInformationError({}));
        result(false);
    }
}

function* updateMessageBanner({payload, keyToken, result}) {
    try {
        loadingToast("Processing..");
        
        const response = yield call(API.updateMessageBanner, payload, keyToken);

        if (response.status === 200) {
            yield put(ACTIONS.updateMessageBannerSuccess());
            removeToast();
            successToast("Successfully Updated.");
            result(true);
        }
    } catch (error) {
        removeToast();
        errorToast(error?.response?.data?.message);
        yield put(ACTIONS.updateMessageBannerError({}));
        result(false);
    }
}

function* getNotificationTypeList({ payload }) {
    try {
        const response = yield call(API.getNotificationTypeList, payload);

        if (response.status === 200) {
            yield put(ACTIONS.getNotificationTypeListSuccess(response.data.response));
        }
    } catch (error) {
        errorToast(error.response.data.message);
        let errors = !Array.isArray(error?.response?.data?.response)
        ? convert2dErrorsArray(error?.response?.data?.response)
        : [error.response.data.message];
        yield put(ACTIONS.getNotificationTypeListError(errors));
    }
}

function* updateNotificationTypeBanner({payload, keyToken, result}) {
    try {
        loadingToast("Processing..");
        
        const response = yield call(API.updateNotificationTypeBanner, payload, keyToken);

        if (response.status === 200) {
            yield put(ACTIONS.updateNotificationTypeBannerSuccess());
            removeToast();
            successToast("Successfully Updated.");
            result(true);
        }
    } catch (error) {
        removeToast();
        errorToast(error?.response?.data?.message);
        yield put(ACTIONS.updateNotificationTypeBannerError());
        result(false);
    }
}

function* getUserNotificationList({ payload, keyToken }) {
    try {
        const response = yield call(API.getUserNotificationList, payload, keyToken);

        if (response.status === 200) {
            yield put(ACTIONS.getUserNotificationListSuccess(response.data.response));
        }
    } catch (error) {
        errorToast(error.response.data.message);
        let errors = !Array.isArray(error?.response?.data?.response)
        ? convert2dErrorsArray(error?.response?.data?.response)
        : [error.response.data.message];
        yield put(ACTIONS.getUserNotificationListError(errors));
    }
}


function* watchSendAnnouncement() {
    yield takeLatest(TYPES.SEND_ANNOUNCEMENT, sendAnnouncement);
}

function* watchGetMessageList() {
    yield takeLatest(TYPES.GET_MESSAGE, getMessageList);
}

function* watchUpdateMessageStatus() {
    yield takeLatest(TYPES.UPDATE_MESSAGE_STATUS, updateMessageStatus);
}

function* watchGetMessageTypes() {
    yield takeLatest(TYPES.GET_MESSAGE_TYPES, getMessageTypes);
}

function* watchCreateMessage() {
    yield takeLatest(TYPES.CREATE_MESSAGE, createMessage);
}

function* watchUpdateMessageInformation() {
    yield takeLatest(TYPES.UPDATE_MESSAGE_INFORMATION, updateMessageInformation);
}

function* watchUpdateMessageBanner() {
    yield takeLatest(TYPES.UPDATE_MESSAGE_BANNER, updateMessageBanner);
}

function* watchGetNotificationTypeList() {
    yield takeLatest(TYPES.GET_NOTIFICATION_TYPE_LIST, getNotificationTypeList);
}

function* watchUpdateNotificationTypeBanner() {
    yield takeLatest(TYPES.UPDATE_NOTIFICATION_TYPE_BANNER, updateNotificationTypeBanner);
}

function* watchGetUserNotificationList() {
    yield takeLatest(TYPES.GET_USER_NOTIFICATION_LIST, getUserNotificationList);
}



function* agentSagas() {
    yield all([
        fork(watchSendAnnouncement),
        fork(watchGetMessageList),
        fork(watchUpdateMessageStatus),
        fork(watchGetMessageTypes),
        fork(watchCreateMessage),
        fork(watchUpdateMessageInformation),
        fork(watchUpdateMessageBanner),
        fork(watchGetNotificationTypeList),
        fork(watchUpdateNotificationTypeBanner),
        fork(watchGetUserNotificationList),        
    ]);
}

export default agentSagas;
