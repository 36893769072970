import { axiosInstance } from "./index";

export const roleList = async (data) => {
    return await axiosInstance.get(`/bo/admin-role/get-paginated`, {
        params: data,
    });
};

export const roleInfo = async (keyToken) => {
    return await axiosInstance.get(
        `/bo/admin-role/get-by-key-token/${keyToken}`
    );
};

export const updateRoleName = async (data, keyToken) => {
    return await axiosInstance.put(`/bo/admin-role/update/${keyToken}`, data);
};

export const updateRolePermission = async (data) => {
    return await axiosInstance.put(
        `/bo/admin-role/update-by-module-permission`,
        data
    );
};

export const getPermissionData = async () => {
    return await axiosInstance.get(`/bo/module/get-all`);
};

export const adminList = async (data) => {
    return await axiosInstance.get(`/bo/user/get-paginated/${data.key_token}`, {
        params: data,
    });
};

export const adminProfile = async (accountNo) => {
    return await axiosInstance.get(`bo/user/get-profile/${accountNo}`);
};

export const updateAdmin = async (data, keyToken) => {
    return await axiosInstance.put(`bo/user/update-profile/${keyToken}`, data);
};

export const updateAdminRole = async (data, keyToken) => {
    return await axiosInstance.put(
        `/bo/user/update-user-admin-role/${keyToken}`,
        data
    );
};

export const createAdmin = async (data) => {
    return await axiosInstance.post(`/bo/user/create`, data);
};

export const createRole = async (data) => {
    return await axiosInstance.post(`/bo/admin-role/create`, data);
};

export const directWithdrawal = async (data) => {
    return await axiosInstance.post(`/bo/direct-withdrawal`, data);
};

export const getDirectWithdrawals = async (data) => {
    return await axiosInstance.get(`/bo/direct-withdrawal/get-paginated`, {
        params: data,
    });
};
