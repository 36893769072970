export const GET_SPORT_TYPE_LIST = "GET_SPORT_TYPE_LIST";
export const SET_SPORT_TYPE_LIST = "SET_SPORT_TYPE_LIST";
export const SET_SPORT_TYPE_LIST_ERROR = "SET_SPORT_TYPE_LIST_ERROR";

export const UPDATE_SPORT_TYPE_STATUS = "UPDATE_SPORT_TYPE_STATUS";
export const UPDATE_SPORT_TYPE_STATUS_SUCCESS = "UPDATE_SPORT_TYPE_STATUS_SUCCESS";
export const UPDATE_SPORT_TYPE_STATUS_ERROR = "UPDATE_SPORT_TYPE_STATUS_ERROR";

export const UPDATE_SPORT_TYPE_USE_CHILD_FILTER = "UPDATE_SPORT_TYPE_USE_CHILD_FILTER";
export const UPDATE_SPORT_TYPE_USE_CHILD_FILTER_SUCCESS = "UPDATE_SPORT_TYPE_USE_CHILD_FILTER_SUCCESS";
export const UPDATE_SPORT_TYPE_USE_CHILD_FILTER_ERROR = "UPDATE_SPORT_TYPE_USE_CHILD_FILTER_ERROR";

export const GET_LEAGUE_TYPE_LIST = "GET_LEAGUE_TYPE_LIST";
export const SET_LEAGUE_TYPE_LIST = "SET_LEAGUE_TYPE_LIST";
export const SET_LEAGUE_TYPE_LIST_ERROR = "SET_LEAGUE_TYPE_LIST_ERROR";

export const UPDATE_LEAGUE_TYPE_STATUS = "UPDATE_LEAGUE_TYPE_STATUS";
export const UPDATE_LEAGUE_TYPE_STATUS_SUCCESS = "UPDATE_LEAGUE_TYPE_STATUS_SUCCESS";
export const UPDATE_LEAGUE_TYPE_STATUS_ERROR = "UPDATE_LEAGUE_TYPE_STATUS_ERROR";

export const GET_GAME_TYPE_LIST = "GET_GAME_TYPE_LIST";
export const SET_GAME_TYPE_LIST = "SET_GAME_TYPE_LIST";
export const SET_GAME_TYPE_LIST_ERROR = "SET_GAME_TYPE_LIST_ERROR";

export const UPDATE_GAME_TYPE_STATUS = "UPDATE_GAME_TYPE_STATUS";
export const UPDATE_GAME_TYPE_STATUS_SUCCESS = "UPDATE_GAME_TYPE_STATUS_SUCCESS";
export const UPDATE_GAME_TYPE_STATUS_ERROR = "UPDATE_GAME_TYPE_STATUS_ERROR";

export const GET_TEAM_LIST = "GET_TEAM_LIST";
export const SET_TEAM_LIST = "SET_TEAM_LIST";
export const SET_TEAM_LIST_ERROR = "SET_TEAM_LIST_ERROR";

export const UPLOAD_TEAM_PHOTO = "UPLOAD_TEAM_PHOTO";
export const UPLOAD_TEAM_PHOTO_SUCCESS = "UPLOAD_TEAM_PHOTO_SUCCESS";
export const UPLOAD_TEAM_PHOTO_ERROR = "UPLOAD_TEAM_PHOTO_ERROR";

export const GET_HOT_MATCH_LIST = "GET_HOT_MATCH_LIST";
export const SET_HOT_MATCH_LIST = "SET_HOT_MATCH_LIST";
export const SET_HOT_MATCH_LIST_ERROR = "SET_HOT_MATCH_LIST_ERROR";

export const UPDATE_HOT_MATCH_POST_STATUS = "UPDATE_HOT_MATCH_POST_STATUS";
export const UPDATE_HOT_MATCH_POST_STATUS_SUCCESS = "UPDATE_HOT_MATCH_POST_STATUS_SUCCESS";
export const UPDATE_HOT_MATCH_POST_STATUS_ERROR = "UPDATE_HOT_MATCH_POST_STATUS_ERROR";

export const HOT_MATCH_MANUAL_PULL = "HOT_MATCH_MANUAL_PULL";
export const HOT_MATCH_MANUAL_PULL_SUCCESS = "HOT_MATCH_MANUAL_PULL_SUCCESS";
export const HOT_MATCH_MANUAL_PULL_ERROR = "HOT_MATCH_MANUAL_PULL_ERROR";