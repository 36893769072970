import React from "react";
import TextPlaceholder from "../TextPlaceholder";

const DisplayText = ({ label, item, col, isLoading }) => {
    return (
        <div className="text-muted">
            {label && <h5 className="fs-14">{label} :</h5>}
            {typeof item === "string" ? 
                <p>
                    <TextPlaceholder text={item} col={col} isLoading={isLoading} />
                </p> 
            : 
                <TextPlaceholder text={item} col={col} isLoading={isLoading} />
            }
            
        </div>
    );
};

export default DisplayText;
