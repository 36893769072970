import { Button, Select } from "@/components/atoms";
import { commissionTransferSchedules } from "@/constants/users";
import { processCommission } from "@/store/actions";
import { useFormik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button as ButtonComponent,
    Col,
    Form,
    FormGroup,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
} from "reactstrap";

const ProcessCommissionModal = ({
    isOpen,
    setIsOpen,
    initialize
}) => {
    const dispatch = useDispatch();
    const { isProcessCommissionLoading } = useSelector((state) => state.transaction);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            schedule_type: "weekly",
        },
        onSubmit: (values) => dispatch(processCommission(values, initialize)),
    });

    return (
        <Modal
            id="PinCodeModal"
            isOpen={isOpen}
            toggle={() => setIsOpen(!isOpen)}
            centered
        >
            <ModalHeader>Process Commission</ModalHeader>
            <ModalBody>
                <Form
                    className="needs-validation"
                    onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}
                >
                    <Row>
                        <Col md="12">
                            <FormGroup className="mb-3">
                                <Select
                                    label="Schedule Type"
                                    name="schedule_type"
                                    isClearable={false}
                                    value={validation.values.schedule_type}
                                    onChange={(e) => validation.setFieldValue('schedule_type', e.target.value)}
                                    options={commissionTransferSchedules}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-end">
                            <ButtonComponent
                                color="light"
                                onClick={() => setIsOpen(!isOpen)}
                                className="me-2"
                            >
                                Close
                            </ButtonComponent>
                            <Button
                                color="success"
                                type="submit"
                                text="Submit"
                                isLoading={isProcessCommissionLoading}
                            />
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
        </Modal>
    );
};

export default ProcessCommissionModal;
