import { AmountFormat, StatusBadge, Title } from "@/components/atoms";
import BreadCrumb from "@/components/common/BreadCrumb";
import { UpdateReferralCodeModal } from "@/components/molecules";
import { Routes, getPath, getPathTitle } from "@/constants";
import { getSetting } from "@/hooks";
import { UserDefaultPhoto } from "@/resources/images";
import {
    copyText,
    defaultDateFormat,
    defaultDateTimeFormat,
    getAgentReferralLink,
} from "@/utils";
import classnames from "classnames";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Input,
    Label,
    Nav,
    NavItem,
    NavLink,
    Row,
} from "reactstrap";

import { ReferralLinksModal } from "@/components/molecules";
import { userRole } from "@/hooks";

const Layout = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const { user: profile } = useSelector((state) => state.user);
    const isAdmin =
        profile.role.slug === "super-admin" || profile.role.slug === "admin";
    const isAgent =
        profile.role.slug === "master-agent" || profile.role.slug === "agent";
    const isOperator = profile.role.slug === "operator";

    const separateSportsBettingCommission =
        getSetting("separate_sports_betting_commission") === "1";

    const nagivate = useNavigate();
    const location = useLocation();

    const [updateReferralModalIsOpen, setUpdateReferralModalIsOpen] =
        useState(false);

    const { isSuperAdmin, isLicenseeAdmin } = userRole();

    const details = [
        {
            label: "Username",
            value: profile.name,
            isShow: true,
        },
        {
            label: "Referral Code",
            value: (
                <div>
                    {profile.referralCode}{" "}
                    {isAgent && (
                        <span
                            onClick={() => setUpdateReferralModalIsOpen(true)}
                            className="ri-pencil-line cursor-pointer text-success mr-2"
                        ></span>
                    )}
                    <span
                        onClick={() => {
                            copyText(profile.referralCode);
                        }}
                        className="ri-file-copy-line cursor-pointer text-primary"
                    ></span>
                </div>
            ),
            isShow: isAgent,
        },
        {
            label: "Date Joined",
            value: defaultDateFormat(profile.createdAt),
            isShow: !isAdmin,
        },
        {
            label: "Date Verified",
            value: defaultDateFormat(profile.verifiedAt),
            isShow: !isAdmin,
        },
        {
            label: "Last Login",
            value: profile.lastLogin
                ? defaultDateTimeFormat(profile.lastLogin?.created_at)
                : "-",
            isShow: true,
        },
    ];

    const balances = useMemo(() => {
        return [
            {
                label: process.env.REACT_APP_BCF_LABEL,
                value: profile?.balance?.fiat?.php?.bcf ? (
                    <AmountFormat amount={profile?.balance?.fiat?.php?.bcf} />
                ) : (
                    "-"
                ),
                isShow: true,
            },
            {
                label: "PHP",
                value: profile?.balance?.fiat?.php?.merchant ? (
                    <AmountFormat
                        amount={profile?.balance?.fiat?.php?.merchant}
                    />
                ) : (
                    "-"
                ),
                isShow: true,
            },
            ...process.env.REACT_APP_LOTTO_ENABLED === "true" ? [
                {
                    label: `${process.env.REACT_APP_BCF_LABEL} (Lotto)`,
                    value: profile?.balance?.fiat?.php?.bcf_lotto ? (
                        <AmountFormat amount={profile?.balance?.fiat?.php?.bcf_lotto} />
                    ) : (
                        "-"
                    ),
                    isShow: isAgent,
                },
                {
                    label: `${process.env.REACT_APP_DEFAULT_CURRENCY} (Lotto)`,
                    value: profile?.balance?.fiat?.php?.merchant_lotto ? (
                        <AmountFormat
                            amount={profile?.balance?.fiat?.php?.merchant_lotto}
                        />
                    ) : (
                        "-"
                    ),
                    isShow: isAgent,
                },
            ] : [],
            {
                value: (
                    <div>
                        <h4 className="card-title text-center mb-0 flex-grow-1">
                            Commission
                        </h4>
                    </div>
                ),
                isShow: isAgent,
            },
            {
                label: `${process.env.REACT_APP_BCF_LABEL} ${
                    profile.separateSportsBettingCommissionBcf ||
                    separateSportsBettingCommission
                        ? "(E-Games/Slots)"
                        : ""
                }`,
                value: profile?.balance?.fiat?.php?.commission_bcf ? (
                    <AmountFormat
                        amount={profile?.balance?.fiat?.php?.commission_bcf}
                    />
                ) : (
                    "-"
                ),
                isShow: isAgent,
            },
            {
                label: `PHP ${
                    profile.separateSportsBettingCommissionMerchant ||
                    separateSportsBettingCommission
                        ? "(E-Games/Slots)"
                        : ""
                }`,
                value: profile?.balance?.fiat?.php?.commission_merchant ? (
                    <AmountFormat
                        amount={
                            profile?.balance?.fiat?.php?.commission_merchant
                        }
                    />
                ) : (
                    "-"
                ),
                isShow: isAgent,
            },
            {
                label: `${process.env.REACT_APP_BCF_LABEL} (Sports/E-Sports/Live Casino)`,
                value: profile?.balance?.fiat?.php?.commission_bcf_sports ? (
                    <AmountFormat
                        amount={
                            profile?.balance?.fiat?.php?.commission_bcf_sports
                        }
                    />
                ) : (
                    "-"
                ),
                isShow:
                    isAgent &&
                    (separateSportsBettingCommission ||
                        profile.separateSportsBettingCommissionBcf),
            },
            {
                label: "PHP (Sports/E-Sports/Live Casino)",
                value: profile?.balance?.fiat?.php
                    ?.commission_merchant_sports ? (
                    <AmountFormat
                        amount={
                            profile?.balance?.fiat?.php
                                ?.commission_merchant_sports
                        }
                    />
                ) : (
                    "-"
                ),
                isShow:
                    isAgent &&
                    (separateSportsBettingCommission ||
                        profile.separateSportsBettingCommissionMerchant),
            },
            ...process.env.REACT_APP_LOTTO_ENABLED === "true" ? [
                {
                    label: `${process.env.REACT_APP_BCF_LABEL} (Lotto)`,
                    value: profile?.balance?.fiat?.php?.commission_bcf_lotto ? (
                        <AmountFormat
                            amount={
                                profile?.balance?.fiat?.php?.commission_bcf_lotto
                            }
                        />
                    ) : (
                        "-"
                    ),
                    isShow: isAgent,
                },
                {
                    label: `${process.env.REACT_APP_DEFAULT_CURRENCY} (Lotto)`,
                    value: profile?.balance?.fiat?.php
                        ?.commission_merchant_lotto ? (
                        <AmountFormat
                            amount={
                                profile?.balance?.fiat?.php
                                    ?.commission_merchant_lotto
                            }
                        />
                    ) : (
                        "-"
                    ),
                    isShow: isAgent,
                },
            ] : [],
            {
                value: (
                    <div>
                        <h4 className="card-title text-center mb-0 flex-grow-1">
                            Pending Commission
                        </h4>
                    </div>
                ),
                isShow: isAgent,
            },
            {
                label: `${process.env.REACT_APP_BCF_LABEL} ${
                    separateSportsBettingCommission ||
                    profile.separateSportsBettingCommissionBcf
                        ? "(E-Games/Slots)"
                        : ""
                }`,
                value: profile?.balance?.fiat?.php?.pending_commission_bcf ? (
                    <AmountFormat
                        amount={
                            profile?.balance?.fiat?.php?.pending_commission_bcf
                        }
                    />
                ) : (
                    "-"
                ),
                isShow: isAgent,
            },
            {
                label: `PHP ${
                    separateSportsBettingCommission ||
                    profile.separateSportsBettingCommissionMerchant
                        ? "(E-Games/Slots)"
                        : ""
                }`,
                value: profile?.balance?.fiat?.php
                    ?.pending_commission_merchant ? (
                    <AmountFormat
                        amount={
                            profile?.balance?.fiat?.php
                                ?.pending_commission_merchant
                        }
                    />
                ) : (
                    "-"
                ),
                isShow: isAgent,
            },
            {
                label: `${process.env.REACT_APP_BCF_LABEL} (Sports/E-Sports/Live Casino)`,
                value: profile?.balance?.fiat?.php
                    ?.pending_commission_bcf_sports ? (
                    <AmountFormat
                        amount={
                            profile?.balance?.fiat?.php
                                ?.pending_commission_bcf_sports
                        }
                    />
                ) : (
                    "-"
                ),
                isShow:
                    isAgent &&
                    (separateSportsBettingCommission ||
                        profile.separateSportsBettingCommissionBcf),
            },
            {
                label: "PHP (Sports/E-Sports/Live Casino)",
                value: profile?.balance?.fiat?.php
                    ?.pending_commission_merchant_sports ? (
                    <AmountFormat
                        amount={
                            profile?.balance?.fiat?.php
                                ?.pending_commission_merchant_sports
                        }
                    />
                ) : (
                    "-"
                ),
                isShow:
                    isAgent &&
                    (separateSportsBettingCommission ||
                        profile.separateSportsBettingCommissionMerchant),
            },
            ...process.env.REACT_APP_LOTTO_ENABLED === "true" ? [
                {
                    label: `${process.env.REACT_APP_BCF_LABEL} (Lotto)`,
                    value: profile?.balance?.fiat?.php
                        ?.pending_commission_bcf_lotto ? (
                        <AmountFormat
                            amount={
                                profile?.balance?.fiat?.php
                                    ?.pending_commission_bcf_lotto
                            }
                        />
                    ) : (
                        "-"
                    ),
                    isShow: isAgent
                },
                {
                    label: `${process.env.REACT_APP_DEFAULT_CURRENCY} (Lotto)`,
                    value: profile?.balance?.fiat?.php
                        ?.pending_commission_merchant_lotto ? (
                        <AmountFormat
                            amount={
                                profile?.balance?.fiat?.php
                                    ?.pending_commission_merchant_lotto
                            }
                        />
                    ) : (
                        "-"
                    ),
                    isShow: isAgent
                },
            ] : []
        ];
    }, [isAgent, separateSportsBettingCommission, profile]);

    const nav = [
        {
            label: "Personal Details",
            path: getPath(Routes.ACCOUNT_PROFILE),
            isShow: true,
        },
        {
            label: "Commissions Settings",
            path: getPath(Routes.ACCOUNT_COMMISSIONS_SETTINGS),
            isShow: isAgent,
        },
        {
            label: isAdmin ? "Transactions" : "Wallet",
            path: getPath(Routes.ACCOUNT_WALLET),
            isShow: true,
        },
        {
            label: "Direct Withdrawals",
            path: getPath(Routes.ACCOUNT_DIRECT_WITHDRAWAL),
            isShow: isSuperAdmin && !isLicenseeAdmin,
        },
        {
            label: "Commissions",
            path: getPath(Routes.ACCOUNT_COMMISSIONS),
            isShow: isAgent,
        },
        {
            label: "Request",
            path: getPath(Routes.ACCOUNT_REQUEST),
            isShow: !isOperator && !isAdmin,
        },
        {
            label: "Change Password",
            path: getPath(Routes.ACCOUNT_CHANGE_PASSWORD),
            isShow: true,
        },
    ];

    return (
        <React.Fragment>
            <Title title={getPathTitle(Routes.ACCOUNT_PROFILE)} />
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb
                        parent={{
                            isParent: true,
                            pageTitle: getPathTitle(Routes.ACCOUNT_PROFILE),
                            pageLink: getPath(Routes.ACCOUNT_PROFILE),
                        }}
                    />

                    {updateReferralModalIsOpen && isAgent && (
                        <UpdateReferralCodeModal
                            user={profile}
                            isOpen={updateReferralModalIsOpen}
                            setIsOpen={setUpdateReferralModalIsOpen}
                        />
                    )}
                    <Row>
                        <Col md={3}>
                            <Card>
                                <CardBody className="p-4">
                                    <div className="text-center">
                                        <div className="profile-user position-relative d-inline-block mx-auto  mb-4">
                                            <img
                                                src={UserDefaultPhoto}
                                                className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                                                alt="user-profile"
                                            />
                                            <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                                                <Input
                                                    id="profile-img-file-input"
                                                    type="file"
                                                    className="profile-img-file-input"
                                                />
                                                <Label
                                                    htmlFor="profile-img-file-input"
                                                    className="profile-photo-edit avatar-xs"
                                                >
                                                    <span className="avatar-title rounded-circle bg-light text-body">
                                                        <i className="ri-camera-fill"></i>
                                                    </span>
                                                </Label>
                                            </div>
                                        </div>
                                        <p className="text-muted mb-0">
                                            {profile.accountNo}
                                        </p>
                                        <h5 className="fs-16 mb-1">
                                            {profile.firstName}{" "}
                                            {profile.lastName}
                                        </h5>
                                        <p className="text-muted mb-0">
                                            {profile.role.name}
                                        </p>
                                        <StatusBadge status={profile.status} />
                                        {isAgent && (
                                            <div className="mt-4">
                                                {process.env.REACT_APP_ENV ===
                                                "production" ? (
                                                    <Button
                                                        color="primary"
                                                        className="m-0 w-100"
                                                        size="sm"
                                                        onClick={() =>
                                                            setIsOpen(true)
                                                        }
                                                    >
                                                        <div className="tw-flex tw-items-center tw-justify-center gap-1">
                                                            Referral Links
                                                            <span className="ri-links-line" />
                                                        </div>
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        color="primary"
                                                        className="m-0 w-100"
                                                        size="sm"
                                                        onClick={() => {
                                                            copyText(
                                                                getAgentReferralLink(
                                                                    profile.referralCode
                                                                )
                                                            );
                                                        }}
                                                    >
                                                        Copy Referral Link{" "}
                                                        <span className="ri-file-copy-line"></span>
                                                    </Button>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardHeader className="align-items-center d-flex">
                                    <h4 className="card-title text-center mb-0 flex-grow-1">
                                        User Details
                                    </h4>
                                </CardHeader>
                                <CardBody>
                                    <div className="table-card">
                                        <table className="table mb-0">
                                            <tbody>
                                                {details.map((item, key) => (
                                                    <tr
                                                        key={key}
                                                        className={
                                                            !item.isShow
                                                                ? "d-none"
                                                                : ""
                                                        }
                                                    >
                                                        <td className="fw-medium">
                                                            {item.label}
                                                        </td>
                                                        <td>{item.value}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardHeader className="align-items-center d-flex">
                                    <h4 className="card-title text-center mb-0 flex-grow-1">
                                        Balances
                                    </h4>
                                </CardHeader>
                                <CardBody>
                                    <div className="table-card">
                                        <table className="table mb-0">
                                            <tbody>
                                                {balances.map((item, key) => (
                                                    <tr
                                                        key={key}
                                                        className={
                                                            !item.isShow
                                                                ? "d-none"
                                                                : ""
                                                        }
                                                    >
                                                        {item?.label && (
                                                            <td className="fw-medium">
                                                                {item.label}
                                                            </td>
                                                        )}
                                                        <td
                                                            className="tw-text-right"
                                                            colSpan={
                                                                item?.label
                                                                    ? 1
                                                                    : 2
                                                            }
                                                        >
                                                            {item.value}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col md={9}>
                            <Card>
                                <CardHeader>
                                    <Nav
                                        className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                        role="tablist"
                                    >
                                        {nav.map((item, key) => {
                                            return (
                                                item.isShow && (
                                                    <NavItem key={key}>
                                                        <NavLink
                                                            className={classnames(
                                                                {
                                                                    active:
                                                                        item.path ===
                                                                        location.pathname,
                                                                }
                                                            )}
                                                            onClick={() => {
                                                                nagivate(
                                                                    item.path
                                                                );
                                                            }}
                                                            type="button"
                                                        >
                                                            <i className="far fa-user"></i>
                                                            {item.label}
                                                        </NavLink>
                                                    </NavItem>
                                                )
                                            );
                                        })}
                                    </Nav>
                                </CardHeader>
                                <CardBody className="p-4">{children}</CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            {process.env.REACT_APP_ENV === "production" && (
                <ReferralLinksModal
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    referralCode={profile.referralCode}
                />
            )}
        </React.Fragment>
    );
};

export default Layout;
