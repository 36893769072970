import { setUserData, userState } from "@/types/states";
import * as types from "./actionTypes";

const initialState = userState;

const user = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_USER_DATA:
        case types.GET_USER_COUNT_DATA:
        case types.UPDATE_USER_PROFILE:
        case types.UPDATE_USER_EMAIL:
        case types.UPDATE_USER_MOBILE_NO:
        case types.UPDATE_USER_USERNAME:
        case types.UPDATE_REFFERAL_CODE:
        case types.USER_CHANGE_PASSWORD:
        case types.USER_FORGOT_PASSWORD:
        case types.USER_RESET_PASSWORD:
        case types.USER_UPDATE_STATUS:
        case types.USER_RESET_PASSWORD_VALIDATE_TOKEN:
        case types.GET_ACTIVITY_LOG:
        case types.GET_COMMISSION:
        case types.DISABLE_WITHDRAWAL_FEE_MERCHANT:
            state = {
                ...state,
                isLoading: true,
                errors: userState.errors,
            };
            break;
        case types.LOGIN_USER:
            state = {
                ...state,
                isLoading: true,
                errors: userState.errors,
                user: {},
            };
            break;
        case types.LOGIN_USER_SUCCESS:
        case types.SET_USER_DATA:
            state = {
                ...state,
                user: setUserData(action),
                settingList: action.payload.settings,
                permissionList: action.payload.permissions?.modules,
                errors: userState.errors,
                userCount: userState.userCount,
                isLoading: false,
                isAuth: true,
            };
            break;
        case types.SET_ACTIVITY_LOG:
            state = {
                ...state,
                activityLogList: action.payload,
                errors: userState.errors,
                isLoading: false,
            };
            break;
        case types.SET_USER_COUNT_DATA:
            state = {
                ...state,
                userCount: action.payload,
                isLoading: false,
            };
            break;
        case types.SET_COMMISSION:
            state = {
                ...state,
                commissionList: action.payload,
                isLoading: false,
            };
            break;
        case types.SET_USER_DATA_ERROR:
            state = {
                errors: userState.errors,
                user: userState.user,
                isLoading: false,
                isAuth: false,
            };
            break;
        case types.UPDATE_USER_PROFILE_SUCCESS:
            state = {
                ...state,
                isLoading: false,
                user: setUserData(action),
                errors: userState.errors,
            };
            break;
        case types.UPDATE_REFFERAL_CODE_SUCCESS:
            state = {
                ...state,
                isLoading: false,
                user: {
                    ...state.user,
                    referralCode: action.payload,
                },
                updateReferralErrors: userState.updateReferralErrors,
            };
            break;
        case types.UPDATE_USER_PROFILE_ERROR:
            state = {
                ...state,
                isLoading: false,
                errors: action.payload,
            };
            break;
        case types.UPDATE_REFFERAL_CODE_ERROR:
            state = {
                ...state,
                isLoading: false,
                updateReferralErrors: action.payload,
            };
            break;
        case types.RESET_UPDATE_REFERRAL_CODE_ERRORS:
            state = {
                ...state,
                updateReferralErrors: userState.updateReferralErrors,
            };
            break;
        case types.USER_CHANGE_PASSWORD_SUCCESS:
        case types.USER_FORGOT_PASSWORD_SUCCESS:
        case types.USER_RESET_PASSWORD_SUCCESS:
        case types.USER_UPDATE_STATUS_SUCCESS:
        case types.UPDATE_USER_MOBILE_NO_SUCCESS:
        case types.USER_RESET_PASSWORD_VALIDATE_TOKEN_SUCCESS:
        case types.RESET_USER_ERRORS:
            state = {
                ...state,
                isLoading: false,
                errors: userState.errors,
            };
            break;
        case types.UPDATE_USER_EMAIL_SUCCESS:
            state = {
                ...state,
                isLoading: false,
                errors: userState.errors,
            };
            break;
        case types.LOGIN_USER_ERROR:
        case types.SET_USER_COUNT_DATA_ERROR:
        case types.UPDATE_USER_EMAIL_ERROR:
        case types.UPDATE_USER_MOBILE_NO_ERROR:
        case types.UPDATE_USER_USERNAME_ERROR:
        case types.USER_CHANGE_PASSWORD_ERROR:
        case types.USER_FORGOT_PASSWORD_ERROR:
        case types.USER_RESET_PASSWORD_ERROR:
        case types.USER_UPDATE_STATUS_ERROR:
        case types.USER_RESET_PASSWORD_VALIDATE_TOKEN_ERROR:
        case types.SET_ACTIVITY_LOG_ERROR:
        case types.SET_COMMISSION_ERROR:
            state = {
                ...state,
                isLoading: false,
                errors: action.payload,
            };
            break;
        case types.UPDATE_USER_USERNAME_SUCCESS:
            state = {
                ...state,
                user: {
                    ...state.user,
                    name: action.payload.username,
                },
                isLoading: false,
                errors: userState.errors,
            };
            break;
        case types.RESET_USER_DATA:
            state = userState;
            break;
        case types.GET_USER_COMMISSIONS:
            state = {
                ...state,
                isLoading: true,
            };
            break;
        case types.GET_USER_COMMISSIONS_SUCCESS:
            state = {
                ...state,
                isLoading: false,
                userCommissionList: action.payload,
            };
            break;
        case types.GET_USER_COMMISSIONS_ERROR:
        case types.DISABLE_WITHDRAWAL_FEE_MERCHANT_SUCCESS:
        case types.DISABLE_WITHDRAWAL_FEE_MERCHANT_ERROR:
            state = {
                ...state,
                isLoading: false,
            };
            break;
        case types.GET_USER_DEVICE_DETAILS_SUCCESS:
            state = {
                ...state,
                deviceDetails: action.payload,
            };
            break;
        case types.GET_OPERATOR:
            state = {
                ...state,
                isGetOperatorLoading: true,
            };
            break;
        case types.GET_OPERATOR_SUCCESS:
            state = {
                ...state,
                isGetOperatorLoading: false,
                operatorList: action?.payload ?? []
            };
            break;
        case types.GET_OPERATOR_ERROR:
            state = {
                ...state,
                isGetOperatorLoading: false,
                getOperatorErrors: action?.payload ?? []
            };
            break;
        
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default user;
