import { copyText, gamelobbyLinks } from "@/utils";
import React from "react";
import {
    Button,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
} from "reactstrap";

const ReferralLinksModal = ({ isOpen, setIsOpen, referralCode = "" }) => {
    return (
        <Modal
            id="ReferralLinksModal"
            isOpen={isOpen}
            toggle={() => setIsOpen(!isOpen)}
        >
            <ModalHeader toggle={() => setIsOpen(!isOpen)}>
                Referral Links <span className="ri-links-line" />
            </ModalHeader>
            <ModalBody>
                <div className="tw-space-y-3">
                    {gamelobbyLinks.map((item, index) => (
                        <div key={index}>
                            <Label>{item.name}</Label>
                            <div className="input-group">
                                <Input
                                    type="text"
                                    className="form-control"
                                    value={
                                        item.link +
                                        `/?referralCode=${referralCode}`
                                    }
                                    disabled
                                />
                                <Button
                                    color="primary"
                                    onClick={() =>
                                        copyText(
                                            item.link +
                                                `/?referralCode=${referralCode}`
                                        )
                                    }
                                >
                                    <span className="ri-file-copy-line" />
                                </Button>
                            </div>
                        </div>
                    ))}
                </div>
            </ModalBody>
        </Modal>
    );
};

export default ReferralLinksModal;
