  import { Button, ErrorAlert, InputReactSelect, FileInputPreview, } from "@/components/atoms";
import { addFeaturedGameValidation } from "@/constants";
import { useFormik } from "formik";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button as ButtonComponent,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { addFeaturedGame, resetFeaturedGameError } from "@/store/actions";

const Index = ({ isOpen, setIsOpen, featuredGameTypes, game }) => {
  const isMounted = useRef(false);
  const dispatch = useDispatch();
  const { isFeaturedGameLoading, isAddFeaturedGameSuccess, errors } =
    useSelector((state) => state.game);
  const [selectedFeatureType, setSelectedFeatureType] = useState(null);
  const [fieldErrors, setFieldErrors] = useState([]);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      game: game.key_token,
      featured_type: "",
      featured_banner: "",
    },
    validationSchema: addFeaturedGameValidation({
      featuredTypeLabel: selectedFeatureType?.label
    }),
    onSubmit: (values) => {
      dispatch(addFeaturedGame(values));
    },
  });

  useEffect(() => {
    if (isMounted.current) {
      if (isAddFeaturedGameSuccess) {
        setIsOpen(false);
      }
    }
    isMounted.current = true;
  }, [isAddFeaturedGameSuccess, setIsOpen]);

  useEffect(() => {
    if (errors.length > 0) {
      setFieldErrors(errors);
      dispatch(resetFeaturedGameError());
    }
  }, [errors, dispatch]);

  return (
    <Modal id="fundModal" isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
      <ModalHeader>
        <div className="modal-title">Add Featured Game</div>
      </ModalHeader>
      <ModalBody>
        <Form
          className="needs-validation"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <ErrorAlert errors={fieldErrors} validation={validation} />
          <Row className="mb-3">
            <Col md={12}>
              <FormGroup>
                <Label htmlFor="game_name">Game Name</Label>
                <Input value={game.game_name} disabled />
              </FormGroup>
            </Col>
            <Col md={12}>
              <FormGroup>
                <Label htmlFor="game_name">Game Provider</Label>
                <Input value={game.game_provider_name} disabled />
              </FormGroup>
            </Col>
            <Col md={12}>
              <FormGroup>
                <Label htmlFor="game_name">Game Type</Label>
                <Input value={game.type} disabled />
              </FormGroup>
            </Col>
            <Col md={12}>
              <FormGroup>
                <InputReactSelect
                  label="Featured Type"
                  name="featured_type"
                  options={featuredGameTypes.map((value) => ({
                    label: value.name,
                    value: value.key_token,
                  }))}
                  onChange={async (option) => {
                    await setSelectedFeatureType(option);
                    await validation.setFieldValue("featured_type", option.value);
                    // await validation.setFieldValue("featured_type_label", option.label);
                  }}
                  value={selectedFeatureType}
                  validation={validation}
                  validateOnBlur={false}
                />
              </FormGroup>
            </Col>

            {selectedFeatureType?.label === "Featured" && (
              <Col md={12}>
                <FormGroup>
                  <Label htmlFor="featured_banner">Featured Banner</Label>
                  <FileInputPreview
                    name="featured_banner"
                    validation={validation}
                    onupdatefiles={(file) =>
                      validation.setFieldValue(
                        "featured_banner",
                        file?.[0]?.file ?? null
                      )
                    }
                  />
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            <Col className="text-end">
              <ButtonComponent
                color="light"
                onClick={() => setIsOpen(!isOpen)}
                className="me-2"
              >
                Close
              </ButtonComponent>
              <Button
                color="success"
                type="submit"
                text="Submit"
                isLoading={isFeaturedGameLoading}
              />
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default Index;
