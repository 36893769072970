import { useEffect } from "react";
import { useSelector } from "react-redux";

const NonAuthLayout = ({ children }) => {
    const { layoutModeType } = useSelector((state) => ({
        layoutModeType: state.layout.layoutModeType,
    }));

    useEffect(() => {
        if (layoutModeType === "dark") {
            document.body.setAttribute("data-layout-mode", "dark");
        } else {
            document.body.setAttribute("data-layout-mode", "light");
        }
    }, [layoutModeType]);
    return <div>{children}</div>;
};

export default NonAuthLayout;
