import {
    AmountFormat,
    StatusBadge,
    Table,
    TextLink,
    CounterWidget,
} from "@/components/atoms";
import { getPath, Routes } from "@/constants";
import { getRequestData } from "@/store/actions";
import { defaultDateTimeFormat, capitalizeFirstLetter } from "@/utils";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import { walletTransactionCounter } from "@/constants/users";

const RequestTab = () => {
    const dispatch = useDispatch();
    const { list, isLoading } = useSelector((state) => state.request);
    const { accountNo } = useParams();

    const transactionCounter = walletTransactionCounter(list);

    const [requestType, setRequestType] = useState("deposit");

    const [search, setSearch] = useState("");
    const [sortBy, setSortBy] = useState({
        field: "",
        type: "asc",
    });

    const [data, setData] = useState({
        account_no: accountNo,
        per_page: 10,
        page: 1,
        search: "",
        sort_by:
            sortBy.field && sortBy.type ? `${sortBy.field} ${sortBy.type}` : "",
    });

    const tableTheadData = [
        {
            label: "Request No.",
            field: "transaction_request_no",
            sortable: true,
            render: (item) => (
                <TextLink
                    text={item.transaction_request_no}
                    path={getPath(
                        requestType === "deposit"
                            ? Routes.DEPOSIT_REQUEST_VIEW
                            : Routes.WITHDRAWAL_REQUEST_VIEW
                    ).replace(
                        ":transactionRequestNo",
                        item?.transaction_request_no
                    )}
                />
            ),
        },
        {
            label: "Balance Category",
            field: "balance_category",
            sortable: false,
            render: (item) => capitalizeFirstLetter(item.balance_category),
        },
        {
            label: "Amount",
            field: "amount",
            sortable: true,
            render: (item) => <AmountFormat amount={item.amount} />,
        },
        {
            label: "Date",
            field: "created_at",
            sortable: true,
            render: (item) => defaultDateTimeFormat(item.created_at),
        },
        {
            label: "Status",
            field: "status",
            sortable: true,
            render: (item) => (
                <StatusBadge status={item.status} type="request" />
            ),
        },
        {
            label: "Action",
            field: "action",
            sortable: false,
            render: (item) => (
                <Link
                    to={getPath(
                        requestType === "deposit"
                            ? Routes.DEPOSIT_REQUEST_VIEW
                            : Routes.WITHDRAWAL_REQUEST_VIEW
                    ).replace(
                        ":transactionRequestNo",
                        item?.transaction_request_no
                    )}
                    className="link-primary">
                    <Button color="success" size="sm">
                        <i className="ri-eye-line"></i>
                    </Button>
                </Link>
            ),
        },
    ];

    const changeRequestType = (type) => {
        setRequestType(type);
        setSearch("");
        setData({
            ...data,
            search: "",
            page: 1,
        });
    };

    useEffect(() => {
        dispatch(getRequestData(data, requestType));
    }, [dispatch, data, requestType]);

    return (
        <>
            <Row className="mb-3">
                <Col>
                    <Button
                        color={requestType === "deposit" ? "primary" : "light"}
                        onClick={() => changeRequestType("deposit")}
                        className="me-2">
                        Deposit Request
                    </Button>
                    <Button
                        color={
                            requestType === "withdrawal" ? "primary" : "light"
                        }
                        onClick={() => changeRequestType("withdrawal")}>
                        Withdrawal Request
                    </Button>
                </Col>
            </Row>
            <Row>
                {transactionCounter.map((item, key) => (
                    <Col key={key}>
                        <CounterWidget withBorder={true} item={item} icon="₱" />
                    </Col>
                ))}
            </Row>
            <Row>
                <Col xs={12}>
                    <Table
                        search={search}
                        handlerSearch={setSearch}
                        sortBy={sortBy}
                        handlerSortBy={setSortBy}
                        thead={tableTheadData}
                        handlerChangeData={setData}
                        data={data}
                        collection={list}
                        isLoading={isLoading}
                        size="sm"
                    />
                </Col>
            </Row>
        </>
    );
};

export default RequestTab;
