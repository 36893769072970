import {
    loadingToast,
    successToast,
    removeToast,
    errorToast,
} from "@/components/constants/toast";
import * as API from "@/network/apis/player";
import { convert2dErrorsArray } from "@/utils";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import * as ACTIONS from "./actions";
import * as TYPES from "./actionTypes";

function* playerList({ type, payload }) {
    // yield delay(500);
    try {
        const response = yield call(API.playerList, payload);

        if (response.status === 200) {
            switch (type) {
                case "GET_PLAYER_SELECTION_LIST":
                    yield put(
                        ACTIONS.onGetPlayerSelectionListSuccess(response.data)
                    );
                    break;
                default:
                    yield put(ACTIONS.setPlayerList(response.data));
                    break;
            }
        }
    } catch (error) {
        console.warn(error.response);
        switch (type) {
            case "GET_PLAYER_SELECTION_LIST":
                yield put(ACTIONS.onGetPlayerSelectionListFailed());
                break;
            default:
                yield put(ACTIONS.setPlayerListError());
                break;
        }
    }
}

function* playerProfile({ payload }) {
    try {
        const response = yield call(API.playerProfile, payload);

        if (response.status === 200) {
            let data = response.data.response;
            yield call(getUserProfileDocumentsAsync, {
                keyToken: data.user_profile.key_token,
            });
            yield put(ACTIONS.setPlayerProfile(data));
        }
    } catch (error) {
        console.warn(error.response);
        yield put(ACTIONS.setPlayerProfileError());
    }
}

function* createPlayer({ payload, validation }) {
    try {
        const response = yield call(API.createPlayer, payload);
        if (response.status === 200) {
            yield put(ACTIONS.createPlayerSuccess(response.data));
            validation.resetForm();
            successToast("Successfully Added.");
        }
    } catch (error) {
        document.documentElement.scrollTop = 0;
        let errors = !Array.isArray(error?.response?.data?.response)
            ? convert2dErrorsArray(error?.response?.data?.response)
            : [error.response.data.message];
        yield put(ACTIONS.createPlayerError(errors));
    }
}

function* updatePlayer({ payload, keyToken }) {
    try {
        const response = yield call(API.updatePlayer, payload, keyToken);

        if (response.status === 200) {
            yield put(ACTIONS.updatePlayerSuccess(response.data.response));
            successToast("Successfully Updated.");
        }
    } catch (error) {
        let errors = !Array.isArray(error?.response?.data?.response)
            ? convert2dErrorsArray(error?.response?.data?.response)
            : [error.response.data.message];
        yield put(ACTIONS.updatePlayerError(errors));
    }
}

function* updateIdVerificationStatus({ payload, status, keyToken, type }) {
    try {
        loadingToast("Processing...");
        const response = yield call(
            API.updateIdVerificationStatus,
            payload,
            status,
            keyToken
        );

        if (response.status === 200) {
            switch (type) {
                case "UPDATE_FULLY_VERIFICATION_STATUS":
                    yield put(
                        ACTIONS.onUpdateFullyVerificationSuccess(
                            response.data.response.user
                        )
                    );
                    break;
                default:
                    yield put(
                        ACTIONS.updateIdVerificationStatusSuccess(
                            response.data.response.user
                        )
                    );
                    break;
            }
            removeToast();
            successToast("Successfully Updated.");
        }
    } catch (error) {
        let errors = !Array.isArray(error?.response?.data?.response)
            ? convert2dErrorsArray(error?.response?.data?.response)
            : [error.response.data.message];
        switch (type) {
            case "UPDATE_FULLY_VERIFICATION_STATUS":
                yield put(ACTIONS.onUpdateFullyVerificationFailed(errors));
                break;
            default:
                yield put(ACTIONS.updateIdVerificationStatusError(errors));
                break;
        }
        errorToast(error.response.data.message);
    }
}

function* getVerificationId({ keyToken }) {
    try {
        const response = yield call(API.getVerificationId, keyToken);

        if (response.status === 200) {
            yield put(ACTIONS.getVerificationIdSuccess(response.data.response));
        }
    } catch (error) {
        let errors = !Array.isArray(error?.response?.data?.response)
            ? convert2dErrorsArray(error?.response?.data?.response)
            : [error.response.data.message];
        yield put(ACTIONS.getVerificationIdError(errors));
    }
}

function* getUserProfileDocumentsAsync({ keyToken }) {
    try {
        const response = yield call(API.getUserProfileDocuments, keyToken);

        if (response.status === 200) {
            yield put(
                ACTIONS.onGetUserProfileDocumentsSuccess(response.data.response)
            );
        }
    } catch (error) {
        yield put(ACTIONS.onGetUserProfileDocumentsFailed());
    }
}

function* watchCreatePlayer() {
    yield takeLatest(TYPES.CREATE_PLAYER, createPlayer);
}
function* watchUpdatePlayer() {
    yield takeLatest(TYPES.UPDATE_PLAYER, updatePlayer);
}
function* watchUpdateIdVerificationStatus() {
    yield takeLatest(
        TYPES.UPDATE_ID_VERIFICATION_STATUS,
        updateIdVerificationStatus
    );
    yield takeLatest(
        TYPES.UPDATE_FULLY_VERIFICATION_STATUS,
        updateIdVerificationStatus
    );
}
function* watchPlayerList() {
    yield takeLatest(TYPES.GET_PLAYER_LIST, playerList);
    yield takeLatest(TYPES.GET_PLAYER_SELECTION_LIST, playerList);
}
function* watchPlayerProfile() {
    yield takeLatest(TYPES.GET_PLAYER_PROFILE, playerProfile);
}

function* watchGetVerificationId() {
    yield takeLatest(TYPES.GET_VERIFICATION_ID, getVerificationId);
}

function* watchGetUserProfileDocuments() {
    yield takeLatest(
        TYPES.GET_USER_PROFILE_DOCUMENTS,
        getUserProfileDocumentsAsync
    );
}

function* playerSagas() {
    yield all([
        fork(watchCreatePlayer),
        fork(watchUpdatePlayer),
        fork(watchUpdateIdVerificationStatus),
        fork(watchPlayerList),
        fork(watchPlayerProfile),
        fork(watchGetVerificationId),
        fork(watchGetUserProfileDocuments),
    ]);
}

export default playerSagas;
