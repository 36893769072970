export const GET_USER_DATA = "GET_USER_DATA";
export const SET_USER_DATA = "SET_USER_DATA";
export const SET_USER_DATA_ERROR = "SET_USER_DATA_ERROR";
export const RESET_USER_DATA = "RESET_USER_DATA";

export const GET_USER_COUNT_DATA = "GET_USER_COUNT_DATA";
export const SET_USER_COUNT_DATA = "SET_USER_COUNT_DATA";
export const SET_USER_COUNT_DATA_ERROR = "SET_USER_COUNT_DATA_ERROR";

export const GET_ACTIVITY_LOG = "GET_ACTIVITY_LOG";
export const SET_ACTIVITY_LOG = "SET_ACTIVITY_LOG";
export const SET_ACTIVITY_LOG_ERROR = "SET_ACTIVITY_LOG_ERROR";

export const USER_FORGOT_PASSWORD = "USER_FORGOT_PASSWORD";
export const USER_FORGOT_PASSWORD_SUCCESS = "USER_FORGOT_PASSWORD_SUCCESS";
export const USER_FORGOT_PASSWORD_ERROR = "USER_FORGOT_PASSWORD_ERROR";

export const USER_RESET_PASSWORD = "USER_RESET_PASSWORD";
export const USER_RESET_PASSWORD_SUCCESS = "USER_RESET_PASSWORD_SUCCESS";
export const USER_RESET_PASSWORD_ERROR = "USER_RESET_PASSWORD_ERROR";

export const USER_UPDATE_STATUS = "USER_UPDATE_STATUS";
export const USER_UPDATE_STATUS_SUCCESS = "USER_UPDATE_STATUS_SUCCESS";
export const USER_UPDATE_STATUS_ERROR = "USER_UPDATE_STATUS_ERROR";

export const USER_UPDATE_USERNAME = "USER_UPDATE_USERNAME";
export const USER_UPDATE_USERNAME_SUCCESS = "USER_UPDATE_USERNAME_SUCCESS";
export const USER_UPDATE_USERNAME_ERROR = "USER_UPDATE_USERNAME_ERROR";

export const USER_RESET_PASSWORD_VALIDATE_TOKEN =
    "USER_RESET_PASSWORD_VALIDATE_TOKEN";
export const USER_RESET_PASSWORD_VALIDATE_TOKEN_SUCCESS =
    "USER_RESET_PASSWORD_VALIDATE_TOKEN_SUCCESS";
export const USER_RESET_PASSWORD_VALIDATE_TOKEN_ERROR =
    "USER_RESET_PASSWORD_VALIDATE_TOKEN_ERROR";

export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";

export const USER_CHANGE_PASSWORD = "USER_CHANGE_PASSWORD";
export const USER_CHANGE_PASSWORD_SUCCESS = "USER_CHANGE_PASSWORD_SUCCESS";
export const USER_CHANGE_PASSWORD_ERROR = "USER_CHANGE_PASSWORD_ERROR";

export const UPDATE_USER_PROFILE = "UPDATE_USER_PROFILE";
export const UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCCESS";
export const UPDATE_USER_PROFILE_ERROR = "UPDATE_USER_PROFILE_ERROR";

export const UPDATE_USER_EMAIL = "UPDATE_USER_EMAIL";
export const UPDATE_USER_EMAIL_SUCCESS = "UPDATE_USER_EMAIL_SUCCESS";
export const UPDATE_USER_EMAIL_ERROR = "UPDATE_USER_EMAIL_ERROR";

export const UPDATE_USER_MOBILE_NO = "UPDATE_USER_MOBILE_NO";
export const UPDATE_USER_MOBILE_NO_SUCCESS = "UPDATE_USER_MOBILE_NO_SUCCESS";
export const UPDATE_USER_MOBILE_NO_ERROR = "UPDATE_USER_MOBILE_NO_ERROR";

export const UPDATE_USER_USERNAME = "UPDATE_USER_USERNAME";
export const UPDATE_USER_USERNAME_SUCCESS = "UPDATE_USER_USERNAME_SUCCESS";
export const UPDATE_USER_USERNAME_ERROR = "UPDATE_USER_USERNAME_ERROR";

export const UPDATE_REFFERAL_CODE = "UPDATE_REFFERAL_CODE";
export const UPDATE_REFFERAL_CODE_SUCCESS = "UPDATE_REFFERAL_CODE_SUCCESS";
export const UPDATE_REFFERAL_CODE_ERROR = "UPDATE_REFFERAL_CODE_ERROR";

export const GET_COMMISSION = "GET_COMMISSION";
export const SET_COMMISSION = "SET_COMMISSION";
export const SET_COMMISSION_ERROR = "SET_COMMISSION_ERROR";

export const GET_USER_COMMISSIONS = "GET_USER_COMMISSIONS";
export const GET_USER_COMMISSIONS_SUCCESS = "GET_USER_COMMISSIONS_SUCCESS";
export const GET_USER_COMMISSIONS_ERROR = "GET_USER_COMMISSIONS_ERROR";

export const RESET_USER_ERRORS = "RESET_USER_ERRORS";
export const RESET_UPDATE_REFERRAL_CODE_ERRORS =
    "RESET_UPDATE_REFERRAL_CODE_ERRORS";

export const USER_LOGOUT = "USER_LOGOUT";

export const GET_USER_DEVICE_DETAILS = "GET_USER_DEVICE_DETAILS";
export const GET_USER_DEVICE_DETAILS_SUCCESS = "GET_USER_DEVICE_DETAILS_SUCCESS";
export const GET_USER_DEVICE_DETAILS_SUCCESS_ERROR = "GET_USER_DEVICE_DETAILS_SUCCESS_ERROR";

export const UPDATE_REFERRER = "UPDATE_REFERRER";
export const UPDATE_REFERRER_SUCCESS = "UPDATE_REFERRER_SUCCESS";
export const UPDATE_REFERRER_ERROR = "UPDATE_REFERRER_ERROR";

export const GET_OPERATOR = "GET_OPERATOR";
export const GET_OPERATOR_SUCCESS = "GET_OPERATOR_SUCCESS";
export const GET_OPERATOR_ERROR = "GET_OPERATOR_ERROR";

export const DISABLE_WITHDRAWAL_FEE_MERCHANT = "DISABLE_WITHDRAWAL_FEE_MERCHANT";
export const DISABLE_WITHDRAWAL_FEE_MERCHANT_SUCCESS = "DISABLE_WITHDRAWAL_FEE_MERCHANT_SUCCESS";
export const DISABLE_WITHDRAWAL_FEE_MERCHANT_ERROR = "DISABLE_WITHDRAWAL_FEE_MERCHANT_ERROR";