import * as types from "./actionTypes";

export const getSportTypeList = (payload) => ({
    type: types.GET_SPORT_TYPE_LIST,
    payload,
});

export const setSportTypeList = (payload) => ({
    type: types.SET_SPORT_TYPE_LIST,
    payload,
});

export const setSportTypeListError = () => ({
    type: types.SET_SPORT_TYPE_LIST_ERROR,
});

export const updateSportTypeStatus = (keyToken, initialize) => ({
    type: types.UPDATE_SPORT_TYPE_STATUS,
    keyToken,
    initialize,
});

export const updateSportTypeStatusSuccess = () => ({
    type: types.UPDATE_SPORT_TYPE_STATUS_SUCCESS,
});

export const updateSportTypeStatusError = (payload) => ({
    type: types.UPDATE_SPORT_TYPE_STATUS_ERROR,
    payload,
});

export const updateSportTypeUseChildFilter = (
    keyToken,
    payload,
    initialize
) => ({
    type: types.UPDATE_SPORT_TYPE_USE_CHILD_FILTER,
    keyToken,
    payload,
    initialize,
});

export const updateSportTypeUseChildFilterSuccess = () => ({
    type: types.UPDATE_SPORT_TYPE_USE_CHILD_FILTER_SUCCESS,
});

export const updateSportTypeUseChildFilterError = (payload) => ({
    type: types.UPDATE_SPORT_TYPE_USE_CHILD_FILTER_ERROR,
    payload,
});

export const getLeagueTypeList = (payload) => ({
    type: types.GET_LEAGUE_TYPE_LIST,
    payload,
});

export const setLeagueTypeList = (payload) => ({
    type: types.SET_LEAGUE_TYPE_LIST,
    payload,
});

export const setLeagueTypeListError = () => ({
    type: types.SET_LEAGUE_TYPE_LIST_ERROR,
});

export const updateLeagueTypeStatus = (keyToken, initialize) => ({
    type: types.UPDATE_LEAGUE_TYPE_STATUS,
    keyToken,
    initialize,
});

export const updateLeagueTypeStatusSuccess = () => ({
    type: types.UPDATE_LEAGUE_TYPE_STATUS_SUCCESS,
});

export const updateLeagueTypeStatusError = (payload) => ({
    type: types.UPDATE_LEAGUE_TYPE_STATUS_ERROR,
    payload,
});

export const getGameTypeList = (payload) => ({
    type: types.GET_GAME_TYPE_LIST,
    payload,
});

export const setGameTypeList = (payload) => ({
    type: types.SET_GAME_TYPE_LIST,
    payload,
});

export const setGameTypeListError = () => ({
    type: types.SET_GAME_TYPE_LIST_ERROR,
});

export const updateGameTypeStatus = (keyToken, initialize) => ({
    type: types.UPDATE_GAME_TYPE_STATUS,
    keyToken,
    initialize,
});

export const updateGameTypeStatusSuccess = () => ({
    type: types.UPDATE_GAME_TYPE_STATUS_SUCCESS,
});

export const updateGameTypeStatusError = (payload) => ({
    type: types.UPDATE_GAME_TYPE_STATUS_ERROR,
    payload,
});

export const getTeamList = (payload) => ({
    type: types.GET_TEAM_LIST,
    payload,
});

export const setTeamList = (payload) => ({
    type: types.SET_TEAM_LIST,
    payload,
});

export const setTeamListError = () => ({
    type: types.SET_TEAM_LIST_ERROR,
});

export const getHotMatchList = (payload) => ({
    type: types.GET_HOT_MATCH_LIST,
    payload,
});

export const uploadTeamPhoto = (keyToken, payload, initialize) => ({
    type: types.UPLOAD_TEAM_PHOTO,
    keyToken,
    payload,
    initialize,
});

export const uploadTeamPhotoSuccess = () => ({
    type: types.UPLOAD_TEAM_PHOTO_SUCCESS,
});

export const uploadTeamPhotoError = (payload) => ({
    type: types.UPLOAD_TEAM_PHOTO_ERROR,
    payload,
});

export const setHotMatchList = (payload) => ({
    type: types.SET_HOT_MATCH_LIST,
    payload,
});

export const setHotMatchListError = () => ({
    type: types.SET_HOT_MATCH_LIST_ERROR,
});

export const updateHotMatchPostStatus = (keyToken, initialize) => ({
    type: types.UPDATE_HOT_MATCH_POST_STATUS,
    keyToken,
    initialize,
});

export const updateHotMatchPostStatusSuccess = () => ({
    type: types.UPDATE_HOT_MATCH_POST_STATUS_SUCCESS,
});

export const updateHotMatchPostStatusError = (payload) => ({
    type: types.UPDATE_HOT_MATCH_POST_STATUS_ERROR,
    payload,
});

export const hotMatchManualPull = (initialize) => ({
    type: types.HOT_MATCH_MANUAL_PULL,
    initialize
});

export const hotMatchManualPullSuccess = () => ({
    type: types.HOT_MATCH_MANUAL_PULL_SUCCESS,
});

export const hotMatchManualPullError = (payload) => ({
    type: types.HOT_MATCH_MANUAL_PULL_ERROR,
});
