import { axiosInstance } from "./index";

export const getTransactionData = async (data, type, betType) => {
    let url =
        type === "wallet"
            ? "/bo/transaction/get-paginated"
            : type === "bcf"
            ? "/bo/betting-credit-fund/get-paginated"
            : `/bo/bet-transaction/get-paginated/${betType}`;
    return await axiosInstance.get(url, {
        params: data,
    });
};

export const getTransactionInfo = async (transactionNo) => {
    return await axiosInstance.get(
        `/bo/transaction/get-by-transaction-no/${transactionNo}`
    );
};

export const getTipTransaction = async (payload) => {
    return await axiosInstance.get(`/bo/tip-transaction/get-paginated`, {
        params: payload,
    });
};

export const userDeposit = async (data) => {
    return await axiosInstance.post(`/bo/transaction/direct-top-up`, data);
};

export const userDepositRequest = async (data) => {
    return await axiosInstance.post(
        `/bo/transaction-request/create/deposit-request`,
        data
    );
};

export const userWithdrawalRequest = async (data) => {
    return await axiosInstance.post(
        `/bo/transaction-request/create/withdrawal-request`,
        data
    );
};

export const getPaymentMethodData = async () => {
    return await axiosInstance.get(`/payment-method/get-all-active`);
};

export const addFund = async (data) => {
    return await axiosInstance.post(`/bo/betting-credit-fund/create`, data);
};

export const processCommission = async (data) => {
    if (data.userCommisionType === "players") {
        return await axiosInstance.post(
            "/bo/commission-player/manual-process",
            data
        );
    }
    return await axiosInstance.post("/bo/commission/manual-process", data);
};
