import * as types from "./actionTypes";

export const getOperatorList = (payload) => ({
    type: types.GET_OPERATOR_LIST,
    payload,
});

export const setOperatorList = (payload) => ({
    type: types.SET_OPERATOR_LIST,
    payload,
});

export const setOperatorListError = () => ({
    type: types.SET_OPERATOR_LIST_ERROR,
});

export const getOperatorProfile = (payload) => ({
    type: types.GET_OPERATOR_PROFILE,
    payload,
});

export const setOperatorProfile = (payload) => ({
    type: types.SET_OPERATOR_PROFILE,
    payload,
});

export const setOperatorProfileError = () => ({
    type: types.SET_OPERATOR_PROFILE_ERROR,
});

export const createOperator = (payload, validation) => ({
    type: types.CREATE_OPERATOR,
    payload,
    validation,
});

export const createOperatorSuccess = (payload) => ({
    type: types.CREATE_OPERATOR_SUCCESS,
    payload,
});

export const createOperatorError = (payload) => ({
    type: types.CREATE_OPERATOR_ERROR,
    payload,
});

export const updateOperator = (payload, keyToken) => ({
    type: types.UPDATE_OPERATOR,
    payload,
    keyToken,
});

export const updateOperatorSuccess = (payload) => ({
    type: types.UPDATE_OPERATOR_SUCCESS,
    payload,
});

export const updateOperatorError = (payload) => ({
    type: types.UPDATE_OPERATOR_ERROR,
    payload,
});

export const resetOperatorErrors = () => ({
    type: types.RESET_OPERATOR_ERRORS,
});

export const updateOperatorWalletBalance = (payload) => ({
    type: types.UPDATE_OPERATOR_WALLET_BALANCE,
    payload,
});

export const updateOperatorCommissionRate = (payload, keyToken) => ({
    type: types.UPDATE_OPERATOR_COMMISSION_RATE,
    payload,
    keyToken,
});

export const updateOperatorCommissionRateSuccess = (payload) => ({
    type: types.UPDATE_OPERATOR_COMMISSION_RATE_SUCCESS,
    payload,
});

export const updateOperatorCommissionRateError = (payload) => ({
    type: types.UPDATE_OPERATOR_COMMISSION_RATE_ERROR,
    payload,
});

export const updateOperatorEmailSuccess = (payload) => ({
    type: types.UPDATE_OPERATOR_EMAIL_SUCCESS,
    payload,
});

export const getOperatorSelectionList = (payload) => ({
    type: types.GET_OPERATOR_SELECTION_LIST,
    payload,
});

export const onGetOperatorSelectionListSuccess = (payload) => ({
    type: types.GET_OPERATOR_SELECTION_LIST_SUCCESS,
    payload,
});

export const onGetOperatorSelectionListFailed = (payload) => ({
    type: types.GET_OPERATOR_SELECTION_LIST_FAILED,
    payload,
});

export const updateMerchantSetting = (payload, keyToken) => ({
    type: types.UPDATE_MERCHANT_SETTING,
    payload,
    keyToken,
});

export const onUpdateMerchantSettingSuccess = () => ({
    type: types.UPDATE_MERCHANT_SETTING_SUCCESS,
});

export const onUpdateMerchantSettingFailed = () => ({
    type: types.UPDATE_MERCHANT_SETTING_FAILED,
});

export const updateCrossBettingProtection = (payload, keyToken) => ({
    type: types.UPDATE_CROSS_BETTING_PROTECTION,
    payload,
    keyToken,
});

export const onUpdateCrossBettingProtectionSuccess = () => ({
    type: types.UPDATE_CROSS_BETTING_PROTECTION_SUCCESS,
});

export const onUpdateCrossBettingProtectionFailed = () => ({
    type: types.UPDATE_CROSS_BETTING_PROTECTION_FAILED,
});

export const updateOperatorRoleTag = (payload, keyToken) => ({
    type: types.UPDATE_OPERATOR_ROLE_TAG,
    payload,
    keyToken,
});

export const updateOperatorRoleTagSuccess = (payload) => ({
    type: types.UPDATE_OPERATOR_ROLE_TAG_SUCCESS,
    payload,
});

export const updateOperatorRoleTagError = (payload) => ({
    type: types.UPDATE_OPERATOR_ROLE_TAG_ERROR,
    payload,
});

export const applyDownlinesCommissionTransferScheduleOperator = (
    payload,
    keyToken,
    initialize
) => ({
    type: types.APPLY_DOWNLINES_COMMISSIONS_TRANSFER_SCHEDULE_OPERATOR,
    payload,
    keyToken,
    initialize,
});

export const applyDownlinesCommissionTransferScheduleOperatorSuccess = (
    payload
) => ({
    type: types.APPLY_DOWNLINES_COMMISSIONS_TRANSFER_SCHEDULE_OPERATOR_SUCCESS,
    payload,
});

export const applyDownlinesCommissionTransferScheduleOperatorError = (
    payload
) => ({
    type: types.APPLY_DOWNLINES_COMMISSIONS_TRANSFER_SCHEDULE_OPERATOR_ERROR,
    payload,
});
