import * as types from "./actionTypes";

export const getAgentList = (payload) => ({
    type: types.GET_AGENT_LIST,
    payload,
});

export const setAgentList = (payload) => ({
    type: types.SET_AGENT_LIST,
    payload,
});

export const setAgentListError = () => ({
    type: types.SET_AGENT_LIST_ERROR,
});

export const getAgentProfile = (payload) => ({
    type: types.GET_AGENT_PROFILE,
    payload,
});

export const setAgentProfile = (payload) => ({
    type: types.SET_AGENT_PROFILE,
    payload,
});

export const setAgentProfileError = () => ({
    type: types.SET_AGENT_PROFILE_ERROR,
});

export const createAgent = (payload, validation) => ({
    type: types.CREATE_AGENT,
    payload,
    validation,
});

export const createAgentSuccess = (payload) => ({
    type: types.CREATE_AGENT_SUCCESS,
    payload,
});

export const createAgentError = (payload) => ({
    type: types.CREATE_AGENT_ERROR,
    payload,
});

export const updateAgent = (payload, keyToken) => ({
    type: types.UPDATE_AGENT,
    payload,
    keyToken,
});

export const updateAgentSuccess = (payload) => ({
    type: types.UPDATE_AGENT_SUCCESS,
    payload,
});

export const updateAgentError = (payload) => ({
    type: types.UPDATE_AGENT_ERROR,
    payload,
});

export const resetAgentErrors = () => ({
    type: types.RESET_AGENT_ERRORS,
});

export const updateAgentWalletBalance = (payload) => ({
    type: types.UPDATE_AGENT_WALLET_BALANCE,
    payload,
});

export const updateAgentCommissionRate = (payload, keyToken, validation) => ({
    type: types.UPDATE_AGENT_COMMISSION_RATE,
    payload,
    keyToken,
    validation,
});

export const updateAgentCommissionRateSuccess = (payload) => ({
    type: types.UPDATE_AGENT_COMMISSION_RATE_SUCCESS,
    payload,
});

export const updateAgentCommissionRateError = (payload) => ({
    type: types.UPDATE_AGENT_COMMISSION_RATE_ERROR,
    payload,
});

export const getAgentCommissions = (payload) => ({
    type: types.GET_AGENT_COMMISSIONS,
    payload,
});

export const onGetAgentCommissionsSuccess = (payload) => ({
    type: types.GET_AGENT_COMMISSIONS_SUCCESS,
    payload,
});

export const onGetAgentCommissionsFailed = () => ({
    type: types.GET_AGENT_COMMISSIONS_FAILED,
});

export const updateAgentEmailSuccess = (payload) => ({
    type: types.UPDATE_AGENT_EMAIL_SUCCESS,
    payload,
});

export const updateAgentRoleTag = (payload, keyToken) => ({
    type: types.UPDATE_AGENT_ROLE_TAG,
    payload,
    keyToken,
});

export const updateAgentRoleTagSuccess = (payload) => ({
    type: types.UPDATE_AGENT_ROLE_TAG_SUCCESS,
    payload,
});

export const updateAgentRoleTagError = (payload) => ({
    type: types.UPDATE_AGENT_ROLE_TAG_ERROR,
    payload,
});

export const enableValidTurnoverCommission = (
    payload,
    balanceCategory,
    keyToken,
    initialize
) => ({
    type: types.ENABLE_VALID_TURNOVER_COMMISSION,
    payload,
    balanceCategory,
    keyToken,
    initialize,
});

export const enableValidTurnoverCommissionSuccess = () => ({
    type: types.ENABLE_VALID_TURNOVER_COMMISSION_SUCCESS,
});

export const enableValidTurnoverCommissionError = () => ({
    type: types.ENABLE_VALID_TURNOVER_COMMISSION_ERROR,
});

export const updateUserProfileSetting = (
    setting,
    payload,
    keyToken,
    initialize
) => ({
    type: types.UPDATE_USER_PROFILE_SETTING,
    setting,
    payload,
    keyToken,
    initialize,
});

export const updateUserProfileSettingSuccess = () => ({
    type: types.UPDATE_USER_PROFILE_SETTING_SUCCESS,
});

export const updateUserProfileSettingError = () => ({
    type: types.UPDATE_USER_PROFILE_SETTING_ERROR,
});

export const updateUserProfileSettingWithBalanceCategory = (
    setting,
    payload,
    balanceCategory,
    keyToken,
    initialize
) => ({
    type: types.UPDATE_USER_PROFILE_SETTING_WITH_BALANCE_CATEGORY,
    setting,
    payload,
    balanceCategory,
    keyToken,
    initialize,
});

export const updateUserProfileSettingWithBalanceCategorySuccess = () => ({
    type: types.UPDATE_USER_PROFILE_SETTING_WITH_BALANCE_CATEGORY_SUCCESS,
});

export const updateUserProfileSettingWithBalanceCategoryError = () => ({
    type: types.UPDATE_USER_PROFILE_SETTING_WITH_BALANCE_CATEGORY_ERROR,
});

export const enableNegativeCarryOverCommission = (
    payload,
    balanceCategory,
    keyToken,
    initialize
) => ({
    type: types.ENABLE_NEGATIVE_CARRY_OVER_COMMISSION,
    payload,
    balanceCategory,
    keyToken,
    initialize,
});
export const enableNegativeCarryOverCommissionSuccess = () => ({
    type: types.ENABLE_NEGATIVE_CARRY_OVER_COMMISSION_SUCCESS,
});

export const enableNegativeCarryOverCommissionError = () => ({
    type: types.ENABLE_NEGATIVE_CARRY_OVER_COMMISSION_ERROR,
});

export const updatePendingCommissionRequiredToResetNegativeCommission = (
    payload,
    balanceCategory,
    keyToken,
    initialize
) => ({
    type: types.UPDATE_PENDING_COMMISSION_REQUIRED_TO_RESET_NEGATIVE_COMMISSION,
    payload,
    balanceCategory,
    keyToken,
    initialize,
});
export const updatePendingCommissionRequiredToResetNegativeCommissionSuccess =
    () => ({
        type: types.UPDATE_PENDING_COMMISSION_REQUIRED_TO_RESET_NEGATIVE_COMMISSION_SUCCESS,
    });

export const updatePendingCommissionRequiredToResetNegativeCommissionError =
    () => ({
        type: types.UPDATE_PENDING_COMMISSION_REQUIRED_TO_RESET_NEGATIVE_COMMISSION_ERROR,
    });

export const updateNumberOfCutoffToResetNegativeCommission = (
    payload,
    balanceCategory,
    keyToken,
    initialize
) => ({
    type: types.UPDATE_NUMBER_OF_CUTOFF_TO_RESET_NEGATIVE_COMMISSION,
    payload,
    balanceCategory,
    keyToken,
    initialize,
});
export const updateNumberOfCutoffToResetNegativeCommissionSuccess = () => ({
    type: types.UPDATE_NUMBER_OF_CUTOFF_TO_RESET_NEGATIVE_COMMISSION_SUCCESS,
});

export const updateNumberOfCutoffToResetNegativeCommissionError = () => ({
    type: types.UPDATE_NUMBER_OF_CUTOFF_TO_RESET_NEGATIVE_COMMISSION_ERROR,
});

export const applyDownlinesCommissionTransferScheduleAgent = (
    payload,
    keyToken,
    initialize
) => ({
    type: types.APPLY_DOWNLINES_COMMISSIONS_TRANSFER_SCHEDULE_AGENT,
    payload,
    keyToken,
    initialize,
});

export const applyDownlinesCommissionTransferScheduleAgentSuccess = (
    payload
) => ({
    type: types.APPLY_DOWNLINES_COMMISSIONS_TRANSFER_SCHEDULE_AGENT_SUCCESS,
    payload,
});

export const applyDownlinesCommissionTransferScheduleAgentError = (
    payload
) => ({
    type: types.APPLY_DOWNLINES_COMMISSIONS_TRANSFER_SCHEDULE_AGENT_ERROR,
    payload,
});

export const updateAffiliateRequestStatus = (payload) => ({
    type: types.UPDATE_AFFILIATE_REQUEST_STATUS,
    payload,
});

export const updateAffiliateRequestStatusSuccess = (payload) => ({
    type: types.UPDATE_AFFILIATE_REQUEST_STATUS_SUCCESS,
    payload,
});

export const updateAffiliateRequestStatusError = (payload) => ({
    type: types.UPDATE_AFFILIATE_REQUEST_STATUS_ERROR,
    payload,
});
