import { Button, InputPinCode, ErrorAlert } from "@/components/atoms";
import { pinCodeValidation } from "@/constants";
import { useFormik } from "formik";
import React from "react";
import {
    Button as ButtonComponent,
    Col,
    Form,
    FormGroup,
    Modal,
    ModalHeader,
    ModalBody,
    Row,
} from "reactstrap";

const PinCodeModal = ({
    isOpen,
    setIsOpen,
    onHandleSubmit,
    errors,
    isLoading,
}) => {

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            pin_code: "",
        },
        validationSchema: pinCodeValidation(6),
        onSubmit: (values) => onHandleSubmit(values.pin_code),
    });

    return (
        <Modal
            id="PinCodeModal"
            isOpen={isOpen}
            toggle={() => setIsOpen(!isOpen)}
            centered
        >
            <ModalHeader>Enter your Pin Code</ModalHeader>
            <ModalBody>
                <ErrorAlert errors={errors} validation={validation} />
                <Form
                    className="needs-validation"
                    onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}
                >
                    <Row>
                        <Col md="12">
                            <FormGroup className="mb-3">
                                <InputPinCode
                                    name="pin_code"
                                    length={6}
                                    validation={validation}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-end">
                            <ButtonComponent
                                color="light"
                                onClick={() => setIsOpen(!isOpen)}
                                className="me-2"
                            >
                                Close
                            </ButtonComponent>
                            <Button
                                color="success"
                                type="submit"
                                text="Submit"
                                isLoading={isLoading}
                            />
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
        </Modal>
    );
};

export default PinCodeModal;
