export const GET_PLAYER_LIST = "GET_PLAYER_LIST";
export const SET_PLAYER_LIST = "SET_PLAYER_LIST";
export const SET_PLAYER_LIST_ERROR = "SET_PLAYER_LIST_ERROR";

export const CREATE_PLAYER = "CREATE_PLAYER";
export const CREATE_PLAYER_SUCCESS = "CREATE_PLAYER_SUCCESS";
export const CREATE_PLAYER_ERROR = "CREATE_PLAYER_ERROR";

export const GET_PLAYER_PROFILE = "GET_PLAYER_PROFILE";
export const SET_PLAYER_PROFILE = "SET_PLAYER_PROFILE";
export const SET_PLAYER_PROFILE_ERROR = "SET_PLAYER_PROFILE_ERROR";

export const UPDATE_PLAYER = "UPDATE_PLAYER";
export const UPDATE_PLAYER_SUCCESS = "UPDATE_PLAYER_SUCCESS";
export const UPDATE_PLAYER_ERROR = "UPDATE_PLAYER_ERROR";

export const DELETE_PLAYER = "DELETE_PLAYER";
export const DELETE_PLAYER_SUCCESS = "DELETE_PLAYER_SUCCESS";
export const DELETE_PLAYER_ERROR = "DELETE_PLAYER_ERROR";

export const GET_VERIFICATION_ID = "GET_VERIFICATION_ID";
export const GET_VERIFICATION_ID_SUCCESS = "GET_VERIFICATION_ID_SUCCESS";
export const GET_VERIFICATION_ID_ERROR = "GET_VERIFICATION_ID_ERROR";

export const UPDATE_ID_VERIFICATION_STATUS = "UPDATE_ID_VERIFICATION_STATUS";
export const UPDATE_ID_VERIFICATION_STATUS_SUCCESS =
    "UPDATE_ID_VERIFICATION_STATUS_SUCCESS";
export const UPDATE_ID_VERIFICATION_STATUS_ERROR =
    "UPDATE_ID_VERIFICATION_STATUS_ERROR";

export const UPDATE_FULLY_VERIFICATION_STATUS =
    "UPDATE_FULLY_VERIFICATION_STATUS";
export const UPDATE_FULLY_VERIFICATION_STATUS_SUCCESS =
    "UPDATE_FULLY_VERIFICATION_STATUS_SUCCESS";
export const UPDATE_FULLY_VERIFICATION_STATUS_FAILED =
    "UPDATE_FULLY_VERIFICATION_STATUS_FAILED";

export const RESET_PLAYER_ERRORS = "RESET_PLAYER_ERRORS";

export const UPDATE_PLAYER_WALLET_BALANCE = "UPDATE_PLAYER_WALLET_BALANCE";

export const GET_USER_PROFILE_DOCUMENTS = "GET_USER_PROFILE_DOCUMENTS";
export const GET_USER_PROFILE_DOCUMENTS_SUCCESS =
    "GET_USER_PROFILE_DOCUMENTS_SUCCESS";
export const GET_USER_PROFILE_DOCUMENTS_FAILED =
    "GET_USER_PROFILE_DOCUMENTS_FAILED";

export const UPDATE_PLAYER_EMAIL_SUCCESS = "UPDATE_PLAYER_EMAIL_SUCCESS";

export const GET_PLAYER_SELECTION_LIST = "GET_PLAYER_SELECTION_LIST";
export const GET_PLAYER_SELECTION_LIST_SUCCESS =
    "GET_PLAYER_SELECTION_LIST_SUCCESS";
export const GET_PLAYER_SELECTION_LIST_FAILED =
    "GET_PLAYER_SELECTION_LIST_FAILED";
export const CLEAR_PLAYER_SELECTION_LIST = "CLEAR_PLAYER_SELECTION_LIST";
