import { axiosInstance } from "./index";

export const getRequestData = async (data, requestType) => {
    return await axiosInstance.get(
        `/bo/transaction-request/get-paginated/${requestType}-request`,
        { params: data }
    );
};

export const getRequestInfo = async (transactionRequestNo) => {
    return await axiosInstance.get(
        `/bo/transaction-request/get-by-transaction-request-no/${transactionRequestNo}`
    );
};

export const updateRequestStatus = async (data, type, keyToken) => {
    return await axiosInstance.put(
        `/bo/transaction-request/${type}/${keyToken}`,
        data
    );
};

export const bulkRelease = async (data) => {
    return await axiosInstance.post(
        `/bo/transaction-request/bulk-release`,
        data
    );
};

export const bulkPayout = async (data) => {
    return await axiosInstance.post(
        "/bo/transaction-request/bulk-payout",
        data
    );
};

export const getBulkPayoutProgress = async () => {
    return await axiosInstance.get(
        "/bo/transaction-request/bulk-payout-progress"
    );
};

export const importPayout = async (params) => {
    return await axiosInstance.post(
        `/bo/transaction-request/import/payout-result`,
        params,
        {
            "content-type": "multipart/form-data",
        }
    );
};

export const getPublicPaymentMethod = async () => {
    return await axiosInstance.get(
        `/payment-method/get-all`
    );
};
