import * as types from "./actionTypes";

export const getRegions = () => ({
    type: types.GET_REGIONS,
});

export const getRegionsSuccess = (payload) => ({
    type: types.GET_REGIONS_SUCCESS,
    payload,
});

export const getRegionsError = (payload) => ({
    type: types.GET_REGIONS_ERROR,
    payload,
});

export const getProvinces = (regionKeyToken) => ({
    type: types.GET_PROVINCES,
    regionKeyToken,
});

export const getProvincesSuccess = (payload) => ({
    type: types.GET_PROVINCES_SUCCESS,
    payload,
});

export const getProvincesError = (payload) => ({
    type: types.GET_PROVINCES_ERROR,
    payload,
});

export const getCities = (provinceKeyToken) => ({
    type: types.GET_CITIES,
    provinceKeyToken,
});

export const getCitiesSuccess = (payload) => ({
    type: types.GET_CITIES_SUCCESS,
    payload,
});

export const getCitiesError = (payload) => ({
    type: types.GET_CITIES_ERROR,
    payload,
});

export const getBarangays = (cityKeyToken) => ({
    type: types.GET_BARANGAYS,
    cityKeyToken,
});

export const getBarangaysSuccess = (payload) => ({
    type: types.GET_BARANGAYS_SUCCESS,
    payload,
});

export const getBarangaysError = (payload) => ({
    type: types.GET_BARANGAYS_ERROR,
    payload,
});

export const resetAddressesData = () => ({
    type: types.RESET_ADDRESSES_DATA,
});

export const getCountries = () => ({
    type: types.GET_COUNTRIES
});

export const onGetCountriesSuccess = (payload) => ({
    type: types.GET_COUNTRIES_SUCCESS,
    payload
});

export const onGetCountriesFailed = (payload) => ({
    type: types.GET_COUNTRIES_FAILED,
    payload
});