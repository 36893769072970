const TablePlaceholder = ({ text, colSpan }) => {
    return (
        <tr className="border text-center">
            <td colSpan={colSpan} className="p-2">
                {text ? text : "No Data Found."}
            </td>
        </tr>
    );
};

export default TablePlaceholder;
