import { commissionRatePerGameOffering } from "@/constants";
import { getSetting } from "@/hooks";
import { numberFormat } from "@/utils/common";

import * as Yup from "yup";

const digitsOnly = (value) => /^\d+$/.test(value);

export const regex = {
    password: {
        regex: /^(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/,
        message:
            "Password must be minimum 8 character, atleast one number, atleast one uppercase letter, and atleast one special character.",
    },
};

export const userValidation = () => {
    return Yup.object({
        first_name: Yup.string().required("Please Enter First Name"),
        last_name: Yup.string().required("Please Enter Last Name"),
        extension_name: Yup.string()
            .max(4, "Suffix Name must be at most 4 characters")
            .matches(
                /^[aA-zZ\s]+$/,
                "Only alphabets are allowed for this field"
            ),
        birth_date: Yup.date()
            .required("Please Enter BirthDate")
            .max(new Date(), "BirthDate field must be at earlier than today"),
        gender: Yup.string().required("Please Enter Gender"),
        occupation: Yup.string().required("Please Enter Occupation").nullable(),
        // country: Yup.string().required("Please Enter Country"),
        // region: Yup.string().required("Please Enter Region"),
        // province: Yup.string().required("Please Enter Province"),
        // city: Yup.string().required("Please Enter City"),
        // barangay: Yup.string().required("Please Enter Barangay"),
    });
};

export const agentValidation = (userType) => {
    let balanceCategory = null;
    let min = null;
    let max = null;
    let commissionRateValidation = {};

    const vtoCommissionRateEnabled =
        getSetting("enable_valid_turnover_commission_rate") === "1";

    commissionRatePerGameOffering
        .filter((item) => {
            if (item.balanceCategory === "bcf") {
                if (!vtoCommissionRateEnabled) {
                    return !item.value.includes("vto");
                } else {
                    return true;
                }
            }

            return false;
        })
        .forEach((item) => {
            balanceCategory = item.value.replace("commission_rate_", "");

            min = getSetting(
                userType === "agent"
                    ? `min_commission_rate_for_agent_${balanceCategory}`
                    : `min_commission_rate_for_master_agent_${balanceCategory}`
            );

            max = getSetting(
                userType === "agent"
                    ? `max_commission_rate_for_agent_${balanceCategory}`
                    : `max_commission_rate_for_master_agent_${balanceCategory}`
            );

            commissionRateValidation = {
                ...commissionRateValidation,
                [item.value]: Yup.number()
                    .min(
                        min,
                        `The minimum commission rate for ${
                            userType === "agent" ? "agent" : "super agent"
                        } is ${numberFormat(min)}`
                    )
                    .max(
                        max,
                        `The maximum commission rate for ${
                            userType === "agent" ? "agent" : "super agent"
                        } is ${numberFormat(max)}`
                    )
                    .required("Please Enter Commssion Rate")
                    .typeError("Commssion Rate must be number"),
            };
        });

    return Yup.object({
        first_name: Yup.string().required("Please Enter First Name"),
        last_name: Yup.string().required("Please Enter Last Name"),
        email: Yup.string()
            .email("Please Enter Valid Email")
            .required("Please Enter Email"),
        mobile_no: Yup.string()
            .typeError("Mobile number must be number")
            .matches(/^(09|\+639)\d{9}$/, "Invalid mobile number")
            .required("Please Enter Mobile Number"),
        extension_name: Yup.string()
            .max(4, "Suffix Name must be at most 4 characters")
            .matches(
                /^[aA-zZ\s]+$/,
                "Only alphabets are allowed for this field"
            ),
        birth_date: Yup.date()
            .required("Please Enter BirthDate")
            .max(new Date(), "BirthDate field must be at earlier than today"),
        gender: Yup.string().required("Please Enter Gender"),
        // occupation: Yup.string().required("Please Enter Occupation"),
        // country: Yup.string().required("Please Enter Country"),
        // region: Yup.string().required("Please Enter Region"),
        // province: Yup.string().required("Please Enter Province"),
        // city: Yup.string().required("Please Enter City"),
        // barangay: Yup.string().required("Please Enter Barangay"),
        ...commissionRateValidation,
    });
};

export const operatorValidation = () => {
    return Yup.object({
        first_name: Yup.string().required("Please Enter First Name"),
        last_name: Yup.string().required("Please Enter Last Name"),
        email: Yup.string()
            .email("Please Enter Valid Email")
            .required("Please Enter Email"),
        mobile_no: Yup.string()
            .typeError("Mobile number must be number")
            .matches(/^(09|\+639)\d{9}$/, "Invalid mobile number")
            .required("Please Enter Mobile Number"),
        extension_name: Yup.string()
            .max(4, "Suffix Name must be at most 4 characters")
            .matches(
                /^[aA-zZ\s]+$/,
                "Only alphabets are allowed for this field"
            ),
        birth_date: Yup.date()
            .required("Please Enter BirthDate")
            .max(new Date(), "BirthDate field must be at earlier than today"),
        gender: Yup.string().required("Please Enter Gender"),
        // occupation: Yup.string().required("Please Enter Occupation"),
        // country: Yup.string().required("Please Enter Country"),
        // region: Yup.string().required("Please Enter Region"),
        // province: Yup.string().required("Please Enter Province"),
        // city: Yup.string().required("Please Enter City"),
        // barangay: Yup.string().required("Please Enter Barangay"),
    });
};

export const playerValidation = () => {
    return Yup.object({
        referred_by: Yup.string(),
        first_name: Yup.string().required("Please Enter First Name"),
        last_name: Yup.string().required("Please Enter Last Name"),
        email: Yup.string()
            .email("Please Enter Valid Email")
            .required("Please Enter Email"),
        mobile_no: Yup.string()
            .typeError("Mobile number must be number")
            .matches(/^(09|\+639)\d{9}$/, "Invalid mobile number")
            .required("Please Enter Mobile Number"),
        extension_name: Yup.string()
            .max(4, "Suffix Name must be at most 4 characters")
            .matches(
                /^[aA-zZ\s]+$/,
                "Only alphabets are allowed for this field"
            ),
        birth_date: Yup.date()
            .required("Please Enter BirthDate")
            .max(new Date(), "BirthDate field must be at earlier than today"),
        gender: Yup.string().required("Please Enter Gender"),
        password: Yup.string()
            .matches(
                regex.password.regex,
                "New password must be minimum 8 character, atleast one number, atleast one uppercase letter, and atleast one special character."
            )
            .nullable(),
        password_confirmation: Yup.string()
            .oneOf([Yup.ref("password"), null], "Passwords must match")
            .nullable(),
    });
};

export const adminValidation = () => {
    return Yup.object({
        first_name: Yup.string().required("Please Enter First Name"),
        last_name: Yup.string().required("Please Enter Last Name"),
        extension_name: Yup.string()
            .max(4, "Suffix Name must be at most 4 characters")
            .matches(
                /^[aA-zZ\s]+$/,
                "Only alphabets are allowed for this field"
            ),
        email: Yup.string()
            .email("Please Enter Valid Email")
            .required("Please Enter Email"),
        mobile_no: Yup.string()
            .typeError("Mobile number must be number")
            .matches(/^(09|\+639)\d{9}$/, "Invalid mobile number")
            .required("Please Enter Mobile Number"),
        birth_date: Yup.date()
            .required("Please Enter BirthDate")
            .max(new Date(), "BirthDate field must be at earlier than today"),
        gender: Yup.string().required("Please Enter Gender"),
        admin_role: Yup.string().required("Please Select Role"),
        occupation: Yup.string().required("Please Enter Occupation").nullable(),
        // country: Yup.string().required("Please Enter Country"),
        // region: Yup.string().required("Please Enter Region"),
        // province: Yup.string().required("Please Enter Province"),
        // city: Yup.string().required("Please Enter City"),
        // barangay: Yup.string().required("Please Enter Barangay"),
    });
};

export const emailValidation = () => {
    return Yup.object({
        email: Yup.string()
            .email("Please Enter Valid Email")
            .required("Please Enter Email"),
    });
};

export const mobileNoValidation = () => {
    return Yup.object({
        mobile_no: Yup.string()
            .typeError("Mobile number must be number")
            .matches(/^(09|\+639)\d{9}$/, "Invalid mobile number")
            .required("Please Enter Mobile Number"),
    });
};

export const usernameValidation = () => {
    return Yup.object({
        username: Yup.string().required("Please Enter Username"),
    });
};

export const commissionRateValidation = (userType, type) => {
    const balanceCategory = type.replace("commission_rate_", "");

    let min = getSetting(
        userType === "agent"
            ? `min_commission_rate_for_agent_${balanceCategory}`
            : `min_commission_rate_for_master_agent_${balanceCategory}`
    );
    let max = getSetting(
        userType === "agent"
            ? `max_commission_rate_for_agent_${balanceCategory}`
            : `max_commission_rate_for_master_agent_${balanceCategory}`
    );

    return Yup.object({
        [type]: Yup.number()
            .required("Please Enter Commission rate")
            .typeError("Commission rate must be number")
            .min(
                min,
                `The minimum commission rate for ${
                    userType === "agent" ? "agent" : "super agent"
                } is ${numberFormat(min)}`
            )
            .max(
                max,
                `The maximum commission rate for ${
                    userType === "agent" ? "agent" : "super agent"
                } is ${numberFormat(max)}`
            ),
    });
};

export const depositValidation = () => {
    let min = getSetting("minimum_deposit_amount");
    let max = getSetting("maximum_deposit_amount");

    return Yup.object({
        user_profile_key_token: Yup.string().required("Please Select Player"),
        payment_method: Yup.string().required("Please Enter Bank"),
        account_no: Yup.number().required("Please Enter Account No."),
        account_name: Yup.string().required("Please Enter Account Name."),
        amount: Yup.number()
            .min(min, `The minimum deposit amount is ${numberFormat(min)}`)
            .max(max, `The maximum deposit amount is ${numberFormat(max)}`)
            .typeError("Amount must be number")
            .required("Please Enter Amount"),
    });
};

export const addFundValidation = () => {
    return Yup.object({
        user_profile_key_token: Yup.string().required("Please Select Player"),
        amount: Yup.number()
            .min(1, `The minimum deposit amount is ${1}`)
            // .max(max, `The maximum deposit amount is ${max}`)
            .typeError("Amount must be number")
            .required("Please Enter Amount"),
        credit: Yup.number()
            .min(1, `The minimum deposit amount is ${1}`)
            // .max(max, `The maximum deposit amount is ${max}`)
            .typeError("Credit must be number")
            .required("Please Enter Credit"),
    });
};

export const deductFundValidation = () => {
    return Yup.object({
        user_profile_key_token: Yup.string().required("Please Select Player"),
        amount: Yup.number()
            .min(0, `The minimum deposit amount is 0`)
            // .max(max, `The maximum deposit amount is ${max}`)
            .typeError("Amount must be number")
            .required("Please Enter Amount"),
        credit: Yup.number()
            .min(0, `The minimum deposit amount is 0`)
            // .max(max, `The maximum deposit amount is ${max}`)
            .typeError("Credit must be number")
            .required("Please Enter Credit"),
    });
};

export const depositRequestValidation = () => {
    let min = getSetting("minimum_deposit_amount");
    let max = getSetting("maximum_deposit_amount");
    return Yup.object({
        payment_method: Yup.string().required("Please Enter Bank"),
        account_no: Yup.number().required("Please Enter Account No."),
        account_name: Yup.string().required("Please Enter Account Name."),
        reference_no: Yup.string().required("Please Enter Reference No."),
        amount: Yup.number()
            .min(
                min,
                `The minimum deposit request amount is ${numberFormat(min)}`
            )
            .max(
                max,
                `The maximum deposit request amount is ${numberFormat(max)}`
            )
            .typeError("Amount must be number")
            .required("Please Enter Amount"),
    });
};

export const withdrawalRequestValidation = (selectedBalanceCategory) => {
    let min = getSetting(
        ["merchant", "commission_merchant"].includes(
            selectedBalanceCategory.value
        )
            ? "minimum_withdraw_amount_merchant"
            : "minimum_withdraw_amount"
    );
    let max = getSetting(
        ["merchant", "commission_merchant"].includes(
            selectedBalanceCategory.value
        )
            ? "maximum_withdraw_amount_merchant"
            : "maximum_withdraw_amount"
    );
    return Yup.object({
        payment_method: Yup.string().required("Please Enter Bank"),
        balance_category: Yup.string().required(
            "Please Select Balance Category"
        ),
        account_no: Yup.number().required("Please Enter Account No."),
        account_name: Yup.string().required("Please Enter Account Name."),
        amount: Yup.number()
            .min(min, `The minimum withdrawal amount is ${numberFormat(min)}`)
            .max(max, `The maximum withdrawal amount is ${numberFormat(max)}`)
            .typeError("Amount must be number")
            .required("Please Enter Amount"),
    });
};

export const pinCodeValidation = (length = 6) => {
    return Yup.object({
        pin_code: Yup.string()
            .test("Number", "Pin code must be a number combination", digitsOnly)
            .min(6, "Invalid Pin code Min")
            .max(6, "Invalid Pin code Max")
            .required("Please Enter Pin Code"),
    });
};

export const directWithdrawalRequestValidation = () => {
    return Yup.object({
        payment_provider: Yup.string().required("Please Payment Provider"),
        payment_method: Yup.string().required("Please Enter Bank"),
        account_no: Yup.number().required("Please Enter Account No."),
        account_first_name: Yup.string().required(
            "Please Enter Account First Name."
        ),
        account_last_name: Yup.string().required(
            "Please Enter Account Last Name."
        ),
        amount: Yup.number()
            .typeError("Amount must be number")
            .required("Please Enter Amount"),
    });
};

export const changePasswordValidation = () => {
    return Yup.object({
        old_password: Yup.string().required("Please Enter Your Old Password"),
        new_password: Yup.string()
            .matches(
                regex.password.regex,
                "New password must be minimum 8 character, atleast one number, atleast one uppercase letter, and atleast one special character."
            )
            .required("Please Enter Your New Password"),
        confirm_password: Yup.string()
            .required("Please Enter Confirm Password")
            .oneOf([Yup.ref("new_password"), null], "Passwords must match"),
    });
};

export const resetPasswordValidation = () => {
    return Yup.object({
        email: Yup.string().email().required("Please Enter Your Email"),
        new_password: Yup.string()
            .matches(
                regex.password.regex,
                "New password must be least one number, one uppercase letter, one lowercase letter, one special character and a minimum length of eight characters."
            )
            .required("Please Enter Your New Password"),
        confirm_password: Yup.string()
            .required("Please Enter Confirm Password")
            .oneOf([Yup.ref("new_password"), null], "Passwords must match"),
    });
};

export const roleValidation = () => {
    return Yup.object({
        name: Yup.string().required("Please Enter Role Name"),
    });
};

export const updateReferralCodeValidation = () => {
    return Yup.object({
        referral_code: Yup.string()
            .min(6, "Referral Code must be minimum 6 characters")
            .max(15, "Referral Code must be maximum 15 characters")
            .required("Referral Code in required"),
    });
};

export const playerValidationUpdateInfo = userValidation;

export const addFeaturedGameValidation = ({featuredTypeLabel = null}) => {
    return Yup.object().shape({
        featured_type: Yup.string().required("Please Enter Featured Type"),
        featured_banner: Yup.mixed().when('featured_type', {
            is: () => featuredTypeLabel === "Featured",
            then: Yup.mixed().required('Featured Banner is required.'),
        }),
    });
};
export const updateFeaturedGameValidation = () => {
    return Yup.object().shape({
        featured_banner: Yup.mixed(),
    });
};

export const importPayoutValidation = () => {
    return Yup.object().shape({
        payout_result: Yup.mixed().required("Please Upload Payout Report"),
    });
};

export const addPaymentFeeValidation = () => {
    return Yup.object({
        convenience_fee_percent: Yup.number()
            .typeError("Convenience Fee Percent must be number")
            .required("Please Enter Convenience Fee Percent"),
        platform_fee_percent: Yup.number()
            .typeError("Platform Fee Percent must be number")
            .required("Please Enter Platform Fee Percent"),
        fix_fee_amount: Yup.number()
            .typeError("Fix Fee Amount must be number")
            .required("Please Enter Fix Fee Amount"),
    });
};

export const numberOfCutoffToResetNegativeCommissionValidation = () => {
    return Yup.object({
        value: Yup.number()
            .min(0, "Input must be greater than equal to 0.")
            .test("integer", "Please enter an integer value", (value) =>
                integerValidation(value)
            )
            .required("this field is required"),
    });
};

export const integerValidation = (value) => {
    if (!Number.isInteger(value) || String(value).includes(".")) {
        return false;
    }
    return true;
};

export const promotionValidation = (isUpdate = false) => {
    let validation = {
        // promotion_category: Yup.string().required(
        //     "Please Select Promotion Category"
        // ),
        name: Yup.string().required("Please Enter Name"),
        start_datetime: Yup.date()
            .required("Please Enter Start Date")
            .min(new Date(), "Ensure that the Start Date Time is set to a future date and time"),
        end_datetime: Yup.date()
            .required("Please Enter End Date")
            .min(
                Yup.ref("start_datetime"),
                "End Date can't be before Start date"
            ),
        banner: Yup.mixed().when('name', {
            is: () => !isUpdate,
            then: Yup.mixed().required('Banner is required.'),
        }),
        thumbnail: Yup.mixed().when('name', {
            is: () => !isUpdate,
            then: Yup.mixed().required('Thumbnail is required.'),
        }),
    };

    if (isUpdate) {
        validation = {
            ...validation,
            start_datetime: Yup.date().required("Please Enter Start Date"),
            end_datetime: Yup.date()
                .required("Please Enter End Date")
                .min(
                    Yup.ref("start_datetime"),
                    "End Date can't be before Start date"
                ),
        };
    }

    return Yup.object(validation);
};

export const fileInputFormValidation = (field) => Yup.object({
    [field]: Yup.mixed().required(),
})

export const promotionUpdateCommonValidation = () => {
    return Yup.object({
        name: Yup.string().required("Please Enter Name"),
    });
};

export const promotionUpdateDateRangeValidation = () => {
    return Yup.object({
        start_datetime: Yup.date()
        .required("Please Enter Start Date")
        .min(new Date(), "Ensure that the Start Date Time is set to a future date and time"),
        end_datetime: Yup.date()
            .required("Please Enter End Date")
            .min(
                Yup.ref("start_datetime"),
                "End Date can't be before Start date"
            ),
    });
};

export const sendAnnouncementValidation = () => Yup.object({
    subject: Yup.string().required("Please Enter Subject"),
    email_message: Yup.string().required("Please Enter Email Message"),
    sms_message: Yup.string().required("Please Enter SMS Message"),
    test_mobile_no: Yup.string().when(['is_test_mode'], {
        is: (showEmail) => showEmail,
        then: (schema) => schema.typeError("Mobile number must be number")
        .matches(/^(09|\+639)\d{9}$/, "Invalid mobile number")
        .required('Please Enter Name')
    }),
    test_email: Yup.string().when(['is_test_mode'], {
        is: (isTestMode) => isTestMode,
        then: (schema) => schema.email("Please Enter Valid Email").required('Please Enter Name')
    }),
    test_name: Yup.string().when(['is_test_mode'], {
        is: (isTestMode) => isTestMode,
        then: (schema) => schema.required('Please Enter Name')
    }),
    operators: Yup.array().when(['is_test_mode'], {
        is: (isTestMode) => !isTestMode,
        then: Yup.array().min(1, "Field must have at least 1 item")
    }),
    user_types: Yup.array().when(['is_test_mode', 'is_downline'], {
        is: (isTestMode, isDownline) => !isTestMode && isDownline,
        then: (schema) => schema.min(1, "Field must have at least 1 item")
    }),
});

export const sendMessageValidation = () => Yup.object({
    title: Yup.string().required("Please Enter Title"),
    description: Yup.string().required("Please Enter Description"),
    sms_description: Yup.string().when(['sms_enabled'], {
        is: (smsEnabled) => smsEnabled,
        then: (schema) => schema.required("Please Enter SMS Description")
    }),
    message_type: Yup.string().required("Please Select Message Type"),
    banner: Yup.mixed(),
    test_mobile_no: Yup.string().when(['is_test_mode'], {
        is: (isTestMode) => isTestMode,
        then: (schema) => schema.typeError("Mobile number must be number")
        .matches(/^(09|\+639)\d{9}$/, "Invalid mobile number")
        .required('Please Enter Name')
    }),
    test_email: Yup.string().when(['is_test_mode'], {
        is: (isTestMode) => isTestMode,
        then: (schema) => schema.email("Please Enter Valid Email").required('Please Enter Name')
    }),
    test_name: Yup.string().when(['is_test_mode'], {
        is: (isTestMode) => isTestMode,
        then: (schema) => schema.required('Please Enter Name')
    }),
});

export const updateSendMessageValidation = () => Yup.object({
    title: Yup.string().required("Please Enter Title"),
    description: Yup.string().required("Please Enter Description"),
});
