import {
    Button,
    ErrorAlert,
    InputAmount,
    InputReactSelect,
    InputSelect,
    InputText,
    TextArea,
} from "@/components/atoms";
import { PinCodeModal } from "@/components/molecules";
import { directWithdrawalRequestValidation } from "@/constants";
import {
    getAllowedInWithdrawalPaymentProviders,
    getPaymentMethodData,
    resetDirectWithdrawalErrors,
    directWithdrawal,
} from "@/store/actions";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button as ButtonComponent,
    Col,
    Form,
    FormGroup,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
} from "reactstrap";

const DirectWithdrawalModal = ({ isOpen, setIsOpen, initialize }) => {
    const dispatch = useDispatch();
    const { paymentMethod } = useSelector((state) => state.transaction);

    const [bank, setBank] = useState({});

    const { allowedInWithdrawalPaymentProviders } = useSelector(
        (state) => state.paymentSetting
    );

    const { errors: directWithdrawalErrors, isLoading } = useSelector(
        (state) => state.admin
    );

    const [isPinCodeModalOpen, setIsPinCodeModalOpen] = useState(false);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            payment_provider: "",
            payment_method: "",
            balance_category: "",
            account_no: "",
            account_name: "",
            amount: "",
            remarks: "",
        },
        validationSchema: directWithdrawalRequestValidation(),
        onSubmit: () => {
            setIsPinCodeModalOpen(true);
        },
    });

    useEffect(() => {
        if (!directWithdrawalErrors?.pin_code) {
            setIsPinCodeModalOpen(false);
        }
    }, [directWithdrawalErrors, setIsPinCodeModalOpen]);

    const handleSubmit = (pin_code) => {
        dispatch(
            directWithdrawal({ pin_code, ...validation.values }, initialize)
        );
    };

    useEffect(() => {
        dispatch(getAllowedInWithdrawalPaymentProviders());
        dispatch(getPaymentMethodData());
        dispatch(resetDirectWithdrawalErrors());
    }, [dispatch]);

    return (
        <>
            <Modal
                id="DirectWithdrawalModal"
                isOpen={isOpen}
                toggle={() => setIsOpen(!isOpen)}
            >
                <ModalHeader>
                    <div className="modal-title">Direct Withdrawal</div>
                </ModalHeader>
                <ModalBody>
                    <Form
                        className="needs-validation"
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                    >
                        <ErrorAlert
                            errors={
                                directWithdrawalErrors?.pin_code
                                    ? {}
                                    : directWithdrawalErrors
                            }
                            validation={validation}
                        />
                        <Row className="mb-3">
                            <Col md="12">
                                <FormGroup className="mb-3">
                                    <InputSelect
                                        label="Payment Provider"
                                        name="payment_provider"
                                        validation={validation}
                                        options={[
                                            {
                                                label: "Select Payment Provider",
                                                value: "",
                                            },
                                            ...allowedInWithdrawalPaymentProviders.map(
                                                (item) => {
                                                    return {
                                                        label: item.name,
                                                        value: item.key_name,
                                                    };
                                                }
                                            ),
                                        ]}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="12">
                                <FormGroup className="mb-3">
                                    <InputReactSelect
                                        label="Banks"
                                        name="payment_method"
                                        onChange={(option) => {
                                            validation.setFieldValue(
                                                "payment_method",
                                                option.value
                                            );
                                            setBank(option);
                                        }}
                                        value={
                                            validation.values.payment_method
                                                ? bank
                                                : ""
                                        }
                                        options={paymentMethod}
                                        validation={validation}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="12">
                                <FormGroup className="mb-3">
                                    <InputText
                                        label="Account No."
                                        name="account_no"
                                        validation={validation}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="12">
                                <FormGroup className="mb-3">
                                    <InputText
                                        label="First Name"
                                        name="account_first_name"
                                        validation={validation}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="12">
                                <FormGroup className="mb-3">
                                    <InputText
                                        label="Last Name"
                                        name="account_last_name"
                                        validation={validation}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <FormGroup className="mb-3">
                                    <InputAmount
                                        label="Amount"
                                        name="amount"
                                        validation={validation}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <FormGroup className="mb-3">
                                    <TextArea
                                        label="Remarks"
                                        name="remarks"
                                        validation={validation}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-end">
                                <ButtonComponent
                                    color="light"
                                    onClick={() => setIsOpen(!isOpen)}
                                    className="me-2"
                                >
                                    Close
                                </ButtonComponent>
                                <Button
                                    color="success"
                                    type="submit"
                                    text="Submit"
                                />
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>
            {isPinCodeModalOpen && (
                <PinCodeModal
                    errors={
                        directWithdrawalErrors?.pin_code
                            ? { pin_code: directWithdrawalErrors?.pin_code }
                            : {}
                    }
                    isLoading={isLoading}
                    isOpen={isPinCodeModalOpen}
                    setIsOpen={setIsPinCodeModalOpen}
                    onHandleSubmit={handleSubmit}
                />
            )}
        </>
    );
};

export default DirectWithdrawalModal;
