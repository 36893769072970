import { all, fork } from "redux-saga/effects";
//layout
import addressSagas from "./address/sagas";
import adminSagas from "./admin/sagas";
import operatorSagas from "./operator/sagas";
import agentSagas from "./agent/sagas";
import gameSagas from "./game/sagas";
import layoutSagas from "./layout/sagas";
import playerSagas from "./player/sagas";
import requestSagas from "./request/sagas";
import settingSagas from "./setting/sagas";
import transactionSagas from "./transaction/sagas";
import userSagas from "./user/sagas";
import reportSagas from "./report/sagas";
import dashboardSagas from "./dashboard/sagas";
import countrySagas from "./country/sagas";
import paymentSettingSagas from "./payment-setting/sagas";
import promotionSagas from "./promotion/sagas";
import hotMatchesSettingSagas from "./hot-match-setting/sagas";
import announcementSagas from "./announcement/sagas";
import lottoSettingSagas from "./lotto-setting/sagas";

export function* watchSagas() {
    yield all([
        fork(layoutSagas),
        fork(userSagas),
        fork(addressSagas),
        fork(operatorSagas),
        fork(agentSagas),
        fork(adminSagas),
        fork(playerSagas),
        fork(transactionSagas),
        fork(requestSagas),
        fork(settingSagas),
        fork(gameSagas),
        fork(reportSagas),
        fork(dashboardSagas),
        fork(countrySagas),
        fork(paymentSettingSagas),
        fork(promotionSagas),
        fork(hotMatchesSettingSagas),
        fork(announcementSagas),
        fork(lottoSettingSagas)
    ]);
}
