import { Button, ErrorAlert, FlatPickerDate } from "@/components/atoms";
import { promotionUpdateDateRangeValidation } from "@/constants";
import { updatePromotion } from "@/store/actions";
import { useFormik } from "formik";
import React, { useRef, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Col, Form, FormGroup, Row, Label } from "reactstrap";
import moment from "moment";

const UpdatePromotionDateRange = ({
  selectedPromotion,
  isSubmit,
  initialize,
  errors,
}) => {
  const startDateRef = useRef();
  const endDateRef = useRef();
  const dispatch = useDispatch();

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      start_datetime: selectedPromotion?.start_datetime,
      end_datetime: selectedPromotion?.end_datetime,
    },
    validationSchema: promotionUpdateDateRangeValidation(),
    onSubmit: (values) => {
      dispatch(
        updatePromotion(
          selectedPromotion?.key_token,
          values,
          "update-validty-date",
          initialize
        )
      );
    },
  });

  const disabledDate = useMemo(
    () => moment(selectedPromotion?.start_datetime) < moment(),
    [selectedPromotion?.start_datetime]
  );

  return (
    <div>
      {errors && <ErrorAlert errors={errors} validation={validation} />}

      <Form
        className="needs-validation"
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        <Row>
          <Col md="6">
            <FormGroup className="mb-3">
              {selectedPromotion?.start_datetime && (
                <FlatPickerDate
                  disabled={selectedPromotion?.is_ongoing || disabledDate}
                  label="Start Date"
                  name="start_datetime"
                  ref={startDateRef}
                  form={validation}
                  mode="default"
                  enableTime={true}
                  altFormat="M j, Y H:i:S"
                  minDate={null}
                  maxDate={null}
                  value={validation.values.start_datetime}
                />
              )}
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup className="mb-3">
              {selectedPromotion?.start_datetime && (
                <FlatPickerDate
                  disabled={selectedPromotion?.is_ongoing || disabledDate}
                  label="End Date"
                  name="end_datetime"
                  ref={endDateRef}
                  form={validation}
                  mode="default"
                  enableTime={true}
                  altFormat="M j, Y H:i:S"
                  minDate={null}
                  maxDate={null}
                  value={validation.values.end_datetime}
                />
              )}
            </FormGroup>
          </Col>
        </Row>
        <div className="border-top py-3 mt-3">
          <Button
            color="success"
            type="submit"
            className="float-end"
            text="Submit"
            isLoading={isSubmit}
          />
        </div>
      </Form>
    </div>
  );
};

export default UpdatePromotionDateRange;
