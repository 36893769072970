import { userForgotPassword } from "@/store/actions";
import { confirmation } from "@/components/constants/common";
import { getResetPasswordLink, getPlayerResetPasswordLink } from "@/utils";
import React from "react";
import { useDispatch } from "react-redux";
import { Button } from "reactstrap";

const PasswordResetButton = ({ email, isLoading, userType }) => {
    const dispatch = useDispatch();

    const sendPasswordResetLink = () => {
        let values = {
            email: email,
            url:
                userType === "player"
                    ? getPlayerResetPasswordLink()
                    : getResetPasswordLink(),
        };
        confirmation({
            title: "Confirmation Message",
            text: "Are you sure you want to send a password reset link?",
            icon: "warning",
            confirmButtonText: "Send now",
            action: () => dispatch(userForgotPassword(values)),
        });
    };
    return (
        <>
            <Button
                disabled={isLoading}
                type="button"
                color="primary"
                size="sm"
                className="me-2 float-end"
                onClick={() => sendPasswordResetLink()}
            >
                <span className="ri-mail-line align-middle"></span> Send
                Password Reset Link
            </Button>
        </>
    );
};

export default PasswordResetButton;
