import {
  Button,
  ErrorAlert,
  FlatPickerDate,
  InputAmount,
  InputReactSelect,
  InputSwitch,
  InputText,
  RichTextArea,
  FileInputPreview,
  TextArea,
} from "@/components/atoms";
import { promotionValidation } from "@/constants";
import {
  createPromotion,
  getPromotionCategoryByKeyToken,
  getPromotionCategoryList,
} from "@/store/actions";
import { filterDateTimeFormat } from "@/utils";
import { useFormik } from "formik";
import "quill/dist/quill.snow.css";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Form, FormGroup, Row, Label } from "reactstrap";

const CreateOrUpdatePromotionForm = () => {
  const startDateRef = useRef();
  const endDateRef = useRef();
  const dispatch = useDispatch();
  const [promotionCategory, setPromotionCategory] = useState("");
  const [isPreview, setIsPreview] = useState(false);

  const {
    isSubmit,
    errors,
    promotionCategoryList,
    selectedPromotionCategory,
    selectedPromotion,
  } = useSelector((state) => state.promotion);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      promotion_category: "",
      name: "",
      start_datetime: "",
      end_datetime: "",
      is_active: 0,
      description: "",
      settings_deposit_requirement: null,
      settings_bonus_percent: null,
      settings_max_cap_amount: null,
      settings_turnover_multiplier: null,
      settings_opt_in_expiration: null,
      banner: "",
      thumbnail: "",
    },
    validationSchema: promotionValidation(selectedPromotion !== null),
    onSubmit: (values) => {
      // console.log(values)
      !selectedPromotion && dispatch(createPromotion(values, initialize));
    },
  });

  const { setValues, setFieldValue, values } = validation;

  const initialize = () => {
    validation.resetForm();
    setPromotionCategory("");
  };

  useEffect(() => {
    dispatch(getPromotionCategoryList());
  }, [dispatch]);

  useEffect(() => {
    if (promotionCategory) {
      dispatch(getPromotionCategoryByKeyToken(promotionCategory.value));
    }
  }, [dispatch, promotionCategory]);

  useEffect(() => {
    if (selectedPromotion?.promotion_category) {
      const { promotion_category } = selectedPromotion;

      setValues({
        promotion_category: promotion_category?.key_token,
        name: selectedPromotion?.name,
        start_datetime: filterDateTimeFormat(selectedPromotion?.start_datetime),
        end_datetime: filterDateTimeFormat(selectedPromotion?.end_datetime),
        is_active: selectedPromotion?.is_active,
        description: selectedPromotion?.description,
      });

      setPromotionCategory({
        label: promotion_category.title,
        value: promotion_category.key_token,
      });

      if (selectedPromotion?.settings.length > 0) {
        /* eslint-disable */
        (selectedPromotion?.settings ?? []).map((item) => {
          setFieldValue(`settings_${item.key}`, item.value);
        });
        /* eslint-enable */
      }
    }
  }, [selectedPromotion, setValues, setFieldValue]);

  useEffect(() => {
    if (selectedPromotionCategory?.default_settings) {
      /* eslint-disable */
      (selectedPromotionCategory?.default_settings ?? []).map((item) => {
        if (values[`settings_${item.key}`] === null) {
          setFieldValue(`settings_${item.key}`, item.value);
        }
      });
      /* eslint-enable */
    }
  }, [selectedPromotionCategory, setFieldValue, values]);

  return (
    <Form
      className="needs-validation"
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      {errors && <ErrorAlert errors={errors} validation={validation} />}
      <Col md="12">
        <Row>
          <Col md="6">
            <FormGroup className="mb-3">
              <InputText
                label="Name"
                name="name"
                placeholder="Name"
                validation={validation}
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup className="mb-3">
              <InputReactSelect
                label="Promotion Category"
                name="promotion_category"
                placeholder="Select Promotion Category..."
                validation={validation}
                value={promotionCategory}
                options={(promotionCategoryList ?? []).map((item) => ({
                  label: item.title,
                  value: item.key_token,
                }))}
                onChange={(option) => {
                  setPromotionCategory(option);
                  validation.setFieldValue("promotion_category", option.value);
                }}
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup className="mb-3">
              <FlatPickerDate
                label="Start Date"
                name="start_datetime"
                ref={startDateRef}
                form={validation}
                mode="default"
                enableTime={true}
                altFormat="M j, Y H:i:S"
                minDate={null}
                maxDate={null}
                value={validation.values.start_datetime}
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup className="mb-3">
              <FlatPickerDate
                label="End Date"
                name="end_datetime"
                ref={endDateRef}
                form={validation}
                mode="default"
                enableTime={true}
                altFormat="M j, Y H:i:S"
                minDate={null}
                maxDate={null}
                value={validation.values.end_datetime}
              />
            </FormGroup>
          </Col>
          <Col md="12">
            <Row>
              <Col md="6">
                <Label htmlFor="banner">Banner</Label>
                <p className="text-muted">Recommended:</p>
                <FormGroup className="mb-3">
                  <FileInputPreview
                    name="banner"
                    validation={validation}
                    onupdatefiles={(file) =>
                      validation.setFieldValue(
                        "banner",
                        file?.[0]?.file ?? null
                      )
                    }
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <Label htmlFor="thumbnail">Thumbnail</Label>
                <p className="text-muted">Recommended: 1913 x 855</p>
                <FormGroup className="mb-3">
                  <FileInputPreview
                    name="thumbnail"
                    validation={validation}
                    onupdatefiles={(file) =>
                      validation.setFieldValue(
                        "thumbnail",
                        file?.[0]?.file ?? null
                      )
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
          </Col>
          <Col md="12">
            <FormGroup className="mb-3">
              <InputSwitch
                label="Post on promotions page?"
                id={"is-active"}
                isChecked={validation?.values.is_active}
                color="success"
                onChange={() =>
                  validation.setFieldValue(
                    "is_active",
                    validation.values.is_active === 1 ? 0 : 1
                  )
                }
              />
            </FormGroup>
          </Col>
          {(
            selectedPromotion?.settings ??
            selectedPromotionCategory?.default_settings ??
            []
          ).length > 0 && (
            <Col md="12">
              <Row>
                <p className="text-muted">Promotion Category Settings</p>
                <Row>
                  {(
                    selectedPromotion?.settings ??
                    selectedPromotionCategory?.default_settings ??
                    []
                  ).map((item, index) => (
                    <Col md="6" key={index}>
                      <FormGroup className="mb-3">
                        {["integer", "decimal"].includes(item.data_type) ? (
                          <InputAmount
                            label={item.label}
                            placeholder={
                              item.data_type === "integer" ? "0" : "0.0"
                            }
                            numericOnly={item.data_type === "integer"}
                            withCurrency={false}
                            name={`settings_${item?.key}`}
                            validation={validation}
                            value={validation.values[`settings_${item?.key}`]}
                          />
                        ) : (
                          <InputText
                            label={item.label}
                            name={`settings_${item?.key}`}
                            placeholder={item.label}
                            validation={validation}
                          />
                        )}
                      </FormGroup>
                    </Col>
                  ))}
                </Row>
              </Row>
            </Col>
          )}
          <Col md="12 mb-2">
            <div className="tw-flex tw-flex-row tw-justify-between tw-items-center">
              <Label className="m-0">Description</Label>
             <div>
                <InputSwitch
                  label="Preview Description"
                  id={"preview"}
                  isChecked={isPreview}
                  color="success"
                  onChange={() => setIsPreview(!isPreview)}
                />
              </div>
            </div>
          </Col>
          <Col md="12">
            {isPreview ? (
              <div
                className="tw-p-5 tw-bg-white"
                dangerouslySetInnerHTML={{
                  __html: validation?.values?.description,
                }}
              />
            ) : (
              <TextArea
                rows="10"
                cols="50"
                name="description"
                validation={validation}
              />
            )}
            {/* <RichTextArea
              label="Description"
              name="description"
              validation={validation}
            /> */}
          </Col>
        </Row>
      </Col>

      <Col md="12">
        <Row>
          <Col md="3"></Col>
        </Row>
      </Col>
      <div className="border-top py-3 mt-3">
        <Button
          color="success"
          type="submit"
          className="float-end"
          text="Submit"
          isLoading={isSubmit}
        />
      </div>
    </Form>
  );
};

export default CreateOrUpdatePromotionForm;
