import { AreaCharts, FlatPickerDate, LineCharts } from "@/components/atoms";
import { Routes, getPath } from "@/constants";
import { dateFilterOptions } from "@/constants/users";
import { defaultCurrency, filterDateFormat } from "@/utils/common";
import React from "react";
import { Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
// import { WinLossByAgentReport } from "@/pages";
import {
    getDailyTotalDepositAndWithdrawalSummary,
    getDailyTotalGgrSummary,
    getDashboardAnalyticsMainTotal,
    getDashboardAnalyticsOverallTotal,
    getMonthlyTotalPlayerSummary,
} from "@/store/actions";
import { useEffect, useRef, useState, useMemo } from "react";
import CountUp from "react-countup";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ProfileLayout from "../Layout";
const { BasicColumn, SplineAreaChart } = AreaCharts;
const { BasicLineCharts } = LineCharts;

const Dashboard = () => {
    const isMounted = useRef(false);
    const dispatch = useDispatch();
    const [selectedDate, setSelectedDate] = useState(dateFilterOptions[0]);
    const { profile } = useSelector((state) => state.operator);

    const {
        dashboardMainTotal,
        isGetDashboardMainTotalLoading,
        dashboardOverallTotal: overallTotal,
        isGetDashboardOverallTotalLoading,
        dailyTotalGgrSummary,
        dailyTotalDepositAndWithdrawalSummary,
        monthlyTotalPlayerSummary,
        isGetDailyTotalGgrSummaryLoading,
        isGetDailyTotalDepositAndWithdrawalSummaryLoading,
        isGetMonthlyTotalPlayerSummaryLoading,
    } = useSelector((state) => state.dashboard);

    const { user } = useSelector((state) => state.user);

    const dashboardOverallTotal = useMemo(() => overallTotal?.total, [overallTotal])

    const [data, setData] = useState({
        date_from: dateFilterOptions[0]?.value[0],
        date_to: dateFilterOptions[0]?.value[1],
    });

    const [graphGgrType, setGraphGgrType] = useState("");
    const [graphWithdrawalAndDepositType, setGraphWithdrawalAndDepositType] =
        useState("");

    useEffect(() => {
        if (profile) {
            dispatch(getDashboardAnalyticsMainTotal(profile.keyToken));
        }
    }, [dispatch, profile]);

    useEffect(() => {
        if (isMounted.current) {
            if (selectedDate?.value && selectedDate?.value.length === 2) {
                setData((data) => ({
                    ...data,
                    date_from: selectedDate.value[0],
                    date_to: selectedDate.value[1],
                }));
            }
        } else isMounted.current = true;
    }, [selectedDate]);

    useEffect(() => {
        if (profile) {
            dispatch(
                getDashboardAnalyticsOverallTotal({
                    params: {
                        ...data,
                        summary_type: "total",
                        balance_category: "merchant",
                      },
                    keyToken: profile.keyToken,
                })
            );
            dispatch(
                getDashboardAnalyticsOverallTotal({
                    params: {
                        ...data,
                        summary_type: "total",
                        balance_category: "bcf",
                      },
                    keyToken: profile.keyToken,
                })
            );
            dispatch(
                getMonthlyTotalPlayerSummary({
                    params: data,
                    keyToken: profile.keyToken,
                })
            );
        }
    }, [dispatch, data, profile]);

    useEffect(() => {
        if (profile) {
            dispatch(
                getDailyTotalGgrSummary({
                    params: {
                        date_from: data.date_from,
                        date_to: data.date_to,
                    },
                    keyToken: profile.keyToken,
                })
            );
        }
    }, [dispatch, data, profile]);

    useEffect(() => {
        if (profile) {
            dispatch(
                getDailyTotalDepositAndWithdrawalSummary({
                    params: {
                        date_from: data.date_from,
                        date_to: data.date_to,
                    },
                    keyToken: profile.keyToken,
                })
            );
        }
    }, [dispatch, data, profile]);

    const mainTotals = [
        {
            id: 1,
            label: `Current Balance ${
                ["super-admin", "operator"].includes(user?.role?.slug)
                    ? "(Operator Chip)"
                    : ""
            }`,
            labelClass: "tw-text-[#D6D8E1]",
            counter: dashboardMainTotal?.current_balance,
            counterClass: "tw-text-[#A400DE]",
            caption: "",
            link: "/",
            decimals: 2,
            prefix: `<i class="ri-wallet-line tw-text-[#A400DE]"></i> ${defaultCurrency()}`,
            suffix: "",
            separator: ",",
            bgColor: "tw-bg-[#A400DE]/20",
        },
        {
            id: 2,
            label: "Pending withdrawal amount",
            labelClass: "tw-text-[#D6D8E1]",
            counter: dashboardMainTotal?.pending_withdrawal_amount,
            counterClass: "tw-text-[#5E8AFC]",
            caption: "Process requests",
            link: getPath(Routes.WITHDRAWAL_REQUEST),
            decimals: 2,
            prefix: `<i class="ri-wallet-line tw-text-[#5E8AFC]"></i> ${defaultCurrency()}`,
            suffix: "",
            separator: ",",
            bgColor: "tw-bg-[#4473EC]/20",
        },
        {
            id: 3,
            label: "Lifetime Gaming Revenue",
            labelClass: "tw-text-[#D6D8E1]",
            counter: dashboardMainTotal?.lifetime_ggr,
            counterClass: "tw-text-[#F7B84B]",
            caption: "",
            link: "/",
            decimals: 2,
            prefix: `<i class="ri-wallet-line tw-text-[#F7B84B]"></i> ${defaultCurrency()}`,
            suffix: "",
            separator: ",",
            bgColor: "tw-bg-[#F7B84B]/20",
        },
        {
            id: 4,
            label: `Player Balance (${process.env.REACT_APP_BCF_LABEL})`,
            labelClass: "muted",
            counter: dashboardMainTotal?.total_player_bcf,
            caption: "",
            link: "/",
            decimals: 2,
            prefix: `<i class="ri-wallet-line"></i> ${defaultCurrency()}`,
            suffix: "",
            separator: ",",
            bgColor: "tw-bg-black/20",
        },
        {
            id: 5,
            label: "Player Balance (PHP)",
            labelClass: "muted",
            counter: dashboardMainTotal?.total_player_merchant,
            caption: "",
            link: "/",
            decimals: 2,
            prefix: `<i class="ri-wallet-line"></i> ${defaultCurrency()}`,
            suffix: "",
            separator: ",",
            bgColor: "tw-bg-black/20",
        },
        {
            id: 6,
            label: "Total Player Balance",
            labelClass: "muted",
            counter: dashboardMainTotal?.total_player_chips,
            caption: "",
            link: "/",
            decimals: 2,
            prefix: `<i class="ri-wallet-line"></i> ${defaultCurrency()}`,
            suffix: "",
            separator: ",",
            bgColor: "tw-bg-black/20",
        },
    ];

    const overallTotalMerchant = [
        {
            id: 1,
            label: "Overall Total GGR",
            labelClass: "muted",
            percentage: "+16.24 %",
            percentageClass: "success",
            percentageIcon: "ri-arrow-right-up-line",
            counter: dashboardOverallTotal?.merchant?.ggr,
            caption: "View Details",
            link: getPath(Routes.WIN_LOSS_BY_AGENT_REPORT),
            icon: "ri-wallet-line",
            iconClass: "warning",
            decimals: 2,
            prefix: defaultCurrency(),
            suffix: "",
            separator: ",",
        },
        {
            id: 2,
            label: "Overall Total Turnover",
            labelClass: "muted",
            percentage: "+42.24 %",
            percentageClass: "success",
            percentageIcon: "ri-arrow-right-up-line",
            counter: dashboardOverallTotal?.merchant?.turnover,
            caption: "View Details",
            link: getPath(Routes.WIN_LOSS_BY_AGENT_REPORT),
            icon: "ri-wallet-line",
            iconClass: "success",
            decimals: 2,
            prefix: defaultCurrency(),
            suffix: "",
            separator: ",",
        },
        {
            id: 3,
            label: "Overall Total Deposit",
            labelClass: "muted",
            percentage: "+14.34 %",
            percentageClass: "muted",
            counter: dashboardOverallTotal?.merchant?.deposit,
            caption: "View Details",
            link: getPath(Routes.TRANSACTIONS),
            icon: "ri-wallet-line",
            iconClass: "primary",
            decimals: 2,
            prefix: defaultCurrency(),
            separator: ",",
            suffix: "",
        },
        {
            id: 4,
            label: "Overall Total Withdrawal",
            labelClass: "muted",
            percentage: "+21.20 %",
            percentageClass: "muted",
            counter: dashboardOverallTotal?.merchant?.withdrawal,
            caption: "View Details",
            link: getPath(Routes.TRANSACTIONS),
            icon: "ri-wallet-line",
            iconClass: "primary",
            decimals: 2,
            prefix: defaultCurrency(),
            separator: ",",
            suffix: "",
        },
    ];

    const overallTotalBcf = [
        {
            id: 1,
            label: "Overall Total GGR",
            labelClass: "muted",
            percentage: "+16.24 %",
            percentageClass: "success",
            percentageIcon: "ri-arrow-right-up-line",
            counter: dashboardOverallTotal?.bcf?.ggr,
            caption: "View Details",
            link: getPath(Routes.WIN_LOSS_BY_AGENT_REPORT),
            icon: "ri-wallet-line",
            iconClass: "warning",
            decimals: 2,
            prefix: defaultCurrency(),
            suffix: "",
            separator: ",",
        },
        {
            id: 2,
            label: "Overall Total Turnover",
            labelClass: "muted",
            percentage: "+42.24 %",
            percentageClass: "success",
            percentageIcon: "ri-arrow-right-up-line",
            counter: dashboardOverallTotal?.bcf?.turnover,
            caption: "View Details",
            link: getPath(Routes.WIN_LOSS_BY_AGENT_REPORT),
            icon: "ri-wallet-line",
            iconClass: "success",
            decimals: 2,
            prefix: defaultCurrency(),
            suffix: "",
            separator: ",",
        },
        {
            id: 3,
            label: "Overall Total Deposit",
            labelClass: "muted",
            percentage: "+14.34 %",
            percentageClass: "muted",
            counter: dashboardOverallTotal?.bcf?.deposit,
            caption: "View Details",
            link: getPath(Routes.TRANSACTIONS),
            icon: "ri-wallet-line",
            iconClass: "primary",
            decimals: 2,
            prefix: defaultCurrency(),
            separator: ",",
            suffix: "",
        },
        {
            id: 4,
            label: "Overall Total Withdrawal",
            labelClass: "muted",
            percentage: "+21.20 %",
            percentageClass: "muted",
            counter: dashboardOverallTotal?.bcf?.withdrawal,
            caption: "View Details",
            link: getPath(Routes.TRANSACTIONS),
            icon: "ri-wallet-line",
            iconClass: "primary",
            decimals: 2,
            prefix: defaultCurrency(),
            separator: ",",
            suffix: "",
        },
    ];

    const graphGgrTypeOptions = [
        {
            label: "All",
            value: "",
        },
        {
            label: "Merchant",
            value: "merchant",
        },
        {
            label: "BCF",
            value: "bcf",
        },
    ];

    const graphWithdrawalAndDepositTypeOptions = [
        {
            label: "All",
            value: "",
        },
        {
            label: "Merchant Deposit",
            value: "merchant_deposit",
        },
        {
            label: "BCF Deposit",
            value: "bcf_deposit",
        },
        {
            label: "Merchant Withdrawal",
            value: "merchant_withdrawal",
        },
        {
            label: "BCF Withdrawal",
            value: "bcf_withdrawal",
        },
    ];

    return (
        <ProfileLayout>
            <Row>
                {(mainTotals || []).map((item, key) => (
                    <Col xl={4} md={6} key={key}>
                        <Card
                            className={`card-animate tw-h-[150px] ${item.bgColor}`}
                        >
                            <CardBody>
                                <div className="d-flex align-items-center">
                                    <div className="flex-grow-1">
                                        <p
                                            className={
                                                "text-uppercase fw-medium mb-0 text-" +
                                                item.labelClass
                                            }
                                        >
                                            {item.label}
                                        </p>
                                    </div>
                                </div>
                                <div className="d-flex align-items-end justify-content-between mt-4">
                                    <div>
                                        <h4
                                            className={
                                                "fs-22 fw-semibold ff-secondary mb-4 " +
                                                item.counterClass
                                            }
                                        >
                                            {!isGetDashboardMainTotalLoading ? (
                                                <span
                                                    className="counter-value"
                                                    data-target="559.25"
                                                >
                                                    <CountUp
                                                        start={0}
                                                        prefix={item.prefix}
                                                        suffix={item.suffix}
                                                        separator={
                                                            item.separator
                                                        }
                                                        end={item.counter}
                                                        decimals={item.decimals}
                                                        duration={4}
                                                    />
                                                </span>
                                            ) : (
                                                <span className="tw-animate-pulse">
                                                    Loading...
                                                </span>
                                            )}
                                        </h4>
                                        {item.link && (
                                            <Link
                                                to={item.link}
                                                className={
                                                    "text-decoration-underline"
                                                }
                                            >
                                                {item.caption}
                                            </Link>
                                        )}
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                ))}
            </Row>
            <Row className="mb-3">
                <Col xl={12}>
                    <div className="tw-flex tw-flex-wrap tw-items-center tw-gap-2">
                        {dateFilterOptions.map((item, index) => (
                            <Button
                                key={index}
                                color="light"
                                className={
                                    selectedDate.label === item.label
                                        ? `tw-text-[#ECB844] tw-bg-[#ECB844]/5 tw-border tw-border-[#ECB844]/50`
                                        : ""
                                }
                                onClick={() => {
                                    setSelectedDate(item);
                                }}
                            >
                                {item.label}
                            </Button>
                        ))}
                        <div className="tw-min-w-[200px]">
                            <FlatPickerDate
                                name="dateFilter"
                                mode="range"
                                placeholder="From Date - To Date"
                                maxDate={null}
                                onChange={(date) => {
                                    setSelectedDate((data) => ({
                                        ...data,
                                        label: "Custom",
                                        value: date.map((value) =>
                                            filterDateFormat(value)
                                        ),
                                    }));
                                }}
                                value={selectedDate.value}
                            />
                        </div>
                    </div>
                </Col>
                <Col xl={4}></Col>
            </Row>
            <Card className="p-3">
                <Row className="mb-2">
                    <Col className="tw-font-bold tw-text-lg">
                        {selectedDate.label} Overall Total
                    </Col>
                </Row>
                <Row className="tw-px-2">
                    <Col>
                        <Row className="tw-text-lg tw-pl-4 tw-text-[#B572CD] tw-font-semibold">
                            Merchant
                        </Row>
                        <Row className="tw-pl-8 tw-gap-2 tw-grid tw-grid-cols-1 md:tw-grid-cols-2">
                            {(overallTotalMerchant || []).map((item, key) => (
                                <div key={key} className="p-1">
                                    <Card
                                        className={
                                            "card-animate tw-border tw-border-[#C746F5]"
                                        }
                                    >
                                        <CardBody>
                                            <div className="d-flex align-items-center">
                                                <div className="flex-grow-1">
                                                    <p
                                                        className={
                                                            "text-uppercase fw-medium mb-0 text-" +
                                                            item.labelClass
                                                        }
                                                    >
                                                        {item.label}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-end justify-content-between mt-4">
                                                <div>
                                                    <h4
                                                        className={
                                                            "fs-22 fw-semibold ff-secondary mb-4 " +
                                                            item.counterClass
                                                        }
                                                    >
                                                        {!isGetDashboardOverallTotalLoading ? (
                                                            <span
                                                                className="counter-value"
                                                                data-target="559.25"
                                                            >
                                                                <CountUp
                                                                    start={0}
                                                                    prefix={
                                                                        item.prefix
                                                                    }
                                                                    suffix={
                                                                        item.suffix
                                                                    }
                                                                    separator={
                                                                        item.separator
                                                                    }
                                                                    end={
                                                                        item.counter
                                                                    }
                                                                    decimals={
                                                                        item.decimals
                                                                    }
                                                                    duration={4}
                                                                />
                                                            </span>
                                                        ) : (
                                                            <span className="tw-animate-pulse">
                                                                Loading...
                                                            </span>
                                                        )}
                                                    </h4>
                                                    {item.link ? (
                                                        <Link
                                                            to={item.link}
                                                            className={
                                                                "text-decoration-underline " +
                                                                item.captionClass
                                                            }
                                                        >
                                                            {item.caption}
                                                        </Link>
                                                    ) : (
                                                        <span>-</span>
                                                    )}
                                                </div>
                                                <div className="avatar-sm flex-shrink-0">
                                                    <span
                                                        className={
                                                            "avatar-title rounded fs-3 bg-soft-" +
                                                            item.iconClass
                                                        }
                                                    >
                                                        <i
                                                            className={
                                                                item.icon +
                                                                " text-" +
                                                                item.iconClass
                                                            }
                                                        ></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>
                            ))}
                        </Row>
                    </Col>
                </Row>
                <Row className="tw-px-2">
                    <Col>
                        <Row className="tw-text-lg tw-pl-4 tw-text-[#B2CDFF] tw-font-semibold">
                            BCF
                        </Row>
                        <Row className="tw-pl-8 tw-gap-2 tw-grid tw-grid-cols-1 md:tw-grid-cols-2">
                            {(overallTotalBcf || []).map((item, key) => (
                                <div key={key} className="p-1">
                                    <Card
                                        className={
                                            "card-animate tw-border tw-border-[#455D92]"
                                        }
                                    >
                                        <CardBody>
                                            <div className="d-flex align-items-center">
                                                <div className="flex-grow-1">
                                                    <p
                                                        className={
                                                            "text-uppercase fw-medium mb-0 text-" +
                                                            item.labelClass
                                                        }
                                                    >
                                                        {item.label}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-end justify-content-between mt-4">
                                                <div>
                                                    <h4
                                                        className={
                                                            "fs-22 fw-semibold ff-secondary mb-4 " +
                                                            item.counterClass
                                                        }
                                                    >
                                                        {!isGetDashboardOverallTotalLoading ? (
                                                            <span
                                                                className="counter-value"
                                                                data-target="559.25"
                                                            >
                                                                <CountUp
                                                                    start={0}
                                                                    prefix={
                                                                        item.prefix
                                                                    }
                                                                    suffix={
                                                                        item.suffix
                                                                    }
                                                                    separator={
                                                                        item.separator
                                                                    }
                                                                    end={
                                                                        item.counter
                                                                    }
                                                                    decimals={
                                                                        item.decimals
                                                                    }
                                                                    duration={4}
                                                                />
                                                            </span>
                                                        ) : (
                                                            <span className="tw-animate-pulse">
                                                                Loading...
                                                            </span>
                                                        )}
                                                    </h4>
                                                    {item.link ? (
                                                        <Link
                                                            to={item.link}
                                                            className={
                                                                "text-decoration-underline " +
                                                                item.captionClass
                                                            }
                                                        >
                                                            {item.caption}
                                                        </Link>
                                                    ) : (
                                                        <span>-</span>
                                                    )}
                                                </div>
                                                <div className="avatar-sm flex-shrink-0">
                                                    <span
                                                        className={
                                                            "avatar-title rounded fs-3 bg-soft-" +
                                                            item.iconClass
                                                        }
                                                    >
                                                        <i
                                                            className={
                                                                item.icon +
                                                                " text-" +
                                                                item.iconClass
                                                            }
                                                        ></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>
                            ))}
                        </Row>
                    </Col>
                </Row>
            </Card>
            <Row>
                <Col md={12}>
                    <Card>
                        <CardHeader className="tw-flex tw-justify-between tw-items-center py-2 my-1">
                            <h4 className="card-title mb-0">
                                Gross Gaming Revenue
                            </h4>
                            <div className="tw-flex tw-items-center gap-2">
                                <div>Filters:</div>
                                {graphGgrTypeOptions.map((item, index) => (
                                    <Button
                                        key={index}
                                        size="sm"
                                        onClick={() =>
                                            setGraphGgrType(item.value)
                                        }
                                        color="light"
                                        className={
                                            graphGgrType === item.value
                                                ? "tw-text-[#ECB844] tw-bg-[#ECB844]/5 tw-border tw-border-[#ECB844]/50"
                                                : ""
                                        }
                                    >
                                        {item.label}
                                    </Button>
                                ))}
                            </div>
                        </CardHeader>
                        <CardBody>
                            <div>
                                <BasicLineCharts
                                    isLoading={isGetDailyTotalGgrSummaryLoading}
                                    data={dailyTotalGgrSummary}
                                    dataColors={["#8A339E", "#30426E"]}
                                    type={graphGgrType}
                                />
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col md={12}>
                    <Card>
                        <CardHeader className="tw-flex tw-flex-col py-2 my-1 tw-space-y-2">
                            <h4 className="card-title mb-0">
                                Deposit & Withdrawal
                            </h4>
                            <div className="tw-flex tw-items-center gap-2">
                                <div>Filters:</div>
                                {graphWithdrawalAndDepositTypeOptions.map(
                                    (item, index) => (
                                        <Button
                                            size="sm"
                                            key={index}
                                            onClick={() =>
                                                setGraphWithdrawalAndDepositType(
                                                    item.value
                                                )
                                            }
                                            color="light"
                                            className={
                                                graphWithdrawalAndDepositType ===
                                                item.value
                                                    ? "tw-text-[#ECB844] tw-bg-[#ECB844]/5 tw-border tw-border-[#ECB844]/50"
                                                    : ""
                                            }
                                        >
                                            {item.label}
                                        </Button>
                                    )
                                )}
                            </div>
                        </CardHeader>
                        <CardBody>
                            <SplineAreaChart
                                isLoading={
                                    isGetDailyTotalDepositAndWithdrawalSummaryLoading
                                }
                                data={dailyTotalDepositAndWithdrawalSummary}
                                dataColors={[
                                    "#336696",
                                    "#0AB39C",
                                    "#BA48F0",
                                    "#F06548",
                                ]}
                                type={graphWithdrawalAndDepositType}
                            />
                        </CardBody>
                    </Card>
                </Col>
                <Col md={12}>
                    <Card>
                        <CardHeader>
                            <h4 className="card-title mb-0">Players</h4>
                        </CardHeader>
                        <CardBody>
                            <BasicColumn
                                isLoading={
                                    isGetMonthlyTotalPlayerSummaryLoading
                                }
                                data={monthlyTotalPlayerSummary}
                                dataColors='["--vz-success", "--vz-primary", "--vz-warning"]'
                            />
                        </CardBody>
                    </Card>
                </Col>
                {/* <Col md={12}>
                    <Card>
                        <CardHeader>
                            <h4 className="card-title mb-0">Win/Loss</h4>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col>
                                    <WinLossByAgentReport
                                        filter={data}
                                        dashboard
                                    />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col> */}
            </Row>
        </ProfileLayout>
    );
};

export default Dashboard;
