import { InputSwitch, Table, Title } from "@/components/atoms";
import BreadCrumb from "@/components/common/BreadCrumb";
import { confirmation } from "@/components/constants/common";
import { Routes, getPath, getPathTitle } from "@/constants";
import { getLeagueTypeList, updateLeagueTypeStatus } from "@/store/actions";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

const LeagueTypeList = () => {
    const dispatch = useDispatch();
    const { leagueTypeList, isLoading } = useSelector(
        (state) => state.hotMatchesSetting
    );
    const [search, setSearch] = useState("");
    const [sortBy, setSortBy] = useState({
        field: "",
        type: "asc",
    });
    const [data, setData] = useState({
        per_page: 10,
        page: 1,
        search: "",
    });

    const tableTheadData = [
        {
            label: "Name",
            field: "name",
            sortable: true,
        },
        {
            label: "Status",
            field: "status",
            sortable: false,
            render: (item) => (
                <div className="d-flex w-100 justify-content-center">
                    <InputSwitch
                        label={item.status === "active" ? "Yes" : "No"}
                        id={`promotion-status-${item.slug}`}
                        isChecked={item.status === "active" }
                        color="success"
                        onChange={() => changeStatus(item)}
                    />
                </div>
            ),
        },
    ];

    const initialize = () => {
        dispatch(getLeagueTypeList(data));
    };

    const changeStatus = (item) => {
        confirmation({
            title: item.title,
            text: `Are you sure you want to ${
                item.is_active ? "DEACTIVATE" : "ACTIVE"
            }`,
            icon: "warning",
            confirmButtonText: "Confirm",
            action: () => {
                dispatch(updateLeagueTypeStatus(item.key_token, initialize));
            },
        });
    };

    useEffect(() => {
        dispatch(getLeagueTypeList(data));
    }, [data, dispatch]);

    return (
        <React.Fragment>
            <Title
                title={getPathTitle(Routes.HOT_MATCHES_SETTINGS_LEAGUE_TYPE)}
            />
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb
                        parent={{
                            isParent: true,
                            pageTitle: getPathTitle(
                                Routes.HOT_MATCHES_SETTINGS_LEAGUE_TYPE
                            ),
                            pageLink: getPath(
                                Routes.HOT_MATCHES_SETTINGS_LEAGUE_TYPE
                            ),
                        }}
                    />
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <Table
                                        search={search}
                                        handlerSearch={setSearch}
                                        sortBy={sortBy}
                                        handlerSortBy={setSortBy}
                                        thead={tableTheadData}
                                        handlerChangeData={setData}
                                        data={data}
                                        collection={leagueTypeList}
                                        isLoading={isLoading}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default LeagueTypeList;
