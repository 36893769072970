import { format } from "date-fns";
import moment from "moment";

export const userGenderOptions = [
    {
        label: "Select",
        value: "",
    },
    {
        label: "Male",
        value: "male",
    },
    {
        label: "Female",
        value: "female",
    },
];

export const userTypeOptions = [
    {
        label: "Operator",
        value: "operator",
        filterValue: "operator",
        role_id: 2,
        level: 4,
    },
    {
        label: "Master Agent",
        value: "master-agent",
        filterValue: "master_agent",
        role_id: 3,
        level: 3,
    },
    {
        label: "Agent",
        value: "agent",
        filterValue: "agent",
        role_id: 4,
        level: 2,
    },
    {
        label: "Player",
        value: "player",
        filterValue: "player",
        role_id: 5,
        level: 1,
    },
];

export const reportUserTypeOptions = [
    {
        label: "Operator",
        value: "operator",
        role_id: 2,
        level: 4,
    },
    {
        label: "Master Agent",
        value: "master-agent",
        role_id: 3,
        level: 3,
    },
    {
        label: "Agent",
        value: "agent",
        role_id: 4,
        level: 2,
    },
    {
        label: "Player",
        value: "player",
        role_id: 5,
        level: 1,
    },
];

export const transactionTypeOptions = [
    {
        label: "Deposit",
        value: "deposit",
        id: 2,
    },
    {
        label: "Withdrawal",
        value: "withdrawal",
        id: 3,
    },
    {
        label: "Transfer Balance",
        value: "transafer_balance",
        id: 4,
    },
];

export const userStatus = [
    {
        value: "active",
        color: "success",
        label: "Active",
    },
    {
        value: "banned",
        color: "danger",
        label: "Banned",
    },
    {
        value: "deactivated",
        color: "danger",
        label: "Deactivated",
    },
    {
        value: "inactive",
        color: "danger",
        label: "Inactive",
    },
];

export const userVerificationStatus = [
    {
        value: "unverified",
        color: "warning",
        label: "Unverified",
    },
    {
        value: "verified",
        color: "success",
        label: "Semi Verified",
    },
    {
        value: "fully_verified",
        color: "success",
        label: "Fully Verified",
    },
    {
        value: "for_approval",
        color: "warning",
        label: "For Semi Verification",
    },
    {
        value: "for_fully_approval",
        color: "success",
        label: "For Fully Verification",
    },
    {
        value: "rejected_approval",
        color: "danger",
        label: "Semi Verified Rejected",
    },
    {
        value: "rejected_fully_approval",
        color: "success",
        label: "Fully Verified Rejected",
    },
];

export const promotionTransactionStatus = [
    {
        value: "joined",
        color: "success",
        label: "Joined",
    },
    {
        value: "ongoing",
        color: "info",
        label: "Ongoing",
    },
    {
        value: "failed",
        color: "danger",
        label: "Failed",
    },
    {
        value: "expired",
        color: "danger",
        label: "Expired",
    },
    {
        value: "completed",
        color: "success",
        label: "Completed",
    },
];

export const requestStatus = [
    {
        value: "Completed",
        color: "success",
        label: "Completed",
        id: 2,
    },
    {
        value: "Cancelled",
        color: "danger",
        label: "Cancelled",
        id: 3,
    },
    {
        value: "For Release",
        color: "primary",
        label: "For Release",
        id: 4,
    },
    {
        value: "For Payout",
        color: "secondary",
        label: "For Payout",
        id: 5,
    },
    {
        value: "Pending",
        color: "warning",
        label: "Pending",
        id: 1,
    },
];

export const commissionStatus = [
    {
        value: "transferred",
        color: "success",
        label: "Transferred",
    },
    {
        value: "cancelled",
        color: "danger",
        label: "Cancelled",
    },
    {
        value: "pending",
        color: "warning",
        label: "Pending",
    },
    {
        value: "for_transfer",
        color: "warning",
        label: "For Transfer",
    },
];

export const boolStatus = [
    {
        value: "yes",
        color: "success",
        label: "Yes",
    },
    {
        value: "no",
        color: "primary",
        label: "No",
    },
];

export const denomination = [100, 250, 500, 1000, 5000];
// 10, 30, 40, 100, "Custom"
export const bcfPercentage = [
    {
        label: "10%",
        value: 10,
    },
    {
        label: "30%",
        value: 30,
    },
    {
        label: "40%",
        value: 40,
    },
    {
        label: "100%",
        value: 100,
    },
    {
        label: "Custom",
        value: null,
    },
];

export const playerVerificationStatus = {
    for_verification: 1,
    verification_step_1: 2,
    verification_step_2: 3,
    verified: 4,
    rejected: 5,
};

export const permissionActionsList = [
    {
        permission: "having_view",
        keyName: "view",
        label: "View",
        name: "checkBoxView",
        id: "checkbox-view",
    },
    {
        permission: "having_create",
        keyName: "create",
        label: "Create",
        name: "checkBoxCreate",
        id: "checkbox-create",
    },
    {
        permission: "having_update",
        keyName: "update",
        label: "Update",
        name: "checkBoxUpdate",
        id: "checkbox-update",
    },
    {
        permission: "having_update_status",
        keyName: "update_status",
        label: "Update Status",
        name: "checkBoxUpdateStatus",
        id: "checkbox-update-status",
    },
    {
        permission: "having_delete",
        keyName: "delete",
        label: "Delete",
        name: "checkBoxDelete",
        id: "checkbox-delete",
    },
];

export const userCounter = (data, userType, isDashboard = false) => {
    let userLabel = "";

    if (userType === "agent") {
        userLabel = "Agent";
    } else if (userType === "master_agent") {
        userLabel = "Master Agent";
    } else userLabel = "Player";

    let counters = [
        {
            label: `${isDashboard ? userLabel : ""} Overall Total`,
            total: [
                {
                    id: 1,
                    counter: data[userType]?.total_overall,
                    separator: ",",
                    suffix: "",
                },
            ],
        },
        {
            label: `${isDashboard ? userLabel : ""} Total Active`,
            total: [
                {
                    id: 2,
                    counter: data[userType]?.total_active,
                    separator: ",",
                    suffix: "",
                },
            ],
        },
        {
            label: `${isDashboard ? userLabel : ""} Total Deactivated`,
            total: [
                {
                    id: 3,
                    counter: data[userType]?.total_deactivated,
                    separator: ",",
                    suffix: "",
                },
            ],
        },
        {
            label: `${isDashboard ? userLabel : ""} Total Banned`,
            total: [
                {
                    id: 3,
                    counter: data[userType]?.total_banned,
                    separator: ",",
                    suffix: "",
                },
            ],
        },
    ];

    if (userType === "player") {
        counters.push(
            {
                label: `${isDashboard ? "Player" : ""} Total Verified`,
                total: [
                    {
                        id: 2,
                        counter: data[userType]?.total_verified,
                        separator: ",",
                        suffix: "",
                    },
                ],
            },
            {
                label: `${isDashboard ? "Player" : ""} Total Unverified`,
                total: [
                    {
                        id: 2,
                        counter: data[userType]?.total_unverified,
                        separator: ",",
                        suffix: "",
                    },
                ],
            },
            {
                label: `${isDashboard ? "Player" : ""} Balance (${
                    process.env.REACT_APP_BCF_LABEL
                })`,
                icon: <i className="ri-wallet-line"></i>,
                total: [
                    {
                        id: 2,
                        counter: data[userType]?.total_bcf,
                        separator: ",",
                        suffix: "",
                        decimals: 2,
                    },
                ],
            },
            {
                label: `${isDashboard ? "Player" : ""} Balance (PHP)`,
                icon: <i className="ri-wallet-line"></i>,
                total: [
                    {
                        id: 2,
                        counter: data[userType]?.total_merchant,
                        separator: ",",
                        suffix: "",
                        decimals: 2,
                    },
                ],
            },
            {
                label: `${isDashboard ? "Player" : ""} Total Balance`,
                icon: <i className="ri-wallet-line"></i>,
                total: [
                    {
                        id: 2,
                        counter: data[userType]?.total_chips,
                        separator: ",",
                        suffix: "",
                        decimals: 2,
                    },
                ],
            }
        );

        if (Object.hasOwn(data[userType] ?? {}, "total_organic_users")) {
            counters.push({
                label: `Total Organic Players`,
                total: [
                    {
                        id: 2,
                        counter: data[userType]?.total_organic_users,
                        separator: ",",
                        suffix: "",
                        decimals: 2,
                    },
                ],
            });
        }
    }

    return counters;
};

export const bettingCreditFundCounter = (data) => {
    return [
        {
            label: "Total Amount",
            total: [
                {
                    id: 1,
                    counter: data?.total_amount,
                    separator: ",",
                    suffix: "",
                    decimals: 2,
                },
            ],
        },
        {
            label: "Total Credit",
            total: [
                {
                    id: 2,
                    counter: data?.total_credit,
                    separator: ",",
                    suffix: "",
                    decimals: 2,
                },
            ],
        },
    ];
};

export const walletTransactionCounter = (data) => {
    return [
        {
            label: "Total Amount",
            total: [
                {
                    id: 1,
                    counter: data?.total_amount,
                    separator: ",",
                    suffix: "",
                    decimals: 2,
                },
            ],
        },
    ];
};

export const totalCounter = (data) => {
    return [
        {
            label: "Total Bet",
            total: [
                {
                    id: 1,
                    counter: data?.total_bet,
                    separator: ",",
                    suffix: "",
                    decimals: 2,
                },
            ],
        },
        {
            label: "Total Win",
            total: [
                {
                    id: 2,
                    counter: data?.total_win,
                    separator: ",",
                    suffix: "",
                    decimals: 2,
                },
            ],
        },
        {
            label: "Jackpot Win",
            total: [
                {
                    id: 2,
                    counter: data?.jackpot_win,
                    separator: ",",
                    suffix: "",
                    decimals: 2,
                },
            ],
        },
        {
            label: "Total Netwin",
            total: [
                {
                    id: 2,
                    counter: data?.total_netwin,
                    separator: ",",
                    suffix: "",
                    decimals: 2,
                },
            ],
        },
        {
            label: "Total GGR",
            total: [
                {
                    id: 3,
                    counter: data?.total_ggr,
                    separator: ",",
                    suffix: "",
                    decimals: 2,
                },
            ],
        },
    ];
};

export const dateFilterOptions = [
    {
        label: "Today",
        value: [
            format(Date.parse(moment(moment.now())), "yyyy-MM-dd"),
            format(Date.parse(moment(moment.now())), "yyyy-MM-dd"),
        ],
    },
    {
        label: "Yesterday",
        value: [
            format(
                Date.parse(moment(moment.now()).subtract(1, "day")),
                "yyyy-MM-dd"
            ),
            format(
                Date.parse(moment(moment.now()).subtract(1, "day")),
                "yyyy-MM-dd"
            ),
        ],
    },
    {
        label: "This Week",
        value: [
            format(
                Date.parse(moment(moment.now()).startOf("isoWeek")),
                "yyyy-MM-dd"
            ),
            format(
                Date.parse(moment(moment.now()).endOf("isoWeek")),
                "yyyy-MM-dd"
            ),
        ],
    },
    {
        label: "Last Week",
        value: [
            format(
                Date.parse(
                    moment(moment(moment.now()).subtract(1, "week")).startOf(
                        "isoWeek"
                    )
                ),
                "yyyy-MM-dd"
            ),
            format(
                Date.parse(
                    moment(moment(moment.now()).subtract(1, "week")).endOf(
                        "isoWeek"
                    )
                ),
                "yyyy-MM-dd"
            ),
        ],
    },
    {
        label: "This Month",
        value: [
            format(
                Date.parse(moment(moment.now()).startOf("month")),
                "yyyy-MM-dd"
            ),
            format(
                Date.parse(moment(moment.now()).endOf("month")),
                "yyyy-MM-dd"
            ),
        ],
    },
    {
        label: "Last Month",
        value: [
            format(
                Date.parse(
                    moment(moment(moment.now()).subtract(1, "month")).startOf(
                        "month"
                    )
                ),
                "yyyy-MM-dd"
            ),
            format(
                Date.parse(
                    moment(moment(moment.now()).subtract(1, "month")).endOf(
                        "month"
                    )
                ),
                "yyyy-MM-dd"
            ),
        ],
    },
    { label: "Custom", value: null },
];

export const betStatus = [
    {
        label: "Win",
        value: "win",
    },
    {
        label: "Loss",
        value: "loss",
    },
    {
        label: "Cancelled",
        value: "cancelled",
    },
    {
        label: "Unsettled",
        value: "unsettled",
    },
    {
        label: "Loss with 0 win amount",
        value: "loss_zero_amount",
    },
    {
        label: "Loss with win amount",
        value: "loss_with_amount",
    },
];

export const balanceCategoryOptions = (type = "gl") => {
    let balanceCategories = [
        {
            label: "All",
            value: "",
        },
        {
            label: `${process.env.REACT_APP_BCF_LABEL} (Bcf)`,
            value: "bcf",
        },
        {
            label: "Php (Merchant)",
            value: "merchant",
        },
        ...(process.env.REACT_APP_LOTTO_ENABLED === "true"
            ? [
                  {
                      label: `${process.env.REACT_APP_BCF_LABEL} (Bcf Lotto)`,
                      value: "bcf_lotto",
                  },
                  {
                      label: `${process.env.REACT_APP_DEFAULT_CURRENCY} (Merchant Lotto)`,
                      value: "merchant_lotto",
                  },
              ]
            : []),
    ];

    if (type === "all") {
        balanceCategories = [
            ...balanceCategories,
            {
                label: `Commission ${process.env.REACT_APP_BCF_LABEL} (Bcf)`,
                value: "commission_bcf",
            },
            {
                label: "Commission Php (Merchant)",
                value: "commission_merchant",
            },
            {
                label: `Commission ${process.env.REACT_APP_BCF_LABEL} (Sports/E-Sports/Live Casino Bcf)`,
                value: "commission_bcf_sports",
            },
            {
                label: "Commission Php (Sports/E-Sports/Live Casino Merchant)",
                value: "commission_merchant_sports",
            },
            ...(process.env.REACT_APP_LOTTO_ENABLED === "true"
                ? [
                      {
                          label: `Commission ${process.env.REACT_APP_BCF_LABEL} (BCF Lotto)`,
                          value: "commission_bcf_lotto",
                      },
                      {
                          label: `Commission ${process.env.REACT_APP_DEFAULT_CURRENCY} (Merchant Lotto)`,
                          value: "commission_merchant_lotto",
                      },
                  ]
                : []),
        ];
    }

    return balanceCategories;
};

export const commissionCategoryOptions = [
    {
        label: "All",
        value: "",
    },
    {
        label: `${process.env.REACT_APP_BCF_LABEL} (Bcf)`,
        value: "bcf",
    },
    {
        label: "Php (merchant)",
        value: "merchant",
    },
    {
        label: `${process.env.REACT_APP_BCF_LABEL} (Sports/E-Sports/Live Casino Bcf)`,
        value: "bcf_sports",
    },
    {
        label: "Php (Sports/E-Sports/Live Casino Merchant)",
        value: "merchant_sports",
    },
    ...(process.env.REACT_APP_LOTTO_ENABLED === "true"
        ? [
              {
                  label: `${process.env.REACT_APP_BCF_LABEL} (Bcf Lotto)`,
                  value: "bcf_lotto",
              },
              {
                  label: `${process.env.REACT_APP_DEFAULT_CURRENCY} (Merchant Lotto)`,
                  value: "merchant_lotto",
              },
          ]
        : []),
];

export const batchPayoutJobsCounter = (data) => {
    return [
        {
            label: "Total",
            icon: <i className="mdi mdi-briefcase"></i>,
            total: [
                {
                    id: 1,
                    counter: data?.total_jobs,
                    separator: ",",
                    suffix: "",
                },
            ],
        },
        {
            label: "Pending",
            icon: <i className="mdi mdi-briefcase-clock" />,
            total: [
                {
                    id: 2,
                    counter: data?.pending_jobs,
                    separator: ",",
                    suffix: "",
                },
            ],
        },
        {
            label: "Failed",
            icon: <i className="mdi mdi-briefcase-remove" />,
            total: [
                {
                    id: 3,
                    counter: data?.failed_jobs,
                    separator: ",",
                    suffix: "",
                },
            ],
        },
        {
            label: "Processed",
            icon: <i className="mdi mdi-briefcase-check" />,
            total: [
                {
                    id: 3,
                    counter: data?.processed_jobs,
                    separator: ",",
                    suffix: "",
                },
            ],
        },
    ];
};

export const balanceCategoryLabel = (type) => {
    const balances = {
        bcf: process.env.REACT_APP_BCF_LABEL,
        merchant: "PHP",
        pending_commission_bcf: `Pending Commission ${process.env.REACT_APP_BCF_LABEL}`,
        pending_commission_merchant: "Pending Commission PHP",
        commission_bcf: `Commission ${process.env.REACT_APP_BCF_LABEL}`,
        commission_merchant: "Commission PHP",
        commission_bcf_sports: `Commission (Sports/E-Sports/Live Casino) ${process.env.REACT_APP_BCF_LABEL}`,
        commission_merchant_sports:
            "Commission (Sports/E-Sports/Live Casino) PHP",
    };

    return balances[type] ?? null;
};

export const responseStatus = [
    {
        value: "success",
        color: "success",
        label: "Success",
    },
    {
        value: "failed",
        color: "danger",
        label: "Failed",
    },
];

export const affiliateRequestStatus = [
    {
        value: "approved",
        color: "success",
        label: "Approved",
    },
    {
        value: "pending",
        color: "warning",
        label: "Pending",
    },
    {
        value: "rejected",
        color: "danger",
        label: "Rejected",
    },
];

export const commonStatus = [
    {
        value: "active",
        color: "success",
        label: "Active",
    },
    {
        value: "inactive",
        color: "primary",
        label: "Inactive",
    },
];

export const roleTag = {
    DEFAULT: "",
    AFFILIATE: "affiliate",
    LICENSEE_OPERATOR: "licensee-operator",
    COMMUNITY_MANAGER: "community-manager",
};

export const agentRoleTagOptions = [
    {
        label: "Default",
        value: roleTag.DEFAULT,
    },
    {
        label: "Affiliate",
        value: roleTag.AFFILIATE,
    },
];

export const operatorRoleTagOptions = [
    {
        label: "Default",
        value: roleTag.DEFAULT,
    },
    {
        label: "Community Manager",
        value: roleTag.COMMUNITY_MANAGER,
    },
];

export const commissionRatePerGameOffering = [
    {
        label: `Commission Rate ${process.env.REACT_APP_BCF_LABEL} (E-Games)`,
        value: "commission_rate_bcf_egames",
        balanceCategory: "bcf",
    },
    {
        label: "Commission Rate Php (E-Games)",
        value: "commission_rate_merchant_egames",
        balanceCategory: "merchant",
    },
    {
        label: `Commission Rate ${process.env.REACT_APP_BCF_LABEL} (Sports Betting)`,
        value: "commission_rate_bcf_sports_betting",
        balanceCategory: "bcf",
    },
    {
        label: "Commission Rate Php (Sports Betting)",
        value: "commission_rate_merchant_sports_betting",
        balanceCategory: "merchant",
    },
    {
        label: `Commission Rate ${process.env.REACT_APP_BCF_LABEL} (E-Sports)`,
        value: "commission_rate_bcf_esports_betting",
        balanceCategory: "bcf",
    },
    {
        label: "Commission Rate Php (E-Sports)",
        value: "commission_rate_merchant_esports_betting",
        balanceCategory: "merchant",
    },
    {
        label: `Commission Rate ${process.env.REACT_APP_BCF_LABEL} (Live Casino)`,
        value: "commission_rate_bcf_live_casino",
        balanceCategory: "bcf",
    },
    {
        label: "Commission Rate Php (Live Casino)",
        value: "commission_rate_merchant_live_casino",
        balanceCategory: "merchant",
    },
    ...(process.env.REACT_APP_LOTTO_ENABLED === "true"
        ? [
              {
                  label: `Commission Rate ${process.env.REACT_APP_BCF_LABEL} (Lotto)`,
                  value: "commission_rate_bcf_lotto",
                  balanceCategory: "bcf",
              },
              {
                  label: `Commission Rate ${process.env.REACT_APP_DEFAULT_CURRENCY} (Lotto)`,
                  value: "commission_rate_merchant_lotto",
                  balanceCategory: "merchant",
              },
          ]
        : []),
    {
        label: `Commission Rate ${process.env.REACT_APP_BCF_LABEL} Sports Betting (VTO)`,
        value: "commission_rate_bcf_sports_betting_vto",
        balanceCategory: "bcf",
    },
    {
        label: "Commission Rate Php Sports Betting (VTO)",
        value: "commission_rate_merchant_sports_betting_vto",
        balanceCategory: "merchant",
    },
    {
        label: `Commission Rate ${process.env.REACT_APP_BCF_LABEL} E-Sports (VTO)`,
        value: "commission_rate_bcf_esports_betting_vto",
        balanceCategory: "bcf",
    },
    {
        label: "Commission Rate Php E-Sports (VTO)",
        value: "commission_rate_merchant_esports_betting_vto",
        balanceCategory: "merchant",
    },
];

export const commissionTransferSchedules = [
    {
        label: "15/30",
        value: "15/30",
    },
    {
        label: "Monthly",
        value: "monthly",
    },
    {
        label: "Weekly",
        value: "weekly",
    },
];
