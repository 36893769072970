import {
  TextLink,
  Table,
  Title,
  JsonPretty,
  DataCollapse,
  StatusBadge,
} from "@/components/atoms";
import BreadCrumb from "@/components/common/BreadCrumb";
import { getUserProfileLink, filterDateFormat, arrayToString } from "@/utils";
import { Routes, getPath, getPathTitle } from "@/constants";
import { getUserNotificationList } from "@/store/actions";
import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardHeader,
  Button
} from "reactstrap";
import { ViewNotificationModal } from "@/components/molecules";
import { useParams } from "react-router-dom";

const List = () => {
  const [viewNotification, setViewNotification] =
    useState(false);
  const [SelectedItem, setSelectedItem] = useState({});
  const [collapse, setCollapse] = useState([]);
  const { keyToken } = useParams();

  const dispatch = useDispatch();
  const { userNotificationList, isLoading } = useSelector(
    (state) => state.announcement
  );

  const [search, setSearch] = useState("");

  const [sortBy, setSortBy] = useState({
    field: "",
    type: "asc",
  });

  const [data, setData] = useState({
    per_page: 10,
    page: 1,
    search: "",
  });

  const tableTheadData = useMemo(() => {
    const handleCollapse = (id) => {
      if (collapse.includes(id)) {
        const selected = collapse.filter((value) => value !== id);
        setCollapse([...selected]);
      } else {
        setCollapse((collapse) => [...collapse, id]);
      }
    };
    return [
      {
        label: "Title",
        field: "title",
        sortable: true,
      },
      {
        label: "Account",
        field: "user",
        render: (item) => (
          <TextLink
            text={`${item.account_no} | ${item.username}`}
            path={getUserProfileLink(item.account_no, "player")}
          />
        ),
      },
      {
        label: "Is Opened",
        field: "is_read",
        render: (item) => <StatusBadge status={item.is_read ? "yes" : "no"} type="bool" />,
      },
      {
        label: "Other Details",
        field: "action",
        sortable: false,
        render: (item) => (
          <>
            {item?.other_details ? (
              <DataCollapse
                label={collapse.includes(item.key_token) ? "show" : "view"}
                collapseKey={item.key_token}
                data={[]}
                content={
                  <JsonPretty
                    id={item.key_token}
                    data={item?.other_details ?? []}
                  />
                }
                collapse={collapse}
                handleCollapse={handleCollapse}
              />
            ) : (
              "-"
            )}
          </>
        ),
      },
      {
        label: "View",
        field: "view",
        sortable: false,
        render: (item) => (
          <Button
            onClick={() => {
              setViewNotification(true);
              setSelectedItem(item);
            }}
            color="primary"
            size="sm"
          >
            <i className="ri-play-list-add-line"></i>
          </Button>
        ),
      },
    ];
  }, [collapse]);

  useEffect(() => {
    dispatch(getUserNotificationList(data, keyToken));
  }, [data, dispatch, keyToken]);

  return (
    <React.Fragment>
      <Title title={getPathTitle(Routes.NOTIFICATION_LIST)} />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            parent={{
              isParent: true,
              pageTitle: getPathTitle(Routes.NOTIFICATION_TYPE_LIST),
              pageLink: getPath(Routes.NOTIFICATION_TYPE_LIST),
            }}
            childs={[
              {
                pageTitle: getPathTitle(Routes.NOTIFICATION_LIST),
                pageLink: getPath(Routes.NOTIFICATION_LIST),
                isActive: true,
              },
            ]}
          />
          <Row>
            <Col>
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    {getPathTitle(Routes.NOTIFICATION_LIST)}
                  </h4>
                </CardHeader>
                <CardBody>
                  <Row className="mb-3">
                    <Col md={12}>
                      <Table
                        search={search}
                        handlerSearch={setSearch}
                        sortBy={sortBy}
                        handlerSortBy={setSortBy}
                        thead={tableTheadData}
                        handlerChangeData={setData}
                        data={data}
                        collection={userNotificationList}
                        isLoading={isLoading}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {viewNotification && (
        <ViewNotificationModal
          isOpen={viewNotification}
          setIsOpen={setViewNotification}
          data={SelectedItem}
        />
      )}
    </React.Fragment>
  );
};

export default List;
