import {
    Button,
    ErrorAlert,
    InputSelect,
    InputSwitch,
} from "@/components/atoms";
import { resetErrors } from "@/store/actions";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button as ButtonComponent,
    Col,
    Form,
    FormGroup,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
} from "reactstrap";

const UpdatePaymentProviderModal = ({
    isOpen,
    setIsOpen,
    data,
    handleUpdate,
}) => {
    const dispatch = useDispatch();
    const { isSubmit, errors } = useSelector((state) => state.paymentSetting);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            is_active: data?.is_active === 1,
            allowed_in_deposit: data.allowed_in_deposit === 1,
            allowed_in_withdrawal: data.allowed_in_withdrawal === 1,
        },
        onSubmit: (values) => {
            handleUpdate(values, data.key_token);
        },
    });

    useEffect(() => {
        dispatch(resetErrors());
    }, [dispatch]);

    return (
        <Modal
            id="paymentFeeModal"
            isOpen={isOpen}
            toggle={() => setIsOpen(!isOpen)}
        >
            <ModalHeader>
                <div className="modal-title">Update Payment Provider</div>
            </ModalHeader>
            <ModalBody>
                <Form
                    className="needs-validation"
                    onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}
                >
                    <ErrorAlert errors={errors} validation={validation} />
                    <Row className="mb-3">
                        <Col md={12}>
                            <FormGroup className="mb-3">
                                <InputSwitch
                                    label={
                                        data.is_active ? "Active" : "Inactive"
                                    }
                                    id={"is-acive"}
                                    isChecked={validation?.values.is_active}
                                    color="success"
                                    onChange={() =>
                                        validation.setFieldValue(
                                            "is_active",
                                            !validation.values.is_active
                                        )
                                    }
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <InputSwitch
                                    label={"Allowed in Deposit"}
                                    id={"allowed-in-deposit"}
                                    isChecked={
                                        validation?.values.allowed_in_deposit
                                    }
                                    color="success"
                                    onChange={() =>
                                        validation.setFieldValue(
                                            "allowed_in_deposit",
                                            !validation.values
                                                .allowed_in_deposit
                                        )
                                    }
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <InputSwitch
                                    label={"Allowed in Withdrawal"}
                                    id={"allowed-in-withdrawal"}
                                    isChecked={
                                        validation?.values.allowed_in_withdrawal
                                    }
                                    color="success"
                                    onChange={() =>
                                        validation.setFieldValue(
                                            "allowed_in_withdrawal",
                                            !validation.values
                                                .allowed_in_withdrawal
                                        )
                                    }
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-end">
                            <ButtonComponent
                                color="light"
                                onClick={() => setIsOpen(!isOpen)}
                                className="me-2"
                            >
                                Close
                            </ButtonComponent>
                            <Button
                                color="success"
                                type="submit"
                                text="Submit"
                                isLoading={isSubmit}
                            />
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
        </Modal>
    );
};

export default UpdatePaymentProviderModal;
