import { InputSwitch, InputTextGroup, Title } from "@/components/atoms";
import BreadCrumb from "@/components/common/BreadCrumb";
import { Routes, getPath, getPathTitle } from "@/constants";
import { getSettingsData, updateSetting } from "@/store/actions";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import Swal from "sweetalert2";

const Settings = () => {
    const dispatch = useDispatch();
    const { list } = useSelector((state) => state.setting);

    const inputs = {
        number: (item) => (
            <InputTextGroup
                disabled
                size="sm"
                label={item.name}
                name={item.key}
                id={item.key_token}
                buttonIcon="ri-pencil-line"
                buttonAction={() => inputEditHandler(item)}
                value={item.value}
            />
        ),
        switch: (item) => (
            <InputSwitch
                label={item.name}
                isChecked={parseInt(item.value) ? true : false}
                onChange={(e) => {
                    updateSettingHandler(
                        e.target.checked ? 1 : 0,
                        item.key_token
                    );
                }}
            />
        ),
    };

    const inputEditHandler = (item) => {
        let attribute = {};
        if (item.value_format !== "string") {
            attribute = {
                inputAttributes: {
                    type: "number",
                    step: "any", // This allows decimal input
                },
            };
        }
        Swal.fire({
            input: item.value_format === "string" ? "text" : "number",
            inputValue: item.value,
            inputLabel: item.name,
            showCancelButton: true,
            confirmButtonText: "Save",
            reverseButtons: true,
            ...attribute,
            preConfirm: (value) => {
                if (!value) {
                    Swal.showValidationMessage(
                        `<i class="fa fa-info-circle"></i> ${item.name} is required`
                    );
                }
            },
        }).then((result) => {
            if (result.isConfirmed) {
                updateSettingHandler(result.value, item.key_token);
            }
        });
    };

    const updateSettingHandler = (data, keyToken) => {
        const values = { value: data };
        dispatch(updateSetting(values, keyToken));
    };

    useEffect(() => {
        dispatch(getSettingsData());
    }, [dispatch]);

    return (
        <React.Fragment>
            <Title title={getPathTitle(Routes.SETTINGS)} />
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb
                        parent={{
                            isParent: true,
                            pageTitle: getPathTitle(Routes.SETTINGS),
                            pageLink: getPath(Routes.SETTINGS),
                        }}
                    />
                    <Row>
                        {list.map((item, key) => (
                            <Col md={12} key={key}>
                                <Card>
                                    <CardHeader>
                                        <h6 className="card-title mb-0 text-sm">
                                            {item.name}
                                        </h6>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            {item.settings.map((item, key) => (
                                                <Col
                                                    md={4}
                                                    key={key}
                                                    className="mb-3"
                                                >
                                                    {item.value_format ===
                                                    "boolean"
                                                        ? inputs.switch(item)
                                                        : inputs.number(item)}
                                                </Col>
                                            ))}
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Settings;
