import { axiosInstance } from "./index";

export const playerList = async (data) => {
    return await axiosInstance.get(`/bo/user/get-paginated/${data.key_token}`, {
        params: data,
    });
};

export const playerProfile = async (accountNo) => {
    return await axiosInstance.get(`/bo/user/get-profile/${accountNo}`);
};

export const updatePlayer = async (data, keyToken) => {
    return await axiosInstance.put(`/bo/user/update-profile/${keyToken}`, data);
};

export const updateIdVerificationStatus = async (data, status, keyToken) => {
    return await axiosInstance.put(
        `/bo/user-profile-document/update-for-verification/${status}/${keyToken}`,
        data
    );
};

export const createPlayer = async (data) => {
    return await axiosInstance.post(`/bo/user/create`, data, {
        "content-type": "multipart/form-data",
    });
};

export const getVerificationId = async (keyToken) => {
    return await axiosInstance.get(
        `/bo/user-profile-document/get-document-for-verification/${keyToken}`
    );
};

export const getUserProfileDocuments = async (keyToken) => {
    return await axiosInstance.get(
        `/bo/user-profile-document/get-by-user-profile-key/${keyToken}`
    );
}
