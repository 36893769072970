import {
    Button,
    Checkbox,
    ErrorAlert,
    InputText,
    Title,
} from "@/components/atoms";
import BreadCrumb from "@/components/common/BreadCrumb";
import {
    getPath,
    getPathTitle,
    permissionActionsList,
    roleValidation,
    Routes,
} from "@/constants";
import {
    getRoleInfo,
    updateRoleName,
    updateRolePermission,
} from "@/store/actions";
import classnames from "classnames";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
    Accordion,
    AccordionItem,
    Card,
    CardBody,
    CardHeader,
    Col,
    Collapse,
    Container,
    Form,
    FormGroup,
    Label,
    Row,
} from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
const Update = () => {
    const { keyToken } = useParams();
    const dispatch = useDispatch();
    const { isLoading, errors, roleSelected } = useSelector(
        (state) => state.admin
    );

    const [permissions, setPermissions] = useState([]);

    const changePermissionHandler = async (key, action, value) => {
        setPermissions({
            ...permissions,
            [key]: {
                ...permissions[key],
                permissions: {
                    ...permissions[key].permissions,
                    [action]: value,
                },
            },
        });
        if (!value) {
            document.getElementById(`checkbox-all-${key}`).checked = false;
        }
    };

    const checkAllRow = (key, isChecked) => {
        let selectedData = roleSelected?.data[key];
        setPermissions({
            ...permissions,
            [key]: {
                ...permissions[key],
                permissions: {
                    ...permissions[key].permissions,
                    view: selectedData.having_view ? isChecked : false,
                    create: selectedData.having_create ? isChecked : false,
                    update: selectedData.having_update ? isChecked : false,
                    update_status: selectedData.having_update_status
                        ? isChecked
                        : false,
                    delete: selectedData.having_delete ? isChecked : false,
                },
            },
        });
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: roleSelected?.info?.name,
        },
        validationSchema: roleValidation,
        onSubmit: (values) => {
            dispatch(updateRoleName(values, keyToken));
        },
    });

    const onSave = (key) => {
        let selectedData = permissions[key];
        let data = {
            admin_role_key_token: keyToken,
            module_key_token: selectedData.key_token,
            permissions: selectedData.permissions,
        };
        dispatch(updateRolePermission(data));
    };

    useEffect(() => {
        dispatch(getRoleInfo(keyToken, setPermissions));
    }, [dispatch, setPermissions, keyToken]);

    return (
        <React.Fragment>
            <Title title={getPathTitle(Routes.MANAGE_ROLE_UPDATE)} />
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb
                        parent={{
                            isParent: false,
                            pageTitle: getPathTitle(Routes.MANAGE_ROLE),
                            pageLink: getPath(Routes.MANAGE_ROLE),
                        }}
                        childs={[
                            {
                                pageTitle: getPathTitle(
                                    Routes.MANAGE_ROLE_UPDATE
                                ),
                                pageLink: getPath(Routes.MANAGE_ROLE_UPDATE),
                                isActive: true,
                            },
                        ]}
                    />
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardHeader className="align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">
                                        {getPathTitle(
                                            Routes.MANAGE_ROLE_UPDATE
                                        )}
                                    </h4>
                                </CardHeader>
                                <CardBody>
                                    <Form
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            validation.handleSubmit();
                                            if (validation?.errors.name) {
                                                document.documentElement.scrollTop = 0;
                                            }
                                            return false;
                                        }}
                                        className="needs-validation"
                                        action="#"
                                    >
                                        <ErrorAlert
                                            errors={errors}
                                            validation={validation}
                                        />
                                        <Row className="mb-3">
                                            <Col md="4">
                                                <FormGroup>
                                                    <InputText
                                                        label="Role Name"
                                                        name="name"
                                                        placeholder="Ex. Staff, Accounting, etc..."
                                                        validation={validation}
                                                    />
                                                </FormGroup>
                                                <Button
                                                    color="success"
                                                    type="submit"
                                                    text="Save"
                                                />
                                            </Col>
                                        </Row>
                                    </Form>
                                    <Row className="mb-2 ">
                                        <Col>
                                            <FormGroup>
                                                <Label htmlFor="permissions">
                                                    Permissions
                                                </Label>
                                                <Accordion
                                                    className="accordion-icon-none"
                                                    id="default-accordion-example"
                                                    open="false"
                                                    toggle={() => {}}
                                                >
                                                    {roleSelected?.data?.map(
                                                        (item, key) => (
                                                            <AccordionItem
                                                                key={key}
                                                            >
                                                                <h2
                                                                    className="accordion-header"
                                                                    id={`heading-${key}`}
                                                                >
                                                                    <button
                                                                        className={classnames(
                                                                            "accordion-button",
                                                                            {
                                                                                collapsed: false,
                                                                            }
                                                                        )}
                                                                        type="button"
                                                                        style={{
                                                                            cursor: "pointer",
                                                                        }}
                                                                    >
                                                                        {item.name.toUpperCase()}
                                                                    </button>
                                                                </h2>
                                                                <Collapse
                                                                    isOpen={
                                                                        true
                                                                    }
                                                                    className="accordion-collapse"
                                                                    id={`collapse-${key}`}
                                                                >
                                                                    <div className="accordion-body">
                                                                        <Row>
                                                                            {/* <Col
                                                                                md={
                                                                                    2
                                                                                }
                                                                            >
                                                                                <Checkbox
                                                                                    name={`checkBoxAll${key}`}
                                                                                    id={`checkbox-all-${key}`}
                                                                                    label="All"
                                                                                    onChange={(
                                                                                        event
                                                                                    ) =>
                                                                                        checkAllRow(
                                                                                            key,
                                                                                            event
                                                                                                .target
                                                                                                .checked
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </Col> */}
                                                                            {permissionActionsList.map(
                                                                                (
                                                                                    row,
                                                                                    keyAction
                                                                                ) => {
                                                                                    return (
                                                                                        item[
                                                                                            row
                                                                                                .permission
                                                                                        ] ===
                                                                                            1 && (
                                                                                            <Col
                                                                                                md={
                                                                                                    2
                                                                                                }
                                                                                                key={
                                                                                                    keyAction
                                                                                                }
                                                                                            >
                                                                                                <Checkbox
                                                                                                    key={
                                                                                                        keyAction
                                                                                                    }
                                                                                                    name={`${row.name}${key}`}
                                                                                                    id={`${row.id}-${key}`}
                                                                                                    label={
                                                                                                        row.label
                                                                                                    }
                                                                                                    onChange={(
                                                                                                        event
                                                                                                    ) =>
                                                                                                        changePermissionHandler(
                                                                                                            key,
                                                                                                            row.keyName,
                                                                                                            event
                                                                                                                .target
                                                                                                                .checked
                                                                                                        )
                                                                                                    }
                                                                                                    checked={
                                                                                                        permissions[
                                                                                                            key
                                                                                                        ]
                                                                                                            ?.permissions[
                                                                                                            row
                                                                                                                .keyName
                                                                                                        ] ||
                                                                                                        ""
                                                                                                    }
                                                                                                />
                                                                                            </Col>
                                                                                        )
                                                                                    );
                                                                                }
                                                                            )}
                                                                        </Row>
                                                                        <Row className="mt-2 text-end">
                                                                            <Col>
                                                                                <Button
                                                                                    text="Save"
                                                                                    type="button"
                                                                                    size="sm"
                                                                                    color="success"
                                                                                    onClick={() =>
                                                                                        onSave(
                                                                                            key
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    Save
                                                                                </Button>
                                                                            </Col>
                                                                        </Row>
                                                                    </div>
                                                                </Collapse>
                                                            </AccordionItem>
                                                        )
                                                    )}
                                                </Accordion>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Update;
