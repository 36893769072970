import {
    errorHandler,
    requestHandler,
    successHandler,
} from "@/network/interceptors";
import store from "@/store";
import axios from "axios";
import base64 from "base-64";
import CryptoJS from "crypto-js";
import randomToken from "random-token";

//Add your BASE_URL to your .env
export const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
        "Content-Type": "application/json",
    },
});

// Handle response process
axiosInstance.interceptors.request.use(async function (config) {
    const accessToken = localStorage.getItem("accessToken");
    const { user } = store.getState();

    if (process.env.REACT_APP_ENV === "production") {
        const apiHostname = window.location.hostname.replace("bo-fe.", "");
        config.baseURL = `https://bo-be.${apiHostname}/api/v1`;
    }

    config.headers.Authorization = `Bearer ${accessToken}`;

    const secretKey = process.env.REACT_APP_API_SECRET_KEY;
    const xToken = randomToken(30).toUpperCase();
    const signature = CryptoJS.HmacSHA256(xToken, secretKey);
    const encryptedSignature = encodeURI(base64.encode(signature));

    config.headers["X-Token"] = xToken;
    config.headers["X-Signature"] = encryptedSignature;
    config.headers["X-UserAgentUUID"] = user?.deviceDetails?.visitorId ?? null;
    config.headers["X-IP"] = user?.deviceDetails?.userIP ?? null;

    return config;
});

// Handle request process
axiosInstance.interceptors.request.use((request) => requestHandler(request));

// Handle response process
axiosInstance.interceptors.response.use(
    (response) => successHandler(response),
    (error) => errorHandler(error)
);
