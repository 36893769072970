import {
  StatusBadge,
  JsonPretty,
  Title,
  AmountFormat,
  TextLink,
  DisplayText,
  Accordion,
} from "@/components/atoms";
import BreadCrumb from "@/components/common/BreadCrumb";
import { Routes, getPath, getPathTitle } from "@/constants";
import { getPromotionTransactionView } from "@/store/actions";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";

import { getUserProfileLink, defaultDateTimeFormat } from "@/utils";

import { useParams } from "react-router-dom";

const data = {};

const View = () => {
  const dispatch = useDispatch();
  const { promotionNo } = useParams();
  const { promotionTransactionViewData: data, isLoading } = useSelector(
    (state) => state.promotion
  );

  useEffect(() => {
    dispatch(getPromotionTransactionView(promotionNo));
  }, [dispatch, promotionNo]);

  return (
    <React.Fragment>
      <Title title={getPathTitle(Routes.PROMOTION_TRANSACTION_VIEW)} />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            parent={{
              isParent: false,
              pageTitle: getPathTitle(Routes.PROMOTION_TRANSACTION_VIEW),
              pageLink: getPath(Routes.PROMOTION_TRANSACTION_VIEW),
            }}
          />
          <br />
          <Row>
            <Col md={7}>
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    Transaction Details
                  </h4>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="4">
                      <DisplayText
                        label="Reference No."
                        item={data?.reference_no}
                        isLoading={isLoading}
                        col="8"
                      />
                    </Col>
                    <Col md="4">
                      <DisplayText
                        label="Amount"
                        item={<AmountFormat amount={data?.amount} />}
                        isLoading={isLoading}
                        col="8"
                      />
                    </Col>
                    <Col md="4">
                      <DisplayText
                        label="Turnover Multiplier"
                        item={
                          <AmountFormat
                            amount={data?.turnover_multiplier}
                            hasPrefix={false}
                          />
                        }
                        isLoading={isLoading}
                        col="8"
                      />
                    </Col>
                    <Col md="4">
                      <DisplayText
                        label="Status"
                        item={
                          <StatusBadge
                            status={data?.status}
                            type="promotionTransaction"
                          />
                        }
                        isLoading={isLoading}
                        col="8"
                      />
                    </Col>
                    <Col md="4">
                      <DisplayText
                        label="Remarks"
                        item={data?.remarks || "-"}
                        isLoading={isLoading}
                        col="8"
                      />
                    </Col>
                    <Col md="4">
                      <DisplayText
                        label="Expired At"
                        item={defaultDateTimeFormat(data?.expired_at)}
                        isLoading={isLoading}
                        col="8"
                      />
                    </Col>
                    <Col md="4">
                      <DisplayText
                        label="Failed At"
                        item={defaultDateTimeFormat(data?.failed_at)}
                        isLoading={isLoading}
                        col="8"
                      />
                    </Col>
                    <Col md="4">
                      <DisplayText
                        label="Completed At"
                        item={defaultDateTimeFormat(data?.completed_at)}
                        isLoading={isLoading}
                        col="8"
                      />
                    </Col>
                    <Col md="4">
                      <DisplayText
                        label="Created At"
                        item={defaultDateTimeFormat(data?.created_at)}
                        isLoading={isLoading}
                        col="8"
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md={5}>
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">User Details</h4>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="4">
                      <DisplayText
                        label="Account No."
                        item={
                          <TextLink
                            text={`${data?.user?.account_no}`}
                            path={getUserProfileLink(
                              data?.user?.account_no,
                              "player"
                            )}
                          />
                        }
                        isLoading={isLoading}
                        col="8"
                      />
                    </Col>
                    <Col md="4">
                      <DisplayText
                        label="Name"
                        item={`${data?.user?.user_profile?.first_name} ${data?.user?.user_profile?.last_name}`}
                        isLoading={isLoading}
                        col="8"
                      />
                    </Col>
                    <Col md="4">
                      <DisplayText
                        label="Mobile"
                        item={data?.user?.mobile ?? "-"}
                        isLoading={isLoading}
                        col="8"
                      />
                    </Col>
                    <Col md="6">
                      <DisplayText
                        label="Email"
                        item={data?.user?.email}
                        isLoading={isLoading}
                        col="8"
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={7}>
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    Promotion Details
                  </h4>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="4">
                      <DisplayText
                        label="Promotion Category Name"
                        item={data?.promotions?.promotion_category?.title}
                        isLoading={isLoading}
                        col="8"
                      />
                    </Col>
                    <Col md="4">
                      <DisplayText
                        label="Promotion No."
                        item={
                          <TextLink
                            text={data?.promotions?.promotion_no}
                            path={getPath(Routes.PROMOTION_UPDATE).replace(
                              ":promotionNo",
                              data?.promotions?.promotion_no
                            )}
                          />
                        }
                        isLoading={isLoading}
                        col="8"
                      />
                    </Col>
                    <Col md="4">
                      <DisplayText
                        label="Name"
                        item={data?.promotions?.name}
                        isLoading={isLoading}
                        col="8"
                      />
                    </Col>
                    <Col md="4">
                      <DisplayText
                        label="Start At"
                        item={defaultDateTimeFormat(
                          data?.promotions?.start_datetime
                        )}
                        isLoading={isLoading}
                        col="8"
                      />
                    </Col>
                    <Col md="4">
                      <DisplayText
                        label="End At"
                        item={defaultDateTimeFormat(
                          data?.promotions?.end_datetime
                        )}
                        isLoading={isLoading}
                        col="8"
                      />
                    </Col>
                    <Col md="4">
                      <DisplayText
                        label="Status"
                        item={
                          <>
                            <div>
                              Ongoing:{" "}
                              <StatusBadge
                                status={
                                  data?.promotions?.is_ongoing ? "yes" : "no"
                                }
                                type="bool"
                              />
                            </div>
                            <div>
                              Active:{" "}
                              <StatusBadge
                                status={
                                  data?.promotions?.is_active ? "yes" : "no"
                                }
                                type="bool"
                              />
                            </div>
                          </>
                        }
                        isLoading={isLoading}
                        col="8"
                      />
                    </Col>
                    <Col md="12">
                      <Accordion
                        title="Settings"
                        content={
                          <JsonPretty
                            id="settings"
                            data={data?.promotions?.settings ?? []}
                          />
                        }
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
           
            <Col md={5}>
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    Promotion Requirements
                  </h4>
                </CardHeader>
                <CardBody>
                  <Row className="mb-2">
                    <Col md="12">
                      <Accordion
                        title="Other Details"
                        content={
                          <JsonPretty
                            id="other-details"
                            data={data?.promotion_requirements ?? []}
                          />
                        }
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default View;
