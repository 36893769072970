import { FileInputPreview, Button } from "@/components/atoms";
import { fileInputFormValidation } from "@/constants";

import { useFormik } from "formik";

import React, { useEffect, useRef, useState } from "react";
import { FormGroup, Label, Form } from "reactstrap";
import { cleanFromFile } from "@/utils/common";

const FileInputForm = ({
  field,
  defaultImage = "",
  onSubmit,
  label,
  isCreate = false,
  isLoading = false,
  description = null,
}) => {
  const [isShowSubmit, setIsShowSubmit] = useState(false);
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      [field]: isShowSubmit ? "" : defaultImage ?? "",
    },
    validationSchema: fileInputFormValidation(field),
    onSubmit: (values) => {
      const payload = cleanFromFile(values, [field]);
      onSubmit(payload);
    },
  });
  return (
    <>
      <Form
        className="needs-validation"
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        <Label htmlFor={field}>{label}</Label>
        {description && <p className="text-muted">{description}</p>}
        <FormGroup className="mb-3">
          <FileInputPreview
            isUpdate={!isCreate}
            defaultImage={defaultImage}
            name={field}
            validation={validation}
            onupdatefiles={(file) =>
              validation.setFieldValue(field, file?.[0]?.file ?? null)
            }
            onEdit={(value) => setIsShowSubmit(value)}
          />
        </FormGroup>
        {(isShowSubmit || isCreate) && (
          <div className="border-top py-3 mt-3">
            <Button
              color="success"
              type="submit"
              className="float-end"
              text="Submit"
              isLoading={isLoading}
            />
          </div>
        )}
      </Form>
    </>
  );
};

export default FileInputForm;
