import { Button, ErrorAlert, InputPassword } from "@/components/atoms";
import { changePasswordValidation } from "@/constants";
import { resetUserErrors, userChangePassword } from "@/store/actions";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Col, Form, FormGroup, Row } from "reactstrap";
import ProfileLayout from "../Layout";

const ChangePassword = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState({
        old: false,
        new: false,
        confirm: false,
    });
    const { errors, isLoading } = useSelector((state) => state.user);

    const showPasswordHandler = (field) => {
        setShowPassword({
            ...showPassword,
            [field]: !showPassword[field],
        });
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            old_password: "",
            new_password: "",
            confirm_password: "",
        },
        validationSchema: changePasswordValidation,
        onSubmit: (values) => {
            dispatch(userChangePassword(values, navigate, validation));
        },
    });

    useEffect(() => {
        dispatch(resetUserErrors());
    }, [dispatch]);

    return (
        <ProfileLayout>
            <Row className="mb-3">
                <Col>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                        className="needs-validation"
                        action="#">
                        <ErrorAlert errors={errors} validation={validation} />
                        <Row>
                            <Col md="4">
                                <FormGroup className="mb-3">
                                    <InputPassword
                                        label="Old Password"
                                        name="old_password"
                                        placeholder="Enter Password"
                                        validation={validation}
                                        showPassword={showPassword.old}
                                        showPasswordHandler={() =>
                                            showPasswordHandler("old")
                                        }
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="4">
                                <FormGroup className="mb-3">
                                    <InputPassword
                                        label="New Password"
                                        name="new_password"
                                        placeholder="Enter Password"
                                        validation={validation}
                                        showPassword={showPassword.new}
                                        showPasswordHandler={() =>
                                            showPasswordHandler("new")
                                        }
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="4">
                                <FormGroup className="mb-3">
                                    <InputPassword
                                        label="Confirm Password"
                                        name="confirm_password"
                                        placeholder="Enter Password"
                                        validation={validation}
                                        showPassword={showPassword.confirm}
                                        showPasswordHandler={() =>
                                            showPasswordHandler("confirm")
                                        }
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                <Button
                                    isLoading={isLoading}
                                    color="success"
                                    className="btn btn-success float-end"
                                    type="submit"
                                    text="Change Password"
                                />
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </ProfileLayout>
    );
};

export default ChangePassword;
