import * as types from "./actionTypes";

export const getDrawScheduleList = (payload) => ({
    type: types.GET_DRAW_SCHEDULE_LIST,
    payload,
});

export const getDrawScheduleListSuccess = (payload) => ({
    type: types.GET_DRAW_SCHEDULE_LIST_SUCCESS,
    payload
});

export const getDrawScheduleListError = (payload) => ({
    type: types.GET_DRAW_SCHEDULE_LIST_ERROR,
    payload
});


export const updateDrawScheduleGame = (payload, keyToken, result) => ({
    type: types.UPDATE_DRAW_SCHEDULE_GAME,
    payload,
    keyToken,
    result
});

export const updateDrawScheduleGameSuccess = () => ({
    type: types.UPDATE_DRAW_SCHEDULE_GAME_SUCCESS,
});

export const updateDrawScheduleGameError = (payload) => ({
    type: types.UPDATE_DRAW_SCHEDULE_GAME_ERROR,
    payload
});