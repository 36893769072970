import {
    Accordion,
    AmountFormat,
    DisplayText,
    JsonPretty,
    TextLink,
    Title,
} from "@/components/atoms";
import BreadCrumb from "@/components/common/BreadCrumb";
import { Routes, getPath, getPathTitle } from "@/constants";
import { userRole } from "@/hooks";
import { getTransactionInfo } from "@/store/actions";
import {
    defaultDateTimeFormat,
    getUserFullname,
    getUserProfileLink,
} from "@/utils";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";

const TransactionView = () => {
    const dispatch = useDispatch();
    const { transactionNo } = useParams();
    const { selected: data, isLoading } = useSelector(
        (state) => state.transaction
    );

    const { isSuperAdmin } = userRole();

    useEffect(() => {
        dispatch(getTransactionInfo(transactionNo));
    }, [dispatch, transactionNo]);

    return (
        <React.Fragment>
            <Title title={getPathTitle(Routes.TIP_TRANSACTION)} />
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb
                        parent={{
                            isParent: true,
                            pageTitle: getPathTitle(Routes.TIP_TRANSACTION),
                            pageLink: getPath(Routes.TIP_TRANSACTION),
                        }}
                        childs={[
                            {
                                pageTitle: transactionNo,
                                pageLink: getPath(Routes.TIP_TRANSACTION_VIEW),
                                isActive: true,
                            },
                        ]}
                    />
                    <h4 className="card-title mb-0 flex-grow-1">
                        Transaction No: {transactionNo}
                    </h4>
                    <br />
                    <Row>
                        <Col md={7}>
                            <Card>
                                <CardHeader className="align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">
                                        Tip Details
                                    </h4>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col md="4">
                                            <DisplayText
                                                label="Account Name"
                                                item={
                                                    <TextLink
                                                        text={getUserFullname(
                                                            data?.user
                                                        )}
                                                        path={getUserProfileLink(
                                                            data?.user
                                                        )}
                                                    />
                                                }
                                                isLoading={isLoading}
                                                col="8"
                                            />
                                        </Col>
                                        <Col md="4">
                                            <DisplayText
                                                label="Tip Type"
                                                item={data?.type}
                                                isLoading={isLoading}
                                                col="8"
                                            />
                                        </Col>
                                        <Col md="4">
                                            <DisplayText
                                                label="Amount"
                                                item={
                                                    <AmountFormat
                                                        amount={
                                                            data?.amount
                                                        }
                                                    />
                                                }
                                                isLoading={isLoading}
                                                col="8"
                                            />
                                        </Col>
                                        <Col md="4">
                                            <DisplayText
                                                label="Balance Before"
                                                item={
                                                    <AmountFormat
                                                        amount={
                                                            data
                                                                ?.balance_before
                                                        }
                                                    />
                                                }
                                                isLoading={isLoading}
                                                col="8"
                                            />
                                        </Col>
                                        <Col md="4">
                                            <DisplayText
                                                label="Balance After"
                                                item={
                                                    <AmountFormat
                                                        amount={
                                                            data
                                                                ?.balance_after
                                                        }
                                                    />
                                                }
                                                isLoading={isLoading}
                                                col="8"
                                            />
                                        </Col>
                                        <Col md="4">
                                            <DisplayText
                                                label="Remarks"
                                                item={
                                                    data?.remarks || "-"
                                                }
                                                isLoading={isLoading}
                                                col="8"
                                            />
                                        </Col>
                                    </Row>
                                    {data?.other_details &&
                                        isSuperAdmin && (
                                            <Row className="mb-2">
                                                <Col md="12">
                                                    <Accordion
                                                        title="Other Details"
                                                        content={
                                                            <JsonPretty
                                                                id="debit-others-details"
                                                                data={
                                                                    data
                                                                        ?.other_details ||
                                                                    []
                                                                }
                                                            />
                                                        }
                                                    />
                                                </Col>
                                            </Row>
                                        )}
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={5}>
                            <Card>
                                <CardHeader className="align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">
                                        Debit Activity
                                    </h4>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col md="6">
                                            <DisplayText
                                                label="Transact By"
                                                item={
                                                    <TextLink
                                                        text={getUserFullname(
                                                            data
                                                                ?.transact_by
                                                        )}
                                                        path={getUserProfileLink(
                                                            data
                                                                ?.transact_by
                                                        )}
                                                    />
                                                }
                                                isLoading={isLoading}
                                                col="8"
                                            />
                                        </Col>
                                        <Col md="6">
                                            <DisplayText
                                                label="Date Created"
                                                item={defaultDateTimeFormat(
                                                    data?.created_at
                                                )}
                                                isLoading={isLoading}
                                                col="8"
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    
                </Container>
            </div>
        </React.Fragment>
    );
};

export default TransactionView;
