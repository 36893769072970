import {
    AmountFormat,
    CounterWidget,
    DeleteModal,
    Select,
    StatusBadge,
    Table,
    Title,
    FlatPickerDate,
} from "@/components/atoms";
import BreadCrumb from "@/components/common/BreadCrumb";
import {
    Routes,
    getPath,
    getPathTitle,
    userCounter,
    userStatus,
    userVerificationStatus,
} from "@/constants";
import { getPlayerList, getUserCountData } from "@/store/actions";
import { defaultDateFormat } from "@/utils";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { filterDateFormat } from "@/utils";
import { useFormik } from "formik";

import { dateFilterOptions } from "@/constants/users";

import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row,
    Input,
    Label,
} from "reactstrap";
import { userRole } from "@/hooks";

const PlayerList = () => {
    const dispatch = useDispatch();
    const dateFilterRef = useRef();
    const { user, userCount } = useSelector((state) => state.user);
    const { list, isLoading } = useSelector((state) => state.player);

    const [showModal, setShowModal] = useState(false);
    const [search, setSearch] = useState("");
    const [sortBy, setSortBy] = useState({
        field: "",
        type: "asc",
    });
    const [selectedDate, setSelectedDate] = useState("");

    const userType = "player";

    const initialValues = {
        key_token: user.keyToken,
        user_type: userType,
        per_page: 10,
        page: 1,
        search: "",
        status: "",
        verification_status: "",
        organic_players: 0,
        sort_by:
            sortBy.field && sortBy.type ? `${sortBy.field} ${sortBy.type}` : "",
        "date_range[wager_transactions.created_at][from]": null,
        "date_range[wager_transactions.created_at][to]": null,
    };

    const { isSuperAdmin, isAdmin } = userRole();

    const [data, setData] = useState(initialValues);

    // Form
    const form = useFormik({
        enableReinitialize: true,
        initialValues,
        onSubmit: (values) => {
            const { date_range, dateFilter, ...rest } = {
                ...data,
                status: values.status,
                verification_status: values.verification_status,
                "date_range[wager_transactions.created_at][from]":
                    values.dateFilter ? values.dateFilter[0] : null,
                "date_range[wager_transactions.created_at][to]":
                    values.dateFilter ? values.dateFilter[1] : null,
                organic_players: parseInt(values.organic_players),
                page: 1,
            };
            setData(rest);
        },
    });

    const resetForm = () => {
        form.resetForm();
        setSelectedDate("");
    };

    const tableTheadData = [
        {
            label: "Account No.",
            field: "account_no",
            sortable: true,
            render: (item) => item?.user_profile?.account_no,
        },
        {
            label: "Username",
            field: "name",
            sortable: true,
            render: (item) => item?.name,
        },
        {
            label: "Email",
            field: "email",
            sortable: true,
        },
        {
            label: "Contact No",
            field: "mobile_no",
            sortable: true,
            render: (item) => item?.mobile_no || "-",
        },
        {
            label: process.env.REACT_APP_DEFAULT_CURRENCY,
            field: "user_profile_balance_merchant.amount",
            sortable: true,
            render: (item) => (
                <AmountFormat
                    amount={item?.user_profile?.balance?.fiat?.php?.merchant}
                />
            ),
        },
        {
            label: process.env.REACT_APP_BCF_LABEL,
            field: "user_profile_balance_bcf.amount",
            sortable: true,
            render: (item) => (
                <AmountFormat
                    amount={item?.user_profile?.balance?.fiat?.php?.bcf}
                />
            ),
        },
        {
            label: `Commissions (${process.env.REACT_APP_DEFAULT_CURRENCY})`,
            field: "user_profile_balance_commission_merchant_player.amount",
            sortable: true,
            render: (item) => (
                <AmountFormat
                    amount={
                        item?.user_profile?.balance?.fiat?.php
                            ?.commission_merchant_player
                    }
                />
            ),
        },
        {
            label: "Winnings",
            field: "winnings",
            sortable: true,
            render: (item) => <AmountFormat amount={item?.winnings} />,
        },
        {
            label: "Turnover",
            field: "overall_turnover",
            sortable: true,
            render: (item) => <AmountFormat amount={item?.overall_turnover} />,
        },
        {
            label: "Status",
            field: "status",
            sortable: false,
            render: (item) => (
                <>
                    <StatusBadge status={item.status} />
                </>
            ),
        },
        {
            label: "Verification Status",
            field: "verification_status",
            sortable: false,
            render: (item) => (
                <StatusBadge
                    type="verification"
                    status={item.verification_status}
                />
            ),
        },
        {
            label: "Date Joined",
            field: "created_at",
            sortable: true,
            render: (item) => defaultDateFormat(item.created_at),
        },
        {
            label: "Action",
            field: "action",
            sortable: false,
            render: (item) => (
                <Link
                    to={getPath(Routes.PLAYER_PROFILE).replace(
                        ":accountNo",
                        item?.user_profile?.account_no
                    )}
                    className="link-primary"
                >
                    <Button color="success" size="sm">
                        <i className="ri-eye-line"></i>
                    </Button>
                </Link>
            ),
        },
    ];

    const userCounterData = userCounter(userCount, userType);

    useEffect(() => {
        dispatch(getPlayerList(data));
        dispatch(
            getUserCountData({
                user_type: [userType],
            })
        );
    }, [dispatch, data, userType]);

    return (
        <React.Fragment>
            <Title title={getPathTitle(Routes.PLAYERS)} />
            <DeleteModal
                show={showModal}
                onDeleteClick={() => setShowModal(false)}
                onCloseClick={() => setShowModal(false)}
            />
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb
                        parent={{
                            isParent: true,
                            pageTitle: getPathTitle(Routes.PLAYERS),
                            pageLink: getPath(Routes.PLAYERS),
                        }}
                    />
                    <Row>
                        {userCounterData.map((item, key) => (
                            <Col md={4} key={key}>
                                <CounterWidget icon={item?.icon} item={item} />
                            </Col>
                        ))}
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardHeader className="align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">
                                        {getPathTitle(Routes.PLAYERS)} List
                                    </h4>
                                    <div className="flex-shrink-0">
                                        <Link
                                            to={getPath(Routes.PLAYER_CREATE)}
                                        >
                                            <Button color="success" size="sm">
                                                <i className="ri-add-fill me-1 align-bottom"></i>
                                                {getPathTitle(
                                                    Routes.PLAYER_CREATE
                                                )}
                                            </Button>
                                        </Link>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Row className="mb-3">
                                        <Col md={6} className="tw-mb-3">
                                            <Select
                                                label="Status"
                                                isClearable={true}
                                                value={form.values.status}
                                                options={[
                                                    ...[
                                                        {
                                                            label: "All",
                                                            value: "",
                                                        },
                                                    ],
                                                    ...userStatus,
                                                ]}
                                                onChange={(event) =>
                                                    form.setValues(
                                                        (values) => ({
                                                            ...values,
                                                            status: event.target
                                                                .value,
                                                            page: 1,
                                                        })
                                                    )
                                                }
                                            />
                                        </Col>
                                        <Col md={6} className="tw-mb-3">
                                            <Select
                                                label="Verification Status"
                                                isClearable={true}
                                                value={
                                                    form.values
                                                        .verification_status
                                                }
                                                options={[
                                                    ...[
                                                        {
                                                            label: "All",
                                                            value: "",
                                                        },
                                                    ],
                                                    ...userVerificationStatus,
                                                ]}
                                                onChange={(event) =>
                                                    form.setValues(
                                                        (values) => ({
                                                            ...values,
                                                            verification_status:
                                                                event.target
                                                                    .value,
                                                            page: 1,
                                                        })
                                                    )
                                                }
                                            />
                                        </Col>
                                        {(isSuperAdmin || isAdmin) && (
                                            <Col md={6}>
                                                <div className="mb-2">
                                                    <Input
                                                        checked={parseInt(
                                                            form.values
                                                                .organic_players
                                                        )}
                                                        className="form-check-input tw-mr-2"
                                                        type="checkbox"
                                                        id="organic_players"
                                                        value={
                                                            form.values
                                                                .organic_players
                                                        }
                                                        onChange={(e) =>
                                                            form.setFieldValue(
                                                                "organic_players",
                                                                !parseInt(
                                                                    e.target
                                                                        .value
                                                                )
                                                                    ? 1
                                                                    : 0
                                                            )
                                                        }
                                                    />
                                                    <Label
                                                        className="form-check-label"
                                                        for="organic_players"
                                                    >
                                                        Organic Players
                                                    </Label>
                                                </div>
                                            </Col>
                                        )}

                                        <Col md={12}>
                                            <div className="w-100 tw-flex tw-gap-1 tw-flex-col lg:tw-flex-row mb-3">
                                                {dateFilterOptions.map(
                                                    (item, index) => (
                                                        <Button
                                                            key={index}
                                                            color={
                                                                selectedDate.label ===
                                                                item.label
                                                                    ? "primary"
                                                                    : "light"
                                                            }
                                                            onClick={() => {
                                                                setSelectedDate(
                                                                    item
                                                                );
                                                                if (
                                                                    item.value
                                                                ) {
                                                                    form.setFieldValue(
                                                                        "dateFilter",
                                                                        item.value
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            {item.label}
                                                        </Button>
                                                    )
                                                )}
                                                <div className="tw-w-full lg:tw-w-1/5">
                                                    <FlatPickerDate
                                                        ref={dateFilterRef}
                                                        name="dateFilter"
                                                        mode="range"
                                                        placeholder="From Date - To Date"
                                                        maxDate={null}
                                                        value={
                                                            selectedDate.value
                                                        }
                                                        onChange={(date) => {
                                                            if (
                                                                date.length >= 2
                                                            ) {
                                                                form.setFieldValue(
                                                                    "dateFilter",
                                                                    date.map(
                                                                        (
                                                                            value
                                                                        ) =>
                                                                            filterDateFormat(
                                                                                value
                                                                            )
                                                                    )
                                                                );
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={12} className="text-center">
                                            <Button
                                                color="primary"
                                                className="mx-1"
                                                onClick={resetForm}
                                            >
                                                Clear
                                            </Button>
                                            <Button
                                                color="success"
                                                className="mx-1"
                                                onClick={form.handleSubmit}
                                            >
                                                Filter
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Table
                                        search={search}
                                        handlerSearch={setSearch}
                                        sortBy={sortBy}
                                        handlerSortBy={setSortBy}
                                        thead={tableTheadData}
                                        handlerChangeData={setData}
                                        data={data}
                                        collection={list}
                                        isLoading={isLoading}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default PlayerList;
