export const GET_OPERATOR_LIST = "GET_OPERATOR_LIST";
export const SET_OPERATOR_LIST = "SET_OPERATOR_LIST";
export const SET_OPERATOR_LIST_ERROR = "SET_OPERATOR_LIST_ERROR";

export const CREATE_OPERATOR = "CREATE_OPERATOR";
export const CREATE_OPERATOR_SUCCESS = "CREATE_OPERATOR_SUCCESS";
export const CREATE_OPERATOR_ERROR = "CREATE_OPERATOR_ERROR";

export const GET_OPERATOR_PROFILE = "GET_OPERATOR_PROFILE";
export const SET_OPERATOR_PROFILE = "SET_OPERATOR_PROFILE";
export const SET_OPERATOR_PROFILE_ERROR = "SET_OPERATOR_PROFILE_ERROR";

export const UPDATE_OPERATOR = "UPDATE_OPERATOR";
export const UPDATE_OPERATOR_SUCCESS = "UPDATE_OPERATOR_SUCCESS";
export const UPDATE_OPERATOR_ERROR = "UPDATE_OPERATOR_ERROR";

export const DELETE_OPERATOR = "DELETE_OPERATOR";
export const DELETE_OPERATOR_SUCCESS = "DELETE_OPERATOR_SUCCESS";
export const DELETE_OPERATOR_ERROR = "DELETE_OPERATOR_ERROR";

export const UPDATE_OPERATOR_WALLET_BALANCE = "UPDATE_OPERATOR_WALLET_BALANCE";

export const UPDATE_OPERATOR_COMMISSION_RATE =
    "UPDATE_OPERATOR_COMMISSION_RATE";
export const UPDATE_OPERATOR_COMMISSION_RATE_SUCCESS =
    "UPDATE_OPERATOR_COMMISSION_RATE_SUCCESS";
export const UPDATE_OPERATOR_COMMISSION_RATE_ERROR =
    "UPDATE_OPERATOR_COMMISSION_RATE_ERROR";

export const RESET_OPERATOR_ERRORS = "RESET_OPERATOR_ERRORS";

export const UPDATE_OPERATOR_EMAIL_SUCCESS = "UPDATE_OPERATOR_EMAIL_SUCCESS";

export const GET_OPERATOR_SELECTION_LIST = "GET_OPERATOR_SELECTION_LIST";
export const GET_OPERATOR_SELECTION_LIST_SUCCESS =
    "GET_OPERATOR_SELECTION_LIST_SUCCESS";
export const GET_OPERATOR_SELECTION_LIST_FAILED =
    "GET_OPERATOR_SELECTION_LIST_FAILED";

export const UPDATE_MERCHANT_SETTING = "UPDATE_MERCHANT_SETTING";
export const UPDATE_MERCHANT_SETTING_SUCCESS =
    "UPDATE_MERCHANT_SETTING_SUCCESS";
export const UPDATE_MERCHANT_SETTING_FAILED = "UPDATE_MERCHANT_SETTING_FAILED";

export const UPDATE_CROSS_BETTING_PROTECTION =
    "UPDATE_CROSS_BETTING_PROTECTION";
export const UPDATE_CROSS_BETTING_PROTECTION_SUCCESS =
    "UPDATE_CROSS_BETTING_PROTECTION_SUCCESS";
export const UPDATE_CROSS_BETTING_PROTECTION_FAILED =
    "UPDATE_CROSS_BETTING_PROTECTION_FAILED";

export const UPDATE_OPERATOR_ROLE_TAG = "UPDATE_OPERATOR_ROLE_TAG";
export const UPDATE_OPERATOR_ROLE_TAG_SUCCESS =
    "UPDATE_OPERATOR_ROLE_TAG_SUCCESS";
export const UPDATE_OPERATOR_ROLE_TAG_ERROR = "UPDATE_OPERATOR_ROLE_TAG_ERROR";

export const APPLY_DOWNLINES_COMMISSIONS_TRANSFER_SCHEDULE_OPERATOR =
    "APPLY_DOWNLINES_COMMISSIONS_TRANSFER_SCHEDULE_OPERATOR";
export const APPLY_DOWNLINES_COMMISSIONS_TRANSFER_SCHEDULE_OPERATOR_SUCCESS =
    "APPLY_DOWNLINES_COMMISSIONS_TRANSFER_SCHEDULE_OPERATOR_SUCCESS";
export const APPLY_DOWNLINES_COMMISSIONS_TRANSFER_SCHEDULE_OPERATOR_ERROR =
    "APPLY_DOWNLINES_COMMISSIONS_TRANSFER_SCHEDULE_OPERATOR_ERROR";
