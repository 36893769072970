import {
    Button,
    ErrorAlert,
    InputActionChecker,
    InputDate,
    InputReactSelect,
    InputSelect,
    InputText,
} from "@/components/atoms";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button as ButtonComponent,
    Col,
    Form,
    FormGroup,
    Row,
} from "reactstrap";

import { userGenderOptions, userValidation } from "@/constants";
import {
    getBarangays,
    getCities,
    getProvinces,
    getRegions,
    getCountries,
    resetAddressesData,
    resetAgentErrors,
    resetUserErrors,
    updateAdmin,
    updateAgent,
    updateOperator,
    updatePlayer,
    updateUserProfile,
} from "@/store/actions";
import { defaultDateFormat } from "@/utils";

const PersonalInformationForm = ({
    profile,
    userType,
    isLoading,
    isAdmin,
    isEditable = true,
    errors,
}) => {
    const dispatch = useDispatch();
    const [isEdit, setIsEdit] = useState(false);
    const [addresses, setAddresses] = useState({});
    const [country, setCountry] = useState(profile?.country?.name ?? null);
    const { countries, regions, provinces, cities, barangays } = useSelector(
        (state) => state.address
    );
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            first_name: profile.firstName,
            last_name: profile.lastName,
            middle_name: profile.middleName,
            extension_name: profile.extensionName,
            birth_date: profile.birthDate,
            gender: profile.gender.toLowerCase(),
            occupation: profile.occupation,
            country: profile?.country?.keyToken,
            region: profile.region.keyToken,
            province: profile.province.keyToken,
            city: profile.city.keyToken,
            barangay: profile.barangay.keyToken,
            building_no: profile.buildingNo,
            street: profile.street,
            postal_code: profile.postalCode,
        },
        validationSchema: userValidation,
        onSubmit: (values) => {
            switch (userType) {
                case "player":
                    dispatch(
                        updatePlayer(
                            values,
                            profile.userProfilekeyToken,
                            validation
                        )
                    );
                    break;
                case "user":
                    dispatch(
                        updateUserProfile(
                            values,
                            profile.userProfilekeyToken,
                            validation
                        )
                    );
                    break;
                case "admin":
                    dispatch(
                        updateAdmin(
                            values,
                            profile.userProfilekeyToken,
                            validation
                        )
                    );
                    break;
                case "operator":
                    dispatch(
                        updateOperator(
                            values,
                            profile.userProfilekeyToken,
                            validation
                        )
                    );
                    break;
                default:
                    dispatch(
                        updateAgent(
                            values,
                            profile.userProfilekeyToken,
                            validation
                        )
                    );
            }
        },
    });

    const addressValueHandler = (option, type) => {
        let value = option.value;
        validation.setFieldValue(type, value);
        if (type === "country") {
            dispatch(getRegions());
            validation.setFieldValue("region", "");
            validation.setFieldValue("province", "");
            validation.setFieldValue("city", "");
            validation.setFieldValue("barangay", "");
        }
        if (type === "region") {
            dispatch(getProvinces(value));
            validation.setFieldValue("province", "");
            validation.setFieldValue("city", "");
            validation.setFieldValue("barangay", "");
        } else if (type === "province") {
            dispatch(getCities(value));
            validation.setFieldValue("city", "");
            validation.setFieldValue("barangay", "");
        } else if (type === "city") {
            dispatch(getBarangays(value));
            validation.setFieldValue("barangay", "");
        }
        setAddresses({
            ...addresses,
            [type]: option,
        });
    };

    const handlerEdit = () => {
        setIsEdit(true);
        validation.setErrors({});
        dispatch(resetAddressesData());
        dispatch(getCountries());
        dispatch(getRegions());
        if (profile?.country?.name === "Philippines") {
            dispatch(getProvinces(profile.region.keyToken));
            dispatch(getCities(profile.province.keyToken));
            dispatch(getBarangays(profile.city.keyToken));
        }
    };

    const handlerBackProfile = () => {
        setIsEdit(false);
        validation.setErrors({});
        validation.resetForm();
        dispatch(resetAgentErrors());
    };

    const personalInformation = () => {
        return (
            <>
                <p className="text-muted pb-3">
                    Personal Information
                    {isAdmin && (
                        <>
                            {isEdit && (
                                <ButtonComponent
                                    type="button"
                                    color="danger"
                                    size="sm"
                                    onClick={handlerBackProfile}
                                    className="float-end">
                                    <span className="ri-arrow-go-back-line align-middle"></span>{" "}
                                    Cancel
                                </ButtonComponent>
                            )}
                            {!isEdit && isEditable && (
                                <ButtonComponent
                                    type="button"
                                    color="primary"
                                    size="sm"
                                    onClick={handlerEdit}
                                    className="float-end">
                                    <span className="ri-edit-line align-middle"></span>{" "}
                                    Edit Personal Information
                                </ButtonComponent>
                            )}
                        </>
                    )}
                </p>
                <Row className="mb-3">
                    <Col md="4">
                        <InputActionChecker
                            label="First Name"
                            value={profile.firstName}
                            isEdit={isEdit}>
                            <FormGroup className="mb-3">
                                <InputText
                                    label="First Name"
                                    name="first_name"
                                    placeholder="First name"
                                    validation={validation}
                                />
                            </FormGroup>
                        </InputActionChecker>
                    </Col>
                    <Col md="3">
                        <InputActionChecker
                            label="Last Name"
                            value={profile.lastName}
                            isEdit={isEdit}>
                            <FormGroup className="mb-3">
                                <InputText
                                    label="Last Name"
                                    name="last_name"
                                    placeholder="Last name"
                                    validation={validation}
                                />
                            </FormGroup>
                        </InputActionChecker>
                    </Col>
                    <Col md="3">
                        <InputActionChecker
                            label="Middle Name"
                            value={profile.middleName}
                            isEdit={isEdit}>
                            <FormGroup className="mb-3">
                                <InputText
                                    label="Middle Name"
                                    name="middle_name"
                                    placeholder="Middle name"
                                    validation={validation}
                                />
                            </FormGroup>
                        </InputActionChecker>
                    </Col>
                    <Col md="2">
                        <InputActionChecker
                            label="Suffix Name"
                            value={profile.extensionName}
                            isEdit={isEdit}>
                            <FormGroup className="mb-3">
                                <InputText
                                    label="Suffix Name"
                                    name="extension_name"
                                    placeholder="Ex. Jr, Sr etc..."
                                    validation={validation}
                                />
                            </FormGroup>
                        </InputActionChecker>
                    </Col>

                    <Col md="4">
                        <InputActionChecker
                            label="BirthDate"
                            value={defaultDateFormat(profile.birthDate)}
                            isEdit={isEdit}>
                            <FormGroup className="mb-3">
                                <InputDate
                                    label="BirthDate"
                                    name="birth_date"
                                    validation={validation}
                                />
                            </FormGroup>
                        </InputActionChecker>
                    </Col>
                    <Col md="4">
                        <InputActionChecker
                            label="Gender"
                            value={profile.gender}
                            isEdit={isEdit}>
                            <FormGroup className="mb-3">
                                <InputSelect
                                    label="Gender"
                                    name="gender"
                                    validation={validation}
                                    options={userGenderOptions}
                                />
                            </FormGroup>
                        </InputActionChecker>
                    </Col>
                    <Col md="4">
                        <InputActionChecker
                            label="Occupation"
                            value={profile.occupation}
                            isEdit={isEdit}>
                            <FormGroup className="mb-3">
                                <InputText
                                    label="Occupation"
                                    name="occupation"
                                    placeholder="Occupation"
                                    validation={validation}
                                />
                            </FormGroup>
                        </InputActionChecker>
                    </Col>
                </Row>
            </>
        );
    };

    const addressInformation = () => {
        return (
            <>
                <p className="text-muted">Address Information</p>
                <Row className="mb-3">
                    <Col md="12">
                        <Row>
                            <Col md="6">
                                <InputActionChecker
                                    label="Country"
                                    value={profile.country?.name}
                                    isEdit={isEdit}>
                                    <FormGroup className="mb-3">
                                        <InputReactSelect
                                            label="Country"
                                            name="country"
                                            onChange={(option) => {
                                                setCountry(option.label);
                                                addressValueHandler(
                                                    option,
                                                    "country"
                                                );
                                            }}
                                            value={
                                                validation.values.country
                                                    ? addresses.country
                                                    : ""
                                            }
                                            options={countries}
                                            validation={validation}
                                        />
                                    </FormGroup>
                                </InputActionChecker>
                            </Col>
                        </Row>
                    </Col>
                    {(country === "Philippines" || !profile.country?.name) && (
                        <>
                            <Col md="6">
                                <InputActionChecker
                                    label="Region"
                                    value={profile.region.name}
                                    isEdit={isEdit}>
                                    <FormGroup className="mb-3">
                                        <InputReactSelect
                                            label="Region"
                                            name="region"
                                            onChange={(option) => {
                                                addressValueHandler(
                                                    option,
                                                    "region"
                                                );
                                            }}
                                            value={
                                                validation.values.region
                                                    ? addresses.region
                                                    : ""
                                            }
                                            options={regions}
                                            validation={validation}
                                        />
                                    </FormGroup>
                                </InputActionChecker>
                            </Col>
                            <Col md="6">
                                <InputActionChecker
                                    label="Province"
                                    value={profile.province.name}
                                    isEdit={isEdit}>
                                    <FormGroup className="mb-3">
                                        <InputReactSelect
                                            label="Province"
                                            name="province"
                                            onChange={(option) => {
                                                addressValueHandler(
                                                    option,
                                                    "province"
                                                );
                                            }}
                                            value={
                                                validation.values.province
                                                    ? addresses.province
                                                    : ""
                                            }
                                            options={provinces}
                                            validation={validation}
                                        />
                                    </FormGroup>
                                </InputActionChecker>
                            </Col>
                            <Col md="6">
                                <InputActionChecker
                                    label="City"
                                    value={profile.city.name}
                                    isEdit={isEdit}>
                                    <FormGroup className="mb-3">
                                        <InputReactSelect
                                            label="City"
                                            name="city"
                                            onChange={(option) => {
                                                addressValueHandler(
                                                    option,
                                                    "city"
                                                );
                                            }}
                                            value={
                                                validation.values.city
                                                    ? addresses.city
                                                    : ""
                                            }
                                            options={cities}
                                            validation={validation}
                                        />
                                    </FormGroup>
                                </InputActionChecker>
                            </Col>
                            <Col md="6">
                                <InputActionChecker
                                    label="Barangay"
                                    value={profile.barangay.name}
                                    isEdit={isEdit}>
                                    <FormGroup className="mb-3">
                                        <InputReactSelect
                                            label="Barangay"
                                            name="barangay"
                                            onChange={(option) => {
                                                addressValueHandler(
                                                    option,
                                                    "barangay"
                                                );
                                            }}
                                            value={
                                                validation.values.barangay
                                                    ? addresses.barangay
                                                    : ""
                                            }
                                            options={barangays}
                                            validation={validation}
                                        />
                                    </FormGroup>
                                </InputActionChecker>
                            </Col>
                        </>
                    )}
                </Row>
                <Row className="mb-3">
                    <Col md="4">
                        <InputActionChecker
                            label="Building No."
                            value={
                                profile.buildingNo ? profile.buildingNo : "-"
                            }
                            isEdit={isEdit}>
                            <FormGroup className="mb-3">
                                <InputText
                                    label="Building No."
                                    name="building_no"
                                    placeholder="Building No"
                                    validation={validation}
                                />
                            </FormGroup>
                        </InputActionChecker>
                    </Col>
                    <Col md="4">
                        <InputActionChecker
                            label="Street"
                            value={profile.street ? profile.street : "-"}
                            isEdit={isEdit}>
                            <FormGroup className="mb-3">
                                <InputText
                                    label="Street"
                                    name="street"
                                    placeholder="Street"
                                    validation={validation}
                                />
                            </FormGroup>
                        </InputActionChecker>
                    </Col>
                    <Col md="4">
                        <InputActionChecker
                            label="Postal Code"
                            value={
                                profile.postalCode ? profile.postalCode : "-"
                            }
                            isEdit={isEdit}>
                            <FormGroup className="mb-3">
                                <InputText
                                    label="Postal Code"
                                    name="postal_code"
                                    placeholder="Postal Code"
                                    validation={validation}
                                />
                            </FormGroup>
                        </InputActionChecker>
                    </Col>
                </Row>
            </>
        );
    };

    useEffect(() => {
        if (profile) {
            setAddresses({
                country: {
                    value: profile?.country?.keyToken,
                    label: profile?.country?.name,
                },
                region: {
                    value: profile.region.keyToken,
                    label: profile.region.name,
                },
                province: {
                    value: profile.province.keyToken,
                    label: profile.province.name,
                },
                city: {
                    value: profile.city.keyToken,
                    label: profile.city.name,
                },
                barangay: {
                    value: profile.barangay.keyToken,
                    label: profile.barangay.name,
                },
            });
        }
        dispatch(resetUserErrors());
    }, [dispatch, setAddresses, profile]);

    return (
        <>
            <ErrorAlert errors={errors} validation={validation} />
            <Form
                className="needs-validation"
                onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}>
                {personalInformation()}
                {addressInformation()}

                {isEdit && isEditable && (
                    <div className="border-top py-3 mt-3 text-end">
                        <ButtonComponent
                            color="danger"
                            type="button"
                            className="me-2"
                            onClick={handlerBackProfile}
                            outline>
                            Cancel
                        </ButtonComponent>
                        <Button
                            color="success"
                            type="submit"
                            text="Save"
                            isLoading={isLoading}
                        />
                    </div>
                )}
            </Form>
        </>
    );
};

export default PersonalInformationForm;
