export const GET_REGIONS = "GET_REGION";
export const GET_REGIONS_SUCCESS = "GET_REGION_SUCCESS";
export const GET_REGIONS_ERROR = "GET_REGION_ERROR";

export const GET_PROVINCES = "GET_PROVINCES";
export const GET_PROVINCES_SUCCESS = "GET_PROVINCES_SUCCESS";
export const GET_PROVINCES_ERROR = "GET_PROVINCES_ERROR";

export const GET_CITIES = "GET_CITIES";
export const GET_CITIES_SUCCESS = "GET_CITIES_SUCCESS";
export const GET_CITIES_ERROR = "GET_CITIES_ERROR";

export const GET_BARANGAYS = "GET_BARANGAYS";
export const GET_BARANGAYS_SUCCESS = "GET_BARANGAYS_SUCCESS";
export const GET_BARANGAYS_ERROR = "GET_BARANGAYS_ERROR";

export const RESET_ADDRESSES_DATA = "RESET_ADDRESSES_DATA";

export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS";
export const GET_COUNTRIES_FAILED = "GET_COUNTRIES_FAILED";