import { errorToast, successToast } from "@/components/constants/toast";
import * as API from "@/network/apis/report";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import * as TYPES from "./actionTypes";
import * as ACTIONS from "./actions";

function* getWinLossReportAsync(action) {
    try {
        const response = yield call(API.getWinLossReport, action.payload);

        if (response.status === 200) {
            yield put(ACTIONS.getWinLossReportSuccess(response.data.response));
        }
    } catch (error) {
        yield put(ACTIONS.getWinLossReportFailed());
        switch (error.response.data.code) {
            case 422:
                errorToast(error.response.data.message);
                break;
            default:
                break;
        }
    }
}

function* getWinLossByProductReportAsync(action) {
    try {
        const response = yield call(
            API.getWinLossByProductReport,
            action.payload
        );

        if (response.status === 200) {
            yield put(
                ACTIONS.onGetWinLossByProductReportSuccess(
                    response.data.response
                )
            );
        }
    } catch (error) {
        yield put(ACTIONS.onGetWinLossByProductReportFailed());
        switch (error.response.data.code) {
            case 422:
                errorToast(error.response.data.message);
                break;
            default:
                break;
        }
    }
}

function* getGeneratedReportsAsync(action) {
    try {
        const response = yield call(API.getGeneratedReports, action.payload);

        if (response.status === 200) {
            yield put(
                ACTIONS.onGetGeneratedReportsSuccess(response.data.response)
            );
        }
    } catch (error) {
        yield put(ACTIONS.onGetGeneratedReportsFailed());
        switch (error.response.data.code) {
            case 422:
                errorToast(error.response.data.message);
                break;
            default:
                break;
        }
    }
}

function* generateBatchPayoutReportAsync(action) {
    try {
        const response = yield call(
            API.generateBatchPayoutReport,
            action.payload
        );

        if (response.status === 200) {
            yield put(
                ACTIONS.onGenerateBatchPayoutReportSuccess(
                    response.data.response
                )
            );
            successToast(response.data.message);
        }
    } catch (error) {
        yield put(ACTIONS.onGenerateBatchPayoutReportFailed());
        switch (error.response.data.code) {
            case 422:
                errorToast(error.response.data.message);
                break;
            default:
                break;
        }
    }
}

function* getLoginLogsAsync(action) {
    try {
        const response = yield call(API.getLoginLogs, action.payload);

        if (response.status === 200) {
            yield put(ACTIONS.onGetLoginLogsSuccess(response.data.response));
            // successToast(response.data.message);
        }
    } catch (error) {
        yield put(ACTIONS.onGetLoginLogsFailed());
        switch (error.response.data.code) {
            case 422:
                errorToast(error.response.data.message);
                break;
            default:
                break;
        }
    }
}

function* getMultipleIPAsync(action) {
    try {
        const response = yield call(API.getMultipleIPDetected, action.payload);

        if (response.status === 200) {
            yield put(
                ACTIONS.onGetMultipleIPDataSuccess(response.data.response)
            );
            // successToast(response.data.message);
        }
    } catch (error) {
        yield put(ACTIONS.onGetMultipleIPDataFailed());
        switch (error.response.data.code) {
            case 422:
                errorToast(error.response.data.message);
                break;
            default:
                break;
        }
    }
}

function* watchGetGeneratedReportsAsync() {
    yield takeLatest(TYPES.GET_GENERATED_REPORTS, getGeneratedReportsAsync);
}

function* watchGenerateBatchPayoutReport() {
    yield takeLatest(
        TYPES.GENERATE_BATCH_PAYOUT_REPORT,
        generateBatchPayoutReportAsync
    );
}

function* watchGetWinLossReport() {
    yield takeLatest(TYPES.GET_WIN_LOSS_REPORT, getWinLossReportAsync);
}

function* watchGetWinLossByProductReport() {
    yield takeLatest(
        TYPES.GET_WIN_LOSS_REPORT_BY_PRODUCT,
        getWinLossByProductReportAsync
    );
}

function* watchgetLoginLogs() {
    yield takeLatest(TYPES.GET_LOGIN_LOGS, getLoginLogsAsync);
}

function* watchGetMultipleIPAsync() {
    yield takeLatest(TYPES.GET_MULTIPLE_IP_DATA, getMultipleIPAsync);
}

function* reportSagas() {
    yield all([
        fork(watchGetWinLossReport),
        fork(watchGetWinLossByProductReport),
        fork(watchGetGeneratedReportsAsync),
        fork(watchGenerateBatchPayoutReport),
        fork(watchgetLoginLogs),
        fork(watchGetMultipleIPAsync),
    ]);
}

export default reportSagas;
