import React, { Fragment, useMemo, useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import BreadCrumb from "@/components/common/BreadCrumb";
import {
    AreaCharts,
    FlatPickerDate,
    LineCharts,
    Title,
} from "@/components/atoms";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row,
} from "reactstrap";
import {
    getDailyTotalDepositAndWithdrawalSummary,
    getDailyTotalGgrSummary,
    getDashboardAnalyticsMainTotal,
    getDashboardAnalyticsOverallTotal,
    getMonthlyTotalPlayerSummary,
} from "@/store/actions";
import { Routes, getPath, getPathTitle } from "@/constants";
import { dateFilterOptions } from "@/constants/users";
import { defaultCurrency, filterDateFormat } from "@/utils/common";
import { Link, useSearchParams } from "react-router-dom";
import CountUp from "react-countup";
import * as DashboardIcons from "@/assets/svg-icons/dashboard";
import { userRole } from "@/hooks";
import { WinLossByAgentReport } from "..";
import {
    Currency,
    CommonCard,
    FilledCard,
} from "@/components/molecules/DashboardComponents";

const { BasicColumn, SplineAreaChart } = AreaCharts;
const { BasicLineCharts } = LineCharts;

const Dashboard = () => {
    const isMounted = useRef(false);
    const { isLicenseeAdmin, isSuperAdmin } = userRole();

    const [searchParams, setSearchParams] = useSearchParams();
    const { filter, date_from, date_to } = Object.fromEntries([
        ...searchParams,
    ]);
    const dispatch = useDispatch();

    const [selectedDate, setSelectedDate] = useState(dateFilterOptions[0]);
    const [data, setData] = useState({
        date_from: dateFilterOptions[0]?.value[0],
        date_to: dateFilterOptions[0]?.value[1],
    });

    const {
        dashboardMainTotal,
        isGetDashboardMainTotalLoading,
        dashboardOverallTotal: overallTotalDashboard,
        isGetDashboardOverallTotalLoading: isOverallLoading,
        dailyTotalGgrSummary,
        dailyTotalDepositAndWithdrawalSummary,
        monthlyTotalPlayerSummary,
        isGetDailyTotalGgrSummaryLoading,
        isGetDailyTotalDepositAndWithdrawalSummaryLoading,
        isGetMonthlyTotalPlayerSummaryLoading,
    } = useSelector((state) => state.dashboard);
    const { user } = useSelector((state) => state.user);

    const [graphWithdrawalAndDepositType, setGraphWithdrawalAndDepositType] =
        useState("");
    const [graphGgrType, setGraphGgrType] = useState("");

    const dashboardOverallTotal = useMemo(
        () => overallTotalDashboard?.total,
        [overallTotalDashboard]
    );

    const isGetDashboardOverallTotalLoading = useMemo(
        () =>
            isOverallLoading ||
            overallTotalDashboard?.total?.merchant?.isLoading ||
            overallTotalDashboard?.operator?.merchant?.isLoading ||
            overallTotalDashboard?.["community-manager"]?.merchant?.isLoading ||
            overallTotalDashboard?.affiliate?.merchant?.isLoading ||
            overallTotalDashboard?.["organic-player"]?.merchant?.isLoading ||
            overallTotalDashboard?.total?.bcf?.isLoading ||
            overallTotalDashboard?.operator?.bcf?.isLoading ||
            overallTotalDashboard?.["community-manager"]?.bcf?.isLoading ||
            overallTotalDashboard?.affiliate?.bcf?.isLoading ||
            overallTotalDashboard?.["organic-player"]?.bcf?.isLoading,
        [overallTotalDashboard, isOverallLoading]
    );

    const isShowMerchantTotals =
        user?.role?.slug === "super-admin" || user?.allowedInMerchant;

    const graphGgrTypeOptions = [
        {
            label: "All",
            value: "",
        },
        {
            label: "Merchant",
            value: "merchant",
        },
        {
            label: "BCF",
            value: "bcf",
        },
    ];

    const graphWithdrawalAndDepositTypeOptions = [
        {
            label: "All",
            value: "",
        },
        {
            label: "Merchant Deposit",
            value: "merchant_deposit",
        },
        {
            label: "BCF Deposit",
            value: "bcf_deposit",
        },
        {
            label: "Merchant Withdrawal",
            value: "merchant_withdrawal",
        },
        {
            label: "BCF Withdrawal",
            value: "bcf_withdrawal",
        },
    ];

    const overallTotal = {
        merchant: {
            title: "Merchant",
            currency: "PHP",
            titleClass: "tw-text-lg tw-text-[#B572CD] tw-font-semibold tw-mt-3",
            containerClass: "tw-border-[#C746F5]",
            overall_total_ggr: {
                label: "Overall Total Merchant GGR",
                value: dashboardOverallTotal?.merchant?.ggr ?? 0,
                containerClass: "tw-border-[#80289F] tw-bg-[#A400DE]/20",
                progresColor: "tw-bg-[#553C66]",
                progressAccent: "tw-bg-[#A86BBD]",
                icon: <DashboardIcons.MerhantGGR />,
                breakdown: {
                    operator: overallTotalDashboard?.operator?.merchant?.ggr,
                    comunity_manager:
                        overallTotalDashboard?.["community-manager"]?.merchant
                            ?.ggr,
                    affiliate: overallTotalDashboard?.affiliate?.merchant?.ggr,
                    organic_player:
                        overallTotalDashboard?.["organic-player"]?.merchant
                            ?.ggr,
                },
                link: `${getPath(
                    Routes.WIN_LOSS_BY_AGENT_REPORT
                )}?balance_category=merchant${
                    filter ? "&date_filter=" + filter : ""
                }${filter ? "&date_from=" + date_from : ""}${
                    filter ? "&date_to=" + date_to : ""
                }`,
            },
            breakdown: {
                overall_total_turnover: {
                    label: "Overall total MERCHANT Turnover",
                    value: dashboardOverallTotal?.merchant?.turnover ?? 0,
                    icon: <DashboardIcons.MerchantTurnover />,
                    link: `${getPath(
                        Routes.WIN_LOSS_BY_AGENT_REPORT
                    )}?balance_category=merchant`,
                },
                overall_total_deposit: {
                    label: "Overall total MERCHANT Deposit",
                    value: dashboardOverallTotal?.merchant?.deposit ?? 0,
                    icon: <DashboardIcons.MerchantDeposit />,
                    link: getPath(Routes.DEPOSIT_REQUEST),
                },
                overall_total_withdrawal: {
                    label: "Overall total MERCHANT Withdrawal",
                    value: dashboardOverallTotal?.merchant?.withdrawal ?? 0,
                    icon: <DashboardIcons.MerchantWithdrawal />,
                    link: getPath(Routes.WITHDRAWAL_REQUEST),
                },
                overall_total_transaction_fees: {
                    label: "Overall total Transaction Fees",
                    gpFees: dashboardOverallTotal?.merchant?.gp_fee,
                    depositFees: dashboardOverallTotal?.merchant?.deposit_fee,
                    withdrawalFees:
                        dashboardOverallTotal?.merchant?.withdrawal_fee,
                    icon: <DashboardIcons.TransactionFees />,
                    link: getPath(Routes.WITHDRAWAL_REQUEST),
                },
                overall_total_generated_commissions: {
                    label: "Overall total Generated Commissions",
                    value: dashboardOverallTotal?.merchant?.commission ?? 0,
                    icon: <DashboardIcons.GeneratedCommissions />,
                    link: `${getPath(
                        Routes.COMMISSIONS_AGENTS
                    )}?balance_category=merchant&date_filter=${
                        filter ?? "Today"
                    }`,
                },
                overall_total_ggr_run_rate: {
                    label: "Overall total GGr Run Rate",
                    value: dashboardOverallTotal?.merchant?.ggr_run_rate ?? 0,
                    icon: <DashboardIcons.GGRRunRate />,
                    link: "",
                },
            },
        },
        bcf: {
            title: "BCF",
            currency: process.env.REACT_APP_BCF_LABEL,
            titleClass: "tw-text-lg tw-text-[#B2CDFF] tw-font-semibold",
            containerClass: "tw-border-[#455D92]",
            overall_total_ggr: {
                label: "Overall Total BCF GGR",
                value: dashboardOverallTotal?.bcf?.ggr ?? 0,
                containerClass: "tw-border-[#455D92] tw-bg-[#1A2640]",
                progresColor: "tw-bg-[#2B3A49]",
                progressAccent: "tw-bg-[#6B84BC]",
                icon: <DashboardIcons.BCF />,
                breakdown: {
                    operator: overallTotalDashboard?.operator?.bcf?.ggr,
                    comunity_manager:
                        overallTotalDashboard?.["community-manager"]?.bcf?.ggr,
                    affiliate: overallTotalDashboard?.affiliate?.bcf?.ggr,
                    organic_player:
                        overallTotalDashboard?.["organic-player"]?.bcf?.ggr,
                },
                link: `${getPath(
                    Routes.WIN_LOSS_BY_AGENT_REPORT
                )}?balance_category=bcf${
                    filter ? "&date_filter=" + filter : ""
                }${filter ? "&date_from=" + date_from : ""}${
                    filter ? "&date_to=" + date_to : ""
                }`,
            },
            breakdown: {
                overall_total_turnover: {
                    label: "Overall total BCF Turnover",
                    value: dashboardOverallTotal?.bcf?.turnover ?? 0,
                    icon: <DashboardIcons.MerchantTurnover />,
                    link: getPath(Routes.WIN_LOSS_BY_AGENT_REPORT),
                },
                overall_total_deposit: {
                    label: "Overall total BCF Deposit",
                    value: dashboardOverallTotal?.bcf?.deposit ?? 0,
                    icon: <DashboardIcons.MerchantDeposit />,
                    link: getPath(Routes.DEPOSIT_REQUEST),
                },
                overall_total_withdrawal: {
                    label: "Overall total BCF Withdrawal",
                    value: dashboardOverallTotal?.bcf?.withdrawal ?? 0,
                    icon: <DashboardIcons.MerchantWithdrawal />,
                    link: getPath(Routes.WITHDRAWAL_REQUEST),
                },
                overall_total_transaction_fees: {
                    label: "Overall total Transaction Fees",
                    gpFees: dashboardOverallTotal?.bcf?.gp_fee,
                    depositFees: dashboardOverallTotal?.bcf?.deposit_fee,
                    withdrawalFees: dashboardOverallTotal?.bcf?.withdrawal_fee,
                    icon: <DashboardIcons.TransactionFees />,
                    link: getPath(Routes.WITHDRAWAL_REQUEST),
                },
                overall_total_generated_commissions: {
                    label: "Overall total Generated Commissions",
                    value: dashboardOverallTotal?.bcf?.commission,
                    icon: <DashboardIcons.GeneratedCommissions />,
                    link: `${getPath(
                        Routes.COMMISSIONS_AGENTS
                    )}?balance_category=bcf&date_filter=${filter ?? "Today"}`,
                },
                overall_total_ggr_run_rate: {
                    label: "Overall total GGr Run Rate",
                    value: dashboardOverallTotal?.bcf?.ggr_run_rate ?? 0,
                    icon: <DashboardIcons.GGRRunRate />,
                    link: "",
                },
            },
        },
    };

    const mainTotals = [
        {
            id: 1,
            label: `Current Balance ${
                ["super-admin", "operator"].includes(user?.role?.slug)
                    ? "(Operator Chip)"
                    : ""
            }`,
            labelClass: "tw-text-[#D6D8E1]",
            counter: dashboardMainTotal?.current_balance,
            counterClass: "tw-text-[#A400DE]",
            caption: "",
            link: "/",
            decimals: 2,
            prefix: `<i class="ri-wallet-line tw-text-[#A400DE]"></i> ${defaultCurrency()}`,
            suffix: "",
            separator: ",",
            bgColor: "tw-bg-[#A400DE]/20",
        },
        {
            id: 2,
            label: "Pending withdrawal amount",
            labelClass: "tw-text-[#D6D8E1]",
            counter: dashboardMainTotal?.pending_withdrawal_amount,
            counterClass: "tw-text-[#5E8AFC]",
            caption: "Process requests",
            link: getPath(Routes.WITHDRAWAL_REQUEST),
            decimals: 2,
            prefix: `<i class="ri-wallet-line tw-text-[#5E8AFC]"></i> ${defaultCurrency()}`,
            suffix: "",
            separator: ",",
            bgColor: "tw-bg-[#4473EC]/20",
        },
        {
            id: 3,
            label: "Lifetime Gaming Revenue",
            labelClass: "tw-text-[#D6D8E1]",
            counter: dashboardMainTotal?.lifetime_ggr,
            counterClass: "tw-text-[#F7B84B]",
            caption: "",
            link: "/",
            decimals: 2,
            prefix: `<i class="ri-wallet-line tw-text-[#F7B84B]"></i> ${defaultCurrency()}`,
            suffix: "",
            separator: ",",
            bgColor: "tw-bg-[#F7B84B]/20",
        },
        {
            id: 4,
            label: `Player Balance (${process.env.REACT_APP_BCF_LABEL})`,
            labelClass: "muted",
            counter: dashboardMainTotal?.total_player_bcf,
            caption: "",
            link: "/",
            decimals: 2,
            prefix: `<i class="ri-wallet-line"></i> ${defaultCurrency()}`,
            suffix: "",
            separator: ",",
        },
        {
            id: 5,
            label: "Player Balance (PHP)",
            labelClass: "muted",
            counter: dashboardMainTotal?.total_player_merchant,
            caption: "",
            link: "/",
            decimals: 2,
            prefix: `<i class="ri-wallet-line"></i> ${defaultCurrency()}`,
            suffix: "",
            separator: ",",
        },
        {
            id: 6,
            label: "Total Player Balance",
            labelClass: "muted",
            counter: dashboardMainTotal?.total_player_chips,
            caption: "",
            link: "/",
            decimals: 2,
            prefix: `<i class="ri-wallet-line"></i> ${defaultCurrency()}`,
            suffix: "",
            separator: ",",
        },
    ];

    const overallTotalMerchant = [
        {
            id: 1,
            label: "Overall Total GGR",
            labelClass: "muted",
            percentage: "+16.24 %",
            percentageClass: "success",
            percentageIcon: "ri-arrow-right-up-line",
            counter: dashboardOverallTotal?.merchant?.ggr,
            caption: "View Details",
            link: getPath(Routes.WIN_LOSS_BY_AGENT_REPORT),
            icon: "ri-wallet-line",
            iconClass: "warning",
            decimals: 2,
            prefix: defaultCurrency(),
            suffix: "",
            separator: ",",
        },
        {
            id: 2,
            label: "Overall Total Turnover",
            labelClass: "muted",
            percentage: "+42.24 %",
            percentageClass: "success",
            percentageIcon: "ri-arrow-right-up-line",
            counter: dashboardOverallTotal?.merchant?.turnover,
            caption: "View Details",
            link: getPath(Routes.WIN_LOSS_BY_AGENT_REPORT),
            icon: "ri-wallet-line",
            iconClass: "success",
            decimals: 2,
            prefix: defaultCurrency(),
            suffix: "",
            separator: ",",
        },
        {
            id: 3,
            label: "Overall Total Deposit",
            labelClass: "muted",
            percentage: "+14.34 %",
            percentageClass: "muted",
            counter: dashboardOverallTotal?.merchant?.deposit,
            caption: "View Details",
            link: getPath(Routes.TRANSACTIONS),
            icon: "ri-wallet-line",
            iconClass: "primary",
            decimals: 2,
            prefix: defaultCurrency(),
            separator: ",",
            suffix: "",
        },
        {
            id: 4,
            label: "Overall Total Withdrawal",
            labelClass: "muted",
            percentage: "+21.20 %",
            percentageClass: "muted",
            counter: dashboardOverallTotal?.merchant?.withdrawal,
            caption: "View Details",
            link: getPath(Routes.TRANSACTIONS),
            icon: "ri-wallet-line",
            iconClass: "primary",
            decimals: 2,
            prefix: defaultCurrency(),
            separator: ",",
            suffix: "",
        },
    ];

    const overallTotalBcf = [
        {
            id: 1,
            label: "Overall Total GGR",
            labelClass: "muted",
            percentage: "+16.24 %",
            percentageClass: "success",
            percentageIcon: "ri-arrow-right-up-line",
            counter: dashboardOverallTotal?.bcf?.ggr,
            caption: "View Details",
            link: getPath(Routes.WIN_LOSS_BY_AGENT_REPORT),
            icon: "ri-wallet-line",
            iconClass: "warning",
            decimals: 2,
            prefix: defaultCurrency(),
            suffix: "",
            separator: ",",
        },
        {
            id: 2,
            label: "Overall Total Turnover",
            labelClass: "muted",
            percentage: "+42.24 %",
            percentageClass: "success",
            percentageIcon: "ri-arrow-right-up-line",
            counter: dashboardOverallTotal?.bcf?.turnover,
            caption: "View Details",
            link: getPath(Routes.WIN_LOSS_BY_AGENT_REPORT),
            icon: "ri-wallet-line",
            iconClass: "success",
            decimals: 2,
            prefix: defaultCurrency(),
            suffix: "",
            separator: ",",
        },
        {
            id: 3,
            label: "Overall Total Deposit",
            labelClass: "muted",
            percentage: "+14.34 %",
            percentageClass: "muted",
            counter: dashboardOverallTotal?.bcf?.deposit,
            caption: "View Details",
            link: getPath(Routes.TRANSACTIONS),
            icon: "ri-wallet-line",
            iconClass: "primary",
            decimals: 2,
            prefix: defaultCurrency(),
            separator: ",",
            suffix: "",
        },
        {
            id: 4,
            label: "Overall Total Withdrawal",
            labelClass: "muted",
            percentage: "+21.20 %",
            percentageClass: "muted",
            counter: dashboardOverallTotal?.bcf?.withdrawal,
            caption: "View Details",
            link: getPath(Routes.TRANSACTIONS),
            icon: "ri-wallet-line",
            iconClass: "primary",
            decimals: 2,
            prefix: defaultCurrency(),
            separator: ",",
            suffix: "",
        },
    ];

    const getData = useCallback(() => {
        dispatch(
            getDashboardAnalyticsOverallTotal({
                params: {
                    ...data,
                    summary_type: "total",
                    balance_category: "merchant",
                },
            })
        );
        dispatch(
            getDashboardAnalyticsOverallTotal({
                params: {
                    ...data,
                    summary_type: "total",
                    balance_category: "bcf",
                },
            })
        );
        if (isSuperAdmin) {
            dispatch(
                getDashboardAnalyticsOverallTotal({
                    params: {
                        ...data,
                        summary_type: "operator",
                        balance_category: "merchant",
                    },
                })
            );
            dispatch(
                getDashboardAnalyticsOverallTotal({
                    params: {
                        ...data,
                        summary_type: "operator",
                        balance_category: "bcf",
                    },
                })
            );
            dispatch(
                getDashboardAnalyticsOverallTotal({
                    params: {
                        ...data,
                        summary_type: "community-manager",
                        balance_category: "merchant",
                    },
                })
            );
            dispatch(
                getDashboardAnalyticsOverallTotal({
                    params: {
                        ...data,
                        summary_type: "community-manager",
                        balance_category: "bcf",
                    },
                })
            );
            dispatch(
                getDashboardAnalyticsOverallTotal({
                    params: {
                        ...data,
                        summary_type: "affiliate",
                        balance_category: "merchant",
                    },
                })
            );
            dispatch(
                getDashboardAnalyticsOverallTotal({
                    params: {
                        ...data,
                        summary_type: "affiliate",
                        balance_category: "bcf",
                    },
                })
            );
            dispatch(
                getDashboardAnalyticsOverallTotal({
                    params: {
                        ...data,
                        summary_type: "organic-player",
                        balance_category: "merchant",
                    },
                })
            );
            dispatch(
                getDashboardAnalyticsOverallTotal({
                    params: {
                        ...data,
                        summary_type: "organic-player",
                        balance_category: "bcf",
                    },
                })
            );
        }

        dispatch(getMonthlyTotalPlayerSummary({ params: data }));

        dispatch(
            getDailyTotalGgrSummary({
                params: {
                    date_from: data.date_from,
                    date_to: data.date_to,
                },
            })
        );

        dispatch(
            getDailyTotalDepositAndWithdrawalSummary({
                params: {
                    date_from: data.date_from,
                    date_to: data.date_to,
                },
            })
        );
    }, [data, dispatch, isSuperAdmin]);

    React.useEffect(() => {
        dispatch(getDashboardAnalyticsMainTotal());
    }, [dispatch]);

    React.useEffect(() => {
        if (filter) {
            setSelectedDate({
                ...dateFilterOptions.find((option) => option?.label === filter),
                value: [date_from, date_to],
            });
        }
    }, [date_from, date_to, filter]);

    React.useEffect(() => {
        if (selectedDate?.value && selectedDate?.value.length === 2) {
            setData((data) => ({
                ...data,
                date_from: selectedDate.value[0],
                date_to: selectedDate.value[1],
            }));
        }
    }, [selectedDate.value]);

    React.useEffect(() => {
        if (isMounted.current) {
            getData();
        } else isMounted.current = true;
    }, [dispatch, data, getData]);

    return (
        <React.Fragment>
            <Title title={getPathTitle(Routes.DASHBOARD)} />
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb
                        parent={{
                            isParent: true,
                            pageTitle: getPathTitle(Routes.DASHBOARD),
                            pageLink: "",
                        }}
                    />

                    {!isLicenseeAdmin && !isSuperAdmin && (
                        <Row>
                            {(mainTotals || []).map((item, key) => (
                                <Col xl={4} md={6} key={key}>
                                    <Card
                                        className={`card-animate tw-h-[150px] ${item.bgColor}`}
                                    >
                                        <CardBody>
                                            <div className="d-flex align-items-center">
                                                <div className="flex-grow-1">
                                                    <p
                                                        className={
                                                            "text-uppercase fw-medium mb-0 text-" +
                                                            item.labelClass
                                                        }
                                                    >
                                                        {item.label}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-end justify-content-between mt-4">
                                                <div>
                                                    <h4
                                                        className={
                                                            "fs-22 fw-semibold ff-secondary mb-4 " +
                                                            item.counterClass
                                                        }
                                                    >
                                                        {!isGetDashboardMainTotalLoading ? (
                                                            <span
                                                                className="counter-value"
                                                                data-target="559.25"
                                                            >
                                                                <CountUp
                                                                    start={0}
                                                                    prefix={
                                                                        item.prefix
                                                                    }
                                                                    suffix={
                                                                        item.suffix
                                                                    }
                                                                    separator={
                                                                        item.separator
                                                                    }
                                                                    end={
                                                                        item.counter
                                                                    }
                                                                    decimals={
                                                                        item.decimals
                                                                    }
                                                                    duration={4}
                                                                />
                                                            </span>
                                                        ) : (
                                                            <span className="tw-animate-pulse">
                                                                Loading...
                                                            </span>
                                                        )}
                                                    </h4>
                                                    {item.link && (
                                                        <Link
                                                            to={item.link}
                                                            className={
                                                                "text-decoration-underline"
                                                            }
                                                        >
                                                            {item.caption}
                                                        </Link>
                                                    )}
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    )}

                    <Row className="mb-3">
                        <Col xl={12}>
                            <div className="tw-flex tw-flex-wrap tw-items-center tw-gap-2">
                                {dateFilterOptions.map((item, index) => (
                                    <Button
                                        key={index}
                                        color="light"
                                        className={
                                            selectedDate.label === item.label
                                                ? `tw-text-[#ECB844] tw-bg-[#ECB844]/5 tw-border tw-border-[#ECB844]/50`
                                                : ""
                                        }
                                        onClick={() => {
                                            setSearchParams({
                                                filter: item.label,
                                                ...(item?.value && {
                                                    date_from: item.value[0],
                                                    date_to: item.value[1],
                                                }),
                                            });
                                        }}
                                    >
                                        {item.label}
                                    </Button>
                                ))}
                                <div className="tw-min-w-[200px]">
                                    <FlatPickerDate
                                        name="dateFilter"
                                        mode="range"
                                        placeholder="From Date - To Date"
                                        maxDate={null}
                                        onChange={(date) => {
                                            setSearchParams({
                                                filter: "Custom",
                                                date_from: date[0]
                                                    ? filterDateFormat(date[0])
                                                    : "",
                                                date_to: date[1]
                                                    ? filterDateFormat(date[1])
                                                    : "",
                                            });
                                        }}
                                        value={selectedDate.value}
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col xl={4}></Col>
                    </Row>

                    {!isLicenseeAdmin && !isSuperAdmin ? (
                        <Card className="p-3">
                            <Row className="mb-2">
                                <Col className="tw-font-bold tw-text-lg">
                                    {selectedDate.label} Overall Total
                                </Col>
                            </Row>
                            {isShowMerchantTotals && (
                                <Row className="tw-px-2">
                                    <Col>
                                        <Row className="tw-text-lg tw-pl-4 tw-text-[#B572CD] tw-font-semibold">
                                            Merchant
                                        </Row>
                                        <Row className="tw-pl-8 tw-gap-2 tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 md:tw-grid-cols-3 lg:tw-grid-cols-4">
                                            {(overallTotalMerchant || []).map(
                                                (item, key) => (
                                                    <div
                                                        key={key}
                                                        className="p-1"
                                                    >
                                                        <Card
                                                            className={
                                                                "card-animate tw-border tw-border-[#C746F5]"
                                                            }
                                                        >
                                                            <CardBody>
                                                                <div className="d-flex align-items-center">
                                                                    <div className="flex-grow-1">
                                                                        <p
                                                                            className={
                                                                                "text-uppercase fw-medium mb-0 text-" +
                                                                                item.labelClass
                                                                            }
                                                                        >
                                                                            {
                                                                                item.label
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="d-flex align-items-end justify-content-between mt-4">
                                                                    <div>
                                                                        <h4
                                                                            className={
                                                                                "fs-22 fw-semibold ff-secondary mb-4 " +
                                                                                item.counterClass
                                                                            }
                                                                        >
                                                                            {!isGetDashboardOverallTotalLoading ? (
                                                                                <span
                                                                                    className="counter-value"
                                                                                    data-target="559.25"
                                                                                >
                                                                                    <CountUp
                                                                                        start={
                                                                                            0
                                                                                        }
                                                                                        prefix={
                                                                                            item.prefix
                                                                                        }
                                                                                        suffix={
                                                                                            item.suffix
                                                                                        }
                                                                                        separator={
                                                                                            item.separator
                                                                                        }
                                                                                        end={
                                                                                            item.counter
                                                                                        }
                                                                                        decimals={
                                                                                            item.decimals
                                                                                        }
                                                                                        duration={
                                                                                            4
                                                                                        }
                                                                                    />
                                                                                </span>
                                                                            ) : (
                                                                                <span className="tw-animate-pulse">
                                                                                    Loading...
                                                                                </span>
                                                                            )}
                                                                        </h4>
                                                                        {item.link ? (
                                                                            <Link
                                                                                to={
                                                                                    item.link
                                                                                }
                                                                                className={
                                                                                    "text-decoration-underline " +
                                                                                    item.captionClass
                                                                                }
                                                                            >
                                                                                {
                                                                                    item.caption
                                                                                }
                                                                            </Link>
                                                                        ) : (
                                                                            <span>
                                                                                -
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                    <div className="avatar-sm flex-shrink-0">
                                                                        <span
                                                                            className={
                                                                                "avatar-title rounded fs-3 bg-soft-" +
                                                                                item.iconClass
                                                                            }
                                                                        >
                                                                            <i
                                                                                className={
                                                                                    item.icon +
                                                                                    " text-" +
                                                                                    item.iconClass
                                                                                }
                                                                            ></i>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </div>
                                                )
                                            )}
                                        </Row>
                                    </Col>
                                </Row>
                            )}

                            <Row className="tw-px-2">
                                <Col>
                                    <Row className="tw-text-lg tw-pl-4 tw-text-[#B2CDFF] tw-font-semibold">
                                        BCF
                                    </Row>
                                    <Row className="tw-pl-8 tw-gap-2 tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 md:tw-grid-cols-3 lg:tw-grid-cols-4">
                                        {(overallTotalBcf || []).map(
                                            (item, key) => (
                                                <div key={key} className="p-1">
                                                    <Card
                                                        className={
                                                            "card-animate tw-border tw-border-[#455D92]"
                                                        }
                                                    >
                                                        <CardBody>
                                                            <div className="d-flex align-items-center">
                                                                <div className="flex-grow-1">
                                                                    <p
                                                                        className={
                                                                            "text-uppercase fw-medium mb-0 text-" +
                                                                            item.labelClass
                                                                        }
                                                                    >
                                                                        {
                                                                            item.label
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex align-items-end justify-content-between mt-4">
                                                                <div>
                                                                    <h4
                                                                        className={
                                                                            "fs-22 fw-semibold ff-secondary mb-4 " +
                                                                            item.counterClass
                                                                        }
                                                                    >
                                                                        {!isGetDashboardOverallTotalLoading ? (
                                                                            <span
                                                                                className="counter-value"
                                                                                data-target="559.25"
                                                                            >
                                                                                <CountUp
                                                                                    start={
                                                                                        0
                                                                                    }
                                                                                    prefix={
                                                                                        item.prefix
                                                                                    }
                                                                                    suffix={
                                                                                        item.suffix
                                                                                    }
                                                                                    separator={
                                                                                        item.separator
                                                                                    }
                                                                                    end={
                                                                                        item.counter
                                                                                    }
                                                                                    decimals={
                                                                                        item.decimals
                                                                                    }
                                                                                    duration={
                                                                                        4
                                                                                    }
                                                                                />
                                                                            </span>
                                                                        ) : (
                                                                            <span className="tw-animate-pulse">
                                                                                Loading...
                                                                            </span>
                                                                        )}
                                                                    </h4>
                                                                    {item.link ? (
                                                                        <Link
                                                                            to={
                                                                                item.link
                                                                            }
                                                                            className={
                                                                                "text-decoration-underline " +
                                                                                item.captionClass
                                                                            }
                                                                        >
                                                                            {
                                                                                item.caption
                                                                            }
                                                                        </Link>
                                                                    ) : (
                                                                        <span>
                                                                            -
                                                                        </span>
                                                                    )}
                                                                </div>
                                                                <div className="avatar-sm flex-shrink-0">
                                                                    <span
                                                                        className={
                                                                            "avatar-title rounded fs-3 bg-soft-" +
                                                                            item.iconClass
                                                                        }
                                                                    >
                                                                        <i
                                                                            className={
                                                                                item.icon +
                                                                                " text-" +
                                                                                item.iconClass
                                                                            }
                                                                        ></i>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                            )
                                        )}
                                    </Row>
                                </Col>
                            </Row>
                        </Card>
                    ) : (
                        <Card className="p-3">
                            <div className="tw-font-bold tw-text-lg">
                                {selectedDate.label} Overall Total
                            </div>
                            <div className="mx-3">
                                {Object.keys(overallTotal).map((key, index) => (
                                    <Fragment key={index}>
                                        <div
                                            className={
                                                overallTotal[key]?.titleClass
                                            }
                                        >
                                            {overallTotal[key]?.title}
                                        </div>
                                        <Row className="m-1">
                                            <Col xs={12} xl={3}>
                                                <FilledCard
                                                    {...{
                                                        ...overallTotal[key]
                                                            ?.overall_total_ggr,
                                                    }}
                                                    currency={
                                                        overallTotal[key]
                                                            ?.currency
                                                    }
                                                    isLoading={
                                                        isGetDashboardOverallTotalLoading
                                                    }
                                                />
                                            </Col>
                                            <Col xs={12} xl={9}>
                                                <Row>
                                                    {Object.keys(
                                                        overallTotal[key]
                                                            ?.breakdown
                                                    ).map(
                                                        (
                                                            breakdownKey,
                                                            index
                                                        ) => (
                                                            <Col
                                                                xs={12}
                                                                sm={6}
                                                                xl={4}
                                                                key={index}
                                                            >
                                                                <CommonCard
                                                                    containerClass={`tw-px-4 tw-h-[250px] lg:tw-h-[210px] ${
                                                                        overallTotal[
                                                                            key
                                                                        ]
                                                                            ?.containerClass
                                                                    } ${
                                                                        breakdownKey ===
                                                                        "overall_total_transaction_fees"
                                                                            ? "py-3"
                                                                            : "py-4"
                                                                    }`}
                                                                    title={
                                                                        overallTotal[
                                                                            key
                                                                        ]
                                                                            ?.breakdown[
                                                                            breakdownKey
                                                                        ].label
                                                                    }
                                                                    icon={
                                                                        overallTotal[
                                                                            key
                                                                        ]
                                                                            ?.breakdown[
                                                                            breakdownKey
                                                                        ].icon
                                                                    }
                                                                >
                                                                    {breakdownKey ===
                                                                    "overall_total_transaction_fees" ? (
                                                                        <div className="mt-2 mx-1">
                                                                            <Currency
                                                                                containerClass="border-bottom"
                                                                                labelClass="tw-text-xs tw-leading-[2rem]"
                                                                                valueClass="tw-text-lg"
                                                                                title="GP Fees"
                                                                                value={
                                                                                    overallTotal[
                                                                                        key
                                                                                    ]
                                                                                        ?.breakdown[
                                                                                        breakdownKey
                                                                                    ]
                                                                                        ?.gpFees
                                                                                }
                                                                                currency={
                                                                                    overallTotal[
                                                                                        key
                                                                                    ]
                                                                                        ?.currency
                                                                                }
                                                                                isLoading={
                                                                                    isGetDashboardOverallTotalLoading
                                                                                }
                                                                            />
                                                                            <div className="tw-flex tw-flex-col lg:tw-flex-row tw-justify-between tw-mt-3">
                                                                                <Currency
                                                                                    labelClass="tw-text-xs tw-leading-[2rem]"
                                                                                    valueClass="tw-text-lg"
                                                                                    title="Deposit Fees"
                                                                                    value={
                                                                                        overallTotal[
                                                                                            key
                                                                                        ]
                                                                                            ?.breakdown[
                                                                                            breakdownKey
                                                                                        ]
                                                                                            ?.depositFees
                                                                                    }
                                                                                    currency={
                                                                                        overallTotal[
                                                                                            key
                                                                                        ]
                                                                                            ?.currency
                                                                                    }
                                                                                    isLoading={
                                                                                        isGetDashboardOverallTotalLoading
                                                                                    }
                                                                                />
                                                                                <Currency
                                                                                    labelClass="tw-text-xs tw-leading-[2rem]"
                                                                                    valueClass="tw-text-lg"
                                                                                    title="Withdrawal Fees"
                                                                                    value={
                                                                                        overallTotal[
                                                                                            key
                                                                                        ]
                                                                                            ?.breakdown[
                                                                                            breakdownKey
                                                                                        ]
                                                                                            ?.withdrawalFees
                                                                                    }
                                                                                    currency={
                                                                                        overallTotal[
                                                                                            key
                                                                                        ]
                                                                                            ?.currency
                                                                                    }
                                                                                    isLoading={
                                                                                        isGetDashboardOverallTotalLoading
                                                                                    }
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    ) : (
                                                                        <Fragment>
                                                                            <div className="mt-4 mx-1">
                                                                                <Currency
                                                                                    value={
                                                                                        overallTotal[
                                                                                            key
                                                                                        ]
                                                                                            ?.breakdown[
                                                                                            breakdownKey
                                                                                        ]
                                                                                            .value
                                                                                    }
                                                                                    currency={
                                                                                        overallTotal[
                                                                                            key
                                                                                        ]
                                                                                            ?.currency
                                                                                    }
                                                                                    isLoading={
                                                                                        isGetDashboardOverallTotalLoading
                                                                                    }
                                                                                    labelClass="tw-text-sm"
                                                                                    valueClass="tw-text-2xl tw-leading-[0.9rem]"
                                                                                />
                                                                            </div>
                                                                            <div className="tw-pt-0 md:tw-pt-11 lg:tw-pt-0">
                                                                                {!!overallTotal[
                                                                                    key
                                                                                ]
                                                                                    ?.breakdown[
                                                                                    breakdownKey
                                                                                ]
                                                                                    ?.link ? (
                                                                                    <div className="tw-text-right mt-5 tw-text-sm">
                                                                                        <Link
                                                                                            to={
                                                                                                overallTotal[
                                                                                                    key
                                                                                                ]
                                                                                                    ?.breakdown[
                                                                                                    breakdownKey
                                                                                                ]
                                                                                                    ?.link
                                                                                            }
                                                                                        >
                                                                                            <span className="text-decoration-underline text-info">
                                                                                                View
                                                                                                Details
                                                                                            </span>
                                                                                            <i className="ri-arrow-right-s-line align-middle ms-1 lh-1" />
                                                                                        </Link>
                                                                                    </div>
                                                                                ) : (
                                                                                    <div className="tw-my-11" />
                                                                                )}
                                                                            </div>
                                                                        </Fragment>
                                                                    )}
                                                                </CommonCard>
                                                            </Col>
                                                        )
                                                    )}
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Fragment>
                                ))}
                            </div>
                        </Card>
                    )}

                    <Row>
                        <Col md={6}>
                            <Card className="tw-pb-7">
                                <CardHeader className="tw-flex tw-justify-between tw-items-center py-2 my-1">
                                    <h4 className="card-title mb-0">
                                        Gross Gaming Revenue
                                    </h4>
                                    {isShowMerchantTotals && (
                                        <div className="tw-flex tw-items-center gap-2">
                                            <div>Filters:</div>
                                            {graphGgrTypeOptions.map(
                                                (item, index) => (
                                                    <Button
                                                        key={index}
                                                        size="sm"
                                                        onClick={() =>
                                                            setGraphGgrType(
                                                                item.value
                                                            )
                                                        }
                                                        color="light"
                                                        className={
                                                            graphGgrType ===
                                                            item.value
                                                                ? "tw-text-[#ECB844] tw-bg-[#ECB844]/5 tw-border tw-border-[#ECB844]/50"
                                                                : ""
                                                        }
                                                    >
                                                        {item.label}
                                                    </Button>
                                                )
                                            )}
                                        </div>
                                    )}
                                </CardHeader>
                                <CardBody>
                                    <div>
                                        <BasicLineCharts
                                            isLoading={
                                                isGetDailyTotalGgrSummaryLoading
                                            }
                                            data={dailyTotalGgrSummary}
                                            dataColors={["#8A339E", "#30426E"]}
                                            type={graphGgrType}
                                            isShowMerchantTotals={
                                                isShowMerchantTotals
                                            }
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={6}>
                            <Card>
                                <CardHeader className="tw-flex tw-flex-col py-2 my-1 tw-space-y-2">
                                    <h4 className="card-title mb-0">
                                        Deposit & Withdrawal
                                    </h4>
                                    {isShowMerchantTotals && (
                                        <div className="tw-flex tw-items-center gap-2">
                                            <div>Filters:</div>
                                            {graphWithdrawalAndDepositTypeOptions.map(
                                                (item, index) => (
                                                    <Button
                                                        size="sm"
                                                        key={index}
                                                        onClick={() =>
                                                            setGraphWithdrawalAndDepositType(
                                                                item.value
                                                            )
                                                        }
                                                        color="light"
                                                        className={
                                                            graphWithdrawalAndDepositType ===
                                                            item.value
                                                                ? "tw-text-[#ECB844] tw-bg-[#ECB844]/5 tw-border tw-border-[#ECB844]/50"
                                                                : ""
                                                        }
                                                    >
                                                        {item.label}
                                                    </Button>
                                                )
                                            )}
                                        </div>
                                    )}
                                </CardHeader>
                                <CardBody>
                                    <SplineAreaChart
                                        isLoading={
                                            isGetDailyTotalDepositAndWithdrawalSummaryLoading
                                        }
                                        data={
                                            dailyTotalDepositAndWithdrawalSummary
                                        }
                                        dataColors={[
                                            "#336696",
                                            "#0AB39C",
                                            "#BA48F0",
                                            "#F06548",
                                        ]}
                                        type={graphWithdrawalAndDepositType}
                                        isShowMerchantTotals={
                                            isShowMerchantTotals
                                        }
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={12}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title mb-0">Players</h4>
                                </CardHeader>
                                <CardBody>
                                    <BasicColumn
                                        isLoading={
                                            isGetMonthlyTotalPlayerSummaryLoading
                                        }
                                        data={monthlyTotalPlayerSummary}
                                        dataColors='["--vz-success", "--vz-primary", "--vz-warning"]'
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                        {/* <Col md={6}>
              <TableCard
                title="Player PHP Balance"
                currency="PHP"
                icon={<DashboardIcons.Peso />}
              />
            </Col>
            <Col md={6}>
              <TableCard
                title="Player mPHP Balance"
                currency="mPHP"
                icon={<DashboardIcons.MetabetsLogo />}
              />
            </Col> */}
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Card>
                                <CardHeader>
                                    <h4 className="card-title mb-0">
                                        Win/Loss
                                    </h4>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <WinLossByAgentReport
                                                filter={data}
                                                dashboard
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Dashboard;
