import { hotMatchesSettingState } from "@/types/states";
import * as types from "./actionTypes";

const initialState = hotMatchesSettingState;
const hotMatchesSetting = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_SPORT_TYPE_LIST:
    case types.GET_LEAGUE_TYPE_LIST:
    case types.GET_GAME_TYPE_LIST:
    case types.GET_HOT_MATCH_LIST:
    case types.UPDATE_SPORT_TYPE_STATUS:
    case types.UPDATE_SPORT_TYPE_USE_CHILD_FILTER:
    case types.UPDATE_LEAGUE_TYPE_STATUS:
    case types.UPDATE_GAME_TYPE_STATUS:
    case types.UPDATE_HOT_MATCH_POST_STATUS:
    case types.GET_TEAM_LIST:
      state = {
        ...state,
        isLoading: true,
      };
      break;
    case types.SET_SPORT_TYPE_LIST:
      state = {
        ...state,
        sportTypeList: action.payload,
        isLoading: false,
      };
      break;
    case types.SET_LEAGUE_TYPE_LIST:
      state = {
        ...state,
        leagueTypeList: action.payload,
        isLoading: false,
      };
      break;
    case types.SET_GAME_TYPE_LIST:
      state = {
        ...state,
        gameTypeList: action.payload,
        isLoading: false,
      };
      break;
    case types.SET_TEAM_LIST:
      state = {
        ...state,
        teamList: action.payload,
        isLoading: false,
      };
      break;
    case types.SET_HOT_MATCH_LIST:
      state = {
        ...state,
        hotMatchList: action.payload,
        isLoading: false,
      };
      break;
    case types.UPDATE_SPORT_TYPE_STATUS_SUCCESS:
    case types.UPDATE_SPORT_TYPE_USE_CHILD_FILTER_SUCCESS:
    case types.UPDATE_LEAGUE_TYPE_STATUS_SUCCESS:
    case types.UPDATE_HOT_MATCH_POST_STATUS_SUCCESS:
    case types.UPDATE_GAME_TYPE_STATUS_ERROR:
    case types.SET_SPORT_TYPE_LIST_ERROR:
    case types.UPDATE_SPORT_TYPE_STATUS_ERROR:
    case types.UPDATE_SPORT_TYPE_USE_CHILD_FILTER_ERROR:
    case types.SET_LEAGUE_TYPE_LIST_ERROR:
    case types.UPDATE_LEAGUE_TYPE_STATUS_ERROR:
    case types.SET_GAME_TYPE_LIST_ERROR:
    case types.SET_HOT_MATCH_LIST_ERROR:
    case types.UPDATE_HOT_MATCH_POST_STATUS_ERROR:
    case types.SET_TEAM_LIST_ERROR:
      state = {
        ...state,
        isLoading: false,
      };
      break;

    case types.UPLOAD_TEAM_PHOTO:
      state = {
        ...state,
        isUpdateFeaturedGameSuccess: false,
        isLoadingUploadTeamPhoto: true,
      };
      break;

    case types.UPLOAD_TEAM_PHOTO_SUCCESS:
      state = {
        ...state,
        isUpdateFeaturedGameSuccess: true,
        isLoading: false,
        isLoadingUploadTeamPhoto: false,
      };
      break;
    case types.UPLOAD_TEAM_PHOTO_ERROR:
      state = {
        ...state,
        isUpdateFeaturedGameSuccess: true,
        isLoading: false,
        isLoadingUploadTeamPhoto: false,
      };
      break;
    case types.HOT_MATCH_MANUAL_PULL:
      state = {
        ...state,
        isManualPullLoading: true,
      };
      break;
    case types.HOT_MATCH_MANUAL_PULL_SUCCESS:
    case types.HOT_MATCH_MANUAL_PULL_ERROR:
      state = {
        ...state,
        isManualPullLoading: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default hotMatchesSetting;
