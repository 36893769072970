import * as types from "./actionTypes";
import { transactionState } from "@/types/states";

const initialState = transactionState;

const transaction = (state = initialState, action) => {
  switch (action.type) {
    case types.USER_DEPOSIT:
      state = {
        ...state,
        isUserDepositSuccess: false,
        isLoading: true,
      };
      break;
    case types.USER_WITHDRAWAL_REQUEST:
      state = {
        ...state,
        isUserWithdrawalRequestSuccess: false,
        isLoading: true,
      };
      break;
    case types.GET_TRANSACTION_DATA:
    case types.GET_TRANSACTION_INFO:
    case types.USER_DEPOSIT_REQUEST:
    case types.GET_PAYMENT_METHOD_DATA:
    case types.ADD_FUND:
    case types.GET_TIP_TRANSACTION:
      state = {
        ...state,
        isLoading: true,
      };
      break;
    case types.SET_TRANSACTION_DATA:
    case types.SET_TIP_TRANSACTION:
      state = {
        ...state,
        list: action.payload,
        isLoading: false,
      };
      break;
    case types.SET_TRANSACTION_DATA_ERROR:
    case types.SET_TRANSACTION_INFO_ERROR:
    case types.SET_TIP_TRANSACTION_ERROR:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    case types.USER_DEPOSIT_SUCCESS:
      state = {
        ...state,
        errors: transactionState.errors,
        isUserDepositSuccess: true,
        isLoading: false,
      };
      break;
    case types.USER_WITHDRAWAL_REQUEST_SUCCESS:
      state = {
        ...state,
        errors: transactionState.errors,
        isUserWithdrawalRequestSuccess: true,
        isLoading: false,
      };
      break;
    case types.USER_DEPOSIT_REQUEST_SUCCESS:
    case types.ADD_FUND_SUCCESS:
      state = {
        ...state,
        errors: transactionState.errors,
        isLoading: false,
      };
      break;
    case types.USER_DEPOSIT_ERROR:
    case types.USER_DEPOSIT_REQUEST_ERROR:
    case types.USER_WITHDRAWAL_REQUEST_ERROR:
    case types.ADD_FUND_ERROR:
      state = {
        ...state,
        errors: action.payload,
        isLoading: false,
      };
      break;
    case types.SET_PAYMENT_METHOD_DATA:
      state = {
        ...state,
        errors: transactionState.errors,
        paymentMethod: action.payload,
        isLoading: false,
      };
      break;
    case types.SET_PAYMENT_METHOD_DATA_ERROR:
      state = {
        ...state,
        errors: action.payload,
        isLoading: false,
      };
      break;
    case types.RESET_TRANSACTION_ERRORS:
      state = {
        ...state,
        errors: transactionState.errors,
      };
      break;
    case types.SET_TRANSACTION_INFO:
      state = {
        ...state,
        selected: action.payload,
        isLoading: false,
      };
      break;
    case types.PROCESS_COMMISSION:
      state = {
        ...state,
        isProcessCommissionLoading: true,
      };
      break;
    case types.PROCESS_COMMISSION_SUCCESS:
    case types.PROCESS_COMMISSION_FAILED:
      state = {
        ...state,
        isProcessCommissionLoading: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default transaction;
