export const GET_PAYMENT_METHODS = "GET_PAYMENT_METHODS";
export const SET_PAYMENT_METHODS_DATA = "SET_PAYMENT_METHODS_DATA";
export const SET_PAYMENT_METHODS_DATA_ERROR = "SET_PAYMENT_METHODS_DATA_ERROR";

export const GET_ALLOWED_IN_WITHDRAWAL_PAYMENT_PROVIDERS = "GET_ALLOWED_IN_WITHDRAWAL_PAYMENT_PROVIDERS";
export const SET_ALLOWED_IN_WITHDRAWAL_PAYMENT_PROVIDERS_DATA = "SET_ALLOWED_IN_WITHDRAWAL_PAYMENT_PROVIDERS_DATA";
export const SET_ALLOWED_IN_WITHDRAWAL_PAYMENT_PROVIDERS_DATA_ERROR = "SET_ALLOWED_IN_WITHDRAWAL_PAYMENT_PROVIDERS_DATA_ERROR";

export const GET_PAYMENT_PROVIDERS = "GET_PAYMENT_PROVIDERS";
export const SET_PAYMENT_PROVIDERS_DATA = "SET_PAYMENT_PROVIDERS_DATA";
export const SET_PAYMENT_PROVIDERS_DATA_ERROR = "SET_PAYMENT_PROVIDERS_DATA_ERROR";

export const GET_PAYMENT_METHOD_PER_PROVIDER = "GET_PAYMENT_METHOD_PER_PROVIDER";
export const SET_PAYMENT_METHOD_PER_PROVIDER_DATA = "SET_PAYMENT_METHOD_PER_PROVIDER_DATA";
export const SET_PAYMENT_METHOD_PER_PROVIDER_DATA_ERROR = "SET_PAYMENT_METHOD_PER_PROVIDER_DATA_ERROR";

export const UPDATE_PAYMENT_METHOD = "UPDATE_PAYMENT_METHOD"; 
export const UPDATE_PAYMENT_METHOD_SUCCESS = "UPDATE_PAYMENT_METHOD_SUCCESS"; 
export const UPDATE_PAYMENT_METHOD_ERROR = "UPDATE_PAYMENT_METHOD_ERROR"; 

export const UPDATE_PAYMENT_METHOD_CONFIG = "UPDATE_PAYMENT_METHOD_CONFIG"; 
export const UPDATE_PAYMENT_METHOD_CONFIG_SUCCESS = "UPDATE_PAYMENT_METHOD_CONFIG_SUCCESS"; 
export const UPDATE_PAYMENT_METHOD_CONFIG_ERROR = "UPDATE_PAYMENT_METHOD_CONFIG_ERROR"; 

export const UPDATE_PAYMENT_PROVIDER = "UPDATE_PAYMENT_PROVIDER"; 
export const UPDATE_PAYMENT_PROVIDER_SUCCESS = "UPDATE_PAYMENT_PROVIDER_SUCCESS"; 
export const UPDATE_PAYMENT_PROVIDER_ERROR = "UPDATE_PAYMENT_PROVIDER_ERROR"; 

export const RESET_ERRORS = "RESET_ERRORS";