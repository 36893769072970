import { Title, Table } from "@/components/atoms";
import BreadCrumb from "@/components/common/BreadCrumb";
import { AddHotMatchSettingsTeamPhoto } from "@/components/molecules";
import { Routes, getPath, getPathTitle } from "@/constants";
import { getTeamList } from "@/store/actions";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Card, CardBody, Button, Col, Container, Row } from "reactstrap";

const TeamList = () => {
  const { teamList, isLoading } = useSelector(
    (state) => state.hotMatchesSetting
  );
  const dispatch = useDispatch();
  const [addTeamBannerlIsOpen, setAddTeamBannerlIsOpen] = useState(false);
  const [SelectedTeam, setSelectedTeam] = useState({});

  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState({
    field: "",
    type: "asc",
  });
  const [data, setData] = useState({
    per_page: 10,
    page: 1,
    search: "",
  });

  const tableTheadData = [
    {
      label: "Name",
      field: "name",
      sortable: true,
    },
    {
      label: "Action",
      field: "action",
      sortable: false,
      render: (item) => (
        <Button
          onClick={() => {
            setAddTeamBannerlIsOpen(true);
            setSelectedTeam(item);
          }}
          color="primary"
          size="sm"
        >
          <i className="ri-play-list-add-line"></i>
        </Button>
      ),
    },
  ];

  const initialize = () => {
    dispatch(getTeamList(data));
  };

  useEffect(() => {
    dispatch(getTeamList(data));
  }, [dispatch, data]);
  return (
    <React.Fragment>
      <Title title={getPathTitle(Routes.HOT_MATCHES_SETTINGS_TEAM)} />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            parent={{
              isParent: true,
              pageTitle: getPathTitle(Routes.HOT_MATCHES_SETTINGS_TEAM),
              pageLink: getPath(Routes.HOT_MATCHES_SETTINGS_TEAM),
            }}
          />
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <Table
                    search={search}
                    handlerSearch={setSearch}
                    sortBy={sortBy}
                    handlerSortBy={setSortBy}
                    thead={tableTheadData}
                    handlerChangeData={setData}
                    data={data}
                    collection={teamList}
                    isLoading={isLoading}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {addTeamBannerlIsOpen && (
          <AddHotMatchSettingsTeamPhoto
            initialize={initialize}
            isOpen={addTeamBannerlIsOpen}
            setIsOpen={setAddTeamBannerlIsOpen}
            data={SelectedTeam}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default TeamList;
