import React from "react";
import { Link } from "react-router-dom";

const TextLink = ({ text, path, className, hasIcon = true }) => {
  return (
    <Link to={path} className={`text-decoration-underline ${className}`}>
      {text} {hasIcon && <span className="ri-links-line" />}
    </Link>
  );
};

export default TextLink;
