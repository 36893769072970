import {
    AmountFormat,
    FlatPickerDate,
    InputReactSelect,
    Select,
    Table,
    TextLink,
    Title,
} from "@/components/atoms";
import BreadCrumb from "@/components/common/BreadCrumb";
import {
    Routes,
    balanceCategoryOptions,
    getPath,
    getPathTitle,
    transactionTypeOptions,
} from "@/constants";
import { useUserTypeOptions } from "@/hooks";
import { getTransactionData } from "@/store/actions";
import {
    arrayToString,
    capitalizeFirstLetter,
    defaultDateTimeFormat,
} from "@/utils";
import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row,
} from "reactstrap";

const TransactionList = () => {
    const dateFilterRef = useRef();
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.user);
    const { list, isLoading } = useSelector((state) => state.transaction);

    const { data: filteredUserTypeOptions } = useUserTypeOptions();

    const isOperator = user.role.slug === "operator";
    const isAdmin =
        user.role.slug === "admin" || user.role.slug === "super-admin";

    const [userTypeSelected, setUserTypeSelected] = useState(null);
    const [search, setSearch] = useState("");
    const [sortBy, setSortBy] = useState({
        field: "",
        type: "asc",
    });

    const transactionTypeIds = "2|3|4";

    const defaultParams = {
        account_no: "",
        per_page: 10,
        page: 1,
        search: "",
        sort_by:
            sortBy.field && sortBy.type ? `${sortBy.field} ${sortBy.type}` : "",
        "filter_by[transaction_type_id]": transactionTypeIds,
        "filter_by[balance_categories.key_name]": null,
        "date_range[transactions.created_at][from]": null,
        "date_range[transactions.created_at][to]": null,
    };

    const [data, setData] = useState(defaultParams);

    const form = useFormik({
        enableReinitialize: true,
        initialValues: {
            userType: "",
            userFilter: [],
            type: [],
            dateFilter: [],
            balanceCategory: "",
        },
        onSubmit: (values) => {
            setData({
                ...data,
                page: 1,
                user_type: values?.userType["value"] ?? null,
                "filter_by[transaction_type_id]":
                    values.type.length > 0
                        ? arrayToString(
                              values.type.map((item) => {
                                  return item.id;
                              })
                          )
                        : transactionTypeIds,
                "date_range[transactions.created_at][from]": values.dateFilter
                    ? values.dateFilter[0]
                    : data["date_range[transactions.created_at][from]"],
                "date_range[transactions.created_at][to]": values.dateFilter
                    ? values.dateFilter[1]
                    : data["date_range[transactions.created_at][to]"],
                "filter_by[balance_categories.key_name]":
                    values.balanceCategory,
            });
        },
    });

    const changeUserType = (option) => {
        form.setFieldValue("userType", option);
    };

    const handleClear = () => {
        setData(defaultParams);
        dateFilterRef.current.flatpickr.clear();
        form.resetForm();
    };

    const tableTheadData = [
        {
            label: "Transaction No.",
            field: "transaction_no",
            sortable: true,
            render: (item) => (
                <TextLink
                    text={item.transaction_no}
                    path={getPath(Routes.TRANSACTION_VIEW).replace(
                        ":transactionNo",
                        item?.transaction_no
                    )}
                />
            ),
        },
        {
            label: "Account No.",
            field: "account_no",
            sortable: true,
        },
        {
            label: "Balance Category",
            field: "balance_category",
            sortable: false,
            render: (item) => capitalizeFirstLetter(item.balance_category),
        },
        {
            label: "Type",
            field: "type",
            sortable: false,
        },
        {
            label: "Amount",
            field: "amount",
            sortable: false,
            render: (item) => <AmountFormat amount={item?.amount} />,
        },
        {
            label: "Balance Before",
            field: "balance_before",
            sortable: false,
            render: (item) => <AmountFormat amount={item?.balance_before} />,
        },
        {
            label: "Balance After",
            field: "balance_after",
            sortable: false,
            render: (item) => <AmountFormat amount={item?.balance_after} />,
        },
        {
            label: "Date",
            field: "created_at",
            sortable: true,
            render: (item) => defaultDateTimeFormat(item.created_at),
        },
        {
            label: "Action",
            field: "action",
            sortable: false,
            render: (item) => (
                <Link
                    to={getPath(Routes.TRANSACTION_VIEW).replace(
                        ":transactionNo",
                        item?.transaction_no
                    )}
                    className="link-primary"
                >
                    <Button color="success" size="sm">
                        <i className="ri-eye-line"></i>
                    </Button>
                </Link>
            ),
        },
    ];

    useEffect(() => {
        dispatch(getTransactionData(data));
    }, [dispatch, data]);

    return (
        <React.Fragment>
            <Title title={getPathTitle(Routes.TRANSACTIONS)} />
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb
                        parent={{
                            isParent: true,
                            pageTitle: getPathTitle(Routes.TRANSACTIONS),
                            pageLink: getPath(Routes.TRANSACTIONS),
                        }}
                    />
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardHeader className="align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">
                                        {getPathTitle(Routes.TRANSACTIONS)}
                                    </h4>
                                </CardHeader>
                                <CardBody>
                                    <Row className="mb-3">
                                        {(isOperator || isAdmin) && (
                                            <Col sm={12} md={3}>
                                                <InputReactSelect
                                                    label="User Type"
                                                    name="user_type"
                                                    value={form.values.userType}
                                                    isClearable={false}
                                                    options={
                                                        filteredUserTypeOptions
                                                    }
                                                    onChange={(option) => {
                                                        changeUserType(option);
                                                    }}
                                                />
                                            </Col>
                                        )}
                                        <Col
                                            sm={12}
                                            md={isOperator || isAdmin ? 3 : 4}
                                        >
                                            <InputReactSelect
                                                label="Transaction Type"
                                                name="type"
                                                isClearable={true}
                                                options={transactionTypeOptions}
                                                value={form.values.type}
                                                onChange={(option) => {
                                                    form.setFieldValue(
                                                        "type",
                                                        option
                                                    );
                                                }}
                                                isMulti={true}
                                            />
                                        </Col>
                                        <Col
                                            sm={12}
                                            md={isOperator || isAdmin ? 3 : 4}
                                        >
                                            <FlatPickerDate
                                                label="Date"
                                                name="dateFilter"
                                                form={form}
                                                ref={dateFilterRef}
                                                mode="range"
                                                value={form.values.dateFilter}
                                            />
                                        </Col>
                                        <Col
                                            sm={12}
                                            md={isOperator || isAdmin ? 3 : 4}
                                        >
                                            <Select
                                                name="balanceCategory"
                                                label="Balance Category"
                                                value={
                                                    form.values.balanceCategory
                                                }
                                                options={balanceCategoryOptions(
                                                    "all"
                                                )}
                                                onChange={(e) => {
                                                    form.setFieldValue(
                                                        "balanceCategory",
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} className="text-center">
                                            <Button
                                                color="primary"
                                                className="mx-1"
                                                onClick={() => handleClear()}
                                            >
                                                Clear
                                            </Button>
                                            <Button
                                                color="success"
                                                className="mx-1"
                                                onClick={() =>
                                                    form.handleSubmit()
                                                }
                                            >
                                                Filter
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <Table
                                        search={search}
                                        handlerSearch={setSearch}
                                        sortBy={sortBy}
                                        handlerSortBy={setSortBy}
                                        thead={tableTheadData}
                                        handlerChangeData={setData}
                                        data={data}
                                        collection={list}
                                        isLoading={isLoading}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default TransactionList;
