import { axiosInstance } from "./index";

export const agentList = async (data) => {
    if (data.user_type === "affiliate-request") {
        return await axiosInstance.get(`/bo/affiliate-request/get-paginated`, {
            params: data,
        });
    }
    return await axiosInstance.get(`/bo/user/get-paginated/${data.key_token}`, {
        params: data,
    });
};

export const agentProfile = async (accountNo) => {
    return await axiosInstance.get(`bo/user/get-profile/${accountNo}`);
};

export const updateAgent = async (data, keyToken) => {
    return await axiosInstance.put(`bo/user/update-profile/${keyToken}`, data);
};

export const createAgent = async (data) => {
    return await axiosInstance.post(`/bo/user/create`, data);
};

export const updateAgentCommissionRate = async (data, keyToken) => {
    return await axiosInstance.put(
        `bo/user/update-commission-rate/${keyToken}`,
        data
    );
};

export const updateAgentRoleTag = async (data, keyToken) => {
    return await axiosInstance.put(
        `/bo/user/update-role-tag/${keyToken}`,
        data
    );
};

export const enableValidTurnoverCommission = async (
    data,
    balanceCategory,
    keyToken
) => {
    return await axiosInstance.put(
        `/bo/user-profile-setting/enable-vto-commission/${balanceCategory}/${keyToken}`,
        data
    );
};

export const updateUserProfileSettingWithBalanceCategory = async (
    setting,
    data,
    balanceCategory,
    keyToken
) => {
    return await axiosInstance.put(
        `/bo/user-profile-setting/${setting}/${balanceCategory}/${keyToken}`,
        data
    );
};

export const updateUserProfileSetting = async (setting, data, keyToken) => {
    return await axiosInstance.put(
        `/bo/user-profile-setting/${setting}/${keyToken}`,
        data
    );
};

export const allowNegativeCarryOverCommission = async (
    data,
    balanceCategory,
    keyToken
) => {
    return await axiosInstance.put(
        `/bo/user-profile-setting/update-allow-negative-carry-over-commission/${balanceCategory}/${keyToken}`,
        data
    );
};

export const updatePendingCommissionRequiredToResetNegativeCommission = async (
    data,
    balanceCategory,
    keyToken
) => {
    return await axiosInstance.put(
        `/bo/user-profile-setting/update-pending-commission-required-to-reset-negative-commission/${balanceCategory}/${keyToken}`,
        data
    );
};

export const updateNoOfCutoffToResetNegativeCommission = async (
    data,
    balanceCategory,
    keyToken
) => {
    return await axiosInstance.put(
        `/bo/user-profile-setting/update-no-of-cutoff-to-reset-negative-commission/${balanceCategory}/${keyToken}`,
        data
    );
};

export const updateAffiliateRequestStatus = async (data, keyToken) => {
    if (data.status === "reject") {
        return await axiosInstance.put(
            `/bo/affiliate-request/reject/${keyToken}`,
            data
        );
    }
    return await axiosInstance.put(
        `/bo/affiliate-request/approve/${keyToken}`,
        data
    );
};
