import { hideLoading, showLoading } from "@/components/constants/common";
import {
    errorToast,
    loadingToast,
    removeToast,
    successToast,
} from "@/components/constants/toast";
import { getPath, Routes } from "@/constants";
import * as API from "@/network/apis/admin";
import { convert2dErrorsArray } from "@/utils";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import * as ACTIONS from "./actions";
import * as TYPES from "./actionTypes";

function* adminList({ payload }) {
    try {
        const response = yield call(API.adminList, payload);

        if (response.status === 200) {
            yield put(ACTIONS.setAdminList(response.data));
        }
    } catch (error) {
        console.warn(error.response);
        yield put(ACTIONS.setAdminListError());
    }
}

function* roleList({ payload, setRoleOptions = null }) {
    try {
        const response = yield call(API.roleList, payload);

        if (response.status === 200) {
            const roles = response.data.response;
            if (setRoleOptions !== null) {
                const data = roles.data.map(({ key_token, name }) => ({
                    value: key_token,
                    label: name,
                }));
                setRoleOptions(data);
            }

            yield put(ACTIONS.setRoleList(roles));
        }
    } catch (error) {
        console.warn(error.response);
        yield put(ACTIONS.setRoleListError());
    }
}

function* getRoleInfo({ keyToken, setPermissions }) {
    try {
        showLoading("Getting Data");
        const response = yield call(API.roleInfo, keyToken);

        if (response.status === 200) {
            let collection = [];
            let data = response.data.response;
            data.modules.map((item) =>
                collection.push({
                    key_token: item.key_token,
                    permissions: {
                        view: item.permissions.can_view,
                        create: item.permissions.can_create,
                        update: item.permissions.can_update,
                        update_status: item.permissions.can_update_status,
                        delete: item.permissions.can_delete,
                    },
                })
            );
            let values = {
                info: {
                    name: data.name,
                    keyToken: data.key_token,
                },
                data: data.modules,
            };
            setPermissions(collection);
            yield put(ACTIONS.setRoleInfo(values));
            hideLoading();
        }
    } catch (error) {
        console.warn(error.response);
        yield put(ACTIONS.setRoleInfoError());
        hideLoading();
    }
}

function* directWithdrawal({ payload, initialize }) {
    try {
        const response = yield call(API.directWithdrawal, payload);

        if (response.status === 200) {
            successToast("Successfully Requested.");
            yield put(ACTIONS.directWithdrawalSuccess());
            initialize();
        }
    } catch (error) {
        let errors = !Array.isArray(error?.response?.data?.response)
            ? convert2dErrorsArray(error?.response?.data?.response)
            : [error.response.data.message];
        yield put(ACTIONS.directWithdrawalErrors(errors));
    }
}

function* getDirectWithdrawals({ payload }) {
    try {
        const response = yield call(API.getDirectWithdrawals, payload);

        if (response.status === 200) {
            yield put(
                ACTIONS.getDirectWithdrawalsSuccess(response.data.response)
            );
        }
    } catch (error) {
        yield put(ACTIONS.getDirectWithdrawalsError());
    }
}

function* adminProfile({ payload }) {
    try {
        const response = yield call(API.adminProfile, payload);

        if (response.status === 200) {
            yield put(ACTIONS.setAdminProfile(response.data.response));
        }
    } catch (error) {
        let errors = !Array.isArray(error?.response?.data?.response)
            ? convert2dErrorsArray(error?.response?.data?.response)
            : [error.response.data.message];
        yield put(ACTIONS.setAdminProfileError(errors));
    }
}

function* createAdmin({ payload, validation }) {
    try {
        const response = yield call(API.createAdmin, payload);

        if (response.status === 200) {
            yield put(ACTIONS.createAdminSuccess(response.data));
            validation.resetForm();
            successToast("Successfully Added.");
        }
    } catch (error) {
        document.documentElement.scrollTop = 0;
        errorToast(error.response.data.message);
        let errors = !Array.isArray(error?.response?.data?.response)
            ? convert2dErrorsArray(error?.response?.data?.response)
            : [error.response.data.message];
        yield put(ACTIONS.createAdminError(errors));
    }
}

function* updateAdmin({ payload, keyToken }) {
    try {
        const response = yield call(API.updateAdmin, payload, keyToken);

        if (response.status === 200) {
            yield put(ACTIONS.updateAdminSuccess(response.data.response));
            successToast("Successfully Updated.");
        }
    } catch (error) {
        document.documentElement.scrollTop = 0;
        let errors = !Array.isArray(error?.response?.data?.response)
            ? convert2dErrorsArray(error?.response?.data?.response)
            : [error.response.data.message];
        yield put(ACTIONS.updateAdminError(errors));
    }
}

function* getPermissionData({ setPermissions }) {
    try {
        const response = yield call(API.getPermissionData);

        if (response.status === 200) {
            let collection = [];
            let data = response.data.response;
            data.map((item) =>
                collection.push({
                    key_token: item.key_token,
                    permissions: {
                        view: false,
                        create: false,
                        update: false,
                        update_status: false,
                        delete: false,
                    },
                })
            );
            setPermissions(collection);
            yield put(ACTIONS.setPermissionData(data));
        }
    } catch (error) {
        let errors = !Array.isArray(error?.response?.data?.response)
            ? convert2dErrorsArray(error?.response?.data?.response)
            : [error.response.data.message];
        yield put(ACTIONS.setPermissionDataError(errors));
    }
}

function* createRole({ payload, validation, navigate }) {
    try {
        const response = yield call(API.createRole, payload);

        if (response.status === 200) {
            yield put(ACTIONS.createRoleSuccess(response.data));
            validation.resetForm();
            navigate(getPath(Routes.MANAGE_ROLE));
            successToast("Successfully Added.");
        }
    } catch (error) {
        document.documentElement.scrollTop = 0;
        let errors = !Array.isArray(error?.response?.data?.response)
            ? convert2dErrorsArray(error?.response?.data?.response)
            : [error.response.data.message];
        yield put(ACTIONS.createRoleError(errors));
    }
}

function* updateRoleName({ payload, keyToken }) {
    try {
        showLoading();
        const response = yield call(API.updateRoleName, payload, keyToken);

        if (response.status === 200) {
            yield put(ACTIONS.updateRoleNameSuccess(response.data));
            hideLoading();
            successToast("Successfully Updated.");
        }
    } catch (error) {
        document.documentElement.scrollTop = 0;
        let errors = !Array.isArray(error?.response?.data?.response)
            ? convert2dErrorsArray(error?.response?.data?.response)
            : [error.response.data.message];
        yield put(ACTIONS.updateRoleNameError(errors));
        hideLoading();
    }
}

function* updateRolePermission({ payload }) {
    try {
        showLoading();
        const response = yield call(API.updateRolePermission, payload);

        if (response.status === 200) {
            yield put(ACTIONS.updateRolePermissionSuccess(response.data));
            hideLoading();
            successToast("Successfully Updated.");
        }
    } catch (error) {
        document.documentElement.scrollTop = 0;
        let errors = !Array.isArray(error?.response?.data?.response)
            ? convert2dErrorsArray(error?.response?.data?.response)
            : [error.response.data.message];
        yield put(ACTIONS.updateRolePermissionError(errors));
        hideLoading();
    }
}

function* updateAdminRole({ payload, keyToken }) {
    try {
        loadingToast("Saving...");
        const response = yield call(API.updateAdminRole, payload, keyToken);

        if (response.status === 200) {
            yield put(ACTIONS.updateAdminRoleSuccess(response.data));
            removeToast();
            successToast("Role Successfully Changed.");
        }
    } catch (error) {
        document.documentElement.scrollTop = 0;
        let errors = !Array.isArray(error?.response?.data?.response)
            ? convert2dErrorsArray(error?.response?.data?.response)
            : [error.response.data.message];
        yield put(ACTIONS.updateAdminRoleError(errors));
        removeToast();
    }
}

function* watchCreateAdmin() {
    yield takeLatest(TYPES.CREATE_ADMIN, createAdmin);
}
function* watchCreateRole() {
    yield takeLatest(TYPES.CREATE_ROLE, createRole);
}
function* watchUpdateRoleName() {
    yield takeLatest(TYPES.UPDATE_ROLE_NAME, updateRoleName);
}
function* watchUpdateRolePermission() {
    yield takeLatest(TYPES.UPDATE_ROLE_PERMISSION, updateRolePermission);
}
function* watchUpdateAdmin() {
    yield takeLatest(TYPES.UPDATE_ADMIN, updateAdmin);
}
function* watchRoleList() {
    yield takeLatest(TYPES.GET_ROLE_LIST, roleList);
}
function* watchGetRoleInfo() {
    yield takeLatest(TYPES.GET_ROLE_INFO, getRoleInfo);
}
function* watchAdminList() {
    yield takeLatest(TYPES.GET_ADMIN_LIST, adminList);
}
function* watchAdminProfile() {
    yield takeLatest(TYPES.GET_ADMIN_PROFILE, adminProfile);
}
function* watchGetPermissionData() {
    yield takeLatest(TYPES.GET_PERMISSION_DATA, getPermissionData);
}
function* watchUpdateAdminRole() {
    yield takeLatest(TYPES.UPDATE_ADMIN_ROLE, updateAdminRole);
}
function* watchDirectWithdrawal() {
    yield takeLatest(TYPES.DIRECT_WITHDRAWAL, directWithdrawal);
}
function* watchGetdirectWithdrawals() {
    yield takeLatest(TYPES.GET_DIRECT_WITHDRAWALS, getDirectWithdrawals);
}

function* adminSagas() {
    yield all([
        fork(watchCreateRole),
        fork(watchCreateAdmin),
        fork(watchUpdateAdmin),
        fork(watchUpdateRoleName),
        fork(watchUpdateRolePermission),
        fork(watchAdminList),
        fork(watchRoleList),
        fork(watchGetRoleInfo),
        fork(watchAdminProfile),
        fork(watchGetPermissionData),
        fork(watchUpdateAdminRole),
        fork(watchDirectWithdrawal),
        fork(watchGetdirectWithdrawals),
    ]);
}

export default adminSagas;
