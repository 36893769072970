import { gameState } from "@/types/states";
import * as types from "./actionTypes";

const initialState = gameState;

const game = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_CASINO_GAME_PROVIDER:
        case types.GET_CASINO_GAME_LIST:
        case types.GET_CASINO_GAME_TYPE:
        case types.SYNC_CASINO_GAME_PROVIDER:
        case types.UPDATE_GAME_STATUS:
            state = {
                ...state,
                isLoading: true,
            };
            break;
        case types.SET_CASINO_GAME_LIST:
            state = {
                ...state,
                gameList: action.payload,
                isLoading: false,
            };
            break;
        case types.SET_CASINO_GAME_PROVIDER:
            state = {
                ...state,
                gameProviderList: action.payload,
                isGameProviderListLoaded: true,
                isLoading: false,
            };
            break;
        case types.SET_CASINO_GAME_TYPE:
            state = {
                ...state,
                gameTypeList: action.payload,
                isGameTypeListLoaded: true,
                isLoading: false,
            };
            break;
        case types.SYNC_CASINO_GAME_PROVIDER_SUCCESS:
        case types.UPDATE_GAME_STATUS_SUCCESS:
            state = {
                ...state,
                isLoading: false,
                errors: gameState.errors,
            };
            break;
        case types.SET_CASINO_GAME_LIST_ERROR:
        case types.SET_CASINO_GAME_PROVIDER_ERROR:
        case types.SET_CASINO_GAME_TYPE_ERROR:
        case types.SYNC_CASINO_GAME_PROVIDER_ERROR:
        case types.UPDATE_GAME_STATUS_ERROR:
            state = {
                ...state,
                isLoading: false,
            };
            break;
        case types.GET_FEATURED_GAME_TYPES:
            state = {
                ...state,
                isLoading: true,
            };
            break;
        case types.GET_FEATURED_GAME_TYPES_SUCCESS:
            state = {
                ...state,
                isLoading: false,
                featuredGameTypes: action.payload,
            };
            break;
        case types.GET_FEATURED_GAME_TYPES_FAILED:
            state = {
                ...state,
                isLoading: false,
            };
            break;
        case types.ADD_FEATURED_GAME:
            state = {
                ...state,
                isAddFeaturedGameLoading: true,
                isAddFeaturedGameSuccess: false,
            };
            break;
        case types.UPDATE_FEATURED_GAME:
            state = {
                ...state,
                isUpdateFeaturedGameLoading: true,
                isUpdateFeaturedGameSuccess: false,
            };
            break;
        case types.UPDATE_FEATURED_GAME_SUCCESS:
            state = {
                ...state,
                isUpdateFeaturedGameLoading: false,
                isUpdateFeaturedGameSuccess: true,
            };
            break;
        case types.ADD_FEATURED_GAME_SUCCESS:
            state = {
                ...state,
                isAddFeaturedGameLoading: false,
                isAddFeaturedGameSuccess: true,
            };
            break;
        case types.UPDATE_FEATURED_GAME_FAILED:
            state = {
                ...state,
                isUpdateFeaturedGameLoading: false,
                errors: action.payload,
            };
            break;
        case types.ADD_FEATURED_GAME_FAILED:
            state = {
                ...state,
                isAddFeaturedGameLoading: false,
                errors: action.payload,
            };
            break;
        case types.RESET_FEATURED_GAME_ERROR:
            state = {
                ...state,
                errors: [],
            };
            break;
        case types.DELETE_FEATURED_GAME:
            state = {
                ...state,
                isDeleteFeaturedGameLoading: true,
                isDeleteFeaturedGameSuccess: false,
            };
            break;
        case types.DELETE_FEATURED_GAME_SUCCESS:
            state = {
                ...state,
                isDeleteFeaturedGameLoading: false,
                isDeleteFeaturedGameSuccess: true,
            };
            break;
        case types.DELETE_FEATURED_GAME_FAILED:
            state = {
                ...state,
                isDeleteFeaturedGameLoading: false,
            };
            break;
        case types.UPDATE_FEATURED_GAME_ORDER:
            state = {
                ...state,
                isUpdateFeaturedGameOrderLoading: true,
                isUpdateFeaturedGameOrderSuccess: false,
            };
            break;
        case types.UPDATE_FEATURED_GAME_ORDER_SUCCESS:
            state = {
                ...state,
                isUpdateFeaturedGameOrderLoading: false,
                isUpdateFeaturedGameOrderSuccess: true,
            };
            break;
        case types.UPDATE_FEATURED_GAME_ORDER_FAILED:
            state = {
                ...state,
                isUpdateFeaturedGameOrderLoading: false,
            };
            break;
        case types.GET_GAME_OFFERINGS:
            state = {
                ...state,
                isGetGameOfferringsLoading: true,
            };
            break;
        case types.GET_GAME_OFFERINGS_SUCCESS:
            state = {
                ...state,
                gameOfferings: action.payload,
                isGetGameOfferingsLoading: false,
            };
            break;
        case types.GET_GAME_OFFERINGS_FAILED:
            state = {
                ...state,
                isGetGameOfferingsLoading: false,
            };
            break;
        case types.UPDATE_GAME_PROVIDER_ORDER:
            state = {
                ...state,
                isSubmit: true,
            };
            break;
        case types.UPDATE_GAME_PROVIDER_ORDER_SUCCESS:
            state = {
                ...state,
                gameProviderList: action.payload,
                isSubmit: false,
            };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default game;
