import { AmountFormat, TablePlaceholder, Title } from "@/components/atoms";
import BreadCrumb from "@/components/common/BreadCrumb";
import { UpdatePaymentMethodModal } from "@/components/molecules/";
import { Routes, getPath, getPathTitle } from "@/constants";
import { getPaymentMethodPerProvider, updatePaymentMethod } from "@/store/actions";
import { numberFormat } from "@/utils";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row,
    Table,
} from "reactstrap";

const SwiftPay = () => {
    const dispatch = useDispatch();

    const { paymentMethodSwiftPayList, isLoading } = useSelector(
        (state) => state.paymentSetting
    );

    const [showModal, setShowModal] = useState(false);
    const [data, setData] = useState([]);

    const initialize = () => {
        dispatch(getPaymentMethodPerProvider('swift-pay'));
        setShowModal(false);
    };

    const onHandleUpdatePaymentMethod = (values, keyToken) => {
        dispatch(
            updatePaymentMethod(values, "swift-pay", keyToken, initialize)
        );
    };

    useEffect(() => {
        dispatch(getPaymentMethodPerProvider('swift-pay'));
    }, [dispatch]);

    return (
        <React.Fragment>
            <Title title={getPathTitle(Routes.PAYMENT_SETTINGS_SWIFTPAY)} />
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb
                        parent={{
                            isParent: true,
                            pageTitle: getPathTitle(
                                Routes.PAYMENT_SETTINGS_SWIFTPAY
                            ),
                            pageLink: getPath(Routes.PAYMENT_SETTINGS_SWIFTPAY),
                        }}
                    />
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardHeader className="align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">
                                        {getPathTitle(
                                            Routes.PAYMENT_SETTINGS_SWIFTPAY
                                        )}
                                    </h4>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <div
                                                className={`table-responsive text-center`}
                                            >
                                                {isLoading && (
                                                    <div className="loading-overlay"></div>
                                                )}
                                                <Table className="table-bordered table-hover align-middle table-nowrap mb-0 table-sm">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th>
                                                                Payment Method
                                                            </th>
                                                            <th>
                                                                Convenience Fee
                                                                %
                                                            </th>
                                                            <th>
                                                                Platform Fee %
                                                            </th>
                                                            <th>Total Fee %</th>
                                                            <th>Fixed Fee</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {paymentMethodSwiftPayList?.map(
                                                            (item, key) => (
                                                                <tr key={key}>
                                                                    <td>
                                                                        {
                                                                            item.name
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        {numberFormat(
                                                                            item.convenience_fee_percent
                                                                        ) + "%"}
                                                                    </td>
                                                                    <td>
                                                                        {numberFormat(
                                                                            item.platform_fee_percent
                                                                        ) + "%"}
                                                                    </td>
                                                                    <td>
                                                                        {numberFormat(
                                                                            item.fee_percent
                                                                        ) + "%"}
                                                                    </td>
                                                                    <td>
                                                                        {
                                                                            <AmountFormat
                                                                                amount={
                                                                                    item.fix_fee_amount
                                                                                }
                                                                            />
                                                                        }
                                                                    </td>
                                                                    <td>
                                                                        <Button
                                                                            onClick={() => {
                                                                                setData(
                                                                                    item
                                                                                );
                                                                                setShowModal(
                                                                                    true
                                                                                );
                                                                            }}
                                                                            color="primary"
                                                                            size="sm"
                                                                        >
                                                                            <i className="ri-pencil-line"></i>
                                                                        </Button>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        )}

                                                        {paymentMethodSwiftPayList.length ===
                                                            0 && (
                                                            <TablePlaceholder
                                                                colSpan={6}
                                                            />
                                                        )}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            {showModal && (
                <UpdatePaymentMethodModal
                    isOpen={showModal}
                    data={data}
                    setIsOpen={setShowModal}
                    handleUpdate={onHandleUpdatePaymentMethod}
                />
            )}
        </React.Fragment>
    );
};

export default SwiftPay;
