import {
    AmountFormat,
    DataCollapse,
    JsonPretty,
    StatusBadge,
    Table,
} from "@/components/atoms";
import { DirectWithdrawalModal } from "@/components/molecules";
import { getDirectWithdrawals } from "@/store/actions";
import { capitalizeFirstLetter, defaultDateFormat } from "@/utils";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Row } from "reactstrap";
import ProfileLayout from "../Layout";

const DirectWithdrawal = () => {
    const dispatch = useDispatch();
    const { directWithdrawals, isLoading } = useSelector(
        (state) => state.admin
    );
    const [search, setSearch] = useState("");
    const [sortBy, setSortBy] = useState({
        field: "",
        type: "asc",
    });
    const [data, setData] = useState({
        per_page: 10,
        page: 1,
        search: "",
        sort_by:
            sortBy.field && sortBy.type ? `${sortBy.field} ${sortBy.type}` : "",
    });

    const [collapse, setCollapse] = useState([]);

    const [
        directWithdrawalRequestModalIsOpen,
        setDirectWithdrawalRequestModalIsOpen,
    ] = useState(false);

    const tableTheadData = useMemo(() => {
        const handleCollapse = (id) => {
            if (collapse.includes(id)) {
                const selected = collapse.filter((value) => value !== id);
                setCollapse([...selected]);
            } else {
                setCollapse((collapse) => [...collapse, id]);
            }
        };

        return [
            {
                label: "Payment Provider",
                field: "payment_provider",
                sortable: true,
                render: (item) => capitalizeFirstLetter(item.payment_provider),
            },
            {
                label: "Payment Method",
                field: "payment_method",
                sortable: true,
                render: (item) => capitalizeFirstLetter(item.payment_method),
            },
            {
                label: "Executed By",
                field: "executed_by",
                sortable: false,
                render: (item) => capitalizeFirstLetter(item.executed_by),
            },
            {
                label: "Account No.",
                field: "account_no",
                sortable: false,
            },
            {
                label: "Account Name",
                field: "account_name",
                sortable: false,
            },
            {
                label: "Amount",
                field: "amount",
                sortable: false,
                render: (item) => <AmountFormat amount={item.amount} />,
            },
            {
                label: "Remarks",
                field: "remarks",
                sortable: false,
                render: (item) => <>{item?.remarks ? item?.remarks : "-"}</>,
            },
            {
                label: "Response Status",
                field: "response_status",
                sortable: false,
                render: (item) => (
                    <StatusBadge
                        status={item.response_status}
                        type="response"
                    />
                ),
            },
            {
                label: "Other Details",
                field: "other_details",
                sortable: false,
                render: (item) => (
                    <>
                        {item?.other_details ? (
                            <DataCollapse
                                label={
                                    collapse.includes(item.key_token)
                                        ? "show"
                                        : "view"
                                }
                                collapseKey={item.key_token}
                                data={[]}
                                content={
                                    <JsonPretty
                                        id={item.key_token}
                                        data={item?.other_details ?? []}
                                    />
                                }
                                collapse={collapse}
                                handleCollapse={handleCollapse}
                            />
                        ) : (
                            "-"
                        )}
                    </>
                ),
            },
            {
                label: "Date Requested",
                field: "created_at",
                sortable: true,
                render: (item) => defaultDateFormat(item.created_at),
            },
        ];
    }, [collapse]);

    const initialize = useCallback(() => {
        dispatch(getDirectWithdrawals(data));
        setDirectWithdrawalRequestModalIsOpen(false);
    }, [dispatch, data]);

    useEffect(() => {
        initialize();
    }, [initialize]);

    return (
        <ProfileLayout>
            {directWithdrawalRequestModalIsOpen && (
                <DirectWithdrawalModal
                    isOpen={directWithdrawalRequestModalIsOpen}
                    setIsOpen={setDirectWithdrawalRequestModalIsOpen}
                    initialize={initialize}
                />
            )}

            <Row>
                <Col className="d-flex justify-content-end">
                    <Button
                        color="primary"
                        onClick={() =>
                            setDirectWithdrawalRequestModalIsOpen(true)
                        }
                    >
                        <span className="ri-add-fill me-1 align-bottom"></span>
                        Direct Withdrawal
                    </Button>
                </Col>
            </Row>
            <hr />
            <Row>
                <Col>
                    <Table
                        search={search}
                        handlerSearch={setSearch}
                        sortBy={sortBy}
                        handlerSortBy={setSortBy}
                        thead={tableTheadData}
                        handlerChangeData={setData}
                        data={data}
                        collection={directWithdrawals}
                        isLoading={isLoading}
                        size="sm"
                    />
                </Col>
            </Row>
        </ProfileLayout>
    );
};

export default DirectWithdrawal;
