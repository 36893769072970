import {
    DeleteModal,
    StatusBadge,
    Table,
    Title,
    InputSwitch,
} from "@/components/atoms";
import BreadCrumb from "@/components/common/BreadCrumb";
import { Routes, getPath, getPathTitle } from "@/constants";
import { getSystemCountries, updateSystemCountryStatus } from "@/store/actions";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { confirmation } from "@/components/constants/common";

import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row,
} from "reactstrap";

const Countries = () => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.user);
    const {
        countries,
        isGetCountriesLoading,
        isUpdateCountriesLoading,
        isUpdateCountryStatusSuccess,
    } = useSelector((state) => state.country);

    const [showModal, setShowModal] = useState(false);
    const [search, setSearch] = useState("");

    const [sortBy, setSortBy] = useState({
        field: "updated_at",
        type: "desc",
    });

    const userType = "player";

    const [data, setData] = useState({
        per_page: 10,
        page: 1,
        sort_by: "name asc | updated_at desc",
    });

    const changeStatus = (item) => {
        confirmation({
            title: item.title,
            text: `Are you sure you want to ${
                item.is_active ? "DEACTIVATE" : "ACTIVATE"
            } this country`,
            icon: "warning",
            confirmButtonText: "Confirm",
            action: () => {
                dispatch(updateSystemCountryStatus(item.key_token));
            },
        });
    };

    const tableTheadData = [
        {
            label: "Name",
            field: "country",
            sortable: true,
            render: (item) => item?.name,
        },
        {
            label: "Code",
            field: "code",
            sortable: true,
        },
        {
            label: "Action",
            field: "action",
            sortable: false,
            render: (item) => (
                <div className="d-flex justify-content-center">
                    <InputSwitch
                        label={item.is_active ? "Active" : "Inactive"}
                        id={`game-type-status-${item.slug}`}
                        isChecked={item.is_active}
                        color="success"
                        onChange={() => changeStatus(item)}
                    />
                </div>
            ),
        },
    ];

    useEffect(() => {
        dispatch(getSystemCountries(data));
    }, [dispatch, data]);

    useEffect(() => {
        if (isUpdateCountryStatusSuccess) {
            dispatch(getSystemCountries(data));
        }
    }, [dispatch, data, isUpdateCountryStatusSuccess]);

    return (
        <React.Fragment>
            <Title title={getPathTitle(Routes.COUNTRIES)} />
            <DeleteModal
                show={showModal}
                onDeleteClick={() => setShowModal(false)}
                onCloseClick={() => setShowModal(false)}
            />
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb
                        parent={{
                            isParent: true,
                            pageTitle: getPathTitle(Routes.COUNTRIES),
                            pageLink: getPath(Routes.COUNTRIES),
                        }}
                    />
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardHeader className="align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">
                                        {getPathTitle(Routes.COUNTRIES)}
                                    </h4>
                                </CardHeader>
                                <CardBody>
                                    <Table
                                        search={search}
                                        handlerSearch={setSearch}
                                        sortBy={sortBy}
                                        handlerSortBy={setSortBy}
                                        thead={tableTheadData}
                                        handlerChangeData={setData}
                                        data={data}
                                        collection={countries}
                                        isLoading={isGetCountriesLoading}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Countries;
