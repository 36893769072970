import { axiosInstance } from "./index";
import { toFormData } from "../../utils/common";

export const sendAnnouncement = async (data) => await axiosInstance.post(`/bo/send-announcement`, data);
export const getMessageList = async (params) => await axiosInstance.get(`/bo/message/get-paginated`, { params });
export const updateMessageStatus = async (keyToken) => await axiosInstance.get(`/bo/message/update-status/${keyToken}`);
export const updateMessageInformation = async (payload, keyToken) => await axiosInstance.put(`/bo/message/update-information/${keyToken}`, payload);
export const getMessageTypes = async () => await axiosInstance.get(`/bo/message-type/get-all`);
export const createMessage = async (params) => {
    const formData = toFormData(params);

    return await axiosInstance({
        method: "post",
        url: `/bo/message/create`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
};

export const updateMessageBanner = async (params, keyToken) => {
    const formData = toFormData(params);

    return await axiosInstance({
        method: "post",
        url: `/bo/message/update-banner/${keyToken}`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
    });
};

export const getNotificationTypeList = async (params) => await axiosInstance.get(`/bo/notification-type/get-paginated`, { params });

export const updateNotificationTypeBanner = async (params, keyToken) => {
  const formData = toFormData(params);

  return await axiosInstance({
      method: "post",
      url: `/bo/notification-type/update-banner/${keyToken}`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    });
};

export const getUserNotificationList = async (params, keyToken) => await axiosInstance.get(`/bo/user-notification/get-paginated/${keyToken}`, { params });
