import { toast } from "react-hot-toast";

export const successToast = (message) => {
    toast.success(message, {
        duration: 5000,
    });
};

export const errorToast = (message) => {
    toast.remove();
    toast.error(message, {
        duration: 5000,
    });
};

export const loadingToast = (message) => {
    toast.loading(message);
};

export const toastIcon = (message, icon) => {
    toast(message, {
        icon: icon,
    });
};

export const removeToast = () => {
    toast.remove();
};
