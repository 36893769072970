import {
    AmountFormat,
    Button as CustomButton,
    FlatPickerDate,
    InputReactSelect,
    StatusBadge,
    Table,
    TextLink,
    Title,
} from "@/components/atoms";
import BreadCrumb from "@/components/common/BreadCrumb";
import { confirmation } from "@/components/constants/common";
import { Routes, commissionStatus, getPath, getPathTitle } from "@/constants";
import { dateFilterOptions } from "@/constants/users";
import { getCommission, processCommission } from "@/store/actions";
import {
    arrayToString,
    defaultDateTimeFormat,
    filterDateFormat,
    today,
} from "@/utils";
import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row,
} from "reactstrap";

const Commissions = () => {
    const dateFilterRef = useRef();
    const [searchParams] = useSearchParams();
    const { balance_category } = Object.fromEntries([...searchParams]);

    const dispatch = useDispatch();
    const { commissionList, isLoading } = useSelector((state) => state.user);
    const { isProcessCommissionLoading } = useSelector(
        (state) => state.transaction
    );

    const [search, setSearch] = useState("");
    const [sortBy, setSortBy] = useState({
        field: "",
        type: "asc",
    });

    const userCommisionType = "players";

    const defaultParams = {
        per_page: 10,
        page: 1,
        search: "",
        sort_by:
            sortBy.field && sortBy.type ? `${sortBy.field} ${sortBy.type}` : "",
        "date_range[commission_players.created_at][from]":
            dateFilterOptions[0].value[0],
        "date_range[commission_players.created_at][to]":
            dateFilterOptions[0].value[1],
        userCommisionType,
    };

    const [data, setData] = useState(defaultParams);

    const [selectedDate, setSelectedDate] = useState(dateFilterOptions[0]);

    const form = useFormik({
        enableReinitialize: true,
        initialValues: {
            userType: "",
            balanceCategory: balance_category ?? "",
            status: [],
            dateFilter: dateFilterOptions[0].value,
        },
        onSubmit: (values) => {
            setData({
                ...data,
                page: 1,
                user_type: values?.userType["value"] ?? null,
                "filter_by[status]": values.status
                    ? arrayToString(
                          values.status.map((item) => {
                              return item.value;
                          })
                      )
                    : null,
                "date_range[commission_players.created_at][from]":
                    values.dateFilter
                        ? values.dateFilter[0]
                        : data[
                              "date_range[commission_players.created_at][from]"
                          ],
                "date_range[commission_players.created_at][to]":
                    values.dateFilter
                        ? values.dateFilter[1]
                        : data["date_range[commission_players.created_at][to]"],
            });
        },
    });

    const tableTheadData = [
        {
            label: "Commission No.",
            field: "commission_no",
            sortable: true,
        },
        {
            label: "Transaction No.",
            field: "transaction_no",
            sortable: true,
            render: (item) => (
                <TextLink
                    text={item.transaction_no}
                    path={getPath(Routes.BET_TRANSACTION_VIEW).replace(
                        ":transactionNo",
                        item?.transaction_no
                    )}
                />
            ),
        },
        {
            label: "Balance Category",
            field: "balance_category",
            sortable: true,
        },
        {
            label: "Rate",
            field: "rate",
            sortable: true,
            render: (item) => `${item.rate}%`,
        },
        {
            label: "Amount",
            field: "amount",
            sortable: true,
            render: (item) => <AmountFormat amount={item?.amount} />,
        },
        {
            label: "Commission Before",
            field: "commission_before",
            sortable: true,
            render: (item) => <AmountFormat amount={item.commission_before} />,
        },
        {
            label: "Commission After",
            field: "commission_after",
            sortable: true,
            render: (item) => <AmountFormat amount={item.commission_after} />,
        },
        {
            label: "Status",
            field: "status",
            sortable: false,
            render: (item) => (
                <StatusBadge status={item.status} type="commission" />
            ),
        },
        {
            label: "Date",
            sortable: true,
            render: (item) => defaultDateTimeFormat(item.created_at),
        },
    ];

    const processCommissionConfirmation = () => {
        confirmation({
            text: `Are you sure you want to process all commissions?`,
            icon: "warning",
            confirmButtonText: "Confirm",
            action: () => {
                dispatch(
                    processCommission(
                        {
                            preferred_date: today(),
                            userCommisionType,
                        },
                        initialize
                    )
                );
            },
        });
    };

    const handleClear = () => {
        setData(defaultParams);
        setSelectedDate(dateFilterOptions[0]);
        form.resetForm();
        form.submitForm();
    };

    const initialize = () => {
        dispatch(getCommission(data));
    };

    useEffect(() => {
        dispatch(getCommission(data));
    }, [dispatch, data]);

    return (
        <React.Fragment>
            <Title title={getPathTitle(Routes.COMMISSIONS_PLAYERS)} />
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb
                        parent={{
                            isParent: true,
                            pageTitle: getPathTitle(Routes.COMMISSIONS_PLAYERS),
                            pageLink: getPath(Routes.COMMISSIONS_PLAYERS),
                        }}
                    />
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardHeader className="align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">
                                        {getPathTitle(
                                            Routes.COMMISSIONS_PLAYERS
                                        )}
                                    </h4>
                                    {process.env.REACT_APP_ENV !==
                                        "production" && (
                                        <CustomButton
                                            text="Process Commission"
                                            color="primary"
                                            onClick={
                                                processCommissionConfirmation
                                            }
                                            isLoading={
                                                isProcessCommissionLoading
                                            }
                                        />
                                    )}
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col md={6}>
                                            <InputReactSelect
                                                label="Status"
                                                isClearable={true}
                                                options={commissionStatus}
                                                value={form.values.status}
                                                onChange={(option) => {
                                                    form.setFieldValue(
                                                        "status",
                                                        option
                                                    );
                                                }}
                                                isMulti={true}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col md={12} className="pt-2">
                                            <div className="w-100 tw-flex tw-gap-1 tw-flex-col lg:tw-flex-row">
                                                {dateFilterOptions.map(
                                                    (item, index) => (
                                                        <Button
                                                            key={index}
                                                            color={
                                                                selectedDate.label ===
                                                                item.label
                                                                    ? "primary"
                                                                    : "light"
                                                            }
                                                            onClick={() => {
                                                                setSelectedDate(
                                                                    item
                                                                );
                                                                if (
                                                                    item.value
                                                                ) {
                                                                    form.setFieldValue(
                                                                        "dateFilter",
                                                                        item.value
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            {item.label}
                                                        </Button>
                                                    )
                                                )}
                                                <div className="tw-w-full lg:tw-w-1/5">
                                                    <FlatPickerDate
                                                        ref={dateFilterRef}
                                                        name="dateFilter"
                                                        mode="range"
                                                        placeholder="From Date - To Date"
                                                        maxDate={null}
                                                        value={
                                                            selectedDate.value
                                                        }
                                                        onChange={(date) => {
                                                            if (
                                                                date.length >= 2
                                                            ) {
                                                                form.setFieldValue(
                                                                    "dateFilter",
                                                                    date.map(
                                                                        (
                                                                            value
                                                                        ) =>
                                                                            filterDateFormat(
                                                                                value
                                                                            )
                                                                    )
                                                                );
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} className="text-center">
                                            <Button
                                                color="primary"
                                                className="mx-1"
                                                onClick={() => handleClear()}
                                            >
                                                Clear
                                            </Button>
                                            <Button
                                                color="success"
                                                className="mx-1"
                                                onClick={() =>
                                                    form.handleSubmit()
                                                }
                                            >
                                                Filter
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <Table
                                        search={search}
                                        handlerSearch={setSearch}
                                        sortBy={sortBy}
                                        handlerSortBy={setSortBy}
                                        thead={tableTheadData}
                                        handlerChangeData={setData}
                                        data={data}
                                        collection={commissionList}
                                        isLoading={isLoading}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Commissions;
