import {
    AmountFormat,
    InputSwitch,
    StatusBadge,
    Title,
} from "@/components/atoms";
import BreadCrumb from "@/components/common/BreadCrumb";
import { confirmation } from "@/components/constants/common";
import { Routes, getPath, getPathTitle } from "@/constants";
import { userRole } from "@/hooks";
import { UserDefaultPhoto } from "@/resources/images";
import {
    disableWithdrawalFeeMerchant,
    getOperatorProfile,
    updateCrossBettingProtection,
    updateMerchantSetting
} from "@/store/actions";
import { defaultDateFormat, defaultDateTimeFormat } from "@/utils";
import classnames from "classnames";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Input,
    Label,
    Nav,
    NavItem,
    NavLink,
    Row,
} from "reactstrap";

const Layout = ({ children }) => {
    const {
        profile,
        isUpdateMerchantSettingSuccess,
        isUpdateCrossBettingProtectionSuccess,
    } = useSelector((state) => state.operator);
    const { accountNo } = useParams();
    const dispatch = useDispatch();
    const nagivate = useNavigate();
    const location = useLocation();
    const { isSuperAdmin } = userRole();

    const initialize = () => {
        dispatch(getOperatorProfile(accountNo));
    };

    const changeStatus = () => {
        confirmation({
            text: `Are you sure you want to ${
                profile.allowedInMerchant ? "DEACTIVATE" : "ACTIVATE"
            } the merchant payment method of this operator`,
            icon: "warning",
            confirmButtonText: "Confirm",
            action: () => {
                dispatch(
                    updateMerchantSetting(
                        { value: profile.allowedInMerchant ? 0 : 1 },
                        profile.userProfilekeyToken
                    )
                );
            },
        });
    };

    const changeProtectionSettings = () => {
        confirmation({
            text: `Are you sure you want to ${
                profile.crossBettingProtection ? "DEACTIVATE" : "ACTIVATE"
            } cross betting protection of this operator`,
            icon: "warning",
            confirmButtonText: "Confirm",
            action: () => {
                dispatch(
                    updateCrossBettingProtection(
                        { value: profile.crossBettingProtection ? 0 : 1 },
                        profile.userProfilekeyToken
                    )
                );
            },
        });
    };

    const disableWithdrawalFee = () => {
        confirmation({
            text: `Are you sure you want to ${
                profile.withdrawalFeeMerchantDisabled ? "ENABLE" : "DISABLE"
            } the merchant withdrawal fee of this operator`,
            icon: "warning",
            confirmButtonText: "Confirm",
            action: () => {
                dispatch(
                    disableWithdrawalFeeMerchant(
                        {
                            value: profile.withdrawalFeeMerchantDisabled
                                ? 0
                                : 1,
                        },
                        profile.userProfilekeyToken,
                        initialize
                    )
                );
            },
        });
    };

    const details = [
        {
            label: "Username",
            value: profile.name,
            isShow: true,
        },
        {
            label: "Date Joined",
            value: defaultDateFormat(profile.createdAt),
            isShow: true,
        },
        {
            label: "Date Deactivated",
            value: defaultDateFormat(profile.deactivatedAt),
            isShow: profile.status === "deactivated" ? true : false,
        },
        {
            label: "Date Banned",
            value: defaultDateFormat(profile.bannedAt),
            isShow: profile.status === "banned" ? true : false,
        },
        {
            label: "Last Login",
            value: profile.lastLogin
                ? defaultDateTimeFormat(profile.lastLogin?.created_at)
                : "-",
            isShow: true,
        },
        {
            label: "Cross Betting Protection",
            render: () => (
                <div>
                    <InputSwitch
                        id={`merchant-option-${profile.crossBettingProtection}`}
                        isChecked={profile?.crossBettingProtection ?? false}
                        color="success"
                        onChange={() => changeProtectionSettings()}
                    />
                </div>
            ),
            isShow: true,
        },
        {
            label: "Merchant",
            render: () => (
                <div>
                    <InputSwitch
                        id={`merchant-option-${profile.allowedInMerchant}`}
                        isChecked={profile?.allowedInMerchant ?? false}
                        color="success"
                        onChange={() => changeStatus()}
                    />
                </div>
            ),
            isShow: true,
        },
        {
            label: "Disable Withdrawal Fee",
            render: () => (
                <div>
                    <InputSwitch
                        id={`disable-withdrawal-fee-merchant-option-${profile.withdrawalFeeMerchantDisabled}`}
                        isChecked={
                            profile?.withdrawalFeeMerchantDisabled ?? false
                        }
                        disabled={
                            !profile?.withdrawalFeeMerchantDisabledEditable ?? false
                        }
                        color="success"
                        onChange={() => disableWithdrawalFee()}
                    />
                </div>
            ),
            isShow: isSuperAdmin,
        },
    ];

    const balances = [
        {
            label: process.env.REACT_APP_BCF_LABEL,
            value: profile?.balance?.fiat?.php?.bcf ? (
                <AmountFormat amount={profile?.balance?.fiat?.php?.bcf} />
            ) : (
                "-"
            ),
            isShow: true,
        },
        {
            label: "PHP",
            value: profile?.balance?.fiat?.php?.merchant ? (
                <AmountFormat amount={profile?.balance?.fiat?.php?.merchant} />
            ) : (
                "-"
            ),
            isShow: true,
        },
    ];

    const nav = [
        {
            label: "Dashboard",
            path: getPath(Routes.COMMUNITY_MANAGER_DASHBOARD).replace(
                ":accountNo",
                accountNo
            ),
        },
        // {
        // 	label: "Commissions Settings",
        // 	path: getPath(Routes.COMMUNITY_MANAGER_COMMISSIONS_SETTINGS).replace(":accountNo", accountNo),
        // },
        {
            label: "Personal Details",
            path: getPath(Routes.COMMUNITY_MANAGER_PROFILE).replace(
                ":accountNo",
                accountNo
            ),
        },
        {
            label: "Downlines",
            path: getPath(Routes.COMMUNITY_MANAGER_DOWNLINES).replace(
                ":accountNo",
                accountNo
            ),
        },
        {
            label: "Betting Credit Fund",
            path: getPath(Routes.COMMUNITY_MANAGER_WALLET).replace(
                ":accountNo",
                accountNo
            ),
        },
        {
            label: "Request",
            path: getPath(Routes.COMMUNITY_MANAGER_REQUEST).replace(
                ":accountNo",
                accountNo
            ),
        },
        {
            label: "Settings",
            path: getPath(Routes.OPERATOR_SETTINGS).replace(
                ":accountNo",
                accountNo
            ),
        },
        // {
        //     label: "Payment Credentials",
        //     path: getPath(Routes.COMMUNITY_MANAGER_PAYMENT_CREDENTIALS).replace(
        //         ":accountNo",
        //         accountNo
        //     ),
        // },
    ];

    useEffect(() => {
        if (
            accountNo !== ":accountNo" ||
            isUpdateMerchantSettingSuccess ||
            isUpdateCrossBettingProtectionSuccess
        ) {
            dispatch(getOperatorProfile(accountNo));
        }
    }, [
        dispatch,
        accountNo,
        isUpdateMerchantSettingSuccess,
        isUpdateCrossBettingProtectionSuccess,
    ]);

    return (
        <React.Fragment>
            <Title title={`Operator | ${accountNo}`} />
            <div className="page-content placeholder-glow">
                <Container fluid>
                    <BreadCrumb
                        parent={{
                            isParent: false,
                            pageTitle: getPathTitle(Routes.COMMUNITY_MANAGERS),
                            pageLink: getPath(Routes.COMMUNITY_MANAGERS),
                        }}
                        childs={[
                            {
                                pageTitle: `${profile.firstName} ${profile.lastName}`,
                                pageLink: getPath(
                                    Routes.COMMUNITY_MANAGER_PROFILE
                                ),
                                isActive: true,
                            },
                        ]}
                    />
                    <Row>
                        <Col md={3}>
                            <Card>
                                <CardBody className="p-4">
                                    <div className="text-center">
                                        <div className="profile-user position-relative d-inline-block mx-auto mb-4">
                                            <img
                                                src={UserDefaultPhoto}
                                                className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                                                alt="user-profile"
                                            />
                                            <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                                                <Input
                                                    id="profile-img-file-input"
                                                    type="file"
                                                    className="profile-img-file-input"
                                                />
                                                <Label
                                                    htmlFor="profile-img-file-input"
                                                    className="profile-photo-edit avatar-xs"
                                                >
                                                    <span className="avatar-title rounded-circle bg-light text-body">
                                                        <i className="ri-camera-fill"></i>
                                                    </span>
                                                </Label>
                                            </div>
                                        </div>
                                        <p className="text-muted mb-0">
                                            {profile.accountNo}
                                        </p>
                                        <h5 className="fs-16 mb-1">
                                            {profile.firstName}{" "}
                                            {profile.lastName}
                                        </h5>
                                        <p className="text-muted mb-0">
                                            Community Manager
                                        </p>
                                        <StatusBadge status={profile.status} />
                                    </div>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardHeader className="align-items-center d-flex">
                                    <h4 className="card-title text-center mb-0 flex-grow-1">
                                        User Details
                                    </h4>
                                </CardHeader>
                                <CardBody>
                                    <div className="table-card">
                                        <table className="table mb-0">
                                            <tbody>
                                                {details.map((item, key) => (
                                                    <tr
                                                        key={key}
                                                        className={
                                                            !item.isShow
                                                                ? "d-none"
                                                                : ""
                                                        }
                                                    >
                                                        <td className="fw-medium">
                                                            {item.label}
                                                        </td>
                                                        <td>
                                                            {item?.render
                                                                ? item?.render()
                                                                : item.value}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardHeader className="align-items-center d-flex">
                                    <h4 className="card-title text-center mb-0 flex-grow-1">
                                        Balances
                                    </h4>
                                </CardHeader>
                                <CardBody>
                                    <div className="table-card">
                                        <table className="table mb-0">
                                            <tbody>
                                                {balances.map((item, key) => (
                                                    <tr
                                                        key={key}
                                                        className={
                                                            !item.isShow
                                                                ? "d-none"
                                                                : ""
                                                        }
                                                    >
                                                        <td className="fw-medium">
                                                            {item.label}
                                                        </td>
                                                        <td>
                                                            {item?.render
                                                                ? item?.render()
                                                                : item.value}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col md={9}>
                            <Card>
                                <CardHeader>
                                    <Nav
                                        className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                        role="tablist"
                                    >
                                        {nav.map((item, key) => (
                                            <NavItem key={key}>
                                                <NavLink
                                                    className={classnames({
                                                        active:
                                                            item.path ===
                                                            location.pathname,
                                                    })}
                                                    onClick={() => {
                                                        nagivate(item.path);
                                                    }}
                                                    type="button"
                                                >
                                                    <i className="far fa-user"></i>
                                                    {item.label}
                                                </NavLink>
                                            </NavItem>
                                        ))}
                                    </Nav>
                                </CardHeader>
                                <CardBody className="p-4">{children}</CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Layout;
