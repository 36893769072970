import * as types from "./actionTypes";

export const sendAnnouncement = (payload, result) => ({
    type: types.SEND_ANNOUNCEMENT,
    payload,
    result
});

export const sendAnnouncementSuccess = () => ({
    type: types.SEND_ANNOUNCEMENT_SUCCESS,
});

export const sendAnnouncementError = (payload) => ({
    type: types.SEND_ANNOUNCEMENT_ERROR,
    payload,
});

export const getMessageList = (payload) => ({
    type: types.GET_MESSAGE,
    payload
});

export const getMessageSuccess = (payload) => ({
    type: types.GET_MESSAGE_SUCCESS,
    payload
});

export const getMessageError = (payload) => ({
    type: types.GET_MESSAGE_ERROR,
    payload,
});

export const updateMessageStatus = (payload, initialize) => ({
    type: types.UPDATE_MESSAGE_STATUS,
    payload,
    initialize
});

export const updateMessageStatusSuccess = () => ({
    type: types.UPDATE_MESSAGE_STATUS_SUCCESS
});

export const updateMessageStatusError = () => ({
    type: types.UPDATE_MESSAGE_STATUS_ERROR
});

export const getMessageTypes = () => ({
    type: types.GET_MESSAGE_TYPES
});

export const getMessageTypesSuccess = (payload) => ({
    type: types.GET_MESSAGE_TYPES_SUCCESS,
    payload
});

export const getMessageTypesError = () => ({
    type: types.GET_MESSAGE_TYPES_ERROR,
});

export const createMessage = (payload, result) => ({
    type: types.CREATE_MESSAGE,
    payload,
    result
});

export const createMessageSuccess = () => ({
    type: types.CREATE_MESSAGE_SUCCESS
});

export const createMessageError = () => ({
    type: types.CREATE_MESSAGE_ERROR,
});

export const updateMessageInformation = (payload, keyToken, result) => ({
    type: types.UPDATE_MESSAGE_INFORMATION,
    payload,
    keyToken,
    result
});

export const updateMessageInformationSuccess = () => ({
    type: types.UPDATE_MESSAGE_INFORMATION_SUCCESS
});

export const updateMessageInformationError = () => ({
    type: types.UPDATE_MESSAGE_INFORMATION_ERROR
});

export const updateMessageBanner = (payload, keyToken, result) => ({
    type: types.UPDATE_MESSAGE_BANNER,
    payload,
    keyToken,
    result
});

export const updateMessageBannerSuccess = () => ({
    type: types.UPDATE_MESSAGE_BANNER_SUCCESS
});

export const updateMessageBannerError = () => ({
    type: types.UPDATE_MESSAGE_BANNER_ERROR
});

export const getNotificationTypeList = (payload) => ({
    type: types.GET_NOTIFICATION_TYPE_LIST,
    payload,
});

export const getNotificationTypeListSuccess = (payload) => ({
    type: types.GET_NOTIFICATION_TYPE_LIST_SUCCESS,
    payload
});

export const getNotificationTypeListError = () => ({
    type: types.GET_NOTIFICATION_TYPE_LIST_ERROR
});

export const updateNotificationTypeBanner = (payload, keyToken, result) => ({
    type: types.UPDATE_NOTIFICATION_TYPE_BANNER,
    payload,
    keyToken,
    result
});

export const updateNotificationTypeBannerSuccess = () => ({
    type: types.UPDATE_NOTIFICATION_TYPE_BANNER_SUCCESS,
});

export const updateNotificationTypeBannerError = () => ({
    type: types.UPDATE_NOTIFICATION_TYPE_BANNER_ERROR
});


export const getUserNotificationList = (payload, keyToken) => ({
    type: types.GET_USER_NOTIFICATION_LIST,
    payload,
    keyToken,
});

export const getUserNotificationListSuccess = (payload) => ({
    type: types.GET_USER_NOTIFICATION_LIST_SUCCESS,
    payload
});

export const getUserNotificationListError = () => ({
    type: types.GET_USER_NOTIFICATION_LIST_ERROR
});