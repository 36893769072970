import {
    EmailUpdateInput,
    MobileNoUpdateInput,
    PasswordResetButton,
    PersonalInformationForm,
    StatusUpdateDropdown,
    UpdateRoleTagSelect,
} from "@/components/molecules";
import { userRole } from "@/hooks";
import React from "react";
import { useSelector } from "react-redux";
import { Col, Row } from "reactstrap";

import ProfileLayout from "../Layout";

const Profile = () => {
    const { isLoading: isLoadingUser, errors: userErrors } = useSelector(
        (state) => state.user
    );

    const {
        isSuperAdmin,
        isAssistantAdmin,
        isOperator,
        isAdmin: isAdminRole,
    } = userRole();

    const isAdmin = isSuperAdmin || isAdminRole;

    const { isLoading, errors, profile } = useSelector(
        (state) => state.operator
    );

    return (
        <ProfileLayout>
            <Row className="mb-3">
                <Col md={12}>
                    {isAdmin && (
                        <StatusUpdateDropdown
                            profile={profile}
                            isLoading={isLoadingUser}
                            disabled={isAssistantAdmin}
                        />
                    )}
                    <PasswordResetButton
                        isLoading={isLoadingUser}
                        email={profile.email}
                        userType="operator"
                    />
                </Col>
            </Row>
            <hr />
            <Row className="mb-3">
                <Col md="6">
                    <EmailUpdateInput
                        errors={userErrors}
                        profile={profile}
                        isAdmin={isAdmin && !isAssistantAdmin}
                        userType="operator"
                    />
                </Col>
                <Col md="6">
                    <MobileNoUpdateInput
                        errors={userErrors}
                        profile={profile}
                        isAdmin={isAdmin && !isAssistantAdmin}
                    />
                </Col>
                <Col md="6">
                    <UpdateRoleTagSelect
                        profile={profile}
                        userType="operator"
                        disabled={isAssistantAdmin}
                    />
                </Col>
            </Row>

            <PersonalInformationForm
                profile={profile}
                userType="operator"
                isLoading={isLoading}
                isAdmin={isAdmin && !isAssistantAdmin}
                errors={errors}
            />
        </ProfileLayout>
    );
};

export default Profile;
