import * as API from "@/network/apis/setting";
import { convert2dErrorsArray } from "@/utils";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import {
    errorToast,
    loadingToast,
    removeToast,
    successToast,
    toastIcon,
} from "@/components/constants/toast";
import * as ACTIONS from "./actions";
import * as TYPES from "./actionTypes";

function* getSettingsData() {
    try {
        const response = yield call(API.getSettingsData);

        if (response.status === 200) {
            yield put(ACTIONS.setSettingsData(response.data.response));
        }
    } catch (error) {
        yield put(ACTIONS.setSettingsDataError());
    }
}

function* updateSetting({ payload, keyToken }) {
    try {
        loadingToast("Saving...");
        const response = yield call(API.updateSetting, payload, keyToken);

        if (response.status === 200) {
            let data = response.data.response;
            yield put(ACTIONS.updateSettingSuccess());
            yield call(getSettingsData);
            removeToast();
            successToast("Successfully Updated.");
            if (data.value_format !== "boolean") {
                document.getElementById(data.key_token).value = data.value;
            }
        }
    } catch (error) {
        yield put(ACTIONS.updateSettingError());
        removeToast();
        errorToast(error.response.data.message);
    }
}

function* watchGetSettingsData() {
    yield takeLatest(TYPES.GET_SETTINGS_DATA, getSettingsData);
}
function* watchUpdateSetting() {
    yield takeLatest(TYPES.UPDATE_SETTING, updateSetting);
}
function* settingSagas() {
    yield all([fork(watchGetSettingsData), fork(watchUpdateSetting)]);
}

export default settingSagas;
