import * as types from "./actionTypes";
import { settingState } from "@/types/states";

const initialState = settingState;

const setting = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_SETTINGS_DATA:
        case types.UPDATE_SETTING:
            state = {
                ...state,
                isLoading: true,
            };
            break;
        case types.SET_SETTINGS_DATA:
            state = {
                ...state,
                list: action.payload,
                isLoading: false,
            };
            break;
        case types.SET_SETTINGS_DATA_ERROR:
        case types.UPDATE_SETTING_ERROR:
            state = {
                ...state,
                isLoading: false,
            };
            break;
        case types.UPDATE_SETTING_SUCCESS:
            state = {
                ...state,
                isLoading: false,
                errors: settingState.errors,
            };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default setting;
