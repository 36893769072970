import { InputSwitch, Select } from "@/components/atoms";
import { confirmation } from "@/components/constants/common";
import { commissionTransferSchedules } from "@/constants";
import {
    applyDownlinesCommissionTransferScheduleAgent,
    getOperatorProfile,
    updateUserProfileSetting,
    updateUserProfileSettingWithBalanceCategory,
} from "@/store/actions";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";

const Settings = () => {
    const dispatch = useDispatch();
    const { accountNo } = useParams();
    const { profile } = useSelector((state) => state.operator);

    const initialize = () => {
        dispatch(getOperatorProfile(accountNo));
    };

    const changeSportsBettingCommissionSeparationStatus = (
        balanceCategory,
        value,
        text = ""
    ) => {
        confirmation({
            title: `Are you sure you want to ${
                value ? "DEACTIVATE" : "ACTIVATE"
            } `,
            text,
            icon: "warning",
            confirmButtonText: "Confirm",
            action: () => {
                dispatch(
                    updateUserProfileSettingWithBalanceCategory(
                        "enable-separate-sports-betting-commission",
                        { value: value ? 0 : 1 },
                        balanceCategory,
                        profile.userProfilekeyToken,
                        initialize
                    )
                );
            },
        });
    };

    const changeCommissionTransferSchedule = (
        balanceCategory,
        scheduleType,
        label
    ) => {
        let additionalMessageLabel = "";
        let additionalMessage = "";

        if (["bcf-sports", "merchant-sports"].includes(balanceCategory)) {
            additionalMessageLabel =
                balanceCategory === "bcf-sports"
                    ? "Sports/E-Sports/Live Casino BCF (mPHP)"
                    : "Sports/E-Sports/Live Casino Merchant (Php)";
            additionalMessage = `Updating this will also enables commission separation for ${additionalMessageLabel}`;
        }

        confirmation({
            text: `Are you sure you want to change the transfer schedule of ${label} to ${scheduleType} of this operator. ${additionalMessage}`,
            icon: "warning",
            confirmButtonText: "Confirm",
            action: () => {
                dispatch(
                    updateUserProfileSetting(
                        `update-commission-transfer-schedule/${balanceCategory}`,
                        { value: scheduleType },
                        profile.userProfilekeyToken,
                        initialize
                    )
                );
            },
        });
    };

    const changeApplyToDownlinesCommissionTransferSchedule = (
        balanceType,
        value,
        text = ""
    ) => {
        confirmation({
            title: `Are you sure you want to ${
                value ? "DEACTIVATE" : "ACTIVATE"
            } `,
            text,
            icon: "warning",
            confirmButtonText: "Confirm",
            action: () => {
                dispatch(
                    applyDownlinesCommissionTransferScheduleAgent(
                        {
                            value: value ? 0 : 1,
                            balanceType,
                        },
                        profile?.userProfilekeyToken,
                        initialize
                    )
                );
            },
        });
    };

    const separationCommissionsSettings = [
        {
            label: "Sports/E-Sports/Live Casino Merchant (Php)",
            value: profile?.separateSportsBettingCommissionMerchant,
            disabled: !profile?.separateSportsBettingCommissionMerchantEditable,
            balanceCategory: "merchant",
        },
        {
            label: `Sports/E-Sports/Live Casino BCF (${process.env.REACT_APP_BCF_LABEL})`,
            value: profile?.separateSportsBettingCommissionBcf,
            disabled: !profile?.separateSportsBettingCommissionBcfEditable,
            balanceCategory: "bcf",
        },
    ];

    const commissionTransferScheduleSettings = [
        {
            label: "E-Games/Slots Merchant (Php)",
            value: profile?.commissionTransferScheduleMerchant,
            balanceType: "merchant",
        },
        {
            label: `E-Games/Slots BCF (${process.env.REACT_APP_BCF_LABEL})`,
            value: profile?.commissionTransferScheduleBcf,
            balanceType: "bcf",
        },
        {
            label: "Sports/E-Sports/Live Casino Merchant (Php)",
            value: profile?.commissionTransferScheduleMerchantSports,
            balanceType: "merchant-sports",
        },
        {
            label: `Sports/E-Sports/Live Casino BCF (${process.env.REACT_APP_BCF_LABEL})`,
            value: profile?.commissionTransferScheduleBcfSports,
            balanceType: "bcf-sports",
        },
        ...process.env.REACT_APP_LOTTO_ENABLED === "true" ? [
            {
                label: `Lotto Merchant (${process.env.REACT_APP_DEFAULT_CURRENCY})`,
                value: profile?.commissionTransferScheduleMerchantLotto,
                balanceType: "merchant-lotto",
            },
            {
                label: `Lotto BCF (${process.env.REACT_APP_BCF_LABEL})`,
                value: profile?.commissionTransferScheduleBcfLotto,
                balanceType: "bcf-lotto",
            },
        ] : []
    ];

    const applyDownlineCommissionsSettings = [
        {
            label: "E-Games/Slots Merchant (Php)",
            value: profile?.applyToDownlinesCommissionTransferScheduleMerchant,
            disabled:
                !profile?.applyToDownlinesCommissionTransferScheduleMerchantEditable,
            balanceType: "merchant",
        },
        {
            label: `E-Games/Slots BCF (${process.env.REACT_APP_BCF_LABEL})`,
            value: profile?.applyToDownlinesCommissionTransferScheduleBcf,
            disabled:
                !profile?.applyToDownlinesCommissionTransferScheduleBcfEditable,
            balanceType: "bcf",
        },
        {
            label: "Sports/E-Sports/Live Casino Merchant (Php)",
            value: profile?.applyToDownlinesCommissionTransferScheduleMerchantSports,
            disabled:
                !profile?.applyToDownlinesCommissionTransferScheduleMerchantSportsEditable,
            balanceType: "merchant-sports",
        },
        {
            label: `Sports/E-Sports/Live Casino BCF (${process.env.REACT_APP_BCF_LABEL})`,
            value: profile?.applyToDownlinesCommissionTransferScheduleBcfSports,
            disabled:
                !profile?.applyToDownlinesCommissionTransferScheduleBcfSportsEditable,
            balanceType: "bcf-sports",
        },
        ...process.env.REACT_APP_LOTTO_ENABLED === "true" ? [ 
            {
                label: `Lotto Merchant (${process.env.REACT_APP_DEFAULT_CURRENCY})`,
                value: profile?.applyToDownlinesCommissionTransferScheduleMerchantLotto,
                disabled:
                    !profile?.applyToDownlinesCommissionTransferScheduleMerchantLottoEditable,
                balanceType: "merchant-lotto",
            },
            {
                label: `Lotto BCF (${process.env.REACT_APP_BCF_LABEL})`,
                value: profile?.applyToDownlinesCommissionTransferScheduleBcfLotto,
                disabled:
                    !profile?.applyToDownlinesCommissionTransferScheduleBcfLottoEditable,
                balanceType: "bcf-lotto",
            },
        ] : []
    ];

    return (
        <div className="tw-space-y-4">
            <Card>
                <CardHeader>
                    <h5>Commission Separation</h5>
                </CardHeader>
                <CardBody>
                    <Row>
                        {separationCommissionsSettings.map((item, index) => (
                            <Col md={6} key={index}>
                                <InputSwitch
                                    id={`separation-commission-${index}`}
                                    isChecked={item.value}
                                    disabled={item.disabled}
                                    color="success"
                                    onChange={() =>
                                        changeSportsBettingCommissionSeparationStatus(
                                            item.balanceCategory,
                                            item.value,
                                            item.label
                                        )
                                    }
                                    label={item.label}
                                />
                            </Col>
                        ))}
                    </Row>
                </CardBody>
            </Card>
            <Card>
                <CardHeader>
                    <h5>Commission Transfer Schedule</h5>
                </CardHeader>
                <CardBody>
                    <Row className="gy-2">
                        {commissionTransferScheduleSettings.map(
                            (item, index) => (
                                <Col md={6} key={index}>
                                    <Select
                                        label={item.label}
                                        name={`commission-transfer-schedule-${index}`}
                                        isClearable={false}
                                        value={item.value}
                                        options={commissionTransferSchedules}
                                        onChange={(e) => {
                                            console.log(e.target.value);
                                            changeCommissionTransferSchedule(
                                                item.balanceType,
                                                e.target.value,
                                                item.label
                                            );
                                        }}
                                    />
                                </Col>
                            )
                        )}
                    </Row>
                </CardBody>
            </Card>
            <Card>
                <CardHeader>
                    <h5>Apply to Downlines Commission Transfer Schedule</h5>
                </CardHeader>
                <CardBody>
                    <Row>
                        {applyDownlineCommissionsSettings.map((item, index) => (
                            <Col md={6} key={index}>
                                <InputSwitch
                                    id={`apply-to-downlines-commission-transafer-schedule-${index}`}
                                    isChecked={item.value}
                                    disabled={item.disabled}
                                    color="success"
                                    onChange={() =>
                                        changeApplyToDownlinesCommissionTransferSchedule(
                                            item.balanceType,
                                            item.value,
                                            item.label
                                        )
                                    }
                                    label={item.label}
                                />
                            </Col>
                        ))}
                    </Row>
                </CardBody>
            </Card>
        </div>
    );
};

export default Settings;
