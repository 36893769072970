import * as API from "@/network/apis/dashboard";
import { all, call, fork, put, takeEvery, takeLatest } from "redux-saga/effects";
import * as ACTIONS from "./actions";
import * as TYPES from "./actionTypes";
import { errorToast } from "@/components/constants/toast";

function* getDashboardAnalyticsMainTotalAsync(action) {
    try {
        const response = yield call(
            API.getDashboardAnalyticsMainTotal,
            action.payload
        );

        if (response.status === 200) {
            yield put(
                ACTIONS.onGetDashboardAnalyticsMainTotalSuccess(
                    response.data.response
                )
            );
        }
    } catch (error) {
        yield put(ACTIONS.onGetDashboardAnalyticsMainTotalFailed());
    }
}

function* getDashboardAnalyticsOverallTotalAsync(action) {
    try {
        const total = yield call(API.getDashboardAnalyticsOverallTotal, action.payload);
        if (total.status === 200) {
            yield put(
                ACTIONS.onGetDashboardAnalyticsOverallTotalSuccess(
                {data: total.data.response, summary_type: action.payload.params.summary_type, balance_category: action.payload.params.balance_category  }
                )
            );
        }
    } catch (error) {
        yield put(ACTIONS.onGetDashboardAnalyticsOverallTotalFailed());
        switch (error.response.data.code) {
            case 422:
                errorToast(error.response.data.message);
                break;
            default:
                break;
        }
    }
}

function* getDailyTotalGgrSummaryAsync(action) {
    try {
        const response = yield call(
            API.getDailyTotalGgrSummary,
            action.payload
        );

        if (response.status === 200) {
            yield put(
                ACTIONS.onGetDailyTotalGgrSummarySuccess(response.data.response)
            );
        }
    } catch (error) {
        yield put(ACTIONS.onGetDailyTotalGgrSummaryFailed());
        switch (error.response.data.code) {
            case 422:
                errorToast(error.response.data.message);
                break;
            default:
                break;
        }
    }
}

function* getDailyTotalDepositAndWithdrawalSummaryAsync(action) {
    try {
        const response = yield call(
            API.getDailyTotalDepositAndWithdrawalSummary,
            action.payload
        );

        if (response.status === 200) {
            yield put(
                ACTIONS.onGetDailyTotalDepositAndWithdrawalSummarySuccess(
                    response.data.response
                )
            );
        }
    } catch (error) {
        yield put(ACTIONS.onGetDailyTotalDepositAndWithdrawalSummaryFailed());
        switch (error.response.data.code) {
            case 422:
                errorToast(error.response.data.message);
                break;
            default:
                break;
        }
    }
}

function* getMonthlyTotalPlayerSummaryAsync(action) {
    try {
        const response = yield call(
            API.getMonthlyTotalPlayerSummary,
            action.payload
        );

        if (response.status === 200) {
            yield put(
                ACTIONS.onGetMonthlyTotalPlayerSummarySuccess(
                    response.data.response
                )
            );
        }
    } catch (error) {
        yield put(ACTIONS.onGetMonthlyTotalPlayerSummaryFailed());
        switch (error.response.data.code) {
            case 422:
                errorToast(error.response.data.message);
                break;
            default:
                break;
        }
    }
}

function* watchGetDashboardAnalyticsMainTotal() {
    yield takeLatest(
        TYPES.GET_DASHBOARD_ANALYTICS_MAIN_TOTAL,
        getDashboardAnalyticsMainTotalAsync
    );
}

function* watchGetDashboardAnalyticsOverallTotal() {
    yield takeEvery(
        TYPES.GET_DASHBOARD_ANALYTICS_OVERALL_TOTAL,
        getDashboardAnalyticsOverallTotalAsync
    );
}

function* watchGetDailyTotalGgrSummaryAsync() {
    yield takeLatest(
        TYPES.GET_DAILY_TOTAL_GGR_SUMMARY,
        getDailyTotalGgrSummaryAsync
    );
}

function* watchGetDailyTotalDepositAndWithdrawalSummary() {
    yield takeLatest(
        TYPES.GET_DAILY_TOTAL_DEPOSIT_AND_WITHDRAWAL_SUMMARY,
        getDailyTotalDepositAndWithdrawalSummaryAsync
    );
}

function* watchGetMonthlyTotalPlayerSummary() {
    yield takeLatest(
        TYPES.GET_MONTHLY_TOTAL_PLAYER_SUMMARY,
        getMonthlyTotalPlayerSummaryAsync
    );
}

function* dashboardSagas() {
    yield all([fork(watchGetDashboardAnalyticsMainTotal)]);
    yield all([fork(watchGetDashboardAnalyticsOverallTotal)]);
    yield all([fork(watchGetDailyTotalGgrSummaryAsync)]);
    yield all([fork(watchGetDailyTotalDepositAndWithdrawalSummary)]);
    yield all([fork(watchGetMonthlyTotalPlayerSummary)]);
}

export default dashboardSagas;
