import { dashboardState } from "@/types/states";
import * as types from "./actionTypes";

const initialState = dashboardState;

const dashboard = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_DASHBOARD_ANALYTICS_MAIN_TOTAL:
            state = {
                ...state,
                isGetDashboardMainTotalLoading: true,
            };
            break;
        case types.GET_DASHBOARD_ANALYTICS_MAIN_TOTAL_SUCCESS:
            state = {
                ...state,
                dashboardMainTotal: action.payload,
                isGetDashboardMainTotalLoading: false,
            };
            break;
        case types.GET_DASHBOARD_ANALYTICS_MAIN_TOTAL_FAILED:
            state = {
                ...state,
                isGetDashboardMainTotalLoading: false,
            };
            break;
        case types.GET_DASHBOARD_ANALYTICS_OVERALL_TOTAL:
            state = {
                ...state,
                isGetDashboardOverallTotalLoading: true,
                dashboardOverallTotal: {
                    ...state.dashboardOverallTotal,
                    [action.payload.params.summary_type]: {
                        ...state.dashboardOverallTotal[action.payload.params.summary_type],
                        [action.payload.params.balance_category]: {
                            isLoading: true,
                        }
                    },
                },
            };
            break;
        case types.GET_DASHBOARD_ANALYTICS_OVERALL_TOTAL_SUCCESS:
            state = {
                ...state,
                isGetDashboardOverallTotalLoading: false,
                dashboardOverallTotal: {
                    ...state.dashboardOverallTotal,
                    [action.payload.summary_type]: {
                        ...state.dashboardOverallTotal[action.payload.summary_type],
                        [action.payload.balance_category]: {
                            ...action.payload.data[action.payload.balance_category],
                            isLoading: false,
                        },
                    },
                },
            };
            break;
        case types.GET_DASHBOARD_ANALYTICS_OVERALL_TOTAL_FAILED:
            state = {
                ...state,
                isGetDashboardOverallTotalLoading: false,
            };
            break;
        case types.GET_DAILY_TOTAL_GGR_SUMMARY:
            state = {
                ...state,
                isGetDailyTotalGgrSummaryLoading: true,
            };
            break;
        case types.GET_DAILY_TOTAL_GGR_SUMMARY_SUCCESS:
            state = {
                ...state,
                isGetDailyTotalGgrSummaryLoading: false,
                dailyTotalGgrSummary: action.payload,
            };
            break;
        case types.GET_DAILY_TOTAL_GGR_SUMMARY_FAILED:
            state = {
                ...state,
                isGetDailyTotalGgrSummaryLoading: false,
            };
            break;
        case types.GET_DAILY_TOTAL_DEPOSIT_AND_WITHDRAWAL_SUMMARY:
            state = {
                ...state,
                isGetDailyTotalDepositAndWithdrawalSummaryLoading: true,
            };
            break;
        case types.GET_DAILY_TOTAL_DEPOSIT_AND_WITHDRAWAL_SUMMARY_SUCCESS:
            state = {
                ...state,
                isGetDailyTotalDepositAndWithdrawalSummaryLoading: false,
                dailyTotalDepositAndWithdrawalSummary: action.payload,
            };
            break;
        case types.GET_DAILY_TOTAL_DEPOSIT_AND_WITHDRAWAL_SUMMARY_FAILED:
            state = {
                ...state,
                isGetDailyTotalDepositAndWithdrawalSummaryLoading: false,
            };
            break;
        case types.GET_MONTHLY_TOTAL_PLAYER_SUMMARY:
            state = {
                ...state,
                isGetMonthlyTotalPlayerSummaryLoading: true,
            };
            break;
        case types.GET_MONTHLY_TOTAL_PLAYER_SUMMARY_SUCCESS:
            state = {
                ...state,
                isGetMonthlyTotalPlayerSummaryLoading: false,
                monthlyTotalPlayerSummary: action.payload,
            };
            break;
        case types.GET_MONTHLY_TOTAL_PLAYER_SUMMARY_FAILED:
            state = {
                ...state,
                isGetMonthlyTotalPlayerSummaryLoading: false,
            };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default dashboard;
