import {
  Button,
  InputReactSelect,
  InputSwitch,
  InputText,
  TextArea,
  DisplayText,
} from "@/components/atoms";
import { sendAnnouncementValidation as validationSchema } from "@/constants";
import { sendAnnouncement } from "@/store/actions";
import { useFormik } from "formik";
import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Form, FormGroup, Row, Label } from "reactstrap";
import { useUserTypeOptions } from "@/hooks";

const SendAnnouncementForm = () => {
  const dispatch = useDispatch();
  const operatorList = useSelector((state) => state.user.operatorList);
  const { isLoading } = useSelector((state) => state.announcement);

  const { data: filteredUserTypeOptions } = useUserTypeOptions(true);

  const userTypesOptions = useMemo(
    () => filteredUserTypeOptions.filter((item) => item.value !== "operator"),
    [filteredUserTypeOptions]
  );

  const operatorListOptions = useMemo(
    () => [
      {
        label: "All",
        value: null,
      },
      ...operatorList,
    ],
    [operatorList]
  );

  const validateSelected = useCallback((value, options) => {
    let newValue = value;

    if (value.filter((item) => !item.value).length > 0) {
      newValue = options.filter((item) => !!item.value);
    }

    return newValue.filter((item) => !!item.value);
  }, []);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      test_name: "",
      test_email: "",
      test_mobile_no: "",
      subject: "",
      sms_message: "",
      email_message: "",
      operators: [],
      is_downline: false,
      user_types: [],
      is_test_mode: true,
    },
    validationSchema,
    onSubmit: (values) => {
      let payload = Object.assign({}, values);
      if (!values.is_test_mode) {
        delete payload.test_email;
        delete payload.test_name;
        delete payload.test_mobile_no;
        payload = {
          ...payload,
          operators: values?.operators?.map((item) => item.value),
          user_types: values?.user_types?.map((item) => item.value),
          is_downline: values?.is_downline ? "1" : "0",
        };
      } else {
        delete payload.operators;
        delete payload.is_downline;
        delete payload.user_types;
      }
      delete payload.is_test_mode;
      dispatch(sendAnnouncement(payload, result));
    },
  });

  const result = (isSuccess) => {
    if (isSuccess && !validation.values.is_test_mode) {
      validation.resetForm();
    }
  };
  return (
    <Form
      className="needs-validation"
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      {/* {errors && <ErrorAlert errors={errors} validation={validation} />} */}
      <Col md="12">
        <Row>
          <Col md="12">
            <Row>
              <Col md="6">
                <DisplayText
                  label="Testing Mode?"
                  item={
                    <InputSwitch
                      label={validation.values.is_test_mode ? "Yes" : "No"}
                      id={"is-testmode"}
                      isChecked={validation.values.is_test_mode}
                      color="success"
                      onChange={(e) =>
                        validation.setFieldValue(
                          "is_test_mode",
                          !validation.values.is_test_mode
                        )
                      }
                    />
                  }
                />
              </Col>

              {!validation.values.is_test_mode && (
                <Col md="6">
                  <DisplayText
                    label="Is Downline"
                    item={
                      <InputSwitch
                        label={validation.values.is_downline ? "Yes" : "No"}
                        id={"is-downline"}
                        isChecked={validation?.values.is_downline}
                        color="success"
                        onChange={(e) =>
                          validation.setFieldValue(
                            "is_downline",
                            !validation.values.is_downline
                          )
                        }
                      />
                    }
                    // isLoading={isLoading}
                    col="8"
                  />
                </Col>
              )}
            </Row>
            <hr />
            {validation.values.is_test_mode ? (
              <Row>
                <Col>
                  <FormGroup className="mb-3">
                    <InputText
                      label="Name"
                      name="test_name"
                      placeholder="Name"
                      validation={validation}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className="mb-3">
                    <InputText
                      label="Email"
                      name="test_email"
                      placeholder="Email"
                      validation={validation}
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className="mb-3">
                    <InputText
                      label="Mobile No."
                      name="test_mobile_no"
                      placeholder="Mobile no."
                      validation={validation}
                    />
                  </FormGroup>
                </Col>
              </Row>
            ) : (
              <>
                <Row>
                  <Col md="6">
                    <FormGroup className="mb-3">
                      <InputReactSelect
                        isMulti={true}
                        label="Operators"
                        name="operators"
                        placeholder="Select Operators"
                        validation={validation}
                        value={validation.values.operators}
                        options={operatorListOptions}
                        onChange={(option) => {
                          const newValue = validateSelected(
                            option,
                            operatorList
                          );
                          validation.setFieldValue("operators", newValue);
                        }}
                      />
                    </FormGroup>
                  </Col>
                  {validation?.values.is_downline && (
                    <Col md="6">
                      <FormGroup className="mb-3">
                        <InputReactSelect
                          isMulti={true}
                          label="User Types"
                          name="user_types"
                          placeholder="Select User Types"
                          validation={validation}
                          value={validation.values.user_types}
                          options={userTypesOptions}
                          onChange={(option) => {
                            const newValue = validateSelected(
                              option,
                              userTypesOptions
                            );
                            validation.setFieldValue("user_types", newValue);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  )}
                </Row>
              </>
            )}
          </Col>

          <Col md="12">
            <hr />
            <p className="text-muted">Email Details:</p>
            <FormGroup className="mb-3">
              <InputText
                label="Subject"
                name="subject"
                placeholder="Subject"
                validation={validation}
              />
            </FormGroup>
          </Col>
          <Col md="12">
            <Label>Message</Label>
            <TextArea
              rows="10"
              cols="50"
              name="email_message"
              validation={validation}
            />
          </Col>
          <Col md="12">
            <hr />
            <p className="text-muted">Sms Details:</p>
            <Label>Message</Label>
            <TextArea
              rows="10"
              cols="50"
              maxLength="160"
              name="sms_message"
              validation={validation}
            />
            <p className="text-muted mt-2">Maximum of  160 characters.</p>

          </Col>
        </Row>
      </Col>
      <div className="border-top py-3 mt-3">
        <Button
          color="success"
          type="submit"
          className="float-end"
          text="Submit"
          isLoading={isLoading}
        />
      </div>
    </Form>
  );
};

export default SendAnnouncementForm;
