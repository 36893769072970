import { TextLink, Table, Title, Select } from "@/components/atoms";
import BreadCrumb from "@/components/common/BreadCrumb";
import { confirmation } from "@/components/constants/common";
import { Routes, getPath, getPathTitle } from "@/constants";
import { getNotificationTypeList } from "@/store/actions";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardHeader,
  Button,
} from "reactstrap";
import { AddNotificationTypeIconModal } from "@/components/molecules";

const List = () => {
  const [addNotificationTypeIconIsOpen, setAddNotificationTypeIconIsOpen] =
    useState(false);
  const [SelectedItem, setSelectedItem] = useState({});

  const dispatch = useDispatch();
  const { notificationTypeList, isLoading } = useSelector(
    (state) => state.announcement
  );

  const [search, setSearch] = useState("");

  const [sortBy, setSortBy] = useState({
    field: "",
    type: "asc",
  });

  const [data, setData] = useState({
    per_page: 10,
    page: 1,
    search: "",
  });

  const tableTheadData = [
    {
      label: "Name",
      field: "name",
      sortable: true,
      render: (item) => (
        <TextLink
          text={item.name}
          path={getPath(Routes.NOTIFICATION_LIST).replace(
            ":keyToken",
            item?.key_token
          )}
        />
      )
    },
    {
      label: "Key Name",
      field: "key_name",
      sortable: true,
    },
    {
      label: "Action",
      field: "action",
      sortable: false,
      render: (item) => (
        <Button
          onClick={() => {
            setAddNotificationTypeIconIsOpen(true);
            setSelectedItem(item);
          }}
          color="primary"
          size="sm"
        >
          <i className="ri-play-list-add-line"></i>
        </Button>
      ),
    },
  ];

  const initialize = () => {
    dispatch(getNotificationTypeList(data));
  };
  useEffect(() => {
    dispatch(getNotificationTypeList(data));
  }, [data, dispatch]);

  return (
    <React.Fragment>
      <Title title={getPathTitle(Routes.NOTIFICATION_TYPE_LIST)} />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            parent={{
              isParent: true,
              pageTitle: getPathTitle(Routes.NOTIFICATION_TYPE_LIST),
              pageLink: getPath(Routes.NOTIFICATION_TYPE_LIST),
            }}
          />
          <Row>
            <Col>
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    {getPathTitle(Routes.NOTIFICATION_TYPE_LIST)}
                  </h4>
                </CardHeader>
                <CardBody>
                  <Row className="mb-3">
                    <Col md={12}>
                      <Table
                        search={search}
                        handlerSearch={setSearch}
                        sortBy={sortBy}
                        handlerSortBy={setSortBy}
                        thead={tableTheadData}
                        handlerChangeData={setData}
                        data={data}
                        collection={notificationTypeList}
                        isLoading={isLoading}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {addNotificationTypeIconIsOpen && (
        <AddNotificationTypeIconModal
          isOpen={addNotificationTypeIconIsOpen}
          setIsOpen={setAddNotificationTypeIconIsOpen}
          data={SelectedItem}
          initialize={initialize}
        />
      )}
    </React.Fragment>
  );
};

export default List;
