import { Routes } from "@/constants";
import { getPermission, userRole } from "@/hooks";
import { getPath } from "@/utils";
import React, { useEffect, useState } from "react";

const Navdata = (userData) => {
    const { user } = userData;
    const [isDashboard, setIsDashboard] = useState(false);
    const [isBettingCreditFund, setIsBettingCreditFund] = useState(false);
    const [isOperators, setIsOperators] = useState(false);
    const [isCommunityManagers, setIsCommunityManagers] = useState(false);
    const [isSuperAgents, setIsSuperAgents] = useState(false);
    const [isAgents, setIsAgents] = useState(false);
    const [isAffiliates, setIsAffiliates] = useState(false);
    const [isPlayers, setIsPlayers] = useState(false);
    const [isUserManagement, setIsUserManagement] = useState(false);
    const [isActivityLogs, setIsActivityLogs] = useState(false);
    const [isReports, setIsReports] = useState(false);
    const [isSettings, setIsSettings] = useState(false);
    const [isApprovalRequest, setIsApprovalRequest] = useState(false);
    const [isTransactions, setIsTransactions] = useState(false);
    const [isCasino, setIsCasino] = useState(false);
    const [isBetTransactions, setIsBetTransactions] = useState(false);
    const [isTipTransactions, setIsTipTransactions] = useState(false);
    const [isCommissions, setIsCommissions] = useState(false);
    const [isWinLossReport, setIsWinLossReport] = useState(false);
    const [isCountries, setIsCountries] = useState(false);
    const [isGeneratedReport, setIsGeneratedReport] = useState(false);
    const [isPaymentSettings, setIsPaymentSettings] = useState(false);
    const [isHotMatchesSettings, setIsHotMatchesSettings] = useState(false);
    const [isUserIPLogs, setIsUserIPLogs] = useState(false);
    const [isPromotions, setIsPromotions] = useState(false);
    const [isAnnouncements, setIsAnnouncements] = useState(false);
    const [isLottoSettings, setIsLottoSettings] = useState(false);

    const [isCurrentState, setIsCurrentState] = useState("Dashboard");

    const { isSuperAdmin, isLicenseeAdmin, isAssistantAdmin, isAdmin } =
        userRole();

    function updateIconSidebar(e) {
        if (e && e.target && e.target.getAttribute("subitems")) {
            const ul = document.getElementById("two-column-menu");
            const iconItems = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                var id = item.getAttribute("subitems");
                if (document.getElementById(id))
                    document.getElementById(id).classList.remove("show");
            });
        }
    }

    useEffect(() => {
        document.body.classList.remove("twocolumn-panel");
        if (isCurrentState !== "Dashboard") {
            setIsDashboard(false);
        }
        if (isCurrentState !== "BettingCreditFund") {
            setIsBettingCreditFund(false);
        }
        if (isCurrentState !== "Operators") {
            setIsOperators(false);
        }
        if (isCurrentState !== "CommunityManagers") {
            setIsCommunityManagers(false);
        }
        if (isCurrentState !== "SuperAgents") {
            setIsSuperAgents(false);
        }
        if (isCurrentState !== "Agents") {
            setIsAgents(false);
        }
        if (isCurrentState !== "Affiliates") {
            setIsAffiliates(false);
        }
        if (isCurrentState !== "Players") {
            setIsPlayers(false);
        }
        if (isCurrentState !== "UserManagement") {
            setIsUserManagement(false);
        }
        if (isCurrentState !== "ActivityLogs") {
            setIsActivityLogs(false);
        }
        if (isCurrentState !== "Reports") {
            setIsReports(false);
        }
        if (isCurrentState !== "Settings") {
            setIsSettings(false);
        }
        if (isCurrentState !== "ApprovalRequest") {
            setIsApprovalRequest(false);
        }
        if (isCurrentState !== "Transactions") {
            setIsTransactions(false);
        }
        if (isCurrentState !== "Commissions") {
            setIsCommissions(false);
        }
        if (isCurrentState !== "Casino") {
            setIsCasino(false);
        }
        if (isCurrentState !== "Promotions") {
            setIsPromotions(false);
        }
        if (isCurrentState !== "Announcements") {
            setIsAnnouncements(false);
        }
        if (isCurrentState !== "LottoSettings") {
            setIsLottoSettings(false);
        }
    }, [isCurrentState]);

    const menuItems = [
        {
            label: "Menu",
            isHeader: true,
        },
        {
            isShow: isSuperAdmin,
            id: "dashboard",
            label: "Dashboard",
            icon: "ri-dashboard-line",
            link: getPath(Routes.DASHBOARD),
            stateVariables: isDashboard,
            click: function (e) {
                e.preventDefault();
                setIsDashboard(!isDashboard);
                setIsCurrentState("Dashboard");
                updateIconSidebar(e);
            },
        },
        {
            isShow: getPermission("master-agent"),
            id: "operators",
            label: "Operators",
            icon: "ri-team-fill",
            link: getPath(Routes.OPERATORS),
            stateVariables: isOperators,
            click: function (e) {
                e.preventDefault();
                setIsAgents(!isOperators);
                setIsCurrentState("Operators");
                updateIconSidebar(e);
            },
        },
        {
            isShow: getPermission("master-agent"),
            id: "community-managers",
            label: "Community Managers",
            icon: "ri-team-fill",
            link: getPath(Routes.COMMUNITY_MANAGERS),
            stateVariables: isCommunityManagers,
            click: function (e) {
                e.preventDefault();
                setIsCommunityManagers(!isCommunityManagers);
                setIsCurrentState("CommunityManagers");
                updateIconSidebar(e);
            },
        },
        {
            isShow: getPermission("master-agent"),
            id: "master-agents",
            label: "Master Agent",
            icon: "ri-team-fill",
            link: getPath(Routes.SUPER_AGENTS),
            stateVariables: isSuperAgents,
            click: function (e) {
                e.preventDefault();
                setIsAgents(!isSuperAgents);
                setIsCurrentState("isSuperAgents");
                updateIconSidebar(e);
            },
        },
        {
            isShow: getPermission("agent"),
            id: "agents",
            label: "Agents",
            icon: "ri-team-fill",
            link: getPath(Routes.AGENTS),
            stateVariables: isAgents,
            click: function (e) {
                e.preventDefault();
                setIsAgents(!isAgents);
                setIsCurrentState("Agents");
                updateIconSidebar(e);
            },
        },
        {
            isShow: getPermission("agent"),
            id: "affiliates",
            label: "Affiliates",
            icon: "ri-team-fill",
            link: getPath(Routes.AFFILIATES),
            stateVariables: isAffiliates,
            click: function (e) {
                e.preventDefault();
                setIsAffiliates(!isAffiliates);
                setIsCurrentState("Affiliates");
                updateIconSidebar(e);
            },
        },
        {
            isShow: getPermission("player"),
            id: "players",
            label: "Players",
            icon: "ri-team-fill",
            link: getPath(Routes.PLAYERS),
            stateVariables: isPlayers,
            click: function (e) {
                e.preventDefault();
                setIsAgents(!isPlayers);
                setIsCurrentState("Players");
                updateIconSidebar(e);
            },
        },
        {
            isShow: getPermission("betting-credit-fund"),
            id: "betting-credit-fund",
            label: "Betting Credit Fund",
            icon: "ri-hand-coin-line",
            link: getPath(Routes.BETTING_CREDIT_FUND),
            stateVariables: isBettingCreditFund,
            click: function (e) {
                e.preventDefault();
                setIsBettingCreditFund(!isBettingCreditFund);
                setIsCurrentState("BettingCreditFund");
                updateIconSidebar(e);
            },
        },

        {
            isShow:
                getPermission("deposit-request") ||
                getPermission("withdrawal-request") ||
                getPermission("player"),
            id: "approval-request",
            label: "Approval Request",
            icon: "ri-currency-line",
            link: "/#",
            stateVariables: isApprovalRequest,
            click: function (e) {
                e.preventDefault();
                setIsApprovalRequest(!isApprovalRequest);
                setIsCurrentState("ApprovalRequest");
                updateIconSidebar(e);
            },
            subItems: [
                {
                    isShow: isAdmin ? getPermission("player") : true,
                    id: "kyc",
                    label: "KYC",
                    link: getPath(Routes.KYC),
                    parentId: "approval-request",
                },
                {
                    isShow: isAdmin ? getPermission("deposit-request") : true,
                    id: "deposit-request",
                    label: "Deposit",
                    link: getPath(Routes.DEPOSIT_REQUEST),
                    parentId: "approval-request",
                },
                {
                    isShow: isAdmin
                        ? getPermission("withdrawal-request")
                        : true,
                    id: "withdrawal-request",
                    label: "Withdrawal",
                    link: getPath(Routes.WITHDRAWAL_REQUEST),
                    parentId: "approval-request",
                },
                {
                    isShow: isAdmin ? getPermission("for-release") : true,
                    id: "for-release",
                    label: "For Release",
                    link: getPath(Routes.FOR_RELEASE),
                    parentId: "approval-request",
                },
                {
                    isShow: isSuperAdmin || isLicenseeAdmin,
                    id: "for-payout-release",
                    label: "For Payout Release",
                    link: getPath(Routes.FOR_PAYOUT_RELEASE),
                    parentId: "approval-request",
                },
                {
                    isShow: isSuperAdmin,
                    id: "affiliate-request",
                    label: "MA Affiliate Request",
                    link: getPath(Routes.AFFILIATES_REQUEST_LIST),
                    parentId: "approval-request",
                },
            ],
        },
        {
            isShow: isAdmin ? getPermission("bet-transactions") : true,
            id: "bet-transactions",
            label: "Bet Transactions",
            icon: "ri-money-dollar-circle-line",
            link: "/#",
            stateVariables: isBetTransactions,
            click: function (e) {
                e.preventDefault();
                setIsBetTransactions(!isBetTransactions);
                setIsCurrentState("BetTransactions");
                updateIconSidebar(e);
            },
            subItems: [
                {
                    isShow: isAdmin
                        ? getPermission("e-games-transaction")
                        : true,
                    id: "e-games-transaction",
                    label: "E-Games",
                    link: getPath(Routes.EGAMES_TRANSACTION),
                    parentId: "bet-transactions",
                },
                {
                    isShow: isAdmin
                        ? getPermission("live-casino-transaction")
                        : true,
                    id: "live-casino-transaction",
                    label: "Live Casino",
                    link: getPath(Routes.LIVE_CASINO_TRANSACTION),
                    parentId: "bet-transactions",
                },
                {
                    isShow: isAdmin
                        ? getPermission("sports-betting-transaction")
                        : true,
                    id: "sports-betting-transaction",
                    label: "Sports Betting",
                    link: getPath(Routes.SPORTS_BETTING_TRANSACTION),
                    parentId: "bet-transactions",
                },
                {
                    isShow: isAdmin
                        ? getPermission("e-sports-transaction")
                        : true,
                    id: "e-sports-transaction",
                    label: "E-Sports",
                    link: getPath(Routes.ESPORTS_TRANSACTION),
                    parentId: "bet-transactions",
                },
                {
                    isShow:
                        isAdmin &&
                        process.env.REACT_APP_LOTTO_ENABLED === "true"
                            ? getPermission("lotto-transaction")
                            : process.env.REACT_APP_LOTTO_ENABLED === "true",
                    id: "lotto-transaction",
                    label: "Lotto",
                    link: getPath(Routes.LOTTO_TRANSACTION),
                    parentId: "bet-transactions",
                },
            ],
        },
        {
            isShow: isAdmin ? getPermission("commission-report") : true,
            id: "commissions",
            label: "Commissions",
            icon: "ri-file-list-3-line",
            link: "/#",
            stateVariables: isCommissions,
            click: function (e) {
                e.preventDefault();
                setIsCommissions(!isCommissions);
                setIsCurrentState("Commissions");
                updateIconSidebar(e);
            },
            subItems: [
                {
                    isShow: isAdmin ? getPermission("commission-report") : true,
                    id: "commissions-agents",
                    label: "Agents",
                    link: getPath(Routes.COMMISSIONS_AGENTS),
                    parentId: "commissions",
                },
                {
                    isShow: isAdmin ? getPermission("commission-report") : true,
                    id: "commissions-players",
                    label: "Players",
                    link: getPath(Routes.COMMISSIONS_PLAYERS),
                    parentId: "commissions",
                },
            ],
        },
        {
            isShow: isSuperAdmin && !isLicenseeAdmin,
            id: "tip-transactions",
            label: "Tip Transactions",
            icon: "ri-contacts-line",
            link: getPath(Routes.TIP_TRANSACTION),
            stateVariables: isTipTransactions,
            click: function (e) {
                e.preventDefault();
                setIsTipTransactions(!isTipTransactions);
                setIsCurrentState("TipTransactions");
                updateIconSidebar(e);
            },
        },
        {
            isShow: getPermission("transaction"),
            id: "transactions",
            label: "Wallet Transactions",
            icon: "ri-file-list-3-line",
            link: getPath(Routes.TRANSACTIONS),
            stateVariables: isTransactions,
            click: function (e) {
                e.preventDefault();
                setIsTransactions(!isTransactions);
                setIsCurrentState("Transactions");
                updateIconSidebar(e);
            },
        },
        {
            isShow: isSuperAdmin && !isLicenseeAdmin && !isAssistantAdmin,
            id: "promotions",
            label: "Promotions",
            icon: "ri-gift-2-line",
            link: "/#",
            stateVariables: isPromotions,
            click: function (e) {
                e.preventDefault();
                setIsPromotions(!isPromotions);
                setIsCurrentState("Promotions");
                updateIconSidebar(e);
            },
            subItems: [
                {
                    isShow:
                        isSuperAdmin && !isLicenseeAdmin && !isAssistantAdmin,
                    id: "promotions-categories",
                    label: "Categories",
                    link: getPath(Routes.PROMOTION_CATEGORIES),
                    parentId: "promotions",
                },
                {
                    isShow:
                        isSuperAdmin && !isLicenseeAdmin && !isAssistantAdmin,
                    id: "promotions-list",
                    label: "Promotion List",
                    link: getPath(Routes.PROMOTION_LIST),
                    parentId: "promotions",
                },
                {
                    isShow:
                        isSuperAdmin && !isLicenseeAdmin && !isAssistantAdmin,
                    id: "promotions-transaction-list",
                    label: "Transaction List",
                    link: getPath(Routes.PROMOTION_TRANSACTION_LIST),
                    parentId: "promotions",
                },
            ],
        },
        {
            isShow: isSuperAdmin && !isLicenseeAdmin && !isAssistantAdmin,
            id: "user-management",
            label: "User Management",
            icon: "ri-user-settings-line",
            link: "/#",
            stateVariables: isUserManagement,
            click: function (e) {
                e.preventDefault();
                setIsUserManagement(!isUserManagement);
                setIsCurrentState("UserManagement");
                updateIconSidebar(e);
            },
            subItems: [
                {
                    isShow:
                        isSuperAdmin && !isLicenseeAdmin && !isAssistantAdmin,
                    id: "users",
                    label: "Users",
                    link: getPath(Routes.MANAGE_USER),
                    parentId: "user-management",
                },
                {
                    isShow:
                        isSuperAdmin && !isLicenseeAdmin && !isAssistantAdmin,
                    id: "roles",
                    label: "Roles",
                    link: getPath(Routes.MANAGE_ROLE),
                    parentId: "user-management",
                },
            ],
        },
        {
            isShow: isAdmin
                ? getPermission("win-loss-by-agent-report") ||
                  getPermission("win-loss-by-product-report")
                : true,
            id: "reports",
            label: "Reports",
            icon: "ri-file-text-line",
            link: "/#",
            stateVariables: isReports,
            click: function (e) {
                e.preventDefault();
                setIsReports(!isReports);
                setIsCurrentState("Reports");
                updateIconSidebar(e);
            },
            subItems: [
                {
                    isShow: isAdmin
                        ? getPermission("generated-report")
                        : true && !isAssistantAdmin,
                    id: "generated-reports",
                    label: "Generated Reports",
                    link: getPath(Routes.GENERATED_REPORT),
                    stateVariables: isGeneratedReport,
                    click: function (e) {
                        e.preventDefault();
                        setIsGeneratedReport(!isGeneratedReport);
                        setIsCurrentState("Generated Reports");
                    },
                },
                {
                    isShow: isAdmin
                        ? getPermission("win-loss-by-agent-report")
                        : true,
                    id: "win-loss",
                    label: "Win/Loss",
                    link: "/#",
                    parentId: "reports",
                    stateVariables: isWinLossReport,
                    click: function (e) {
                        e.preventDefault();
                        setIsWinLossReport(!isWinLossReport);
                        setIsCurrentState("Reports");
                    },
                    subItems: [
                        {
                            isShow: isAdmin
                                ? getPermission("win-loss-by-agent-report")
                                : true,
                            id: "win-loss-by-agent-report",
                            label: "Win/Loss by Agent",
                            link: getPath(Routes.WIN_LOSS_BY_AGENT_REPORT),
                            parentId: "reports",
                        },
                        {
                            isShow: isAdmin
                                ? getPermission("win-loss-by-product-report")
                                : true,
                            id: "win-loss-by-product-report",
                            label: "Win/Loss by Product",
                            link: getPath(Routes.WIN_LOSS_BY_PRODUCT_REPORT),
                            parentId: "reports",
                        },
                    ],
                },
            ],
        },
        {
            isShow: (isSuperAdmin && !isAssistantAdmin) || isLicenseeAdmin,
            id: "casino",
            label: "Casino",
            icon: "ri-gamepad-line",
            link: "/#",
            stateVariables: isCasino,
            click: function (e) {
                e.preventDefault();
                setIsCasino(!isCasino);
                setIsCurrentState("Casino");
                updateIconSidebar(e);
            },
            subItems: [
                {
                    isShow:
                        (isSuperAdmin && !isAssistantAdmin) || isLicenseeAdmin,
                    id: "game-provider",
                    label: "Game Provider",
                    link: getPath(Routes.CASINO_GAME_PROVIDER),
                    parentId: "casino",
                },
                {
                    isShow:
                        (isSuperAdmin && !isAssistantAdmin) || isLicenseeAdmin,
                    id: "game-type",
                    label: "Game Type",
                    link: getPath(Routes.CASINO_GAME_TYPE),
                    parentId: "casino",
                },
                {
                    isShow:
                        (isSuperAdmin && !isAssistantAdmin) || isLicenseeAdmin,
                    id: "game-list",
                    label: "Game List",
                    link: getPath(Routes.CASINO_GAME_LIST),
                    parentId: "casino",
                },
            ],
        },
        {
            isShow: isSuperAdmin && !isAssistantAdmin,
            id: "countries",
            label: "Countries",
            icon: "las la-globe",
            link: getPath(Routes.COUNTRIES),
            stateVariables: isCountries,
            click: function (e) {
                e.preventDefault();
                setIsCountries(!isCountries);
                setIsCurrentState("Countries");
                updateIconSidebar(e);
            },
        },
        {
            isShow: isSuperAdmin,
            id: "user-ip-logs",
            label: "User IP Logs",
            icon: "las la-globe",
            link: "/#",
            stateVariables: isUserIPLogs,
            click: function (e) {
                e.preventDefault();
                setIsUserIPLogs(!isUserIPLogs);
                setIsCurrentState("UserIPLogs");
                updateIconSidebar(e);
            },
            subItems: [
                {
                    isShow: isSuperAdmin,
                    id: "login-ips",
                    label: "Login IPs",
                    link: getPath(Routes.LOGIN_IPS),
                    parentId: "user-ip-logs",
                },
                {
                    isShow: isSuperAdmin,
                    id: "multiple-ip-detected",
                    label: "Multiple IP Detected",
                    link: getPath(Routes.MULTIPLE_IP_DETECTED),
                    parentId: "user-ip-logs",
                },
            ],
        },
        {
            isShow: isSuperAdmin && !isLicenseeAdmin && !isAssistantAdmin,
            id: "payment-settings",
            label: "Payment Settings",
            icon: "mdi mdi-transit-connection-variant",
            link: "/#",
            stateVariables: isPaymentSettings,
            click: function (e) {
                e.preventDefault();
                setIsPaymentSettings(!isPaymentSettings);
                setIsCurrentState("PaymentSettings");
                updateIconSidebar(e);
            },
            subItems: [
                {
                    isShow:
                        isSuperAdmin && !isLicenseeAdmin && !isAssistantAdmin,
                    id: "payment-settings-general",
                    label: "General",
                    link: getPath(Routes.PAYMENT_SETTINGS_GENERAL),
                    parentId: "payment-settings",
                },
                {
                    isShow:
                        isSuperAdmin && !isLicenseeAdmin && !isAssistantAdmin,
                    id: "payment-settings-h2pay",
                    label: "H2Pay",
                    link: getPath(Routes.PAYMENT_SETTINGS_H2PAY),
                    parentId: "payment-settings",
                },
                {
                    isShow:
                        isSuperAdmin && !isLicenseeAdmin && !isAssistantAdmin,
                    id: "payment-settings-monetix",
                    label: "Monetix",
                    link: getPath(Routes.PAYMENT_SETTINGS_MONETIX),
                    parentId: "payment-settings",
                },
                {
                    isShow:
                        isSuperAdmin && !isLicenseeAdmin && !isAssistantAdmin,
                    id: "payment-settings-swift-pay",
                    label: "Swift Pay",
                    link: getPath(Routes.PAYMENT_SETTINGS_SWIFTPAY),
                    parentId: "payment-settings",
                },
                {
                    isShow:
                        isSuperAdmin &&
                        !isLicenseeAdmin &&
                        !isAssistantAdmin &&
                        process.env.REACT_APP_ENV !== "production",
                    id: "payment-settings-ak-pay",
                    label: "Ak Pay",
                    link: getPath(Routes.PAYMENT_SETTINGS_AK_PAY),
                    parentId: "payment-settings",
                },
                {
                    isShow:
                        isSuperAdmin &&
                        !isLicenseeAdmin &&
                        !isAssistantAdmin,
                    id: "payment-settings-coinspaid",
                    label: "Coins Paid",
                    link: getPath(Routes.PAYMENT_SETTINGS_COINSPAID),
                    parentId: "payment-settings",
                },

                
                {
                    isShow:
                        isSuperAdmin && !isLicenseeAdmin && !isAssistantAdmin,
                    id: "payment-settings-payment-methods",
                    label: "Payment Providers",
                    link: getPath(Routes.PAYMENT_SETTINGS_PAYMENT_PROVIDERS),
                    parentId: "payment-settings",
                },
            ],
        },

        {
            isShow: isSuperAdmin && !isLicenseeAdmin && !isAssistantAdmin,
            id: "hot-matches-settings",
            label: "Hot Matches Settings",
            icon: "ri-settings-2-line",
            link: "/#",
            stateVariables: isHotMatchesSettings,
            click: function (e) {
                e.preventDefault();
                setIsHotMatchesSettings(!isHotMatchesSettings);
                setIsCurrentState("HotMatchesSettings");
                updateIconSidebar(e);
            },
            subItems: [
                {
                    isShow:
                        isSuperAdmin && !isLicenseeAdmin && !isAssistantAdmin,
                    id: "hot-matches-settings-sport-type",
                    label: "Sport Type",
                    link: getPath(Routes.HOT_MATCHES_SETTINGS_SPORT_TYPE),
                    parentId: "hot-matches-settings",
                },
                {
                    isShow:
                        isSuperAdmin && !isLicenseeAdmin && !isAssistantAdmin,
                    id: "hot-matches-settings-league-type",
                    label: "League Type",
                    link: getPath(Routes.HOT_MATCHES_SETTINGS_LEAGUE_TYPE),
                    parentId: "hot-matches-settings",
                },
                {
                    isShow:
                        isSuperAdmin && !isLicenseeAdmin && !isAssistantAdmin,
                    id: "hot-matches-settings-game-type",
                    label: "Game Type",
                    link: getPath(Routes.HOT_MATCHES_SETTINGS_GAME_TYPE),
                    parentId: "hot-matches-settings",
                },
                {
                    isShow:
                        isSuperAdmin && !isLicenseeAdmin && !isAssistantAdmin,
                    id: "hot-matches-settings-team",
                    label: "Team",
                    link: getPath(Routes.HOT_MATCHES_SETTINGS_TEAM),
                    parentId: "hot-matches-settings",
                },
                {
                    isShow:
                        isSuperAdmin && !isLicenseeAdmin && !isAssistantAdmin,
                    id: "hot-matches-settings-hot-matches",
                    label: "Hot Matches",
                    link: getPath(Routes.HOT_MATCHES_SETTINGS_HOT_MATCHES),
                    parentId: "hot-matches-settings",
                },
            ],
        },

        {
            isShow: isSuperAdmin && !isLicenseeAdmin && !isAssistantAdmin,
            id: "announcements",
            label: "Announcements",
            icon: "bx bx-broadcast",
            link: "/#",
            stateVariables: isAnnouncements,
            click: function (e) {
                e.preventDefault();
                setIsAnnouncements(!isAnnouncements);
                setIsCurrentState("Announcements");
                updateIconSidebar(e);
            },
            subItems: [
                {
                    isShow:
                        isSuperAdmin && !isLicenseeAdmin && !isAssistantAdmin,
                    id: "announcements-send",
                    label: "Send Announcements",
                    link: getPath(Routes.SEND_ANNOUNCEMENT),
                    parentId: "announcements",
                },
                {
                    isShow:
                        isSuperAdmin && !isLicenseeAdmin && !isAssistantAdmin,
                    id: "message-list",
                    label: "Message List",
                    link: getPath(Routes.MESSAGE_LIST),
                    parentId: "announcements",
                },
                {
                    isShow:
                        isSuperAdmin && !isLicenseeAdmin && !isAssistantAdmin,
                    id: "notification-type-list",
                    label: "Notification Type List",
                    link: getPath(Routes.NOTIFICATION_TYPE_LIST),
                    parentId: "announcements",
                },
            ],
        },

        {
            isShow:
                process.env.REACT_APP_LOTTO_ENABLED === "true" &&
                isSuperAdmin &&
                !isLicenseeAdmin &&
                !isAssistantAdmin,
            id: "lotto-settings",
            label: "Lotto Settings",
            icon: "ri-settings-2-line",
            link: "/#",
            stateVariables: isLottoSettings,
            click: function (e) {
                e.preventDefault();
                setIsLottoSettings(!isLottoSettings);
                setIsCurrentState("LottoSettings");
                updateIconSidebar(e);
            },
            subItems: [
                {
                    isShow:
                        isSuperAdmin && !isLicenseeAdmin && !isAssistantAdmin,
                    id: "draw-schedule-list",
                    label: "Draw Schedule List",
                    link: getPath(Routes.DRAW_SCHEDULE_LIST),
                    parentId: "lotto-settings",
                },
            ],
        },

        {
            isShow: isSuperAdmin && !isLicenseeAdmin && !isAssistantAdmin,
            id: "settings",
            label: "Settings",
            icon: "ri-settings-2-line",
            link: getPath(Routes.SETTINGS),
            stateVariables: isSettings,
            click: function (e) {
                e.preventDefault();
                setIsSettings(!isSettings);
                setIsCurrentState("Settings");
                updateIconSidebar(e);
            },
        },
        {
            isShow: isSuperAdmin && !isLicenseeAdmin && !isAssistantAdmin,
            id: "activity-logs",
            label: "Activity Logs",
            icon: "ri-contacts-line",
            link: getPath(Routes.ACTIVITY_LOGS),
            stateVariables: isActivityLogs,
            click: function (e) {
                e.preventDefault();
                setIsActivityLogs(!isActivityLogs);
                setIsCurrentState("ActivityLogs");
                updateIconSidebar(e);
            },
        },
    ];

    const userSidebarMenuAccess = {
        "super-admin": [
            "dashboard",
            "operators",
            "community-managers",
            "master-agents",
            "agents",
            "affiliates",
            "players",
            "activity-logs",
            "user-management",
            "reports",
            "approval-request",
            "betting-credit-fund",
            "transactions",
            "casino",
            "settings",
            "commissions",
        ],
        "licensee-admin": [
            "dashboard",
            "operators",
            "master-agents",
            "agents",
            "players",
            "reports",
            "approval-request",
            "betting-credit-fund",
            "transactions",
            "casino",
            "commissions",
        ],
        "assistant-admin": [
            "dashboard",
            "operators",
            "master-agents",
            "agents",
            "players",
            "reports",
            "approval-request",
            "betting-credit-fund",
            "transactions",
            "commissions",
        ],
        admin: [
            "dashboard",
            "operators",
            "community-managers",
            "master-agents",
            "agents",
            "affiliates",
            "players",
            "activity-logs",
            "reports",
            "approval-request",
            "transactions",
            "commissions",
        ],
        operator: [
            "dashboard",
            "master-agents",
            "agents",
            "affiliates",
            "players",
            "activity-logs",
            "reports",
            "approval-request",
            "betting-credit-fund",
            "transactions",
            "commissions",
        ],
        "master-agent": [
            "dashboard",
            "agents",
            "affiliates",
            "players",
            "activity-logs",
            "reports",
            "approval-request",
            "transactions",
            "commissions",
        ],
        agent: [
            "dashboard",
            "players",
            "activity-logs",
            "reports",
            "approval-request",
            "transactions",
            "commissions",
        ],
    };

    const filteredMenuItems = menuItems.filter((menuItem) => {
        return (
            userSidebarMenuAccess[
                isLicenseeAdmin
                    ? "licensee-admin"
                    : isAssistantAdmin
                    ? "assistant-admin"
                    : user.role.slug
            ].includes(menuItem.id) || menuItem.isShow
        );
    });

    return <React.Fragment>{filteredMenuItems}</React.Fragment>;
};

export default Navdata;
