import React, { useEffect } from "react";
//i18n
import { Link } from "react-router-dom";
import { Collapse } from "reactstrap";
// Import Data
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import navdata from "./LayoutMenuData";

const CollapsibleMenuItem = ({ item }) => {
    return (
        <li className="nav-item">
            <Link
                onClick={item.click}
                className="nav-link menu-link"
                to={item.link ? item.link : "/#"}
                data-bs-toggle="collapse"
            >
                {item.icon && <i className={item.icon}></i>}
                <span data-key="t-apps">{item.label}</span>
                {item.badgeName ? (
                    <span
                        className={"badge badge-pill bg-" + item.badgeColor}
                        data-key="t-new"
                    >
                        {item.badgeName}
                    </span>
                ) : null}
            </Link>
            <Collapse
                className="menu-dropdown"
                isOpen={item.stateVariables}
                id="sidebarApps"
            >
                <ul className="nav nav-sm flex-column test">
                    {/* subItms  */}
                    {item.subItems &&
                        (item.subItems || []).map(
                            (subItem, key) =>
                                subItem.isShow && (
                                    <React.Fragment key={key}>
                                        {subItem.subItems ? (
                                            <CollapsibleMenuItem
                                                item={subItem}
                                            />
                                        ) : (
                                            <li className="nav-item">
                                                <Link
                                                    to={
                                                        subItem.link
                                                            ? subItem.link
                                                            : "/#"
                                                    }
                                                    className="nav-link"
                                                >
                                                    {subItem.label}{" "}
                                                </Link>
                                            </li>
                                        )}
                                    </React.Fragment>
                                )
                        )}
                </ul>
            </Collapse>
        </li>
    );
};

const SidebarContent = (props) => {
    const location = useLocation();
    const userData = useSelector((state) => state.user);
    const navData = navdata(userData).props.children;
    const layoutType = useSelector((state) => state.layout.layoutType);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        const initMenu = () => {
            const ul = document.getElementById("navbar-nav");
            const items = ul.getElementsByTagName("a");
            let itemsArray = [...items]; // converts NodeList to Array
            removeActivation(itemsArray);
            let matchingMenuItem = itemsArray.find((x) => {
                let pathNameSplit = location.pathname.split("/");
                let pathName = "/" + pathNameSplit[1];
                let xPathNameSplit = x.pathname.split("/");
                let xPathName = "/" + xPathNameSplit[1];

                if (
                    xPathName.includes("user-management") ||
                    xPathName.includes("promotions") ||
                    xPathName.includes("reports") ||
                    xPathName.includes("bet-transactions") ||
                    xPathName.includes("tip-transactions") ||
                    xPathName.includes("casino") ||
                    xPathName.includes("approval-request") ||
                    xPathName.includes("user-ip-logs") ||
                    xPathName.includes("payment-settings") ||
                    xPathName.includes("hot-matches-settings") ||
                    xPathName.includes("announcements") ||
                    xPathName.includes("commissions") ||
                    xPathName.includes("lotto-settings") ||
                    xPathName.includes("affiliates")
                ) {
                    return x.pathname === location.pathname;
                }

                document.body.classList.remove("vertical-sidebar-enable");
                return x.pathname === pathName;
            });
            if (matchingMenuItem) {
                activateParentDropdown(matchingMenuItem);
            }
        };
        if (layoutType === "vertical") {
            initMenu();
        }
    }, [location.pathname, layoutType]);

    function activateParentDropdown(item) {
        item.classList.add("active");
        let parentCollapseDiv = item.closest(".collapse.menu-dropdown");

        if (parentCollapseDiv) {
            // to set aria expand true remaining
            parentCollapseDiv.classList.add("show");
            parentCollapseDiv.parentElement.children[0].classList.add("active");
            parentCollapseDiv.parentElement.children[0].setAttribute(
                "aria-expanded",
                "true"
            );
            if (
                parentCollapseDiv.parentElement.closest(
                    ".collapse.menu-dropdown"
                )
            ) {
                parentCollapseDiv.parentElement
                    .closest(".collapse")
                    .classList.add("show");
                if (
                    parentCollapseDiv.parentElement.closest(".collapse")
                        .previousElementSibling
                )
                    parentCollapseDiv.parentElement
                        .closest(".collapse")
                        .previousElementSibling.classList.add("active");
            }
            return false;
        }
        return false;
    }

    const removeActivation = (items) => {
        let actiItems = items.filter((x) => x.classList.contains("active"));
        actiItems.forEach((item) => {
            if (item.classList.contains("menu-link")) {
                if (!item.classList.contains("active")) {
                    item.setAttribute("aria-expanded", false);
                }
                if (item.nextElementSibling) {
                    item.nextElementSibling.classList.remove("show");
                }
            }
            if (item.classList.contains("nav-link")) {
                if (item.nextElementSibling) {
                    item.nextElementSibling.classList.remove("show");
                }
                item.setAttribute("aria-expanded", false);
            }
            item.classList.remove("active");
        });
    };

    return (
        <React.Fragment>
            {/* menu Items */}
            {(navData || []).map((item, key) => {
                return (
                    <React.Fragment key={key}>
                        {/* Main Header */}
                        {item["isHeader"] ? (
                            <li className="menu-title">
                                <span data-key="t-menu">{item.label} 23</span>
                            </li>
                        ) : item.subItems ? (
                            <CollapsibleMenuItem item={item} />
                        ) : (
                            <li className="nav-item">
                                <Link
                                    className="nav-link menu-link"
                                    to={item.link ? item.link : "/#"}
                                >
                                    <i className={item.icon}></i>{" "}
                                    <span>{item.label}</span>
                                    {item.badgeName ? (
                                        <span
                                            className={
                                                "badge badge-pill bg-" +
                                                item.badgeColor
                                            }
                                            data-key="t-new"
                                        >
                                            {item.badgeName}
                                        </span>
                                    ) : null}
                                </Link>
                            </li>
                        )}
                    </React.Fragment>
                );
            })}
        </React.Fragment>
    );
};

export default SidebarContent;
