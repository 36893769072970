import {
    AmountFormat,
    CounterWidget,
    Select,
    StatusBadge,
    Table,
    Title,
} from "@/components/atoms";
import BreadCrumb from "@/components/common/BreadCrumb";
import {
    Routes,
    getPath,
    getPathTitle,
    userCounter,
    userStatus,
    roleTag,
} from "@/constants";
import { userRole } from "@/hooks";
import { getAgentList, getUserCountData } from "@/store/actions";
import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row,
} from "reactstrap";

const AffiliateList = () => {
    const dispatch = useDispatch();
    const { user, userCount, settingList } = useSelector((state) => state.user);
    const { list, isLoading } = useSelector((state) => state.agent);

    const [search, setSearch] = useState("");
    const [sortBy, setSortBy] = useState({
        field: "",
        type: "asc",
    });

    const userType = "agent";

    const [data, setData] = useState({
        key_token: user.keyToken,
        user_type: "agent",
        per_page: 10,
        page: 1,
        search: "",
        status: "",
        sort_by:
            sortBy.field && sortBy.type ? `${sortBy.field} ${sortBy.type}` : "",
        role_tag: roleTag.AFFILIATE,
    });

    const tableTheadData = useMemo(() => {
        const seperatedCommsSetting = settingList.filter(
            (item) => item.key === "separate_sports_betting_commission"
        );

        let pendingCommissions = [
            {
                label: `Pending Commission ${process.env.REACT_APP_BCF_LABEL}`,
                field: "user_profile_balance_pending_commission_bcf.amount",
                sortable: true,
                render: (item) => (
                    <AmountFormat
                        amount={
                            item?.user_profile?.balance?.fiat?.php
                                ?.pending_commission_bcf
                        }
                    />
                ),
            },
            {
                label: "Pending Commission PHP",
                field: "user_profile_balance_pending_commission_merchant.amount",
                sortable: true,
                render: (item) => (
                    <AmountFormat
                        amount={
                            item?.user_profile?.balance?.fiat?.php
                                ?.pending_commission_merchant
                        }
                    />
                ),
            },
            {
                label: `Pending Commission ${process.env.REACT_APP_BCF_LABEL} (Sports/E-Sports/Live Casino)`,
                field: "user_profile_balance_pending_commission_bcf_sports.amount",
                sortable: true,
                render: (item) => (
                    <AmountFormat
                        amount={
                            item?.user_profile?.balance?.fiat?.php
                                ?.pending_commission_bcf_sports
                        }
                    />
                ),
            },
            {
                label: "Pending Commission PHP (Sports/E-Sports/Live Casino)",
                field: "user_profile_balance_pending_commission_merchant_sports.amount",
                sortable: true,
                render: (item) => (
                    <AmountFormat
                        amount={
                            item?.user_profile?.balance?.fiat?.php
                                ?.pending_commission_merchant_sports
                        }
                    />
                ),
            },
            ...(process.env.REACT_APP_LOTTO_ENABLED === "true"
                ? [
                      {
                          label: `Pending Commission ${process.env.REACT_APP_BCF_LABEL} (Lotto)`,
                          field: "user_profile_balance_pending_commission_bcf_lotto.amount",
                          sortable: true,
                          render: (item) => (
                              <AmountFormat
                                  amount={
                                      item?.user_profile?.balance?.fiat?.php
                                          ?.pending_commission_bcf_lotto
                                  }
                              />
                          ),
                      },
                      {
                          label: `Pending Commission ${process.env.REACT_APP_DEFAULT_CURRENCY} (Lotto)`,
                          field: "user_profile_balance_pending_commission_merchant_lotto.amount",
                          sortable: true,
                          render: (item) => (
                              <AmountFormat
                                  amount={
                                      item?.user_profile?.balance?.fiat?.php
                                          ?.pending_commission_merchant_lotto
                                  }
                              />
                          ),
                      },
                  ]
                : []),
        ];

        // if ((seperatedCommsSetting[0]?.value ?? 0) === "1") {
        //     pendingCommissions[0][
        //         "label"
        //     ] = `Pending Commission ${process.env.REACT_APP_BCF_LABEL} (E-Games)`;
        //     pendingCommissions[1]["label"] = "Pending Commission PHP (E-Games)";

        //     pendingCommissions = [
        //         ...pendingCommissions,
        //         {
        //             label: `Pending Commission ${process.env.REACT_APP_BCF_LABEL} (Sports/E-Sports/Live Casino)`,
        //             field: "user_profile_balance_pending_commission_bcf_sports.amount",
        //             sortable: true,
        //             render: (item) => (
        //                 <AmountFormat
        //                     amount={
        //                         item?.user_profile?.balance?.fiat?.php
        //                             ?.pending_commission_bcf_sports
        //                     }
        //                 />
        //             ),
        //         },
        //         {
        //             label: "Pending Commission PHP (Sports/E-Sports/Live Casino)",
        //             field: "user_profile_balance_pending_commission_merchant_sports.amount",
        //             sortable: true,
        //             render: (item) => (
        //                 <AmountFormat
        //                     amount={
        //                         item?.user_profile?.balance?.fiat?.php
        //                             ?.pending_commission_merchant_sports
        //                     }
        //                 />
        //             ),
        //         },
        //     ];
        // }

        return [
            {
                label: "Account No.",
                field: "account_no",
                sortable: true,
                render: (item) => item?.user_profile?.account_no,
            },
            {
                label: "First Name",
                field: "first_name",
                sortable: true,
                render: (item) => item?.user_profile?.first_name,
            },
            {
                label: "Last Name",
                field: "last_name",
                sortable: true,
                render: (item) => item?.user_profile?.last_name,
            },
            {
                label: "Email",
                field: "email",
                sortable: true,
            },
            {
                label: "Contact No",
                field: "mobile_no",
                sortable: true,
                render: (item) => item?.mobile_no || "-",
            },
            ...pendingCommissions,
            {
                label: `Commission ${process.env.REACT_APP_BCF_LABEL}`,
                field: "user_profile_balance_commission_bcf.amount",
                sortable: true,
                render: (item) => (
                    <AmountFormat
                        amount={
                            item?.user_profile?.balance?.fiat?.php
                                ?.commission_bcf
                        }
                    />
                ),
            },
            {
                label: "Commission PHP",
                field: "user_profile_balance_commission_merchant.amount",
                sortable: true,
                render: (item) => (
                    <AmountFormat
                        amount={
                            item?.user_profile?.balance?.fiat?.php
                                ?.commission_merchant
                        }
                    />
                ),
            },
            {
                label: `Commission ${process.env.REACT_APP_BCF_LABEL} (Sports/E-Sports/Live Casino)`,
                field: "user_profile_balance_commission_bcf_sports.amount",
                sortable: true,
                render: (item) => (
                    <AmountFormat
                        amount={
                            item?.user_profile?.balance?.fiat?.php
                                ?.commission_bcf_sports
                        }
                    />
                ),
            },
            {
                label: "Commission Php (Sports/E-Sports/Live Casino)",
                field: "user_profile_balance_commission_merchant_sports.amount",
                sortable: true,
                render: (item) => (
                    <AmountFormat
                        amount={
                            item?.user_profile?.balance?.fiat?.php
                                ?.commission_merchant_sports
                        }
                    />
                ),
            },
            ...(process.env.REACT_APP_LOTTO_ENABLED === "true"
                ? [
                      {
                          label: `Commission ${process.env.REACT_APP_BCF_LABEL} (Lotto)`,
                          field: "user_profile_balance_commission_bcf_lotto.amount",
                          sortable: true,
                          render: (item) => (
                              <AmountFormat
                                  amount={
                                      item?.user_profile?.balance?.fiat?.php
                                          ?.commission_bcf_lotto
                                  }
                              />
                          ),
                      },
                      {
                          label: `Commission ${process.env.REACT_APP_DEFAULT_CURRENCY} (Lotto)`,
                          field: "user_profile_balance_commission_merchant_lotto.amount",
                          sortable: true,
                          render: (item) => (
                              <AmountFormat
                                  amount={
                                      item?.user_profile?.balance?.fiat?.php
                                          ?.commission_merchant_lotto
                                  }
                              />
                          ),
                      },
                  ]
                : []),
            {
                label: process.env.REACT_APP_BCF_LABEL,
                field: process.env.REACT_APP_BCF_LABEL,
                sortable: true,
                render: (item) => (
                    <AmountFormat
                        amount={item?.user_profile?.balance?.fiat?.php?.bcf}
                    />
                ),
            },
            {
                label: "Php",
                field: "user_profile_balance_merchant.amount",
                sortable: true,
                render: (item) => (
                    <AmountFormat
                        amount={
                            item?.user_profile?.balance?.fiat?.php?.merchant
                        }
                    />
                ),
            },
            ...(process.env.REACT_APP_LOTTO_ENABLED === "true"
                ? [
                      {
                          label: `${process.env.REACT_APP_BCF_LABEL} (Lotto)`,
                          field: "user_profile_balance_bcf_lotto.amount",
                          sortable: true,
                          render: (item) => (
                              <AmountFormat
                                  amount={
                                      item?.user_profile?.balance?.fiat?.php
                                          ?.bcf_lotto
                                  }
                              />
                          ),
                      },
                      {
                          label: `${process.env.REACT_APP_DEFAULT_CURRENCY} (Lotto)`,
                          field: "user_profile_balance_merchant_lotto.amount",
                          sortable: true,
                          render: (item) => (
                              <AmountFormat
                                  amount={
                                      item?.user_profile?.balance?.fiat?.php
                                          ?.merchant_lotto
                                  }
                              />
                          ),
                      },
                  ]
                : []),
            {
                label: "Status",
                field: "status",
                sortable: false,
                render: (item) => <StatusBadge status={item.status} />,
            },
            {
                label: "Action",
                field: "action",
                sortable: false,
                render: (item) => (
                    <Link
                        to={getPath(Routes.AFFILIATE_PROFILE).replace(
                            ":accountNo",
                            item?.user_profile?.account_no
                        )}
                        className="link-primary"
                    >
                        <Button color="success" size="sm">
                            <i className="ri-eye-line"></i>
                        </Button>
                    </Link>
                ),
            },
        ];
    }, [settingList]);

    const userCounterData = userCounter(userCount, userType);

    useEffect(() => {
        dispatch(getAgentList(data));
        dispatch(
            getUserCountData({
                user_type: [userType],
                role_tag: roleTag.AFFILIATE,
            })
        );
    }, [dispatch, data, userType]);

    return (
        <React.Fragment>
            <Title title={getPathTitle(Routes.AFFILIATES)} />
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb
                        parent={{
                            isParent: true,
                            pageTitle: getPathTitle(Routes.AFFILIATES),
                            pageLink: getPath(Routes.AFFILIATES),
                        }}
                    />
                    <Row>
                        {userCounterData.map((item, key) => (
                            <Col md={3} key={key}>
                                <CounterWidget item={item} />
                            </Col>
                        ))}
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardHeader className="align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">
                                        {getPathTitle(Routes.AFFILIATES)} List
                                    </h4>
                                    {userRole()?.isMasterAgent ? (
                                        <div className="flex-shrink-0">
                                            <Link
                                                to={getPath(
                                                    Routes.AFFILIATE_CREATE
                                                )}
                                            >
                                                <Button
                                                    color="success"
                                                    size="sm"
                                                >
                                                    <i className="ri-add-fill me-1 align-bottom"></i>
                                                    {getPathTitle(
                                                        Routes.AFFILIATE_CREATE
                                                    )}
                                                </Button>
                                            </Link>
                                        </div>
                                    ) : null}
                                </CardHeader>
                                <CardBody>
                                    <Row className="mb-3">
                                        <Col md={6}>
                                            <Select
                                                label="Status"
                                                isClearable={true}
                                                value={data.status}
                                                options={[
                                                    ...[
                                                        {
                                                            label: "All",
                                                            value: "",
                                                        },
                                                    ],
                                                    ...userStatus,
                                                ]}
                                                onChange={(event) =>
                                                    setData({
                                                        ...data,
                                                        status: event.target
                                                            .value,
                                                        page: 1,
                                                    })
                                                }
                                            />
                                        </Col>
                                    </Row>
                                    <Table
                                        search={search}
                                        handlerSearch={setSearch}
                                        sortBy={sortBy}
                                        handlerSortBy={setSortBy}
                                        thead={tableTheadData}
                                        handlerChangeData={setData}
                                        data={data}
                                        collection={list}
                                        isLoading={isLoading}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AffiliateList;
