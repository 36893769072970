import React from "react";
import { Input, Label } from "reactstrap";

const Checkbox = ({ label, color = "success", validation, id, ...rest }) => {
    return (
        <div className={`form-check form-check-${color}`}>
            <Input
                className="form-check-input"
                type="checkbox"
                id={id}
                {...rest}
            />
            <Label className="form-check-label" for={id}>
                {label}
            </Label>
        </div>
    );
};

export default Checkbox;
