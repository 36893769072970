import { CreateAgentForm } from "@/components/molecules";
import React from "react";
import {
    Button,
    Card,
    CardBody,
    Modal,
    ModalBody,
    ModalHeader,
} from "reactstrap";

const AffiliateRequestApproveModal = (props) => {
    const { isOpen, setIsOpen } = props.data;

    return (
        <Modal
            id="affiliateApproveRequestModal"
            isOpen={isOpen}
            toggle={() => setIsOpen(!isOpen)}
            size="xl"
        >
            <ModalHeader>
                <div>Please Fill up all fields</div>
            </ModalHeader>
            <ModalBody>
                <Card>
                    <CardBody>
                        <CreateAgentForm
                            userType="master_agent"
                            roleTag="affiliate"
                            requestFrom="affiliate-request"
                            data={props.data}
                        />
                    </CardBody>
                </Card>
                <Button
                    color="light"
                    className="float-end"
                    onClick={() => setIsOpen(false)}
                >
                    Close
                </Button>
            </ModalBody>
        </Modal>
    );
};

export default AffiliateRequestApproveModal;
