import { Button, InputText, Title, InputPassword } from "@/components/atoms";
import {
    getPath,
    getPathTitle,
    Routes,
    resetPasswordValidation,
} from "@/constants";
import { Metabets } from "@/resources/images";
import {
    userResetPasswordValidateToken,
    userResetPassword,
} from "@/store/actions";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Link,
    useNavigate,
    useParams,
    useSearchParams,
} from "react-router-dom";
import { Alert, Card, CardBody, Col, Container, Form, Row } from "reactstrap";

const ResetPassword = () => {
    const { token } = useParams();
    const { errors, isLoading } = useSelector((state) => state.user);
    const [searchParams] = useSearchParams();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState({
        new: false,
        confirm: false,
    });

    const showPasswordHandler = (field) => {
        setShowPassword({
            ...showPassword,
            [field]: !showPassword[field],
        });
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: searchParams.get("email"),
            new_password: "",
            confirm_password: "",
        },
        validationSchema: resetPasswordValidation,
        onSubmit: (values) => {
            dispatch(userResetPassword(values, token, navigate));
        },
    });

    useEffect(() => {
        dispatch(
            userResetPasswordValidateToken(
                { email: searchParams.get("email") },
                token
            )
        );
    }, [dispatch, searchParams, token]);

    return (
        <React.Fragment>
            <Title title={getPathTitle(Routes.RESET_PASSWORD)} />
            <div className="auth-page-content">
                <Container>
                    <Row className="justify-content-center">
                        <Col
                            md={8}
                            lg={6}
                            xl={5}
                            className="tw-h-screen tw-flex tw-flex-col tw-justify-center tw-items-center"
                        >
                            <div>
                                <Card className="tw-min-w-full md:tw-min-w-[400px]">
                                    <CardBody className="p-4">
                                        <div className="text-center">
                                            <img
                                                src={Metabets}
                                                className="tw-w-32 mb-4"
                                                alt={process.env.REACT_APP_NAME}
                                            />
                                            <h5 className="text-primary">
                                                {getPathTitle(
                                                    Routes.RESET_PASSWORD
                                                )}
                                            </h5>
                                            <p className="text-muted">
                                                Reset password with{" "}
                                                {process.env.REACT_APP_NAME}
                                            </p>
                                        </div>
                                        {!errors.length > 0 && (
                                            <div className="p-2 mt-4">
                                                <Form
                                                    onSubmit={(e) => {
                                                        e.preventDefault();
                                                        validation.handleSubmit();
                                                        return false;
                                                    }}
                                                    className="needs-validation"
                                                    action="#"
                                                >
                                                    {errors?.length > 0 && (
                                                        <Alert color="danger">
                                                            {errors?.map(
                                                                (
                                                                    value,
                                                                    key
                                                                ) => (
                                                                    <div
                                                                        key={
                                                                            key
                                                                        }
                                                                    >
                                                                        {value}
                                                                    </div>
                                                                )
                                                            )}
                                                        </Alert>
                                                    )}
                                                    <p className="text-muted">
                                                        * Enter your email and
                                                        instructions will be
                                                        sent to you!
                                                    </p>
                                                    <div className="mb-3">
                                                        <InputText
                                                            disabled
                                                            label="Email"
                                                            name="email"
                                                            placeholder="Enter Email"
                                                            validation={
                                                                validation
                                                            }
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <InputPassword
                                                            label="New Password"
                                                            name="new_password"
                                                            placeholder="Enter New Password"
                                                            validation={
                                                                validation
                                                            }
                                                            showPassword={
                                                                showPassword.new
                                                            }
                                                            showPasswordHandler={() =>
                                                                showPasswordHandler(
                                                                    "new"
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                    <div className="mb-3">
                                                        <InputPassword
                                                            label="Confirm Password"
                                                            name="confirm_password"
                                                            placeholder="Enter Confirm Password"
                                                            validation={
                                                                validation
                                                            }
                                                            showPassword={
                                                                showPassword.confirm
                                                            }
                                                            showPasswordHandler={() =>
                                                                showPasswordHandler(
                                                                    "confirm"
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                    <div className="mt-4">
                                                        <Button
                                                            isLoading={
                                                                isLoading
                                                            }
                                                            color="success"
                                                            className="btn btn-success w-100"
                                                            type="submit"
                                                            text="Reset Password"
                                                        />
                                                    </div>
                                                </Form>
                                            </div>
                                        )}
                                        {errors.length > 0 && (
                                            <Alert
                                                color="danger"
                                                className="text-center"
                                            >
                                                <strong>
                                                    Reset Password Link is
                                                    Expired or Invalid!
                                                </strong>
                                            </Alert>
                                        )}
                                    </CardBody>
                                </Card>
                                <div className="mt-4 text-center">
                                    <p className="mb-0">
                                        Wait, I remember my password...{" "}
                                        <Link
                                            to={getPath(Routes.LOGIN)}
                                            className="fw-semibold text-primary text-decoration-underline"
                                        >
                                            {" "}
                                            Click here{" "}
                                        </Link>{" "}
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ResetPassword;
