import { WalletTab } from "@/components/molecules/";
import React from "react";
import ProfileLayout from "../Layout";

const Wallet = () => {
    return (
        <ProfileLayout>
            <WalletTab userType="player" />
        </ProfileLayout>
    );
};

export default Wallet;
