import React from "react";
import { Button } from "reactstrap";

const EditActionButton = ({
  isEdit,
  onClickEdit,
  onClickCancel,
  onClickSave,
  editIconButton,
  disabledSave = false
}) => {
  return (
    <div>
      {!isEdit ? (
        <Button onClick={onClickEdit}>
          <div className="tw-flex gap-2">
            {editIconButton && <span className={editIconButton}></span>}
            Edit
          </div>
        </Button>
      ) : (
        <div className="tw-flex gap-2">
          <Button outline onClick={onClickCancel}>
            Cancel
          </Button>
          <Button color="warning" onClick={onClickSave} disabled={disabledSave}>
            Save
          </Button>
        </div>
      )}
    </div>
  );
};

export default EditActionButton;
