import React from "react";
import CountUp from "react-countup";

const CounterWidget = ({
    item,
    start = 0,
    duration = 1,
    icon = null,
    withBorder = false,
}) => {
    return (
        <div className={`card ${withBorder ? "border-3 border-black" : ""}`}>
            <div className="card-body">
                <div className="d-flex align-items-center">
                    <div className="avatar-sm flex-shrink-0">
                        <span className="avatar-title bg-light text-primary rounded-circle fs-3">
                            {icon ? (
                                icon
                            ) : (
                                <i className="align-middle ri-team-fill"></i>
                            )}
                        </span>
                    </div>
                    <div className="flex-grow-1 ms-3">
                        <p className="text-uppercase fw-semibold fs-12 text-muted mb-1 tw-w-4/6 tw-overflow-hidden tw-truncate">
                            {item.label}
                        </p>
                        <h4 className="mb-0">
                            <span>
                                {item.total.map((item, key) => (
                                    <span
                                        className="counter-value me-1"
                                        data-target="825"
                                        key={key}>
                                        <CountUp
                                            start={start}
                                            suffix={item.suffix}
                                            separator={item.separator}
                                            end={item.counter}
                                            duration={duration}
                                            decimals={item.decimals ?? 0}
                                        />
                                    </span>
                                ))}
                            </span>
                        </h4>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CounterWidget;
