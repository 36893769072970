import {
    Button,
    ErrorAlert,
    InputAmount,
    InputReactSelect,
    InputText,
    TextArea,
} from "@/components/atoms";
import { denomination, withdrawalRequestValidation } from "@/constants";
import { getSetting } from "@/hooks";
import {
    getPaymentMethodData,
    resetTransactionErrors,
    userWithdrawalRequest,
} from "@/store/actions";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button as ButtonComponent,
    ButtonGroup,
    Col,
    Form,
    FormGroup,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
} from "reactstrap";

const WithdrawalRequestModal = ({ isOpen, setIsOpen }) => {
    const dispatch = useDispatch();
    const { isLoading, errors, paymentMethod } = useSelector(
        (state) => state.transaction
    );

    const [bank, setBank] = useState({});
    const [selectedBalanceCategory, setSelectedBalanceCategory] = useState({});

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            payment_method: "",
            balance_category: "",
            account_no: "",
            account_name: "",
            amount: "",
            remarks: "",
        },
        validationSchema: withdrawalRequestValidation(
            getSetting,
            selectedBalanceCategory
        ),
        onSubmit: (values) => {
            dispatch(userWithdrawalRequest(values, setIsOpen));
        },
    });

    const walletOptions = [
        {
            label: "Php",
            value: "merchant",
        },
        {
            label: `Commission ${process.env.REACT_APP_BCF_LABEL}`,
            value: "commission_bcf",
        },
        {
            label: "Commission Php",
            value: "commission_merchant",
        },
        {
            label: `Commission ${process.env.REACT_APP_BCF_LABEL} (Sports/E-Sports/Live Casino)`,
            value: "commission_bcf_sports",
        },
        {
            label: "Commission Php (Sports/E-Sports/Live Casino)",
            value: "commission_merchant_sports",
        },
        ...process.env.REACT_APP_LOTTO_ENABLED === "true" ? [
            {
                label: `Commission ${process.env.REACT_APP_BCF_LABEL} (Lotto)`,
                value: "commission_bcf_lotto",
            },
            {
                label: `Commission ${process.env.REACT_APP_DEFAULT_CURRENCY} (Lotto)`,
                value: "commission_merchant_lotto",
            },
        ]:[]
    ];

    useEffect(() => {
        dispatch(getPaymentMethodData());
    }, [dispatch]);

    useEffect(() => {
        dispatch(resetTransactionErrors());
    }, [dispatch]);

    return (
        <Modal
            id="WithdrawalRequestModal"
            isOpen={isOpen}
            toggle={() => setIsOpen(!isOpen)}
        >
            <ModalHeader>
                <div className="modal-title">Withdrawal Request</div>
            </ModalHeader>
            <ModalBody>
                <Form
                    className="needs-validation"
                    onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}
                >
                    <ErrorAlert errors={errors} validation={validation} />
                    <Row className="mb-3">
                        <Col md="12">
                            <FormGroup className="mb-3">
                                <InputReactSelect
                                    label="Banks"
                                    name="payment_method"
                                    onChange={(option) => {
                                        validation.setFieldValue(
                                            "payment_method",
                                            option.value
                                        );
                                        setBank(option);
                                    }}
                                    value={
                                        validation.values.payment_method
                                            ? bank
                                            : ""
                                    }
                                    options={paymentMethod}
                                    validation={validation}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="12">
                            <FormGroup className="mb-3">
                                <InputReactSelect
                                    label="Balance Category"
                                    name="balance_category"
                                    onChange={(option) => {
                                        validation.setFieldValue(
                                            "balance_category",
                                            option.value
                                        );
                                        setSelectedBalanceCategory(option);
                                    }}
                                    value={
                                        validation.values.balance_category
                                            ? selectedBalanceCategory
                                            : ""
                                    }
                                    options={walletOptions}
                                    validation={validation}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="12">
                            <FormGroup className="mb-3">
                                <InputText
                                    label="Account No."
                                    name="account_no"
                                    validation={validation}
                                />
                            </FormGroup>
                        </Col>
                        <Col md="12">
                            <FormGroup className="mb-3">
                                <InputText
                                    label="Account Name"
                                    name="account_name"
                                    validation={validation}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={12}>
                            <FormGroup className="mb-3">
                                <InputAmount
                                    label="Amount"
                                    name="amount"
                                    validation={validation}
                                />
                                <ButtonGroup className="w-100 pt-2">
                                    {denomination.map((value, key) => (
                                        <ButtonComponent
                                            key={key}
                                            color="success"
                                            outline
                                            onClick={() =>
                                                validation.setFieldValue(
                                                    "amount",
                                                    value
                                                )
                                            }
                                        >
                                            {value}
                                        </ButtonComponent>
                                    ))}
                                </ButtonGroup>
                            </FormGroup>
                        </Col>
                        <Col md={12}>
                            <FormGroup className="mb-3">
                                <TextArea
                                    label="Remarks"
                                    name="remarks"
                                    validation={validation}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-end">
                            <ButtonComponent
                                color="light"
                                onClick={() => setIsOpen(!isOpen)}
                                className="me-2"
                            >
                                Close
                            </ButtonComponent>
                            <Button
                                color="success"
                                type="submit"
                                text="Submit"
                                isLoading={isLoading}
                            />
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
        </Modal>
    );
};

export default WithdrawalRequestModal;
