import React from "react";
import { Button, FormFeedback, Input, Label } from "reactstrap";

const InputTextGroup = ({
    label,
    validation,
    name,
    text,
    type = "text",
    buttonIcon,
    buttonAction,
    buttonText,
    size = "md",
    ...rest
}) => {
    return (
        <>
            {label && ( <Label>{label}</Label> )}
            <div className={`input-group input-group-${size}`}>
                {text && (
                    <span className="input-group-text" id={name}>
                        {text}
                    </span>
                )}
                <Input
                    type={type}
                    className="form-control"
                    name={name}
                    onChange={validation?.handleChange}
                    onBlur={validation?.handleBlur}
                    value={validation?.values[name] || ""}
                    invalid={
                        validation?.touched[name] && validation?.errors[name]
                            ? true
                            : false
                    }
                    {...rest}
                />
                {buttonIcon && (
                    <Button color="primary" onClick={buttonAction}>
                        <span className={buttonIcon}></span>
                    </Button>
                )}
                {buttonText && (
                    <Button color="primary" onClick={buttonAction}>
                        {buttonText}
                    </Button>
                )}
                {validation?.touched[name] && validation?.errors[name] ? (
                    <FormFeedback type="invalid">
                        {validation?.errors[name]}
                    </FormFeedback>
                ) : null}
            </div>
        </>
    );
};

export default InputTextGroup;
