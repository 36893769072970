import { DisplayText } from "@/components/atoms";
import {
  EmailUpdateInput,
  MobileNoUpdateInput,
  PasswordResetButton,
  StatusUpdateDropdown,
  UpdateReferrerInput,
} from "@/components/molecules";
import { resetUserErrors, getPlayerProfile } from "@/store/actions";
import { defaultDateFormat } from "@/utils";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import ProfileLayout from "../Layout";
import { roleBaseSettings } from "@/hooks";
import { useParams } from "react-router-dom";

const Profile = () => {
  const dispatch = useDispatch();
  const { accountNo } = useParams();

  const {
    user,
    errors: userErrors,
    isLoading: isLoadingUser,
  } = useSelector((state) => state.user);

  const isAdmin =
    user?.role.slug === "super-admin" || user?.role.slug === "admin"
      ? true
      : false;

  const canUpdateUserStatus = !isAdmin
    ? roleBaseSettings("to_verify_player", "allow")
    : isAdmin;

  const { isLoading, profile } = useSelector((state) => state.player);

  const initialize = () => {
    dispatch(getPlayerProfile(accountNo));
  }

  useEffect(() => {
    dispatch(resetUserErrors());
  }, [dispatch]);

  return (
    <ProfileLayout>
      <Row className="mb-3">
        <Col md={12}>
          {canUpdateUserStatus && (
            <StatusUpdateDropdown profile={profile} isLoading={isLoadingUser} />
          )}
          <PasswordResetButton
            isLoading={isLoadingUser}
            email={profile.email}
            userType="player"
          />
        </Col>
      </Row>
      <hr />
      {user?.role.slug === "super-admin" && (
        <Row>
          <Col>
            <UpdateReferrerInput initialize={initialize} profile={profile} />
          </Col>
        </Row>
      )}

      <Row className="mb-3">
        <Col md="6">
          <EmailUpdateInput
            errors={userErrors}
            profile={profile}
            isAdmin={isAdmin}
            userType="player"
          />
        </Col>
        <Col md="6">
          <MobileNoUpdateInput
            errors={userErrors}
            profile={profile}
            isAdmin={isAdmin}
          />
        </Col>
      </Row>
      <Row>
        <p className="text-muted">Personal Information</p>
        <Col md="3">
          <DisplayText
            label="First Name"
            item={profile.firstName ? profile.firstName : "-"}
            isLoading={isLoading}
            col="8"
          />
        </Col>
        <Col md="3">
          <DisplayText
            label="Last Name"
            item={profile.lastName ? profile.lastName : "-"}
            isLoading={isLoading}
            col="8"
          />
        </Col>
        <Col md="3">
          <DisplayText
            label="Middle Name"
            item={profile.middleName ? profile.middleName : "-"}
            isLoading={isLoading}
            col="8"
          />
        </Col>
        <Col md="3">
          <DisplayText
            label="Suffix Name"
            item={profile.extensionName ? profile.extensionName : "-"}
            isLoading={isLoading}
            col="8"
          />
        </Col>
        <Col md="3">
          <DisplayText
            label="BirthDate"
            item={
              profile.birthDate ? defaultDateFormat(profile.birthDate) : "-"
            }
            isLoading={isLoading}
            col="8"
          />
        </Col>
        <Col md="3">
          <DisplayText
            label="Gender"
            item={profile.gender ? profile.gender : "-"}
            isLoading={isLoading}
            col="8"
          />
        </Col>
        <Col md="3">
          <DisplayText
            label="Occupation"
            item={profile.occupation ? profile.occupation : "-"}
            isLoading={isLoading}
            col="8"
          />
        </Col>
      </Row>
      <Row>
        <p className="text-muted">Address Information</p>
        <Col md="3">
          <DisplayText
            label="Region"
            item={profile.region ? profile.region.name : "-"}
            isLoading={isLoading}
            col="8"
          />
        </Col>
        <Col md="3">
          <DisplayText
            label="Province"
            item={profile.province ? profile.province.name : "-"}
            isLoading={isLoading}
            col="8"
          />
        </Col>
        <Col md="3">
          <DisplayText
            label="City"
            item={profile.city ? profile.city.name : "-"}
            isLoading={isLoading}
            col="8"
          />
        </Col>
        <Col md="3">
          <DisplayText
            label="Barangay"
            item={profile.barangay ? profile.barangay.name : "-"}
            isLoading={isLoading}
            col="8"
          />
        </Col>
      </Row>
    </ProfileLayout>
  );
};

export default Profile;
