import * as types from "./actionTypes";
import { lottoSettingState } from "@/types/states";

const initialState = lottoSettingState;

const operator = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_DRAW_SCHEDULE_LIST:
            state = {
                ...state,
                isLoading: true,
            };
            break;
        case types.GET_DRAW_SCHEDULE_LIST_ERROR:
            state = {
                ...state,
                isLoading: false,
                errors: action.payload
            };
            break;
        case types.GET_DRAW_SCHEDULE_LIST_SUCCESS:
            state = {
                ...state,
                isLoading: false,
                drawScheduleList: action.payload,
                errors: {},
            };
            break;


        case types.UPDATE_DRAW_SCHEDULE_GAME:
            state = {
                ...state,
                isUpdating: true,
                errors: {},
            };
            break;
        case types.UPDATE_DRAW_SCHEDULE_GAME_SUCCESS:
            state = {
                ...state,
                isUpdating: false,
                errors: {},
            };
            break;
        case types.UPDATE_DRAW_SCHEDULE_GAME_ERROR:
            state = {
                ...state,
                isUpdating: false,
                errors: action.payload
            };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default operator;
