import {
    Button,
    ErrorAlert,
    InputDate,
    InputReactSelect,
    InputSelect,
    InputText,
} from "@/components/atoms";
import {
    agentValidation,
    commissionRatePerGameOffering,
    userGenderOptions,
} from "@/constants";
import { getSetting } from "@/hooks";
import {
    createAgent,
    getBarangays,
    getCities,
    getCountries,
    getProvinces,
    getRegions,
    resetAddressesData,
    resetAgentErrors,
    updateAffiliateRequestStatus,
} from "@/store/actions";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Form, FormGroup, Row } from "reactstrap";

const CreateAgentForm = ({
    userType,
    roleTag = null,
    data = null,
    requestFrom = "",
}) => {
    const dispatch = useDispatch();
    const { isLoading, errors } = useSelector((state) => state.agent);

    const { countries, regions, provinces, cities, barangays } = useSelector(
        (state) => state.address
    );
    console.log(data);
    const [country, setCountry] = useState(null);

    const [addresses, setAddresses] = useState({
        region: "",
        province: "",
        city: "",
        barangay: "",
    });

    const addressValueHandler = (option, type) => {
        const { value } = option;
        validation.setFieldValue(type, value);
        if (type === "country") {
            dispatch(getRegions());
            validation.setFieldValue("region", "");
            validation.setFieldValue("province", "");
            validation.setFieldValue("city", "");
            validation.setFieldValue("barangay", "");
        }
        if (type === "region") {
            dispatch(getProvinces(value));
            validation.setFieldValue("province", "");
            validation.setFieldValue("city", "");
            validation.setFieldValue("barangay", "");
        } else if (type === "province") {
            dispatch(getCities(value));
            validation.setFieldValue("city", "");
            validation.setFieldValue("barangay", "");
        } else if (type === "city") {
            dispatch(getBarangays(value));
            validation.setFieldValue("barangay", "");
        }
        setAddresses({
            ...addresses,
            [type]: option,
        });
    };
    let balanceCategory = null;
    let commissionRates = {};

    const vtoCommissionRateEnabled =
        getSetting("enable_valid_turnover_commission_rate") === "1";

    const filteredCommissionRatePerGameOffering =
        commissionRatePerGameOffering.filter((item) => {
            if (!vtoCommissionRateEnabled) {
                return !item.value.includes("vto");
            } else {
                return true;
            }
        });

    commissionRatePerGameOffering
        .filter((item) => item.balanceCategory === "bcf")
        .forEach((item) => {
            balanceCategory = item.value.replace("commission_rate_", "");

            commissionRates = {
                ...commissionRates,
                [item.value]: getSetting(
                    userType === "agent"
                        ? `default_commission_rate_for_agent_${balanceCategory}`
                        : `default_commission_rate_for_master_agent_${balanceCategory}`
                ),
            };
        });

    const initialValues = {
        first_name: data?.details?.first_name || "",
        last_name: data?.details?.last_name || "",
        middle_name: data?.details?.middle_name || "",
        extension_name: "",
        birth_date: data?.details?.birth_date || "",
        gender: data?.details?.gender || "",
        occupation: "",
        ...commissionRates,
        email: data?.details?.email || "",
        mobile_no: data?.details?.mobile_no || "",
        country: "",
        region: "",
        province: "",
        city: "",
        barangay: "",
        building_no: "",
        street: "",
        postal_code: "",
        user_type: userType,
        role_tag: roleTag,
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: agentValidation(userType, getSetting),
        onSubmit: (values) => {
            if (requestFrom === "affiliate-request") {
                dispatch(
                    updateAffiliateRequestStatus({
                        data: {
                            ...values,
                            backoffice_url:
                                process.env.REACT_APP_BACKOFFICE_URL,
                            status: "approve",
                        },
                        keyToken: data?.details?.key_token,
                        validation,
                        ...data,
                    })
                );
            } else {
                dispatch(
                    createAgent(
                        {
                            ...values,
                            backoffice_url:
                                process.env.REACT_APP_BACKOFFICE_URL,
                        },
                        validation
                    )
                );
            }
        },
    });

    useEffect(() => {
        dispatch(resetAddressesData());
        dispatch(getCountries());
        dispatch(resetAgentErrors());
    }, [dispatch]);

    const personalInformation = () => {
        return (
            <>
                <p className="text-muted">Personal Information</p>
                <Row className="mb-3">
                    <Col md="3">
                        <FormGroup className="mb-3">
                            <InputText
                                label="First Name"
                                name="first_name"
                                placeholder="First name"
                                validation={validation}
                            />
                        </FormGroup>
                    </Col>
                    <Col md="3">
                        <FormGroup className="mb-3">
                            <InputText
                                label="Last Name"
                                name="last_name"
                                placeholder="Last name"
                                validation={validation}
                            />
                        </FormGroup>
                    </Col>
                    <Col md="3">
                        <FormGroup className="mb-3">
                            <InputText
                                label="Middle Name"
                                name="middle_name"
                                placeholder="Middle name"
                                validation={validation}
                            />
                        </FormGroup>
                    </Col>
                    <Col md="3">
                        <FormGroup className="mb-3">
                            <InputText
                                label="Suffix Name"
                                name="extension_name"
                                placeholder="Ex. Jr, Sr etc..."
                                validation={validation}
                            />
                        </FormGroup>
                    </Col>

                    <Col md="3">
                        <FormGroup className="mb-3">
                            <InputDate
                                label="BirthDate"
                                name="birth_date"
                                validation={validation}
                            />
                        </FormGroup>
                    </Col>
                    <Col md="3">
                        <FormGroup className="mb-3">
                            <InputSelect
                                label="Gender"
                                name="gender"
                                validation={validation}
                                options={userGenderOptions}
                            />
                        </FormGroup>
                    </Col>
                    <Col md="3">
                        <FormGroup className="mb-3">
                            <InputText
                                label="Occupation"
                                name="occupation"
                                placeholder="Occupation"
                                validation={validation}
                            />
                        </FormGroup>
                    </Col>
                    <Col md="3" />
                    {filteredCommissionRatePerGameOffering
                        .filter((item) => item.balanceCategory === "bcf")
                        .map((item, index) => (
                            <Col
                                key={index}
                                md={vtoCommissionRateEnabled ? "2" : "3"}
                            >
                                <FormGroup className="mb-3">
                                    <InputText
                                        type="number"
                                        label={item.label}
                                        name={item.value}
                                        placeholder="%"
                                        validation={validation}
                                    />
                                </FormGroup>
                            </Col>
                        ))}
                    {filteredCommissionRatePerGameOffering
                        .filter((item) => item.balanceCategory === "merchant")
                        .map((item, index) => (
                            <Col
                                key={index}
                                md={vtoCommissionRateEnabled ? "2" : "3"}
                            >
                                <FormGroup className="mb-3">
                                    <InputText
                                        type="number"
                                        label={item.label}
                                        name={item.value}
                                        placeholder="%"
                                        disabled
                                        value={getSetting(
                                            userType === "agent"
                                                ? `default_commission_rate_for_agent_${item.value.replace(
                                                      "commission_rate_",
                                                      ""
                                                  )}`
                                                : `default_commission_rate_for_master_agent_${item.value.replace(
                                                      "commission_rate_",
                                                      ""
                                                  )}`
                                        )}
                                    />
                                </FormGroup>
                            </Col>
                        ))}
                </Row>
            </>
        );
    };

    const contactInformation = () => {
        return (
            <>
                <p className="text-muted">Contact Information</p>
                <Row className="mb-3">
                    <Col md="6">
                        <FormGroup className="mb-3">
                            <InputText
                                label="Email"
                                name="email"
                                placeholder="Email Address"
                                validation={validation}
                            />
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <FormGroup className="mb-3">
                            <InputText
                                label="Mobile Number"
                                name="mobile_no"
                                placeholder="09xxxxxxxxx"
                                validation={validation}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </>
        );
    };

    const addressInformation = () => {
        return (
            <>
                <p className="text-muted">Address Information</p>
                <Row className="mb-3">
                    <Col md="12">
                        <Row>
                            <Col md="3">
                                <FormGroup className="mb-3">
                                    <InputReactSelect
                                        label="Country"
                                        name="country"
                                        onChange={(option) => {
                                            setCountry(option.label);
                                            addressValueHandler(
                                                option,
                                                "country"
                                            );
                                        }}
                                        value={
                                            validation.values.country
                                                ? addresses.country
                                                : ""
                                        }
                                        options={countries}
                                        validation={validation}
                                        validateOnBlur={false}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>
                    {country === "Philippines" && (
                        <>
                            <Col md="3">
                                <FormGroup className="mb-3">
                                    <InputReactSelect
                                        label="Region"
                                        name="region"
                                        onChange={(option) => {
                                            addressValueHandler(
                                                option,
                                                "region"
                                            );
                                        }}
                                        value={
                                            validation.values.region
                                                ? addresses.region
                                                : ""
                                        }
                                        options={regions}
                                        validation={validation}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="3">
                                <FormGroup className="mb-3">
                                    <InputReactSelect
                                        label="Province"
                                        name="province"
                                        onChange={(option) => {
                                            addressValueHandler(
                                                option,
                                                "province"
                                            );
                                        }}
                                        value={
                                            validation.values.province
                                                ? addresses.province
                                                : ""
                                        }
                                        options={provinces}
                                        validation={validation}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="3">
                                <FormGroup className="mb-3">
                                    <InputReactSelect
                                        label="City"
                                        name="city"
                                        onChange={(option) => {
                                            addressValueHandler(option, "city");
                                        }}
                                        value={
                                            validation.values.city
                                                ? addresses.city
                                                : ""
                                        }
                                        options={cities}
                                        validation={validation}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="3">
                                <FormGroup className="mb-3">
                                    <InputReactSelect
                                        label="Barangay"
                                        name="barangay"
                                        onChange={(option) => {
                                            addressValueHandler(
                                                option,
                                                "barangay"
                                            );
                                        }}
                                        value={
                                            validation.values.barangay
                                                ? addresses.barangay
                                                : ""
                                        }
                                        options={barangays}
                                        validation={validation}
                                    />
                                </FormGroup>
                            </Col>
                        </>
                    )}
                </Row>
                <Row className="mb-3">
                    <Col md="4">
                        <FormGroup className="mb-3">
                            <InputText
                                label="Building No."
                                name="building_no"
                                placeholder="Building No"
                                validation={validation}
                            />
                        </FormGroup>
                    </Col>
                    <Col md="4">
                        <FormGroup className="mb-3">
                            <InputText
                                label="Street"
                                name="street"
                                placeholder="Street"
                                validation={validation}
                            />
                        </FormGroup>
                    </Col>
                    <Col md="4">
                        <FormGroup className="mb-3">
                            <InputText
                                label="Postal Code"
                                name="postal_code"
                                placeholder="Postal Code"
                                validation={validation}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </>
        );
    };

    return (
        <>
            <Form
                className="needs-validation"
                onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}
            >
                <ErrorAlert errors={errors} validation={validation} />
                {personalInformation()}
                {contactInformation()}
                {addressInformation()}
                <div className="border-top py-3 mt-3">
                    <Button
                        color="success"
                        type="submit"
                        className="float-end"
                        text="Submit"
                        isLoading={isLoading}
                    />
                </div>
            </Form>
        </>
    );
};

export default CreateAgentForm;
