import { Table, Title } from "@/components/atoms";
import BreadCrumb from "@/components/common/BreadCrumb";
import { getPath, getPathTitle, Routes } from "@/constants";
import { getActivityLog } from "@/store/actions";
import { defaultDateTimeFormat } from "@/utils";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";

const List = () => {
    const dispatch = useDispatch();
    const { activityLogList, isLoading } = useSelector((state) => state.user);

    const [search, setSearch] = useState("");
    const [sortBy, setSortBy] = useState({
        field: "",
        type: "asc",
    });

    const [data, setData] = useState({
        // "types[0]": "login",
        per_page: 10,
        page: 1,
        search: "",
        sort_by:
            sortBy.field && sortBy.type ? `${sortBy.field} ${sortBy.type}` : "",
    });

    const tableTheadData = [
        {
            label: "Type",
            field: "type",
            sortable: false,
            render: (item) => item?.type.label,
        },
        {
            label: "Descriptions",
            field: "description",
            sortable: true,
            render: (item) => (item?.description ? item?.description : "-"),
        },
        {
            label: "IP Address",
            field: "ip_address",
            sortable: true,
        },
        {
            label: "Date & Time",
            field: "created_at",
            sortable: true,
            render: (item) => defaultDateTimeFormat(item.created_at),
        },
    ];

    useEffect(() => {
        dispatch(getActivityLog(data));
    }, [dispatch, data]);

    return (
        <React.Fragment>
            <Title title={getPathTitle(Routes.ACTIVITY_LOGS)} />
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb
                        parent={{
                            isParent: true,
                            pageTitle: getPathTitle(Routes.ACTIVITY_LOGS),
                            pageLink: getPath(Routes.ACTIVITY_LOGS),
                        }}
                    />
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardHeader className="align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">
                                        {getPathTitle(Routes.ACTIVITY_LOGS)}
                                    </h4>
                                </CardHeader>
                                <CardBody>
                                    <Table
                                        search={search}
                                        handlerSearch={setSearch}
                                        sortBy={sortBy}
                                        handlerSortBy={setSortBy}
                                        thead={tableTheadData}
                                        handlerChangeData={setData}
                                        data={data}
                                        collection={activityLogList}
                                        isLoading={isLoading}
                                        tableTextAlign="left"
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default List;
