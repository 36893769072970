import * as types from "./actionTypes";
import { adminState, setUserData } from "@/types/states";

const initialState = adminState;

const admin = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_ADMIN_LIST:
        case types.GET_PERMISSION_DATA:
        case types.CREATE_ADMIN:
        case types.GET_ADMIN_PROFILE:
        case types.UPDATE_ADMIN:
        case types.UPDATE_ADMIN_ROLE:
        case types.CREATE_ROLE:
        case types.UPDATE_ROLE_NAME:
        case types.GET_ROLE_LIST:
        case types.GET_ROLE_INFO:
        case types.UPDATE_ROLE_PERMISSION:
        case types.GET_DIRECT_WITHDRAWALS:
        case types.DIRECT_WITHDRAWAL:
            state = {
                ...state,
                isLoading: true,
            };
            break;
        case types.GET_DIRECT_WITHDRAWALS_SUCCESS:
            state = {
                ...state,
                isLoading: false,
                directWithdrawals: action.payload,
                errors: adminState.errors,
            };
            break;
        case types.SET_ADMIN_LIST:
            state = {
                ...state,
                isLoading: false,
                list: action.payload.response,
                errors: adminState.errors,
            };
            break;
        case types.SET_PERMISSION_DATA:
            state = {
                ...state,
                isLoading: false,
                permissionList: action.payload,
                errors: adminState.errors,
            };
            break;
        case types.SET_ROLE_LIST:
            state = {
                ...state,
                isLoading: false,
                roleList: action.payload,
                errors: adminState.errors,
            };
            break;
        case types.SET_ROLE_INFO:
            state = {
                ...state,
                isLoading: false,
                roleSelected: action.payload,
                errors: adminState.errors,
            };
            break;
        case types.SET_ADMIN_LIST_ERROR:
            state = {
                ...state,
                isLoading: false,
            };
            break;
        case types.CREATE_ADMIN_SUCCESS:
        case types.CREATE_ROLE_SUCCESS:
        case types.UPDATE_ROLE_NAME_SUCCESS:
        case types.UPDATE_ROLE_PERMISSION_SUCCESS:
        case types.UPDATE_ADMIN_ROLE_SUCCESS:
        case types.DIRECT_WITHDRAWAL_SUCCESS:
            state = {
                ...state,
                isLoading: false,
                errors: adminState.errors,
            };
            break;
        case types.UPDATE_ADMIN_EMAIL_SUCCESS:
            state = {
                ...state,
                isLoading: false,
                profile: {
                    ...state.profile,
                    email: action.payload.email,
                },
                errors: adminState.errors,
            };
            break;
        case types.CREATE_ADMIN_ERROR:
        case types.SET_ADMIN_PROFILE_ERROR:
        case types.SET_PERMISSION_DATA_ERROR:
        case types.UPDATE_ADMIN_ERROR:
        case types.CREATE_ROLE_ERROR:
        case types.UPDATE_ROLE_NAME_ERROR:
        case types.SET_ROLE_LIST_ERROR:
        case types.SET_ROLE_INFO_ERROR:
        case types.UPDATE_ROLE_PERMISSION_ERROR:
        case types.UPDATE_ADMIN_ROLE_ERROR:
        case types.DIRECT_WITHDRAWAL_ERROR:
            state = {
                ...state,
                isLoading: false,
                errors: action.payload,
            };
            break;
        case types.SET_ADMIN_PROFILE:
        case types.UPDATE_ADMIN_SUCCESS:
            state = {
                ...state,
                isLoading: false,
                profile: setUserData(action),
                errors: adminState.errors,
            };
            break;
        case types.RESET_ADMIN_ERRORS:
        case types.RESET_DIRECT_WITHDRAWAL_ERROR:
            state = {
                ...state,
                isLoading: false,
                errors: adminState.errors,
            };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default admin;
