import React from "react";
import { Label } from "reactstrap";
import Select from "react-select/creatable";
import classNames from "classnames";

const CreatableSelect = ({ label, validation, name, ...rest }) => {
    return (
        <>
            <Label>{label}</Label>
            <Select
                name={name}
                className={
                    validation?.errors[name]
                        ? "border border-danger rounded"
                        : ""
                }
                classNames={{
                    control: () =>
                        classNames(
                            "dark:tw-bg-dark-input bg-transparent border border-gray rounded input tw-shadow-none"
                        ),
                    menuList: () => classNames("dark:tw-bg-dark"),
                    option: () =>
                        classNames(
                            "dark:tw-bg-dark dark:hover:tw-bg-primary dark:tw-text-white"
                        ),
                    multiValue: () => classNames("dark:tw-bg-dark"),
                    multiValueLabel: () => classNames("dark:tw-text-white"),
                    singleValue: () => classNames("dark:tw-text-white"),
                    input: () => classNames("dark:tw-text-white"),
                }}
                {...rest}
            />
            {validation?.errors[name] ? (
                <small className="text-danger text-sm">
                    {validation.errors[name]}
                </small>
            ) : null}
        </>
    );
};

export default CreatableSelect;
