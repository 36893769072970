import {
    errorToast,
    loadingToast,
    removeToast,
    successToast,
} from "@/components/constants/toast";
import * as API from "@/network/apis/lotto";
import { applyDownlinesCommissionTransferSchedule } from "@/network/apis/user";
import { convert2dErrorsArray } from "@/utils";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import * as TYPES from "./actionTypes";
import * as ACTIONS from "./actions";

function* getDrawScheduleList({ payload }) {
    try {
        const response = yield call(API.getDrawScheduleList, payload);

        if (response.status === 200) {
            yield put(ACTIONS.getDrawScheduleListSuccess(response.data.response));
        }
    } catch (error) {
        errorToast(error.response.data.message);
        let errors = !Array.isArray(error?.response?.data?.response)
        ? convert2dErrorsArray(error?.response?.data?.response)
        : [error.response.data.message];
        yield put(ACTIONS.getDrawScheduleListError(errors));
    }
}

function* updateDrawScheduleGame({ payload, keyToken, result }) {
    try {
        loadingToast("Updating...");
        const response = yield call(API.updateDrawScheduleGame, payload, keyToken);

        if (response.status === 200) {
            yield put(ACTIONS.updateDrawScheduleGameSuccess(response.data.response));
            removeToast();
            successToast("Success!");
            result(true);
        }
    } catch (error) {
        removeToast();
        errorToast(error.response.data.message);
        let errors = !Array.isArray(error?.response?.data?.response)
        ? convert2dErrorsArray(error?.response?.data?.response)
        : [error.response.data.message];
        result(false);
        yield put(ACTIONS.updateDrawScheduleGameError(errors));
    }
}


function* watchGetDrawScheduleList() {
    yield takeLatest(TYPES.GET_DRAW_SCHEDULE_LIST, getDrawScheduleList);
}

function* watchUpdateDrawScheduleGame() {
    yield takeLatest(TYPES.UPDATE_DRAW_SCHEDULE_GAME, updateDrawScheduleGame);
}

function* LottoSettingsSagas() {
    yield all([
        fork(watchGetDrawScheduleList),
        fork(watchUpdateDrawScheduleGame),
    ]);
}

export default LottoSettingsSagas;
