import * as types from "./actionTypes";
import { agentState, setUserData } from "@/types/states";

const initialState = agentState;

const agent = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_AGENT_LIST:
        case types.UPDATE_AGENT_ROLE_TAG:
        case types.UPDATE_AFFILIATE_REQUEST_STATUS:
            state = {
                ...state,
                isLoading: true,
            };
            break;
        case types.SET_AGENT_LIST:
            state = {
                ...state,
                isLoading: false,
                list: action.payload.response,
                errors: agentState.errors,
                commissionRateErrors: agentState.commissionRateErrors,
            };
            break;
        case types.SET_AGENT_LIST_ERROR:
            state = {
                ...state,
                isLoading: false,
            };
            break;
        case types.CREATE_AGENT:
            state = {
                ...state,
                isLoading: true,
            };
            break;
        case types.CREATE_AGENT_SUCCESS:
            state = {
                ...state,
                isLoading: false,
                message: action.payload.message,
                errors: agentState.errors,
                commissionRateErrors: agentState.commissionRateErrors,
            };
            break;
        case types.CREATE_AGENT_ERROR:
            state = {
                ...state,
                isLoading: false,
                errors: action.payload,
            };
            break;
        case types.GET_AGENT_PROFILE:
            state = {
                ...state,
                isLoading: true,
                agentCommissionList: [],
            };
            break;
        case types.SET_AGENT_PROFILE:
        case types.UPDATE_AGENT_SUCCESS:
        case types.UPDATE_AGENT_ROLE_TAG_SUCCESS:
            state = {
                ...state,
                isLoading: false,
                profile: setUserData(action),
                errors: agentState.errors,
                commissionRateErrors: agentState.commissionRateErrors,
            };
            break;
        case types.SET_AGENT_PROFILE_ERROR:
        case types.UPDATE_AFFILIATE_REQUEST_STATUS_SUCCESS:
        case types.UPDATE_AFFILIATE_REQUEST_STATUS_ERROR:
            state = {
                ...state,
                isLoading: false,
            };
            break;
        case types.UPDATE_AGENT:
            state = {
                ...state,
                isLoading: true,
            };
            break;
        case types.UPDATE_AGENT_EMAIL_SUCCESS:
            state = {
                ...state,
                isLoading: false,
                profile: {
                    ...state.profile,
                    email: action.payload.email,
                },
                errors: agentState.errors,
                commissionRateErrors: agentState.commissionRateErrors,
            };
            break;
        case types.UPDATE_AGENT_ERROR:
            state = {
                ...state,
                isLoading: false,
                errors: action.payload,
            };
            break;
        case types.RESET_AGENT_ERRORS:
            state = {
                ...state,
                isLoading: false,
                errors: agentState.errors,
                commissionRateErrors: agentState.commissionRateErrors,
            };
            break;
        case types.UPDATE_AGENT_WALLET_BALANCE:
            state = {
                ...state,
                profile: {
                    ...state.profile,
                    balance: action.payload,
                },
            };
            break;
        case types.UPDATE_AGENT_COMMISSION_RATE:
            state = {
                ...state,
                isLoading: true,
            };
            break;
        case types.UPDATE_AGENT_COMMISSION_RATE_SUCCESS:
            state = {
                ...state,
                isLoading: false,
                errors: agentState.errors,
                commissionRateErrors: agentState.commissionRateErrors,
            };
            break;
        case types.UPDATE_AGENT_COMMISSION_RATE_ERROR:
            state = {
                ...state,
                isLoading: false,
                [`commission${
                    action.payload.type === "bcf" ? "BCF" : "Merchant"
                }Errors`]: action.payload.errors,
            };
            break;
        case types.GET_AGENT_COMMISSIONS:
            state = {
                ...state,
                isLoading: true,
            };
            break;
        case types.GET_AGENT_COMMISSIONS_SUCCESS:
            state = {
                ...state,
                isLoading: false,
                agentCommissionList: action.payload,
            };
            break;
        case types.GET_AGENT_COMMISSIONS_FAILED:
            state = {
                ...state,
                isLoading: false,
            };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default agent;
