import {
  Button,
  ErrorAlert,
  InputAmount,
  InputText,
} from "@/components/atoms";
import {
  updatePromotion,
} from "@/store/actions";
import { useFormik } from "formik";
import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Col, Form, FormGroup, Row } from "reactstrap";
import moment from "moment";
 
const UpdatePromotionSettingsForm = ({
  selectedPromotion,
  isSubmit,
  initialize,
  errors,
  selectedPromotionCategory,
}) => {
  const dispatch = useDispatch();

  const settings = useMemo(
    () =>
      selectedPromotion?.settings ??
      selectedPromotionCategory?.default_settings,
    [selectedPromotion?.settings, selectedPromotionCategory?.default_settings]
  );

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      settings_deposit_requirement: null,
      settings_bonus_percent: null,
      settings_max_cap_amount: null,
      settings_turnover_multiplier: null,
      settings_opt_in_expiration: null,
    },
    //   validationSchema: promotionUpdateCommonValidation,
    onSubmit: (values) => {
      // console.log(values)
      dispatch(
        updatePromotion(
          selectedPromotion?.key_token,
          values,
          "update-settings",
          initialize
        )
      );
    },
  });


  const disabledDate = useMemo(
    () => moment(selectedPromotion?.start_datetime) < moment(),
    [selectedPromotion?.start_datetime]
  );

  const { setFieldValue, values } = validation;

  useEffect(() => {
    if (selectedPromotion?.promotion_category) {

      if (selectedPromotion?.settings.length > 0) {
        /* eslint-disable */
        (selectedPromotion?.settings ?? []).map((item) => {
          setFieldValue(`settings_${item.key}`, item.value);
        });
        /* eslint-enable */
      }
    }
  }, [selectedPromotion?.promotion_category, selectedPromotion?.settings, setFieldValue]);

  useEffect(() => {
    if (selectedPromotionCategory?.default_settings) {
      /* eslint-disable */
      (selectedPromotionCategory?.default_settings ?? []).map((item) => {
        if (values?.[`settings_${item.key}`] === null) {
          setFieldValue(`settings_${item.key}`, item.value);
        }
      });
      /* eslint-enable */
    }
  }, [selectedPromotionCategory?.default_settings, setFieldValue, values]);

  return (
    <div>
      {errors && <ErrorAlert errors={errors} validation={validation} />}

      <Form
        className="needs-validation"
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        <Row>
          {(
            selectedPromotion?.settings ??
            selectedPromotionCategory?.default_settings ??
            []
          ).length > 0 && (
            <Col md="12">
              <Row>
                <p className="text-muted">Promotion Category Settings</p>
                <Row>
                  {(
                    selectedPromotion?.settings ??
                    selectedPromotionCategory?.default_settings ??
                    []
                  ).map((item, index) => (
                    <Col md="6" key={index}>
                      <FormGroup className="mb-3">
                        {["integer", "decimal"].includes(item.data_type) ? (
                          <InputAmount
                            disabled={disabledDate}
                            label={item.label}
                            placeholder={
                              item.data_type === "integer" ? "0" : "0.0"
                            }
                            numericOnly={item.data_type === "integer"}
                            withCurrency={false}
                            name={`settings_${item?.key}`}
                            validation={validation}
                            value={validation.values[`settings_${item?.key}`]}
                          />
                        ) : (
                          <InputText
                            disabled={disabledDate}
                            label={item.label}
                            name={`settings_${item?.key}`}
                            placeholder={item.label}
                            validation={validation}
                          />
                        )}
                      </FormGroup>
                    </Col>
                  ))}
                </Row>
              </Row>
            </Col>
          )}
        </Row>
        <div className="border-top py-3 mt-3">
          <Button
            color="success"
            type="submit"
            className="float-end"
            text="Submit"
            isLoading={isSubmit}
          />
        </div>
      </Form>
    </div>
  );
};

export default UpdatePromotionSettingsForm;
