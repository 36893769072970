import * as types from "./actionTypes";

export const getWinLossReport = (payload) => ({
    type: types.GET_WIN_LOSS_REPORT,
    payload,
});

export const getWinLossReportSuccess = (payload) => ({
    type: types.GET_WIN_LOSS_REPORT_SUCCESS,
    payload,
});

export const getWinLossReportFailed = () => ({
    type: types.GET_WIN_LOSS_REPORT_FAILED,
});

export const getWinLossByProductReport = (payload) => ({
    type: types.GET_WIN_LOSS_REPORT_BY_PRODUCT,
    payload,
});

export const onGetWinLossByProductReportSuccess = (payload) => ({
    type: types.GET_WIN_LOSS_REPORT_BY_PRODUCT_SUCCESS,
    payload,
});

export const onGetWinLossByProductReportFailed = () => ({
    type: types.GET_WIN_LOSS_REPORT_BY_PRODUCT_FAILED,
});

export const generateBatchPayoutReport = (payload) => ({
    type: types.GENERATE_BATCH_PAYOUT_REPORT,
    payload,
});

export const onGenerateBatchPayoutReportSuccess = (payload) => ({
    type: types.GENERATE_BATCH_PAYOUT_REPORT_SUCCESS,
    payload,
});

export const onGenerateBatchPayoutReportFailed = () => ({
    type: types.GENERATE_BATCH_PAYOUT_REPORT_FAILED,
});

export const getGeneratedReports = (payload) => ({
    type: types.GET_GENERATED_REPORTS,
    payload,
});

export const onGetGeneratedReportsSuccess = (payload) => ({
    type: types.GET_GENERATED_REPORTS_SUCCESS,
    payload,
});

export const onGetGeneratedReportsFailed = () => ({
    type: types.GET_GENERATED_REPORTS_FAILED,
});


export const getLoginLogs = (payload) => ({
    type: types.GET_LOGIN_LOGS,
    payload,
});

export const onGetLoginLogsSuccess = (payload) => ({
    type: types.GET_LOGIN_LOGS_SUCCESS,
    payload,
});

export const onGetLoginLogsFailed = () => ({
    type: types.GET_LOGIN_LOGS_FAILED,
});

export const getMultipleIPData = (payload) => ({
    type: types.GET_MULTIPLE_IP_DATA,
    payload,
});

export const onGetMultipleIPDataSuccess = (payload) => ({
    type: types.GET_MULTIPLE_IP_DATA_SUCCESS,
    payload,
});

export const onGetMultipleIPDataFailed = () => ({
    type: types.GET_MULTIPLE_IP_DATA_FAILED,
});




