import { Button, ErrorAlert, InputAmount } from "@/components/atoms";
import { addPaymentFeeValidation } from "@/constants";
import { resetErrors } from "@/store/actions";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button as ButtonComponent,
    Col,
    Form,
    FormGroup,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
} from "reactstrap";

const UpdatePaymentMethodModal = ({ isOpen, setIsOpen, data, handleUpdate }) => {
    const dispatch = useDispatch();
    const { isSubmit, errors } = useSelector((state) => state.paymentSetting);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            convenience_fee_percent: data.convenience_fee_percent,
            platform_fee_percent: data.platform_fee_percent,
            fix_fee_amount: data.fix_fee_amount,
        },
        validationSchema: addPaymentFeeValidation(),
        onSubmit: (values) => {
            handleUpdate(values, data.key_token);
        },
    });

    useEffect(() => {
        dispatch(resetErrors());
    }, [dispatch]);

    return (
        <Modal
            id="paymentFeeModal"
            isOpen={isOpen}
            toggle={() => setIsOpen(!isOpen)}
        >
            <ModalHeader>
                <div className="modal-title">Update Payment Method</div>
            </ModalHeader>
            <ModalBody>
                <Form
                    className="needs-validation"
                    onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}
                >
                    <ErrorAlert errors={errors} validation={validation} />
                    <Row className="mb-3">
                        <Col md={12}>
                            <FormGroup className="mb-3">
                                <InputAmount
                                    withCurrency={false}
                                    label="Convenience Fee (%)"
                                    name="convenience_fee_percent"
                                    validation={validation}
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <InputAmount
                                    withCurrency={false}
                                    label="Platform Fee (%)"
                                    name="platform_fee_percent"
                                    validation={validation}
                                />
                            </FormGroup>
                            <FormGroup className="mb-3">
                                <InputAmount
                                    label="Fixed Fee Amount"
                                    name="fix_fee_amount"
                                    validation={validation}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-end">
                            <ButtonComponent
                                color="light"
                                onClick={() => setIsOpen(!isOpen)}
                                className="me-2"
                            >
                                Close
                            </ButtonComponent>
                            <Button
                                color="success"
                                type="submit"
                                text="Submit"
                                isLoading={isSubmit}
                            />
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
        </Modal>
    );
};

export default UpdatePaymentMethodModal;
