import React from "react";
import { FormFeedback, Label } from "reactstrap";

const TextArea = ({ label = null, validation, name, ...rest }) => {
    return (
        <>
            {label && <Label>{label}</Label>}
            <textarea
                className={`form-control ${validation?.touched[name] && validation?.errors[name] ? "border-danger" : ""}`}
                name={name}
                value={validation?.values[name] || ""}
                onChange={validation?.handleChange}
                {...rest}
            />

            {validation?.touched[name] && validation?.errors[name] ? (
                <div className="text-danger tw-text-xs mt-2">
                    {validation?.errors[name]}
                </div>
            ) : null}
        </>
    );
};

export default TextArea;
