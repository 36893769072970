import React from "react";
import { Label, Input } from "reactstrap";

const Select = ({
    label,
    name,
    options,
    placeholder,
    value,
    onChange,
    disabled,
}) => {
    return (
        <>
            {label && <Label>{label}</Label>}
            <Input
                type="select"
                className={`form-control ${
                    value === "" ? "tw-text-[#878A99]" : ""
                }`}
                name={name}
                onChange={onChange}
                value={value || ""}
                disabled={disabled}
            >
                {placeholder && (
                    <option hidden value="">
                        {placeholder}
                    </option>
                )}
                {options.map((item, key) => (
                    <option key={key} value={item.value}>
                        {item.label}
                    </option>
                ))}
            </Input>
        </>
    );
};

export default Select;
