import {
    AmountFormat,
    CounterWidget,
    InputSwitch,
    Select,
    StatusBadge,
    Table,
    Title,
} from "@/components/atoms";
import BreadCrumb from "@/components/common/BreadCrumb";
import { confirmation } from "@/components/constants/common";
import {
    Routes,
    getPath,
    getPathTitle,
    userCounter,
    userStatus,
} from "@/constants";
import { getPermission, userRole } from "@/hooks";
import {
    getOperatorList,
    getUserCountData,
    updateMerchantSetting,
} from "@/store/actions";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row,
} from "reactstrap";

const SuperAgentList = () => {
    const isMounted = useRef(false);
    const dispatch = useDispatch();
    const { user, userCount } = useSelector((state) => state.user);
    const { list, isLoading, isUpdateMerchantSettingSuccess } = useSelector(
        (state) => state.operator
    );

    const [search, setSearch] = useState("");
    const [sortBy, setSortBy] = useState({
        field: "",
        type: "asc",
    });

    const userType = "operator";

    const { isSuperAdmin, isAssistantAdmin } = userRole();

    const [data, setData] = useState({
        key_token: user.keyToken,
        user_type: userType,
        per_page: 10,
        page: 1,
        search: "",
        status: "",
        sort_by:
            sortBy.field && sortBy.type ? `${sortBy.field} ${sortBy.type}` : "",
    });

    const changeStatus = (item) => {
        confirmation({
            title: item.title,
            text: `Are you sure you want to ${
                item?.user_profile?.allowed_in_merchant
                    ? "DEACTIVATE"
                    : "ACTIVATE"
            } the merchant payment method of this operator`,
            icon: "warning",
            confirmButtonText: "Confirm",
            action: () => {
                dispatch(
                    updateMerchantSetting(
                        {
                            value: item?.user_profile?.allowed_in_merchant
                                ? 0
                                : 1,
                        },
                        item?.user_profile?.key_token
                    )
                );
            },
        });
    };

    const tableTheadData = [
        {
            label: "Account No.",
            field: "account_no",
            sortable: true,
            render: (item) => item?.user_profile?.account_no,
        },
        {
            label: "First Name",
            field: "first_name",
            sortable: true,
            render: (item) => item?.user_profile?.first_name,
        },
        {
            label: "Last Name",
            field: "last_name",
            sortable: true,
            render: (item) => item?.user_profile?.last_name,
        },
        {
            label: "Email",
            field: "email",
            sortable: true,
        },
        {
            label: "Contact No",
            field: "mobile_no",
            sortable: true,
            render: (item) => item?.mobile_no || "-",
        },
        {
            label: "PHP",
            field: "user_profile_balance_merchant.amount",
            sortable: true,
            render: (item) => (
                <AmountFormat
                    amount={item?.user_profile?.balance?.fiat?.php?.merchant}
                />
            ),
        },
        {
            label: process.env.REACT_APP_BCF_LABEL,
            field: "user_profile_balance_bcf.amount",
            sortable: true,
            render: (item) => (
                <AmountFormat
                    amount={item?.user_profile?.balance?.fiat?.php?.bcf}
                />
            ),
        },
        {
            label: "Status",
            field: "status",
            sortable: false,
            render: (item) => <StatusBadge status={item.status} />,
        },
        {
            label: "Merchant",
            field: "payment_action",
            sortable: false,
            render: (item) => (
                <div className="d-flex justify-content-center">
                    <InputSwitch
                        id={`game-type-status-${item.slug}`}
                        isChecked={item?.user_profile?.allowed_in_merchant}
                        color="success"
                        onChange={() => changeStatus(item)}
                    />
                </div>
            ),
            isHide: isAssistantAdmin,
        },
        {
            label: "Action",
            field: "action",
            sortable: false,
            render: (item) => (
                <Link
                    to={getPath(Routes.OPERATOR_DASHBOARD).replace(
                        ":accountNo",
                        item?.user_profile?.account_no
                    )}
                    className="link-primary"
                >
                    <Button color="success" size="sm">
                        <i className="ri-eye-line"></i>
                    </Button>
                </Link>
            ),
        },
    ];

    const userCounterData = userCounter(userCount, userType);

    useEffect(() => {
        dispatch(getOperatorList(data));
        dispatch(
            getUserCountData({
                user_type: [userType],
            })
        );
    }, [dispatch, data, userType]);

    useEffect(() => {
        if (isMounted.current) {
            if (isUpdateMerchantSettingSuccess) {
                dispatch(getOperatorList(data));
                dispatch(
                    getUserCountData({
                        user_type: [userType],
                    })
                );
            }
        } else {
            isMounted.current = true;
        }
    }, [dispatch, data, userType, isUpdateMerchantSettingSuccess]);

    return (
        <React.Fragment>
            <Title title={getPathTitle(Routes.OPERATORS)} />
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb
                        parent={{
                            isParent: true,
                            pageTitle: getPathTitle(Routes.OPERATORS),
                            pageLink: getPath(Routes.OPERATORS),
                        }}
                    />

                    <Row>
                        {userCounterData.map((item, key) => (
                            <Col md={3} key={key}>
                                <CounterWidget item={item} />
                            </Col>
                        ))}
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardHeader className="align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">
                                        {getPathTitle(Routes.OPERATORS)} List
                                    </h4>
                                    {getPermission(
                                        "master-agent",
                                        "can_create"
                                    ) || isSuperAdmin ? (
                                        <div className="flex-shrink-0">
                                            <Link
                                                to={getPath(
                                                    Routes.OPERATOR_CREATE
                                                )}
                                            >
                                                <Button
                                                    color="success"
                                                    size="sm"
                                                >
                                                    <i className="ri-add-fill me-1 align-bottom"></i>
                                                    {getPathTitle(
                                                        Routes.OPERATOR_CREATE
                                                    )}
                                                </Button>
                                            </Link>
                                        </div>
                                    ) : null}
                                </CardHeader>
                                <CardBody>
                                    <Row className="mb-3">
                                        <Col md={6}>
                                            <Select
                                                label="Status"
                                                isClearable={true}
                                                value={data.status}
                                                options={[
                                                    ...[
                                                        {
                                                            label: "All",
                                                            value: "",
                                                        },
                                                    ],
                                                    ...userStatus,
                                                ]}
                                                onChange={(event) =>
                                                    setData({
                                                        ...data,
                                                        status: event.target
                                                            .value,
                                                        page: 1,
                                                    })
                                                }
                                            />
                                        </Col>
                                    </Row>
                                    <Table
                                        search={search}
                                        handlerSearch={setSearch}
                                        sortBy={sortBy}
                                        handlerSortBy={setSortBy}
                                        thead={tableTheadData}
                                        handlerChangeData={setData}
                                        data={data}
                                        collection={list}
                                        isLoading={isLoading}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default SuperAgentList;
