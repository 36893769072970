import { axiosInstance } from "./index";

export const getSettingsData = async () => {
    return await axiosInstance.get(`/bo/system-setting/get-all`);
};

export const getSettingValue = async (keyToken) => {
    return await axiosInstance.get(
        `/bo/system-setting/get-by-key-token/${keyToken}`
    );
};

export const updateSetting = async (data, keyToken) => {
    return await axiosInstance.put(
        `/bo/system-setting/update/${keyToken}`,
        data
    );
};
