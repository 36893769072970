import React from "react";
import { Alert } from "reactstrap";

const ErrorAlert = ({ errors, validation = null }) => {
    if (!Array.isArray(errors) && Object.keys(errors).length > 0) {
        validation?.setErrors(errors);
    }
    return (
        <>
            {typeof errors !== "undefined"
                ? Array.isArray(errors)
                    ? errors?.length > 0 && (
                          <Alert color="danger">
                              {errors?.map((value, key) => (
                                  <div key={key}>
                                      <span className="ri-error-warning-line align-middle"></span>{" "}
                                      {value}
                                  </div>
                              ))}
                          </Alert>
                      )
                    : null
                : null}
        </>
    );
};

export default ErrorAlert;

// Object.keys(errors).length > 0 && (
//     <Alert color="danger">
//         {Object.keys(errors).map((key, index) => (
//             <div key={index}>
//                 <span className="ri-error-warning-line align-middle"></span>{" "}
//                 {errors[key]}
//             </div>
//         ))}
//     </Alert>
// )
