import { Title } from "@/components/atoms";
import BreadCrumb from "@/components/common/BreadCrumb";
import { CreateAgentForm } from "@/components/molecules";
import { getPath, getPathTitle, Routes } from "@/constants";
import React from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";

const AgentCreate = () => {
    return (
        <React.Fragment>
            <Title title={getPathTitle(Routes.AFFILIATE_CREATE)} />
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb
                        parent={{
                            isParent: true,
                            pageTitle: getPathTitle(Routes.AFFILIATES),
                            pageLink: getPath(Routes.AFFILIATES),
                        }}
                        childs={[
                            {
                                pageTitle: getPathTitle(
                                    Routes.AFFILIATE_CREATE
                                ),
                                pageLink: getPath(Routes.AFFILIATE_CREATE),
                                isActive: true,
                            },
                        ]}
                    />
                    <Row>
                        <Col xs={12}>
                            <CardHeader className="align-items-center d-flex">
                                <h4 className="card-title mb-0 flex-grow-1">
                                    {getPathTitle(Routes.AFFILIATE_CREATE)}
                                </h4>
                                <div className="flex-shrink-0">
                                    Please Fill up all fields
                                </div>
                            </CardHeader>
                            <Card>
                                <CardBody>
                                    <CreateAgentForm
                                        userType="agent"
                                        roleTag="affiliate"
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default AgentCreate;
