import {
    AmountFormat,
    AsyncReactSelect,
    FlatPickerDate,
    InputReactSelect,
    Table,
    TextLink,
    Title,
} from "@/components/atoms";
import BreadCrumb from "@/components/common/BreadCrumb";
import {
    getPath,
    getPathTitle,
    Routes,
    transactionTypeOptions,
    userTypeOptions,
} from "@/constants";
import {
    getAgentList,
    getPlayerList,
    getTransactionData,
} from "@/store/actions";
import { arrayToString, defaultDateTimeFormat, today } from "@/utils";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row,
} from "reactstrap";

const TransactionReports = () => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.user);
    const { list: agentList } = useSelector((state) => state.agent);
    const { list: playerList } = useSelector((state) => state.player);
    const { list, isLoading } = useSelector((state) => state.transaction);

    const [userTypeSelected, setUserTypeSelected] = useState(null);

    const [search, setSearch] = useState("");
    const [sortBy, setSortBy] = useState({
        field: "",
        type: "asc",
    });

    const transactionTypeIds = "2|3|4";

    const defaultParams = {
        account_no: "",
        per_page: 10,
        page: 1,
        search: "",
        sort_by:
            sortBy.field && sortBy.type ? `${sortBy.field} ${sortBy.type}` : "",
        "filter_by[transaction_type_id]": transactionTypeIds,
        "date_range[transactions.created_at][from]": today(),
        "date_range[transactions.created_at][to]": today(),
    };

    const [data, setData] = useState(defaultParams);

    const form = useFormik({
        enableReinitialize: true,
        initialValues: {
            userType: "",
            userFilter: "",
            type: "",
            dateFilter: [],
        },
        onSubmit: (values) => {
            setData({
                ...data,
                "filter_by[user_profiles.account_no]": values.userFilter
                    ? arrayToString(
                          values.userFilter.map((item) => {
                              return item.value;
                          })
                      )
                    : null,
                "filter_by[transaction_type_id]":
                    values.type.length > 0
                        ? arrayToString(
                              values.type.map((item) => {
                                  return item.value;
                              })
                          )
                        : transactionTypeIds,
                "date_range[transactions.created_at][from]": values.dateFilter
                    ? values.dateFilter[0]
                    : data["date_range[transactions.created_at][from]"],
                "date_range[transactions.created_at][to]": values.dateFilter
                    ? values.dateFilter[1]
                    : data["date_range[transactions.created_at][to]"],
            });
        },
    });

    const [extraData, setExtraData] = useState({
        key_token: user.keyToken,
        user_type: form.values.userFilter,
    });

    const tableTheadData = [
        {
            label: "Transaction No.",
            field: "transaction_no",
            sortable: true,
            render: (item) => (
                <TextLink
                    text={item.transaction_no}
                    path={getPath(Routes.TRANSACTION_VIEW).replace(
                        ":transactionNo",
                        item?.transaction_no
                    )}
                />
            ),
        },
        {
            label: "Account No.",
            field: "account_no",
            sortable: true,
        },
        {
            label: "Type",
            field: "type",
            sortable: false,
        },
        {
            label: "Amount",
            field: "amount",
            sortable: false,
            render: (item) => <AmountFormat amount={item?.amount} />,
        },
        {
            label: "Balance Before",
            field: "balance_before",
            sortable: false,
            render: (item) => <AmountFormat amount={item?.balance_before} />,
        },
        {
            label: "Balance After",
            field: "balance_after",
            sortable: false,
            render: (item) => <AmountFormat amount={item?.balance_after} />,
        },
        {
            label: "Date",
            field: "created_at",
            sortable: true,
            render: (item) => defaultDateTimeFormat(item.created_at),
        },
    ];

    const changeUserType = (option) => {
        setUserTypeSelected(option);
        form.setFieldValue("userType", option);
        form.setFieldValue("userFilter", "");
        setExtraData({
            ...extraData,
            user_type: option.value,
        });
    };

    const handleClear = () => {
        setUserTypeSelected(null);
        setData(defaultParams);
        form.resetForm();
    };

    useEffect(() => {
        dispatch(getTransactionData(data));
    }, [dispatch, data]);

    return (
        <React.Fragment>
            <Title title={getPathTitle(Routes.TRANSACTIONS)} />
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb
                        parent={{
                            isParent: true,
                            pageTitle: getPathTitle(Routes.TRANSACTIONS),
                            pageLink: getPath(Routes.TRANSACTIONS),
                        }}
                    />
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardHeader className="align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">
                                        {getPathTitle(Routes.TRANSACTIONS)}
                                    </h4>
                                </CardHeader>
                                <CardBody>
                                    <Row className="mb-3">
                                        <Col md={4}>
                                            <InputReactSelect
                                                label="User Type"
                                                isClearable={false}
                                                options={userTypeOptions}
                                                onChange={(option) => {
                                                    changeUserType(option);
                                                }}
                                            />
                                        </Col>
                                        <Col md={4}>
                                            <InputReactSelect
                                                label="Transaction Type"
                                                name="type"
                                                isClearable={true}
                                                options={transactionTypeOptions}
                                                value={form.values.type}
                                                onChange={(option) => {
                                                    form.setFieldValue(
                                                        "type",
                                                        option
                                                    );
                                                }}
                                                isMulti={true}
                                            />
                                        </Col>
                                        <Col md={4}>
                                            <FlatPickerDate
                                                label="Date"
                                                name="dateFilter"
                                                form={form}
                                                mode="range"
                                                value={form.values.dateFilter}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col>
                                            {userTypeSelected && (
                                                <AsyncReactSelect
                                                    label={
                                                        userTypeSelected?.label
                                                    }
                                                    name="userFilter"
                                                    isMulti={true}
                                                    fetchData={
                                                        userTypeSelected?.value ===
                                                        "player"
                                                            ? getPlayerList
                                                            : getAgentList
                                                    }
                                                    data={
                                                        userTypeSelected?.value ===
                                                        "player"
                                                            ? playerList
                                                            : agentList
                                                    }
                                                    form={form}
                                                    value={
                                                        form.values.userFilter
                                                    }
                                                    dataField={{
                                                        label: "account_no|name",
                                                        value: "account_no",
                                                    }}
                                                    onChange={(options) => {
                                                        form.setFieldValue(
                                                            "userFilter",
                                                            options
                                                        );
                                                    }}
                                                    extraData={extraData}
                                                    sortBy="name asc"
                                                />
                                            )}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} className="text-center">
                                            <Button
                                                color="primary"
                                                className="mx-1"
                                                onClick={() => handleClear()}>
                                                Clear
                                            </Button>
                                            <Button
                                                color="success"
                                                className="mx-1"
                                                onClick={() =>
                                                    form.handleSubmit()
                                                }>
                                                Filter
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <Table
                                        search={search}
                                        handlerSearch={setSearch}
                                        sortBy={sortBy}
                                        handlerSortBy={setSortBy}
                                        thead={tableTheadData}
                                        handlerChangeData={setData}
                                        data={data}
                                        collection={list}
                                        isLoading={isLoading}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default TransactionReports;
