import { EditInput, InputSwitch, Select } from "@/components/atoms";
import { confirmation } from "@/components/constants/common";
import { CommissionsSettingsInput } from "@/components/molecules";
import {
    commissionRatePerGameOffering,
    commissionTransferSchedules,
    numberOfCutoffToResetNegativeCommissionValidation,
} from "@/constants";
import { getSetting, userRole } from "@/hooks";
import {
    applyDownlinesCommissionTransferScheduleAgent,
    enableNegativeCarryOverCommission,
    enableValidTurnoverCommission,
    getAgentProfile,
    updateNumberOfCutoffToResetNegativeCommission,
    updatePendingCommissionRequiredToResetNegativeCommission,
    updateUserProfileSetting,
    updateUserProfileSettingWithBalanceCategory,
} from "@/store/actions";
import classnames from "classnames";
import React, { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Nav,
    NavItem,
    NavLink,
    Row,
} from "reactstrap";

const CommissionsSettings = ({
    userType,
    showEditMerchant,
    showEditBCF,
    commissionRateErrors,
    profile,
}) => {
    const dispatch = useDispatch();
    const { accountNo } = useParams();

    const { isSuperAdmin, isOperator } = userRole();

    const [activeNav, setActiveNav] = useState("merchant");
    const [isShowSettings, setIsShowSettings] = useState(false);

    const navs = useMemo(
        () => [
            { label: "Merchant", value: "merchant" },
            { label: "BCF", value: "bcf" },
        ],
        []
    );

    const vtoCommissionRateEnabled =
        getSetting("enable_valid_turnover_commission_rate") === "1";

    const filteredCommissionRatePerGameOffering =
        commissionRatePerGameOffering.filter((item) => {
            if (!vtoCommissionRateEnabled) {
                return !item.value.includes("vto");
            } else {
                return true;
            }
        });

    // const { commissionRateErrors, profile } = useSelector((state) => state.agent);

    const initialize = () => {
        dispatch(getAgentProfile(accountNo));
    };

    const savePendingCommissionRequired = (value, balanceCategory) => {
        dispatch(
            updatePendingCommissionRequiredToResetNegativeCommission(
                { value },
                balanceCategory,
                profile.userProfilekeyToken,
                initialize
            )
        );
    };

    const saveNumberOfCutoffToReset = (value, balanceCategory) => {
        dispatch(
            updateNumberOfCutoffToResetNegativeCommission(
                { value },
                balanceCategory,
                profile.userProfilekeyToken,
                initialize
            )
        );
    };

    const changeAllowCarryOverCommissionStatus = (balanceCategory, value) => {
        confirmation({
            text: `Are you sure you want to ${
                value ? "DEACTIVATE" : "ACTIVATE"
            } the carry over commission for this agent`,
            icon: "warning",
            confirmButtonText: "Confirm",
            action: () => {
                dispatch(
                    enableNegativeCarryOverCommission(
                        { value: value ? "0" : "1" },
                        balanceCategory,
                        profile.userProfilekeyToken,
                        initialize
                    )
                );
            },
        });
    };

    const changeValidTurnoverCommissionStatus = (balanceCategory, value) => {
        confirmation({
            text: `Are you sure you want to ${
                value ? "DEACTIVATE" : "ACTIVATE"
            } the VTO commission for this agent`,
            icon: "warning",
            confirmButtonText: "Confirm",
            action: () => {
                dispatch(
                    enableValidTurnoverCommission(
                        { value: value ? 0 : 1 },
                        balanceCategory,
                        profile.userProfilekeyToken,
                        initialize
                    )
                );
            },
        });
    };

    const changeCommissionTransferSchedule = (
        gameOffering,
        scheduleType,
        label
    ) => {
        let additionalMessageLabel = "";
        let additionalMessage = "";
        // let type =
        //     gameOffering === "sports" ? `${activeNav}-sports` : activeNav;
        let type = activeNav;

        switch (gameOffering) {
            case "sports":
                type = `${activeNav}-sports`;
                break;

            case "lotto":
                type = `${activeNav}-lotto`;
                break;

            default:
                type = activeNav;
                break;
        }

        if (["bcf-sports", "merchant-sports"].includes(type)) {
            additionalMessageLabel =
                type === "bcf-sports"
                    ? "Sports/E-Sports/Live Casino BCF (mPHP)"
                    : "Sports/E-Sports/Live Casino Merchant (Php)";
            additionalMessage = `Updating this will also enables commission separation for ${additionalMessageLabel}`;
        }
        
        confirmation({
            text: `Are you sure you want to change the transfer schedule of ${gameOffering} to ${scheduleType} of this master agent. ${additionalMessage}`,
            icon: "warning",
            confirmButtonText: "Confirm",
            action: () => {
                dispatch(
                    updateUserProfileSetting(
                        `update-commission-transfer-schedule/${type}`,
                        { value: scheduleType },
                        profile.userProfilekeyToken,
                        initialize
                    )
                );
            },
        });
    };

    const changeSportsBettingCommissionSeparationStatus = (
        balanceCategory,
        value
    ) => {
        const isEditable =
            balanceCategory === "bcf"
                ? profile.separateSportsBettingCommissionBcfEditable ?? false
                : profile.separateSportsBettingCommissionMerchantEditable ??
                  false;

        if (isEditable) {
            confirmation({
                text: `Are you sure you want to ${
                    value ? "DEACTIVATE" : "ACTIVATE"
                } the (sports/e-sports/live casino) commission separation for this agent`,
                icon: "warning",
                confirmButtonText: "Confirm",
                action: () => {
                    dispatch(
                        updateUserProfileSettingWithBalanceCategory(
                            "enable-separate-sports-betting-commission",
                            { value: value ? 0 : 1 },
                            balanceCategory,
                            profile.userProfilekeyToken,
                            initialize
                        )
                    );
                },
            });
        }
    };

    const changeApplyToDownlinesCommissionTransferSchedule = (
        gameType,
        value
    ) => {

        let type = activeNav;
        let text = "";
        switch (gameType) {
            case "sports":
                type = `${activeNav}-sports`;
                text = "Sports/E-Sports/Live Casino"

                break;

            case "lotto":
                type = `${activeNav}-lotto`;
                text = "Lotto"
                break;

            default:
                type = activeNav;
                text = "E-Games/Slots"
                break;
        }

        confirmation({
            title: `Are you sure you want to ${
                value ? "DEACTIVATE" : "ACTIVATE"
            } `,
            text: `${text} Apply to Downlines Commission Transfer Schedule`,
            icon: "warning",
            confirmButtonText: "Confirm",
            action: () => {
                dispatch(
                    applyDownlinesCommissionTransferScheduleAgent(
                        {
                            value: value ? 0 : 1,
                            balanceType: type,
                        },
                        profile?.userProfilekeyToken,
                        initialize
                    )
                );
            },
        });
    };

    const details = [
        {
            value: (
                <div>
                    <p className="tw-font-bold text-center mb-0 flex-grow-1">
                        Commission Transfer Schedule
                    </p>
                </div>
            ),
            isShow: isSuperAdmin || (activeNav === "bcf" && isOperator),
        },
        {
            label: `E-Games/Slots`,
            render: () => (
                <div>
                    <Select
                        name="commissionTransferScheduleCasino"
                        isClearable={false}
                        value={
                            activeNav === "bcf"
                                ? profile.commissionTransferScheduleBcf
                                : profile.commissionTransferScheduleMerchant
                        }
                        disabled={
                            activeNav === "bcf"
                                ? !profile?.commissionTransferScheduleBcfEditable
                                : !profile?.commissionTransferScheduleMerchantEditable
                        }
                        options={commissionTransferSchedules}
                        onChange={(e) => {
                            console.log(e.target.value);
                            changeCommissionTransferSchedule(
                                "casino",
                                e.target.value
                            );
                        }}
                    />
                </div>
            ),
            isShow: isSuperAdmin || (activeNav === "bcf" && isOperator),
        },
        {
            label: `Sports/E-Sports/Live Casino`,
            render: () => (
                <div>
                    <Select
                        name="commissionTransferScheduleSport"
                        isClearable={false}
                        value={
                            activeNav === "bcf"
                                ? profile.commissionTransferScheduleBcfSports
                                : profile.commissionTransferScheduleMerchantSports
                        }
                        disabled={
                            activeNav === "bcf"
                                ? !profile?.commissionTransferScheduleBcfSportsEditable
                                : !profile?.commissionTransferScheduleMerchantSportsEditable
                        }
                        options={commissionTransferSchedules}
                        onChange={(e) => {
                            changeCommissionTransferSchedule(
                                "sports",
                                e.target.value
                            );
                        }}
                    />
                </div>
            ),
            isShow: isSuperAdmin || (activeNav === "bcf" && isOperator),
        },
        ...process.env.REACT_APP_LOTTO_ENABLED === "true" ? [
            {
                label: `Lotto`,
                render: () => (
                    <div>
                        <Select
                            name="commissionTransferScheduleLotto"
                            isClearable={false}
                            value={
                                activeNav === "bcf"
                                    ? profile.commissionTransferScheduleBcfLotto
                                    : profile.commissionTransferScheduleMerchantLotto
                            }
                            disabled={
                                activeNav === "bcf"
                                    ? !profile?.commissionTransferScheduleBcfLottoEditable
                                    : !profile?.commissionTransferScheduleMerchantLottoEditable
                            }
                            options={commissionTransferSchedules}
                            onChange={(e) => {
                                changeCommissionTransferSchedule(
                                    "lotto",
                                    e.target.value
                                );
                            }}
                        />
                    </div>
                ),
                isShow: isSuperAdmin || (activeNav === "bcf" && isOperator),
            },
        ] : [],
        ...(profile?.role?.slug === "master-agent"
            ? [
                  {
                      value: (
                          <div>
                              <hr />
                              <p className="tw-font-bold text-center mb-0 flex-grow-1">
                                  Apply to Downlines Commission Transfer
                                  Schedule
                              </p>
                          </div>
                      ),
                      isShow: isSuperAdmin || (activeNav === "bcf" && isOperator),
                  },
                  {
                      label: `E-Games/Slots`,
                      render: () => (
                          <div>
                              <InputSwitch
                                  id={`apply-to-downlines-commission-transafer-schedule-casino`}
                                  isChecked={
                                      activeNav === "bcf"
                                          ? profile?.applyToDownlinesCommissionTransferScheduleBcf
                                          : profile?.applyToDownlinesCommissionTransferScheduleMerchant
                                  }
                                  disabled={
                                      activeNav === "bcf"
                                          ? !profile?.applyToDownlinesCommissionTransferScheduleBcfEditable
                                          : !profile?.applyToDownlinesCommissionTransferScheduleMerchantEditable
                                  }
                                  color="success"
                                  onChange={(e) =>
                                      changeApplyToDownlinesCommissionTransferSchedule(
                                          "casino",
                                          activeNav === "bcf"
                                              ? profile?.applyToDownlinesCommissionTransferScheduleBcf
                                              : profile?.applyToDownlinesCommissionTransferScheduleMerchant
                                      )
                                  }
                              />
                          </div>
                      ),
                      isShow: isSuperAdmin || (activeNav === "bcf" && isOperator),
                  },
                  {
                      label: `(Sports/E-Sports/Live Casino)`,
                      render: () => (
                          <div>
                              <InputSwitch
                                  id={`apply-to-downlines-commission-transafer-schedule-sports`}
                                  isChecked={
                                      activeNav === "bcf"
                                          ? profile?.applyToDownlinesCommissionTransferScheduleBcfSports
                                          : profile?.applyToDownlinesCommissionTransferScheduleMerchantSports
                                  }
                                  disabled={
                                      activeNav === "bcf"
                                          ? !profile?.applyToDownlinesCommissionTransferScheduleBcfSportsEditable
                                          : !profile?.applyToDownlinesCommissionTransferScheduleMerchantSportsEditable
                                  }
                                  color="success"
                                  onChange={(e) =>
                                      changeApplyToDownlinesCommissionTransferSchedule(
                                          "sports",
                                          activeNav === "bcf"
                                              ? profile?.applyToDownlinesCommissionTransferScheduleBcfSports
                                              : profile?.applyToDownlinesCommissionTransferScheduleMerchantSports
                                      )
                                  }
                              />
                          </div>
                      ),
                      isShow: isSuperAdmin || (activeNav === "bcf" && isOperator),
                  },
                  ...process.env.REACT_APP_LOTTO_ENABLED === "true" ? [
                    {
                        label: `Lotto`,
                        render: () => (
                            <div>
                                <InputSwitch
                                    id={`apply-to-downlines-commission-transafer-schedule-lotto`}
                                    isChecked={
                                        activeNav === "bcf"
                                            ? profile?.applyToDownlinesCommissionTransferScheduleBcfLotto
                                            : profile?.applyToDownlinesCommissionTransferScheduleMerchantLotto
                                    }
                                    disabled={
                                        activeNav === "bcf"
                                            ? !profile?.applyToDownlinesCommissionTransferScheduleBcfLottoEditable
                                            : !profile?.applyToDownlinesCommissionTransferScheduleMerchantLottoEditable
                                    }
                                    color="success"
                                    onChange={(e) =>
                                        changeApplyToDownlinesCommissionTransferSchedule(
                                            "lotto",
                                            activeNav === "bcf"
                                                ? profile?.applyToDownlinesCommissionTransferScheduleBcfLotto
                                                : profile?.applyToDownlinesCommissionTransferScheduleMerchantLotto
                                        )
                                    }
                                />
                            </div>
                        ),
                        isShow: isSuperAdmin || (activeNav === "bcf" && isOperator),
                    },
                  ] : []
              ]
            : []),
        ...(activeNav === "bcf"
            ? [
                  {
                      value: (
                          <div>
                              <hr />

                              <p className="tw-font-bold text-center mb-0 flex-grow-1">
                                  Enable BCF VTO Commission
                              </p>
                          </div>
                      ),
                      isShow:
                          (isSuperAdmin || isOperator) &&
                          vtoCommissionRateEnabled,
                  },
                  {
                      label: `Enable`,
                      render: () => (
                          <div>
                              <InputSwitch
                                  id={`enable-vto-commission-bcf`}
                                  isChecked={
                                      profile?.validTurnoverBCfEnabled ?? false
                                  }
                                  color="success"
                                  onChange={() =>
                                      changeValidTurnoverCommissionStatus(
                                          "bcf",
                                          profile?.validTurnoverBCfEnabled ??
                                              false
                                      )
                                  }
                              />
                          </div>
                      ),
                      isShow:
                          (isSuperAdmin || isOperator) &&
                          vtoCommissionRateEnabled,
                  },

                  {
                      value: (
                          <div>
                              <hr />
                              <p className="tw-font-bold text-center mb-0 flex-grow-1">
                                  BCF (Sports/E-Sports/Live Casino) Separation
                                  Commission
                              </p>
                          </div>
                      ),
                      isShow: isSuperAdmin || isOperator,
                  },
                  {
                      label: `Enable`,
                      render: () => (
                          <div>
                              <InputSwitch
                                  id={`enable-sports-separation-commission-bcf`}
                                  isChecked={
                                      profile?.separateSportsBettingCommissionBcf ??
                                      false
                                  }
                                  color="success"
                                  disabled={
                                      !profile?.separateSportsBettingCommissionBcfEditable ??
                                      false
                                  }
                                  onChange={() =>
                                      changeSportsBettingCommissionSeparationStatus(
                                          "bcf",
                                          profile?.separateSportsBettingCommissionBcf ??
                                              false
                                      )
                                  }
                              />
                          </div>
                      ),
                      isShow: isSuperAdmin || isOperator,
                  },

                  {
                      value: (
                          <div>
                              <hr />
                              <p className="tw-font-bold text-center mb-0 flex-grow-1">
                                  Allow Negative Carry Over Commission Bcf{" "}
                                  {profile?.separateSportsBettingCommissionBcf ??
                                  false
                                      ? "(E-Games/Slots)"
                                      : ""}
                              </p>
                          </div>
                      ),
                      isShow: isSuperAdmin || isOperator,
                  },
                  {
                      label: `Enable`,
                      render: () => (
                          <div>
                              <InputSwitch
                                  id={`enable-carry-over-commission-bcf`}
                                  isChecked={
                                      profile?.allowNegativeCarryOverCommissionBcf ??
                                      false
                                  }
                                  color="success"
                                  onChange={() =>
                                      changeAllowCarryOverCommissionStatus(
                                          "bcf",
                                          profile?.allowNegativeCarryOverCommissionBcf ??
                                              false
                                      )
                                  }
                              />
                          </div>
                      ),
                      isShow: isSuperAdmin || isOperator,
                  },

                  {
                      value: (
                          <div>
                              <hr />
                              <p className="tw-font-bold text-center mb-0 flex-grow-1">
                                  Allow Negative Carry Over Commission Bcf
                                  (Sports/E-Sports/Live Casino)
                              </p>
                          </div>
                      ),
                      isShow:
                          (isSuperAdmin || isOperator) &&
                          (profile?.separateSportsBettingCommissionBcf ??
                              false),
                  },
                  {
                      label: `Enable`,
                      render: () => (
                          <div>
                              <InputSwitch
                                  id={`enable-carry-over-commission-bcf`}
                                  isChecked={
                                      profile?.allowNegativeCarryOverCommissionBcfSports ??
                                      false
                                  }
                                  color="success"
                                  onChange={() =>
                                      changeAllowCarryOverCommissionStatus(
                                          "bcf-sports",
                                          profile?.allowNegativeCarryOverCommissionBcfSports ??
                                              false
                                      )
                                  }
                              />
                          </div>
                      ),
                      isShow:
                          (isSuperAdmin || isOperator) &&
                          (profile?.separateSportsBettingCommissionBcf ??
                              false),
                  },
                ...process.env.REACT_APP_LOTTO_ENABLED === "true" ? [

                    {
                        label: `Enable (Lotto)`,
                        render: () => (
                            <div>
                                <InputSwitch
                                    id={`enable-carry-over-commission-bcf-lotto`}
                                    isChecked={
                                        profile?.allowNegativeCarryOverCommissionBcfLotto ??
                                        false
                                    }
                                    color="success"
                                    onChange={() =>
                                        changeAllowCarryOverCommissionStatus(
                                            "bcf-lotto",
                                            profile?.allowNegativeCarryOverCommissionBcfLotto ??
                                                false
                                        )
                                    }
                                />
                            </div>
                        ),
                        isShow:
                            (isSuperAdmin || isOperator),
                    },
                ] : []
              ]
            : []),

        ...(activeNav === "merchant"
            ? [
                  {
                      value: (
                          <div>
                              <hr />
                              <div className="tw-font-bold text-center mb-0 flex-grow-1">
                                  Enable Merchant VTO Commission
                              </div>
                          </div>
                      ),
                      isShow: isSuperAdmin && vtoCommissionRateEnabled,
                  },
                  {
                      label: `Enable`,
                      render: () => (
                          <div>
                              <InputSwitch
                                  id={`enable-vto-commission-merchant`}
                                  isChecked={
                                      profile?.validTurnoverMerchantEnabled ??
                                      false
                                  }
                                  color="success"
                                  onChange={() =>
                                      changeValidTurnoverCommissionStatus(
                                          "merchant",
                                          profile?.validTurnoverMerchantEnabled ??
                                              false
                                      )
                                  }
                              />
                          </div>
                      ),
                      isShow: isSuperAdmin && vtoCommissionRateEnabled,
                  },

                  {
                      value: (
                          <div>
                              <hr />

                              <p className="tw-font-bold text-center mb-0 flex-grow-1">
                                  Merchant (Sports/E-Sports/Live Casino)
                                  Separation Commission
                              </p>
                          </div>
                      ),
                      isShow: isSuperAdmin,
                  },
                  {
                      label: `Enable`,
                      render: () => (
                          <div>
                              <InputSwitch
                                  id={`enable-sports-separation-commission-merchant`}
                                  isChecked={
                                      profile?.separateSportsBettingCommissionMerchant ??
                                      false
                                  }
                                  color="success"
                                  disabled={
                                      !profile?.separateSportsBettingCommissionMerchantEditable ??
                                      false
                                  }
                                  onChange={() =>
                                      changeSportsBettingCommissionSeparationStatus(
                                          "merchant",
                                          profile?.separateSportsBettingCommissionMerchant ??
                                              false
                                      )
                                  }
                              />
                          </div>
                      ),
                      isShow: isSuperAdmin,
                  },

                  {
                      value: (
                          <div>
                              <hr />
                              <p className="tw-font-bold text-center mb-0 flex-grow-1">
                                  Allow Negative Carry Over Commission Merchant{" "}
                                  {profile?.separateSportsBettingCommissionMerchant ??
                                  false
                                      ? "(E-Games/Slots)"
                                      : ""}
                              </p>
                          </div>
                      ),
                      isShow: isSuperAdmin,
                  },
                  {
                      label: `Enable`,
                      render: () => (
                          <div>
                              <InputSwitch
                                  id={`enable-carry-over-commission-merchant`}
                                  isChecked={
                                      profile?.allowNegativeCarryOverCommissionMerchant ??
                                      false
                                  }
                                  color="success"
                                  onChange={() =>
                                      changeAllowCarryOverCommissionStatus(
                                          "merchant",
                                          profile?.allowNegativeCarryOverCommissionMerchant ??
                                              false
                                      )
                                  }
                              />
                          </div>
                      ),
                      isShow: isSuperAdmin,
                  },

                  {
                      value: (
                          <div>
                              <hr />
                              <p className="tw-font-bold text-center mb-0 flex-grow-1">
                                  Allow Negative Carry Over Commission Merchant
                                  (Sports/E-Sports/Live Casino)
                              </p>
                          </div>
                      ),
                      isShow:
                          isSuperAdmin &&
                          (profile?.separateSportsBettingCommissionMerchant ??
                              false),
                  },
                  {
                      label: `Enable`,
                      render: () => (
                          <div>
                              <InputSwitch
                                  id={`enable-carry-over-commission-merchant`}
                                  isChecked={
                                      profile?.allowNegativeCarryOverCommissionMerchantSports ??
                                      false
                                  }
                                  color="success"
                                  onChange={() =>
                                      changeAllowCarryOverCommissionStatus(
                                          "merchant-sports",
                                          profile?.allowNegativeCarryOverCommissionMerchantSports ??
                                              false
                                      )
                                  }
                              />
                          </div>
                      ),
                      isShow:
                          isSuperAdmin &&
                          (profile?.separateSportsBettingCommissionMerchant ??
                              false),
                  },
                ...process.env.REACT_APP_LOTTO_ENABLED === "true" ? [

                    {
                        label: `Enable (Lotto)`,
                        render: () => (
                            <div>
                                <InputSwitch
                                    id={`enable-carry-over-commission-merchant-lotto`}
                                    isChecked={
                                        profile?.allowNegativeCarryOverCommissionMerchantLotto ??
                                        false
                                    }
                                    color="success"
                                    onChange={() =>
                                        changeAllowCarryOverCommissionStatus(
                                            "merchant-lotto",
                                            profile?.allowNegativeCarryOverCommissionMerchantLotto ??
                                                false
                                        )
                                    }
                                />
                            </div>
                        ),
                        isShow:
                            isSuperAdmin,
                    },
                ] : []
              ]
            : []),
    ];

    const pendingCommissionRequiredToResetNegativeCommissions = {
        bcf: [
            {
                label: `${
                    profile?.separateSportsBettingCommissionBcf ?? false
                        ? "E-GAMES/SLOTS "
                        : ""
                }(%)`,
                value: "pendingCommissionRequiredToResetNegativeCommissionBcf",
                balanceCategory: "bcf",
                isShow: true,
            },
            {
                label: "SPORTS/E-SPORTS/LIVE CASINO (%)",
                value: "pendingCommissionRequiredToResetNegativeCommissionBcfSports",
                balanceCategory: "bcf-sports",
                isShow: profile?.separateSportsBettingCommissionBcf ?? false,
            },
            ...process.env.REACT_APP_LOTTO_ENABLED === "true" ? [
                {
                    label: `Lotto (%)`,
                    value: "pendingCommissionRequiredToResetNegativeCommissionBcfLotto",
                    balanceCategory: "bcf-lotto",
                    isShow: true,
                },
            ] : []
        ],
        merchant: [
            {
                label: `${
                    profile?.separateSportsBettingCommissionMerchant ?? false
                        ? "E-GAMES/SLOTS "
                        : ""
                }(%)`,
                value: "pendingCommissionRequiredToResetNegativeCommissionMerchant",
                balanceCategory: "merchant",
                isShow: true,
            },
            {
                label: "SPORTS/E-SPORTS/LIVE CASINO (%)",
                value: "pendingCommissionRequiredToResetNegativeCommissionMerchantSports",
                balanceCategory: "merchant-sports",
                isShow:
                    profile?.separateSportsBettingCommissionMerchant ?? false,
            },
            ...process.env.REACT_APP_LOTTO_ENABLED === "true" ? [
                {
                    label: `Lotto (%)`,
                    value: "pendingCommissionRequiredToResetNegativeCommissionMerchantLotto",
                    balanceCategory: "merchant-lotto",
                    isShow: true,
                },
            ] : []
        ],
    };

    const numberOfCutoffToResetNegativeCommission = {
        bcf: [
            {
                label: `${
                    profile?.separateSportsBettingCommissionBcf ?? false
                        ? "E-GAMES/SLOTS "
                        : ""
                }`,

                value: "noOfCutoffToResetNegativeCommissionBcf",
                balanceCategory: "bcf",
                isShow: true,
            },
            {
                label: "SPORTS/E-SPORTS/LIVE CASINO",
                value: "noOfCutoffToResetNegativeCommissionBcfSports",
                balanceCategory: "bcf-sports",
                isShow: profile?.separateSportsBettingCommissionBcf ?? false,
            },
            ...process.env.REACT_APP_LOTTO_ENABLED === "true" ? [
                {
                    label: `Lotto`,
                    value: "noOfCutoffToResetNegativeCommissionBcfLotto",
                    balanceCategory: "bcf-lotto",
                    isShow: true,
                },
            ] : []
        ],
        merchant: [
            {
                label: `${
                    profile?.separateSportsBettingCommissionMerchant ?? false
                        ? "E-GAMES/SLOTS "
                        : ""
                }`,
                value: "noOfCutoffToResetNegativeCommissionMerchant",
                balanceCategory: "merchant",
                isShow: true,
            },
            {
                label: "SPORTS/E-SPORTS/LIVE CASINO",
                value: "noOfCutoffToResetNegativeCommissionMerchantSports",
                balanceCategory: "merchant-sports",
                isShow:
                    profile?.separateSportsBettingCommissionMerchant ?? false,
            },
            ...process.env.REACT_APP_LOTTO_ENABLED === "true" ? [
                {
                    label: `Lotto`,
                    value: "noOfCutoffToResetNegativeCommissionMerchantLotto",
                    balanceCategory: "merchant-lotto",
                    isShow: true,
                },
            ] : []
        ],
    };

    return (
        <>
            <p className="text-uppercase fw-semibold fs-14 text-muted mb-1 tw-w-4/6 tw-overflow-hidden tw-truncate">
                Commissions Information
            </p>
            <Card>
                <CardHeader>
                    <Nav
                        className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                        role="tablist"
                    >
                        {navs.map((item, key) => (
                            <NavItem key={key}>
                                <NavLink
                                    className={classnames({
                                        active: activeNav === item.value,
                                    })}
                                    onClick={() => setActiveNav(item.value)}
                                    type="button"
                                >
                                    <i className="far fa-user"></i>
                                    {item.label}
                                </NavLink>
                            </NavItem>
                        ))}
                    </Nav>
                </CardHeader>

                <CardBody className="py-4">
                    <Row>
                        <Col
                            {...(isShowSettings
                                ? { xs: 12, md: 7 }
                                : { xs: 12 })}
                        >
                            <Row className="mt-3">
                                <Col xs="12">
                                    <div className="tw-flex tw-justify-between">
                                        <p className="text-uppercase fw-semibold fs-14 text-muted mb-1 tw-w-4/6 tw-overflow-hidden tw-truncate">
                                            Fixed Commission Rate
                                        </p>
                                        {!isShowSettings && accountNo && (
                                            <div
                                                onClick={() =>
                                                    setIsShowSettings(true)
                                                }
                                                className="link-warning link-offset-2 text-decoration-underline link-underline-opacity-25 link-underline-opacity-100-hover tw-cursor-pointer"
                                            >
                                                Commissions Settings
                                                <span className="ri-arrow-right-s-line" />
                                            </div>
                                        )}
                                    </div>
                                </Col>
                                <Col xs="12" className="mx-3 mt-3">
                                    {filteredCommissionRatePerGameOffering.map(
                                        // eslint-disable-next-line array-callback-return
                                        (item, index) => {
                                            if (
                                                item.balanceCategory ===
                                                    "merchant" &&
                                                activeNav === "merchant"
                                            ) {
                                                return (
                                                    <CommissionsSettingsInput
                                                        errors={
                                                            commissionRateErrors
                                                        }
                                                        type={item.value}
                                                        commissionRate={
                                                            profile[item.value]
                                                        }
                                                        userProfilekeyToken={
                                                            profile.userProfilekeyToken
                                                        }
                                                        showEdit={
                                                            showEditMerchant
                                                        }
                                                        userType={userType}
                                                        title={item?.label?.replace(
                                                            "Commission Rate",
                                                            ""
                                                        )}
                                                        key={index}
                                                    />
                                                );
                                            } else if (
                                                item.balanceCategory ===
                                                    "bcf" &&
                                                activeNav === "bcf"
                                            ) {
                                                return (
                                                    <CommissionsSettingsInput
                                                        errors={
                                                            commissionRateErrors
                                                        }
                                                        type={item.value}
                                                        commissionRate={
                                                            profile[item.value]
                                                        }
                                                        userProfilekeyToken={
                                                            profile.userProfilekeyToken
                                                        }
                                                        showEdit={showEditBCF}
                                                        userType={userType}
                                                        title={item?.label?.replace(
                                                            "Commission Rate",
                                                            ""
                                                        )}
                                                        key={index}
                                                    />
                                                );
                                            }
                                        }
                                    )}
                                </Col>
                            </Row>
                            {isSuperAdmin && (
                                <>
                                    <Row className="mt-3">
                                        <Col xs="12">
                                            <div className="tw-flex tw-justify-between mt-3">
                                                <p className="text-uppercase fw-semibold fs-14 text-muted mb-1 tw-w-4/6 tw-overflow-hidden tw-truncate">
                                                    Pending Commission Required
                                                    To Reset Negative Commission
                                                </p>
                                            </div>
                                        </Col>
                                        <Col xs="12" className="mx-3 mt-3">
                                            {pendingCommissionRequiredToResetNegativeCommissions?.[
                                                activeNav
                                            ]?.map((item, key) => (
                                                <div
                                                    className={
                                                        item?.isShow
                                                            ? ""
                                                            : "d-none"
                                                    }
                                                    key={key}
                                                >
                                                    <EditInput
                                                        title={item?.label}
                                                        showEdit={isSuperAdmin}
                                                        type={
                                                            item?.balanceCategory
                                                        }
                                                        defaultValue={
                                                            profile?.[
                                                                item?.value
                                                            ]
                                                        }
                                                        onSave={
                                                            savePendingCommissionRequired
                                                        }
                                                    />
                                                </div>
                                            ))}
                                        </Col>
                                    </Row>
                                    <Row className="mt-3">
                                        <Col xs="12">
                                            <div className="tw-flex tw-justify-between mt-3">
                                                <p className="text-uppercase fw-semibold fs-14 text-muted mb-1 tw-w-4/6 tw-overflow-hidden tw-truncate">
                                                    No. of Cutoff to Reset
                                                    Negative Commission
                                                </p>
                                            </div>
                                        </Col>
                                        <Col xs="12" className="mx-3 mt-3">
                                            {numberOfCutoffToResetNegativeCommission?.[
                                                activeNav
                                            ]?.map((item, key) => (
                                                <div
                                                    className={
                                                        item?.isShow
                                                            ? ""
                                                            : "d-none"
                                                    }
                                                    key={key}
                                                >
                                                    <EditInput
                                                        title={item?.label}
                                                        showEdit={isSuperAdmin}
                                                        type={
                                                            item?.balanceCategory
                                                        }
                                                        validationSchema={
                                                            numberOfCutoffToResetNegativeCommissionValidation
                                                        }
                                                        defaultValue={
                                                            profile?.[
                                                                item?.value
                                                            ]
                                                        }
                                                        onSave={
                                                            saveNumberOfCutoffToReset
                                                        }
                                                    />
                                                </div>
                                            ))}
                                        </Col>
                                    </Row>
                                </>
                            )}
                        </Col>
                        {isShowSettings && (
                            <Col xs={12} md={5}>
                                <Card color="light" className="tw-ml-5">
                                    <div className="tw-flex tw-justify-between align-items-center d-flex p-3">
                                        <p className="text-uppercase fw-semibold fs-14 text-muted mb-1 tw-w-4/6 tw-overflow-hidden tw-truncate">
                                            Commissions Settings
                                        </p>
                                        <div
                                            onClick={() =>
                                                setIsShowSettings(false)
                                            }
                                            className="ri-close-line tw-cursor-pointer"
                                        />
                                    </div>
                                    <CardBody>
                                        <div className="table-card">
                                            <table className="table table-borderless mb-0">
                                                <tbody>
                                                    {details.map(
                                                        (item, key) => (
                                                            <tr
                                                                key={key}
                                                                className={
                                                                    !item.isShow
                                                                        ? "d-none"
                                                                        : ""
                                                                }
                                                            >
                                                                {item?.label && (
                                                                    <td className="fw-medium">
                                                                        {
                                                                            item.label
                                                                        }
                                                                    </td>
                                                                )}
                                                                <td
                                                                    colSpan={
                                                                        item?.label
                                                                            ? 1
                                                                            : 2
                                                                    }
                                                                >
                                                                    {item?.render
                                                                        ? item?.render()
                                                                        : item.value}
                                                                </td>
                                                            </tr>
                                                        )
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        )}
                    </Row>
                </CardBody>
            </Card>
        </>
    );
};

export default CommissionsSettings;
