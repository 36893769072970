import {
    boolStatus,
    commissionStatus,
    commonStatus,
    requestStatus,
    responseStatus,
    userStatus,
    userVerificationStatus,
    promotionTransactionStatus,
    affiliateRequestStatus,
} from "@/constants";
import React from "react";
import { Badge } from "reactstrap";

const StatusBadge = ({ status, type = "user" }) => {
    let data;
    if (type === "request") data = requestStatus;
    else if (type === "commission") data = commissionStatus;
    else if (type === "verification") data = userVerificationStatus;
    else if (type === "common") data = commonStatus;
    else if (type === "response") data = responseStatus;
    else if (type === "promotionTransaction") data = promotionTransactionStatus;
    else if (type === "affiliateRequest") data = affiliateRequestStatus;
    else if (type === "bool") data = boolStatus;
    else data = userStatus;

    let statusData = data.find((item) => item.value === status);

    return (
        <>
            {status && statusData && (
                <Badge color={statusData.color}>{statusData.label}</Badge>
            )}
            {!status && <Badge>status</Badge>}
        </>
    );
};

export default StatusBadge;
