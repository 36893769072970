import {
    AmountFormat,
    Table,
    TextLink,
    CounterWidget,
    Select,
    DataCollapse,
} from "@/components/atoms";
import { getPath, Routes, betStatus } from "@/constants";
import { getTransactionData } from "@/store/actions";
import { defaultDateTimeFormat, getStatusColor } from "@/utils";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Badge, Button, Col, Row } from "reactstrap";
import ProfileLayout from "../Layout";
import { totalCounter } from "@/constants/users";

const BetHistory = () => {
    const { accountNo } = useParams();
    const dispatch = useDispatch();
    const { list, isLoading } = useSelector((state) => state.transaction);

    const [search, setSearch] = useState("");
    const [collapse, setCollapse] = useState([]);
    const [sortBy, setSortBy] = useState({
        field: "",
        type: "asc",
    });
    const [data, setData] = useState({
        account_no: accountNo,
        per_page: 10,
        page: 1,
        search: "",
        status: "",
        sort_by:
            sortBy.field && sortBy.type ? `${sortBy.field} ${sortBy.type}` : "",
    });

    const [betType, setBetType] = useState("electronic-games");

    const reportTotalCounter = totalCounter(list);

    const tableTheadData = useMemo(() => {
        const handleCollapse = (id) => {
            if (collapse.includes(id)) {
                const selected = collapse.filter((value) => value !== id);
                setCollapse([...selected]);
            } else {
                setCollapse((collapse) => [...collapse, id]);
            }
        };

        let columns = [
            {
                label: "Transaction No.",
                field: "bet_transaction_no",
                sortable: true,
                render: (item) => (
                    <TextLink
                        text={item.bet_transaction_no}
                        path={getPath(Routes.BET_TRANSACTION_VIEW).replace(
                            ":transactionNo",
                            item?.bet_transaction_no
                        )}
                    />
                ),
            },
            {
                label: "Provider",
                field: "game_provider",
                sortable: true,
                render: (item) => item.game_provider,
            },
            {
                label: "Game",
                field: "game_name",
                sortable: true,
                render: (item) => item.game_name,
            },
            {
                label: "Status",
                field: "status",
                sortable: false,
                render: (item) => {
                    const status = item.settlement_datetime
                        ? item.status
                        : "unsettled";
                    return (
                        <Badge color={getStatusColor(status)}>{status}</Badge>
                    );
                },
            },
            {
                label: "Bet Amount",
                field: "bet_amount",
                sortable: false,
                render: (item) => <AmountFormat amount={item?.bet_amount} />,
            },
            {
                label: "Win Amount",
                field: "win_amount",
                sortable: false,
                render: (item) => <AmountFormat amount={item?.win_amount} />,
            },
            {
                label: "Preserve Amount",
                field: "preserve_amount",
                sortable: false,
                render: (item) => (
                    <AmountFormat amount={item?.preserve_amount} />
                ),
            },
            {
                label: "Final Balance",
                field: "win_balance_after",
                sortable: false,
                render: (item) =>
                    item?.status === "pending" ? (
                        "-"
                    ) : (
                        <AmountFormat amount={item?.win_balance_after} />
                    ),
            },
            {
                label: "Events",
                field: "events",
                sortable: false,
                render: (item) => (
                    <>
                        {item?.events ? (
                            <DataCollapse
                                collapseKey={item.bet_transaction_no}
                                data={item.events}
                                fields={[
                                    { field: "sports_name" },
                                    {
                                        field: "match_date",
                                        render: (value) =>
                                            defaultDateTimeFormat(value),
                                    },
                                    { field: "match_name" },
                                    {
                                        field: "selected_team",
                                        render: (value) => (
                                            <div className="text-primary">
                                                {value}
                                            </div>
                                        ),
                                    },
                                    {
                                        field: "result",
                                        render: (value) => value ?? "pending",
                                    },
                                    { field: "odds" },
                                ]}
                                collapse={collapse}
                                handleCollapse={handleCollapse}
                            />
                        ) : (
                            "-"
                        )}
                    </>
                ),
            },
            {
                label: "Bet Datetime",
                field: "bet_datetime",
                sortable: true,
                render: (item) => defaultDateTimeFormat(item?.bet_datetime),
            },
            {
                label: "Settlement Datetime",
                field: "settlement_datetime",
                sortable: true,
                render: (item) =>
                    defaultDateTimeFormat(item?.settlement_datetime),
            },
        ];

        if (!["sportsbetting", "esports"].includes(betType)) {
            columns = columns.filter((value) => value.field !== "events");
        }

        return columns;
    }, [collapse, betType]);

    let gameList = [
        {
            label: "E-Games",
            betType: "electronic-games",
            isActive: true,
        },
        {
            label: "Live Casino",
            betType: "live-casino",
            isActive: true,
        },
        {
            label: "Sports Betting",
            betType: "sportsbetting",
            isActive: true,
        },
        {
            label: "E-Sports",
            betType: "esports",
            isActive: true,
        },
        ...process.env.REACT_APP_LOTTO_ENABLED === "true" ? [
            {
                label: "Lotto",
                betType: "lotto",
                isActive: true,
            },
        ] : []
    ];

    useEffect(() => {
        dispatch(getTransactionData(data, "bet", betType));
    }, [dispatch, data, betType]);

    return (
        <ProfileLayout>
            <Row className="mb-3">
                <Col>
                    {gameList.map((item, key) => (
                        <Button
                            color={
                                betType === item.betType ? "primary" : "light"
                            }
                            onClick={() => setBetType(item.betType)}
                            className="me-2"
                            key={key}
                            disabled={!item.isActive}
                        >
                            {item.label}
                        </Button>
                    ))}
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <Row className="flex justify-content-center gx-2">
                        {reportTotalCounter.map((item, key) => (
                            <Col sm={12} md={4} key={key}>
                                <CounterWidget
                                    withBorder={true}
                                    item={item}
                                    icon="₱"
                                />
                            </Col>
                        ))}
                    </Row>
                    <Col md={6} className="tw-mb-3">
                        <Select
                            label="Status"
                            isClearable={true}
                            value={data.status}
                            options={[
                                ...[
                                    {
                                        label: "All",
                                        value: "",
                                    },
                                ],
                                ...betStatus,
                            ]}
                            onChange={(event) =>
                                setData({
                                    ...data,
                                    status: event.target.value,
                                    page: 1,
                                })
                            }
                        />
                    </Col>
                    <Table
                        search={search}
                        handlerSearch={setSearch}
                        sortBy={sortBy}
                        handlerSortBy={setSortBy}
                        thead={tableTheadData}
                        handlerChangeData={setData}
                        data={data}
                        collection={list}
                        isLoading={isLoading}
                    />
                </Col>
            </Row>
        </ProfileLayout>
    );
};

export default BetHistory;
