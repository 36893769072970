import React from "react";

const FormikErrorMessage = ({ validation, name }) => {
  return (
    <div>
      {validation?.errors[name] ? (
        <small className="text-danger text-sm">{validation.errors[name]}</small>
      ) : null}
    </div>
  );
};

export default FormikErrorMessage;
