import {
  Button,
  ErrorAlert,
  TextLink,
  StatusBadge,
  DisplayText,
} from "@/components/atoms";
import { addFeaturedGameValidation } from "@/constants";
import { useFormik } from "formik";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button as ButtonComponent,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { addFeaturedGame, resetFeaturedGameError } from "@/store/actions";
import { getUserProfileLink, filterDateFormat, arrayToString } from "@/utils";

const ViewNotificationModal = ({ isOpen, setIsOpen, data }) => {
  return (
    <Modal id="fundModal" isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
      <ModalHeader>
        <div className="modal-title">View Notification</div>
      </ModalHeader>
      <ModalBody>
        <Row className="mb-3">
          <Col md={12} className="mb-3">
            <DisplayText
              label="User"
              item={
                <TextLink
                  text={`${data.account_no} | ${data.username}`}
                  path={getUserProfileLink(data.account_no, "player")}
                />
              }
            />
          </Col>
          <Col md={12}>
            <DisplayText label="Title" item={data?.title} />
          </Col>
          <Col md={12}>
            <DisplayText label="Description" item={data?.description} />
          </Col>
          <Col md={12}>
            <DisplayText
              label="Is Read"
              item={
                <StatusBadge status={data.is_read ? "yes" : "no"} type="bool" />
              }
            />
          </Col>
        </Row>
        <Row>
            <Col className="text-end">
              <ButtonComponent
                color="light"
                onClick={() => setIsOpen(!isOpen)}
                className="me-2"
              >
                Close
              </ButtonComponent>
            </Col>
          </Row>
      </ModalBody>
    </Modal>
  );
};

export default ViewNotificationModal;
