import {
  DisplayText,
} from "@/components/atoms";
import { useFormik } from "formik";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button as ButtonComponent,
  Col,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { updateDrawScheduleGame } from "@/store/actions";

const UpdateLottoScheduleModal = ({
  isOpen,
  setIsOpen,
  data,
  initialize,
  isUpdating
}) => {
  const isMounted = useRef(false);
  const dispatch = useDispatch();

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      games: [],
    },
    onSubmit: (values) => {
      dispatch(updateDrawScheduleGame(values, data.key_token, result))
    },
  });

  const result = (isSuccess) => {
    if(isSuccess) {
        setIsOpen(false);
        validation.resetForm();
        initialize();
    }
  }

  const addDeleteValue = (value) => {
    let newValue = Object.assign([], validation.values.games);
    if (newValue.includes(value)) {
      newValue = newValue.filter((item) => item !== value);
    } else {
      newValue.push(value);
    }
    validation.setFieldValue("games", newValue);
  };

  return (
    <Modal
      id="updateLottoScheduleModal"
      isOpen={isOpen}
      toggle={() => setIsOpen(!isOpen)}
    >
      <ModalHeader>
        <div className="modal-title">Update Shedule</div>
      </ModalHeader>
      <ModalBody>
        <Form
          className="needs-validation"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row>
            <Col md="12">
              <DisplayText label="Select Game" />
            </Col>
            {data.games.map((game, index) => (
              <Col md={4} key={index} className="text-center ">
                <div className="relative">
                  <div
                    className={` tw-flex-row tw-cursor-pointer hover:tw-bg-primary p-2 tw-rounded-lg ${
                      validation.values.games.includes(game.key_token)
                        ? ""
                        : ""
                    }`}
                    onClick={() => addDeleteValue(game.key_token)}
                  >
                    <div>
                      <img src={game.banner_path} alt="icon" />
                    </div>
                    <div className="mt-2">{game.name}</div>
                  </div>
                  {validation.values.games.includes(game.key_token) && (
                    <i className="tw-absolute ri-checkbox-circle-fill text-success tw-text-2xl absolute tw-top-[-8px] tw-right-[45px]"></i>
                  )}
                </div>
              </Col>
            ))}
          </Row>
          
          <Row>
            <Col className="text-end">
              <ButtonComponent
                color="light"
                onClick={() => setIsOpen(!isOpen)}
                className="me-2"
              >
                Close
              </ButtonComponent>
              <ButtonComponent color="success" type="submit" text="Submit" disabled={validation.values.games.length <= 0 || isUpdating}>Save</ButtonComponent>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default UpdateLottoScheduleModal;
