import {
  DataCollapse,
  InputSwitch,
  JsonPretty,
  Table,
  Title,
  Button,
} from "@/components/atoms";
import BreadCrumb from "@/components/common/BreadCrumb";
import { Routes, getPath, getPathTitle } from "@/constants";
import React, { useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import {
  getHotMatchList,
  updateHotMatchPostStatus,
  hotMatchManualPull,
} from "@/store/actions";
import { confirmation } from "@/components/constants/common";

const HotMatchesList = () => {
  const dispatch = useDispatch();
  const { hotMatchList, isLoading, isManualPullLoading } = useSelector(
    (state) => state.hotMatchesSetting
  );
  const [collapse, setCollapse] = useState([]);

  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState({
    field: "",
    type: "asc",
  });
  const [data, setData] = useState({
    per_page: 10,
    page: 1,
    search: "",
  });

  const tableTheadData = useMemo(() => {
    const handleCollapse = (id) => {
      if (collapse.includes(id)) {
        const selected = collapse.filter((value) => value !== id);
        setCollapse([...selected]);
      } else {
        setCollapse((collapse) => [...collapse, id]);
      }
    };

    return [
      {
        label: "Event ID",
        field: "event_id",
        sortable: true,
      },
      {
        label: "Sport Type",
        field: "sport_type",
        sortable: true,
      },
      {
        label: "League Name",
        field: "league_name",
        sortable: true,
      },
      {
        label: "Is Posted",
        field: "is_posted",
        sortable: false,
        render: (item) => (
          <div className="d-flex w-100 justify-content-center">
            <InputSwitch
              label={item.is_posted ? "Yes" : "No"}
              id={`promotion-status-${item.slug}`}
              isChecked={item.is_posted}
              color="success"
              onChange={() => changeStatus(item)}
            />
          </div>
        ),
      },
      {
        label: "Teams",
        sortable: false,
        render: (item) => (
          <>
            {item?.teams ? (
              <DataCollapse
                label={collapse.includes(item.key_token) ? "show" : "view"}
                collapseKey={item.key_token}
                data={[]}
                content={
                  <JsonPretty id={item.key_token} data={item?.teams ?? []} />
                }
                collapse={collapse}
                handleCollapse={handleCollapse}
              />
            ) : (
              "-"
            )}
          </>
        ),
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collapse]);

  const initialize = () => {
    dispatch(getHotMatchList(data));
  };

  const changeStatus = (item) => {
    confirmation({
      title: item.title,
      text: `Are you sure you want to ${
        item.is_active ? "REMOVE POST" : "POST"
      }`,
      icon: "warning",
      confirmButtonText: "Confirm",
      action: () => {
        dispatch(updateHotMatchPostStatus(item.key_token, initialize));
      },
    });
  };

  const onManualPull = () => {
    dispatch(hotMatchManualPull(initialize));
  };

  useEffect(() => {
    dispatch(getHotMatchList(data));
  }, [data, dispatch]);

  return (
    <React.Fragment>
      <Title title={getPathTitle(Routes.HOT_MATCHES_SETTINGS_HOT_MATCHES)} />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            parent={{
              isParent: true,
              pageTitle: getPathTitle(Routes.HOT_MATCHES_SETTINGS_HOT_MATCHES),
              pageLink: getPath(Routes.HOT_MATCHES_SETTINGS_HOT_MATCHES),
            }}
          />
          <Row>
            <Col xs={12}>
              <Card>
                <CardHeader>
                <Button
                    text="Manual Pull"
                    isLoading={isManualPullLoading}
                    className="tw-float-right"
                    color="primary"
                    onClick={onManualPull}
                  />
                </CardHeader>
                <CardBody>
                  <Table
                    search={search}
                    handlerSearch={setSearch}
                    sortBy={sortBy}
                    handlerSortBy={setSortBy}
                    thead={tableTheadData}
                    handlerChangeData={setData}
                    data={data}
                    collection={hotMatchList}
                    isLoading={isLoading}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default HotMatchesList;
