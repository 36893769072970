import { TablePlaceholder } from "@/components/atoms";
import { numberFormat } from "@/utils/common";
import React, { useState } from "react";
import Pagination from "react-js-pagination";
import { Col, Input, Row, Table as TableComponent } from "reactstrap";

const Column = ({
    childKey = null,
    thead,
    parentIndex,
    item,
    isCollapsible,
    setIsCollapse,
    childItemKey,
}) => {
    return (
        <tr
            className="tw-cursor-pointer"
            onClick={() => {
                setIsCollapse((rows) => ({
                    ...rows,
                    [`collapse-${parentIndex}${
                        childKey != null ? "-" + childKey : ""
                    }`]:
                        !rows[
                            `collapse-${parentIndex}${
                                childKey != null ? "-" + childKey : ""
                            }`
                        ] ?? true,
                }));
            }}
        >
            {thead.map(({ field, render }, index) => (
                <td key={`col-${index}`}>
                    {index === 0 && isCollapsible ? (
                        <div className="tw-relative tw-flex tw-flex-row tw-items-center tw-justify-between">
                            {childItemKey && item[childItemKey] ? (
                                <span className="bx bx-chevron-down tw-text-lg tw-leading-none" />
                            ) : (
                                !childItemKey && (
                                    <span className="bx bx-chevron-down tw-text-lg tw-leading-none" />
                                )
                            )}

                            <div className="tw-flex-1">
                                {render ? render(item) : item[field]}
                            </div>
                        </div>
                    ) : render ? (
                        render(item)
                    ) : (
                        item[field]
                    )}
                </td>
            ))}
        </tr>
    );
};

const CollapsibleTable = ({
    thead,
    search,
    handlerSearch,
    sortBy,
    handlerSortBy,
    handlerChangeData,
    data,
    collection,
    isLoading,
    size = "sm",
    tableTextAlign = "center",
    per_page = 10,
    isCollapsible = false,
    subItemKey = null,
    hidePaginate = false,
    totalFieldStart = null,
    customComponents,
    hasGroup = false,
    groupKey = "",
    childItemKey = null,
}) => {
    const showPageOptions = [10, 20, 50, 100, 500, 1000];

    const [isCollapse, setIsCollapse] = useState({});

    return (
        <>
            <Row className="mb-2">
                {!customComponents ? (
                    <>
                        <Col md={3} className="pb-1">
                            {handlerChangeData && (
                                <div
                                    className={`input-group input-group-${size}`}
                                >
                                    <label
                                        htmlFor="per-page"
                                        className="input-group-text form-label"
                                    >
                                        Show page
                                    </label>
                                    <select
                                        className="form-select"
                                        id="per-page"
                                        value={per_page}
                                        onChange={(event) =>
                                            handlerChangeData({
                                                ...data,
                                                per_page: event.target.value,
                                                page: 1,
                                            })
                                        }
                                    >
                                        {showPageOptions.map((value) => (
                                            <option key={value}>{value}</option>
                                        ))}
                                    </select>
                                </div>
                            )}
                        </Col>
                        <Col md={6}></Col>
                        <Col md={3}>
                            {handlerSearch && (
                                <div
                                    className={`input-group input-group-${size}`}
                                >
                                    <Input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search..."
                                        value={search}
                                        onChange={(event) =>
                                            handlerSearch(event.target.value)
                                        }
                                    />
                                    <button
                                        className="btn btn-success me-2 rounded-end"
                                        type="button"
                                        onClick={() =>
                                            handlerChangeData({
                                                ...data,
                                                search: search,
                                                page: 1,
                                            })
                                        }
                                    >
                                        <span className="ri-search-2-line"></span>
                                    </button>
                                    {data.search && (
                                        <button
                                            className="btn btn-light rounded"
                                            type="button"
                                            onClick={() => {
                                                handlerChangeData({
                                                    ...data,
                                                    search: "",
                                                });
                                                handlerSearch("");
                                            }}
                                        >
                                            Clear
                                        </button>
                                    )}
                                </div>
                            )}
                        </Col>
                    </>
                ) : (
                    customComponents
                )}
            </Row>
            <Row>
                <Col>
                    <div className={`table-responsive text-${tableTextAlign}`}>
                        {isLoading && <div className="loading-overlay"></div>}
                        <TableComponent
                            className={`table-bordered table-hover align-middle table-nowrap mb-0 table-${size}`}
                        >
                            <thead className="table-light">
                                <tr>
                                    {thead?.map((item, key) => {
                                        let currentSortField =
                                            item.field === sortBy.field
                                                ? true
                                                : false;
                                        let currentSortType =
                                            sortBy.type === "desc"
                                                ? "ri-sort-desc"
                                                : "ri-sort-asc";
                                        return (
                                            <th scope="col" key={key}>
                                                {item.label}
                                                {item.sortable && (
                                                    <span
                                                        onClick={() => {
                                                            handlerSortBy({
                                                                field: item.field,
                                                                type:
                                                                    sortBy.type ===
                                                                        "asc" &&
                                                                    currentSortField
                                                                        ? "desc"
                                                                        : "asc",
                                                            });
                                                            handlerChangeData({
                                                                ...data,
                                                                sort_by:
                                                                    sortBy.field &&
                                                                    sortBy.type
                                                                        ? `${sortBy.field} ${sortBy.type}`
                                                                        : "",
                                                            });
                                                        }}
                                                        className={`align-middle float-end cursor-pointer ${
                                                            !currentSortField
                                                                ? "text-muted"
                                                                : ""
                                                        } ${
                                                            currentSortField
                                                                ? currentSortType
                                                                : "ri-filter-line"
                                                        }`}
                                                    ></span>
                                                )}
                                            </th>
                                        );
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {collection?.totals && totalFieldStart && (
                                    <tr className="dark:tw-bg-[#2B343D] dark:tw-border-[#2B343D] tw-bg-[#e9ebec] tw-border-[#e9ebec]">
                                        {thead.map(
                                            ({ field, render }, index) => {
                                                if (
                                                    collection?.totals[
                                                        field
                                                    ] !== undefined ||
                                                    field === totalFieldStart
                                                ) {
                                                    return (
                                                        <td
                                                            key={`total-${index}`}
                                                        >
                                                            {field ===
                                                            totalFieldStart ? (
                                                                <div className="tw-flex tw-justify-end tw-px-2 dark:tw-text-[#B2CDFF]">
                                                                    TOTAL
                                                                </div>
                                                            ) : render ? (
                                                                render(
                                                                    collection?.totals
                                                                )
                                                            ) : (
                                                                collection
                                                                    ?.totals[
                                                                    field
                                                                ]
                                                            )}
                                                        </td>
                                                    );
                                                }
                                                return <td key={index} />;
                                            }
                                        )}
                                    </tr>
                                )}
                                {collection?.data?.map((item, parentIndex) => (
                                    <React.Fragment key={parentIndex}>
                                        {hasGroup && (
                                            <tr>
                                                <td colSpan={thead.length}>
                                                    <div className="tw-flex tw-flex-start tw-px-2">
                                                        {item[groupKey]}
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                        {hasGroup ? (
                                            item[subItemKey].map(
                                                (subItem, childKey) => (
                                                    <React.Fragment
                                                        key={childKey}
                                                    >
                                                        <Column
                                                            childKey={childKey}
                                                            thead={thead}
                                                            item={subItem}
                                                            parentIndex={
                                                                parentIndex
                                                            }
                                                            isCollapsible={
                                                                isCollapsible
                                                            }
                                                            setIsCollapse={
                                                                setIsCollapse
                                                            }
                                                            childItemKey={
                                                                childItemKey
                                                            }
                                                        />

                                                        {isCollapsible &&
                                                            (
                                                                subItem[
                                                                    childItemKey
                                                                ] ?? []
                                                            ).map(
                                                                (
                                                                    subItem,
                                                                    index
                                                                ) => (
                                                                    <tr
                                                                        key={
                                                                            index
                                                                        }
                                                                        className={`${
                                                                            isCollapse[
                                                                                `collapse-${parentIndex}-${childKey}`
                                                                            ]
                                                                                ? ""
                                                                                : "tw-hidden"
                                                                        }`}
                                                                    >
                                                                        {thead.map(
                                                                            (
                                                                                {
                                                                                    subItemField,
                                                                                    subItemRender,
                                                                                },
                                                                                index
                                                                            ) => (
                                                                                <td
                                                                                    key={`sub-col-${index}`}
                                                                                >
                                                                                    {subItemRender
                                                                                        ? subItemRender(
                                                                                              subItem,
                                                                                              item
                                                                                          )
                                                                                        : subItem[
                                                                                              subItemField
                                                                                          ]}
                                                                                </td>
                                                                            )
                                                                        )}
                                                                    </tr>
                                                                )
                                                            )}
                                                    </React.Fragment>
                                                )
                                            )
                                        ) : (
                                            <Column
                                                thead={thead}
                                                item={item}
                                                parentIndex={parentIndex}
                                                isCollapsible={isCollapsible}
                                                setIsCollapse={setIsCollapse}
                                            />
                                        )}
                                        {!hasGroup &&
                                            isCollapsible &&
                                            item[subItemKey] &&
                                            item[subItemKey].map(
                                                (subItem, index) => (
                                                    <tr
                                                        key={index}
                                                        className={`${
                                                            isCollapse[
                                                                `collapse-${parentIndex}`
                                                            ]
                                                                ? ""
                                                                : "tw-hidden"
                                                        }`}
                                                    >
                                                        {thead.map(
                                                            (
                                                                {
                                                                    subItemField,
                                                                    subItemRender,
                                                                },
                                                                index
                                                            ) => (
                                                                <td
                                                                    key={`sub-col-${index}`}
                                                                >
                                                                    {subItemRender
                                                                        ? subItemRender(
                                                                              subItem,
                                                                              item
                                                                          )
                                                                        : subItem[
                                                                              subItemField
                                                                          ]}
                                                                </td>
                                                            )
                                                        )}
                                                    </tr>
                                                )
                                            )}
                                    </React.Fragment>
                                ))}

                                {/* {isCollapsible && hasGroup
                                            ? item[subItemKey] &&
                                              item[subItemKey].map(
                                                  (item, index) => (
                                                      <React.Fragment
                                                          key={index}>
                                                          {(
                                                              item[
                                                                  childItemKey
                                                              ] ?? []
                                                          ).map(
                                                              (item, index) => (
                                                                  <tr
                                                                      key={
                                                                          index
                                                                      }
                                                                      className={`${
                                                                          isCollapse[
                                                                              `collapse-${parentIndex}`
                                                                          ]
                                                                              ? ""
                                                                              : "tw-hidden"
                                                                      }`}>
                                                                      {thead.map(
                                                                          (
                                                                              {
                                                                                  subItemField,
                                                                                  subItemRender,
                                                                              },
                                                                              index
                                                                          ) => (
                                                                              <td
                                                                                  key={`sub-col-${index}`}>
                                                                                  {subItemRender
                                                                                      ? subItemRender(
                                                                                            item
                                                                                        )
                                                                                      : item[
                                                                                            subItemField
                                                                                        ]}
                                                                              </td>
                                                                          )
                                                                      )}
                                                                  </tr>
                                                              )
                                                          )}
                                                      </React.Fragment>
                                                  )
                                              )
                                            : isCollapsible &&
                                              item[subItemKey] &&
                                              item[subItemKey].map(
                                                  (item, index) => (
                                                      <tr
                                                          key={index}
                                                          className={`${
                                                              isCollapse[
                                                                  `collapse-${parentIndex}`
                                                              ]
                                                                  ? ""
                                                                  : "tw-hidden"
                                                          }`}>
                                                          {thead.map(
                                                              (
                                                                  {
                                                                      subItemField,
                                                                      subItemRender,
                                                                  },
                                                                  index
                                                              ) => (
                                                                  <td
                                                                      key={`sub-col-${index}`}>
                                                                      {subItemRender
                                                                          ? subItemRender(
                                                                                item
                                                                            )
                                                                          : item[
                                                                                subItemField
                                                                            ]}
                                                                  </td>
                                                              )
                                                          )}
                                                      </tr>
                                                  )
                                              )}
                                    </React.Fragment>
                                ))}*/}

                                {!(hasGroup || isCollapsible) &&
                                    !collection?.meta?.total > 0 && (
                                        <TablePlaceholder
                                            colSpan={thead.length}
                                        />
                                    )}

                                {(hasGroup || isCollapsible) &&
                                    !(
                                        collection?.data?.length ||
                                        collection?.length
                                    ) > 0 && (
                                        <TablePlaceholder
                                            colSpan={thead.length}
                                        />
                                    )}
                            </tbody>
                        </TableComponent>
                    </div>
                </Col>
            </Row>

            {collection?.meta?.total > 0 && (
                <Row className="mt-3">
                    <Col md={6}>
                        <div className="float-left">
                            Showing{" "}
                            {numberFormat(collection?.meta?.first_item, false)}{" "}
                            to{" "}
                            {numberFormat(collection?.meta?.last_item, false)}{" "}
                            of {numberFormat(collection?.meta?.total, false)}{" "}
                            results
                        </div>
                    </Col>

                    {!hidePaginate &&
                        collection?.meta?.total >
                            collection?.meta?.per_page && (
                            <Col md={6}>
                                <div className="float-end">
                                    <Pagination
                                        activePage={collection.meta.page}
                                        itemsCountPerPage={
                                            collection.meta.per_page
                                        }
                                        totalItemsCount={collection.meta.total}
                                        onChange={(pageNumber) =>
                                            handlerChangeData({
                                                ...data,
                                                page: pageNumber,
                                            })
                                        }
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        firstPageText="First"
                                        lastPageText="last"
                                    />
                                </div>
                            </Col>
                        )}
                </Row>
            )}
        </>
    );
};

export default CollapsibleTable;
