import * as types from "./actionTypes";
import { announcementState } from "@/types/states";

const initialState = announcementState;

const agent = (state = initialState, action) => {
    switch (action.type) {
        case types.SEND_ANNOUNCEMENT:
        case types.UPDATE_MESSAGE_STATUS:
        case types.CREATE_MESSAGE:
        case types.GET_NOTIFICATION_TYPE_LIST:
        case types.GET_USER_NOTIFICATION_LIST:
            state = {
                ...state,
                isLoading: true,
                errors: {}
            };
            break;
        case types.SEND_ANNOUNCEMENT_SUCCESS:
        case types.UPDATE_MESSAGE_STATUS_SUCCESS:
        case types.CREATE_MESSAGE_SUCCESS:
            state = {
                ...state,
                isLoading: false,
                errors: {}
            };
            break;
        case types.SEND_ANNOUNCEMENT_ERROR:
        case types.UPDATE_MESSAGE_STATUS_ERROR:
        case types.CREATE_MESSAGE_ERROR:
        case types.GET_NOTIFICATION_TYPE_LIST_ERROR:
        case types.GET_USER_NOTIFICATION_LIST_ERROR:

            state = {
                ...state,
                isLoading: false,
                errors: action.payload
            };
            break;

        case types.GET_MESSAGE:
            state = {
                ...state,
                isLoading: true,
                errors: {}
            };
            break;

        case types.GET_MESSAGE_ERROR:
            state = {
                ...state,
                isLoading: false,
                messageList: [],
                errors: action.payload
            };
            break;

        case types.GET_MESSAGE_SUCCESS:
            state = {
                ...state,
                messageList: action.payload,
                isLoading: false,
                errors: {}
            };
            break;

        case types.GET_MESSAGE_TYPES_SUCCESS:
            state = {
                ...state,
                messageTypes: action.payload,
            };
            break;

        case types.UPDATE_MESSAGE_INFORMATION:
        case types.UPDATE_MESSAGE_BANNER:
        case types.UPDATE_NOTIFICATION_TYPE_BANNER:
            state = {
                ...state,
                isSubmitting: true,
            };
            break;

        case types.UPDATE_MESSAGE_INFORMATION_SUCCESS:
        case types.UPDATE_MESSAGE_BANNER_SUCCESS:
        case types.UPDATE_NOTIFICATION_TYPE_BANNER_SUCCESS:
            state = {
                ...state,
                isSubmitting: false,

            };
            break;

        case types.UPDATE_MESSAGE_INFORMATION_ERROR:
        case types.UPDATE_MESSAGE_BANNER_ERROR:
        case types.UPDATE_NOTIFICATION_TYPE_BANNER_ERROR:
            state = {
                ...state,
                isSubmitting: false,
            };
            break;

        case types.GET_NOTIFICATION_TYPE_LIST_SUCCESS:
            state = {
                ...state,
                notificationTypeList: action.payload,
                isLoading: false,
                errors: {}
            };
            break;
        case types.GET_USER_NOTIFICATION_LIST_SUCCESS:
            state = {
                ...state,
                userNotificationList: action.payload,
                isLoading: false,
                errors: {}
            };
            break;
    
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default agent;
