import { defaultCurrency } from "@/utils/common";
import Cleave from "cleave.js/react";
import React from "react";
import { Label } from "reactstrap";

const InputAmount = ({
    label,
    validation,
    name,
    placeholder = "0.0",
    size = "md",
    onChange = () => {},
    withCurrency = true,
    numericOnly = false,
    ...rest
}) => {
    return (
        <>
            <Label>{label}</Label>
            <div className={`input-group input-group-${size}`}>
                {withCurrency && (
                    <span className="input-group-text">
                        {defaultCurrency()}
                    </span>
                )}
                <Cleave
                    options={{
                        numeral: true,
                        numeralThousandsGroupStyle: "thousand",
                        numericOnly: numericOnly,
                        numeralDecimalScale: numericOnly ? 0 : 6,
                    }}
                    value={validation?.values[name] || ""}
                    className={`form-control ${
                        validation?.touched[name] &&
                        validation?.errors[name] &&
                        "border-danger"
                    }`}
                    name={name}
                    onChange={(e) => {
                        if (!rest.disabled) {
                            validation.setFieldValue(name, e.target.rawValue);
                            onChange(e);
                        }
                    }}
                    onBlur={validation?.handleBlur}
                    placeholder={placeholder}
                    {...rest}
                />
            </div>
            {validation?.touched[name] && validation?.errors[name] && (
                <small className="text-danger text-sm">
                    {validation.errors[name]}
                </small>
            )}
        </>
    );
};

export default InputAmount;
