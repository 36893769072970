export const GET_AGENT_LIST = "GET_AGENT_LIST";
export const SET_AGENT_LIST = "SET_AGENT_LIST";
export const SET_AGENT_LIST_ERROR = "SET_AGENT_LIST_ERROR";

export const CREATE_AGENT = "CREATE_AGENT";
export const CREATE_AGENT_SUCCESS = "CREATE_AGENT_SUCCESS";
export const CREATE_AGENT_ERROR = "CREATE_AGENT_ERROR";

export const GET_AGENT_PROFILE = "GET_AGENT_PROFILE";
export const SET_AGENT_PROFILE = "SET_AGENT_PROFILE";
export const SET_AGENT_PROFILE_ERROR = "SET_AGENT_PROFILE_ERROR";

export const UPDATE_AGENT = "UPDATE_AGENT";
export const UPDATE_AGENT_SUCCESS = "UPDATE_AGENT_SUCCESS";
export const UPDATE_AGENT_ERROR = "UPDATE_AGENT_ERROR";

export const DELETE_AGENT = "DELETE_AGENT";
export const DELETE_AGENT_SUCCESS = "DELETE_AGENT_SUCCESS";
export const DELETE_AGENT_ERROR = "DELETE_AGENT_ERROR";

export const UPDATE_AGENT_WALLET_BALANCE = "UPDATE_AGENT_WALLET_BALANCE";

export const UPDATE_AGENT_COMMISSION_RATE = "UPDATE_AGENT_COMMISSION_RATE";
export const UPDATE_AGENT_COMMISSION_RATE_SUCCESS =
    "UPDATE_AGENT_COMMISSION_RATE_SUCCESS";
export const UPDATE_AGENT_COMMISSION_RATE_ERROR =
    "UPDATE_AGENT_COMMISSION_RATE_ERROR";

export const RESET_AGENT_ERRORS = "RESET_AGENT_ERRORS";

export const GET_AGENT_COMMISSIONS = "GET_AGENT_COMMISSIONS";
export const GET_AGENT_COMMISSIONS_SUCCESS = "GET_AGENT_COMMISSIONS_SUCCESS";
export const GET_AGENT_COMMISSIONS_FAILED = "GET_AGENT_COMMISSIONS_FAILED";

export const UPDATE_AGENT_EMAIL_SUCCESS = "UPDATE_AGENT_EMAIL_SUCCESS";

export const UPDATE_AGENT_ROLE_TAG = "UPDATE_AGENT_ROLE_TAG";
export const UPDATE_AGENT_ROLE_TAG_SUCCESS = "UPDATE_AGENT_ROLE_TAG_SUCCESS";
export const UPDATE_AGENT_ROLE_TAG_ERROR = "UPDATE_AGENT_ROLE_TAG_ERROR";

export const ENABLE_VALID_TURNOVER_COMMISSION =
    "ENABLE_VALID_TURNOVER_COMMISSION";
export const ENABLE_VALID_TURNOVER_COMMISSION_SUCCESS =
    "ENABLE_VALID_TURNOVER_COMMISSION_SUCCESS";
export const ENABLE_VALID_TURNOVER_COMMISSION_ERROR =
    "ENABLE_VALID_TURNOVER_COMMISSION_ERROR";

export const UPDATE_USER_PROFILE_SETTING_WITH_BALANCE_CATEGORY =
    "UPDATE_USER_PROFILE_SETTING_WITH_BALANCE_CATEGORY";
export const UPDATE_USER_PROFILE_SETTING_WITH_BALANCE_CATEGORY_SUCCESS =
    "UPDATE_USER_PROFILE_SETTING_WITH_BALANCE_CATEGORY_SUCCESS";
export const UPDATE_USER_PROFILE_SETTING_WITH_BALANCE_CATEGORY_ERROR =
    "UPDATE_USER_PROFILE_SETTING_WITH_BALANCE_CATEGORY_ERROR";

export const UPDATE_USER_PROFILE_SETTING = "UPDATE_USER_PROFILE_SETTING";
export const UPDATE_USER_PROFILE_SETTING_SUCCESS =
    "UPDATE_USER_PROFILE_SETTING_SUCCESS";
export const UPDATE_USER_PROFILE_SETTING_ERROR =
    "UPDATE_USER_PROFILE_SETTING_ERROR";

export const ENABLE_NEGATIVE_CARRY_OVER_COMMISSION =
    "ENABLE_NEGATIVE_CARRY_OVER_COMMISSION";
export const ENABLE_NEGATIVE_CARRY_OVER_COMMISSION_SUCCESS =
    "ENABLE_NEGATIVE_CARRY_OVER_COMMISSION_SUCCESS";
export const ENABLE_NEGATIVE_CARRY_OVER_COMMISSION_ERROR =
    "ENABLE_NEGATIVE_CARRY_OVER_COMMISSION_ERROR";

export const UPDATE_PENDING_COMMISSION_REQUIRED_TO_RESET_NEGATIVE_COMMISSION =
    "UPDATE_PENDING_COMMISSION_REQUIRED_TO_RESET_NEGATIVE_COMMISSION";
export const UPDATE_PENDING_COMMISSION_REQUIRED_TO_RESET_NEGATIVE_COMMISSION_SUCCESS =
    "UPDATE_PENDING_COMMISSION_REQUIRED_TO_RESET_NEGATIVE_COMMISSION_SUCCESS";
export const UPDATE_PENDING_COMMISSION_REQUIRED_TO_RESET_NEGATIVE_COMMISSION_ERROR =
    "UPDATE_PENDING_COMMISSION_REQUIRED_TO_RESET_NEGATIVE_COMMISSION_ERROR";

export const UPDATE_NUMBER_OF_CUTOFF_TO_RESET_NEGATIVE_COMMISSION =
    "UPDATE_NUMBER_OF_CUTOFF_TO_RESET_NEGATIVE_COMMISSION";
export const UPDATE_NUMBER_OF_CUTOFF_TO_RESET_NEGATIVE_COMMISSION_SUCCESS =
    "UPDATE_NUMBER_OF_CUTOFF_TO_RESET_NEGATIVE_COMMISSION_SUCCESS";
export const UPDATE_NUMBER_OF_CUTOFF_TO_RESET_NEGATIVE_COMMISSION_ERROR =
    "UPDATE_NUMBER_OF_CUTOFF_TO_RESET_NEGATIVE_COMMISSION_ERROR";

export const APPLY_DOWNLINES_COMMISSIONS_TRANSFER_SCHEDULE_AGENT =
    "APPLY_DOWNLINES_COMMISSIONS_TRANSFER_SCHEDULE_AGENT";
export const APPLY_DOWNLINES_COMMISSIONS_TRANSFER_SCHEDULE_AGENT_SUCCESS =
    "APPLY_DOWNLINES_COMMISSIONS_TRANSFER_SCHEDULE_AGENT_SUCCESS";
export const APPLY_DOWNLINES_COMMISSIONS_TRANSFER_SCHEDULE_AGENT_ERROR =
    "APPLY_DOWNLINES_COMMISSIONS_TRANSFER_SCHEDULE_AGENT_ERROR";

export const UPDATE_AFFILIATE_REQUEST_STATUS =
    "UPDATE_AFFILIATE_REQUEST_STATUS";
export const UPDATE_AFFILIATE_REQUEST_STATUS_SUCCESS =
    "UPDATE_AFFILIATE_REQUEST_STATUS_SUCCESS";
export const UPDATE_AFFILIATE_REQUEST_STATUS_ERROR =
    "UPDATE_AFFILIATE_REQUEST_STATUS_ERROR";
