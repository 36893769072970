import * as types from "./actionTypes";

export const getPaymentMethodPerProvider = (paymentProvider) => ({
    type: types.GET_PAYMENT_METHOD_PER_PROVIDER,
    paymentProvider
});

export const setPaymentMethodPerProviderData = (payload, paymentProvider) => ({
    type: types.SET_PAYMENT_METHOD_PER_PROVIDER_DATA,
    payload,
    paymentProvider
});

export const setPaymentMethodPerProviderDataError = () => ({
    type: types.SET_PAYMENT_METHOD_PER_PROVIDER_DATA_ERROR,
});

export const getPaymentMethods = () => ({
    type: types.GET_PAYMENT_METHODS,
});

export const setPaymentMethodsData = (payload) => ({
    type: types.SET_PAYMENT_METHODS_DATA,
    payload,
});

export const setPaymentMethodsDataError = () => ({
    type: types.SET_PAYMENT_METHODS_DATA_ERROR,
});

export const getPaymentProviders = () => ({
    type: types.GET_PAYMENT_PROVIDERS,
});

export const setPaymentProvidersData = (payload) => ({
    type: types.SET_PAYMENT_PROVIDERS_DATA,
    payload,
});

export const setPaymentProvidersDataError = () => ({
    type: types.SET_PAYMENT_PROVIDERS_DATA_ERROR,
});

export const getAllowedInWithdrawalPaymentProviders = () => ({
    type: types.GET_ALLOWED_IN_WITHDRAWAL_PAYMENT_PROVIDERS,
});

export const setAllowedInWithdrawalPaymentProvidersData = (payload) => ({
    type: types.SET_ALLOWED_IN_WITHDRAWAL_PAYMENT_PROVIDERS_DATA,
    payload,
});

export const setAllowedInWithdrawalPaymentProvidersDataError = () => ({
    type: types.SET_ALLOWED_IN_WITHDRAWAL_PAYMENT_PROVIDERS_DATA_ERROR,
});

export const updatePaymentMethod = (
    payload,
    paymentProvider,
    keyToken,
    initialize
) => ({
    type: types.UPDATE_PAYMENT_METHOD,
    payload,
    paymentProvider,
    keyToken,
    initialize,
});

export const onUpdatePaymentMethodSuccess = () => ({
    type: types.UPDATE_PAYMENT_METHOD_SUCCESS,
});

export const onUpdatePaymentMethodError = (payload) => ({
    type: types.UPDATE_PAYMENT_METHOD_ERROR,
    payload,
});

export const updatePaymentProvider = (payload, keyToken, initialize) => ({
    type: types.UPDATE_PAYMENT_PROVIDER,
    payload,
    keyToken,
    initialize,
});

export const onUpdatePaymentProviderSuccess = () => ({
    type: types.UPDATE_PAYMENT_PROVIDER_SUCCESS,
});

export const onUpdatePaymentProviderError = (payload) => ({
    type: types.UPDATE_PAYMENT_PROVIDER_ERROR,
    payload,
});

export const updatePaymentMethodConfig = (payload, keyToken, initialize) => ({
    type: types.UPDATE_PAYMENT_METHOD_CONFIG,
    payload,
    keyToken,
    initialize,
});

export const onUpdatePaymentMethodConfigSuccess = () => ({
    type: types.UPDATE_PAYMENT_METHOD_CONFIG_SUCCESS,
});

export const onUpdatePaymentMethodConfigError = (payload) => ({
    type: types.UPDATE_PAYMENT_METHOD_CONFIG_ERROR,
    payload,
});

export const resetErrors = () => ({
    type: types.RESET_ERRORS,
});
