import {
  Button,
  ErrorAlert,
  InputAmount,
  TextArea,
  InputReactSelect,
  InputText,
} from "@/components/atoms";
import { denomination, depositValidation } from "@/constants";
import { getSetting } from "@/hooks";
import { userDeposit, getPaymentMethodData } from "@/store/actions";
import { useFormik } from "formik";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button as ButtonComponent,
  ButtonGroup,
  Col,
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";

const DepositModal = ({
  isOpen,
  setIsOpen,
  userProfilekeyToken,
  initialize,
  userType,
}) => {
  const dispatch = useDispatch();
  const { isLoading, errors, paymentMethod } = useSelector(
    (state) => state.transaction
  );
  const [bank, setBank] = useState({});

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      user_profile_key_token: userProfilekeyToken,
      payment_method: "",
      account_no: "",
      account_name: "",
      amount: "",
      remarks: "",
    },
    validationSchema: depositValidation(getSetting),
    onSubmit: (values) => {
      dispatch(userDeposit(values, setIsOpen, initialize, userType));
    },
  });

  useEffect(() => {
    dispatch(getPaymentMethodData());
  }, [dispatch]);

  return (
    <Modal id="depositModal" isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
      <ModalHeader>
        <div className="modal-title">Deposit</div>
      </ModalHeader>
      <ModalBody>
        <Form
          className="needs-validation"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <ErrorAlert errors={errors} validation={validation} />
          <Row className="mb-3">
            <Col md="12">
              <FormGroup className="mb-3">
                <InputReactSelect
                  label="Banks"
                  name="payment_method"
                  onChange={(option) => {
                    validation.setFieldValue("payment_method", option.value);
                    setBank(option);
                  }}
                  value={validation.values.payment_method ? bank : ""}
                  options={paymentMethod}
                  validation={validation}
                />
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup className="mb-3">
                <InputText
                  label="Account No."
                  name="account_no"
                  validation={validation}
                />
              </FormGroup>
            </Col>
            <Col md="12">
              <FormGroup className="mb-3">
                <InputText
                  label="Account Name"
                  name="account_name"
                  validation={validation}
                />
              </FormGroup>
            </Col>
            <Col md={12}>
              <FormGroup className="mb-3">
                <InputAmount
                  label="Amount"
                  name="amount"
                  validation={validation}
                />
                <ButtonGroup className="w-100 pt-2">
                  {denomination.map((value, key) => (
                    <ButtonComponent
                      key={key}
                      color="success"
                      outline
                      onClick={() => validation.setFieldValue("amount", value)}
                    >
                      {value}
                    </ButtonComponent>
                  ))}
                </ButtonGroup>
              </FormGroup>
            </Col>
            <Col md={12}>
              <FormGroup className="mb-3">
                <TextArea
                  label="Remarks"
                  name="remarks"
                  validation={validation}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col className="text-end">
              <ButtonComponent
                color="light"
                onClick={() => setIsOpen(!isOpen)}
                className="me-2"
              >
                Close
              </ButtonComponent>
              <Button
                color="success"
                type="submit"
                text="Submit"
                isLoading={isLoading}
              />
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default DepositModal;
