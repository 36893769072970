import React from "react";
import { FormFeedback, Input, Label } from "reactstrap";

const InputDate = ({ label, validation, name, ...rest }) => {
    return (
        <>
            <Label>{label}</Label>
            <Input
                type="date"
                className="form-control"
                name={name}
                onChange={validation?.handleChange}
                onBlur={validation?.handleBlur}
                value={validation?.values[name] || ""}
                invalid={
                    validation?.touched[name] && validation.errors[name]
                        ? true
                        : false
                }
                {...rest}
            />
            {validation?.touched[name] && validation.errors[name] ? (
                <FormFeedback type="invalid">
                    {validation.errors[name]}
                </FormFeedback>
            ) : null}
        </>
    );
};

export default InputDate;
