const layoutTypes = {
    HORIZONTAL: "horizontal",
    VERTICAL: "vertical",
    TWOCOLUMN: "twocolumn",
};

const layoutModeTypes = {
    LIGHTMODE: "light",
    DARKMODE: "dark",
};

const leftSidebarTypes = {
    LIGHT: "light",
    DARK: "dark",
};

const layoutWidthTypes = {
    FLUID: "lg",
    BOXED: "boxed",
};

const layoutPositionTypes = {
    FIXED: "fixed",
    SCROLLABLE: "scrollable",
};

const topbarThemeTypes = {
    LIGHT: "light",
    DARK: "dark",
};

const leftsidbarSizeTypes = {
    DEFAULT: "lg",
    COMPACT: "md",
    SMALLICON: "sm",
    SMALLHOVER: "sm-hover",
};

const leftSidebarViewTypes = {
    DEFAULT: "default",
    DETACHED: "detached",
};

export {
    layoutTypes,
    layoutModeTypes,
    leftSidebarTypes,
    layoutWidthTypes,
    layoutPositionTypes,
    topbarThemeTypes,
    leftsidbarSizeTypes,
    leftSidebarViewTypes,
};
