import * as types from "./actionTypes";

export const getSettingsData = () => ({
    type: types.GET_SETTINGS_DATA,
});

export const setSettingsData = (payload) => ({
    type: types.SET_SETTINGS_DATA,
    payload,
});

export const setSettingsDataError = () => ({
    type: types.SET_SETTINGS_DATA_ERROR,
});

export const updateSetting = (payload, keyToken) => ({
    type: types.UPDATE_SETTING,
    payload,
    keyToken,
});

export const updateSettingSuccess = (payload) => ({
    type: types.UPDATE_SETTING_SUCCESS,
    payload,
});

export const updateSettingError = (payload) => ({
    type: types.UPDATE_SETTING_ERROR,
    payload,
});
