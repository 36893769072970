import store from "@/store";
import { loader } from "@/store/actions";

export const isHandlerEnabled = (config = {}) => {
    // eslint-disable-next-line no-prototype-builtins
    return config.hasOwnProperty("handlerEnabled") && !config.handlerEnabled
        ? false
        : true;
};

export const requestHandler = (request) => {
    if (isHandlerEnabled(request)) {
        // Modify request here
        // store.dispatch(loader(true));
    }
    return request;
};

export const successHandler = (response) => {
    if (isHandlerEnabled(response)) {
        // Handle responses
        // store.dispatch(loader(false));
    }
    return response;
};

export const errorHandler = (error) => {
    if (isHandlerEnabled(error.config)) {
        // store.dispatch(loader(false));
    }
    // if (error.response.status === 403) {
    //     window.location.href =
    //         window.location.protocol +
    //         "//" +
    //         window.location.host +
    //         "/not-found";
    // }
    return Promise.reject({ ...error });
};
