import { axiosInstance } from "./index";

export const getPaymentMethodPerProvider = async (paymentProvider) => {
    return await axiosInstance.get(`/payment-method/get-all-${paymentProvider}`);
};

export const getPaymentMethods = async () => {
    return await axiosInstance.get(`/bo/payment-method/get-all`);
};

export const getPaymentProviders = async () => {
    return await axiosInstance.get(`/bo/payment-provider/get-all`);
};

export const getAllowedInWithdrawalPaymentProviders = async () => {
    return await axiosInstance.get(`/bo/payment-provider/get-allowed-in-withdrawal`);
};


export const updatePaymentMethod = async (
    params,
    paymentProvider,
    keyToken
) => {
    return await axiosInstance.put(
        `/bo/payment-provider/update-payment-method/${paymentProvider}/${keyToken}`,
        params
    );
};

export const updatePaymentMethodConfig = async (params, keyToken) => {
    return await axiosInstance.put(
        `/bo/payment-method/update/${keyToken}`,
        params
    );
};

export const updatePaymentProvider = async (params, keyToken) => {
    return await axiosInstance.put(
        `/bo/payment-provider/update-status/${keyToken}`,
        params
    );
};
