export { getPath, getPathTitle } from "@/utils/routes";

export const Routes = {
    // Auth
    LOGIN: "LOGIN",
    FORGOT_PASSWORD: "FORGOT_PASSWORD",
    RESET_PASSWORD: "RESET_PASSWORD",
    // Account
    ACCOUNT_PROFILE: "ACCOUNT_PROFILE",
    ACCOUNT_COMMISSIONS_SETTINGS: "ACCOUNT_COMMISSIONS_SETTINGS",
    ACCOUNT_WALLET: "ACCOUNT_WALLET",
    ACCOUNT_DIRECT_WITHDRAWAL: "ACCOUNT_DIRECT_WITHDRAWAL",
    ACCOUNT_REQUEST: "ACCOUNT_REQUEST",
    ACCOUNT_CHANGE_PASSWORD: "ACCOUNT_CHANGE_PASSWORD",
    ACCOUNT_COMMISSIONS: "ACCOUNT_COMMISSIONS",
    // Dashboard
    DASHBOARD: "DASHBOARD",
    DASHBOARDV1: "DASHBOARDV1",
    // Betting Credit Fund
    BETTING_CREDIT_FUND: "BETTING_CREDIT_FUND",
    // Operators
    OPERATORS: "OPERATORS",
    OPERATOR_CREATE: "OPERATOR_CREATE",
    OPERATOR_DASHBOARD: "OPERATOR_DASHBOARD",
    OPERATOR_PROFILE: "OPERATOR_PROFILE",
    OPERATOR_DOWNLINES: "OPERATOR_DOWNLINES",
    OPERATOR_WALLET: "OPERATOR_WALLET",
    OPERATOR_REQUEST: "OPERATOR_REQUEST",
    OPERATOR_PAYMENT_CREDENTIALS: "OPERATOR_PAYMENT_CREDENTIALS",
    OPERATOR_SETTINGS: "OPERATOR_SETTINGS",
    // Community Manager
    COMMUNITY_MANAGERS: "COMMUNITY_MANAGERS",
    COMMUNITY_MANAGER_CREATE: "COMMUNITY_MANAGER_CREATE",
    COMMUNITY_MANAGER_DASHBOARD: "COMMUNITY_MANAGER_DASHBOARD",
    COMMUNITY_MANAGER_COMMISSIONS_SETTINGS:
        "COMMUNITY_MANAGER_COMMISSIONS_SETTINGS",
    COMMUNITY_MANAGER_PROFILE: "COMMUNITY_MANAGER_PROFILE",
    COMMUNITY_MANAGER_DOWNLINES: "COMMUNITY_MANAGER_DOWNLINES",
    COMMUNITY_MANAGER_WALLET: "COMMUNITY_MANAGER_WALLET",
    COMMUNITY_MANAGER_REQUEST: "COMMUNITY_MANAGER_REQUEST",
    COMMUNITY_MANAGER_PAYMENT_CREDENTIALS:
        "COMMUNITY_MANAGER_PAYMENT_CREDENTIALS",
    COMMUNITY_MANAGER_SETTINGS: "COMMUNITY_MANAGER_SETTINGS",
    // Super Agents
    SUPER_AGENTS: "SUPER_AGENTS",
    SUPER_AGENT_CREATE: "SUPER_AGENT_CREATE",
    SUPER_AGENT_PROFILE: "SUPER_AGENT_PROFILE",
    SUPER_AGENT_DOWNLINES: "SUPER_AGENT_DOWNLINES",
    SUPER_AGENT_WALLET: "SUPER_AGENT_WALLET",
    SUPER_AGENT_COMMISSIONS_SETTINGS: "SUPER_AGENTS_COMMISSIONS_SETTINGS",
    SUPER_AGENT_REQUEST: "SUPER_AGENT_REQUEST",
    SUPER_AGENT_COMMISSIONS: "SUPER_AGENT_COMMISSIONS",
    // Agents
    AGENTS: "AGENTS",
    AGENT_CREATE: "AGENT_CREATE",
    AGENT_PROFILE: "AGENT_PROFILE",
    AGENT_DOWNLINES: "AGENT_DOWNLINES",
    AGENT_WALLET: "AGENT_WALLET",
    AGENT_COMMISSIONS_SETTINGS: "AGENTS_COMMISSIONS_SETTINGS",
    AGENT_REQUEST: "AGENT_REQUEST",
    AGENT_COMMISSIONS: "AGENT_COMMISSIONS",
    // Affiliates
    AFFILIATES: "AFFILIATES",
    AFFILIATE_CREATE: "AFFILIATE_CREATE",
    AFFILIATE_PROFILE: "AFFILIATE_PROFILE",
    AFFILIATE_DOWNLINES: "AFFILIATE_DOWNLINES",
    AFFILIATE_WALLET: "AFFILIATE_WALLET",
    AFFILIATE_COMMISSIONS_SETTINGS: "AFFILIATES_COMMISSIONS_SETTINGS",
    AFFILIATE_REQUEST: "AFFILIATE_REQUEST",
    AFFILIATE_COMMISSIONS: "AFFILIATE_COMMISSIONS",
    // Players
    PLAYERS: "PLAYERS",
    PLAYER_CREATE: "PLAYER_CREATE",
    PLAYER_PROFILE: "PLAYER_PROFILE",
    PLAYER_WALLET: "PLAYER_WALLET",
    PLAYER_REQUEST: "PLAYER_REQUEST",
    PLAYER_BET_HISTORY: "PLAYER_BET_HISTORY",
    PLAYER_COMMISSIONS: "PLAYER_COMMISSIONS",
    // Activity Logs
    ACTIVITY_LOGS: "ACTIVITY_LOGS",
    //User IP Logs
    LOGIN_IPS: "LOGIN_IPS",
    MULTIPLE_IP_DETECTED: "MULTIPLE_IP_DETECTED",
    // User Management
    MANAGE_USER: "MANAGE_USER",
    MANAGE_USER_CREATE: "MANAGE_USER_CREATE",
    MANAGE_USER_PROFILE: "MANAGE_USER_PROFILE",
    MANAGE_ROLE: "MANAGE_ROLE",
    MANAGE_ROLE_CREATE: "MANAGE_ROLE_CREATE",
    MANAGE_ROLE_UPDATE: "MANAGE_ROLE_UPDATE",
    // Reports
    BET_TRANSACTION_REPORTS: "BET_TRANSACTION_REPORTS",
    EGAMES_REPORTS: "EGAMES_REPORTS",
    LIVE_CASINO_REPORTS: "LIVE_CASINO_REPORTS",
    SPORTS_BETTING_REPORTS: "SPORTS_BETTING_REPORTS",
    DEPOSIT_REQUEST_REPORTS: "DEPOSIT_REQUEST_REPORTS",
    WITHDRAWAL_REQUEST_REPORTS: "WITHDRAWAL_REQUEST_REPORTS",
    COMMISSION_REPORTS: "COMMISSION_REPORTS",
    TRANSACTION_REPORTS: "TRANSACTION_REPORTS",
    WIN_LOSS_BY_AGENT_REPORT: "WIN_LOSS_BY_AGENT_REPORT",
    WIN_LOSS_BY_PRODUCT_REPORT: "WIN_LOSS_BY_PRODUCT_REPORT",
    GENERATED_REPORT: "GENERATED_REPORT",
    //Payment Settings
    PAYMENT_SETTINGS_GENERAL: "PAYMENT_SETTINGS_GENERAL",
    PAYMENT_SETTINGS_H2PAY: "PAYMENT_SETTINGS_H2PAY",
    PAYMENT_SETTINGS_MONETIX: "PAYMENT_SETTINGS_MONETIX",
    PAYMENT_SETTINGS_SWIFTPAY: "PAYMENT_SETTINGS_SWIFTPAY",
    PAYMENT_SETTINGS_PAYMENT_PROVIDERS: "PAYMENT_SETTINGS_PAYMENT_PROVIDERS",
    PAYMENT_SETTINGS_AK_PAY: "PAYMENT_SETTINGS_AK_PAY",
    PAYMENT_SETTINGS_COINSPAID: "PAYMENT_SETTINGS_COINSPAID",
    // Settings
    SETTINGS: "SETTINGS",
    COUNTRIES: "COUNTRIES",
    // Kcy
    KYC: "KYC",
    // Deposit Request
    DEPOSIT_REQUEST: "DEPOSIT_REQUEST",
    DEPOSIT_REQUEST_VIEW: "DEPOSIT_REQUEST_VIEW",
    // Withdrawal Request
    WITHDRAWAL_REQUEST: "WITHDRAWAL_REQUEST",
    WITHDRAWAL_REQUEST_VIEW: "WITHDRAWAL_REQUEST_VIEW",
    // Affiliates Request
    AFFILIATES_REQUEST_LIST: "AFFILIATES_REQUEST_LIST",
    // For Release
    FOR_RELEASE: "FOR_RELEASE",
    FOR_RELEASE_VIEW: "FOR_RELEASE_VIEW",
    // For Payout Release
    FOR_PAYOUT_RELEASE: "FOR_PAYOUT_RELEASE",
    FOR_PAYOUT_RELEASE_VIEW: "FOR_PAYOUT_RELEASE_VIEW",
    // Transactions
    TRANSACTIONS: "TRANSACTIONS",
    TRANSACTION_VIEW: "TRANSACTION_VIEW",
    // Casino
    CASINO_GAME_PROVIDER: "CASINO_GAME_PROVIDER",
    CASINO_GAME_TYPE: "CASINO_GAME_TYPE",
    CASINO_GAME_LIST: "CASINO_GAME_LIST",
    //Tip Transactions
    TIP_TRANSACTION: "TIP_TRANSACTION",
    TIP_TRANSACTION_VIEW: "TIP_TRANSACTION_VIEW",
    // Errors
    ERROR_NOT_FOUND: "ERROR_NOT_FOUND",
    // Transaction Listing
    BET_TRANSACTION: "BET_TRANSACTION",
    EGAMES_TRANSACTION: "EGAMES_TRANSACTION",
    LIVE_CASINO_TRANSACTION: "LIVE_CASINO_TRANSACTION",
    SPORTS_BETTING_TRANSACTION: "SPORTS_BETTING_TRANSACTION",
    ESPORTS_TRANSACTION: "ESPORTS_TRANSACTION",
    BET_TRANSACTION_VIEW: "BET_TRANSACTION_VIEW",
    COMMISSIONS_AGENTS: "COMMISSIONS_AGENTS",
    COMMISSIONS_PLAYERS: "COMMISSIONS_PLAYERS",
    LOTTO_TRANSACTION: "LOTTO_TRANSACTION",
    // Promotions
    PROMOTION_LIST: "PROMOTION_LIST",
    PROMOTION_CATEGORIES: "PROMOTION_CATEGORIES",
    PROMOTION_CREATE: "PROMOTION_CREATE",
    PROMOTION_UPDATE: "PROMOTION_UPDATE",
    PROMOTION_TRANSACTION_LIST: "PROMOTION_TRANSACTION_LIST",
    PROMOTION_TRANSACTION_VIEW: "PROMOTION_TRANSACTION_VIEW",
    // Hot Matches
    HOT_MATCHES_SETTINGS_SPORT_TYPE: "HOT_MATCHES_SETTINGS_SPORT_TYPE",
    HOT_MATCHES_SETTINGS_LEAGUE_TYPE: "HOT_MATCHES_SETTINGS_LEAGUE_TYPE",
    HOT_MATCHES_SETTINGS_GAME_TYPE: "HOT_MATCHES_SETTINGS_GAME_TYPE",
    HOT_MATCHES_SETTINGS_TEAM: "HOT_MATCHES_SETTINGS_TEAM",
    HOT_MATCHES_SETTINGS_HOT_MATCHES: "HOT_MATCHES_SETTINGS_HOT_MATCHES",
    //Announcement
    SEND_ANNOUNCEMENT: "SEND_ANNOUNCEMENT",
    MESSAGE_LIST: "MESSAGE_LIST",
    MESSAGE_CREATE_OR_UPDATE: "MESSAGE_CREATE_OR_UPDATE",
    NOTIFICATION_TYPE_LIST: "NOTIFICATION_TYPE_LIST",
    NOTIFICATION_LIST: "NOTIFICATION_LIST",
    //Lotto
    DRAW_SCHEDULE_LIST: "DRAW_SCHEDULE_LIST",
};

export const fullRoutes = {
    // Auth
    [Routes.LOGIN]: {
        path: "/login",
        title: "Login",
    },
    [Routes.FORGOT_PASSWORD]: {
        path: "/forgot-password",
        title: "Forgot Password",
    },
    [Routes.RESET_PASSWORD]: {
        path: "/password/reset/:token",
        title: "Password Reset",
    },
    // Acount
    [Routes.ACCOUNT_PROFILE]: {
        path: "/account/profile",
        title: "My Account Profile",
    },
    [Routes.ACCOUNT_COMMISSIONS_SETTINGS]: {
        path: "/account/commissions-settings",
        title: "My Account Commissions settings",
    },

    [Routes.ACCOUNT_WALLET]: {
        path: "/account/wallet",
        title: "My Account Wallet",
    },
    [Routes.ACCOUNT_DIRECT_WITHDRAWAL]: {
        path: "/account/direct-withdrawal",
        title: "My Account Direct Withdrawal",
    },
    [Routes.ACCOUNT_REQUEST]: {
        path: "/account/request",
        title: "My Account Request",
    },
    [Routes.ACCOUNT_CHANGE_PASSWORD]: {
        path: "/account/change-password",
        title: "Change Password",
    },
    [Routes.ACCOUNT_COMMISSIONS]: {
        path: "/account/comissions",
        title: "My Comissions",
    },
    // Dashboard
    [Routes.DASHBOARD]: {
        path: "/dashboard",
        title: "Dashboard",
    },
    // [Routes.DASHBOARDV1]: {
    //     path: "/dashboard-v1",
    //     title: "DashboardV1",
    // },
    // Betting Credit Fund
    [Routes.BETTING_CREDIT_FUND]: {
        path: "/betting-credit-fund",
        title: "Betting Credit Fund",
    },
    // Operators
    [Routes.OPERATORS]: {
        path: "/operators",
        title: "Operators",
    },
    [Routes.OPERATOR_CREATE]: {
        path: "/operators/create",
        title: "Create Operator ",
    },
    [Routes.OPERATOR_DASHBOARD]: {
        path: "/operators/dashboard/:accountNo",
        title: "Operator Dasboard ",
    },
    [Routes.OPERATOR_PROFILE]: {
        path: "/operators/profile/:accountNo",
        title: "Operator Profile",
    },
    [Routes.OPERATOR_DOWNLINES]: {
        path: "/operators/profile/:accountNo/downlines",
        title: "Operator Downlines",
    },
    [Routes.OPERATOR_WALLET]: {
        path: "/operators/profile/:accountNo/fund",
        title: "Operator Fund",
    },
    [Routes.OPERATOR_REQUEST]: {
        path: "/operators/profile/:accountNo/request",
        title: "Operator Request",
    },
    [Routes.OPERATOR_PAYMENT_CREDENTIALS]: {
        path: "/operators/profile/:accountNo/payment-credentials",
        title: "Operator Payment Credentials",
    },
    [Routes.OPERATOR_SETTINGS]: {
        path: "/operators/profile/:accountNo/settings",
        title: "Operator Settings",
    },
    // Community Managers
    [Routes.COMMUNITY_MANAGERS]: {
        path: "/community-managers",
        title: "Community Managers",
    },
    [Routes.COMMUNITY_MANAGER_CREATE]: {
        path: "/community-managers/create",
        title: "Create Community Manager ",
    },
    [Routes.COMMUNITY_MANAGER_DASHBOARD]: {
        path: "/community-managers/dashboard/:accountNo",
        title: "Community Manager Dasboard ",
    },
    [Routes.COMMUNITY_MANAGER_PROFILE]: {
        path: "/community-managers/profile/:accountNo",
        title: "Community Manager Profile",
    },
    [Routes.COMMUNITY_MANAGER_COMMISSIONS_SETTINGS]: {
        path: "/community-managers/commissions-settings/:accountNo",
        title: "Community Manager Commissions Settings",
    },
    [Routes.COMMUNITY_MANAGER_DOWNLINES]: {
        path: "/community-managers/profile/:accountNo/downlines",
        title: "Community Manager Downlines",
    },
    [Routes.COMMUNITY_MANAGER_WALLET]: {
        path: "/community-managers/profile/:accountNo/fund",
        title: "Community Manager Fund",
    },
    [Routes.COMMUNITY_MANAGER_REQUEST]: {
        path: "/community-managers/profile/:accountNo/request",
        title: "Community Manager Request",
    },
    [Routes.COMMUNITY_MANAGER_PAYMENT_CREDENTIALS]: {
        path: "/community-managers/profile/:accountNo/payment-credentials",
        title: "Community Manager Payment Credentials",
    },
    [Routes.COMMUNITY_MANAGER_SETTINGS]: {
        path: "/community-managers/profile/:accountNo/settings",
        title: "Community Manager Settings",
    },
    // Master Agents
    [Routes.SUPER_AGENTS]: {
        path: "/master-agent",
        title: "Master Agent",
    },
    [Routes.SUPER_AGENT_CREATE]: {
        path: "/master-agent/create",
        title: "Create Master Agent",
    },
    [Routes.SUPER_AGENT_PROFILE]: {
        path: "/master-agents/profile/:accountNo",
        title: "Master Agent Profile",
    },
    [Routes.SUPER_AGENT_DOWNLINES]: {
        path: "/master-agents/profile/:accountNo/downlines",
        title: "Master Agent Downlines",
    },
    [Routes.SUPER_AGENT_COMMISSIONS_SETTINGS]: {
        path: "/master-agents/profile/:accountNo/commissions-settings",
        title: "Master Agent Wallet",
    },
    [Routes.SUPER_AGENT_WALLET]: {
        path: "/master-agents/profile/:accountNo/wallet",
        title: "Master Agent Wallet",
    },
    [Routes.SUPER_AGENT_REQUEST]: {
        path: "/master-agents/profile/:accountNo/request",
        title: "Master Agent Request",
    },
    [Routes.SUPER_AGENT_COMMISSIONS]: {
        path: "/master-agents/profile/:accountNo/commissions",
        title: "Master Agent Commissions",
    },
    // Agents
    [Routes.AGENTS]: {
        path: "/agents",
        title: "Agents",
    },
    [Routes.AGENT_CREATE]: {
        path: "/agents/create",
        title: "Create Agent",
    },
    [Routes.AGENT_PROFILE]: {
        path: "/agents/profile/:accountNo",
        title: "Agent Profile",
    },
    [Routes.AGENT_DOWNLINES]: {
        path: "/agents/profile/:accountNo/downlines",
        title: "Agent Downlines",
    },
    [Routes.AGENT_COMMISSIONS_SETTINGS]: {
        path: "/agents/profile/:accountNo/commissions-settings",
        title: "Agent Commissions Settings",
    },
    [Routes.AGENT_WALLET]: {
        path: "/agents/profile/:accountNo/wallet",
        title: "Agent Wallet",
    },
    [Routes.AGENT_REQUEST]: {
        path: "/agents/profile/:accountNo/request",
        title: "Agent Request",
    },
    [Routes.AGENT_COMMISSIONS]: {
        path: "/agents/profile/:accountNo/commissions",
        title: "Agent Commissions",
    },
    // Affiliates
    [Routes.AFFILIATES]: {
        path: "/affiliates",
        title: "Affiliates",
    },
    [Routes.AFFILIATE_CREATE]: {
        path: "/affiliates/create",
        title: "Create Affiliate",
    },
    [Routes.AFFILIATE_PROFILE]: {
        path: "/affiliates/profile/:accountNo",
        title: "Affiliate Profile",
    },
    [Routes.AFFILIATE_DOWNLINES]: {
        path: "/affiliates/profile/:accountNo/downlines",
        title: "Affiliate Downlines",
    },
    [Routes.AFFILIATE_COMMISSIONS_SETTINGS]: {
        path: "/affiliates/profile/:accountNo/commissions-settings",
        title: "Affiliate Commissions Settings",
    },
    [Routes.AFFILIATE_WALLET]: {
        path: "/affiliates/profile/:accountNo/wallet",
        title: "Affiliate Wallet",
    },
    [Routes.AFFILIATE_REQUEST]: {
        path: "/affiliates/profile/:accountNo/request",
        title: "Affiliate Request",
    },
    [Routes.AFFILIATE_COMMISSIONS]: {
        path: "/affiliates/profile/:accountNo/commissions",
        title: "Affiliate Commissions",
    },
    // Players
    [Routes.PLAYERS]: {
        path: "/players",
        title: "Players",
    },
    [Routes.PLAYER_CREATE]: {
        path: "/players/create",
        title: "Create Player",
    },
    [Routes.PLAYER_PROFILE]: {
        path: "/players/profile/:accountNo",
        title: "Player Profile",
    },
    [Routes.PLAYER_WALLET]: {
        path: "/players/profile/:accountNo/wallet",
        title: "Player Wallet",
    },
    [Routes.PLAYER_REQUEST]: {
        path: "/players/profile/:accountNo/request",
        title: "Player Request",
    },
    [Routes.PLAYER_BET_HISTORY]: {
        path: "/players/profile/:accountNo/bet-history",
        title: "Player Bet History",
    },
    [Routes.PLAYER_COMMISSIONS]: {
        path: "/players/profile/:accountNo/commissions",
        title: "Player commissions",
    },
    // Activity Logs
    [Routes.ACTIVITY_LOGS]: {
        path: "/activity-logs",
        title: "Activity Logs",
    },
    // User Management
    [Routes.MANAGE_USER]: {
        path: "/user-management/users",
        title: "Manage User",
    },
    [Routes.MANAGE_USER_CREATE]: {
        path: "/user-management/users/create",
        title: "Manage User Create",
    },
    [Routes.MANAGE_USER_PROFILE]: {
        path: "/user-management/users/profile/:accountNo",
        title: "User Profile",
    },
    [Routes.MANAGE_ROLE]: {
        path: "/user-management/roles",
        title: "Manage Role",
    },
    [Routes.MANAGE_ROLE_CREATE]: {
        path: "/user-management/roles/create",
        title: "Create Role",
    },
    [Routes.MANAGE_ROLE_UPDATE]: {
        path: "/user-management/roles/edit/:keyToken",
        title: "Edit Role",
    },
    // Reports
    [Routes.BET_TRANSACTION_REPORTS]: {
        path: "/reports/bet-transaction-reports",
        title: "Bet Transaction Reports",
    },
    [Routes.EGAMES_REPORTS]: {
        path: "/reports/bet-transaction-reports/e-games",
        title: "E-Games Reports",
    },
    [Routes.LIVE_CASINO_REPORTS]: {
        path: "/reports/bet-transaction-reports/live-casino",
        title: "Live Casino Reports",
    },
    [Routes.SPORTS_BETTING_REPORTS]: {
        path: "/reports/bet-transaction-reports/sports-betting",
        title: "Sports Betting Reports",
    },
    // [Routes.DEPOSIT_REQUEST_REPORTS]: {
    //     path: "/reports/deposit-request-reports",
    //     title: "Deposit Request Reports",
    // },
    // [Routes.WITHDRAWAL_REQUEST_REPORTS]: {
    //     path: "/reports/withdrawal-request-reports",
    //     title: "Withdrawal Request Reports",
    // },
    [Routes.COMMISSION_REPORTS]: {
        path: "/reports/commission-reports",
        title: "Commission Reports",
    },
    [Routes.TRANSACTION_REPORTS]: {
        path: "/reports/wallet-transaction-reports",
        title: "Wallet Transaction Reports",
    },
    //User IP Logs
    [Routes.LOGIN_IPS]: {
        path: "/user-ip-logs/login-ips",
        title: "Login IPs",
    },
    [Routes.MULTIPLE_IP_DETECTED]: {
        path: "/user-ip-logs/multiple-ip-detected",
        title: "Multiple IP Detected",
    },
    //Payment Settings
    [Routes.PAYMENT_SETTINGS_GENERAL]: {
        path: "/payment-settings/general",
        title: "General",
    },
    [Routes.PAYMENT_SETTINGS_H2PAY]: {
        path: "/payment-settings/h2pay",
        title: "H2Pay",
    },
    [Routes.PAYMENT_SETTINGS_MONETIX]: {
        path: "/payment-settings/monetix",
        title: "Monetix",
    },
    [Routes.PAYMENT_SETTINGS_SWIFTPAY]: {
        path: "/payment-settings/swift-pay",
        title: "Swift Pay",
    },
    [Routes.PAYMENT_SETTINGS_PAYMENT_PROVIDERS]: {
        path: "/payment-settings/payment-providers",
        title: "Payment Providers",
    },
    [Routes.PAYMENT_SETTINGS_AK_PAY]: {
        path: "/payment-settings/ak-pay",
        title: "AkPay",
    },
    [Routes.PAYMENT_SETTINGS_COINSPAID]: {
        path: "/payment-settings/coins-paid",
        title: "CoinsPaid",
    },
    // Settings
    [Routes.SETTINGS]: {
        path: "/settings",
        title: "System",
    },
    [Routes.COUNTRIES]: {
        path: "/countries",
        title: "Countries",
    },
    // Kyc
    [Routes.KYC]: {
        path: "/approval-request/kyc",
        title: "Kyc",
    },
    // Deposit Request
    [Routes.DEPOSIT_REQUEST]: {
        path: "/approval-request/deposit",
        title: "Deposit Request",
    },
    [Routes.DEPOSIT_REQUEST_VIEW]: {
        path: "/approval-request/deposit/:transactionRequestNo",
        title: "Deposit Request View",
    },
    // Withdrawal Request
    [Routes.WITHDRAWAL_REQUEST]: {
        path: "/approval-request/withdrawal",
        title: "Withdrawal Request",
    },
    [Routes.WITHDRAWAL_REQUEST_VIEW]: {
        path: "/approval-request/withdrawal/:transactionRequestNo",
        title: "Withdrawal Request View",
    },
    // Affiliate request
    [Routes.AFFILIATES_REQUEST_LIST]: {
        path: "/approval-request/affiliates",
        title: "Affiliates Request List",
    },
    //For Release
    [Routes.FOR_RELEASE]: {
        path: "/approval-request/for-release",
        title: "For Release",
    },
    [Routes.FOR_RELEASE_VIEW]: {
        path: "/approval-request/for-release/:transactionRequestNo",
        title: "For Release View",
    },
    //For Payout Release
    [Routes.FOR_PAYOUT_RELEASE]: {
        path: "/approval-request/for-payout-release",
        title: "For Payout Release",
    },
    [Routes.FOR_PAYOUT_RELEASE_VIEW]: {
        path: "/approval-request/for-payout-release/:transactionRequestNo",
        title: "For Payout Release View",
    },
    // Transactions
    [Routes.TRANSACTIONS]: {
        path: "/wallet-transactions",
        title: "Wallet Transactions",
    },
    [Routes.TRANSACTION_VIEW]: {
        path: "/wallet-transactions/:transactionNo",
        title: "Wallet Transactions View",
    },
    // Casino Game
    [Routes.CASINO_GAME_PROVIDER]: {
        path: "/casino/game-provider",
        title: "Casino Game Provider",
    },
    [Routes.CASINO_GAME_TYPE]: {
        path: "/casino/game-type",
        title: "Casino Game Type",
    },
    [Routes.CASINO_GAME_LIST]: {
        path: "/casino/game-list",
        title: "Casino Game List",
    },
    //Tip Transaction
    [Routes.TIP_TRANSACTION]: {
        path: "/tip-transactions",
        title: "Tip Transactions",
    },
    [Routes.TIP_TRANSACTION_VIEW]: {
        path: "/tip-transactions/view/:transactionNo",
        title: "Tip Transaction view",
    },
    // Errors
    [Routes.ERROR_NOT_FOUND]: {
        path: "/error/not-found",
        title: "Not Found",
    },
    [Routes.ERROR_NOT_FOUND]: {
        path: "/error/not-found",
        title: "Not Found",
    },
    [Routes.BET_TRANSACTION]: {
        path: "/bet-transactions",
        title: "Bet Transactions",
    },
    [Routes.EGAMES_TRANSACTION]: {
        path: "/bet-transactions/e-games",
        title: "E-Games Transactions",
    },
    [Routes.LIVE_CASINO_TRANSACTION]: {
        path: "/bet-transactions/live-casino",
        title: "Live Casino Transactions",
    },
    [Routes.SPORTS_BETTING_TRANSACTION]: {
        path: "/bet-transactions/sports-betting",
        title: "Sports Betting Transactions",
    },
    [Routes.ESPORTS_TRANSACTION]: {
        path: "/bet-transactions/e-sports",
        title: "E-Sports Transactions",
    },
    [Routes.LOTTO_TRANSACTION]: {
        path: "/bet-transactions/lotto",
        title: "Lotto Transactions",
    },
    [Routes.BET_TRANSACTION_VIEW]: {
        path: "/bet-transactions/view/:transactionNo",
        title: "View Transactions",
    },
    [Routes.COMMISSIONS_AGENTS]: {
        path: "/commissions/agents",
        title: "Agents Commissions",
    },
    [Routes.COMMISSIONS_PLAYERS]: {
        path: "/commissions/players",
        title: "Players Commissions",
    },
    [Routes.WIN_LOSS_BY_AGENT_REPORT]: {
        path: "/reports/win-loss/agent",
        title: "Win/Loss by Agent",
    },
    [Routes.WIN_LOSS_BY_PRODUCT_REPORT]: {
        path: "/reports/win-loss/product",
        title: "Win/Loss by Product",
    },
    [Routes.GENERATED_REPORT]: {
        path: "/reports/generated-reports",
        title: "Generated Report",
    },
    // Promotions
    [Routes.PROMOTION_LIST]: {
        path: "/promotions",
        title: "Promotion List",
    },
    [Routes.PROMOTION_CATEGORIES]: {
        path: "/promotions/categories",
        title: "Promotion Categories",
    },
    [Routes.PROMOTION_CREATE]: {
        path: "/promotions/create",
        title: "Create Promotion",
    },
    [Routes.PROMOTION_UPDATE]: {
        path: "/promotions/update/:promotionNo",
        title: "Update Promotion",
    },
    [Routes.PROMOTION_TRANSACTION_LIST]: {
        path: "/promotions/transaction",
        title: "Promotion Transaction List",
    },
    [Routes.PROMOTION_TRANSACTION_VIEW]: {
        path: "/promotions/transaction/:promotionNo",
        title: "Promotion Transaction",
    },
    //hot matches
    [Routes.HOT_MATCHES_SETTINGS_SPORT_TYPE]: {
        path: "/hot-matches-settings/sport-type",
        title: "Sport Type",
    },
    [Routes.HOT_MATCHES_SETTINGS_LEAGUE_TYPE]: {
        path: "/hot-matches-settings/league-type",
        title: "League Type",
    },
    [Routes.HOT_MATCHES_SETTINGS_GAME_TYPE]: {
        path: "/hot-matches-settings/game-type",
        title: "Game Type",
    },
    [Routes.HOT_MATCHES_SETTINGS_TEAM]: {
        path: "/hot-matches-settings/team",
        title: "Team",
    },
    [Routes.HOT_MATCHES_SETTINGS_HOT_MATCHES]: {
        path: "/hot-matches-settings/hot-matches",
        title: "Hot Matches",
    },
    //Announcement
    [Routes.SEND_ANNOUNCEMENT]: {
        path: "/announcements/send",
        title: "Send Announcement",
    },
    [Routes.MESSAGE_LIST]: {
        path: "/announcements/message",
        title: "Message List",
    },
    [Routes.MESSAGE_CREATE_OR_UPDATE]: {
        path: "/announcements/message/create",
        title: "Create Message",
    },
    [Routes.NOTIFICATION_TYPE_LIST]: {
        path: "/announcements/notification-type",
        title: "Notification Type List",
    },
    [Routes.NOTIFICATION_LIST]: {
        path: "/announcements/notification-type/:keyToken",
        title: "Notification List",
    },
    //Lotto
    [Routes.DRAW_SCHEDULE_LIST]: {
        path: "/lotto-settings/draw-shedule-list",
        title: "Draw Schedule List",
    },
};
