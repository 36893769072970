import { Title } from "@/components/atoms";
import BreadCrumb from "@/components/common/BreadCrumb";
import { CreateOrUpdatePromotionForm } from "@/components/molecules";
import { Routes, getPath, getPathTitle } from "@/constants";
import { getPromotionByNo } from "@/store/actions";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";

const PromotionCreateOrUpdate = () => {
    const dispatch = useDispatch();
    const { promotionNo } = useParams();

    useEffect(() => {
        if (promotionNo) {
            dispatch(getPromotionByNo(promotionNo));
        }
    }, [dispatch, promotionNo]);

    return (
        <React.Fragment>
            <Title
                title={getPathTitle(
                    Routes[
                        promotionNo ? "PROMOTION_UPDATE" : "PROMOTION_CREATE"
                    ]
                )}
            />
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb
                        parent={{
                            isParent: true,
                            pageTitle: getPathTitle(Routes.PROMOTION_LIST),
                            pageLink: getPath(Routes.PROMOTION_LIST),
                        }}
                        childs={[
                            {
                                pageTitle: getPathTitle(
                                    Routes[
                                        promotionNo
                                            ? "PROMOTION_UPDATE"
                                            : "PROMOTION_CREATE"
                                    ]
                                ),
                                pageLink: getPath(
                                    Routes[
                                        promotionNo
                                            ? "PROMOTION_UPDATE"
                                            : "PROMOTION_CREATE"
                                    ]
                                ),
                                isActive: true,
                            },
                        ]}
                    />
                    <Row>
                        <Col xs={12}>
                            <CardHeader className="align-items-center d-flex">
                                <h4 className="card-title mb-0 flex-grow-1">
                                    {getPathTitle(
                                        Routes[
                                            promotionNo
                                                ? "PROMOTION_UPDATE"
                                                : "PROMOTION_CREATE"
                                        ]
                                    )}
                                </h4>
                                <div className="flex-shrink-0">
                                    Please Fill up all fields
                                </div>
                            </CardHeader>
                            <Card>
                                <CardBody>
                                    <CreateOrUpdatePromotionForm />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default PromotionCreateOrUpdate;
