import React from "react";
import ProfileLayout from "../Layout";
import { CommissionsSettings as CommissionsSettingsTab } from "@/components/molecules";
import { useSelector } from "react-redux";

const CommissionsSettings = () => {
    const {
        user: profile,
    } = useSelector((state) => state.user);
    
  return (
    <ProfileLayout>
      <CommissionsSettingsTab
        profile={profile}
        commissionRateErrors={{}}
        userType={"agent"}
        showEditMerchant={false}
        showEditBCF={false}
      />
    </ProfileLayout>
  );
};

export default CommissionsSettings;
