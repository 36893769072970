import React from "react";
import { Label, Input, FormFeedback } from "reactstrap";

const InputSelect = ({ label, validation, name, options, ...rest }) => {
    return (
        <>
            <Label>{label}</Label>
            <Input
                type="select"
                className="form-control"
                name={name}
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values[name] || ""}
                invalid={
                    validation.touched[name] && validation.errors[name]
                        ? true
                        : false
                }
                {...rest}
            >
                {options.map((item, key) => (
                    <option key={key} value={item.value}>
                        {item.label}
                    </option>
                ))}
            </Input>
            {validation.touched[name] && validation.errors[name] ? (
                <FormFeedback type="invalid">
                    {validation.errors[name]}
                </FormFeedback>
            ) : null}
        </>
    );
};

export default InputSelect;
