import { playerState, setUserData } from "@/types/states";
import * as types from "./actionTypes";

const initialState = playerState;

const player = (state = initialState, action) => {
    switch (action.type) {
        case types.UPDATE_ID_VERIFICATION_STATUS:
            state = {
                ...state,
                isUpdateIdVerificationStatusSuccess: false,
                isLoading: true,
            };
            break;
        case types.GET_PLAYER_LIST:
        case types.CREATE_PLAYER:
        case types.GET_PLAYER_PROFILE:
        case types.UPDATE_PLAYER:
        case types.GET_VERIFICATION_ID:
            state = {
                ...state,
                isLoading: true,
            };
            break;
        case types.SET_PLAYER_LIST:
            state = {
                ...state,
                isLoading: false,
                list: action.payload.response,
                errors: playerState.errors,
            };
            break;
        case types.SET_PLAYER_LIST_ERROR:
        case types.SET_PLAYER_PROFILE_ERROR:
            state = {
                ...state,
                isLoading: false,
            };
            break;
        case types.CREATE_PLAYER_SUCCESS:
            state = {
                ...state,
                isLoading: false,
                errors: playerState.errors,
            };
            break;
        case types.GET_VERIFICATION_ID_SUCCESS:
            state = {
                ...state,
                isLoading: false,
                idList: action.payload,
                errors: playerState.errors,
            };
            break;
        case types.UPDATE_PLAYER_EMAIL_SUCCESS:
            state = {
                ...state,
                isLoading: false,
                profile: {
                    ...state.profile,
                    email: action.payload.email,
                },
                errors: playerState.errors,
            };
            break;
        case types.UPDATE_ID_VERIFICATION_STATUS_ERROR:
        case types.UPDATE_PLAYER_ERROR:
        case types.CREATE_PLAYER_ERROR:
        case types.GET_VERIFICATION_ID_ERROR:
        case types.UPDATE_FULLY_VERIFICATION_STATUS_FAILED:
            state = {
                ...state,
                isLoading: false,
                errors: action.payload,
            };
            break;
        case types.UPDATE_ID_VERIFICATION_STATUS_SUCCESS:
            state = {
                ...state,
                isLoading: false,
                isUpdateIdVerificationStatusSuccess: true,
                profile: setUserData(action),
            };
            break;
        case types.UPDATE_PLAYER_SUCCESS:
        case types.SET_PLAYER_PROFILE:
            state = {
                ...state,
                isLoading: false,
                profile: setUserData(action),
                errors: playerState.errors,
            };
            break;
        case types.UPDATE_PLAYER_WALLET_BALANCE:
            state = {
                ...state,
                profile: {
                    ...state.profile,
                    balance: action.payload,
                },
            };
            break;
        case types.RESET_PLAYER_ERRORS:
            state = {
                ...state,
                isLoading: false,
                errors: playerState.errors,
            };
            break;
        case types.UPDATE_FULLY_VERIFICATION_STATUS:
            state = {
                ...state,
                isUpdateFullyVerificationSuccess: false,
            };
            break;
        case types.UPDATE_FULLY_VERIFICATION_STATUS_SUCCESS:
            state = {
                ...state,
                isUpdateFullyVerificationSuccess: true,
                profile: setUserData(action),
            };
            break;
        case types.GET_USER_PROFILE_DOCUMENTS:
            state = {
                ...state,
                isLoading: true,
            };
            break;
        case types.GET_USER_PROFILE_DOCUMENTS_SUCCESS:
            state = {
                ...state,
                userProfileDocuments: action.payload,
                isLoading: false,
            };
            break;
        case types.GET_USER_PROFILE_DOCUMENTS_FAILED:
            state = {
                ...state,
                isLoading: false,
            };
            break;
        case types.GET_PLAYER_SELECTION_LIST:
            state = {
                ...state,
                isGetPlayerSelectionListLoading: true,
            };
            break;
        case types.GET_PLAYER_SELECTION_LIST_SUCCESS:
            state = {
                ...state,
                playerSelectionList: action.payload.response,
                isGetPlayerSelectionListLoading: false,
            };
            break;
        case types.GET_PLAYER_SELECTION_LIST_FAILED:
            state = {
                ...state,
                isGetPlayerSelectionListLoading: false,
            };
            break;
        case types.CLEAR_PLAYER_SELECTION_LIST:
            state = {
                ...state,
                playerSelectionList: [],
            };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default player;
