import {
  Title,
  InputSwitch,
  DisplayText,
  StatusBadge,
} from "@/components/atoms";
import BreadCrumb from "@/components/common/BreadCrumb";
import {
  UpdatePromotionCommonForm,
  UpdatePromotionDateRange,
  UpdatePromotionSettingsForm,
  FileInputForm,
} from "@/components/molecules";
import { Routes, getPath, getPathTitle } from "@/constants";
import { getPromotionByNo, updatePromotion } from "@/store/actions";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Label,
  Row,
} from "reactstrap";
import { confirmation } from "@/components/constants/common";
import { updatePromotionStatus } from "@/store/actions";
import { defaultDateTimeFormat } from "@/utils";

const PromotionUpdate = () => {
  const dispatch = useDispatch();
  const { promotionNo } = useParams();

  const { isSubmit, errors, selectedPromotionCategory, selectedPromotion, isLoading } =
    useSelector((state) => state.promotion);

  useEffect(() => {
    if (promotionNo) {
      dispatch(getPromotionByNo(promotionNo));
    }
  }, [dispatch, promotionNo]);

  const initialize = () => {
    if (promotionNo) {
      dispatch(getPromotionByNo(promotionNo));
    }
  };

  const changeStatus = (item) => {
    confirmation({
      title: item.promotion_no,
      text: `Are you sure you want to ${
        item.is_active ? "REMOVE POST" : "POST"
      } on promotions page`,
      icon: "warning",
      confirmButtonText: "Confirm",
      action: () => {
        dispatch(updatePromotionStatus(item.key_token, initialize));
      },
    });
  };

  const updateImages = (type, values) => {
    dispatch(
      updatePromotion(selectedPromotion?.key_token, values, type, initialize)
    );
  };

  return (
    <React.Fragment>
      <Title title={getPathTitle(Routes["PROMOTION_UPDATE"])} />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            parent={{
              isParent: true,
              pageTitle: getPathTitle(Routes.PROMOTION_LIST),
              pageLink: getPath(Routes.PROMOTION_LIST),
            }}
            childs={[
              {
                pageTitle: getPathTitle(Routes["PROMOTION_UPDATE"]),
                pageLink: getPath(Routes["PROMOTION_UPDATE"]),
                isActive: true,
              },
            ]}
          />
          <Row>
            <Col xs={12}>
              <CardHeader className="align-items-center d-flex">
                <h4 className="card-title mb-0 flex-grow-1">
                  {selectedPromotion?.promotion_category?.title}
                </h4>
                <div className="flex-shrink-0">Please Fill up all fields</div>
              </CardHeader>
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <DisplayText
                        label="Created At"
                        item={defaultDateTimeFormat(
                          selectedPromotion?.created_at
                        )}
                        isLoading={isLoading}
                        col="8"
                      />
                    </Col>
                    <Col>
                      <DisplayText
                        label="Ongoing"
                        item={
                          <StatusBadge
                            status={
                              selectedPromotion?.is_ongoing ? "yes" : "no"
                            }
                            type="bool"
                          />
                        }
                        isLoading={isLoading}
                        col="8"
                      />
                    </Col>
                    <Col>
                      <DisplayText
                        label="Post on promotions page?"
                        item={
                          <InputSwitch
                            label={selectedPromotion?.is_active ? "Yes" : "No"}
                            id={`promotion-status`}
                            isChecked={selectedPromotion?.is_active ?? false}
                            color="success"
                            onChange={() => changeStatus(selectedPromotion)}
                          />
                        }
                        isLoading={isLoading}
                        col="8"
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <UpdatePromotionCommonForm
                    selectedPromotion={selectedPromotion}
                    isSubmit={isSubmit}
                    initialize={initialize}
                    errors={errors}
                  />
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <p className="text-muted">
                    Note: If start_datetime is lessthan current datetime, it is
                    not editable.
                  </p>
                  <UpdatePromotionDateRange
                    selectedPromotion={selectedPromotion}
                    isSubmit={isSubmit}
                    initialize={initialize}
                    errors={errors}
                  />
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <FileInputForm
                        field="banner"
                        description="Recommended:"
                        defaultImage={selectedPromotion?.banner}
                        label="Banner"
                        onSubmit={(values) =>
                          updateImages("update-banner", values)
                        }
                      />
                    </Col>
                    <Col>
                      <FileInputForm
                        field="thumbnail"
                        description="Recommended: 1913 x 855"
                        defaultImage={selectedPromotion?.thumbnail}
                        label="Thumbnail"
                        onSubmit={(values) =>
                          updateImages("update-thumbnail", values)
                        }
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <p className="text-muted">
                    Note: If start_datetime is lessthan current datetime, it is
                    not editable.
                  </p>
                  <UpdatePromotionSettingsForm
                    selectedPromotion={selectedPromotion}
                    selectedPromotionCategory={selectedPromotionCategory}
                    isSubmit={isSubmit}
                    initialize={initialize}
                    errors={errors}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PromotionUpdate;
