import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import { encryptTransform } from "redux-persist-transform-encrypt";
import storage from "redux-persist/lib/storage";

import address from "./address/reducers";
import admin from "./admin/reducers";
import operator from "./operator/reducers";
import agent from "./agent/reducers";
import layout from "./layout/reducers";
import loader from "./loader/reducers";
import player from "./player/reducers";
import request from "./request/reducers";
import setting from "./setting/reducers";
import transaction from "./transaction/reducers";
import user from "./user/reducers";
import game from "./game/reducers";
import report from "./report/reducers";
import dashboard from "./dashboard/reducers";
import country from "./country/reducers";
import paymentSetting from "./payment-setting/reducers";
import promotion from "./promotion/reducers";
import hotMatchesSetting from "./hot-match-setting/reducers";
import announcement from "./announcement/reducers";
import lottoSetting from "./lotto-setting/reducers";


const encryptor = encryptTransform({
    secretKey: process.env.REACT_APP_PERSIST_SECRET_KEY,
    onError: function (error) {
        // Handle the error.
    },
});

const persistConfig = {
    key: "root",
    storage,
    whitelist: ["layout", "setting"],
    transforms: [encryptor],
};

const userConfig = {
    key: "user",
    storage,
    transforms: [encryptor],
};

const rootReducer = combineReducers({
    layout,
    loader,
    user: persistReducer(userConfig, user),
    address,
    operator,
    agent,
    admin,
    player,
    transaction,
    request,
    setting,
    game,
    report,
    dashboard,
    country,
    paymentSetting,
    promotion,
    hotMatchesSetting,
    announcement,
    lottoSetting
});

export default persistReducer(persistConfig, rootReducer);
