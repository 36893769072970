import {
    AmountFormat,
    CounterWidget,
    FlatPickerDate,
    InputReactSelect,
    StatusBadge,
    Table,
    Title,
    Select,
} from "@/components/atoms";
import BreadCrumb from "@/components/common/BreadCrumb";
import { Routes, getPath, getPathTitle, requestStatus } from "@/constants";
import {
    walletTransactionCounter,
    balanceCategoryOptions,
} from "@/constants/users";
import { useUserTypeOptions, userRole } from "@/hooks";
import { getRequestData, getPaymentProviders, getPublicPaymentMethods } from "@/store/actions";
import {
    arrayToString,
    capitalizeFirstLetter,
    defaultDateTimeFormat,
} from "@/utils";
import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row,
} from "reactstrap";

const WithdrawalRequestList = () => {
    const dateFilterRef = useRef();
    const dispatch = useDispatch();
    const { isSuperAdmin } = userRole();

    const { data: filteredUserTypeOptions } = useUserTypeOptions();

    const { list, isLoading, paymentMethods } = useSelector((state) => state.request);

    const { paymentProviders } = useSelector(
        (state) => state.paymentSetting
    );

    const {
        user,
        isLoading: isLoadingUser,
        errors: userErrors,
    } = useSelector((state) => state.user);

    const isAdmin =
        user?.role.slug === "super-admin" || user?.role.slug === "admin"
            ? true
            : false;

    const [search, setSearch] = useState("");
    const [sortBy, setSortBy] = useState({
        field: "",
        type: "asc",
    });

    const defaultParams = {
        account_no: "",
        per_page: 10,
        page: 1,
        search: "",
        sort_by:
            sortBy.field && sortBy.type ? `${sortBy.field} ${sortBy.type}` : "",
        "date_range[transaction_requests.created_at][from]": "",
        "date_range[transaction_requests.created_at][to]": "",
        "filter_by[balance_categories.key_name]": "",
    };

    const [data, setData] = useState(defaultParams);
    const withdrawalCounter = walletTransactionCounter(list);

    const form = useFormik({
        enableReinitialize: true,
        initialValues: {
            userType: "",
            userFilter: [],
            status: [],
            dateFilter: [],
            balanceCategory: "",
            paymentProvider: "",
            paymentMethod: "",
        },
        onSubmit: (values) => {
            let filter = {
                ...data,
                page: 1,
                user_type: values?.userType["value"] ?? null,
                "filter_by[transaction_requests.transaction_request_status_id]":
                    values.status.length > 0
                        ? arrayToString(
                              values.status.map((item) => {
                                  return item.id;
                              })
                          )
                        : null,
                "date_range[transaction_requests.created_at][from]":
                    values.dateFilter
                        ? values.dateFilter[0]
                        : data[
                              "date_range[transaction_requests.created_at][from]"
                          ],
                "date_range[transaction_requests.created_at][to]":
                    values.dateFilter
                        ? values.dateFilter[1]
                        : data[
                              "date_range[transaction_requests.created_at][to]"
                          ],
                "filter_by[balance_categories.key_name]":
                    values.balanceCategory,
                "filter_by[payment_methods.key_token]":
                values.paymentMethod ? arrayToString(
                    values.paymentMethod.map((item) => {
                        return item.value;
                    })
                )
                : null,
                "payment_order_provider": values.paymentProvider,
            };

            if (!values.paymentProvider && Object.hasOwn(filter, 'payment_order_provider')) {
                delete filter.payment_order_provider
            }

            setData(filter);
        },
    });

    const paymentProvidersData = React.useMemo(() => [{ label: 'All', value: '' }, ...paymentProviders.map((provider) => ({ label: provider.name, value: provider.key_name }))], [paymentProviders]);
    const paymentMethodsData = React.useMemo(() => {
        
        const paymentMethodsKeys = Object.keys(paymentMethods);
        let mappedPaymentMethods = [];

        paymentMethodsKeys.forEach(paymentMethodsKey => {
            paymentMethods[paymentMethodsKey].forEach(method => mappedPaymentMethods.push({ label: method.name, value: method.key_token }))
        });

        return mappedPaymentMethods;
    }, [paymentMethods]);

    const handleClear = () => {
        setData(defaultParams);
        dateFilterRef.current.flatpickr.clear();
        form.resetForm();
    };

    const changeUserType = (option) => {
        form.setFieldValue("userType", option);
    };

    const tableTheadData = [
        {
            label: "Request No.",
            field: "transaction_request_no",
            sortable: true,
        },
        {
            label: "Account No.",
            field: "account_no",
            sortable: true,
        },
        {
            label: "Balance Category",
            field: "balance_category",
            sortable: false,
            render: (item) => capitalizeFirstLetter(item.balance_category),
        },
        {
            label: "Fee %",
            field: "fee_percent",
            sortable: true,
            render: (item) => item.fee_percent,
        },
        {
            label: "Fee Amount",
            field: "fee_amount",
            sortable: true,
            render: (item) => <AmountFormat amount={item.fee_amount} />,
        },
        {
            label: "Amount",
            field: "amount",
            sortable: true,
            render: (item) => <AmountFormat amount={item.amount} />,
        },
        {
            label: "Payment Method",
            field: "payment_method",
            sortable: true,
            render: (item) => capitalizeFirstLetter(item?.payment_method?.name),
        },
        {
            label: "Datetime Requested",
            field: "created_at",
            sortable: true,
            render: (item) => defaultDateTimeFormat(item.created_at),
        },
        {
            label: "IP Addresses",
            field: "ip_address",
            sortable: true,
            isHide: !isSuperAdmin,
            // render: (item) => defaultDateTimeFormat(item.created_at),
        },
        {
            label: "User Agent UUID",
            field: "user_agent_uuid",
            sortable: true,
            isHide: !isSuperAdmin,
            // render: (item) => defaultDateTimeFormat(item.created_at),
        },
        {
            label: "Status",
            field: "status",
            sortable: true,
            render: (item) => (
                <StatusBadge status={item.status} type="request" />
            ),
        },
        {
            label: "Action",
            field: "action",
            sortable: false,
            render: (item) => (
                <Link
                    to={getPath(Routes.WITHDRAWAL_REQUEST_VIEW).replace(
                        ":transactionRequestNo",
                        item?.transaction_request_no
                    )}
                    className="link-primary"
                >
                    <Button color="success" size="sm">
                        <i className="ri-eye-line"></i>
                    </Button>
                </Link>
            ),
        },
    ];

    useEffect(() => {
        if (isSuperAdmin) {
            dispatch(getPaymentProviders());
        }
        dispatch(getPublicPaymentMethods());
        dispatch(getRequestData(data, "withdrawal"));
    }, [dispatch, data, isSuperAdmin]);

    return (
        <React.Fragment>
            <Title title={getPathTitle(Routes.WITHDRAWAL_REQUEST)} />
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb
                        parent={{
                            isParent: true,
                            pageTitle: getPathTitle(Routes.WITHDRAWAL_REQUEST),
                            pageLink: getPath(Routes.WITHDRAWAL_REQUEST),
                        }}
                    />
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardHeader className="align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">
                                        {getPathTitle(
                                            Routes.WITHDRAWAL_REQUEST
                                        )}
                                    </h4>
                                </CardHeader>
                                <CardBody>
                                    <Row className="mb-3">
                                        <Col md={3}>
                                            <InputReactSelect
                                                label="User Type"
                                                isClearable={false}
                                                value={form.values.userType}
                                                options={
                                                    filteredUserTypeOptions
                                                }
                                                onChange={(option) => {
                                                    changeUserType(option);
                                                }}
                                            />
                                        </Col>
                                        <Col md={3}>
                                            <InputReactSelect
                                                label="Status"
                                                isClearable={true}
                                                options={requestStatus}
                                                value={form.values.status}
                                                onChange={(option) => {
                                                    form.setFieldValue(
                                                        "status",
                                                        option
                                                    );
                                                }}
                                                isMulti={true}
                                            />
                                        </Col>
                                        <Col md={3}>
                                            <FlatPickerDate
                                                label="Date"
                                                name="dateFilter"
                                                ref={dateFilterRef}
                                                form={form}
                                                mode="range"
                                                value={form.values.dateFilter}
                                            />
                                        </Col>
                                        <Col md={3}>
                                            <Select
                                                name="balanceCategory"
                                                label="Balance Category"
                                                isClearable={false}
                                                value={
                                                    form.values.balanceCategory
                                                }
                                                options={balanceCategoryOptions(
                                                    "all"
                                                )}
                                                onChange={(e) => {
                                                    form.setFieldValue(
                                                        "balanceCategory",
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        {isSuperAdmin && 
                                            <Col xs={12} sm={6} xl={3}>
                                                <Select
                                                    name="paymentProvider"
                                                    label="Payment Provider"
                                                    isClearable={false}
                                                    value={
                                                        form.values.paymentProvider
                                                    }
                                                    options={paymentProvidersData}
                                                    onChange={(e) => {
                                                        form.setFieldValue(
                                                            "paymentProvider",
                                                            e.target.value
                                                        );
                                                    }}
                                                />
                                            </Col>
                                        }

                                        <Col xs={12} sm={6} xl={3}>
                                            <InputReactSelect
                                                isMulti={true}
                                                name="paymentMethod"
                                                label="Payment Method"
                                                isClearable={false}
                                                value={
                                                    form.values.paymentMethod
                                                }
                                                options={paymentMethodsData}
                                                onChange={(option) => {
                                                    form.setFieldValue(
                                                        "paymentMethod",
                                                        option
                                                    );
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} className="text-center">
                                            <Button
                                                color="primary"
                                                className="mx-1"
                                                onClick={() => handleClear()}
                                            >
                                                Clear
                                            </Button>
                                            <Button
                                                color="success"
                                                className="mx-1"
                                                onClick={() =>
                                                    form.handleSubmit()
                                                }
                                            >
                                                Filter
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        {withdrawalCounter.map((item, key) => (
                            <Col key={key}>
                                <CounterWidget item={item} icon="₱" />
                            </Col>
                        ))}
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <Table
                                        search={search}
                                        handlerSearch={setSearch}
                                        sortBy={sortBy}
                                        handlerSortBy={setSortBy}
                                        thead={tableTheadData}
                                        handlerChangeData={setData}
                                        data={data}
                                        collection={list}
                                        isLoading={isLoading}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default WithdrawalRequestList;
