import { AmountFormat, StatusBadge, Table } from "@/components/atoms";
import { getPlayerList } from "@/store/actions";
import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Row } from "reactstrap";
import ProfileLayout from "../Layout";
import { Link } from "react-router-dom";
import { getPath, Routes } from "@/constants";

const Downlines = () => {
    const dispatch = useDispatch();
    const isMounted = useRef(false);
    const { profile } = useSelector((state) => state.agent);
    const { list: playerList, isLoading: isLoadingPlayer } = useSelector(
        (state) => state.player
    );

    const [search, setSearch] = useState("");
    const [sortBy, setSortBy] = useState({
        field: "",
        type: "asc",
    });

    const [playerData, setPlayerData] = useState({
        user_type: "player",
        per_page: 10,
        page: 1,
        search: "",
        sort_by:
            sortBy.field && sortBy.type ? `${sortBy.field} ${sortBy.type}` : "",
    });

    const tableTheadData = [
        {
            label: "Account No.",
            field: "account_no",
            sortable: true,
            render: (item) => item?.user_profile?.account_no,
        },
        {
            label: "Username",
            field: "name",
            sortable: true,
            render: (item) => item?.name,
        },
        {
            label: "Email",
            field: "email",
            sortable: true,
        },
        {
            label: "PHP",
            field: "user_profile_balance_merchant.amount",
            sortable: true,
            render: (item) => (
                <AmountFormat
                    amount={item?.user_profile?.balance?.fiat?.php?.merchant}
                />
            ),
        },
        {
            label: process.env.REACT_APP_BCF_LABEL,
            field: "user_profile_balance_bcf.amount",
            sortable: true,
            render: (item) => (
                <AmountFormat
                    amount={item?.user_profile?.balance?.fiat?.php?.bcf}
                />
            ),
        },
        {
            label: "Status",
            field: "status",
            sortable: true,
            render: (item) => <StatusBadge status={item.status} />,
        },
        {
            label: "Action",
            field: "action",
            sortable: false,
            render: (item) => (
                <Link
                    to={getPath(Routes.PLAYER_PROFILE).replace(
                        ":accountNo",
                        item?.user_profile?.account_no
                    )}
                    className="link-primary"
                >
                    <Button color="success" size="sm">
                        <i className="ri-eye-line"></i>
                    </Button>
                </Link>
            ),
        },
    ];

    useEffect(() => {
        if (isMounted.current) {
            dispatch(
                getPlayerList({ ...playerData, key_token: profile.keyToken })
            );
        } else isMounted.current = true;
    }, [dispatch, playerData, profile]);

    return (
        <ProfileLayout>
            <Row>
                <Col xs={12}>
                    <Table
                        search={search}
                        handlerSearch={setSearch}
                        sortBy={sortBy}
                        handlerSortBy={setSortBy}
                        thead={tableTheadData}
                        handlerChangeData={setPlayerData}
                        data={playerData}
                        collection={playerList}
                        isLoading={isLoadingPlayer}
                        size="sm"
                    />
                </Col>
            </Row>
        </ProfileLayout>
    );
};

export default Downlines;
