import React from "react";
import ProfileLayout from "../Layout";
import { CommissionsSettings as CommissionsSettingsTab } from "@/components/molecules";
import { userRole } from "@/hooks";
import { useSelector } from "react-redux";

const CommissionsSettings = () => {
  const { isSuperAdmin, isOperator } = userRole();
  const { commissionRateErrors, profile } = useSelector((state) => state.agent);
  return (
    <ProfileLayout>
      <CommissionsSettingsTab
        profile={profile}
        commissionRateErrors={commissionRateErrors}
        userType={"agent"}
        showEditMerchant={isSuperAdmin}
        showEditBCF={isOperator}
      />
    </ProfileLayout>
  );
};

export default CommissionsSettings;
