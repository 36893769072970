import React from "react";
import ReactQuill, {Quill} from "react-quill";
import { FormFeedback, Label } from "reactstrap";
import htmlEditButton from "quill-html-edit-button";

Quill.register("modules/htmlEditButton", htmlEditButton);

const RichTextArea = ({ label, validation, name, ...rest }) => {
    const modules = {
        toolbar: [
            // ["bold", "italic", "underline", "strike"], // toggled buttons
            // ["blockquote", "code-block"],
            // ["link", "image", "video", "formula"],

            // [{ header: 1 }, { header: 2 }], // custom button values
            // [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
            // [{ script: "sub" }, { script: "super" }], // superscript/subscript
            // [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
            // [{ direction: "rtl" }], // text direction

            // [{ size: ["small", false, "large", "huge"] }], // custom dropdown
            // [{ header: [1, 2, 3, 4, 5, 6, false] }],

            // [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            // [{ font: [] }],
            // [{ align: [] }],

            // ["clean"],
            [{ header: [1, 2, false] }, { font: [] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [
              { list: "ordered" },
              { list: "bullet" },
              { indent: "-1" },
              { indent: "+1" }
            ],
            ["link", "image", "video"],
            ["clean"]
        ],
        htmlEditButton: {
            msg: "Edit the content in HTML format", //Custom message to display in the editor, default: Edit HTML here, when you click "OK" the quill editor's contents will be replaced
            okText: "Ok", // Text to display in the OK button, default: Ok,
            cancelText: "Cancel", // Text to display in the cancel button, default: Cancel
            buttonHTML: "<>", // Text to display in the toolbar button, default: <>
            buttonTitle: "Show HTML source", // Text to display as the tooltip for the toolbar button, default: Show HTML source
            syntax: false, // Show the HTML with syntax highlighting. Requires highlightjs on window.hljs (similar to Quill itself), default: false
            prependSelector: "div#myelement", // a string used to select where you want to insert the overlayContainer, default: null (appends to body),
            editorModules: {} // The default mod
          }
    };

    const handleChange = (content, delta, source, editor) => {
        validation.setFieldValue(name, content)
      };

    return (
        <>
            <Label>{label}</Label>
            <ReactQuill
                name={name}
                theme="snow"
                value={validation.values[name] || ""}
                onChange={handleChange}
                {...rest}
                modules={modules}
            />
            {validation?.touched[name] && validation?.errors[name] ? (
                <FormFeedback type="invalid">
                    {validation?.errors[name]}
                </FormFeedback>
            ) : null}
        </>
    );
};

export default RichTextArea;
