import {
    AmountFormat,
    CollapsibleTable,
    FlatPickerDate,
    InputText,
    Select,
    Title,
} from "@/components/atoms";
import BreadCrumb from "@/components/common/BreadCrumb";
import {
    Routes,
    balanceCategoryOptions,
    dateFilterOptions,
    getPath,
    getPathTitle,
    reportUserTypeOptions,
} from "@/constants";
import { userRole } from "@/hooks";
import { getGameOfferings, getWinLossReport } from "@/store/actions";
import { capitalizeFirstLetter, filterDateFormat, today } from "@/utils";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row,
} from "reactstrap";

const WinLossReport = ({ filter = null, dashboard = false }) => {
    const isMounted = useRef(false);
    const isMounted1 = useRef(false);
    const isMounted2 = useRef(false);
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const { balance_category, date_filter, date_from, date_to } =
        Object.fromEntries([...searchParams]);
    const { user } = useSelector((state) => state.user);
    const { gameOfferings } = useSelector((state) => state.game);

    const { isSuperAdmin, isOperator, isMasterAgent, isAgent } = userRole();

    const { winLossReport, isGetWinLossReportLoading } = useSelector(
        (state) => state.report
    );

    const [selectedGp, setSelectedGp] = useState("");
    const [isDirectPlayer, setIsDirectPlayer] = useState(false);

    const [filterUserAccountNos, setFilterUserAccountNo] = useState({
        operator: "",
        "master-agent": "",
        agent: "",
        player: "",
    });

    const [dataSource, setDataSource] = useState([]);

    const [search, setSearch] = useState("");
    const [sortBy, setSortBy] = useState({
        field: "",
        type: "asc",
    });

    const currentUserType = isSuperAdmin
        ? "operator"
        : isOperator
        ? "master-agent"
        : isMasterAgent
        ? "agent"
        : "player";

    const defaultParams = {
        game_offering: null,
        balance_category: balance_category ?? null,
        date_from: today(),
        date_to: today(),
        user_type: currentUserType,
    };

    const [data, setData] = useState(defaultParams);
    const [userTypeOptions, setUserTypeOptions] = useState([]);
    const [selectedDate, setSelectedDate] = useState(
        date_filter
            ? {
                  ...dateFilterOptions.find(
                      (option) => option?.label === date_filter
                  ),
                  value: [date_from, date_to],
              }
            : dateFilterOptions[0]
    );

    const tableTheadData = useMemo(() => {
        return [
            {
                label: "Account No",
                field: "account_no",
                subItemField: "game_provider_name",
                subItemRender: (item) =>
                    !selectedGp ? (
                        <div className="text-decoration-underline">
                            <span
                                className="tw-cursor-pointer"
                                onClick={() => {
                                    setSelectedGp(item.game_provider_name);
                                    setData({
                                        ...data,
                                        game_provider: item.game_provider_name,
                                    });
                                }}
                            >
                                {item?.game_provider_name}
                            </span>
                        </div>
                    ) : (
                        item.game_provider_name
                    ),
                render: (item) =>
                    filterUserAccountNos?.player === "" ? (
                        <div className="text-decoration-underline text-primary">
                            <span
                                onClick={() => {
                                    setIsDirectPlayer(
                                        !filterUserAccountNos.agent &&
                                            item?.user_type === "player"
                                    );

                                    setFilterUserAccountNo((currentData) => {
                                        return {
                                            ...currentData,
                                            [item?.user_type]: item?.account_no,
                                        };
                                    });
                                    setData((currentData) => ({
                                        ...currentData,
                                        user_type:
                                            item?.user_type === "operator"
                                                ? "master-agent"
                                                : item?.user_type ===
                                                  "master-agent"
                                                ? "agent"
                                                : "player",
                                        accountNo: item.account_no,
                                    }));
                                }}
                            >
                                {item?.account_no}
                            </span>
                        </div>
                    ) : (
                        item?.account_no
                    ),
            },
            {
                label: "Name",
                field: "name",
                subItemField: "name",
                render: (item) =>
                    capitalizeFirstLetter(
                        item.first_name + " " + item.last_name
                    ),
            },
            {
                label: "Loss",
                field: "turnover",
                subItemField: "turnover",
                render: (item) => <AmountFormat amount={item?.turnover} />,
                subItemRender: (item) => (
                    <AmountFormat amount={item?.turnover} />
                ),
            },
            {
                label: "Win",
                field: "win_loss",
                subItemField: "win_loss",
                render: (item) => <AmountFormat amount={item?.win_loss} />,
                subItemRender: (item) => (
                    <AmountFormat amount={item?.win_loss} />
                ),
            },
            {
                label: "Jackpot",
                field: "jackpot",
                subItemField: "jackpot",
                render: (item) => <AmountFormat amount={item?.jackpot} />,
                subItemRender: (item) => (
                    <AmountFormat amount={item?.jackpot} />
                ),
            },
            {
                label: "GGR",
                field: "net_income",
                subItemField: "net_income",
                render: (item) => <AmountFormat amount={item?.net_income} />,
                subItemRender: (item) => (
                    <AmountFormat amount={item?.net_income} />
                ),
            },
        ];
    }, [data, selectedGp, filterUserAccountNos]);

    // useEffect(() => {
    //     if (isMounted.current) {
    //         if (selectedDate?.value && selectedDate?.value.length === 2) {
    //             setData((data) => ({
    //                 ...data,
    //                 date_from: selectedDate.value[0],
    //                 date_to: selectedDate.value[1],
    //             }));
    //         }
    //     } else isMounted.current = true;
    // }, [selectedDate]);

    useEffect(() => {
        if (!isMounted.current) {
            if (selectedDate?.value && selectedDate?.value.length === 2) {
                setData((data) => ({
                    ...data,
                    date_from: selectedDate.value[0],
                    date_to: selectedDate.value[1],
                }));
            }
        } else isMounted.current = true;
    }, [selectedDate.value]);

    useEffect(() => {
        if (isMounted1.current) {
            dispatch(getGameOfferings());
            dispatch(getWinLossReport(data));
        } else isMounted1.current = true;
    }, [dispatch, data]);

    useEffect(() => {
        if (winLossReport && !isGetWinLossReportLoading) {
            setDataSource(winLossReport);
        }
    }, [winLossReport, isGetWinLossReportLoading]);

    useEffect(() => {
        if (winLossReport?.data?.length > 0 && search !== "") {
            let report = { ...winLossReport };
            report.data = report.data.filter(
                (value) =>
                    value.account_no.search(new RegExp(`${search}`, "gi")) !==
                        -1 ||
                    value.first_name.search(new RegExp(`${search}`, "gi")) !==
                        -1 ||
                    value.last_name.search(new RegExp(`${search}`, "gi")) !== -1
            );

            if (report?.totals) {
                report.totals = {
                    turnover: report.data.reduce(
                        (a, b) => a + b?.turnover ?? 0,
                        0
                    ),
                    win_loss: report.data.reduce(
                        (a, b) => a + b?.win_loss ?? 0,
                        0
                    ),
                    jackpot: report.data.reduce(
                        (a, b) => a + b?.jackpot ?? 0,
                        0
                    ),
                    net_income: report.data.reduce(
                        (a, b) => a + b?.net_income ?? 0,
                        0
                    ),
                };
            }

            setDataSource({ ...report });
        } else if (search === "") {
            setDataSource({ ...winLossReport });
        }
    }, [search, winLossReport]);

    useEffect(() => {
        if (isMounted2.current) {
            setData((data) => ({ ...data, ...filter }));
        } else isMounted2.current = true;
    }, [filter]);

    const customBreadcrumb = [
        {
            label: `Operator ${
                filterUserAccountNos.operator &&
                `(${filterUserAccountNos.operator})`
            }`,
            value:
                filterUserAccountNos.operator || data.user_type !== "operator",
            isShow: isSuperAdmin,
            onClick: () => {
                setFilterUserAccountNo({
                    ...filterUserAccountNos,
                    operator: "",
                    "master-agent": "",
                    agent: "",
                    player: "",
                });
                setData((currentData) => ({
                    ...currentData,
                    user_type: "operator",
                    accountNo: "",
                }));
                setIsDirectPlayer(false);
            },
            level: 4,
        },
        {
            label: `Master agents ${
                filterUserAccountNos["master-agent"] &&
                `(${filterUserAccountNos["master-agent"]})`
            }`,
            value:
                filterUserAccountNos["master-agent"] ||
                data.user_type === "agent" ||
                data.user_type === "player",
            isShow:
                filterUserAccountNos.operator ||
                data.user_type === "master-agent" ||
                data.user_type === "agent" ||
                data.user_type === "player",
            onClick: () => {
                setFilterUserAccountNo({
                    ...filterUserAccountNos,
                    "master-agent": "",
                    agent: "",
                    player: "",
                });
                setData((currentData) => ({
                    ...currentData,
                    user_type: "master-agent",
                    accountNo: filterUserAccountNos.operator,
                }));
                setIsDirectPlayer(false);
            },
            level: 3,
        },
        {
            label: (
                <>
                    {(!filterUserAccountNos.player || !isDirectPlayer) && (
                        <span
                            className={`${
                                data.user_type === "player" &&
                                "tw-underline text-primary tw-cursor-pointer"
                            }`}
                            onClick={() => {
                                if (data.user_type === "player") {
                                    setFilterUserAccountNo({
                                        ...filterUserAccountNos,
                                        player: "",
                                    });
                                    setData({
                                        ...data,
                                        user_type: "agent",
                                    });
                                    setIsDirectPlayer(false);
                                }
                            }}
                        >
                            Agents{" "}
                            {filterUserAccountNos.agent &&
                                `(${filterUserAccountNos.agent})`}
                        </span>
                    )}
                    {!filterUserAccountNos.player &&
                        !filterUserAccountNos.agent && <>/</>}
                    {!filterUserAccountNos.agent && (
                        <>
                            <span
                                className={`${
                                    (data.user_type === "agent" ||
                                        (data.user_type === "player" &&
                                            filterUserAccountNos.player)) &&
                                    "tw-underline text-primary tw-cursor-pointer"
                                }`}
                                onClick={() => {
                                    if (
                                        data.user_type === "agent" ||
                                        (data.user_type === "player" &&
                                            filterUserAccountNos.player)
                                    ) {
                                        setFilterUserAccountNo({
                                            ...filterUserAccountNos,
                                            player: "",
                                        });
                                        setData({
                                            ...data,
                                            accountNo:
                                                filterUserAccountNos[
                                                    "master-agent"
                                                ],
                                            user_type: "player",
                                        });
                                        setIsDirectPlayer(true);
                                    }
                                }}
                            >
                                Direct players
                            </span>
                        </>
                    )}
                </>
            ),
            value: filterUserAccountNos["agent"],
            isShow:
                filterUserAccountNos["master-agent"] ||
                data.user_type === "agent" ||
                data.user_type === "player",
            onClick: () => {
                setFilterUserAccountNo({
                    ...filterUserAccountNos,
                    agent: "",
                    player: "",
                });
                setData((currentData) => ({
                    ...currentData,
                    user_type: "agent",
                    accountNo: filterUserAccountNos["master-agent"],
                }));
            },
            level: 2,
        },
        {
            label: (
                <span>
                    {filterUserAccountNos.player && isDirectPlayer
                        ? filterUserAccountNos.player
                        : "Players"}
                </span>
            ),
            value: !isDirectPlayer ? filterUserAccountNos.player : null,
            onClick: () => {
                setFilterUserAccountNo({
                    ...filterUserAccountNos,
                    player: "",
                });
                setData((currentData) => ({
                    ...currentData,
                    user_type: "player",
                    accountNo: filterUserAccountNos.agent,
                }));
            },
            isShow:
                ((filterUserAccountNos.agent || data.user_type === "player") &&
                    !isDirectPlayer) ||
                (isDirectPlayer &&
                    filterUserAccountNos.player &&
                    !filterUserAccountNos.agent),
            level: 1,
        },
        {
            label: (
                <span>
                    {filterUserAccountNos.player &&
                        `${filterUserAccountNos.player}`}
                </span>
            ),
            value: null,
            isShow: !isDirectPlayer && filterUserAccountNos.player,
            level: 0,
        },
    ];

    const userCurrentRoleLevel = isSuperAdmin
        ? 5
        : reportUserTypeOptions.find(
              (value) => value.value === user?.role?.slug
          )?.level;

    const NofilterAccountNos =
        !filterUserAccountNos.operator &&
        !filterUserAccountNos["master-agent"] &&
        !filterUserAccountNos.agent;
    const TableData = (
        <CollapsibleTable
            customComponents={
                <div className="tw-space-y-2">
                    {selectedGp && (
                        <div>
                            <span className="tw-text-gray-500 tw-mr-1">
                                Game provider:
                            </span>
                            <span>
                                {selectedGp}{" "}
                                <i
                                    className="ri-close-line tw-cursor-pointer text-danger"
                                    onClick={() => {
                                        setData((currentData) => ({
                                            ...currentData,
                                            game_provider: "",
                                        }));
                                        setSelectedGp("");
                                    }}
                                />
                            </span>
                        </div>
                    )}
                    <div className="tw-flex tw-flex-row tw-justify-between tw-items-center">
                        <span className="tw-text-[#878A99]">
                            {NofilterAccountNos ? (
                                reportUserTypeOptions.find(
                                    (item) => item.value === data?.user_type
                                ).label
                            ) : (
                                <ol className="breadcrumb m-0">
                                    {customBreadcrumb.map(
                                        (item, index) =>
                                            item.isShow &&
                                            userCurrentRoleLevel >
                                                item.level && (
                                                <li
                                                    key={index}
                                                    className={`breadcrumb-item ${
                                                        item.value &&
                                                        "tw-underline tw-text-primary tw-cursor-pointer"
                                                    }`}
                                                    onClick={() =>
                                                        item.value
                                                            ? item.onClick()
                                                            : {}
                                                    }
                                                >
                                                    {item.label}
                                                </li>
                                            )
                                    )}
                                </ol>
                            )}
                        </span>
                        {dashboard ? (
                            <div className="tw-flex tw-items-center">
                                <Link
                                    to={getPath(
                                        Routes.WIN_LOSS_BY_AGENT_REPORT
                                    )}
                                    className="link-primary tw-underline"
                                >
                                    <Button className="px-0" color="link">
                                        View more{" "}
                                    </Button>
                                </Link>
                                <span className="ri-arrow-right-s-line"></span>
                            </div>
                        ) : (
                            <>
                                {false && (
                                    <Button size="sm" color="primary">
                                        <span className="las la-clipboard-list" />
                                        Generate Report
                                    </Button>
                                )}
                            </>
                        )}
                    </div>
                </div>
            }
            sortBy={sortBy}
            handlerSortBy={setSortBy}
            thead={tableTheadData}
            data={data}
            collection={dataSource}
            isLoading={isGetWinLossReportLoading}
            isCollapsible={true}
            hidePaginate={true}
            subItemKey="game_providers"
            hasTotal={true}
            totalFieldStart="name"
        />
    );
    return dashboard ? (
        TableData
    ) : (
        <React.Fragment>
            <Title title={getPathTitle(Routes.WIN_LOSS_BY_AGENT_REPORT)} />
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb
                        parent={{
                            isParent: true,
                            pageTitle: "REPORTS",
                            pageLink: getPath(Routes.WIN_LOSS_BY_AGENT_REPORT),
                        }}
                    />
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <Row>
                                    <Col xs={12}>
                                        <Card>
                                            <CardHeader className="align-items-center d-flex tw-border-0">
                                                <h4 className="card-title mb-0 flex-grow-1">
                                                    {getPathTitle(
                                                        Routes.WIN_LOSS_BY_AGENT_REPORT
                                                    )}
                                                </h4>
                                            </CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <Col md={6}>
                                                        <InputText
                                                            label="Search"
                                                            placeholder={`Search for operator / master agent / agent / player’s account number or name...`}
                                                            onChange={(e) =>
                                                                setSearch(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                            value={search}
                                                        />
                                                    </Col>
                                                    <Col md={2}>
                                                        <Select
                                                            label="User Type"
                                                            isClearable={false}
                                                            value={
                                                                data.user_type
                                                            }
                                                            options={
                                                                NofilterAccountNos
                                                                    ? reportUserTypeOptions.filter(
                                                                          (
                                                                              item
                                                                          ) =>
                                                                              item.level <=
                                                                              reportUserTypeOptions.find(
                                                                                  (
                                                                                      value
                                                                                  ) =>
                                                                                      value.value ===
                                                                                      currentUserType
                                                                              )
                                                                                  ?.level
                                                                      )
                                                                    : reportUserTypeOptions.filter(
                                                                          (
                                                                              item
                                                                          ) =>
                                                                              item.level <=
                                                                              reportUserTypeOptions.find(
                                                                                  (
                                                                                      value
                                                                                  ) =>
                                                                                      value.value ===
                                                                                      data.user_type
                                                                              )
                                                                                  ?.level
                                                                      )
                                                            }
                                                            onChange={(e) => {
                                                                setData(
                                                                    (data) => ({
                                                                        ...data,
                                                                        user_type:
                                                                            e
                                                                                .target
                                                                                .value,
                                                                    })
                                                                );
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col md={2}>
                                                        <Select
                                                            label="Game Offering"
                                                            isClearable={false}
                                                            value={
                                                                data.game_offering
                                                            }
                                                            options={[
                                                                {
                                                                    label: "All",
                                                                    value: "",
                                                                },
                                                                ...gameOfferings.map(
                                                                    (item) => ({
                                                                        label: item.name,
                                                                        value: item.value,
                                                                    })
                                                                ),
                                                            ]}
                                                            onChange={(e) => {
                                                                setData(
                                                                    (data) => ({
                                                                        ...data,
                                                                        game_offering:
                                                                            e
                                                                                .target
                                                                                .value,
                                                                    })
                                                                );
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col md={2}>
                                                        <Select
                                                            label="Balance Category"
                                                            isClearable={false}
                                                            value={
                                                                data.balance_category
                                                            }
                                                            options={balanceCategoryOptions()}
                                                            onChange={(e) => {
                                                                setData(
                                                                    (data) => ({
                                                                        ...data,
                                                                        balance_category:
                                                                            e
                                                                                .target
                                                                                .value,
                                                                    })
                                                                );
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col
                                                        md={12}
                                                        className="pt-3"
                                                    >
                                                        <div className="w-100 tw-flex tw-gap-1 tw-flex-col lg:tw-flex-row">
                                                            {dateFilterOptions.map(
                                                                (
                                                                    item,
                                                                    index
                                                                ) => (
                                                                    <Button
                                                                        key={
                                                                            index
                                                                        }
                                                                        color={
                                                                            selectedDate.label ===
                                                                            item.label
                                                                                ? "primary"
                                                                                : "light"
                                                                        }
                                                                        onClick={() => {
                                                                            setSelectedDate(
                                                                                item
                                                                            );
                                                                        }}
                                                                    >
                                                                        {
                                                                            item.label
                                                                        }
                                                                    </Button>
                                                                )
                                                            )}
                                                            <div className="tw-w-full lg:tw-w-1/5">
                                                                <FlatPickerDate
                                                                    name="dateFilter"
                                                                    mode="range"
                                                                    placeholder="From Date - To Date"
                                                                    maxDate={
                                                                        null
                                                                    }
                                                                    value={
                                                                        selectedDate.value
                                                                    }
                                                                    onChange={(
                                                                        date
                                                                    ) => {
                                                                        setSelectedDate(
                                                                            (
                                                                                data
                                                                            ) => ({
                                                                                ...data,
                                                                                label: "Custom",
                                                                                value: date.map(
                                                                                    (
                                                                                        value
                                                                                    ) =>
                                                                                        filterDateFormat(
                                                                                            value
                                                                                        )
                                                                                ),
                                                                            })
                                                                        );
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                {/* <Row className="px-3">
                                    <Col>
                                        {!filterUserAccountNos.operator ? (
                                            <h4>Operator</h4>
                                        ) : (
                                            filterUserAccountNos[
                                                "master-agent"
                                            ] && (
                                                <h4 className="tw-flex tw-items-center tw-gap-1">
                                                    <i className="bx bx-chevron-left" />
                                                    <div>
                                                        {
                                                            filterUserAccountNos[
                                                                "master-agent"
                                                            ]
                                                        }{" "}
                                                        Master Agents
                                                    </div>
                                                    <div>
                                                        {
                                                            filterUserAccountNos[
                                                                "agent"
                                                            ]
                                                        }{" "}
                                                        Agents
                                                    </div>
                                                </h4>
                                            )
                                        )}
                                    </Col>
                                </Row> */}
                                <CardBody>{TableData}</CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default WinLossReport;
