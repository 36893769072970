export const SEND_ANNOUNCEMENT = "SEND_ANNOUNCEMENT";
export const SEND_ANNOUNCEMENT_SUCCESS = "SEND_ANNOUNCEMENT_SUCCESS";
export const SEND_ANNOUNCEMENT_ERROR = "SEND_ANNOUNCEMENT_ERROR";

export const GET_MESSAGE = "GET_MESSAGE";
export const GET_MESSAGE_SUCCESS = "GET_MESSAGE_SUCCESS";
export const GET_MESSAGE_ERROR = "GET_MESSAGE_ERROR";

export const UPDATE_MESSAGE_STATUS = "UPDATE_MESSAGE_STATUS";
export const UPDATE_MESSAGE_STATUS_SUCCESS = "UPDATE_MESSAGE_STATUS_SUCCESS";
export const UPDATE_MESSAGE_STATUS_ERROR = "UPDATE_MESSAGE_STATUS_ERROR";

export const GET_MESSAGE_TYPES = "GET_MESSAGE_TYPES";
export const GET_MESSAGE_TYPES_SUCCESS = "GET_MESSAGE_TYPES_SUCCESS";
export const GET_MESSAGE_TYPES_ERROR = "GET_MESSAGE_TYPES_ERROR";

export const CREATE_MESSAGE = "CREATE_MESSAGE";
export const CREATE_MESSAGE_SUCCESS = "CREATE_MESSAGE_SUCCESS";
export const CREATE_MESSAGE_ERROR = "CREATE_MESSAGE_ERROR";

export const UPDATE_MESSAGE_BANNER = "UPDATE_MESSAGE_BANNER";
export const UPDATE_MESSAGE_BANNER_SUCCESS = "UPDATE_MESSAGE_BANNER_SUCCESS";
export const UPDATE_MESSAGE_BANNER_ERROR = "UPDATE_MESSAGE_BANNER_ERROR";

export const UPDATE_MESSAGE_INFORMATION = "UPDATE_MESSAGE_INFORMATION";
export const UPDATE_MESSAGE_INFORMATION_SUCCESS = "UPDATE_MESSAGE_INFORMATION_SUCCESS";
export const UPDATE_MESSAGE_INFORMATION_ERROR = "UPDATE_MESSAGE_INFORMATION_ERROR";

export const GET_NOTIFICATION_TYPE_LIST = "GET_NOTIFICATION_TYPE_LIST";
export const GET_NOTIFICATION_TYPE_LIST_SUCCESS = "GET_NOTIFICATION_TYPE_LIST_SUCCESS";
export const GET_NOTIFICATION_TYPE_LIST_ERROR = "GET_NOTIFICATION_TYPE_LIST_ERROR";

export const UPDATE_NOTIFICATION_TYPE_BANNER = "UPDATE_NOTIFICATION_TYPE_BANNER";
export const UPDATE_NOTIFICATION_TYPE_BANNER_SUCCESS = "UPDATE_NOTIFICATION_TYPE_BANNER_SUCCESS";
export const UPDATE_NOTIFICATION_TYPE_BANNER_ERROR = "UPDATE_NOTIFICATION_TYPE_BANNER_ERROR";

export const GET_USER_NOTIFICATION_LIST = "GET_USER_NOTIFICATION_LIST";
export const GET_USER_NOTIFICATION_LIST_SUCCESS = "GET_USER_NOTIFICATION_LIST_SUCCESS";
export const GET_USER_NOTIFICATION_LIST_ERROR = "GET_USER_NOTIFICATION_LIST_ERROR";