import React from 'react';
import { useSelector as selector } from "react-redux";
import { userTypeOptions } from "@/constants";
import { useLocation } from "react-router-dom";

export function userRole() {
    const { user } = selector((state) => state.user);
    return {
        isSuperAdmin:
            user?.role?.slug === "super-admin",
        isLicenseeAdmin:
            user?.role?.slug === "super-admin" &&
            user?.roleTag === "licensee-admin",
        isAssistantAdmin:
            user?.role?.slug === "super-admin" &&
            user?.roleTag === "assistant-admin",
        isOperator: user?.role?.slug === "operator",
        isAdmin: user?.role?.slug === "admin",
        isMasterAgent: user?.role?.slug === "master-agent",
        isAgent: user?.role?.slug === "agent",
    };
}

export function getRole() {
    const { user } = selector((state) => state.user);
    return user?.role?.slug;
}

export function getSetting(keyName) {
    const { settingList } = selector((state) => state.user);

    let setting = settingList.filter((item) => item.key === keyName);
    return setting.length > 0 ? setting[0].value : null;
}

export function getPermission(module, action = "can_view") {
    const { permissionList } = selector((state) => state.user);
    const { isAdmin, isSuperAdmin } = userRole();

    if (isAdmin) {
        let permission = permissionList.filter((item) => item.slug === module);
        return permission
            ? permission[0]?.permissions[action]
                ? true
                : false
            : false;
    } else if (isSuperAdmin) {
        return true;
    }

    return false;
}

export function roleBaseSettings(module, prefix = null) {
    const { user, settingList } = selector((state) => state.user);

    if (settingList && user) {
        const key = `${
            (prefix ? prefix + "_" : "") + user?.role?.slug
        }_${module}`.replaceAll("-", "_");
        const filteredSettings = settingList.filter((item) => item.key === key);

        if (filteredSettings) {
            return parseInt(filteredSettings[0]?.value) === 1 ? true : false;
        }
    }

    return null;
}

export function useUserTypeOptions(hasAll = false) {
    const { user } = selector((state) => state.user);
    const role = user?.role?.slug;
    const data = userTypeOptions.filter((item) =>
        role === "operator"
            ? ["master-agent", "agent", "player"].includes(item.value)
            : role === "master-agent"
            ? ["agent", "player"].includes(item.value)
            : role === "agent"
            ? ["player"].includes(item.value)
            : true
    );
    
    if (hasAll) {
        return {
            data: [{label: "All", value: null}, ...data]
        }
    }

    return { data };
}

export function useQuery() {
const { search } = useLocation();

return React.useMemo(() => new URLSearchParams(search), [search]);
}
