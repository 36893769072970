import { axiosInstance } from "./index";

export const operatorList = async (data) => {
    return await axiosInstance.get(`/bo/user/get-paginated/${data.key_token}`, {
        params: data,
    });
};

export const operatorProfile = async (accountNo) => {
    return await axiosInstance.get(`bo/user/get-profile/${accountNo}`);
};

export const updateOperator = async (data, keyToken) => {
    return await axiosInstance.put(`bo/user/update-profile/${keyToken}`, data);
};

export const createOperator = async (data) => {
    return await axiosInstance.post(`/bo/user/create`, data);
};

export const updateMerchantSetting = async (data, keyToken) => {
    return await axiosInstance.put(
        `/bo/user-profile-setting/update-merchant/${keyToken}`,
        data
    );
};

export const updateCrossBettingProtection = async (data, keyToken) => {
    return await axiosInstance.put(
        `/bo/user-profile-setting/update-enable-cross-betting-protection/${keyToken}`,
        data
    );
};

export const updateOperatorRoleTag = async (data, keyToken) => {
    return await axiosInstance.put(
        `/bo/user/update-role-tag/${keyToken}`,
        data
    );
};
