import { CounterWidget, ProgressBar } from "@/components/atoms";
import { batchPayoutJobsCounter } from "@/constants/users";
import React from "react";
import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
const Index = ({ isOpen, setIsOpen, data }) => {
    const batchPayoutCounter = batchPayoutJobsCounter(data);

    return (
        <Modal
            id="batchPayoutStatusModal"
            isOpen={isOpen}
            toggle={() => setIsOpen(!isOpen)}
            centered={true}
        >
            <ModalHeader
                toggle={() => setIsOpen(!isOpen)}
                className="tw-flex tw-flex-row tw-w-full"
            >
                <div className="modal-title">Batch Payout Status</div>
            </ModalHeader>
            <ModalBody>
                <Row>
                    {batchPayoutCounter.map((item, key) => (
                        <Col sm={12} md={6} key={key}>
                            <CounterWidget
                                withBorder={true}
                                item={item}
                                icon={item.icon}
                            />
                        </Col>
                    ))}
                </Row>

                <ProgressBar progress={data?.progress} />
            </ModalBody>
        </Modal>
    );
};

export default Index;
