import MaintenancePage from "@/pages/Errors/Maintenance";
import { decryptData } from "@/utils/cryptoJs";
import React, { useEffect, useRef, useState } from "react";
import { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import Route from "./routes";
import { getUserData } from "./store/actions";

import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { registerPlugin } from "react-filepond";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
registerPlugin(
    FilePondPluginImageExifOrientation,
    FilePondPluginImagePreview,
    FilePondPluginFileValidateSize
);

const App = () => {
    const isMounted = useRef(false);
    const dispatch = useDispatch();
    const [qASession, setQaSession] = useState("");
    const { isAuth } = useSelector((state) => state.user);
    const { isUserWithdrawalRequestSuccess } = useSelector(
        (state) => state.transaction
    );

    useEffect(() => {
        if (
            !["/login", "/forgot-password"].includes(window.location.pathname)
        ) {
            dispatch(getUserData());
        }
    }, [dispatch]);

    useEffect(() => {
        if (isMounted.current) {
            dispatch(getUserData());
        } else isMounted.current = true;
    }, [dispatch, isUserWithdrawalRequestSuccess]);

    useEffect(() => {
        if (process.env.REACT_APP_MAINTENANCE === "true") {
            setQaSession(decryptData(localStorage.getItem("QASESSION")));
        } else {
            localStorage.removeItem("QASESSION");
        }
    }, []);

    return (
        <React.Fragment>
            {process.env.REACT_APP_MAINTENANCE === "true" &&
            process.env.REACT_APP_MAINTENANCE_QA_PASSWORD !== qASession ? (
                <MaintenancePage />
            ) : (
                <>
                    <Route isAuth={isAuth} />
                    <Toaster position="top-right" />
                </>
            )}
        </React.Fragment>
    );
};

export default App;
