import {
    Button,
    Checkbox,
    ErrorAlert,
    InputText,
    Title,
} from "@/components/atoms";
import BreadCrumb from "@/components/common/BreadCrumb";
import {
    getPath,
    getPathTitle,
    permissionActionsList,
    roleValidation,
    Routes,
} from "@/constants";
import { createRole, getPermissionData } from "@/store/actions";
import classnames from "classnames";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Accordion,
    AccordionItem,
    Card,
    CardBody,
    CardHeader,
    Col,
    Collapse,
    Container,
    Form,
    FormGroup,
    Label,
    Row,
} from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
const Create = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { permissionList, isLoading, errors } = useSelector(
        (state) => state.admin
    );

    const [permissions, setPermissions] = useState([]);

    const changePermissionHandler = async (key, action, value) => {
        setPermissions({
            ...permissions,
            [key]: {
                ...permissions[key],
                permissions: {
                    ...permissions[key].permissions,
                    [action]: value,
                },
            },
        });
        if (!value) {
            document.getElementById(`checkbox-all-${key}`).checked = false;
        }
    };

    const checkAllRow = (key, isChecked) => {
        let selectedData = permissionList[key];
        setPermissions({
            ...permissions,
            [key]: {
                ...permissions[key],
                permissions: {
                    view: selectedData.having_view ? isChecked : false,
                    create: selectedData.having_create ? isChecked : false,
                    update: selectedData.having_update ? isChecked : false,
                    update_status: selectedData.having_update_status
                        ? isChecked
                        : false,
                    delete: selectedData.having_delete ? isChecked : false,
                },
            },
        });
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: "",
        },
        validationSchema: roleValidation,
        onSubmit: (values) => {
            let data = {
                name: values.name,
                module: permissions,
            };
            dispatch(createRole(data, validation, navigate));
        },
    });

    useEffect(() => {
        dispatch(getPermissionData(setPermissions));
    }, [dispatch, setPermissions]);

    return (
        <React.Fragment>
            <Title title={getPathTitle(Routes.MANAGE_ROLE_CREATE)} />
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb
                        parent={{
                            isParent: false,
                            pageTitle: getPathTitle(Routes.MANAGE_ROLE),
                            pageLink: getPath(Routes.MANAGE_ROLE),
                        }}
                        childs={[
                            {
                                pageTitle: getPathTitle(
                                    Routes.MANAGE_ROLE_CREATE
                                ),
                                pageLink: getPath(Routes.MANAGE_ROLE_CREATE),
                                isActive: true,
                            },
                        ]}
                    />
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardHeader className="align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">
                                        Create Role
                                    </h4>
                                </CardHeader>
                                <CardBody>
                                    <Form
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            validation.handleSubmit();
                                            if (validation?.errors.name) {
                                                document.documentElement.scrollTop = 0;
                                            }
                                            return false;
                                        }}
                                        className="needs-validation"
                                        action="#"
                                    >
                                        <ErrorAlert
                                            errors={errors}
                                            validation={validation}
                                        />
                                        <Row className="mb-3">
                                            <Col md="4">
                                                <FormGroup className="mb-3">
                                                    <InputText
                                                        label="Role Name"
                                                        name="name"
                                                        placeholder="Ex. Staff, Accounting, etc..."
                                                        validation={validation}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row className="mb-2 ">
                                            <Col>
                                                <FormGroup>
                                                    <Label htmlFor="permissions">
                                                        Permissions
                                                    </Label>
                                                    <Accordion
                                                        className="accordion-icon-none"
                                                        id="default-accordion-example"
                                                        open="false"
                                                        toggle={() => {}}
                                                    >
                                                        {permissionList?.map(
                                                            (item, key) => (
                                                                <AccordionItem
                                                                    key={key}
                                                                >
                                                                    <h2
                                                                        className="accordion-header"
                                                                        id={`heading-${key}`}
                                                                    >
                                                                        <button
                                                                            className={classnames(
                                                                                "accordion-button",
                                                                                {
                                                                                    collapsed: false,
                                                                                }
                                                                            )}
                                                                            type="button"
                                                                            style={{
                                                                                cursor: "pointer",
                                                                            }}
                                                                        >
                                                                            {item.name.toUpperCase()}
                                                                        </button>
                                                                    </h2>
                                                                    <Collapse
                                                                        isOpen={
                                                                            true
                                                                        }
                                                                        className="accordion-collapse"
                                                                        id={`collapse-${key}`}
                                                                    >
                                                                        <div className="accordion-body">
                                                                            <Row>
                                                                                {/* <Col
                                                                                    md={
                                                                                        2
                                                                                    }
                                                                                >
                                                                                    <Checkbox
                                                                                        name={`checkBoxAll${key}`}
                                                                                        id={`checkbox-all-${key}`}
                                                                                        label="All"
                                                                                        onChange={(
                                                                                            event
                                                                                        ) =>
                                                                                            checkAllRow(
                                                                                                key,
                                                                                                event
                                                                                                    .target
                                                                                                    .checked
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </Col> */}
                                                                                {permissionActionsList.map(
                                                                                    (
                                                                                        row,
                                                                                        keyAction
                                                                                    ) => {
                                                                                        return (
                                                                                            item[
                                                                                                row
                                                                                                    .permission
                                                                                            ] ===
                                                                                                1 && (
                                                                                                <Col
                                                                                                    md={
                                                                                                        2
                                                                                                    }
                                                                                                    key={
                                                                                                        keyAction
                                                                                                    }
                                                                                                >
                                                                                                    <Checkbox
                                                                                                        key={
                                                                                                            keyAction
                                                                                                        }
                                                                                                        name={`${row.name}${key}`}
                                                                                                        id={`${row.id}-${key}`}
                                                                                                        label={
                                                                                                            row.label
                                                                                                        }
                                                                                                        onChange={(
                                                                                                            event
                                                                                                        ) =>
                                                                                                            changePermissionHandler(
                                                                                                                key,
                                                                                                                row.keyName,
                                                                                                                event
                                                                                                                    .target
                                                                                                                    .checked
                                                                                                            )
                                                                                                        }
                                                                                                        checked={
                                                                                                            permissions[
                                                                                                                key
                                                                                                            ]
                                                                                                                ?.permissions[
                                                                                                                row
                                                                                                                    .keyName
                                                                                                            ] ||
                                                                                                            ""
                                                                                                        }
                                                                                                    />
                                                                                                </Col>
                                                                                            )
                                                                                        );
                                                                                    }
                                                                                )}
                                                                            </Row>
                                                                        </div>
                                                                    </Collapse>
                                                                </AccordionItem>
                                                            )
                                                        )}
                                                    </Accordion>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Button
                                                    color="success"
                                                    type="submit"
                                                    className="float-end"
                                                    text="Submit"
                                                    isLoading={isLoading}
                                                />
                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Create;
