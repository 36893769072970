import {
  Select,
  StatusBadge,
  Table,
  JsonPretty,
  Title,
  AmountFormat,
  DataCollapse,
  TextLink,
  FlatPickerDate,
  CreatableSelect,
} from "@/components/atoms";
import BreadCrumb from "@/components/common/BreadCrumb";
import { Routes, getPath, getPathTitle } from "@/constants";
import {
  getPromotionTransactionList,
  getPromotionCategoryList,
} from "@/store/actions";
import React, { useEffect, useState, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Button,
} from "reactstrap";
import { promotionTransactionStatus } from "@/constants";
import { getUserProfileLink, filterDateFormat, arrayToString } from "@/utils";
import { dateFilterOptions } from "@/constants/users";
import { useFormik } from "formik";

const List = () => {
  const dateFilterRef = useRef();

  const dispatch = useDispatch();
  const { promotionTransactionList, promotionCategoryList, isLoading } =
    useSelector((state) => state.promotion);

  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState({
    field: "",
    type: "asc",
  });
  const [collapse, setCollapse] = useState([]);

  const defaultParams = {
    per_page: 10,
    page: 1,
    search: "",
    sort_by:
      sortBy.field && sortBy.type ? `${sortBy.field} ${sortBy.type}` : "",
    "filter_by[promotion_transactions.status]": "",
    "filter_by[promotion_categories.key_name]": "",
    "date_range[promotion_transactions.created_at][from]":
      dateFilterOptions[0].value[0],
    "date_range[promotion_transactions.created_at][to]":
      dateFilterOptions[0].value[1],
  };

  const [data, setData] = useState(defaultParams);
  const [selectedDate, setSelectedDate] = useState(dateFilterOptions[0]);

  const form = useFormik({
    enableReinitialize: true,
    initialValues: {
      dateFilter: dateFilterOptions[0].value,
      category: "",
      status: "",
      accountNumber: "",
    },
    onSubmit: (values) => {
      setData({
        ...data,
        page: 1,
        "date_range[promotion_transactions.created_at][from]": values.dateFilter
          ? values.dateFilter[0]
          : data["date_range[promotion_transactions.created_at][from]"],
        "date_range[promotion_transactions.created_at][to]": values.dateFilter
          ? values.dateFilter[1]
          : data["date_range[promotion_transactions.created_at][to]"],
        // "filter_by[roles.name]": values?.roleName["value"] ?? null,
        "filter_by[promotion_transactions.status]": values?.category ?? null,
        "filter_by[promotion_categories.key_name]": values?.status ?? null,
        "filter_by[user_profiles.account_no]": values.accountNumber
          ? arrayToString(
              values.accountNumber.map((item) => {
                return item.value;
              })
            )
          : null,
      });
    },
  });

  const tableTheadData = useMemo(() => {
    const handleCollapse = (id) => {
      if (collapse.includes(id)) {
        const selected = collapse.filter((value) => value !== id);
        setCollapse([...selected]);
      } else {
        setCollapse((collapse) => [...collapse, id]);
      }
    };

    return [
      {
        label: "Reference No.",
        field: "reference_no",
        sortable: true,
        render: (item) => (
          <TextLink
            text={item.reference_no}
            path={getPath(Routes.PROMOTION_TRANSACTION_VIEW).replace(
              ":promotionNo",
              item?.reference_no
            )}
          />
        ),
      },
      {
        label: "Player No.",
        field: "account_no",
        sortable: true,
        render: (item) => (
          <TextLink
            text={`${item.account_no}`}
            path={getUserProfileLink(item.account_no, "player")}
          />
        ),
      },
      {
        label: "Promotion No.",
        // field: "reference_no",
        render: (item) => (
          <TextLink
            text={item?.promotions?.promotion_no}
            path={getPath(Routes.PROMOTION_UPDATE).replace(
              ":promotionNo",
              item?.promotions?.promotion_no
            )}
          />
        ),
        sortable: true,
      },
      {
        label: "Category",
        render: (item) => <div>{item.promotions.promotion_category.title}</div>,
        sortable: true,
      },
      {
        label: "Amount",
        field: "amount",
        render: (item) =>
          item.amount === "" || item.amout === null ? (
            "TBA"
          ) : (
            <AmountFormat amount={item.amount} />
          ),

        sortable: true,
      },
      {
        label: "Turnover Multiplier",
        field: "turnover_multiplier",
        render: (item) => (
          <AmountFormat amount={item.turnover_multiplier} hasPrefix={false} />
        ),

        sortable: true,
      },
      {
        label: "Status",
        field: "status",
        render: (item) => (
          <StatusBadge status={item.status} type="promotionTransaction" />
        ),

        sortable: true,
      },
      {
        label: "Remarks",
        field: "remarks",
        sortable: true,
      },
      {
        label: "Requirements",
        field: "promotions",
        sortable: false,
        render: (item) => (
          <>
            {item?.promotion_requirements ? (
              <DataCollapse
                label={collapse.includes(item.key_token) ? "show" : "view"}
                collapseKey={item.key_token}
                data={[]}
                content={
                  <JsonPretty
                    id={item.key_token}
                    data={item?.promotion_requirements ?? []}
                  />
                }
                collapse={collapse}
                handleCollapse={handleCollapse}
              />
            ) : (
              "-"
            )}
          </>
        ),
      },
    ];
  }, [collapse]);

  const handleClear = () => {
    setData(defaultParams);
    setSelectedDate(dateFilterOptions[0]);
    form.resetForm();
    form.submitForm();
  };

  useEffect(() => {
    dispatch(getPromotionCategoryList());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getPromotionTransactionList(data));
  }, [dispatch, data]);

  return (
    <React.Fragment>
      <Title title={getPathTitle(Routes.PROMOTION_TRANSACTION_LIST)} />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            parent={{
              isParent: true,
              pageTitle: getPathTitle(Routes.PROMOTION_TRANSACTION_LIST),
              pageLink: getPath(Routes.PROMOTION_TRANSACTION_LIST),
            }}
          />
          <Row>
            <Col xs={12}>
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    {getPathTitle(Routes.PROMOTION_TRANSACTION_LIST)}
                  </h4>
                </CardHeader>
                <CardBody>
                  <Row className="mb-3">
                    <Col md={4}>
                      <Select
                        label="Category"
                        isClearable={true}
                        value={form.values.category}
                        options={[
                          { label: "All", value: "" },
                          ...((promotionCategoryList ?? []).map((item) => ({
                            label: item.title,
                            value: item.key_token,
                          })) ?? []),
                        ]}
                        onChange={(e) => {
                          form.setFieldValue("category", e.target.value);
                        }}
                      />
                    </Col>
                    <Col md={4}>
                      <Select
                        label="Status"
                        isClearable={true}
                        value={form.values.status}
                        options={[
                          { label: "All", value: "" },
                          ...((promotionTransactionStatus ?? []).map(
                            (item) => ({
                              label: item.label,
                              value: item.value,
                            })
                          ) ?? []),
                        ]}
                        onChange={(e) => {
                          form.setFieldValue("status", e.target.value);
                        }}
                      />
                    </Col>
                    <Col md={4}>
                      <CreatableSelect
                        label="Account No."
                        placeholder="Add Account Number"
                        isClearable
                        value={form.values.accountNumber}
                        isMulti
                        onChange={(options) =>
                          form.setFieldValue("accountNumber", options)
                        }
                      />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md={12} className="pt-2">
                      <div className="w-100 tw-flex tw-gap-1 tw-flex-col lg:tw-flex-row">
                        {dateFilterOptions.map((item, index) => (
                          <Button
                            key={index}
                            color={
                              selectedDate.label === item.label
                                ? "primary"
                                : "light"
                            }
                            onClick={() => {
                              setSelectedDate(item);
                              if (item.value) {
                                form.setFieldValue("dateFilter", item.value);
                              }
                            }}
                          >
                            {item.label}
                          </Button>
                        ))}
                        <div className="tw-w-full lg:tw-w-1/5">
                          <FlatPickerDate
                            ref={dateFilterRef}
                            name="dateFilter"
                            mode="range"
                            placeholder="From Date - To Date"
                            maxDate={null}
                            value={selectedDate.value}
                            onChange={(date) => {
                              if (date.length >= 2) {
                                form.setFieldValue(
                                  "dateFilter",
                                  date.map((value) => filterDateFormat(value))
                                );
                              }
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} className="text-center">
                      <Button
                        color="primary"
                        className="mx-1"
                        onClick={() => handleClear()}
                      >
                        Clear
                      </Button>
                      <Button
                        color="success"
                        className="mx-1"
                        onClick={() => form.handleSubmit()}
                      >
                        Filter
                      </Button>
                    </Col>
                  </Row>
                  <Table
                    search={search}
                    handlerSearch={setSearch}
                    sortBy={sortBy}
                    handlerSortBy={setSortBy}
                    thead={tableTheadData}
                    handlerChangeData={setData}
                    data={data}
                    collection={promotionTransactionList}
                    isLoading={isLoading}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default List;
