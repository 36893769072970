import {
  Button,
  InputReactSelect,
  InputText,
  TextArea,
  FileInputPreview,
  InputSwitch,
} from "@/components/atoms";
import { sendMessageValidation as validationSchema } from "@/constants";
import { createMessage } from "@/store/actions";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Col, Form, FormGroup, Row, Label } from "reactstrap";
import { DisplayText } from "@/components/atoms";

const AddMessageForm = ({ messageTypes, isLoading }) => {
  const dispatch = useDispatch();
  const [messageType, setMessageType] = useState(null);
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: "",
      description: "",
      message_type: "",
      banner: "",
      sms_description: "",
      is_test_mode: true,
      test_name: "",
      test_email: "",
      test_mobile_no: "",

      email_enabled: true,
      sms_enabled: true,
    },
    validationSchema,
    onSubmit: (values) => {
      let payload = Object.assign({}, values);
      if (!payload.banner || values.is_test_mode) {
        delete payload.banner;
      }

      if (!values.is_test_mode) {
        delete payload.test_email;
        delete payload.test_name;
        delete payload.test_mobile_no;
      }

      dispatch(createMessage(payload, result));
    },
  });

  const result = (isSuccess) => {
    if (isSuccess && !validation.values.is_test_mode) {
      setMessageType(null);
      validation.resetForm();
    }
  };

  return (
    <Form
      className="needs-validation"
      onSubmit={(e) => {
        e.preventDefault();
        validation.handleSubmit();
        return false;
      }}
    >
      <Row>
        <Col md="4">
          <DisplayText
            label="Testing Mode?"
            item={
              <InputSwitch
                label={validation.values.is_test_mode ? "Yes" : "No"}
                id={"is-testmode"}
                isChecked={validation.values.is_test_mode}
                color="success"
                onChange={(e) => {
                  validation.setFieldValue(
                    "is_test_mode",
                    !validation.values.is_test_mode
                  );

                  if (validation.values.is_test_mode === false) {
                    validation.setFieldValue("email_enabled", true);
                    validation.setFieldValue("sms_enabled", true);
                  }
                }}
              />
            }
          />
        </Col>

        <Col md="4">
          <DisplayText
            label="Enable E-mail"
            item={
              <InputSwitch
                label={validation.values.email_enabled ? "Yes" : "No"}
                disabled={validation.values.is_test_mode}
                id={"email-disabled"}
                isChecked={validation.values.email_enabled}
                color="success"
                onChange={(e) =>
                  validation.setFieldValue(
                    "email_enabled",
                    !validation.values.email_enabled
                  )
                }
              />
            }
          />
        </Col>
        <Col md="4">
          <DisplayText
            label="Enable SMS"
            item={
              <InputSwitch
                label={validation.values.sms_enabled ? "Yes" : "No"}
                disabled={validation.values.is_test_mode}
                id={"sms-disabled"}
                isChecked={validation.values.sms_enabled}
                color="success"
                onChange={(e) =>
                  validation.setFieldValue(
                    "sms_enabled",
                    !validation.values.sms_enabled
                  )
                }
              />
            }
          />
        </Col>

        {validation.values.is_test_mode && (
          <Col md={12}>
            <hr />
            <Row>
              <Col>
                <FormGroup className="mb-3">
                  <InputText
                    label="Name"
                    name="test_name"
                    placeholder="Name"
                    validation={validation}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="mb-3">
                  <InputText
                    label="Email"
                    name="test_email"
                    placeholder="Email"
                    validation={validation}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup className="mb-3">
                  <InputText
                    label="Mobile No."
                    name="test_mobile_no"
                    placeholder="Mobile no."
                    validation={validation}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Col>
        )}
        {!validation.values.is_test_mode && (
          <Col md="12">
            <hr />
            <Label htmlFor="banner">Banner</Label>
            <p className="text-muted">*Optional</p>
            <FormGroup className="mb-3">
              <FileInputPreview
                name="banner"
                validation={validation}
                onupdatefiles={(file) =>
                  validation.setFieldValue("banner", file?.[0]?.file ?? null)
                }
              />
            </FormGroup>
          </Col>
        )}
        {validation.values.email_enabled && (
          <Col md="12">
            <p className="text-muted">
              This Section serves as message notification and email details:
            </p>
          </Col>
        )}
        <Col md="6">
          <FormGroup className="mb-3">
            <InputText
              label={`Title${
                validation.values.email_enabled ? "/Subject" : ""
              }`}
              name="title"
              placeholder="Title"
              validation={validation}
            />
          </FormGroup>
        </Col>

        <Col md="6">
          <FormGroup className="mb-3">
            <InputReactSelect
              label="Message Type"
              name="message_type"
              validation={validation}
              placeholder="Select Message Type"
              options={messageTypes.map((item) => ({
                label: item.title,
                value: item.key_token,
              }))}
              value={validation.values.message_type ? messageType : ""}
              onChange={(option) => {
                setMessageType(option);
                validation.setFieldValue("message_type", option.value);
              }}
            />
          </FormGroup>
        </Col>
        <Col md="12">
          <Label>Description</Label>
          <TextArea
            rows="10"
            cols="50"
            name="description"
            validation={validation}
          />
        </Col>

        {validation.values.sms_enabled && (
          <Col md="12" className="mt-2">
            <Label>SMS Description</Label>
            <TextArea
              rows="10"
              cols="50"
              name="sms_description"
              maxLength="160"
              validation={validation}
            />
            <p className="text-muted mt-2">Maximum of  160 characters.</p>
          </Col>
        )}
      </Row>
      <div className="border-top py-3 mt-3">
        <Button
          color="success"
          type="submit"
          className="float-end"
          text="Submit"
          isLoading={isLoading}
        />
      </div>
    </Form>
  );
};

export default AddMessageForm;
