export const GET_CASINO_GAME_PROVIDER = "GET_CASINO_GAME_PROVIDER";
export const SET_CASINO_GAME_PROVIDER = "SET_CASINO_GAME_PROVIDER";
export const SET_CASINO_GAME_PROVIDER_ERROR = "SET_CASINO_GAME_PROVIDER_ERROR";

export const GET_CASINO_GAME_LIST = "GET_CASINO_GAME_LIST";
export const SET_CASINO_GAME_LIST = "SET_CASINO_GAME_LIST";
export const SET_CASINO_GAME_LIST_ERROR = "SET_CASINO_GAME_LIST_ERROR";

export const GET_CASINO_GAME_TYPE = "GET_CASINO_GAME_TYPE";
export const SET_CASINO_GAME_TYPE = "SET_CASINO_GAME_TYPE";
export const SET_CASINO_GAME_TYPE_ERROR = "SET_CASINO_GAME_TYPE_ERROR";

export const SYNC_CASINO_GAME_PROVIDER = "SYNC_CASINO_GAME_PROVIDER";
export const SYNC_CASINO_GAME_PROVIDER_SUCCESS =
    "SYNC_CASINO_GAME_PROVIDER_SUCCESS";
export const SYNC_CASINO_GAME_PROVIDER_ERROR =
    "SYNC_CASINO_GAME_PROVIDER_ERROR";

export const UPDATE_GAME_STATUS = "UPDATE_GAME_STATUS";
export const UPDATE_GAME_STATUS_SUCCESS = "UPDATE_GAME_STATUS_SUCCESS";
export const UPDATE_GAME_STATUS_ERROR = "UPDATE_GAME_STATUS_ERROR";

export const GET_FEATURED_GAME_TYPES = "GET_FEATURED_GAME_TYPES";
export const GET_FEATURED_GAME_TYPES_SUCCESS =
    "GET_FEATURED_GAME_TYPES_SUCCESS";
export const GET_FEATURED_GAME_TYPES_FAILED = "GET_FEATURED_GAME_TYPES_FAILED";

export const ADD_FEATURED_GAME = "ADD_FEATURED_GAME";
export const ADD_FEATURED_GAME_SUCCESS = "ADD_FEATURED_GAME_SUCCESS";
export const ADD_FEATURED_GAME_FAILED = "ADD_FEATURED_GAME_FAILED";
export const RESET_FEATURED_GAME_ERROR = "RESET_FEATURED_GAME_ERROR";

export const DELETE_FEATURED_GAME = "DELETE_FEATURED_GAME";
export const DELETE_FEATURED_GAME_SUCCESS = "DELETE_FEATURED_GAME_SUCCES";
export const DELETE_FEATURED_GAME_FAILED = "DELETE_FEATURED_GAME_FAILED";

export const UPDATE_FEATURED_GAME_ORDER = "UPDATE_FEATURED_GAME_ORDER";
export const UPDATE_FEATURED_GAME_ORDER_SUCCESS =
    "UPDATE_FEATURED_GAME_ORDER_SUCCESS";
export const UPDATE_FEATURED_GAME_ORDER_FAILED =
    "UPDATE_FEATURED_GAME_ORDER_FAILED";

export const GET_GAME_OFFERINGS = "GET_GAME_OFFERINGS";
export const GET_GAME_OFFERINGS_SUCCESS = "GET_GAME_OFFERINGS_SUCCESS";
export const GET_GAME_OFFERINGS_FAILED = "GET_GAME_OFFERINGS_FAILED";

export const UPDATE_GAME_PROVIDER_ORDER = "UPDATE_GAME_PROVIDER_ORDER"; 
export const UPDATE_GAME_PROVIDER_ORDER_SUCCESS = "UPDATE_GAME_PROVIDER_ORDER_SUCCESS"; 
export const UPDATE_GAME_PROVIDER_ORDER_ERROR = "UPDATE_GAME_PROVIDER_ORDER_ERROR"; 

export const UPDATE_FEATURED_GAME = "UPDATE_FEATURED_GAME";
export const UPDATE_FEATURED_GAME_SUCCESS = "UPDATE_FEATURED_GAME_SUCCESS";
export const UPDATE_FEATURED_GAME_FAILED = "UPDATE_FEATURED_GAME_FAILED";