import {
    AmountFormat,
    AsyncReactSelect,
    FlatPickerDate,
    CounterWidget,
    Table,
    TextLink,
    Title,
} from "@/components/atoms";
import BreadCrumb from "@/components/common/BreadCrumb";
import { getPath, getPathTitle, Routes } from "@/constants";
import { getOperatorList, getTransactionData } from "@/store/actions";
import { getUserProfileLink, today } from "@/utils";
import { arrayToString } from "@/utils/common";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bettingCreditFundCounter } from "@/constants/users";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row,
} from "reactstrap";

const Index = () => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.user);
    const { list, isLoading } = useSelector((state) => state.transaction);
    const { list: operatorList } = useSelector((state) => state.operator);

    const [search, setSearch] = useState("");
    const [sortBy, setSortBy] = useState({
        field: "",
        type: "asc",
    });

    const [extraData, setExtraData] = useState({
        key_token: null,
        user_type: "operator",
        role_tag: "all",
    });

    const [data, setData] = useState({
        account_no: "",
        per_page: 10,
        page: 1,
        search: "",
        sort_by:
            sortBy.field && sortBy.type ? `${sortBy.field} ${sortBy.type}` : "",
        "date_range[betting_credit_funds.created_at][from]": today(),
        "date_range[betting_credit_funds.created_at][to]": today(),
    });

    const bcfCounter = bettingCreditFundCounter(list);

    const form = useFormik({
        enableReinitialize: true,
        initialValues: {
            dateFilter: "",
            operatorFilter: null,
        },
        onSubmit: (values) => {
            setData({
                ...data,
                "filter_by[account_no]": values.operatorFilter
                    ? arrayToString(values.operatorFilter)
                    : null,
                "date_range[betting_credit_funds.created_at][from]":
                    values.dateFilter
                        ? values.dateFilter[0]
                        : data[
                              "date_range[betting_credit_funds.created_at][from]"
                          ],
                "date_range[betting_credit_funds.created_at][to]":
                    values.dateFilter
                        ? values.dateFilter[1]
                        : data[
                              "date_range[betting_credit_funds.created_at][to]"
                          ],
            });
        },
    });

    const tableTheadData = [
        {
            label: "Transaction No.",
            field: "transaction_no",
            sortable: true,
        },
        {
            label: "Operator",
            field: "operator",
            sortable: false,
            render: (item) => (
                <TextLink
                    text={`${item?.user?.user_profile?.first_name} ${item?.user?.user_profile?.last_name}`}
                    path={getUserProfileLink(
                        item?.user?.user_profile?.account_no,
                        "operator"
                    )}
                />
            ),
        },
        {
            label: "Amount",
            field: "amount",
            sortable: true,
            render: (item) => <AmountFormat amount={item.amount} />,
        },
        {
            label: "Credit",
            field: "credit",
            sortable: true,
            render: (item) => <AmountFormat amount={item.credit} />,
        },
        {
            label: "Transact By",
            field: "transact_by",
            sortable: false,
            render: (item) =>
                `${item?.transact_by?.user_profile?.first_name} ${item?.transact_by?.user_profile?.last_name}`,
        },
    ];

    useEffect(() => {
        if (user) {
            setExtraData((data) => ({
                ...data,
                key_token: user.keyToken,
            }));
        }
    }, [user]);

    useEffect(() => {
        dispatch(getTransactionData(data, "bcf"));
    }, [dispatch, data]);

    return (
        <React.Fragment>
            <Title title={getPathTitle(Routes.BETTING_CREDIT_FUND)} />
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb
                        parent={{
                            isParent: true,
                            pageTitle: getPathTitle(Routes.BETTING_CREDIT_FUND),
                            pageLink: getPath(Routes.BETTING_CREDIT_FUND),
                        }}
                    />
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardHeader className="align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">
                                        {getPathTitle(
                                            Routes.BETTING_CREDIT_FUND
                                        )}
                                    </h4>
                                </CardHeader>
                                <CardBody>
                                    <Row className="mb-3">
                                        <Col md="6">
                                            <AsyncReactSelect
                                                label="Operators"
                                                name="operatorFilter"
                                                isMulti={true}
                                                fetchData={getOperatorList}
                                                data={operatorList}
                                                form={form}
                                                dataField={{
                                                    label: "account_no|name",
                                                    value: "account_no",
                                                }}
                                                sortBy="name asc"
                                                extraData={extraData}
                                            />
                                        </Col>
                                        <Col md="6">
                                            <FlatPickerDate
                                                label="Date"
                                                name="dateFilter"
                                                form={form}
                                                mode="range"
                                                defaultDate={[
                                                    data[
                                                        "date_range[betting_credit_funds.created_at][from]"
                                                    ],
                                                    data[
                                                        "date_range[betting_credit_funds.created_at][to]"
                                                    ],
                                                ]}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} className="text-center">
                                            <Button
                                                color="success"
                                                onClick={() =>
                                                    form.handleSubmit()
                                                }
                                            >
                                                Filter
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        {bcfCounter.map((item, key) => (
                            <Col key={key}>
                                <CounterWidget item={item} icon="₱" />
                            </Col>
                        ))}
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <Table
                                        search={search}
                                        handlerSearch={setSearch}
                                        sortBy={sortBy}
                                        handlerSortBy={setSortBy}
                                        thead={tableTheadData}
                                        handlerChangeData={setData}
                                        data={data}
                                        collection={list}
                                        isLoading={isLoading}
                                        size="sm"
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Index;
