import React from "react";

import { Card, CardBody, CardHeader } from "reactstrap";

import { titleCase } from "@/utils/common";
import { Link, useSearchParams } from "react-router-dom";
import CountUp from "react-countup";

export const Currency = ({
  labelClass,
  valueClass,
  title,
  containerClass,
  value = "0",
  currency = "PHP",
  isLoading = false,
}) => (
  <div className={`${containerClass}`}>
    {title && <div className="mb-0 text-muted">{title}</div>}

    {isLoading ? (
      <h2 className={`tw-animate-pulse fw-medium ${valueClass}`}>Loading...</h2>
    ) : (
      <div className="tw-self-end tw-flex">
        <label className={`tw-self-end ${labelClass}`}>{currency}</label>
        <h2 className={`tw-mx-1 fw-medium ${valueClass}`}>
          <CountUp
            start={0}
            prefix={""}
            suffix={""}
            separator={","}
            end={isLoading ? 0 : value}
            decimals={2}
            duration={4}
          />
        </h2>
      </div>
    )}
  </div>
);

export const Icon = ({ icon, children }) => (
  <div className="tw-flex">
    <div className="tw-mr-2">{icon}</div>
    <div className="tw-self-center ">{children}</div>
  </div>
);

export const CommonCard = ({ children, containerClass, title, icon }) => (
  <Card className={`card-animate tw-border px-2 ${containerClass}`}>
    <Icon icon={icon}>
      <div className="text-uppercase fw-medium mb-0 text-muted tw-line-clamp-2">
        {title}
      </div>
    </Icon>
    {children}
  </Card>
);

export const FilledCard = ({
  containerClass,
  progresColor,
  progressAccent,
  ...props
}) => (
  <Card
    className={`card-animate tw-border tw-ml-none tw-py-2 md:tw-py-5 lg:tw-py-2 tw-h-[95%] ${containerClass}`}
  >
    <CardBody className="tw-px-4 lg:tw-px-5">
      <Icon icon={props?.icon}>
        <div className="text-uppercase fw-medium mb-0 text-muted">
          {props?.label}
        </div>
      </Icon>

      <div className="my-4">
        <Currency
          labelClass="tw-text-sm lg:tw-text-[16px]"
          valueClass="tw-text-2xl lg:tw-text-[28px] tw-leading-[0.9rem]"
          value={props?.value}
          currency={props?.currency}
          isLoading={props?.isLoading}
        />
      </div>

      <hr />

      {Object.keys(props?.breakdown ?? {}).map((key, index) => (
        <div className="tw-mb-9 lg:tw-mb-6" key={index}>
          <div className="tw-flex tw-justify-between tw-mb-1">
            <span className="tw-text-xs fw-medium text-muted">
              {titleCase(key)}
            </span>
            <Currency
              labelClass="tw-text-[0.6rem] tw-mb-0"
              valueClass="tw-text-sm tw-mb-0 tw-leading-[0.7rem]"
              isLoading={props?.isLoading}
              value={props?.breakdown[key]}
              currency={props?.currency}
            />
          </div>
          <div
            className={`progress progress-sm ${progresColor} animated-progress`}
          >
            <div
              className={`progress-bar ${progressAccent}`}
              role="progressbar"
              style={{
                width: `${
                  props?.breakdown[key] <= 0
                    ? 0
                    : (props?.breakdown[key] / props?.value) * 100
                }%`,
              }}
              aria-valuenow={15}
              aria-valuemin={0}
              aria-valuemax={parseFloat(props?.value)}
            />
          </div>
        </div>
      ))}

      <div className="tw-text-right tw-text-sm tw-mt-7">
        <Link to={props?.link}>
          <span className="text-decoration-underline text-info">
            View Details
          </span>
          <i className="ri-arrow-right-s-line align-middle ms-1 lh-1" />
        </Link>
      </div>
    </CardBody>
  </Card>
);

export const TableCard = ({ title, currency, icon }) => (
  <Card>
    <CardHeader>
      <h4 className="card-title mb-0">{title}</h4>
    </CardHeader>
    <CardBody>
      <h5 className="text-uppercase mb-3">Total Balance</h5>
      <Icon icon={icon}>
        <Currency
          value={100000}
          currency={currency}
          labelClass="tw-leading-[1.5rem]"
        />
      </Icon>
      <div className="table-responsive text-center">
        <table className="table table-bordered table-hover align-middle table-nowrap table-sm my-3">
          <thead className="table-light">
            <tr>
              <th>Account No.</th>
              <th>Username</th>
              <th>{currency} Balance</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="fw-medium">PL000282</td>
              <td>player1dec</td>
              <td>{currency} 12,000.00</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="tw-text-right tw-text-sm tw-mx-3 tw-mt-7">
        <Link to="#">
          <span className="text-decoration-underline text-info">
            View Details
          </span>
          <i className="ri-arrow-right-s-line align-middle ms-1 lh-1" />
        </Link>
      </div>
    </CardBody>
  </Card>
);
