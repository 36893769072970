import * as types from "./actionTypes";
import { operatorState, setUserData } from "@/types/states";

const initialState = operatorState;

const operator = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_OPERATOR_LIST:
            state = {
                ...state,
                isLoading: true,
                isUpdateCrossBettingProtectionSuccess: false,
                isUpdateMerchantSettingSuccess: false,
            };
            break;
        case types.SET_OPERATOR_LIST:
            state = {
                ...state,
                isLoading: false,
                list: action.payload.response,
                errors: operatorState.errors,
            };
            break;
        case types.SET_OPERATOR_LIST_ERROR:
            state = {
                ...state,
                isLoading: false,
            };
            break;
        case types.CREATE_OPERATOR:
        case types.UPDATE_OPERATOR_ROLE_TAG:
            state = {
                ...state,
                isLoading: true,
            };
            break;
        case types.CREATE_OPERATOR_SUCCESS:
            state = {
                ...state,
                isLoading: false,
                message: action.payload.message,
                errors: operatorState.errors,
            };
            break;
        case types.CREATE_OPERATOR_ERROR:
            state = {
                ...state,
                isLoading: false,
                errors: action.payload,
            };
            break;
        case types.GET_OPERATOR_PROFILE:
            state = {
                ...state,
                isLoading: true,
            };
            break;
        case types.SET_OPERATOR_PROFILE:
            state = {
                ...state,
                isLoading: false,
                profile: setUserData(action),
                errors: operatorState.errors,
            };
            break;
        case types.SET_OPERATOR_PROFILE_ERROR:
            state = {
                ...state,
                isLoading: false,
            };
            break;
        case types.UPDATE_OPERATOR:
            state = {
                ...state,
                isLoading: true,
            };
            break;
        case types.UPDATE_OPERATOR_SUCCESS:
        case types.UPDATE_OPERATOR_ROLE_TAG_SUCCESS:
            state = {
                ...state,
                isLoading: false,
                profile: setUserData(action),
                errors: operatorState.errors,
            };
            break;
        case types.UPDATE_OPERATOR_EMAIL_SUCCESS:
            state = {
                ...state,
                isLoading: false,
                profile: {
                    ...state.profile,
                    email: action.payload.email,
                },
                errors: operatorState.errors,
            };
            break;
        case types.UPDATE_OPERATOR_ERROR:
            state = {
                ...state,
                isLoading: false,
                errors: action.payload,
            };
            break;
        case types.RESET_OPERATOR_ERRORS:
            state = {
                ...state,
                isLoading: false,
                errors: operatorState.errors,
            };
            break;
        case types.UPDATE_OPERATOR_WALLET_BALANCE:
            state = {
                ...state,
                profile: {
                    ...state.profile,
                    balance: action.payload,
                },
            };
            break;
        case types.GET_OPERATOR_SELECTION_LIST:
            state = {
                ...state,
                isGetOperatorSelectionListLoading: false,
            };
            break;
        case types.GET_OPERATOR_SELECTION_LIST_SUCCESS:
            state = {
                ...state,
                isGetOperatorSelectionListLoading: false,
                operatorSelectionList: action.payload.response,
            };
            break;
        case types.GET_OPERATOR_SELECTION_LIST_FAILED:
            state = {
                ...state,
                isGetOperatorSelectionListLoading: false,
                operatorSelectionList: [],
            };
            break;
        case types.UPDATE_MERCHANT_SETTING:
            state = {
                ...state,
                isUpdateMerchantSettingSuccess: false,
                isUpdateMerchantSettingLoading: true,
            };
            break;
        case types.UPDATE_MERCHANT_SETTING_SUCCESS:
            state = {
                ...state,
                isUpdateMerchantSettingSuccess: true,
                isUpdateMerchantSettingLoading: false,
            };
            break;
        case types.UPDATE_MERCHANT_SETTING_FAILED:
            state = {
                ...state,
                isUpdateMerchantSettingSuccess: false,
                isUpdateMerchantSettingLoading: false,
            };
            break;
        case types.UPDATE_CROSS_BETTING_PROTECTION:
            state = {
                ...state,
                isUpdateCrossBettingProtectionLoading: true,
                isUpdateCrossBettingProtectionSuccess: false,
            };
            break;
        case types.UPDATE_CROSS_BETTING_PROTECTION_SUCCESS:
            state = {
                ...state,
                isUpdateCrossBettingProtectionLoading: false,
                isUpdateCrossBettingProtectionSuccess: true,
            };
            break;
        case types.UPDATE_CROSS_BETTING_PROTECTION_FAILED:
            state = {
                ...state,
                isUpdateCrossBettingProtectionLoading: false,
                isUpdateCrossBettingProtectionSuccess: false,
            };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default operator;
