import { getPath, Routes } from "@/constants";
import { userRole } from "@/hooks";
import * as PAGE from "@/pages";

export const useAllRoutes = () => {
    const role = userRole();
    let authProtectedRoutes = [
        // My Account
        {
            path: getPath(Routes.ACCOUNT_PROFILE),
            component: PAGE.AccountProfile,
            permission: true,
        },
        {
            path: getPath(Routes.ACCOUNT_COMMISSIONS_SETTINGS),
            component: PAGE.AccountCommissionsSettings,
            permission: role?.isAgent || role?.isMasterAgent,
        },
        {
            path: getPath(Routes.ACCOUNT_WALLET),
            component: PAGE.AccountWallet,
            permission: true,
        },
        {
            path: getPath(Routes.ACCOUNT_DIRECT_WITHDRAWAL),
            component: PAGE.AccountDirectWithdrawal,
            permission: role.isSuperAdmin && !role.isLicenseeAdmin,
        },
        {
            path: getPath(Routes.ACCOUNT_COMMISSIONS),
            component: PAGE.AccountCommissions,
            permission: true,
        },
        {
            path: getPath(Routes.ACCOUNT_REQUEST),
            component: PAGE.AccountRequest,
            permission: true,
        },
        {
            path: getPath(Routes.ACCOUNT_CHANGE_PASSWORD),
            component: PAGE.AccountChangePassword,
            permission: true,
        },
        // Dashboard
        {
            path: getPath(Routes.DASHBOARD),
            component: PAGE.Dashboard,
            permission: true,
        },
        {
            path: getPath(Routes.DASHBOARDV1),
            component: PAGE.DashboardV1,
            permission: true,
        },

        // Betting Credit Fund
        {
            path: getPath(Routes.BETTING_CREDIT_FUND),
            component: PAGE.BettingCreditFund,
            permission: true,
        },

        // Operators
        {
            path: getPath(Routes.OPERATORS),
            component: PAGE.OperatorsList,
            permission: role?.isSuperAdmin,
        },
        {
            path: getPath(Routes.OPERATOR_PROFILE),
            component: PAGE.OperatorProfile,
            permission: role?.isSuperAdmin,
        },
        {
            path: getPath(Routes.OPERATOR_CREATE),
            component: PAGE.OperatorCreate,
            permission: role?.isSuperAdmin,
        },
        {
            path: getPath(Routes.OPERATOR_DASHBOARD),
            component: PAGE.OperatorDashboard,
            permission: role?.isSuperAdmin,
        },
        {
            path: getPath(Routes.OPERATOR_DOWNLINES),
            component: PAGE.OperatorDownlines,
            permission: role?.isSuperAdmin,
        },
        {
            path: getPath(Routes.OPERATOR_WALLET),
            component: PAGE.OperatorWallet,
            permission: role?.isSuperAdmin,
        },
        {
            path: getPath(Routes.OPERATOR_REQUEST),
            component: PAGE.OperatorRequest,
            permission: role?.isSuperAdmin,
        },
        {
            path: getPath(Routes.OPERATOR_PAYMENT_CREDENTIALS),
            component: PAGE.OperatorPaymentCredentials,
            permission: role?.isSuperAdmin,
        },
        {
            path: getPath(Routes.OPERATOR_SETTINGS),
            component: PAGE.OperatorSettings,
            permission: role?.isSuperAdmin,
        },

        // Community Managers
        {
            path: getPath(Routes.COMMUNITY_MANAGERS),
            component: PAGE.CommunityManagersList,
            permission: role?.isSuperAdmin,
        },
        {
            path: getPath(Routes.COMMUNITY_MANAGER_PROFILE),
            component: PAGE.CommunityManagerProfile,
            permission: role?.isSuperAdmin,
        },
        {
            path: getPath(Routes.COMMUNITY_MANAGER_CREATE),
            component: PAGE.CommunityManagerCreate,
            permission: role?.isSuperAdmin,
        },
        {
            path: getPath(Routes.COMMUNITY_MANAGER_DASHBOARD),
            component: PAGE.CommunityManagerDashboard,
            permission: role?.isSuperAdmin,
        },
        {
            path: getPath(Routes.COMMUNITY_MANAGER_COMMISSIONS_SETTINGS),
            component: PAGE.CommunityManagerCommissionsSettings,
            permission: true,
        },
        {
            path: getPath(Routes.COMMUNITY_MANAGER_DOWNLINES),
            component: PAGE.CommunityManagerDownlines,
            permission: role?.isSuperAdmin,
        },
        {
            path: getPath(Routes.COMMUNITY_MANAGER_WALLET),
            component: PAGE.CommunityManagerWallet,
            permission: role?.isSuperAdmin,
        },
        {
            path: getPath(Routes.COMMUNITY_MANAGER_REQUEST),
            component: PAGE.CommunityManagerRequest,
            permission: role?.isSuperAdmin,
        },
        {
            path: getPath(Routes.COMMUNITY_MANAGER_PAYMENT_CREDENTIALS),
            component: PAGE.CommunityManagerPaymentCredentials,
            permission: role?.isSuperAdmin,
        },
        {
            path: getPath(Routes.COMMUNITY_MANAGER_COMMISSIONS_SETTINGS),
            component: PAGE.CommunityManagerSettings,
            permission: role?.isSuperAdmin,
        },
        // Super Agents
        {
            path: getPath(Routes.SUPER_AGENTS),
            component: PAGE.SuperAgentsList,
            permission: true,
        },
        {
            path: getPath(Routes.SUPER_AGENT_PROFILE),
            component: PAGE.SuperAgentProfile,
            permission: true,
        },
        {
            path: getPath(Routes.SUPER_AGENT_CREATE),
            component: PAGE.SuperAgentCreate,
            permission: true,
        },
        {
            path: getPath(Routes.SUPER_AGENT_DOWNLINES),
            component: PAGE.SuperAgentDownlines,
            permission: true,
        },
        {
            path: getPath(Routes.SUPER_AGENT_COMMISSIONS_SETTINGS),
            component: PAGE.SuperAgentCommissionsSettings,
            permission: true,
        },
        {
            path: getPath(Routes.SUPER_AGENT_WALLET),
            component: PAGE.SuperAgentWallet,
            permission: true,
        },
        {
            path: getPath(Routes.SUPER_AGENT_REQUEST),
            component: PAGE.SuperAgentRequest,
            permission: true,
        },
        {
            path: getPath(Routes.SUPER_AGENT_COMMISSIONS),
            component: PAGE.SuperAgentCommissions,
            permission: true,
        },

        // Agents
        {
            path: getPath(Routes.AGENTS),
            component: PAGE.AgentsList,
            permission: true,
        },
        {
            path: getPath(Routes.AGENT_CREATE),
            component: PAGE.AgentCreate,
            permission: true,
        },
        {
            path: getPath(Routes.AGENT_PROFILE),
            component: PAGE.AgentProfile,
            permission: true,
        },
        {
            path: getPath(Routes.AGENT_DOWNLINES),
            component: PAGE.AgentDownlines,
            permission: true,
        },
        {
            path: getPath(Routes.AGENT_COMMISSIONS_SETTINGS),
            component: PAGE.AgentCommissionsSettings,
            permission: true,
        },
        {
            path: getPath(Routes.AGENT_WALLET),
            component: PAGE.AgentWallet,
            permission: true,
        },
        {
            path: getPath(Routes.AGENT_REQUEST),
            component: PAGE.AgentRequest,
            permission: true,
        },
        {
            path: getPath(Routes.AGENT_COMMISSIONS),
            component: PAGE.AgentCommissions,
            permission: true,
        },

        // Affiliates
        {
            path: getPath(Routes.AFFILIATES),
            component: PAGE.AffiliatesList,
            permission: true,
        },
        {
            path: getPath(Routes.AFFILIATE_CREATE),
            component: PAGE.AffiliateCreate,
            permission: true,
        },
        {
            path: getPath(Routes.AFFILIATE_PROFILE),
            component: PAGE.AffiliateProfile,
            permission: true,
        },
        {
            path: getPath(Routes.AFFILIATE_DOWNLINES),
            component: PAGE.AffiliateDownlines,
            permission: true,
        },
        {
            path: getPath(Routes.AFFILIATE_COMMISSIONS_SETTINGS),
            component: PAGE.AffiliateCommissionsSettings,
            permission: true,
        },
        {
            path: getPath(Routes.AFFILIATE_WALLET),
            component: PAGE.AffiliateWallet,
            permission: true,
        },
        {
            path: getPath(Routes.AFFILIATE_REQUEST),
            component: PAGE.AffiliateRequest,
            permission: true,
        },
        {
            path: getPath(Routes.AFFILIATE_COMMISSIONS),
            component: PAGE.AffiliateCommissions,
            permission: true,
        },
        // Affiliates Request List
        {
            path: getPath(Routes.AFFILIATES_REQUEST_LIST),
            component: PAGE.AffiliateRequestList,
            permission: true,
        },

        // Players
        {
            path: getPath(Routes.PLAYERS),
            component: PAGE.PlayersList,
            permission: true,
        },
        {
            path: getPath(Routes.PLAYER_PROFILE),
            component: PAGE.PlayerProfile,
            permission: true,
        },
        {
            path: getPath(Routes.PLAYER_CREATE),
            component: PAGE.PlayerCreate,
            permission: true,
        },
        {
            path: getPath(Routes.PLAYER_WALLET),
            component: PAGE.PlayerWallet,
            permission: true,
        },
        {
            path: getPath(Routes.PLAYER_REQUEST),
            component: PAGE.PlayerRequest,
            permission: true,
        },
        {
            path: getPath(Routes.PLAYER_BET_HISTORY),
            component: PAGE.PlayerBetHistory,
            permission: true,
        },
        {
            path: getPath(Routes.PLAYER_COMMISSIONS),
            component: PAGE.PlayerCommissions,
            permission: true,
        },

        // Activity Logs
        {
            path: getPath(Routes.ACTIVITY_LOGS),
            component: PAGE.ActivityLogs,
            permission: true,
        },

        // User Management
        {
            path: getPath(Routes.MANAGE_USER),
            component: PAGE.ManageUser,
            permission: role?.isSuperAdmin || role?.isOperator,
        },
        {
            path: getPath(Routes.MANAGE_USER_CREATE),
            component: PAGE.ManageUserCreate,
            permission: role?.isSuperAdmin || role?.isOperator,
        },
        {
            path: getPath(Routes.MANAGE_USER_PROFILE),
            component: PAGE.ManageUserProfile,
            permission: role?.isSuperAdmin || role?.isOperator,
        },
        {
            path: getPath(Routes.MANAGE_ROLE),
            component: PAGE.ManageRole,
            permission: role?.isSuperAdmin || role?.isOperator,
        },
        {
            path: getPath(Routes.MANAGE_ROLE_CREATE),
            component: PAGE.ManageRoleCreate,
            permission: role?.isSuperAdmin || role?.isOperator,
        },
        {
            path: getPath(Routes.MANAGE_ROLE_UPDATE),
            component: PAGE.ManageRoleUpdate,
            permission: role?.isSuperAdmin || role?.isOperator,
        },

        // Reports
        {
            path: getPath(Routes.GENERATED_REPORT),
            component: PAGE.GeneratedReport,
            permission: true,
        },
        {
            path: getPath(Routes.DEPOSIT_REQUEST_REPORTS),
            component: PAGE.DepositRequestReports,
            permission: true,
        },
        {
            path: getPath(Routes.WITHDRAWAL_REQUEST_REPORTS),
            component: PAGE.WithdrawalRequestReports,
            permission: true,
        },
        {
            path: getPath(Routes.TRANSACTION_REPORTS),
            component: PAGE.TransactionReports,
            permission: true,
        },
        {
            path: getPath(Routes.WIN_LOSS_BY_AGENT_REPORT),
            component: PAGE.WinLossByAgentReport,
            permission: true,
        },
        {
            path: getPath(Routes.WIN_LOSS_BY_PRODUCT_REPORT),
            component: PAGE.WinLossByProductReport,
            permission: true,
        },

        // Transaction Listing
        {
            path: getPath(Routes.EGAMES_TRANSACTION),
            component: PAGE.EgamesTransactions,
            permission: true,
        },
        {
            path: getPath(Routes.LIVE_CASINO_TRANSACTION),
            component: PAGE.LiveCasinoTransactions,
            permission: true,
        },
        {
            path: getPath(Routes.SPORTS_BETTING_TRANSACTION),
            component: PAGE.SportsBettingTransactions,
            permission: true,
        },
        {
            path: getPath(Routes.ESPORTS_TRANSACTION),
            component: PAGE.EsportsTransactions,
            permission: true,
        },
        {
            path: getPath(Routes.LOTTO_TRANSACTION),
            component: PAGE.LottoTransactions,
            permission: process.env.REACT_APP_LOTTO_ENABLED === "true",
        },

        {
            path: getPath(Routes.BET_TRANSACTION_VIEW),
            component: PAGE.BetTransactionView,
            permission: true,
        },
        {
            path: getPath(Routes.COMMISSIONS_AGENTS),
            component: PAGE.CommissionsAgents,
            permission: true,
        },
        {
            path: getPath(Routes.COMMISSIONS_PLAYERS),
            component: PAGE.CommissionsPlayers,
            permission: true,
        },
        //Tip Transaction
        {
            path: getPath(Routes.TIP_TRANSACTION),
            component: PAGE.TipTransactionList,
            permission: role?.isSuperAdmin,
        },
        {
            path: getPath(Routes.TIP_TRANSACTION_VIEW),
            component: PAGE.TipTransactionView,
            permission: role?.isSuperAdmin,
        },
        //User Ip logs
        {
            path: getPath(Routes.LOGIN_IPS),
            exact: true,
            component: PAGE.UserIPLogsLoginIps,
            permission: role?.isSuperAdmin,
        },
        {
            path: getPath(Routes.MULTIPLE_IP_DETECTED),
            exact: true,
            component: PAGE.MultipleIpDetected,
            permission: role?.isSuperAdmin,
        },

        // Payment Settings
        {
            path: getPath(Routes.PAYMENT_SETTINGS_GENERAL),
            exact: true,
            component: PAGE.PaymentSettingsGeneral,
            permission: role?.isSuperAdmin,
        },
        {
            path: getPath(Routes.PAYMENT_SETTINGS_H2PAY),
            exact: true,
            component: PAGE.PaymentSettingsH2Pay,
            permission: role?.isSuperAdmin,
        },
        {
            path: getPath(Routes.PAYMENT_SETTINGS_MONETIX),
            exact: true,
            component: PAGE.PaymentSettingsMonetix,
            permission: role?.isSuperAdmin,
        },
        {
            path: getPath(Routes.PAYMENT_SETTINGS_SWIFTPAY),
            exact: true,
            component: PAGE.PaymentSettingsSwiftPay,
            permission: role?.isSuperAdmin,
        },
        {
            path: getPath(Routes.PAYMENT_SETTINGS_PAYMENT_PROVIDERS),
            exact: true,
            component: PAGE.PaymentSettingsPaymentProviders,
            permission: role?.isSuperAdmin,
        },
        {
            path: getPath(Routes.PAYMENT_SETTINGS_AK_PAY),
            exact: true,
            component: PAGE.PaymentSettingsAkPay,
            permission: role?.isSuperAdmin,
        },
        {
            path: getPath(Routes.PAYMENT_SETTINGS_COINSPAID),
            exact: true,
            component: PAGE.PaymentSettingsCoinsPaid,
            permission: role?.isSuperAdmin,
        },
        
        // Settings
        {
            path: getPath(Routes.SETTINGS),
            component: PAGE.Settings,
            permission: role?.isSuperAdmin,
        },
        {
            path: getPath(Routes.COUNTRIES),
            exact: true,
            component: PAGE.Countries,
            permission: role?.isSuperAdmin,
        },
        // Kyc
        {
            path: getPath(Routes.KYC),
            component: PAGE.Kyc,
            permission: true,
        },
        // Deposit Request
        {
            path: getPath(Routes.DEPOSIT_REQUEST),
            component: PAGE.DepositRequestList,
            permission: true,
        },
        {
            path: getPath(Routes.DEPOSIT_REQUEST_VIEW),
            component: PAGE.DepositRequestView,
            permission: true,
        },
        // Withdrawal Request
        {
            path: getPath(Routes.WITHDRAWAL_REQUEST),
            component: PAGE.WithdrawalRequestList,
            permission: true,
        },
        {
            path: getPath(Routes.WITHDRAWAL_REQUEST_VIEW),
            component: PAGE.WithdrawalRequestView,
            permission: true,
        },
        // For Release
        {
            path: getPath(Routes.FOR_RELEASE),
            component: PAGE.ForReleaseList,
            permission: true,
        },
        {
            path: getPath(Routes.FOR_RELEASE_VIEW),
            component: PAGE.ForReleaseView,
            permission: true,
        },
        // For Payout Release
        {
            path: getPath(Routes.FOR_PAYOUT_RELEASE),
            component: PAGE.ForPayoutReleaseList,
            permission: true,
        },
        {
            path: getPath(Routes.FOR_PAYOUT_RELEASE_VIEW),
            component: PAGE.ForPayoutReleaseView,
            permission: true,
        },
        // Transactions
        {
            path: getPath(Routes.TRANSACTIONS),
            component: PAGE.TransactionList,
            permission: true,
        },
        {
            path: getPath(Routes.TRANSACTION_VIEW),
            component: PAGE.TransactionView,
            permission: true,
        },
        // Casino Games
        {
            path: getPath(Routes.CASINO_GAME_PROVIDER),
            component: PAGE.CasinoGameProvider,
            permission: role?.isSuperAdmin,
        },
        {
            path: getPath(Routes.CASINO_GAME_TYPE),
            component: PAGE.CasinoGameType,
            permission: role?.isSuperAdmin,
        },
        {
            path: getPath(Routes.CASINO_GAME_LIST),
            component: PAGE.CasinoGameList,
            permission: role?.isSuperAdmin,
        },
        {
            path: "/",
            exact: true,
            component: PAGE.Dashboard,
            permission: true,
        },
        // Promotions
        {
            path: getPath(Routes.PROMOTION_LIST),
            component: PAGE.PromotionList,
            permission: role?.isSuperAdmin,
        },
        {
            path: getPath(Routes.PROMOTION_CATEGORIES),
            component: PAGE.PromotionCategories,
            permission: role?.isSuperAdmin,
        },
        {
            path: getPath(Routes.PROMOTION_CREATE),
            component: PAGE.PromotionCreateOrUpdate,
            permission: role?.isSuperAdmin,
        },
        {
            path: getPath(Routes.PROMOTION_UPDATE),
            component: PAGE.PromotionUpdate,
            permission: role?.isSuperAdmin,
        },
        {
            path: getPath(Routes.PROMOTION_TRANSACTION_LIST),
            component: PAGE.PromotionTransactionList,
            permission: role?.isSuperAdmin,
        },
        {
            path: getPath(Routes.PROMOTION_TRANSACTION_VIEW),
            component: PAGE.PromotionTransactionView,
            permission: role?.isSuperAdmin,
        },
        //Hot Matches
        {
            path: getPath(Routes.HOT_MATCHES_SETTINGS_SPORT_TYPE),
            component: PAGE.SportTypeList,
            permission: role?.isSuperAdmin,
        },
        {
            path: getPath(Routes.HOT_MATCHES_SETTINGS_LEAGUE_TYPE),
            component: PAGE.LeagueTypeList,
            permission: role?.isSuperAdmin,
        },
        {
            path: getPath(Routes.HOT_MATCHES_SETTINGS_GAME_TYPE),
            component: PAGE.GameTypeList,
            permission: role?.isSuperAdmin,
        },
        {
            path: getPath(Routes.HOT_MATCHES_SETTINGS_TEAM),
            component: PAGE.TeamList,
            permission: role?.isSuperAdmin,
        },
        {
            path: getPath(Routes.HOT_MATCHES_SETTINGS_HOT_MATCHES),
            component: PAGE.HotMatchesList,
            permission: role?.isSuperAdmin,
        },

        //Announcement
        {
            path: getPath(Routes.SEND_ANNOUNCEMENT),
            component: PAGE.SendAnnouncement,
            permission: role?.isSuperAdmin,
        },
        {
            path: getPath(Routes.MESSAGE_LIST),
            component: PAGE.MessageList,
            permission: role?.isSuperAdmin,
        },
        {
            path: getPath(Routes.MESSAGE_CREATE_OR_UPDATE),
            component: PAGE.MessageCreateOrUpdate,
            permission: role?.isSuperAdmin,
        },
        {
            path: getPath(Routes.NOTIFICATION_TYPE_LIST),
            component: PAGE.NotificationTypeList,
            permission: role?.isSuperAdmin,
        },
        {
            path: getPath(Routes.NOTIFICATION_LIST),
            component: PAGE.NotificationList,
            permission: role?.isSuperAdmin,
        },

        //Lotto
        {
            path: getPath(Routes.DRAW_SCHEDULE_LIST),
            component: PAGE.DrawScheduleList,
            permission:
                process.env.REACT_APP_LOTTO_ENABLED === "true" &&
                role?.isSuperAdmin,
        },
    ];

    const publicRoutes = [
        // Authentication
        { path: getPath(Routes.LOGIN), component: PAGE.Login },
        {
            path: getPath(Routes.FORGOT_PASSWORD),
            component: PAGE.ForgotPassword,
        },
        { path: getPath(Routes.RESET_PASSWORD), component: PAGE.ResetPassword },
        { path: getPath(Routes.ERROR_NOT_FOUND), component: PAGE.Error404 },
    ];

    return {
        authProtectedRoutes,
        publicRoutes,
    };
};
