export const GET_DASHBOARD_ANALYTICS_MAIN_TOTAL =
    "GET_DASHBOARD_ANALYTICS_MAIN_TOTAL";
export const GET_DASHBOARD_ANALYTICS_MAIN_TOTAL_SUCCESS =
    "GET_DASHBOARD_ANALYTICS_MAIN_TOTAL_SUCCESS";
export const GET_DASHBOARD_ANALYTICS_MAIN_TOTAL_FAILED =
    "GET_DASHBOARD_ANALYTICS_MAIN_TOTAL_FAILED";

export const GET_DASHBOARD_ANALYTICS_OVERALL_TOTAL =
    "GET_DASHBOARD_ANALYTICS_OVERALL_TOTAL";
export const GET_DASHBOARD_ANALYTICS_OVERALL_TOTAL_SUCCESS =
    "GET_DASHBOARD_ANALYTICS_OVERALL_TOTAL_SUCCESS";
export const GET_DASHBOARD_ANALYTICS_OVERALL_TOTAL_FAILED =
    "GET_DASHBOARD_ANALYTICS_OVERALL_TOTAL_FAILED";

export const GET_DAILY_TOTAL_GGR_SUMMARY = "GET_DAILY_TOTAL_GGR_SUMMARY";
export const GET_DAILY_TOTAL_GGR_SUMMARY_SUCCESS =
    "GET_DAILY_TOTAL_GGR_SUMMARY_SUCCESS";
export const GET_DAILY_TOTAL_GGR_SUMMARY_FAILED =
    "GET_DAILY_TOTAL_GGR_SUMMARY_FAILED";

export const GET_DAILY_TOTAL_DEPOSIT_AND_WITHDRAWAL_SUMMARY =
    "GET_DAILY_TOTAL_DEPOSIT_AND_WITHDRAWAL_SUMMARY";
export const GET_DAILY_TOTAL_DEPOSIT_AND_WITHDRAWAL_SUMMARY_SUCCESS =
    "GET_DAILY_TOTAL_DEPOSIT_AND_WITHDRAWAL_SUMMARY_SUCCESS";
export const GET_DAILY_TOTAL_DEPOSIT_AND_WITHDRAWAL_SUMMARY_FAILED =
    "GET_DAILY_TOTAL_DEPOSIT_AND_WITHDRAWAL_SUMMARY_FAILED";

export const GET_MONTHLY_TOTAL_PLAYER_SUMMARY =
    "GET_MONTHLY_TOTAL_PLAYER_SUMMARY";
export const GET_MONTHLY_TOTAL_PLAYER_SUMMARY_SUCCESS =
    "GET_MONTHLY_TOTAL_PLAYER_SUMMARY_SUCCESS";
export const GET_MONTHLY_TOTAL_PLAYER_SUMMARY_FAILED =
    "GET_MONTHLY_TOTAL_PLAYER_SUMMARY_FAILED";
