import React from "react";
import { Input, Label } from "reactstrap";

const InputSwitch = ({ label, id, isChecked, color = "primary", ...rest }) => {
    return (
        <>
            <div className={`form-check form-switch form-switch-${color}`}>
                <Input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id={id}
                    checked={isChecked}
                    {...rest}
                />{" "}
                <Label className="form-check-label" for={id}>
                    {label}
                </Label>
            </div>
        </>
    );
};

export default InputSwitch;
