import { axiosInstance } from "./index";
import axios from "axios";

export const getUserData = async () => {
    return await axiosInstance.get(`/auth/me`);
};

export const getActivityLog = async (data) => {
    return await axiosInstance.get(`/bo/activity-log/get-paginated`, {
        params: data,
    });
};

export const userForgotPassword = async (data) => {
    return await axiosInstance.put(`/forgot-password`, data);
};

export const userResetPassword = async (data, token) => {
    return await axiosInstance.put(`/forgot-password/reset/${token}`, data);
};

export const userUpdateStatus = async (data, keyToken) => {
    return await axiosInstance.put(`/bo/user/update-status/${keyToken}`, data);
};

export const updateReferralCode = async (data) => {
    return await axiosInstance.put(`/bo/user/update-referral-code`, data);
};

export const userResetPasswordValidateToken = async (data, token) => {
    return await axiosInstance.get(`/forgot-password/validate-token/${token}`, {
        params: data,
    });
};

export const loginUser = async (data) => {
    return await axiosInstance.post(`/auth/login`, data);
};

export const userChangePassword = async (data) => {
    return await axiosInstance.put(`/auth/update-password`, data);
};

export const updateUserProfile = async (data, keyToken) => {
    return await axiosInstance.put(`bo/user/update-profile/${keyToken}`, data);
};

export const logoutUser = async () => {
    return await axiosInstance.get(`/auth/logout`);
};

export const getCommissions = async (params) => {
    if (params.userCommisionType === "players") {
        return await axiosInstance.get(`/bo/commission-player/get-paginated`, {
            params,
        });
    }
    return await axiosInstance.get(`/bo/commission/get-paginated`, {
        params,
    });
};

export const getRegions = async () => {
    return await axiosInstance.get(`/philippine-address/get-all/region`);
};

export const getProvinces = async (regionKeyToken) => {
    let params = { region_key_token: regionKeyToken };
    return await axiosInstance.get(`/philippine-address/get-all/province`, {
        params,
    });
};

export const getCities = async (provinceKeyToken) => {
    let params = { province_key_token: provinceKeyToken };
    return await axiosInstance.get(`/philippine-address/get-all/city`, {
        params,
    });
};

export const getBarangays = async (cityKeyToken) => {
    let params = { city_key_token: cityKeyToken };
    return await axiosInstance.get(`/philippine-address/get-all/barangay`, {
        params,
    });
};

export const getSettingsData = async () => {
    return await axiosInstance.get(`/bo/system-setting/get-all`);
};

export const updateUserEmail = async (data, keyToken) => {
    return await axiosInstance.put(`bo/user/update-email/${keyToken}`, data);
};

export const updateUserMobileNo = async (data, keyToken) => {
    return await axiosInstance.put(
        `bo/user/update-mobile-no/${keyToken}`,
        data
    );
};

export const updateUserUsername = async (data) => {
    return await axiosInstance.post(`auth/update-username`, data);
};

export const getUserCountData = async (data) => {
    return await axiosInstance.get(`bo/user/total-by-status`, {
        params: data,
    });
};

export const getCountries = async () => {
    return await axiosInstance.get(`country/get-all`);
};

export const getInternetProtocol = async () => {
    return await axios.get(`https://ifconfig.me/ip`);
};

export const updateReferrer = async (data, keyToken) => {
    return await axiosInstance.put(`/bo/user/update-upline/${keyToken}`, data);
};

export const applyDownlinesCommissionTransferSchedule = async (
    data,
    keyToken
) => {
    return await axiosInstance.put(
        `/bo/user-profile-setting/apply-to-downlines-commission-transfer-schedule/${data.balanceType}/${keyToken}`,
        {
            value: data.value,
        }
    );
};

export const disableWithdrawalFeeMerchant = async (data, keyToken) => {
    return await axiosInstance.put(
        `/bo/user-profile-setting/disable-withdrawal-fee-merchant/${keyToken}`,
        {
            value: data.value,
        }
    );
};

export const getOperatorsList = async () =>
    await axiosInstance.get(`/bo/user/filter/operator`);
