import * as types from "./actionTypes";

export const getUserData = () => ({
    type: types.GET_USER_DATA,
});

export const setUserData = (payload) => ({
    type: types.SET_USER_DATA,
    payload,
});

export const setUserDataError = () => ({
    type: types.SET_USER_DATA_ERROR,
});

export const getActivityLog = (payload) => ({
    type: types.GET_ACTIVITY_LOG,
    payload,
});

export const setActivityLog = (payload) => ({
    type: types.SET_ACTIVITY_LOG,
    payload,
});

export const setActivityLogError = (payload) => ({
    type: types.SET_ACTIVITY_LOG_ERROR,
    payload,
});

export const getUserCountData = (payload) => ({
    type: types.GET_USER_COUNT_DATA,
    payload,
});

export const setUserCountData = (payload) => ({
    type: types.SET_USER_COUNT_DATA,
    payload,
});

export const setUserCountDataError = () => ({
    type: types.SET_USER_COUNT_DATA_ERROR,
});

export const loginUser = (payload, navigate) => ({
    type: types.LOGIN_USER,
    payload,
    navigate,
});

export const loginUserSuccess = (payload) => ({
    type: types.LOGIN_USER_SUCCESS,
    payload,
});

export const loginUserError = (payload) => ({
    type: types.LOGIN_USER_ERROR,
    payload,
});

export const userChangePassword = (payload, navigate, validation) => ({
    type: types.USER_CHANGE_PASSWORD,
    navigate,
    payload,
    validation,
});

export const userChangePasswordSuccess = (payload) => ({
    type: types.USER_CHANGE_PASSWORD_SUCCESS,
    payload,
});

export const userChangePasswordError = (payload) => ({
    type: types.USER_CHANGE_PASSWORD_ERROR,
    payload,
});

export const resetUserErrors = () => ({
    type: types.RESET_USER_ERRORS,
});

export const userLogout = (navigate) => ({
    type: types.USER_LOGOUT,
    navigate,
});

export const resetUserData = () => ({
    type: types.RESET_USER_DATA,
});

export const updateUserEmail = (payload, keyToken, validation, userType) => ({
    type: types.UPDATE_USER_EMAIL,
    payload,
    keyToken,
    validation,
    userType,
});

export const updateUserEmailSuccess = (payload) => ({
    type: types.UPDATE_USER_EMAIL_SUCCESS,
    payload,
});

export const updateUserEmailError = (payload) => ({
    type: types.UPDATE_USER_EMAIL_ERROR,
    payload,
});

export const updateUserMobileNo = (payload, keyToken, validation) => ({
    type: types.UPDATE_USER_MOBILE_NO,
    payload,
    keyToken,
    validation,
});

export const updateUserMobileNoSuccess = (payload) => ({
    type: types.UPDATE_USER_MOBILE_NO_SUCCESS,
    payload,
});

export const updateUserMobileNoError = (payload) => ({
    type: types.UPDATE_USER_MOBILE_NO_ERROR,
    payload,
});

export const updateUserUsername = (payload, validation) => ({
    type: types.UPDATE_USER_USERNAME,
    payload,
    validation,
});

export const updateUserUsernameSuccess = (payload) => ({
    type: types.UPDATE_USER_USERNAME_SUCCESS,
    payload,
});

export const updateUserUsernameError = (payload) => ({
    type: types.UPDATE_USER_USERNAME_ERROR,
    payload,
});

export const updateUserProfile = (payload, keyToken, validation) => ({
    type: types.UPDATE_USER_PROFILE,
    payload,
    keyToken,
    validation,
});

export const updateUserProfileSuccess = (payload) => ({
    type: types.UPDATE_USER_PROFILE_SUCCESS,
    payload,
});

export const updateUserProfileError = (payload) => ({
    type: types.UPDATE_USER_PROFILE_ERROR,
    payload,
});

export const userForgotPassword = (payload, validation) => ({
    type: types.USER_FORGOT_PASSWORD,
    payload,
    validation,
});

export const userForgotPasswordSuccess = (payload) => ({
    type: types.USER_FORGOT_PASSWORD_SUCCESS,
    payload,
});

export const userForgotPasswordError = (payload) => ({
    type: types.USER_FORGOT_PASSWORD_ERROR,
    payload,
});

export const userResetPasswordValidateToken = (payload, token) => ({
    type: types.USER_RESET_PASSWORD_VALIDATE_TOKEN,
    payload,
    token,
});

export const userResetPasswordValidateTokenSuccess = (payload) => ({
    type: types.USER_RESET_PASSWORD_VALIDATE_TOKEN_SUCCESS,
    payload,
});

export const userResetPasswordValidateTokenError = (payload) => ({
    type: types.USER_RESET_PASSWORD_VALIDATE_TOKEN_ERROR,
    payload,
});

export const userResetPassword = (payload, token, navigate) => ({
    type: types.USER_RESET_PASSWORD,
    payload,
    token,
    navigate,
});

export const userResetPasswordSuccess = (payload) => ({
    type: types.USER_RESET_PASSWORD_SUCCESS,
    payload,
});

export const userResetPasswordError = (payload) => ({
    type: types.USER_RESET_PASSWORD_ERROR,
    payload,
});

export const userUpdateStatus = (data, keyToken) => ({
    type: types.USER_UPDATE_STATUS,
    data,
    keyToken,
});

export const userUpdateStatusSuccess = (payload) => ({
    type: types.USER_UPDATE_STATUS_SUCCESS,
    payload,
});

export const userUpdateStatusError = (payload) => ({
    type: types.USER_UPDATE_STATUS_ERROR,
    payload,
});

export const getCommission = (payload) => ({
    type: types.GET_COMMISSION,
    payload,
});

export const setCommission = (payload) => ({
    type: types.SET_COMMISSION,
    payload,
});

export const setCommissionError = () => ({
    type: types.SET_COMMISSION_ERROR,
});

export const updateReferralCode = (payload, setIsOpen) => ({
    type: types.UPDATE_REFFERAL_CODE,
    payload,
    setIsOpen,
});

export const updateReferralCodeSuccess = (payload) => ({
    type: types.UPDATE_REFFERAL_CODE_SUCCESS,
    payload,
});

export const updateReferralCodeError = (payload) => ({
    type: types.UPDATE_REFFERAL_CODE_ERROR,
    payload,
});

export const resetUpdateReferralCodeErrors = () => ({
    type: types.RESET_UPDATE_REFERRAL_CODE_ERRORS,
});

export const getUserCommissions = (payload) => ({
    type: types.GET_USER_COMMISSIONS,
    payload,
});

export const onGetUserCommissionsSuccess = (payload) => ({
    type: types.GET_USER_COMMISSIONS_SUCCESS,
    payload,
});

export const onGetUserCommissionsError = () => ({
    type: types.GET_USER_COMMISSIONS_ERROR,
});

export const getUserDeviceDetails = () => ({
    type: types.GET_USER_DEVICE_DETAILS,
});

export const getUserDeviceDetailsSuccess = (payload) => ({
    type: types.GET_USER_DEVICE_DETAILS_SUCCESS,
    payload,
});

export const getUserDeviceDetailsError = (payload) => ({
    type: types.GET_USER_DEVICE_DETAILS_SUCCESS_ERROR,
    payload,
});

export const updateReferrer = ({ payload, keyToken, initialize }) => ({
    type: types.UPDATE_REFERRER,
    payload,
    keyToken,
    initialize,
});

export const updateReferrerSuccess = () => ({
    type: types.UPDATE_REFERRER_SUCCESS,
});

export const updateReferrerError = () => ({
    type: types.UPDATE_REFERRER_ERROR,
});

export const getOperator = () => ({
    type: types.GET_OPERATOR,
});

export const getOperatorSuccess = (payload) => ({
    type: types.GET_OPERATOR_SUCCESS,
    payload,
});

export const getOperatorError = (payload) => ({
    type: types.GET_OPERATOR_ERROR,
    payload,
});

export const disableWithdrawalFeeMerchant = (
    payload,
    keyToken,
    initialize
) => ({
    type: types.DISABLE_WITHDRAWAL_FEE_MERCHANT,
    payload,
    keyToken,
    initialize,
});

export const disableWithdrawalFeeMerchantSuccess = () => ({
    type: types.DISABLE_WITHDRAWAL_FEE_MERCHANT_SUCCESS,
});

export const disableWithdrawalFeeMerchantError = () => ({
    type: types.DISABLE_WITHDRAWAL_FEE_MERCHANT_ERROR,
});
