import { confirmation } from "@/components/constants/common";
import {
    errorToast,
    loadingToast,
    removeToast,
    successToast,
    toastIcon,
} from "@/components/constants/toast";
import { Routes, getPath } from "@/constants";
import * as API from "@/network/apis/user";
import {
    setAdminProfile,
    setAgentProfile,
    setOperatorProfile,
    setPlayerProfile,
    updateAdminEmailSuccess,
    updateAgentEmailSuccess,
    updateOperatorEmailSuccess,
    updatePlayerEmailSuccess,
} from "@/store/actions";
import { convert2dErrorsArray } from "@/utils";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import * as TYPES from "./actionTypes";
import * as ACTIONS from "./actions";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

function* getUserData() {
    try {
        const response = yield call(API.getUserData);

        if (response.status === 200) {
            yield put(ACTIONS.setUserData(response.data.response));
        }
    } catch (error) {
        localStorage.clear();
        console.warn(error.response);
        yield put(ACTIONS.setUserDataError());
    }
}

function* getActivityLog({ payload }) {
    try {
        const response = yield call(API.getActivityLog, payload);

        if (response.status === 200) {
            yield put(ACTIONS.setActivityLog(response.data.response));
        }
    } catch (error) {
        let errors = !Array.isArray(error?.response?.data?.response)
            ? convert2dErrorsArray(error?.response?.data?.response)
            : [error.response.data.message];
        yield put(ACTIONS.setActivityLogError(errors));
    }
}

function* getUserCountData({ payload }) {
    try {
        const response = yield call(API.getUserCountData, payload);

        if (response.status === 200) {
            yield put(ACTIONS.setUserCountData(response.data.response));
        }
    } catch (error) {
        yield put(ACTIONS.setUserCountDataError());
    }
}

function* userLogout({ navigate }) {
    try {
        const response = yield call(API.logoutUser);

        if (response.status === 200) {
            yield put(ACTIONS.resetUserData());
            localStorage.removeItem("accessToken");
            navigate(getPath(Routes.LOGIN));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* loginUser({ payload, navigate }) {
    try {
        const response = yield call(API.loginUser, payload);

        if (response.status === 200) {
            yield put(ACTIONS.loginUserSuccess(response.data.response.user));
            localStorage.setItem(
                "accessToken",
                response.data.response.accessToken
            );
            navigate(getPath(Routes.DASHBOARD));
        }
    } catch (error) {
        let errors = !Array.isArray(error?.response?.data?.response)
            ? convert2dErrorsArray(error?.response?.data?.response)
            : [error.response.data.message];
        yield put(ACTIONS.loginUserError(errors));
    }
}

function* userChangePassword({ payload, navigate, validation }) {
    try {
        const response = yield call(API.userChangePassword, payload);

        if (response.status === 200) {
            yield put(
                ACTIONS.userChangePasswordSuccess(response.data.response)
            );
            confirmation({
                title: "Password Successfully Changed",
                text: "Do you want to logout?",
                icon: "success",
                confirmButtonText: "Yes, Logout",
                cancelButtonText: "Stay signin",
                action: () => {
                    let counter = 4,
                        countDown = setInterval(function () {
                            counter--;
                            toastIcon(`Logout in ${counter}`, "👏");
                            if (counter === 0) {
                                removeToast();
                                localStorage.removeItem("accessToken");
                                navigate(getPath(Routes.LOGIN));
                                clearInterval(countDown);
                            }
                        }, 1000);
                },
            });
            validation.resetForm();
        }
    } catch (error) {
        let errors = !Array.isArray(error?.response?.data?.response)
            ? convert2dErrorsArray(error?.response?.data?.response)
            : [error.response.data.message];
        yield put(ACTIONS.userChangePasswordError(errors));
    }
}

function* updateUserProfile({ payload, keyToken, validation }) {
    try {
        const response = yield call(API.updateUserProfile, payload, keyToken);

        if (response.status === 200) {
            yield put(ACTIONS.updateUserProfileSuccess(response.data.response));
            successToast("Successfully Updated.");
            validation.setErrors({});
        }
    } catch (error) {
        document.documentElement.scrollTop = 0;
        let errors = !Array.isArray(error?.response?.data?.response)
            ? convert2dErrorsArray(error?.response?.data?.response)
            : [error.response.data.message];
        yield put(ACTIONS.updateUserProfileError(errors));
    }
}

function* updateUserEmail({ payload, keyToken, validation, userType }) {
    try {
        loadingToast("Saving...");
        const response = yield call(API.updateUserEmail, payload, keyToken);

        if (response.status === 200) {
            switch (userType) {
                case "agent":
                case "master-agent":
                    yield put(updateAgentEmailSuccess(payload));
                    break;
                case "admin":
                    yield put(updateAdminEmailSuccess(payload));
                    break;
                case "player":
                    yield put(updatePlayerEmailSuccess(payload));
                    break;
                case "operator":
                    yield put(updateOperatorEmailSuccess(payload));
                    break;
                default:
                    break;
            }
            removeToast();
            successToast("Successfully Updated.");
            validation.setErrors({});
        }
    } catch (error) {
        document.documentElement.scrollTop = 0;
        let errors = convert2dErrorsArray(error?.response?.data?.response);
        yield put(ACTIONS.updateUserEmailError(errors));
        removeToast();
        errorToast(error.response.data.message);
    }
}

function* updateUserMobileNo({ payload, keyToken, validation }) {
    try {
        loadingToast("Saving...");
        const response = yield call(API.updateUserMobileNo, payload, keyToken);

        if (response.status === 200) {
            yield put(
                ACTIONS.updateUserMobileNoSuccess(response.data.response)
            );
            removeToast();
            successToast("Successfully Updated.");
            validation.setErrors({});
        }
    } catch (error) {
        document.documentElement.scrollTop = 0;
        let errors = convert2dErrorsArray(error?.response?.data?.response);
        yield put(ACTIONS.updateUserMobileNoError(errors));
        removeToast();
        errorToast(error.response.data.message);
    }
}

function* updateUserUsername({ payload, validation }) {
    try {
        loadingToast("Saving...");
        const response = yield call(API.updateUserUsername, payload);

        if (response.status === 200) {
            yield put(
                ACTIONS.updateUserUsernameSuccess(response.data.response)
            );
            removeToast();
            successToast("Successfully Updated.");
            validation.setErrors({});
        }
    } catch (error) {
        document.documentElement.scrollTop = 0;
        let errors = convert2dErrorsArray(error?.response?.data?.response);
        yield put(ACTIONS.updateUserUsernameError(errors));
        removeToast();
        errorToast(error.response.data.message);
    }
}

function* userForgotPassword({ payload, validation = null }) {
    try {
        loadingToast("Sending Reset Link...");
        const response = yield call(API.userForgotPassword, payload);
        if (response.status === 200) {
            yield put(
                ACTIONS.userForgotPasswordSuccess(response.data.response)
            );
            removeToast();
            successToast(
                validation
                    ? "Password reset link successfully sent to your email."
                    : "Password reset link successfully sent."
            );
            if (validation) {
                validation.resetForm();
            }
        }
    } catch (error) {
        removeToast();
        let errors = !Array.isArray(error?.response?.data?.response)
            ? convert2dErrorsArray(error?.response?.data?.response)
            : [error.response.data.message];
        yield put(ACTIONS.userForgotPasswordError(errors));
    }
}

function* userResetPasswordValidateToken({ payload, token }) {
    try {
        const response = yield call(
            API.userResetPasswordValidateToken,
            payload,
            token
        );

        if (response.status === 200) {
            yield put(
                ACTIONS.userResetPasswordValidateTokenSuccess(
                    response.data.response
                )
            );
        }
    } catch (error) {
        let errors = !Array.isArray(error?.response?.data?.response)
            ? convert2dErrorsArray(error?.response?.data?.response)
            : [error.response.data.message];
        yield put(ACTIONS.userResetPasswordValidateTokenError(errors));
    }
}

function* userResetPassword({ payload, token, navigate }) {
    try {
        const response = yield call(API.userResetPassword, payload, token);

        if (response.status === 200) {
            yield put(ACTIONS.userResetPasswordSuccess(response.data.response));
            successToast("Password Successfully Reset.");
            navigate(getPath(Routes.LOGIN));
        }
    } catch (error) {
        let errors = !Array.isArray(error?.response?.data?.response)
            ? convert2dErrorsArray(error?.response?.data?.response)
            : [error.response.data.message];
        yield put(ACTIONS.userResetPasswordError(errors));
    }
}

function* userUpdateStatus({ data, keyToken }) {
    try {
        loadingToast("Saving...");
        const response = yield call(API.userUpdateStatus, data, keyToken);

        if (response.status === 200) {
            yield put(ACTIONS.userUpdateStatusSuccess());
            const user = response.data.response;
            let userType = user.role.slug;
            if (userType === "player") {
                yield put(setPlayerProfile(response.data.response));
            }
            if (userType === "admin") {
                yield put(setAdminProfile(response.data.response));
            }
            if (userType === "operator") {
                yield put(setOperatorProfile(response.data.response));
            } else {
                yield put(setAgentProfile(response.data.response));
            }
            removeToast();
            successToast("Status Successfully Changed.");
        }
    } catch (error) {
        let errors = !Array.isArray(error?.response?.data?.response)
            ? convert2dErrorsArray(error?.response?.data?.response)
            : [error.response.data.message];
        removeToast();
        yield put(ACTIONS.userUpdateStatusError(errors));
    }
}

function* updateReferralCode({ payload, setIsOpen }) {
    try {
        loadingToast("Saving...");
        const response = yield call(API.updateReferralCode, payload);

        if (response.status === 200) {
            yield put(
                ACTIONS.updateReferralCodeSuccess(
                    response.data.response?.user_profile?.referral_code
                )
            );
            setIsOpen(false);
            removeToast();
            successToast("Successfully Updated.");
        }
    } catch (error) {
        let errors = !Array.isArray(error?.response?.data?.response)
            ? convert2dErrorsArray(error?.response?.data?.response)
            : [error.response.data.message];
        yield put(ACTIONS.updateReferralCodeError(errors));
        removeToast();
        errorToast(error.response.data.message);
    }
}

function* getCommission(action) {
    try {
        const response = yield call(API.getCommissions, action.payload);

        if (response.status === 200) {
            switch (action.type) {
                case "GET_USER_COMMISSIONS":
                    yield put(
                        ACTIONS.onGetUserCommissionsSuccess(
                            response.data.response
                        )
                    );
                    break;
                default:
                    yield put(ACTIONS.setCommission(response.data.response));
                    break;
            }
        }
    } catch (error) {
        switch (action.type) {
            case "GET_USER_COMMISSIONS":
                yield put(ACTIONS.onGetUserCommissionsError());
                break;
            default:
                yield put(ACTIONS.setCommissionError());
                break;
        }
    }
}

function* getUserDeviceDetails() {
    try {
        const deviceDetails = new Promise((resolve, reject) => {
            const setDeviceDetails = async () => {
                const fp = await FingerprintJS.load();
                const device = await fp.get();
                resolve(device);
            };
            setDeviceDetails();
        });

        const { visitorId } = yield deviceDetails;
        const getIpResponse = yield call(API.getInternetProtocol);

        if (visitorId || getIpResponse?.data) {
            yield put(
                ACTIONS.getUserDeviceDetailsSuccess({
                    visitorId,
                    userIP: getIpResponse?.data ?? null,
                })
            );
        }
    } catch (error) {
        errorToast(error.response.data.message);
    }
}

function* updateReferrer({ payload, keyToken, initialize }) {
    try {
        loadingToast("Saving...");
        const response = yield call(API.updateReferrer, payload, keyToken);

        if (response.status === 200) {
            yield put(ACTIONS.updateReferrerSuccess());
            removeToast();
            successToast("Successfully Updated.");
            initialize();
        }
    } catch (error) {
        initialize();
        yield put(ACTIONS.updateReferrerError([]));
        removeToast();
        errorToast(error.response.data.message);
    }
}

function* getOperatorsList() {
    try {
        const response = yield call(API.getOperatorsList);

        if (response.status === 200) {
            yield put(ACTIONS.getOperatorSuccess(response.data.response));
        }
    } catch (error) {
        let errors = convert2dErrorsArray(error?.response?.data?.response);
        yield put(ACTIONS.getOperatorError(errors));
        errorToast(error.response.data.message);
    }
}

function* disableWithdrawalFeeMerchant({ payload, keyToken, initialize }) {
    try {
        loadingToast("Saving...");
        const response = yield call(
            API.disableWithdrawalFeeMerchant,
            payload,
            keyToken
        );

        if (response.status === 200) {
            yield put(ACTIONS.disableWithdrawalFeeMerchantSuccess());
            removeToast();
            successToast("Successfully Updated.");
            initialize();
        }
    } catch (error) {
        yield put(ACTIONS.disableWithdrawalFeeMerchantError());
        removeToast();
        errorToast(error.response.data.message);
    }
}

function* watchGetUserData() {
    yield takeLatest(TYPES.GET_USER_DATA, getUserData);
}
function* watchGetActivityLog() {
    yield takeLatest(TYPES.GET_ACTIVITY_LOG, getActivityLog);
}
function* watchGetUserCountData() {
    yield takeLatest(TYPES.GET_USER_COUNT_DATA, getUserCountData);
}
function* watchUserLogin() {
    yield takeLatest(TYPES.LOGIN_USER, loginUser);
}
function* watchUserUpdateStatus() {
    yield takeLatest(TYPES.USER_UPDATE_STATUS, userUpdateStatus);
}
function* watchUserChangePassword() {
    yield takeLatest(TYPES.USER_CHANGE_PASSWORD, userChangePassword);
}
function* watchUpdateUserProfile() {
    yield takeLatest(TYPES.UPDATE_USER_PROFILE, updateUserProfile);
}
function* watchUserLogout() {
    yield takeLatest(TYPES.USER_LOGOUT, userLogout);
}
function* watchUpdateUserEmail() {
    yield takeLatest(TYPES.UPDATE_USER_EMAIL, updateUserEmail);
}
function* watchUpdateUserMobileNo() {
    yield takeLatest(TYPES.UPDATE_USER_MOBILE_NO, updateUserMobileNo);
}
function* watchUpdateUserUsername() {
    yield takeLatest(TYPES.UPDATE_USER_USERNAME, updateUserUsername);
}
function* watchUpdateReferralCode() {
    yield takeLatest(TYPES.UPDATE_REFFERAL_CODE, updateReferralCode);
}
function* watchUserForgotPassword() {
    yield takeLatest(TYPES.USER_FORGOT_PASSWORD, userForgotPassword);
}
function* watchUserResetPassword() {
    yield takeLatest(TYPES.USER_RESET_PASSWORD, userResetPassword);
}
function* watchUserResetPasswordValidateToken() {
    yield takeLatest(
        TYPES.USER_RESET_PASSWORD_VALIDATE_TOKEN,
        userResetPasswordValidateToken
    );
}
function* watchGetCommission() {
    yield takeLatest(TYPES.GET_COMMISSION, getCommission);
    yield takeLatest(TYPES.GET_USER_COMMISSIONS, getCommission);
}

function* watchgetUserDeviceDetails() {
    yield takeLatest(TYPES.GET_USER_DEVICE_DETAILS, getUserDeviceDetails);
}

function* watchUpdateReferrer() {
    yield takeLatest(TYPES.UPDATE_REFERRER, updateReferrer);
}

function* watchGetOperatorsList() {
    yield takeLatest(TYPES.GET_OPERATOR, getOperatorsList);
}

function* watchDisableWithdrawalFeeMerchant() {
    yield takeLatest(
        TYPES.DISABLE_WITHDRAWAL_FEE_MERCHANT,
        disableWithdrawalFeeMerchant
    );
}

function* userSagas() {
    yield all([
        fork(watchUserLogin),
        fork(watchGetActivityLog),
        fork(watchUserUpdateStatus),
        fork(watchGetUserCountData),
        fork(watchUserChangePassword),
        fork(watchGetUserData),
        fork(watchUserLogout),
        fork(watchUpdateUserEmail),
        fork(watchUpdateUserMobileNo),
        fork(watchUpdateUserUsername),
        fork(watchUpdateUserProfile),
        fork(watchUpdateReferralCode),
        fork(watchUserForgotPassword),
        fork(watchUserResetPassword),
        fork(watchGetCommission),
        fork(watchUserResetPasswordValidateToken),
        fork(watchgetUserDeviceDetails),
        fork(watchUpdateReferrer),
        fork(watchGetOperatorsList),
        fork(watchDisableWithdrawalFeeMerchant),
    ]);
}

export default userSagas;
