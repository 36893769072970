import {
    errorToast,
    loadingToast,
    removeToast,
    successToast,
} from "@/components/constants/toast";
import * as agentApi from "@/network/apis/agent";
import {
    applyDownlinesCommissionTransferSchedule,
    getCommissions,
} from "@/network/apis/user";
import { convert2dErrorsArray } from "@/utils";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import * as TYPES from "./actionTypes";
import * as ACTIONS from "./actions";

const API = {
    ...agentApi,
    getCommissions,
    applyDownlinesCommissionTransferSchedule,
};

function* agentList({ payload }) {
    // yield delay(500);
    try {
        const response = yield call(API.agentList, payload);

        if (response.status === 200) {
            yield put(ACTIONS.setAgentList(response.data));
        }
    } catch (error) {
        console.warn(error.response);
        yield put(ACTIONS.setAgentListError());
    }
}

function* agentProfile({ payload }) {
    try {
        const response = yield call(API.agentProfile, payload);

        if (response.status === 200) {
            yield put(ACTIONS.setAgentProfile(response.data.response));
        }
    } catch (error) {
        console.warn(error.response);
        yield put(ACTIONS.setAgentProfileError());
    }
}

function* createAgent({ payload, validation }) {
    try {
        loadingToast("Processing...");
        const response = yield call(API.createAgent, payload);

        if (response.status === 200) {
            yield put(ACTIONS.createAgentSuccess(response.data));
            validation.resetForm();
            removeToast();
            successToast("Successfully Added.");
        }
    } catch (error) {
        removeToast();
        document.documentElement.scrollTop = 0;
        let errors = !Array.isArray(error?.response?.data?.response)
            ? convert2dErrorsArray(error?.response?.data?.response)
            : [error.response.data.message];
        yield put(ACTIONS.createAgentError(errors));
    }
}

function* updateAgent({ payload, keyToken }) {
    try {
        const response = yield call(API.updateAgent, payload, keyToken);

        if (response.status === 200) {
            yield put(ACTIONS.updateAgentSuccess(response.data.response));
            successToast("Successfully Updated.");
        }
    } catch (error) {
        document.documentElement.scrollTop = 0;
        let errors = !Array.isArray(error?.response?.data?.response)
            ? convert2dErrorsArray(error?.response?.data?.response)
            : [error.response.data.message];
        yield put(ACTIONS.updateAgentError(errors));
    }
}

function* updateAgentCommissionRate({ payload, keyToken, validation }) {
    try {
        loadingToast("Saving...");
        const response = yield call(
            API.updateAgentCommissionRate,
            payload,
            keyToken
        );

        if (response.status === 200) {
            yield put(
                ACTIONS.updateAgentCommissionRateSuccess(response.data.response)
            );
            removeToast();
            successToast("Successfully Updated.");
            validation.setErrors({});
        }
    } catch (error) {
        document.documentElement.scrollTop = 0;
        let errors = !Array.isArray(error?.response?.data?.response)
            ? convert2dErrorsArray(error?.response?.data?.response)
            : [error.response.data.message];

        if (errors?.commission_rate) {
            errors = {
                [payload.type]: errors.commission_rate,
            };
        }

        yield put(
            ACTIONS.updateAgentCommissionRateError({
                type: payload.type,
                errors: errors,
            })
        );
        removeToast();
        errorToast(error.response.data.message);
    }
}

function* updateAgentRoleTag({ payload, keyToken }) {
    try {
        loadingToast("Saving...");
        const response = yield call(API.updateAgentRoleTag, payload, keyToken);

        if (response.status === 200) {
            yield put(
                ACTIONS.updateAgentRoleTagSuccess(response.data.response)
            );
            removeToast();
            successToast("Successfully Updated.");
        }
    } catch (error) {
        yield put(ACTIONS.updateAgentRoleTagError([]));
        removeToast();
        errorToast(error.response.data.message);
    }
}

function* enableValidTurnoverCommission({
    payload,
    balanceCategory,
    keyToken,
    initialize,
}) {
    try {
        loadingToast("Saving...");
        const response = yield call(
            API.enableValidTurnoverCommission,
            payload,
            balanceCategory,
            keyToken
        );

        if (response.status === 200) {
            yield put(ACTIONS.enableValidTurnoverCommissionSuccess());
            removeToast();
            successToast("Successfully Updated.");
            initialize();
        }
    } catch (error) {
        yield put(ACTIONS.enableValidTurnoverCommissionError([]));
        removeToast();
        errorToast(error.response.data.message);
    }
}

function* updateUserProfileSetting({ setting, payload, keyToken, initialize }) {
    try {
        const response = yield call(
            API.updateUserProfileSetting,
            setting,
            payload,
            keyToken
        );

        if (response.status === 200) {
            yield put(ACTIONS.updateUserProfileSettingSuccess());
            removeToast();
            successToast("Successfully Updated.");
            initialize();
        }
    } catch (error) {
        yield put(ACTIONS.updateUserProfileSettingError([]));
        removeToast();
        errorToast(error.response.data.message);
    }
}

function* updateUserProfileSettingWithBalanceCategory({
    setting,
    payload,
    balanceCategory,
    keyToken,
    initialize,
}) {
    try {
        const response = yield call(
            API.updateUserProfileSettingWithBalanceCategory,
            setting,
            payload,
            balanceCategory,
            keyToken
        );

        if (response.status === 200) {
            yield put(
                ACTIONS.updateUserProfileSettingWithBalanceCategorySuccess()
            );
            removeToast();
            successToast("Successfully Updated.");
            initialize();
        }
    } catch (error) {
        yield put(ACTIONS.updateUserProfileSettingWithBalanceCategoryError([]));
        removeToast();
        errorToast(error.response.data.message);
    }
}

function* getAgentCommissionAsync(action) {
    try {
        const response = yield call(API.getCommissions, action.payload);

        if (response.status === 200) {
            yield put(
                ACTIONS.onGetAgentCommissionsSuccess(response?.data?.response)
            );
        }
    } catch (error) {
        yield put(ACTIONS.onGetAgentCommissionsFailed());
    }
}

function* enableNegativeCarryOverCommission({
    payload,
    balanceCategory,
    keyToken,
    initialize,
}) {
    try {
        loadingToast("Saving...");
        const response = yield call(
            API.allowNegativeCarryOverCommission,
            payload,
            balanceCategory,
            keyToken
        );

        if (response.status === 200) {
            yield put(ACTIONS.enableNegativeCarryOverCommissionSuccess());
            removeToast();
            successToast("Successfully Updated.");
            initialize();
        }
    } catch (error) {
        yield put(ACTIONS.enableNegativeCarryOverCommissionError([]));
        removeToast();
        errorToast(error.response.data.message);
    }
}

function* updatePendingCommissionRequiredToResetNegativeCommission({
    payload,
    balanceCategory,
    keyToken,
    initialize,
}) {
    try {
        loadingToast("Saving...");
        const response = yield call(
            API.updatePendingCommissionRequiredToResetNegativeCommission,
            payload,
            balanceCategory,
            keyToken
        );

        if (response.status === 200) {
            yield put(
                ACTIONS.updatePendingCommissionRequiredToResetNegativeCommissionSuccess()
            );
            removeToast();
            successToast("Successfully Updated.");
            initialize();
        }
    } catch (error) {
        initialize();
        yield put(
            ACTIONS.updatePendingCommissionRequiredToResetNegativeCommissionError(
                []
            )
        );
        removeToast();
        errorToast(error.response.data.message);
    }
}

function* updateNoOfCutoffToResetNegativeCommission({
    payload,
    balanceCategory,
    keyToken,
    initialize,
}) {
    try {
        loadingToast("Saving...");
        const response = yield call(
            API.updateNoOfCutoffToResetNegativeCommission,
            payload,
            balanceCategory,
            keyToken
        );

        if (response.status === 200) {
            yield put(
                ACTIONS.updateNumberOfCutoffToResetNegativeCommissionSuccess()
            );
            removeToast();
            successToast("Successfully Updated.");
            initialize();
        }
    } catch (error) {
        initialize();
        yield put(
            ACTIONS.updateNumberOfCutoffToResetNegativeCommissionError([])
        );
        removeToast();
        errorToast(error.response.data.message);
    }
}

function* applyDownlinesCommissionTransferScheduleAgent({
    payload,
    keyToken,
    initialize,
}) {
    try {
        loadingToast("Saving...");
        const response = yield call(
            API.applyDownlinesCommissionTransferSchedule,
            payload,
            keyToken
        );

        if (response.status === 200) {
            yield put(
                ACTIONS.applyDownlinesCommissionTransferScheduleAgentSuccess()
            );
            removeToast();
            successToast("Successfully Updated.");
            initialize();
        }
    } catch (error) {
        initialize();
        yield put(
            ACTIONS.applyDownlinesCommissionTransferScheduleAgentError([])
        );
        removeToast();
        errorToast(error.response.data.message);
    }
}

function* updateAffiliateRequestStatus({ payload }) {
    try {
        loadingToast("Saving...");
        const response = yield call(
            API.updateAffiliateRequestStatus,
            payload.data,
            payload.keyToken
        );
        if (response.status === 200) {
            yield put(ACTIONS.updateAffiliateRequestStatusSuccess());
            payload.setDetails(response?.data?.response);
            payload.initialize();
            removeToast();
            successToast("Successfully Updated.");
            if (payload.data.status === "approve") {
                payload.setIsOpen(false);
                payload.validation.resetForm();
            }
        }
    } catch (error) {
        removeToast();
        if (payload.data.status === "reject") {
            yield put(ACTIONS.updateAffiliateRequestStatusError([]));
        } else {
            let errors = !Array.isArray(error?.response?.data?.response)
                ? convert2dErrorsArray(error?.response?.data?.response)
                : [error.response.data.message];
            yield put(ACTIONS.createAgentError(errors));
        }
        errorToast(error.response.data.message);
    }
}

function* watchCreateAgent() {
    yield takeLatest(TYPES.CREATE_AGENT, createAgent);
}
function* watchUpdateAgent() {
    yield takeLatest(TYPES.UPDATE_AGENT, updateAgent);
}
function* watchAgentList() {
    yield takeLatest(TYPES.GET_AGENT_LIST, agentList);
}
function* watchAgentProfile() {
    yield takeLatest(TYPES.GET_AGENT_PROFILE, agentProfile);
}
function* watchUpdateAgentCommissionRate() {
    yield takeLatest(
        TYPES.UPDATE_AGENT_COMMISSION_RATE,
        updateAgentCommissionRate
    );
}
function* watchGetAgentCommission() {
    yield takeLatest(TYPES.GET_AGENT_COMMISSIONS, getAgentCommissionAsync);
}
function* watchUpdateAgentRoleTag() {
    yield takeLatest(TYPES.UPDATE_AGENT_ROLE_TAG, updateAgentRoleTag);
}
function* watchEnableValidTurnoverCommission() {
    yield takeLatest(
        TYPES.ENABLE_VALID_TURNOVER_COMMISSION,
        enableValidTurnoverCommission
    );
}

function* watchUpdateUserProfileSetting() {
    yield takeLatest(
        TYPES.UPDATE_USER_PROFILE_SETTING,
        updateUserProfileSetting
    );
}

function* watchUpdateUserProfileSettingWithBalanceCategory() {
    yield takeLatest(
        TYPES.UPDATE_USER_PROFILE_SETTING_WITH_BALANCE_CATEGORY,
        updateUserProfileSettingWithBalanceCategory
    );
}

function* watchEnableNegativeCarryOverCommission() {
    yield takeLatest(
        TYPES.ENABLE_NEGATIVE_CARRY_OVER_COMMISSION,
        enableNegativeCarryOverCommission
    );
}

function* watchUpdatePendingCommissionRequiredToResetNegativeCommission() {
    yield takeLatest(
        TYPES.UPDATE_PENDING_COMMISSION_REQUIRED_TO_RESET_NEGATIVE_COMMISSION,
        updatePendingCommissionRequiredToResetNegativeCommission
    );
}
function* watchUpdateNoOfCutoffToResetNegativeCommission() {
    yield takeLatest(
        TYPES.UPDATE_NUMBER_OF_CUTOFF_TO_RESET_NEGATIVE_COMMISSION,
        updateNoOfCutoffToResetNegativeCommission
    );
}
function* watchApplyDownlinesCommissionTransferScheduleAgent() {
    yield takeLatest(
        TYPES.APPLY_DOWNLINES_COMMISSIONS_TRANSFER_SCHEDULE_AGENT,
        applyDownlinesCommissionTransferScheduleAgent
    );
}
function* watchUpdateAffiliateRequestStatus() {
    yield takeLatest(
        TYPES.UPDATE_AFFILIATE_REQUEST_STATUS,
        updateAffiliateRequestStatus
    );
}

function* agentSagas() {
    yield all([
        fork(watchCreateAgent),
        fork(watchUpdateAgent),
        fork(watchAgentList),
        fork(watchAgentProfile),
        fork(watchUpdateAgentCommissionRate),
        fork(watchGetAgentCommission),
        fork(watchUpdateAgentRoleTag),
        fork(watchEnableValidTurnoverCommission),
        fork(watchUpdateUserProfileSetting),
        fork(watchUpdateUserProfileSettingWithBalanceCategory),
        fork(watchEnableNegativeCarryOverCommission),
        fork(watchUpdatePendingCommissionRequiredToResetNegativeCommission),
        fork(watchUpdateNoOfCutoffToResetNegativeCommission),
        fork(watchApplyDownlinesCommissionTransferScheduleAgent),
        fork(watchUpdateAffiliateRequestStatus),
    ]);
}

export default agentSagas;
