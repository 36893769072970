import {
    InputSwitch,
    Select,
    StatusBadge,
    Table,
    Title,
} from "@/components/atoms";
import BreadCrumb from "@/components/common/BreadCrumb";
import { confirmation } from "@/components/constants/common";
import { Routes, getPath, getPathTitle } from "@/constants";
import {
    getPromotionCategoryList,
    getPromotionList,
    updatePromotionStatus,
} from "@/store/actions";
import { defaultDateTimeFormat } from "@/utils";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row,
} from "reactstrap";

const PromotionList = () => {
    const dispatch = useDispatch();
    const { promotionList, promotionCategoryList, isLoading } = useSelector(
        (state) => state.promotion
    );

    const [search, setSearch] = useState("");
    const [sortBy, setSortBy] = useState({
        field: "",
        type: "asc",
    });

    const [data, setData] = useState({
        per_page: 10,
        page: 1,
        search: "",
        sort_by:
            sortBy.field && sortBy.type ? `${sortBy.field} ${sortBy.type}` : "",
        promotion_category: "",
    });

    const tableTheadData = [
        {
            label: "Promotion No.",
            field: "promotion_no",
            sortable: true,
        },
        {
            label: "Name",
            field: "name",
            sortable: true,
        },
        {
            label: "Start At",
            sortable: true,
            render: (item) => defaultDateTimeFormat(item.start_datetime),
        },
        {
            label: "End At",
            sortable: true,
            render: (item) => defaultDateTimeFormat(item.end_datetime),
        },
        {
            label: "Is Posted",
            field: "is_active",
            sortable: false,
            render: (item) => (
                <div className="d-flex w-100 justify-content-center">
                    <InputSwitch
                        label={item.is_active ? "Yes" : "No"}
                        id={`promotion-status-${item.slug}`}
                        isChecked={item.is_active}
                        color="success"
                        onChange={() => changeStatus(item)}
                    />
                </div>
            ),
        },
        {
            label: "Is Ongoing",
            field: "is_ongoing",
            sortable: false,
            render: (item) => (
                <StatusBadge
                    status={item.is_ongoing ? "yes" : "no"}
                    type="bool"
                />
            ),
        },
        {
            label: "Action",
            field: "action",
            sortable: false,
            render: (item) => (
                <Link
                    to={getPath(Routes.PROMOTION_UPDATE).replace(
                        ":promotionNo",
                        item?.promotion_no
                    )}
                    className="link-primary"
                >
                    <Button color="primary" size="sm">
                        <i className="ri-pencil-line"></i>
                    </Button>
                </Link>
            ),
        },
    ];

    const changeStatus = (item) => {
        confirmation({
            title: item.promotion_no,
            text: `Are you sure you want to ${
                item.is_active ? "REMOVE POST" : "POST"
            } on promotions page`,
            icon: "warning",
            confirmButtonText: "Confirm",
            action: () => {
                dispatch(updatePromotionStatus(item.key_token, initialize));
            },
        });
    };

    const initialize = () => {
        dispatch(getPromotionList(data));
    };

    useEffect(() => {
        dispatch(getPromotionCategoryList());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getPromotionList(data));
    }, [dispatch, data]);

    return (
        <React.Fragment>
            <Title title={getPathTitle(Routes.PROMOTION_LIST)} />
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb
                        parent={{
                            isParent: true,
                            pageTitle: getPathTitle(Routes.PROMOTION_LIST),
                            pageLink: getPath(Routes.PROMOTION_LIST),
                        }}
                    />
                    <Row>
                        {/* <Col xs={3}>
                            <Card className="h-100">
                                <CardHeader className="align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1 text-center">
                                        {getPathTitle(
                                            Routes.PROMOTION_CATEGORIES
                                        )}
                                    </h4>
                                </CardHeader>
                                <CardBody>
                                    {[
                                        { title: "All", key_token: "" },
                                        ...(promotionCategoryList ?? []),
                                    ].map((item, index) => (
                                        <ButtonStrap
                                            key={index}
                                            color={
                                                data.promotion_category ===
                                                item?.key_token
                                                    ? "primary"
                                                    : "light"
                                            }
                                            className="me-2 mb-2 tw-w-full"
                                            onClick={() => {
                                                setData((data) => ({
                                                    ...data,
                                                    promotion_category:
                                                        item.key_token,
                                                }));
                                            }}
                                        >
                                            {item?.title}
                                        </ButtonStrap>
                                    ))}
                                </CardBody>
                            </Card>
                        </Col> */}
                        <Col xs={12}>
                            <Card>
                                <CardHeader className="align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">
                                        {getPathTitle(Routes.PROMOTION_LIST)}
                                    </h4>
                                    <div className="flex-shrink-0">
                                        <Link
                                            to={getPath(
                                                Routes.PROMOTION_CREATE
                                            )}
                                        >
                                            <Button color="success" size="sm">
                                                <i className="ri-add-fill me-1 align-bottom"></i>
                                                {getPathTitle(
                                                    Routes.PROMOTION_CREATE
                                                )}
                                            </Button>
                                        </Link>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Row className="mb-3">
                                        <Col md={6}>
                                            <Select
                                                label="Category"
                                                isClearable={true}
                                                value={data.promotion_category}
                                                options={[
                                                    { label: "All", value: "" },
                                                    ...((
                                                        promotionCategoryList ??
                                                        []
                                                    ).map((item) => ({
                                                        label: item.title,
                                                        value: item.key_token,
                                                    })) ?? []),
                                                ]}
                                                onChange={(e) => {
                                                    setData((data) => ({
                                                        ...data,
                                                        promotion_category:
                                                            e.target.value,
                                                    }));
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Table
                                        search={search}
                                        handlerSearch={setSearch}
                                        sortBy={sortBy}
                                        handlerSortBy={setSortBy}
                                        thead={tableTheadData}
                                        handlerChangeData={setData}
                                        data={data}
                                        collection={promotionList}
                                        isLoading={isLoading}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default PromotionList;
