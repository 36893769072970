import { CounterWidget, ProgressBar } from "@/components/atoms";
import { batchPayoutJobsCounter } from "@/constants/users";
import React from "react";
import {
    Col,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
    Card,
    CardBody,
    Badge,
} from "reactstrap";
const ImportPayoutStatusModal = ({ isOpen, setIsOpen, data }) => {
    return (
        <Modal
            id="batchPayoutStatusModal"
            isOpen={isOpen}
            toggle={() => setIsOpen(!isOpen)}
            centered={true}
        >
            <ModalHeader
                toggle={() => setIsOpen(!isOpen)}
                className="tw-flex tw-flex-row tw-w-full"
            >
                <div className="modal-title">Import Payout Status</div>
            </ModalHeader>
            <ModalBody>
                <div className="tw-overflow-y-auto tw-h-96">
                    {data.map((item, index) => (
                        <Card
                            key={index}
                            className="border-3 border-black mb-2"
                        >
                            <CardBody className="d-grid gap-2">
                                <div className="d-flex tw-items-center justify-content-between gap-2">
                                    <div className="">Row {item.row}</div>
                                    <div>
                                        <Badge
                                            color={
                                                item.success
                                                    ? "success"
                                                    : "danger"
                                            }
                                        >
                                            {item.success
                                                ? "Success"
                                                : "Failed"}
                                        </Badge>
                                    </div>
                                </div>
                                {item?.message && <div>{item.message}</div>}
                            </CardBody>
                        </Card>
                    ))}
                </div>
            </ModalBody>
        </Modal>
    );
};

export default ImportPayoutStatusModal;
