import * as types from "./actionTypes";

export const getRequestData = (payload, requestType) => ({
    type: types.GET_REQUEST_DATA,
    payload,
    requestType,
});

export const setRequestData = (payload) => ({
    type: types.SET_REQUEST_DATA,
    payload,
});

export const setRequestDataError = (payload) => ({
    type: types.SET_REQUEST_DATA_ERROR,
    payload,
});

export const getRequestInfo = (payload) => ({
    type: types.GET_REQUEST_INFO,
    payload,
});

export const setRequestInfo = (payload) => ({
    type: types.SET_REQUEST_INFO,
    payload,
});

export const setRequestInfoError = (payload) => ({
    type: types.SET_REQUEST_INFO_ERROR,
    payload,
});

export const updateRequestStatus = (payload, requestType, keyToken) => ({
    type: types.UPDATE_REQUEST_STATUS,
    payload,
    requestType,
    keyToken,
});

export const updateRequestStatusSuccess = (payload) => ({
    type: types.UPDATE_REQUEST_STATUS_SUCCESS,
    payload,
});

export const updateRequestStatusError = (payload) => ({
    type: types.UPDATE_REQUEST_STATUS_ERROR,
    payload,
});

export const resetRequestErrors = () => ({
    type: types.RESET_REQUEST_ERRORS,
});

export const bulkRelease = (payload) => ({
    type: types.BULK_RELEASE,
    payload,
});

export const onBulkReleaseSuccess = (payload) => ({
    type: types.BULK_RELEASE_SUCCESS,
    payload,
});

export const onBulkReleaseFailed = (payload) => ({
    type: types.BULK_RELEASE_FAILED,
    payload,
});

export const bulkPayout = (payload) => ({
    type: types.BULK_PAYOUT,
    payload,
});

export const onBulkPayoutSuccess = () => ({
    type: types.BULK_PAYOUT_SUCCESS,
});

export const onBulkPayoutFailed = () => ({
    type: types.BULK_PAYOUT_FAILED,
});

export const getBulkPayoutProgress = () => ({
    type: types.GET_BULK_PAYOUT_PROGRESS,
});

export const onGetBulkPayoutProgressSuccess = (payload) => ({
    type: types.GET_BULK_PAYOUT_PROGRESS_SUCCESS,
    payload,
});

export const onGetBulkPayoutProgressFailed = () => ({
    type: types.GET_BULK_PAYOUT_PROGRESS_FAILED,
});

export const importPayout = (payload) => ({
    type: types.IMPORT_PAYOUT,
    payload,
});

export const onImportPayoutSuccess = (payload) => ({
    type: types.IMPORT_PAYOUT_SUCCESS,
    payload,
});

export const onImportPayoutFailed = (payload) => ({
    type: types.IMPORT_PAYOUT_FAILED,
    payload,
});

export const setPublicPaymentMethods = (payload) => ({
    type: types.SET_PUBLIC_PAYMENT_METHODS,
    payload,
});

export const getPublicPaymentMethods = () => ({
    type: types.GET_PUBLIC_PAYMENT_METHODS,
});