import { InputSwitch, Table, Title } from "@/components/atoms";
import { UpdateLottoScheduleModal } from "@/components/molecules";
import BreadCrumb from "@/components/common/BreadCrumb";
import { confirmation } from "@/components/constants/common";
import { Routes, getPath, getPathTitle } from "@/constants";
import { getDrawScheduleList, updateGameTypeStatus } from "@/store/actions";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import { defaultDateFormat } from "@/utils";

const DrawSecheduleList = () => {
  const dispatch = useDispatch();
  const [updateLottoScheduleIsOpen, setUpdateLottoScheduleIsOpen] =
    useState(false);
  const [selectedItem, setSelectedItem] = useState({});

  const { drawScheduleList, isLoading, isUpdating } = useSelector(
    (state) => state.lottoSetting
  );
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState({
    field: "",
    type: "asc",
  });
  const [data, setData] = useState({
    per_page: 10,
    page: 1,
    // search: "",
  });

  const tableTheadData = [
    {
      label: "Date",
      field: "draw_date",
      render: (item) => defaultDateFormat(item.draw_date),
    },
    {
      label: "Action",
      sortable: false,
      render: (item) => (
        <Button
          onClick={() => {
            setUpdateLottoScheduleIsOpen(true);
            setSelectedItem(item);
          }}
          color="primary"
          size="sm"
        >
          <i className="ri-play-list-add-line"></i>
        </Button>
      ),
    },
  ];

  const initialize = () => {
    dispatch(getDrawScheduleList(data));
  };

  useEffect(() => {
    dispatch(getDrawScheduleList(data));
  }, [data, dispatch]);

  return (
    <React.Fragment>
      <Title title={getPathTitle(Routes.DRAW_SCHEDULE_LIST)} />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            parent={{
              isParent: true,
              pageTitle: getPathTitle(Routes.DRAW_SCHEDULE_LIST),
              pageLink: getPath(Routes.DRAW_SCHEDULE_LIST),
            }}
          />
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <Table
                    search={search}
                    handlerSearch={setSearch}
                    sortBy={sortBy}
                    handlerSortBy={setSortBy}
                    thead={tableTheadData}
                    handlerChangeData={setData}
                    data={data}
                    collection={drawScheduleList}
                    isLoading={isLoading}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {updateLottoScheduleIsOpen && (
          <UpdateLottoScheduleModal
            isOpen={updateLottoScheduleIsOpen}
            setIsOpen={setUpdateLottoScheduleIsOpen}
            data={selectedItem}
            initialize={initialize}
            isUpdating={isUpdating}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default DrawSecheduleList;
