import * as types from "./actionTypes";

export const getCasinoGameProvider = () => ({
    type: types.GET_CASINO_GAME_PROVIDER,
});

export const setCasinoGameProvider = (payload) => ({
    type: types.SET_CASINO_GAME_PROVIDER,
    payload,
});

export const setCasinoGameProviderError = (payload) => ({
    type: types.SET_CASINO_GAME_PROVIDER_ERROR,
    payload,
});

export const getCasinoGameType = () => ({
    type: types.GET_CASINO_GAME_TYPE,
});

export const setCasinoGameType = (payload) => ({
    type: types.SET_CASINO_GAME_TYPE,
    payload,
});

export const setCasinoGameTypeError = (payload) => ({
    type: types.SET_CASINO_GAME_TYPE_ERROR,
    payload,
});

export const getCasinoGameList = (payload) => ({
    type: types.GET_CASINO_GAME_LIST,
    payload,
});

export const setCasinoGameList = (payload) => ({
    type: types.SET_CASINO_GAME_LIST,
    payload,
});

export const setCasinoGameListError = (payload) => ({
    type: types.SET_CASINO_GAME_LIST_ERROR,
    payload,
});

export const syncCasinoGameProvider = (payload) => ({
    type: types.SYNC_CASINO_GAME_PROVIDER,
    payload,
});

export const syncCasinoGameProviderSuccess = (payload) => ({
    type: types.SYNC_CASINO_GAME_PROVIDER_SUCCESS,
    payload,
});

export const syncCasinoGameProviderError = (payload) => ({
    type: types.SYNC_CASINO_GAME_PROVIDER_ERROR,
    payload,
});

export const updateGameStatus = (gameType, keyToken, reloadData) => ({
    type: types.UPDATE_GAME_STATUS,
    gameType,
    keyToken,
    reloadData,
});

export const updateGameStatusSuccess = (payload) => ({
    type: types.UPDATE_GAME_STATUS_SUCCESS,
    payload,
});

export const updateGameStatusError = (payload) => ({
    type: types.UPDATE_GAME_STATUS_ERROR,
    payload,
});

export const getFeaturedGameTypes = () => ({
    type: types.GET_FEATURED_GAME_TYPES,
});

export const onGetFeaturedGameTypesSuccess = (payload) => ({
    type: types.GET_FEATURED_GAME_TYPES_SUCCESS,
    payload,
});

export const onGetFeatureGameTypesFailed = (payload) => ({
    type: types.GET_FEATURED_GAME_TYPES_FAILED,
    payload,
});

export const addFeaturedGame = (payload) => ({
    type: types.ADD_FEATURED_GAME,
    payload,
});

export const onAddFeaturedGameSuccess = (payload) => ({
    type: types.ADD_FEATURED_GAME_SUCCESS,
    payload,
});

export const onAddFeaturedGameFailed = (payload) => ({
    type: types.ADD_FEATURED_GAME_FAILED,
    payload,
});

export const resetFeaturedGameError = () => ({
    type: types.RESET_FEATURED_GAME_ERROR,
});

export const deleteFeaturedGame = (payload) => ({
    type: types.DELETE_FEATURED_GAME,
    payload,
});

export const onDeleteFeaturedGameSuccess = () => ({
    type: types.DELETE_FEATURED_GAME_SUCCESS,
});

export const onDeleteFeaturedGameFailed = () => ({
    type: types.DELETE_FEATURED_GAME_FAILED,
});

export const updateFeaturedGameOrder = (payload, keyToken) => ({
    type: types.UPDATE_FEATURED_GAME_ORDER,
    payload,
    keyToken,
});

export const onUpdateFeaturedGameOrderSuccess = () => ({
    type: types.UPDATE_FEATURED_GAME_ORDER_SUCCESS,
});

export const onUpdateFeaturedGameOrderFailed = () => ({
    type: types.UPDATE_FEATURED_GAME_ORDER_FAILED,
});

export const getGameOfferings = (payload) => ({
    type: types.GET_GAME_OFFERINGS,
    payload,
});

export const onGetGameOfferingsSuccess = (payload) => ({
    type: types.GET_GAME_OFFERINGS_SUCCESS,
    payload,
});

export const onGetGameOfferingsFailed = () => ({
    type: types.GET_GAME_OFFERINGS_FAILED,
});

export const updateGameProviderOrder = (payload) => ({
    type: types.UPDATE_GAME_PROVIDER_ORDER,
    payload
});

export const onUpdateGameProviderOrderSuccess = (payload) => ({
    type: types.UPDATE_GAME_PROVIDER_ORDER_SUCCESS,
    payload
});

export const onUpdateGameProviderOrderError = () => ({
    type: types.UPDATE_GAME_PROVIDER_ORDER_ERROR,
});

export const updateFeaturedGame = (payload) => ({
    type: types.UPDATE_FEATURED_GAME,
    payload,
});

export const onUpdateFeaturedGameSuccess = (payload) => ({
    type: types.UPDATE_FEATURED_GAME_SUCCESS,
    payload,
});

export const onUpdateFeaturedGameFailed = (payload) => ({
    type: types.UPDATE_FEATURED_GAME_FAILED,
    payload,
});