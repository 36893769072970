import * as types from "./actionTypes";
import { addressState } from "@/types/states";

const initialState = addressState;

const address = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_REGIONS:
            state = {
                ...state,
                isLoading: true,
            };
            break;
        case types.GET_REGIONS_SUCCESS:
            state = {
                ...state,
                regions: action.payload,
                isLoading: false,
            };
            break;
        case types.GET_REGIONS_ERROR:
            state = {
                ...state,
                errors: action.payload,
                isLoading: false,
            };
            break;
        case types.GET_PROVINCES:
            state = {
                ...state,
                isLoading: true,
            };
            break;
        case types.GET_PROVINCES_SUCCESS:
            state = {
                ...state,
                provinces: action.payload,
                isLoading: false,
            };
            break;
        case types.GET_PROVINCES_ERROR:
            state = {
                ...state,
                errors: action.payload,
                isLoading: false,
            };
            break;
        case types.GET_CITIES:
            state = {
                ...state,
                isLoading: true,
            };
            break;
        case types.GET_CITIES_SUCCESS:
            state = {
                ...state,
                cities: action.payload,
                isLoading: false,
            };
            break;
        case types.GET_CITIES_ERROR:
            state = {
                ...state,
                errors: action.payload,
                isLoading: false,
            };
            break;
        case types.GET_BARANGAYS:
            state = {
                ...state,
                isLoading: true,
            };
            break;
        case types.GET_BARANGAYS_SUCCESS:
            state = {
                ...state,
                barangays: action.payload,
                isLoading: false,
            };
            break;
        case types.GET_BARANGAYS_ERROR:
            state = {
                ...state,
                errors: action.payload,
                isLoading: false,
            };
            break;
        case types.RESET_ADDRESSES_DATA:
            state = addressState;
            break;
        case types.GET_COUNTRIES:
            state = {
                ...state,
                isLoading: true,
            }
            break;
        case types.GET_COUNTRIES_SUCCESS:
            state = {
                ...state,
                countries: action.payload,
                isLoading: false
            }
            break;
        case types.GET_COUNTRIES_FAILED:
            state = {
                ...state,
                isLoading: false
            }
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default address;
