import * as types from "./actionTypes";

export const getDashboardAnalyticsMainTotal = (payload) => ({
    type: types.GET_DASHBOARD_ANALYTICS_MAIN_TOTAL,
    payload,
});

export const onGetDashboardAnalyticsMainTotalSuccess = (payload) => ({
    type: types.GET_DASHBOARD_ANALYTICS_MAIN_TOTAL_SUCCESS,
    payload,
});

export const onGetDashboardAnalyticsMainTotalFailed = () => ({
    type: types.GET_DASHBOARD_ANALYTICS_MAIN_TOTAL_FAILED,
});

export const getDashboardAnalyticsOverallTotal = (payload) => ({
    type: types.GET_DASHBOARD_ANALYTICS_OVERALL_TOTAL,
    payload,
});

export const onGetDashboardAnalyticsOverallTotalSuccess = (payload) => ({
    type: types.GET_DASHBOARD_ANALYTICS_OVERALL_TOTAL_SUCCESS,
    payload,
});

export const onGetDashboardAnalyticsOverallTotalFailed = () => ({
    type: types.GET_DASHBOARD_ANALYTICS_OVERALL_TOTAL_FAILED,
});

export const getDailyTotalGgrSummary = (payload) => ({
    type: types.GET_DAILY_TOTAL_GGR_SUMMARY,
    payload,
});

export const onGetDailyTotalGgrSummarySuccess = (payload) => ({
    type: types.GET_DAILY_TOTAL_GGR_SUMMARY_SUCCESS,
    payload,
});

export const onGetDailyTotalGgrSummaryFailed = () => ({
    type: types.GET_DAILY_TOTAL_GGR_SUMMARY_FAILED,
});

export const getDailyTotalDepositAndWithdrawalSummary = (payload) => ({
    type: types.GET_DAILY_TOTAL_DEPOSIT_AND_WITHDRAWAL_SUMMARY,
    payload,
});

export const onGetDailyTotalDepositAndWithdrawalSummarySuccess = (payload) => ({
    type: types.GET_DAILY_TOTAL_DEPOSIT_AND_WITHDRAWAL_SUMMARY_SUCCESS,
    payload,
});

export const onGetDailyTotalDepositAndWithdrawalSummaryFailed = () => ({
    type: types.GET_DAILY_TOTAL_DEPOSIT_AND_WITHDRAWAL_SUMMARY_FAILED,
});

export const getMonthlyTotalPlayerSummary = (payload) => ({
    type: types.GET_MONTHLY_TOTAL_PLAYER_SUMMARY,
    payload,
});

export const onGetMonthlyTotalPlayerSummarySuccess = (payload) => ({
    type: types.GET_MONTHLY_TOTAL_PLAYER_SUMMARY_SUCCESS,
    payload,
});

export const onGetMonthlyTotalPlayerSummaryFailed = () => ({
    type: types.GET_MONTHLY_TOTAL_PLAYER_SUMMARY_FAILED,
});
