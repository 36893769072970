import {
    AmountFormat,
    DisplayText,
    PageLoader,
    StatusBadge,
    TextLink,
    Title,
} from "@/components/atoms";
import BreadCrumb from "@/components/common/BreadCrumb";
import {
    Routes,
    getPath,
    getPathTitle,
    balanceCategoryLabel,
} from "@/constants";
import { roleBaseSettings, userRole } from "@/hooks";
import { Error404 } from "@/pages";
import { getRequestInfo, updateRequestStatus } from "@/store/actions";
import {
    defaultDateTimeFormat,
    getUserFullname,
    getUserProfileLink,
} from "@/utils";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Col,
    Container,
    Row,
} from "reactstrap";
import Swal from "sweetalert2";

const ForReleaseView = () => {
    const dispatch = useDispatch();
    const { transactionRequestNo } = useParams();
    const { selected: data, isLoading } = useSelector((state) => state.request);
    const { user } = useSelector((state) => state.user);
    const {isSuperAdmin} = userRole();

    const canCancelWithdrawalRequest = isSuperAdmin ? true : roleBaseSettings(
        "can_cancel_withdrawal_request"
    );

    const canApproveWithdrawalRequest =
        user.role.slug === "operator" || isSuperAdmin
            ? true
            : roleBaseSettings("can_approve_withdrawal_request");

    const confirmHandler = (type) => {
        Swal.fire({
            input: "textarea",
            inputLabel: `${
                type === "complete"
                    ? "Approved Request"
                    : type === "for-release" && (user.role.slug === "operator" || isSuperAdmin)
                    ? "For Release Request"
                    : "Cancel Request"
            }`,
            inputPlaceholder: ["complete", "for-release"].includes(type)
                ? "Put your remarks here..."
                : "Cancellation reason...",
            showCancelButton: true,
            confirmButtonText: "Submit",
            reverseButtons: true,
            preConfirm: (value) => {
                if (!value && type === "cancel") {
                    Swal.showValidationMessage(
                        '<i class="fa fa-info-circle"></i> Cancellation reason is required'
                    );
                }
            },
        }).then((result) => {
            if (result.isConfirmed) {
                let payload = {};
                if (type === "cancel") {
                    payload = {
                        cancellation_reason: result.value,
                    };
                } else {
                    payload = {
                        remarks: result.value,
                    };
                }

                dispatch(updateRequestStatus(payload, type, data.key_token));
            }
        });
    };

    const userType = data?.user?.role?.slug;

    useEffect(() => {
        dispatch(getRequestInfo(transactionRequestNo));
    }, [dispatch, transactionRequestNo]);

    if (isLoading) {
        return <PageLoader />;
    } else {
        return (
            <React.Fragment>
                {Object.keys(data).length > 0 ? (
                    <>
                        <Title title={getPathTitle(Routes.FOR_RELEASE)} />
                        <div className="page-content">
                            <Container fluid>
                                <BreadCrumb
                                    parent={{
                                        isParent: true,
                                        pageTitle: getPathTitle(
                                            Routes.FOR_RELEASE
                                        ),
                                        pageLink: getPath(Routes.FOR_RELEASE),
                                    }}
                                    childs={[
                                        {
                                            pageTitle: transactionRequestNo,
                                            pageLink: getPath(
                                                Routes.FOR_RELEASE_VIEW
                                            ),
                                            isActive: true,
                                        },
                                    ]}
                                />
                                <Row>
                                    <Col md={7}>
                                        <Card>
                                            <CardHeader className="align-items-center d-flex">
                                                <h4 className="card-title mb-0 flex-grow-1">
                                                    Withdrawal Request No:{" "}
                                                    {transactionRequestNo}
                                                </h4>
                                            </CardHeader>
                                            <CardBody>
                                                <Row className="mb-2">
                                                    <Col md="4">
                                                        <DisplayText
                                                            label="Amount"
                                                            item={
                                                                <AmountFormat
                                                                    amount={
                                                                        data.amount
                                                                    }
                                                                />
                                                            }
                                                            isLoading={
                                                                isLoading
                                                            }
                                                            col="8"
                                                        />
                                                    </Col>
                                                    <Col md="4">
                                                        <DisplayText
                                                            label="Status"
                                                            item={
                                                                <StatusBadge
                                                                    status={
                                                                        data.status
                                                                    }
                                                                    type="request"
                                                                />
                                                            }
                                                            isLoading={
                                                                isLoading
                                                            }
                                                            col="8"
                                                        />
                                                    </Col>
                                                    <Col md="4">
                                                        <DisplayText
                                                            label="Remarks"
                                                            item={
                                                                data?.remarks
                                                                    ? data?.remarks
                                                                    : "-"
                                                            }
                                                            isLoading={
                                                                isLoading
                                                            }
                                                            col="8"
                                                        />
                                                    </Col>
                                                </Row>
                                                <p>Bank/E-wallet Details</p>
                                                <Row className="mb-2">
                                                    <Col md="4">
                                                        <DisplayText
                                                            label="Provider Name"
                                                            item={
                                                                data
                                                                    .payment_method
                                                                    ?.name
                                                            }
                                                            isLoading={
                                                                isLoading
                                                            }
                                                            col="8"
                                                        />
                                                    </Col>
                                                    <Col md="4">
                                                        <DisplayText
                                                            label="Account Name"
                                                            item={
                                                                data.account_name
                                                            }
                                                            isLoading={
                                                                isLoading
                                                            }
                                                            col="8"
                                                        />
                                                    </Col>
                                                    <Col md="4">
                                                        <DisplayText
                                                            label="Account No."
                                                            item={
                                                                data.payment_account_no
                                                            }
                                                            isLoading={
                                                                isLoading
                                                            }
                                                            col="8"
                                                        />
                                                    </Col>
                                                </Row>
                                                {data?.status ===
                                                    "Cancelled" && (
                                                    <Row>
                                                        <Col md="12">
                                                            <DisplayText
                                                                label="Cancellation Reason"
                                                                item={
                                                                    data?.cancellation_reason
                                                                }
                                                                isLoading={
                                                                    isLoading
                                                                }
                                                                col="8"
                                                            />
                                                        </Col>
                                                    </Row>
                                                )}
                                                {!Array.isArray(
                                                    data?.transaction
                                                ) &&
                                                    data.status !==
                                                        "Pending" && (
                                                        <Row className="mb-2">
                                                            <Col md="12">
                                                                <DisplayText
                                                                    label="Transaction No."
                                                                    item={
                                                                        <TextLink
                                                                            text={
                                                                                data
                                                                                    ?.transaction
                                                                                    ?.transaction_no
                                                                            }
                                                                            path={getPath(
                                                                                Routes.TRANSACTION_VIEW
                                                                            ).replace(
                                                                                ":transactionNo",
                                                                                data
                                                                                    ?.transaction
                                                                                    ?.transaction_no
                                                                            )}
                                                                        />
                                                                    }
                                                                    isLoading={
                                                                        isLoading
                                                                    }
                                                                    col="4"
                                                                />
                                                            </Col>
                                                        </Row>
                                                    )}
                                            </CardBody>
                                            {[
                                                "Pending",
                                                "For Release",
                                            ].includes(data?.status) &&
                                                (canCancelWithdrawalRequest ||
                                                    canApproveWithdrawalRequest) && (
                                                    <>
                                                        {isSuperAdmin || (userType ===
                                                            "agent" &&
                                                            user.role.slug ===
                                                                "master-agent") ||
                                                        (userType ===
                                                            "player" &&
                                                            user.role.slug ===
                                                                "agent") ||
                                                        (userType ===
                                                            "player" &&
                                                            user.role.slug ===
                                                                "master-agent") ||
                                                        user.role.slug ===
                                                            "operator" ? (
                                                            <CardFooter>
                                                                <Row className="text-end">
                                                                    <Col>
                                                                        {canCancelWithdrawalRequest && (
                                                                            <Button
                                                                                color="danger"
                                                                                className="me-2"
                                                                                onClick={() =>
                                                                                    confirmHandler(
                                                                                        "cancel"
                                                                                    )
                                                                                }
                                                                            >
                                                                                Cancel
                                                                            </Button>
                                                                        )}
                                                                        {data
                                                                            ?.user
                                                                            ?.user_profile
                                                                            ?.account_no !==
                                                                            user?.accountNo &&
                                                                            canApproveWithdrawalRequest &&
                                                                            data?.status ===
                                                                                "Pending" && (
                                                                                <Button
                                                                                    color="success"
                                                                                    onClick={() =>
                                                                                        confirmHandler(
                                                                                            [
                                                                                                "merchant",
                                                                                                "commission_merchant",
                                                                                                "commission_merchant_sports",
                                                                                                "commission_merchant_player"
                                                                                            ].includes(
                                                                                                data?.balance_category
                                                                                            ) &&
                                                                                                (user
                                                                                                    .role
                                                                                                    .slug ===
                                                                                                    "operator" || isSuperAdmin)
                                                                                                ? "for-release"
                                                                                                : "complete"
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    Approve
                                                                                </Button>
                                                                            )}
                                                                    </Col>
                                                                </Row>
                                                            </CardFooter>
                                                        ) : null}
                                                    </>
                                                )}
                                        </Card>
                                    </Col>
                                    <Col md={5}>
                                        <Card>
                                            <CardHeader className="align-items-center d-flex">
                                                <h4 className="card-title mb-0 flex-grow-1">
                                                    Details
                                                </h4>
                                            </CardHeader>
                                            <CardBody>
                                                <Row>
                                                    <Col md="6">
                                                        <DisplayText
                                                            label={`Current ${balanceCategoryLabel(
                                                                data?.balance_category
                                                            )}`}
                                                            item={
                                                                <AmountFormat
                                                                    amount={
                                                                        data
                                                                            ?.user
                                                                            ?.user_profile
                                                                            ?.balance
                                                                            ?.fiat
                                                                            ?.php[
                                                                            data
                                                                                ?.balance_category
                                                                        ] ?? 0
                                                                    }
                                                                />
                                                            }
                                                            isLoading={
                                                                isLoading
                                                            }
                                                            col="8"
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col md="6">
                                                        <DisplayText
                                                            label="Requested By"
                                                            item={
                                                                <TextLink
                                                                    text={getUserFullname(
                                                                        data?.user
                                                                    )}
                                                                    path={getUserProfileLink(
                                                                        data?.user
                                                                    )}
                                                                />
                                                            }
                                                            isLoading={
                                                                isLoading
                                                            }
                                                            col="8"
                                                        />
                                                    </Col>
                                                    <Col md="6">
                                                        <DisplayText
                                                            label="Date Requested"
                                                            item={defaultDateTimeFormat(
                                                                data.created_at
                                                            )}
                                                            isLoading={
                                                                isLoading
                                                            }
                                                            col="8"
                                                        />
                                                    </Col>
                                                </Row>
                                                {data?.status ===
                                                    "Cancelled" && (
                                                    <Row>
                                                        <Col md="6">
                                                            <DisplayText
                                                                label="Cancelled By"
                                                                item={
                                                                    <TextLink
                                                                        text={getUserFullname(
                                                                            data?.cancelled_by
                                                                        )}
                                                                        path={getUserProfileLink(
                                                                            data?.cancelled_by
                                                                        )}
                                                                    />
                                                                }
                                                                isLoading={
                                                                    isLoading
                                                                }
                                                                col="8"
                                                            />
                                                        </Col>
                                                        <Col md="6">
                                                            <DisplayText
                                                                label="Date Cancelled"
                                                                item={defaultDateTimeFormat(
                                                                    data.cancelled_at
                                                                )}
                                                                isLoading={
                                                                    isLoading
                                                                }
                                                                col="8"
                                                            />
                                                        </Col>
                                                    </Row>
                                                )}
                                                {data?.status ===
                                                    "Completed" && (
                                                    <Row>
                                                        <Col md="6">
                                                            <DisplayText
                                                                label="Approved By"
                                                                item={
                                                                    <TextLink
                                                                        text={getUserFullname(
                                                                            data?.completed_by
                                                                        )}
                                                                        path={getUserProfileLink(
                                                                            data?.completed_by
                                                                        )}
                                                                    />
                                                                }
                                                                isLoading={
                                                                    isLoading
                                                                }
                                                                col="8"
                                                            />
                                                        </Col>
                                                        <Col md="6">
                                                            <DisplayText
                                                                label="Date Approved"
                                                                item={defaultDateTimeFormat(
                                                                    data.completed_at
                                                                )}
                                                                isLoading={
                                                                    isLoading
                                                                }
                                                                col="8"
                                                            />
                                                        </Col>
                                                    </Row>
                                                )}
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </>
                ) : (
                    <Error404
                        title="Sorry, Data not Found"
                        subTitle="The data you are looking for is not available!"
                        returnPath={Routes.FOR_RELEASE}
                    />
                )}
            </React.Fragment>
        );
    }
};

export default ForReleaseView;
