import { Title } from "@/components/atoms";
import { SendAnnouncementForm } from "@/components/molecules";
import BreadCrumb from "@/components/common/BreadCrumb";
import { Routes, getPath, getPathTitle } from "@/constants";
import { getOperator } from "@/store/actions";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";

const Send = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getOperator());
    }, [dispatch]);
  return (
    <React.Fragment>
      <Title title={getPathTitle(Routes.SEND_ANNOUNCEMENT)} />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            parent={{
              isParent: true,
              pageTitle: getPathTitle(Routes.SEND_ANNOUNCEMENT),
              pageLink: getPath(Routes.SEND_ANNOUNCEMENT),
            }}
          />
          <Row>
            <Col>
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    {getPathTitle(Routes.SEND_ANNOUNCEMENT)}
                  </h4>
                  <div className="flex-shrink-0">Please Fill up all fields</div>
                </CardHeader>
                <CardBody>
                    <SendAnnouncementForm />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Send;
