import {
    loadingToast,
    removeToast,
    successToast,
    errorToast,
} from "@/components/constants/toast";
import * as API from "@/network/apis/hot-match-setting";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import * as TYPES from "./actionTypes";
import * as ACTIONS from "./actions";
import { convert2dErrorsArray } from "@/utils";

function* getSportTypeList({ payload }) {
    try {
        const response = yield call(API.getSportTypeList, payload);

        if (response.status === 200) {
            yield put(ACTIONS.setSportTypeList(response.data.response));
        }
    } catch (error) {
        yield put(ACTIONS.setSportTypeListError());
        if (error?.response?.data?.message) {
            errorToast(error.response.data.message);
        }
    }
}

function* updateSportTypeStatus({ keyToken, initialize }) {
    try {
        const response = yield call(API.updateSportTypeStatus, keyToken);

        if (response.status === 200) {
            yield put(ACTIONS.updateSportTypeStatusSuccess());
            initialize();

            removeToast();
            successToast("Successfully Updated.");
        }
    } catch (error) {
        yield put(ACTIONS.updateSportTypeStatusError());
        if (error?.response?.data?.message) {
            errorToast(error.response.data.message);
        }
    }
}

function* updateSportTypeUseChildFilter({ keyToken, payload, initialize }) {
    try {
        const response = yield call(
            API.updateSportTypeUseChildFilter,
            keyToken,
            payload
        );

        if (response.status === 200) {
            yield put(ACTIONS.updateSportTypeStatusSuccess());
            initialize();

            removeToast();
            successToast("Successfully Updated.");
        }
    } catch (error) {
        yield put(ACTIONS.updateSportTypeStatusError());
        if (error?.response?.data?.message) {
            errorToast(error.response.data.message);
        }
    }
}

function* getLeagueTypeList({ payload }) {
    try {
        const response = yield call(API.getLeagueTypeList, payload);

        if (response.status === 200) {
            yield put(ACTIONS.setLeagueTypeList(response.data.response));
        }
    } catch (error) {
        yield put(ACTIONS.setLeagueTypeListError());
        if (error?.response?.data?.message) {
            errorToast(error.response.data.message);
        }
    }
}

function* updateLeagueTypeStatus({ keyToken, initialize }) {
    try {
        const response = yield call(API.updateLeagueTypeStatus, keyToken);

        if (response.status === 200) {
            yield put(ACTIONS.updateLeagueTypeStatusSuccess());
            initialize();

            removeToast();
            successToast("Successfully Updated.");
        }
    } catch (error) {
        yield put(ACTIONS.updateLeagueTypeStatusError());
        if (error?.response?.data?.message) {
            errorToast(error.response.data.message);
        }
    }
}

function* getGameTypeList({ payload }) {
    try {
        const response = yield call(API.getGameTypeList, payload);

        if (response.status === 200) {
            yield put(ACTIONS.setGameTypeList(response.data.response));
        }
    } catch (error) {
        yield put(ACTIONS.setGameTypeListError());
        if (error?.response?.data?.message) {
            errorToast(error.response.data.message);
        }
    }
}

function* updateGameTypeStatus({ keyToken, initialize }) {
    try {
        const response = yield call(API.updateGameTypeStatus, keyToken);

        if (response.status === 200) {
            yield put(ACTIONS.updateGameTypeStatusSuccess());
            initialize();

            removeToast();
            successToast("Successfully Updated.");
        }
    } catch (error) {
        yield put(ACTIONS.updateGameTypeStatusError());
        if (error?.response?.data?.message) {
            errorToast(error.response.data.message);
        }
    }
}

function* getTeamList({ payload }) {
    try {
        const response = yield call(API.getTeamList, payload);

        if (response.status === 200) {
            yield put(ACTIONS.setTeamList(response.data.response));
        }
    } catch (error) {
        yield put(ACTIONS.setTeamListError());
        if (error?.response?.data?.message) {
            errorToast(error.response.data.message);
        }
    }
}

function* uploadTeamPhoto({ keyToken, payload, initialize }) {
    try {
        const response = yield call(API.uploadTeamPhoto, keyToken, payload);

        if (response.status === 200) {
            yield put(ACTIONS.uploadTeamPhotoSuccess());
            initialize();

            removeToast();
            successToast("Successfully Updated.");
        }
    } catch (error) {
        yield put(ACTIONS.uploadTeamPhotoError());
        if (error?.response?.data?.message) {
            errorToast(error.response.data.message);
        }
    }
}

function* hotMatchManualPull({ initialize }) {
    try {
        const response = yield call(API.hotMatchManualPull);

        if (response.status === 200) {
            yield put(ACTIONS.hotMatchManualPullSuccess());
            initialize();
            removeToast();
            successToast("Hot matches pulling successfully queued.");
        }
    } catch (error) {
        yield put(ACTIONS.hotMatchManualPullError());
        if (error?.response?.data?.message) {
            errorToast(error.response.data.message);
        }
    }
}

function* getHotMatchList({ payload }) {
    try {
        const response = yield call(API.getHotMatchList, payload);

        if (response.status === 200) {
            yield put(ACTIONS.setHotMatchList(response.data.response));
        }
    } catch (error) {
        yield put(ACTIONS.setHotMatchListError());
        if (error?.response?.data?.message) {
            errorToast(error.response.data.message);
        }
    }
}

function* updateHotMatchPostStatus({ keyToken, initialize }) {
    try {
        const response = yield call(API.updateHotMatchPostStatus, keyToken);

        if (response.status === 200) {
            yield put(ACTIONS.updateHotMatchPostStatusSuccess());
            initialize();

            removeToast();
            successToast("Successfully Updated.");
        }
    } catch (error) {
        yield put(ACTIONS.updateHotMatchPostStatusError());
        if (error?.response?.data?.message) {
            errorToast(error.response.data.message);
        }
    }
}

function* watchGetSportTypeList() {
    yield takeLatest(TYPES.GET_SPORT_TYPE_LIST, getSportTypeList);
}

function* watchUpdateSportTypeStatus() {
    yield takeLatest(TYPES.UPDATE_SPORT_TYPE_STATUS, updateSportTypeStatus);
}

function* watchUpdateSportTypeUseChildFilter() {
    yield takeLatest(
        TYPES.UPDATE_SPORT_TYPE_USE_CHILD_FILTER,
        updateSportTypeUseChildFilter
    );
}

function* watchGetLeagueTypeList() {
    yield takeLatest(TYPES.GET_LEAGUE_TYPE_LIST, getLeagueTypeList);
}

function* watchUpdateLeagueTypeStatus() {
    yield takeLatest(TYPES.UPDATE_LEAGUE_TYPE_STATUS, updateLeagueTypeStatus);
}

function* watchGetGameTypeList() {
    yield takeLatest(TYPES.GET_GAME_TYPE_LIST, getGameTypeList);
}

function* watchUpdateGameTypeStatus() {
    yield takeLatest(TYPES.UPDATE_GAME_TYPE_STATUS, updateGameTypeStatus);
}

function* watchGetTeamList() {
    yield takeLatest(TYPES.GET_TEAM_LIST, getTeamList);
}

function* watchUploadTeamPhoto() {
    yield takeLatest(TYPES.UPLOAD_TEAM_PHOTO, uploadTeamPhoto);
}

function* watchGetHotMatchList() {
    yield takeLatest(TYPES.GET_HOT_MATCH_LIST, getHotMatchList);
}

function* watchHotMatchManualPull() {
    yield takeLatest(TYPES.HOT_MATCH_MANUAL_PULL, hotMatchManualPull);
}

function* watchUpdateHotMatchPostStatus() {
    yield takeLatest(
        TYPES.UPDATE_HOT_MATCH_POST_STATUS,
        updateHotMatchPostStatus
    );
}

function* hotMatchesSettingSagas() {
    yield all([
        fork(watchGetSportTypeList),
        fork(watchUpdateSportTypeStatus),
        fork(watchUpdateSportTypeUseChildFilter),
        fork(watchGetLeagueTypeList),
        fork(watchUpdateLeagueTypeStatus),
        fork(watchGetGameTypeList),
        fork(watchUpdateGameTypeStatus),
        fork(watchGetHotMatchList),
        fork(watchUpdateHotMatchPostStatus),
        fork(watchGetTeamList),
        fork(watchUploadTeamPhoto),
        fork(watchHotMatchManualPull),
    ]);
}

export default hotMatchesSettingSagas;
