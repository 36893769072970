import { errorToast, successToast } from "@/components/constants/toast";
import { format } from "date-fns";
import moment from "moment";

export const convert2dErrorsArray = (array2d) => {
    let result = {};

    for (var key in array2d) {
        result = { ...result, [key]: array2d[key][0] };
    }
    return result;
};

export const filterError = (error, validation = null) => {
    if (!Array.isArray(error?.response?.data?.response)) {
        validation?.setErrors(error?.response?.data?.response);
        return;
    }
    return [error?.response?.data?.message];
};

export const defaultDateFormat = (date) => {
    return date ? format(Date.parse(date), "MMM dd, yyyy") : "-";
};

export const filterDateFormat = (date) => {
    return format(Date.parse(date), "yyyy-MM-dd");
};

export const filterDateTimeFormat = (date) => {
    return format(Date.parse(date), "yyyy-MM-dd HH:mm:ss");
};

export const today = () => {
    return format(Date.parse(new Date()), "yyyy-MM-dd");
};

export const defaultDateTimeFormat = (datetime) => {
    return datetime
        ? format(Date.parse(datetime), "MMM dd, yyyy hh:mm:ss a")
        : "-";
};

export const defaultCurrency = () => {
    return "₱";
};

export const numberFormat = (num = 0, float = true) => {
    if (float) {
        return Number(num)
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return Number(num)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const getUserFullname = (user, withAccountNo = true) => {
    let name = user?.user_profile?.first_name
        ? `${user?.user_profile?.first_name} ${user?.user_profile?.last_name}`
        : user?.name;
    name += withAccountNo ? ` | ${user?.user_profile?.account_no}` : "";
    return name;
};

export const copyText = (text) => {
    if (typeof navigator.clipboard === "undefined") {
        var textArea = document.createElement("textarea");
        textArea.value = text;
        textArea.style.position = "fixed";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            var successful = document.execCommand("copy");
            var msg = successful ? "Copied to Clipboard!" : "Copy failed!";
            successToast(msg);
        } catch (err) {
            errorToast("Was not possible to copy te text: ", err);
        }

        document.body.removeChild(textArea);
        return;
    }
    navigator.clipboard.writeText(text).then(
        function () {
            successToast("Copied to Clipboard!");
        },
        function (err) {
            errorToast("Copy failed!");
        }
    );
};

export const getStatusColor = (status) => {
    switch (status) {
        case "win":
        case "success":
            return "success";
        case "loss":
        case "cancelled":
        case "failed":
            return "danger";
        default:
            return "primary";
    }
};

export const arrayToString = (array, separator = "|") => {
    let data = array.map((item) => item).join(separator);
    return data;
};

export const capitalizeFirstLetter = (text) => {
    return text
        ? text
              ?.split("_")
              ?.map((value) => value.charAt(0).toUpperCase() + value.slice(1))
              ?.join(" ")
        : "-";
};

export const titleCase = (s) =>
    s.replace(/^_*(.)|_+(.)/g, (s, c, d) =>
        c ? c.toUpperCase() : " " + d.toUpperCase()
    );

// export const isActionViewable = (role, setting, permission) => {
//     let data = true;
//     if (role !== "master-admin") {
//         if (role === "admin") {
//             data = permission ? true : false;
//         } else if (role === "master-agent") {
//             data = setting ? true : false;
//         } else if (role === "agent") {
//             data = setting ? true : false;
//         }
//     }

//     return data;
// };

export const toFormData = (params) => {
    let bodyFormData = new FormData();

    Object.keys(params).forEach(key => {
        bodyFormData.append(key, params?.[key] ?? null);
    });

    return bodyFormData;
}

export const cleanFromFile = (values, fields) =>{
    let data = Object.assign({}, values);
    fields.forEach((key) => {
        if (typeof data[key] === "string") {
          delete data[key];
        }
      });

      return data;
};

export const getDateDiffs = (dateFrom, dateTo) => moment(dateTo).diff(moment(dateFrom), 'days')+1
