import PinInput from "react-pin-input";
import { Label } from "reactstrap";

const InputPinCode = ({ label, validation, name, length }) => {
    return (
        <>
            <Label>{label}</Label>
            <PinInput
                length={length}
                initialValue={validation?.values?.[name] || ""}
                secret
                secretDelay={20}
                onChange={(value) => validation?.setFieldValue(name, value)}
                type="numeric"
                inputMode="number"
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                }}
                inputStyle={{
                    borderColor:
                        validation?.touched?.[name] &&
                        validation?.errors?.[name]
                            ? "red"
                            : "var(--vz-input-border)",
                    backgroundColor: "var(--vz-input-bg)",
                    color: "var(--vz-body-color)",
                    borderRadius: "3px",
                    width: 40,
                    height: 40,
                    fontSize: "20px",
                }}
                inputFocusStyle={{
                    boxShadow: `0 0 0 1.8px var(--vz-body-color)`,
                }}
                onComplete={(value) => validation?.setFieldValue(name, value)}
                autoSelect={true}
                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
            />
            {validation.touched[name] && validation.errors[name] ? (
                <small className="text-danger text-sm">
                    {validation.errors[name]}
                </small>
            ) : null}
        </>
    );
};

export default InputPinCode;
