import {
    AmountFormat,
    CounterWidget,
    StatusBadge,
    Table,
    Title,
} from "@/components/atoms";
import BreadCrumb from "@/components/common/BreadCrumb";
import { Routes, getPath, getPathTitle } from "@/constants";
import { walletTransactionCounter } from "@/constants/users";
import { useUserTypeOptions } from "@/hooks";
import { bulkRelease, getRequestData } from "@/store/actions";
import { capitalizeFirstLetter, defaultDateTimeFormat } from "@/utils";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    Input,
    Row,
    CardHeader,
} from "reactstrap";
import Swal from "sweetalert2";
import {
    userRole
} from "@/hooks"
const ForReleaseList = () => {
    const isMounted = useRef(false);
    const dispatch = useDispatch();
    const {isSuperAdmin} = userRole();
    const { list, isLoading, isBulkReleaseSuccess } = useSelector(
        (state) => state.request
    );

    const { user } = useSelector((state) => state.user);

    const balanceCategory = [
        {
            label: "Pending",
            value: 1,
        },
        {
            label: "For Release",
            value: 4,
        },
    ];

    const isAdmin =
        user?.role.slug === "super-admin" || user?.role.slug === "admin"
            ? true
            : false;

    const isOperator = user.role.slug === "operator";

    const [search, setSearch] = useState("");
    const [sortBy, setSortBy] = useState({
        field: "",
        type: "asc",
    });

    const defaultParams = {
        account_no: "",
        per_page: 10,
        page: 1,
        search: "",
        sort_by:
            sortBy.field && sortBy.type ? `${sortBy.field} ${sortBy.type}` : "",
        "date_range[transaction_requests.created_at][from]": "",
        "date_range[transaction_requests.created_at][to]": "",
        "pending_for_release": 1,
        "filter_by[transaction_requests.transaction_request_status_id]": 1,
    };

    const [data, setData] = useState(defaultParams);
    const withdrawalCounter = walletTransactionCounter(list);

    const confirmHandler = () => {
        Swal.fire({
            input: "textarea",
            inputLabel: `Bulk Release`,
            inputPlaceholder: "Put your remarks here...",
            showCancelButton: true,
            confirmButtonText: "Submit",
            reverseButtons: true,
        }).then((result) => {
            if (result.isConfirmed) {
                setSelectedRow([]);
                dispatch(
                    bulkRelease({
                        remarks: result.value,
                        transaction_requests: selectedRow,
                    })
                );
            }
        });
    };

    const [selectedRow, setSelectedRow] = useState([]);

    const tableTheadData = useMemo(() => {
        const handleToggle = (e) => {
            const data = e.target.value;
            const isChecked = e.target.checked;
            let rows = selectedRow;

            if (isChecked) {
                setSelectedRow(() => [...rows, data]);
            } else {
                setSelectedRow(() => rows.filter((item) => item !== data));
            }
        };

        let columns = [];
        if (
            (isOperator || isSuperAdmin) &&
            data[
                "filter_by[transaction_requests.transaction_request_status_id]"
            ] !== 4
        ) {
            columns = [
                {
                    field: "key_token",
                    sortable: false,
                    render: (item) => (
                        <Input
                            className="form-check-input"
                            type="checkbox"
                            id="inlinCheckbox2"
                            value={item.key_token}
                            onChange={handleToggle}
                            checked={selectedRow.includes(item.key_token)}
                        />
                    ),
                },
            ];
        }

        columns = [
            ...columns,
            {
                label: "Request No.",
                field: "transaction_request_no",
                sortable: true,
            },
            {
                label: "Account No.",
                field: "account_no",
                sortable: true,
            },
            {
                label: "Balance Category",
                field: "balance_category",
                sortable: false,
                render: (item) => capitalizeFirstLetter(item.balance_category),
            },
            {
                label: "Fee %",
                field: "fee_percent",
                sortable: true,
                render: (item) => item.fee_percent,
            },
            {
                label: "Fee Amount",
                field: "fee_amount",
                sortable: true,
                render: (item) => <AmountFormat amount={item.fee_amount} />,
            },
            {
                label: "Amount",
                field: "amount",
                sortable: true,
                render: (item) => <AmountFormat amount={item.amount} />,
            },
            {
                label: "Datetime Requested",
                field: "created_at",
                sortable: true,
                render: (item) => defaultDateTimeFormat(item.created_at),
            },
            {
                label: "Status",
                field: "status",
                sortable: true,
                render: (item) => (
                    <StatusBadge status={item.status} type="request" />
                ),
            },
            {
                label: "Action",
                field: "action",
                sortable: false,
                render: (item) => (
                    <Link
                        to={getPath(Routes.FOR_RELEASE_VIEW).replace(
                            ":transactionRequestNo",
                            item?.transaction_request_no
                        )}
                        className="link-primary"
                    >
                        <Button color="success" size="sm">
                            <i className="ri-eye-line"></i>
                        </Button>
                    </Link>
                ),
            },
        ];
        return columns;
    }, [selectedRow, isOperator, data, isSuperAdmin]);

    useEffect(() => {
        dispatch(getRequestData(data, "withdrawal"));
    }, [dispatch, data]);

    useEffect(() => {
        if (isMounted.current) {
            if (isBulkReleaseSuccess) {
                setSelectedRow([]);
                dispatch(getRequestData(data, "withdrawal"));
            }
        } else {
            isMounted.current = true;
        }
    }, [dispatch, data, isBulkReleaseSuccess]);

    return (
        <React.Fragment>
            <Title title={getPathTitle(Routes.FOR_RELEASE)} />
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb
                        parent={{
                            isParent: true,
                            pageTitle: getPathTitle(Routes.FOR_RELEASE),
                            pageLink: getPath(Routes.FOR_RELEASE),
                        }}
                    />
                    <Row>
                        {withdrawalCounter.map((item, key) => (
                            <Col key={key}>
                                <CounterWidget item={item} icon="₱" />
                            </Col>
                        ))}
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardHeader className="align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">
                                        {getPathTitle(Routes.FOR_RELEASE)} List
                                    </h4>
                                    <div className="flex-shrink-0">
                                        {(isOperator || isSuperAdmin) &&
                                            data[
                                                "filter_by[transaction_requests.transaction_request_status_id]"
                                            ] !== 4 && (
                                                <div className="tw-flex tw-flex-row tw-justify-end tw-items-center gap-2">
                                                    <Button
                                                        size="sm"
                                                        color="success"
                                                        className="tw-flex flex-row tw-items-center justify-center gap-1"
                                                        disabled={
                                                            selectedRow.length ===
                                                            0
                                                        }
                                                        onClick={() =>
                                                            confirmHandler()
                                                        }
                                                    >
                                                        <span className="ri-money-dollar-circle-line" />
                                                        Bulk Payout Release
                                                    </Button>
                                                </div>
                                            )}
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Row className="mb-3">
                                        <Col>
                                            {balanceCategory.map(
                                                (item, index) => (
                                                    <Button
                                                        key={index}
                                                        color={
                                                            data[
                                                                "filter_by[transaction_requests.transaction_request_status_id]"
                                                            ] === item.value
                                                                ? "primary"
                                                                : "light"
                                                        }
                                                        onClick={() => {
                                                            setSelectedRow([]);
                                                            setData({
                                                                ...data,
                                                                "filter_by[transaction_requests.transaction_request_status_id]":
                                                                    item.value,
                                                                page: 1,
                                                            });
                                                        }}
                                                        className="me-2"
                                                    >
                                                        {item.label}
                                                    </Button>
                                                )
                                            )}
                                        </Col>
                                    </Row>
                                    <Table
                                        search={search}
                                        handlerSearch={setSearch}
                                        sortBy={sortBy}
                                        handlerSortBy={setSortBy}
                                        thead={tableTheadData}
                                        handlerChangeData={setData}
                                        data={data}
                                        collection={list}
                                        isLoading={isLoading}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ForReleaseList;
