import { Button, InputFile, TextArea } from "@/components/atoms";
import { importPayoutValidation } from "@/constants";
import { importPayout } from "@/store/actions";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button as ButtonComponent,
    Col,
    Form,
    FormGroup,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
} from "reactstrap";

const ImportPayoutModal = ({ isOpen, setIsOpen, paymentProvider }) => {
    const dispatch = useDispatch();
    const { isImportPayoutLoading, errors } = useSelector(
        (state) => state.report
    );

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            payout_result: "",
        },
        validationSchema: importPayoutValidation(),
        onSubmit: (values) => {
            const formData = new FormData();
            for (var key in values) {
                formData.append(key, values[key]);
            }
            
            formData.append('payment_provider', paymentProvider);

            dispatch(importPayout(formData, setIsOpen));
        },
    });

    const { setErrors } = validation;

    useEffect(() => {
        if (errors) {
            setErrors(errors);
        }
    }, [errors, setErrors]);

    return (
        <Modal id="fundModal" isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
            <ModalHeader>
                <div className="modal-title tw-capitalize">Import Payout Report ({paymentProvider})</div>
            </ModalHeader>
            <ModalBody>
                <Form
                    className="needs-validation"
                    onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}>
                    <Row className="mb-3">
                        <Col md={12}>
                            <FormGroup className="mb-3">
                                <InputFile
                                    label="File"
                                    name="payout_result"
                                    validation={validation}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-end">
                            <ButtonComponent
                                color="light"
                                onClick={() => setIsOpen(!isOpen)}
                                className="me-2">
                                Close
                            </ButtonComponent>
                            <Button
                                color="success"
                                type="submit"
                                text="Submit"
                                isLoading={isImportPayoutLoading}
                            />
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
        </Modal>
    );
};

export default ImportPayoutModal;
