import {
    EmailUpdateInput,
    MobileNoUpdateInput,
    PasswordResetButton,
    PersonalInformationForm,
    RoleUpdateSelect,
    StatusUpdateDropdown,
} from "@/components/molecules";
import React from "react";
import { useSelector } from "react-redux";
import { Col, Row } from "reactstrap";

import ProfileLayout from "../Layout";

const Profile = () => {
    const {
        user,
        isLoading: isLoadingUser,
        errors: userErrors,
    } = useSelector((state) => state.user);

    const isAdmin =
        user?.role.slug === "super-admin" || user?.role.slug === "admin"
            ? true
            : false;

    const { isLoading, errors, profile } = useSelector((state) => state.admin);

    return (
        <ProfileLayout>
            <Row className="mb-3">
                <Col md={12}>
                    {isAdmin && (
                        <StatusUpdateDropdown
                            profile={profile}
                            isLoading={isLoadingUser}
                        />
                    )}
                    <PasswordResetButton
                        isLoading={isLoadingUser}
                        email={profile.email}
                        userType="agent"
                    />
                </Col>
            </Row>
            <hr />
            <Row>
                <Col md="6">
                    <EmailUpdateInput
                        errors={userErrors}
                        profile={profile}
                        isAdmin={isAdmin}
                        userType="admin"
                    />
                </Col>
                <Col md="6">
                    <MobileNoUpdateInput
                        errors={userErrors}
                        profile={profile}
                        isAdmin={isAdmin}
                    />
                </Col>
            </Row>

            <Row className="mb-3">
                <Col md="6">
                    <RoleUpdateSelect profile={profile} />
                </Col>
            </Row>

            <PersonalInformationForm
                profile={profile}
                userType="admin"
                isLoading={isLoading}
                isAdmin={isAdmin}
                errors={errors}
            />
        </ProfileLayout>
    );
};

export default Profile;
