import { Select } from "@/components/atoms";
import { agentRoleTagOptions, operatorRoleTagOptions } from "@/constants";
import { updateAgentRoleTag, updateOperatorRoleTag } from "@/store/actions";
import React from "react";
import { useDispatch } from "react-redux";

const UpdateRoleTagSelect = ({
    profile,
    userType = "agent",
    disabled = false,
}) => {
    const dispatch = useDispatch();
    return (
        <Select
            disabled={disabled}
            label="Role Tag"
            value={profile?.roleTag}
            onChange={(e) => {
                console.log(e.target.value);
                dispatch(
                    userType === "agent"
                        ? updateAgentRoleTag(
                              {
                                  role_tag: e.target.value,
                              },
                              profile.keyToken
                          )
                        : updateOperatorRoleTag(
                              {
                                  role_tag: e.target.value,
                              },
                              profile.keyToken
                          )
                );
            }}
            options={
                userType === "agent"
                    ? agentRoleTagOptions
                    : operatorRoleTagOptions
            }
        />
    );
};

export default UpdateRoleTagSelect;
