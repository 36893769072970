import { Navigate, Route } from "react-router-dom";
//Layouts
import { Routes } from "@/constants";
import NonAuthLayout from "@/layouts/NonAuthLayout";
import VerticalLayout from "@/layouts/VerticalLayout";
import { getPath } from "@/utils";

const PublicRoute = (props) => {
    const isAutenticated = localStorage.getItem("accessToken");

    if (isAutenticated) {
        return (
            <Navigate
                to={{
                    pathname: getPath(Routes.DASHBOARD),
                    state: { from: props.location },
                }}
            />
        );
    }

    return (
        <>
            <NonAuthLayout>{props.children}</NonAuthLayout>
        </>
    );
};

const ProtectedRoute = (props) => {
    const accessToken = localStorage.getItem("accessToken");
    const isAutenticated = props.isAuth;

    if (!accessToken || !isAutenticated) {
        return (
            <Navigate
                to={{
                    pathname: getPath(Routes.LOGIN),
                    state: { from: props.location },
                }}
            />
        );
    }

    return (
        <>
            <VerticalLayout>{props.children}</VerticalLayout>
        </>
    );
};

const AccessRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) => {
                return (
                    <>
                        {" "}
                        <Component {...props} />{" "}
                    </>
                );
            }}
        />
    );
};

export { ProtectedRoute, PublicRoute, AccessRoute };
