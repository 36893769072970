import React from "react";

const TextPlaceholder = ({ text, col, isLoading }) => {
    return (
        <>
            <span className="placeholder-glow">
                {isLoading && (
                    <span className={`placeholder col-${col}`}></span>
                )}
                {!isLoading && <>{text}</>}
            </span>
        </>
    );
};

export default TextPlaceholder;
