import { AmountFormat, Table, TextLink } from "@/components/atoms";
import {
    DepositRequestModal,
    WithdrawalRequestModal,
} from "@/components/molecules";
import { getPath, Routes } from "@/constants";
import { getTransactionData } from "@/store/actions";
import { defaultCurrency, numberFormat, capitalizeFirstLetter } from "@/utils";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Row } from "reactstrap";
import ProfileLayout from "../Layout";
import { userRole } from "@/hooks";
import { Link } from "react-router-dom";

const Wallet = () => {
    const dispatch = useDispatch();
    const { user: profile } = useSelector((state) => state.user);
    const { list, isLoading } = useSelector((state) => state.transaction);
    const isAdmin =
        profile.role.slug === "super-admin" || profile.role.slug === "admin"
            ? true
            : false;
    const [search, setSearch] = useState("");
    const [sortBy, setSortBy] = useState({
        field: "",
        type: "asc",
    });
    const [data, setData] = useState({
        account_no: profile.accountNo,
        per_page: 10,
        page: 1,
        search: "",
        sort_by:
            sortBy.field && sortBy.type ? `${sortBy.field} ${sortBy.type}` : "",
    });

    const [depositRequestModalIsOpen, setDepositRequestModalIsOpen] =
        useState(false);
    const [withdrawalRequestModalIsOpen, setWithdrawalRequestModalIsOpen] =
        useState(false);

    const tableTheadData = [
        {
            label: "Transaction No.",
            field: "transaction_no",
            sortable: true,
            render: (item) => (
                <TextLink
                    text={item.transaction_no}
                    path={getPath(Routes.TRANSACTION_VIEW).replace(
                        ":transactionNo",
                        item?.transaction_no
                    )}
                />
            ),
        },
        {
            label: "Balance Category",
            field: "balance_category",
            sortable: false,
            render: (item) => capitalizeFirstLetter(item.balance_category),
        },
        {
            label: "Type",
            field: "type",
            sortable: false,
        },
        {
            label: "Amount",
            field: "amount",
            sortable: false,
            render: (item) => <AmountFormat amount={item.amount} />,
        },
        {
            label: "Balance Before",
            field: "balance_before",
            sortable: false,
            render: (item) => <AmountFormat amount={item.balance_before} />,
        },
        {
            label: "Balance After",
            field: "balance_after",
            sortable: false,
            render: (item) => <AmountFormat amount={item.balance_after} />,
        },
        {
            label: "Action",
            field: "action",
            sortable: false,
            render: (item) => (
                <Link
                    to={getPath(Routes.TRANSACTION_VIEW).replace(
                        ":transactionNo",
                        item?.transaction_no
                    )}
                    className="link-primary"
                >
                    <Button color="success" size="sm">
                        <i className="ri-eye-line"></i>
                    </Button>
                </Link>
            ),
        },
    ];

    const initialize = useCallback(() => {
        dispatch(getTransactionData(data));
    }, [dispatch, data]);

    useEffect(() => {
        initialize();
    }, [initialize]);

    return (
        <ProfileLayout>
            {depositRequestModalIsOpen && (
                <DepositRequestModal
                    isOpen={depositRequestModalIsOpen}
                    setIsOpen={setDepositRequestModalIsOpen}
                    userProfilekeyToken={profile.userProfilekeyToken}
                    initialize={initialize}
                />
            )}
            {withdrawalRequestModalIsOpen && (
                <WithdrawalRequestModal
                    isOpen={withdrawalRequestModalIsOpen}
                    setIsOpen={setWithdrawalRequestModalIsOpen}
                />
            )}

            <Row>
                <Col>
                    <div className="card-body bg-light rounded">
                        <Row>
                            <Col md="6">
                                <div className="d-flex align-items-center">
                                    <h1 className="display-2 text-success">
                                        {defaultCurrency()}
                                    </h1>
                                    <div className="flex-grow-1 ms-3">
                                        <h1
                                            className={
                                                parseFloat(
                                                    profile?.balance?.fiat?.php
                                                        ?.bcf
                                                ) > 0
                                                    ? "text-success"
                                                    : "text-danger"
                                            }
                                        >
                                            {numberFormat(
                                                profile?.balance?.fiat?.php
                                                    ?.bcf ?? 0
                                            )}
                                        </h1>
                                        <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                                            {process.env.REACT_APP_BCF_LABEL}
                                        </p>
                                    </div>
                                </div>
                            </Col>
                            {!isAdmin && !userRole()?.isOperator && (
                                <Col
                                    md="6"
                                    className="d-flex align-items-center justify-content-end"
                                >
                                    <Button
                                        color="success"
                                        className="me-2"
                                        onClick={() =>
                                            setDepositRequestModalIsOpen(true)
                                        }
                                    >
                                        <span className="ri-add-fill me-1 align-bottom"></span>
                                        Deposit Request
                                    </Button>
                                    <Button
                                        color="primary"
                                        onClick={() =>
                                            setWithdrawalRequestModalIsOpen(
                                                true
                                            )
                                        }
                                    >
                                        <span className="ri-add-fill me-1 align-bottom"></span>
                                        Withdrawal Request
                                    </Button>
                                </Col>
                            )}
                        </Row>
                    </div>
                </Col>
            </Row>
            <hr />
            <Row>
                <Col>
                    {!isAdmin && <h5>Transactions</h5>}
                    <Table
                        search={search}
                        handlerSearch={setSearch}
                        sortBy={sortBy}
                        handlerSortBy={setSortBy}
                        thead={tableTheadData}
                        handlerChangeData={setData}
                        data={data}
                        collection={list}
                        isLoading={isLoading}
                        size="sm"
                    />
                </Col>
            </Row>
        </ProfileLayout>
    );
};

export default Wallet;
