import {
    EmailUpdateInput,
    MobileNoUpdateInput,
    PersonalInformationForm,
    UsernameUpdateInput,
    CommissionRateUpdateInput,
} from "@/components/molecules";
import React from "react";
import { useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import { commissionRatePerGameOffering } from "@/constants";
import { getSetting } from "@/hooks";

import ProfileLayout from "../Layout";

const Profile = () => {
    const {
        user: profile,
        isLoading,
        errors,
    } = useSelector((state) => state.user);
    const isAdmin =
        profile?.role.slug === "super-admin" || profile?.role.slug === "admin"
            ? true
            : false;
    const isAgent =
        profile.role.slug === "master-agent" || profile.role.slug === "agent";

    const vtoCommissionRateEnabled =
        getSetting("enable_valid_turnover_commission_rate") === "1";

    const filteredCommissionRatePerGameOffering =
        commissionRatePerGameOffering.filter((item) => {
            if (!vtoCommissionRateEnabled) {
                return !item.value.includes("vto");
            } else {
                return true;
            }
        });

    return (
        <ProfileLayout>
            <Row className="mb-3">
                <Col md="6">
                    <UsernameUpdateInput
                        errors={errors}
                        profile={profile}
                        isAdmin={isAdmin}
                    />
                </Col>
                <Col md="6">
                    <EmailUpdateInput
                        errors={errors}
                        profile={profile}
                        isAdmin={isAdmin}
                        userType="user"
                    />
                </Col>
                <Col md="6">
                    <MobileNoUpdateInput
                        errors={errors}
                        profile={profile}
                        isAdmin={isAdmin}
                    />
                </Col>
                <Col md="6" />
                {/* {isAgent &&
                    filteredCommissionRatePerGameOffering.map((item, index) => {
                        if (item.balanceCategory === "merchant") {
                            return (
                                <Col key={index} md="6">
                                    <CommissionRateUpdateInput
                                        name={item.label}
                                        errors={{}}
                                        type={item.value}
                                        commissionRate={profile[item.value]}
                                        userProfilekeyToken={
                                            profile.userProfilekeyToken
                                        }
                                        isEdit={false}
                                        userType={profile.role.slug}
                                    />
                                </Col>
                            );
                        } else {
                            return (
                                <Col key={index} md="6">
                                    <CommissionRateUpdateInput
                                        name={item.label}
                                        errors={{}}
                                        type={item.value}
                                        commissionRate={profile[item.value]}
                                        userProfilekeyToken={
                                            profile.userProfilekeyToken
                                        }
                                        isEdit={false}
                                        userType={profile.role.slug}
                                    />
                                </Col>
                            );
                        }
                    })} */}
            </Row>

            <PersonalInformationForm
                errors={errors}
                profile={profile}
                userType="user"
                isLoading={isLoading}
                isAdmin={isAdmin}
            />
        </ProfileLayout>
    );
};

export default Profile;
