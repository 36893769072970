import { Button, InputText, Title, ErrorAlert } from "@/components/atoms";
import { getPath, getPathTitle, Routes } from "@/constants";
import { Metabets } from "@/resources/images";
import { resetUserErrors, userForgotPassword } from "@/store/actions";
import { getResetPasswordLink } from "@/utils";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Alert, Card, CardBody, Col, Container, Form, Row } from "reactstrap";
import * as Yup from "yup";

const ForgotPassword = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: "",
            url: getResetPasswordLink(),
        },
        validationSchema: Yup.object({
            email: Yup.string().email().required("Please Enter Your Email"),
        }),
        onSubmit: (values) => {
            dispatch(userForgotPassword(values, validation));
        },
    });

    useEffect(() => {
        dispatch(resetUserErrors());
    }, [dispatch]);

    const { errors, isLoading } = useSelector((state) => state.user);

    return (
        <React.Fragment>
            <Title title={getPathTitle(Routes.FORGOT_PASSWORD)} />
            <div className="auth-page-content">
                <Container>
                    <Row className="justify-content-center">
                        <Col
                            md={8}
                            lg={6}
                            xl={5}
                            className="tw-h-screen tw-flex tw-flex-col tw-justify-center tw-items-center"
                        >
                            <div>
                                <Card className="tw-min-w-full md:tw-min-w-[400px]">
                                    <CardBody className="p-4">
                                        <div className="text-center">
                                            <img
                                                src={Metabets}
                                                className="tw-w-32 mb-4"
                                                alt={process.env.REACT_APP_NAME}
                                            />
                                            <h5 className="text-primary">
                                                {getPathTitle(
                                                    Routes.FORGOT_PASSWORD
                                                )}
                                            </h5>
                                            <p className="text-muted">
                                                Reset password with{" "}
                                                {process.env.REACT_APP_NAME}
                                            </p>
                                        </div>
                                        <div className="p-2 mt-4">
                                            <ErrorAlert
                                                errors={errors}
                                                validation={validation}
                                            />
                                            <Form
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    validation.handleSubmit();
                                                    return false;
                                                }}
                                                className="needs-validation"
                                                action="#"
                                            >
                                                <p className="text-muted">
                                                    * Enter your email and
                                                    instructions will be sent to
                                                    you!
                                                </p>
                                                <div className="mb-3">
                                                    <InputText
                                                        label="Email"
                                                        name="email"
                                                        placeholder="Enter Email"
                                                        validation={validation}
                                                    />
                                                </div>
                                                <div className="mt-4">
                                                    <Button
                                                        isLoading={isLoading}
                                                        color="success"
                                                        className="btn btn-success w-100"
                                                        type="submit"
                                                        text="Send Reset Link"
                                                    />
                                                </div>
                                            </Form>
                                        </div>
                                    </CardBody>
                                </Card>
                                <div className="mt-4 text-center">
                                    <p className="mb-0">
                                        Wait, I remember my password...{" "}
                                        <Link
                                            to={getPath(Routes.LOGIN)}
                                            className="fw-semibold text-primary text-decoration-underline"
                                        >
                                            {" "}
                                            Click here{" "}
                                        </Link>{" "}
                                    </p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ForgotPassword;
