export const GET_SETTINGS_DATA = "GET_SETTINGS_DATA";
export const SET_SETTINGS_DATA = "SET_SETTINGS_DATA";
export const SET_SETTINGS_DATA_ERROR = "SET_SETTINGS_DATA_ERROR";

export const UPDATE_SETTING = "UPDATE_SETTING";
export const UPDATE_SETTING_SUCCESS = "UPDATE_SETTING_SUCCESS";
export const UPDATE_SETTING_ERROR = "UPDATE_SETTING_ERROR";

export const GET_SETTING_VALUE = "GET_SETTING_VALUE";
export const GET_SETTING_VALUE_SUCCESS = "GET_SETTING_VALUE_SUCCESS";
export const GET_SETTING_VALUE_ERROR = "GET_SETTING_VALUE_ERROR";
