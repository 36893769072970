import * as types from "./actionTypes";

export const getAdminList = (payload) => ({
    type: types.GET_ADMIN_LIST,
    payload,
});

export const setAdminList = (payload) => ({
    type: types.SET_ADMIN_LIST,
    payload,
});

export const setAdminListError = () => ({
    type: types.SET_ADMIN_LIST_ERROR,
});

export const getRoleList = (payload, setRoleOptions) => ({
    type: types.GET_ROLE_LIST,
    payload,
    setRoleOptions,
});

export const setRoleList = (payload) => ({
    type: types.SET_ROLE_LIST,
    payload,
});

export const setRoleListError = (payload) => ({
    type: types.SET_ROLE_LIST_ERROR,
    payload,
});

export const getRoleInfo = (keyToken, setPermissions) => ({
    type: types.GET_ROLE_INFO,
    keyToken,
    setPermissions,
});

export const setRoleInfo = (payload) => ({
    type: types.SET_ROLE_INFO,
    payload,
});

export const setRoleInfoError = (payload) => ({
    type: types.SET_ROLE_INFO_ERROR,
    payload,
});

export const getPermissionData = (setPermissions) => ({
    type: types.GET_PERMISSION_DATA,
    setPermissions,
});

export const setPermissionData = (payload) => ({
    type: types.SET_PERMISSION_DATA,
    payload,
});

export const setPermissionDataError = () => ({
    type: types.SET_PERMISSION_DATA_ERROR,
});

export const getAdminProfile = (payload) => ({
    type: types.GET_ADMIN_PROFILE,
    payload,
});

export const setAdminProfile = (payload) => ({
    type: types.SET_ADMIN_PROFILE,
    payload,
});

export const setAdminProfileError = () => ({
    type: types.SET_ADMIN_PROFILE_ERROR,
});

export const createAdmin = (payload, validation) => ({
    type: types.CREATE_ADMIN,
    payload,
    validation,
});

export const createAdminSuccess = (payload) => ({
    type: types.CREATE_ADMIN_SUCCESS,
    payload,
});

export const createAdminError = (payload) => ({
    type: types.CREATE_ADMIN_ERROR,
    payload,
});

export const createRole = (payload, validation, navigate) => ({
    type: types.CREATE_ROLE,
    payload,
    validation,
    navigate,
});

export const createRoleSuccess = (payload) => ({
    type: types.CREATE_ROLE_SUCCESS,
    payload,
});

export const createRoleError = (payload) => ({
    type: types.CREATE_ROLE_ERROR,
    payload,
});

export const updateRoleName = (payload, keyToken) => ({
    type: types.UPDATE_ROLE_NAME,
    payload,
    keyToken,
});

export const updateRoleNameSuccess = (payload) => ({
    type: types.UPDATE_ROLE_NAME_SUCCESS,
    payload,
});

export const updateRoleNameError = (payload) => ({
    type: types.UPDATE_ROLE_NAME_ERROR,
    payload,
});

export const updateRolePermission = (payload) => ({
    type: types.UPDATE_ROLE_PERMISSION,
    payload,
});

export const updateRolePermissionSuccess = (payload) => ({
    type: types.UPDATE_ROLE_PERMISSION_SUCCESS,
    payload,
});

export const updateRolePermissionError = (payload) => ({
    type: types.UPDATE_ROLE_PERMISSION_ERROR,
    payload,
});

export const updateAdmin = (payload, keyToken) => ({
    type: types.UPDATE_ADMIN,
    payload,
    keyToken,
});

export const updateAdminSuccess = (payload) => ({
    type: types.UPDATE_ADMIN_SUCCESS,
    payload,
});

export const updateAdminError = (payload) => ({
    type: types.UPDATE_ADMIN_ERROR,
    payload,
});

export const updateAdminRole = (payload, keyToken) => ({
    type: types.UPDATE_ADMIN_ROLE,
    payload,
    keyToken,
});

export const updateAdminRoleSuccess = (payload) => ({
    type: types.UPDATE_ADMIN_ROLE_SUCCESS,
    payload,
});

export const updateAdminRoleError = (payload) => ({
    type: types.UPDATE_ADMIN_ROLE_ERROR,
    payload,
});

export const resetAdminErrors = () => ({
    type: types.RESET_ADMIN_ERRORS,
});

export const updateAdminEmailSuccess = (payload) => ({
    type: types.UPDATE_ADMIN_EMAIL_SUCCESS,
    payload,
});

export const directWithdrawal = (payload, initialize) => ({
    type: types.DIRECT_WITHDRAWAL,
    payload,
    initialize,
});

export const directWithdrawalSuccess = () => ({
    type: types.DIRECT_WITHDRAWAL_SUCCESS,
});

export const directWithdrawalErrors = (payload) => ({
    type: types.DIRECT_WITHDRAWAL_ERROR,
    payload,
});

export const resetDirectWithdrawalErrors = () => ({
    type: types.RESET_DIRECT_WITHDRAWAL_ERROR,
});

export const getDirectWithdrawals = (payload) => ({
    type: types.GET_DIRECT_WITHDRAWALS,
    payload,
});

export const getDirectWithdrawalsSuccess = (payload) => ({
    type: types.GET_DIRECT_WITHDRAWALS_SUCCESS,
    payload,
});

export const getDirectWithdrawalsError = (payload) => ({
    type: types.GET_DIRECT_WITHDRAWALS_ERROR,
    payload,
});
