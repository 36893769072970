import { Button, ErrorAlert, InputAmount, TextArea } from "@/components/atoms";
import { addFundValidation, bcfPercentage } from "@/constants";
import { addFund, resetTransactionErrors } from "@/store/actions";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button as ButtonComponent,
    Col,
    Form,
    FormGroup,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
    ButtonGroup,
} from "reactstrap";

const Index = ({ isOpen, setIsOpen, userProfilekeyToken, initialize }) => {
    const dispatch = useDispatch();
    const { isLoading, errors } = useSelector((state) => state.transaction);
    const [selectedPercentage, setSelectedPercentage] = useState(10);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            user_profile_key_token: userProfilekeyToken,
            amount: "",
            credit: "",
            remarks: "",
            type: "credit",
        },
        validationSchema: addFundValidation(),
        onSubmit: (values) => {
            dispatch(addFund(values, setIsOpen, initialize));
        },
    });

    const { setFieldValue, values } = validation;

    useEffect(() => {
        dispatch(resetTransactionErrors());
    }, [dispatch]);

    useEffect(() => {
        if (selectedPercentage !== null) {
            setFieldValue(
                "amount",
                Number(values.credit) * (selectedPercentage / 100)
            );
        }
    }, [selectedPercentage, setFieldValue, values]);

    const handleSelectPercentage = (value) => {
        setSelectedPercentage(value);
        if (value === null) {
            setFieldValue("amount", 0);
        }
    };

    const handleChangeCredit = (value) => {
        if (selectedPercentage !== null) {
            validation.setFieldValue(
                "amount",
                Number(value) * (selectedPercentage / 100)
            );
        }
    };

    const handleChangeAmount = (value) => {
        if (selectedPercentage === null) {
            validation.setFieldValue("amount", value);
        }
    };

    return (
        <Modal id="fundModal" isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
            <ModalHeader>
                <div className="modal-title">Add Fund</div>
            </ModalHeader>
            <ModalBody>
                <Form
                    className="needs-validation"
                    onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}
                >
                    <ErrorAlert errors={errors} validation={validation} />
                    <Row className="mb-3">
                        <Col md={12}>
                            <FormGroup className="mb-3">
                                <InputAmount
                                    label="Credit"
                                    name="credit"
                                    validation={validation}
                                    onChange={(e) =>
                                        handleChangeCredit(e.target.rawValue)
                                    }
                                />
                            </FormGroup>
                        </Col>
                        <ButtonGroup className="w-100 pb-2">
                            {bcfPercentage.map((item, key) => (
                                <ButtonComponent
                                    key={key}
                                    color="success"
                                    outline={selectedPercentage !== item.value}
                                    onClick={() =>
                                        handleSelectPercentage(item.value)
                                    }
                                >
                                    {item.label}
                                </ButtonComponent>
                            ))}
                        </ButtonGroup>
                        <Col md={12}>
                            <FormGroup className="mb-3">
                                <InputAmount
                                    label="Amount"
                                    name="amount"
                                    validation={validation}
                                    onChange={(e) =>
                                        handleChangeAmount(e.target.rawValue)
                                    }
                                    value={
                                        validation.values.credit !== ""
                                            ? Number(validation.values.credit) *
                                              (selectedPercentage / 100)
                                            : validation.values.amount
                                    }
                                    disabled={selectedPercentage !== null}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={12}>
                            <FormGroup className="mb-3">
                                <TextArea
                                    label="Remarks"
                                    name="remarks"
                                    validation={validation}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-end">
                            <ButtonComponent
                                color="light"
                                onClick={() => setIsOpen(!isOpen)}
                                className="me-2"
                            >
                                Close
                            </ButtonComponent>
                            <Button
                                color="success"
                                type="submit"
                                text="Submit"
                                isLoading={isLoading}
                            />
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
        </Modal>
    );
};

export default Index;
