import { InputSwitch, Table, Title, Select } from "@/components/atoms";
import BreadCrumb from "@/components/common/BreadCrumb";
import { confirmation } from "@/components/constants/common";
import { Routes, getPath, getPathTitle } from "@/constants";
import {
  getMessageList,
  updateMessageStatus,
  getMessageTypes,
} from "@/store/actions";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardHeader,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import { UpdateMessageModal } from "@/components/molecules";

const MessageList = () => {
  const dispatch = useDispatch();
  const [updateMessageIsOpen, setUpdateMessageIsOpen] = useState(false);
  const [SelectedItem, setSelectedItem] = useState({});

  const { messageList, isLoading, messageTypes } = useSelector(
    (state) => state.announcement
  );
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState({
    field: "",
    type: "asc",
  });
  const [data, setData] = useState({
    per_page: 10,
    page: 1,
    search: "",
    message_type: "",
    is_active: "",
  });

  const tableTheadData = [
    {
      label: "Title",
      field: "title",
      sortable: true,
    },
    {
      label: "Description",
      field: "description",
      sortable: true,
      render: ({description}) => <div className="tw-w-[500px] tw-truncate tw-text-center">{description}</div>
    },
    {
      label: "Type",
      field: "type",
      sortable: true,
    },
    {
      label: "Status",
      sortable: false,
      render: (item) => (
        <div className="d-flex w-100 justify-content-center">
          <InputSwitch
            label={item.is_active ? "Active" : "Inactive"}
            id={`message-status-${item.slug}`}
            isChecked={item.is_active}
            color="success"
            onChange={() => changeStatus(item)}
          />
        </div>
      ),
    },
    {
      label: "Action",
      field: "action",
      sortable: false,
      render: (item) => (
        <Button
          onClick={() => {
            setUpdateMessageIsOpen(true);
            setSelectedItem(item);
          }}
          color="primary"
          size="sm"
        >
          <i className="ri-play-list-add-line"></i>
        </Button>
      ),
    },
  ];

  const changeStatus = (item) => {
    confirmation({
      title: item.title,
      text: `Are you sure you want to ${
        item.is_active ? "DEACTIVATE" : "ACTIVE"
      }`,
      icon: "warning",
      confirmButtonText: "Confirm",
      action: () => {
        dispatch(updateMessageStatus(item.key_token, initialize));
      },
    });
  };

  const initialize = () => {
    dispatch(getMessageList(data));
  };
  useEffect(() => {
    dispatch(getMessageList(data));
  }, [data, dispatch]);

  useEffect(() => {
    dispatch(getMessageTypes());
  }, [dispatch]);
  return (
    <React.Fragment>
      <Title title={getPathTitle(Routes.MESSAGE_LIST)} />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            parent={{
              isParent: true,
              pageTitle: getPathTitle(Routes.MESSAGE_LIST),
              pageLink: getPath(Routes.MESSAGE_LIST),
            }}
          />
          <Row>
            <Col>
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    {getPathTitle(Routes.MESSAGE_LIST)}
                  </h4>
                  <div className="flex-shrink-0">
                    <Link to={getPath(Routes.MESSAGE_CREATE_OR_UPDATE)}>
                      <Button color="success" size="sm">
                        <i className="ri-add-fill me-1 align-bottom"></i>
                        {getPathTitle(Routes.MESSAGE_CREATE_OR_UPDATE)}
                      </Button>
                    </Link>
                  </div>
                </CardHeader>
                <CardBody>
                  <Row className="mb-3">
                    <Col md={6}>
                      <Select
                        label="Type"
                        isClearable={true}
                        value={data.message_type}
                        options={[
                          { label: "All", value: "" },
                          ...((messageTypes ?? []).map((item) => ({
                            label: item.title,
                            value: item.key_token,
                          })) ?? []),
                        ]}
                        onChange={(e) => {
                          setData((data) => ({
                            ...data,
                            message_type: e.target.value,
                          }));
                        }}
                      />
                    </Col>
                    <Col md={6}>
                      <Select
                        label="Status"
                        isClearable={true}
                        value={data.is_active}
                        options={[
                          { label: "All", value: "" },
                          { label: "Active", value: 1 },
                          { label: "Inactive", value: 0 },
                        ]}
                        onChange={(e) => {
                          setData((data) => ({
                            ...data,
                            is_active: e.target.value,
                          }));
                        }}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Card>
                <CardBody>
                  <Table
                    search={search}
                    handlerSearch={setSearch}
                    sortBy={sortBy}
                    handlerSortBy={setSortBy}
                    thead={tableTheadData}
                    handlerChangeData={setData}
                    data={data}
                    collection={messageList}
                    isLoading={isLoading}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          {updateMessageIsOpen && (
            <UpdateMessageModal
              isOpen={updateMessageIsOpen}
              setIsOpen={setUpdateMessageIsOpen}
              data={SelectedItem}
              initialize={initialize}
            />
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default MessageList;
