const DataCollapse = ({
    label = null,
    collapseKey,
    data,
    fields,
    collapse,
    handleCollapse,
    content = null,
}) => {
    return (
        <div
            onClick={() => handleCollapse(collapseKey)}
            className="tw-flex tw-flex-col"
        >
            <div className="tw-relative tw-flex tw-flex-row tw-items-center tw-justify-start tw-cursor-pointer">
                <span className="bx bx-chevron-down tw-text-lg tw-leading-none" />
                <div className="text-decoration-underline">
                    {label ? label : data.length > 1 ? "Multiple" : "Single"}{" "}
                    {label
                        ? ""
                        : `(${data.length})`}
                </div>
            </div>
            <div
                className={`tw-flex tw-flex-col tw-capitalize tw-items-start gap-2 ${
                    collapse.includes(collapseKey) ? "" : "tw-hidden"
                }`}
            >
                {!content ? (
                    data.map((values, index) => (
                        <div
                            className="tw-px-4 tw-flex tw-flex-col tw-items-start"
                            key={index}
                        >
                            {fields.map(({ field, render }, index) => (
                                <div key={index}>
                                    {render
                                        ? render(values[field])
                                        : values[field]}
                                </div>
                            ))}
                        </div>
                    ))
                ) : (
                    <div className="tw-px-4 tw-flex tw-flex-col tw-items-start text-start">
                        {content}
                    </div>
                )}
            </div>
        </div>
    );
};

export default DataCollapse;
