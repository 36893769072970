export const GET_ADMIN_LIST = "GET_ADMIN_LIST";
export const SET_ADMIN_LIST = "SET_ADMIN_LIST";
export const SET_ADMIN_LIST_ERROR = "SET_ADMIN_LIST_ERROR";

export const GET_ROLE_LIST = "GET_ROLE_LIST";
export const SET_ROLE_LIST = "SET_ROLE_LIST";
export const SET_ROLE_LIST_ERROR = "SET_ROLE_LIST_ERROR";

export const GET_PERMISSION_DATA = "GET_PERMISSION_DATA";
export const SET_PERMISSION_DATA = "SET_PERMISSION_DATA";
export const SET_PERMISSION_DATA_ERROR = "SET_PERMISSION_DATA_ERROR";

export const GET_ROLE_INFO = "GET_ROLE_INFO";
export const SET_ROLE_INFO = "SET_ROLE_INFO";
export const SET_ROLE_INFO_ERROR = "SET_ROLE_INFO_ERROR";

export const CREATE_ADMIN = "CREATE_ADMIN";
export const CREATE_ADMIN_SUCCESS = "CREATE_ADMIN_SUCCESS";
export const CREATE_ADMIN_ERROR = "CREATE_ADMIN_ERROR";

export const CREATE_ROLE = "CREATE_ROLE";
export const CREATE_ROLE_SUCCESS = "CREATE_ROLE_SUCCESS";
export const CREATE_ROLE_ERROR = "CREATE_ROLE_ERROR";

export const UPDATE_ROLE_NAME = "UPDATE_ROLE_NAME";
export const UPDATE_ROLE_NAME_SUCCESS = "UPDATE_ROLE_NAME_SUCCESS";
export const UPDATE_ROLE_NAME_ERROR = "UPDATE_ROLE_NAME_ERROR";

export const UPDATE_ROLE_PERMISSION = "UPDATE_ROLE_PERMISSION";
export const UPDATE_ROLE_PERMISSION_SUCCESS = "UPDATE_ROLE_PERMISSION_SUCCESS";
export const UPDATE_ROLE_PERMISSION_ERROR = "UPDATE_ROLE_PERMISSION_ERROR";

export const GET_ADMIN_PROFILE = "GET_ADMIN_PROFILE";
export const SET_ADMIN_PROFILE = "SET_ADMIN_PROFILE";
export const SET_ADMIN_PROFILE_ERROR = "SET_ADMIN_PROFILE_ERROR";

export const UPDATE_ADMIN = "UPDATE_ADMIN";
export const UPDATE_ADMIN_SUCCESS = "UPDATE_ADMIN_SUCCESS";
export const UPDATE_ADMIN_ERROR = "UPDATE_ADMIN_ERROR";

export const UPDATE_ADMIN_ROLE = "UPDATE_ADMIN_ROLE";
export const UPDATE_ADMIN_ROLE_SUCCESS = "UPDATE_ADMIN_ROLE_SUCCESS";
export const UPDATE_ADMIN_ROLE_ERROR = "UPDATE_ADMIN_ROLE_ERROR";

export const DELETE_ADMIN = "DELETE_ADMIN";
export const DELETE_ADMIN_SUCCESS = "DELETE_ADMIN_SUCCESS";
export const DELETE_ADMIN_ERROR = "DELETE_ADMIN_ERROR";

export const RESET_ADMIN_ERRORS = "RESET_ADMIN_ERRORS";

export const UPDATE_ADMIN_EMAIL_SUCCESS = "UPDATE_ADMIN_EMAIL_SUCCESS";

export const DIRECT_WITHDRAWAL = "DIRECT_WITHDRAWAL";
export const DIRECT_WITHDRAWAL_SUCCESS = "DIRECT_WITHDRAWAL_SUCCESS";
export const DIRECT_WITHDRAWAL_ERROR = "DIRECT_WITHDRAWAL_ERROR";
export const RESET_DIRECT_WITHDRAWAL_ERROR = "RESET_DIRECT_WITHDRAWAL_ERROR";

export const GET_DIRECT_WITHDRAWALS = "GET_DIRECT_WITHDRAWALS";
export const GET_DIRECT_WITHDRAWALS_SUCCESS = "GET_DIRECT_WITHDRAWALS_SUCCESS";
export const GET_DIRECT_WITHDRAWALS_ERROR = "GET_DIRECT_WITHDRAWALS_ERROR";
