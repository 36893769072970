import {
    ErrorAlert,
    InputActionChecker,
    InputTextGroup,
} from "@/components/atoms";
import { confirmation } from "@/components/constants/common";
import { emailValidation } from "@/constants";
import { updateUserEmail } from "@/store/actions";
import { useFormik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import { FormGroup } from "reactstrap";

const EmailUpdateInput = ({ profile, isAdmin, errors, userType }) => {
    const dispatch = useDispatch();

    const showConfirmation = () => {
        confirmation({
            title: "Confirmation Message",
            text: "Are you sure you want to save?",
            icon: "warning",
            confirmButtonText: "Save",
            action: () => validation.handleSubmit(),
        });
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: profile.email,
        },
        validationSchema: emailValidation,
        onSubmit: (values) => {
            dispatch(
                updateUserEmail(values, profile.keyToken, validation, userType)
            );
        },
    });
    return (
        <>
            <ErrorAlert errors={errors} validation={validation} />
            <InputActionChecker
                label="Email"
                value={profile.email}
                isEdit={isAdmin}>
                <FormGroup className="mb-3">
                    <InputTextGroup
                        label="Email"
                        name="email"
                        buttonIcon="ri-pencil-line"
                        buttonAction={showConfirmation}
                        validation={validation}
                    />
                </FormGroup>
            </InputActionChecker>
        </>
    );
};

export default EmailUpdateInput;
