import React from "react";
import { Button as ButtonComponent, Spinner } from "reactstrap";

const Button = ({ text, isLoading, ...rest }) => {
    return (
        <>
            <ButtonComponent {...rest} disabled={isLoading}>
                <span className="align-items-center">
                    <span className={`flex-grow-1 ${isLoading && "me-2"}`}>
                        {text}
                    </span>
                    {isLoading && (
                        <Spinner
                            size="sm"
                            className="flex-shrink-0"
                            role="status"
                        ></Spinner>
                    )}
                </span>
            </ButtonComponent>
        </>
    );
};

export default Button;
