import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  FormGroup,
  Label,
  Form,
} from "reactstrap";
import { updateMessageInformation, updateMessageBanner } from "@/store/actions";
import { FileInputForm } from "@/components/molecules";
import { DisplayText } from "@/components/atoms";
import { useFormik } from "formik";
import {
  Button,
  InputText,
  TextArea,
} from "@/components/atoms";
import { updateSendMessageValidation as validationSchema } from "@/constants";

const UpdateMessageModal = ({ isOpen, setIsOpen, data, initialize }) => {
  const dispatch = useDispatch();
  const { isSubmitting } = useSelector((state) => state.announcement);

  const validation = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues: {
      title: data?.title,
      description: data?.description,
    },
    onSubmit: (values) => {
      dispatch(updateMessageInformation(values, data?.key_token, result));
    },
  });

  const result = (isSuccess) => !isSuccess ? validation.resetForm() : initialize();

  const onUpdate = (values) => {
    dispatch(updateMessageBanner(values, data?.key_token, result));
  }

  return (
    <Modal
      id="updateMessageModal"
      isOpen={true}
      toggle={() => setIsOpen(!isOpen)}
    >
      <ModalHeader>
        <div className="modal-title">Update Message</div>
      </ModalHeader>
      <ModalBody>
        <Form
          className="needs-validation"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row>
            <Col md="6">
              <DisplayText label="Type" item={data?.type} col="8" />
            </Col>
            <Col md="6">
              <DisplayText
                label="Message"
                item={data?.sms_description ?? "-"}
                col="8"
              />
            </Col>
            <Col md="12">
              <FormGroup className="mb-3">
                <InputText
                  label="Title"
                  name="title"
                  placeholder="Title"
                  validation={validation}
                />
              </FormGroup>
            </Col>
            <Col md="12">
              <Label>Description</Label>
              <TextArea
                rows="10"
                cols="50"
                name="description"
                validation={validation}
              />
            </Col>
          </Row>
          <div className="border-top py-3 mt-3">
            <Button
              color="success"
              type="submit"
              className="float-end"
              text="Submit"
              isLoading={isSubmitting}
            />
          </div>
        </Form>
        <hr className="tw-mt-9"/>
        <FileInputForm
          isLoading={isSubmitting}
          field="banner"
          defaultImage={data?.banner_url}
          label="Banner"
          isCreate={!data?.banner_url}
          onSubmit={(values) => onUpdate(values)}
        />
      </ModalBody>
    </Modal>
  );
};

export default UpdateMessageModal;
