import * as types from "./actionTypes";

export const getTransactionData = (
  payload,
  transactionType = "wallet",
  betType = "electronic-games"
) => ({
  type: types.GET_TRANSACTION_DATA,
  payload,
  transactionType,
  betType,
});

export const setTransactionData = (payload) => ({
  type: types.SET_TRANSACTION_DATA,
  payload,
});

export const setTransactionDataError = (payload) => ({
  type: types.SET_TRANSACTION_DATA_ERROR,
  payload,
});

export const userDeposit = (payload, setIsOpen, initialize, userType) => ({
  type: types.USER_DEPOSIT,
  payload,
  setIsOpen,
  initialize,
  userType,
});

export const userDepositSuccess = () => ({
  type: types.USER_DEPOSIT_SUCCESS,
});

export const userDepositError = (payload) => ({
  type: types.USER_DEPOSIT_ERROR,
  payload,
});

export const userDepositRequest = (payload, setIsOpen) => ({
  type: types.USER_DEPOSIT_REQUEST,
  payload,
  setIsOpen,
});

export const userDepositRequestSuccess = () => ({
  type: types.USER_DEPOSIT_REQUEST_SUCCESS,
});

export const userDepositRequestError = (payload) => ({
  type: types.USER_DEPOSIT_REQUEST_ERROR,
  payload,
});

export const userWithdrawalRequest = (payload, setIsOpen) => ({
  type: types.USER_WITHDRAWAL_REQUEST,
  payload,
  setIsOpen,
});

export const userWithdrawalRequestSuccess = () => ({
  type: types.USER_WITHDRAWAL_REQUEST_SUCCESS,
});

export const userWithdrawalRequestError = (payload) => ({
  type: types.USER_WITHDRAWAL_REQUEST_ERROR,
  payload,
});

export const getPaymentMethodData = () => ({
  type: types.GET_PAYMENT_METHOD_DATA,
});

export const setPaymentMethodDataSuccess = (payload) => ({
  type: types.SET_PAYMENT_METHOD_DATA,
  payload,
});

export const setPaymentMethodDataError = (payload) => ({
  type: types.SET_PAYMENT_METHOD_DATA_ERROR,
  payload,
});

export const resetTransactionErrors = () => ({
  type: types.RESET_TRANSACTION_ERRORS,
});

export const getTransactionInfo = (payload) => ({
  type: types.GET_TRANSACTION_INFO,
  payload,
});

export const setTransactionInfo = (payload) => ({
  type: types.SET_TRANSACTION_INFO,
  payload,
});

export const setTransactionInfoError = (payload) => ({
  type: types.SET_TRANSACTION_INFO_ERROR,
  payload,
});

export const addFund = (payload, setIsOpen, initialize) => ({
  type: types.ADD_FUND,
  payload,
  setIsOpen,
  initialize,
});

export const addFundSuccess = () => ({
  type: types.ADD_FUND_SUCCESS,
});

export const addFundError = (payload) => ({
  type: types.ADD_FUND_ERROR,
  payload,
});

export const processCommission = (payload, initialize) => ({
  type: types.PROCESS_COMMISSION,
  payload, 
  initialize,
});

export const onProcessCommissionSuccess = () => ({
  type: types.PROCESS_COMMISSION_SUCCESS,
});

export const onProcessCommissionFailed = () => ({
  type: types.PROCESS_COMMISSION_FAILED,
});

export const getTipTransaction = (payload) => ({
  type: types.GET_TIP_TRANSACTION,
  payload,
});

export const setTipTransaction = (payload) => ({
  type: types.SET_TIP_TRANSACTION,
  payload,
});

export const setTipTransactionError = (payload) => ({
  type: types.SET_TIP_TRANSACTION_ERROR,
  payload,
});