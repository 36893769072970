import { FormFeedback, Input, Label } from "reactstrap";

const InputPassword = ({
    label,
    validation,
    name,
    showPassword,
    showPasswordHandler,
    ...rest
}) => {
    return (
        <>
            <Label>{label}</Label>
            <div className="position-relative auth-pass-inputgroup mb-3">
                <Input
                    type={showPassword ? "text" : "password"}
                    className="form-control pe-5"
                    name={name}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values[name] || ""}
                    invalid={
                        validation.touched[name] && validation.errors[name]
                            ? true
                            : false
                    }
                    {...rest}
                />
                {validation.touched[name] && validation.errors[name] ? (
                    <FormFeedback type="invalid">
                        {validation.errors[name]}
                    </FormFeedback>
                ) : null}
                <button
                    className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
                    type="button"
                    id={name}
                >
                    <i
                        className={
                            showPassword
                                ? "ri-eye-off-fill align-middle"
                                : "ri-eye-fill align-middle"
                        }
                        onClick={showPasswordHandler}
                    ></i>
                </button>
            </div>
        </>
    );
};

export default InputPassword;
