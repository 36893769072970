import {
    Button,
    ErrorAlert,
    InputDate,
    InputReactSelect,
    InputSelect,
    InputText,
    Title,
} from "@/components/atoms";
import BreadCrumb from "@/components/common/BreadCrumb";
import {
    adminValidation,
    getPath,
    getPathTitle,
    Routes,
    userGenderOptions,
} from "@/constants";
import {
    createAdmin,
    getBarangays,
    getCities,
    getProvinces,
    getRegions,
    resetAddressesData,
    resetAdminErrors,
    getRoleList,
    getCountries,
} from "@/store/actions";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form,
    FormGroup,
    Row,
} from "reactstrap";

const Create = () => {
    const dispatch = useDispatch();
    const { isLoading, errors, roleList } = useSelector((state) => state.admin);

    const { countries, regions, provinces, cities, barangays } = useSelector(
        (state) => state.address
    );

    const [country, setCountry] = useState(null);
    const [roleOptions, setRoleOptions] = useState([]);
    const [selectedRole, setSelectedRole] = useState({});

    const [addresses, setAddresses] = useState({
        region: "",
        province: "",
        city: "",
        barangay: "",
    });

    const addressValueHandler = (option, type) => {
        const { value } = option;
        validation.setFieldValue(type, value);
        if (type === "country") {
            dispatch(getRegions());
            validation.setFieldValue("region", "");
            validation.setFieldValue("province", "");
            validation.setFieldValue("city", "");
            validation.setFieldValue("barangay", "");
        }
        if (type === "region") {
            dispatch(getProvinces(value));
            validation.setFieldValue("province", "");
            validation.setFieldValue("city", "");
            validation.setFieldValue("barangay", "");
        } else if (type === "province") {
            dispatch(getCities(value));
            validation.setFieldValue("city", "");
            validation.setFieldValue("barangay", "");
        } else if (type === "city") {
            dispatch(getBarangays(value));
            validation.setFieldValue("barangay", "");
        }
        setAddresses({
            ...addresses,
            [type]: option,
        });
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            first_name: "",
            last_name: "",
            middle_name: "",
            extension_name: "",
            birth_date: "",
            gender: "",
            occupation: "",
            email: "",
            mobile_no: "",
            country: "",
            region: "",
            province: "",
            city: "",
            barangay: "",
            building_no: "",
            street: "",
            postal_code: "",
            admin_role: "",
            user_type: "admin",
        },
        validationSchema: adminValidation,
        onSubmit: (values) => {
            dispatch(createAdmin(values, validation));
        },
    });

    useEffect(() => {
        dispatch(resetAddressesData());
        dispatch(getCountries());
        dispatch(resetAdminErrors());
        dispatch(getRoleList({ per_page: 1000, page: 1 }, setRoleOptions));
    }, [dispatch]);

    const roleSection = () => {
        return (
            <Row>
                <Col>
                    <FormGroup className="mb-3">
                        <InputReactSelect
                            label="Role"
                            name="admin_role"
                            onChange={(option) => {
                                setSelectedRole(option);
                                validation.setFieldValue(
                                    "admin_role",
                                    option.value
                                );
                            }}
                            value={
                                validation.values.admin_role ? selectedRole : ""
                            }
                            options={roleOptions}
                            validation={validation}
                        />
                    </FormGroup>
                </Col>
            </Row>
        );
    };
    const personalInformation = () => {
        return (
            <>
                <p className="text-muted">Personal Information</p>
                <Row className="mb-3">
                    <Col md="4">
                        <FormGroup className="mb-3">
                            <InputText
                                label="First Name"
                                name="first_name"
                                placeholder="First name"
                                validation={validation}
                            />
                        </FormGroup>
                    </Col>
                    <Col md="3">
                        <FormGroup className="mb-3">
                            <InputText
                                label="Last Name"
                                name="last_name"
                                placeholder="Last name"
                                validation={validation}
                            />
                        </FormGroup>
                    </Col>
                    <Col md="3">
                        <FormGroup className="mb-3">
                            <InputText
                                label="Middle Name"
                                name="middle_name"
                                placeholder="Middle name"
                                validation={validation}
                            />
                        </FormGroup>
                    </Col>
                    <Col md="2">
                        <FormGroup className="mb-3">
                            <InputText
                                label="Suffix Name"
                                name="extension_name"
                                placeholder="Ex. Jr, Sr etc..."
                                validation={validation}
                            />
                        </FormGroup>
                    </Col>

                    <Col md="4">
                        <FormGroup className="mb-3">
                            <InputDate
                                label="BirthDate"
                                name="birth_date"
                                validation={validation}
                            />
                        </FormGroup>
                    </Col>
                    <Col md="4">
                        <FormGroup className="mb-3">
                            <InputSelect
                                label="Gender"
                                name="gender"
                                validation={validation}
                                options={userGenderOptions}
                            />
                        </FormGroup>
                    </Col>
                    <Col md="4">
                        <FormGroup className="mb-3">
                            <InputText
                                label="Occupation"
                                name="occupation"
                                placeholder="Occupation"
                                validation={validation}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </>
        );
    };

    const contactInformation = () => {
        return (
            <>
                <p className="text-muted">Contact Information</p>
                <Row className="mb-3">
                    <Col md="6">
                        <FormGroup className="mb-3">
                            <InputText
                                label="Email"
                                name="email"
                                placeholder="Email Address"
                                validation={validation}
                            />
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <FormGroup className="mb-3">
                            <InputText
                                label="Mobile Number"
                                name="mobile_no"
                                placeholder="09xxxxxxxxx"
                                validation={validation}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </>
        );
    };

    const addressInformation = () => {
        return (
            <>
                <p className="text-muted">Address Information</p>
                <Row className="mb-3">
                    <Col md="12">
                        <Row>
                            <Col md="3">
                                <FormGroup className="mb-3">
                                    <InputReactSelect
                                        label="Country"
                                        name="country"
                                        onChange={(option) => {
                                            setCountry(option.label);
                                            addressValueHandler(
                                                option,
                                                "country"
                                            );
                                        }}
                                        value={
                                            validation.values.country
                                                ? addresses.country
                                                : ""
                                        }
                                        options={countries}
                                        validation={validation}
                                        validateOnBlur={false}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>
                    {country === "Philippines" && (
                        <>
                            <Col md="3">
                                <FormGroup className="mb-3">
                                    <InputReactSelect
                                        label="Region"
                                        name="region"
                                        onChange={(option) => {
                                            addressValueHandler(
                                                option,
                                                "region"
                                            );
                                        }}
                                        value={
                                            validation.values.region
                                                ? addresses.region
                                                : ""
                                        }
                                        options={regions}
                                        validation={validation}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="3">
                                <FormGroup className="mb-3">
                                    <InputReactSelect
                                        label="Province"
                                        name="province"
                                        onChange={(option) => {
                                            addressValueHandler(
                                                option,
                                                "province"
                                            );
                                        }}
                                        value={
                                            validation.values.province
                                                ? addresses.province
                                                : ""
                                        }
                                        options={provinces}
                                        validation={validation}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="3">
                                <FormGroup className="mb-3">
                                    <InputReactSelect
                                        label="City"
                                        name="city"
                                        onChange={(option) => {
                                            addressValueHandler(option, "city");
                                        }}
                                        value={
                                            validation.values.city
                                                ? addresses.city
                                                : ""
                                        }
                                        options={cities}
                                        validation={validation}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="3">
                                <FormGroup className="mb-3">
                                    <InputReactSelect
                                        label="Barangay"
                                        name="barangay"
                                        onChange={(option) => {
                                            addressValueHandler(
                                                option,
                                                "barangay"
                                            );
                                        }}
                                        value={
                                            validation.values.barangay
                                                ? addresses.barangay
                                                : ""
                                        }
                                        options={barangays}
                                        validation={validation}
                                    />
                                </FormGroup>
                            </Col>
                        </>
                    )}
                </Row>
                <Row className="mb-3">
                    <Col md="4">
                        <FormGroup className="mb-3">
                            <InputText
                                label="Building No."
                                name="building_no"
                                placeholder="Building No"
                                validation={validation}
                            />
                        </FormGroup>
                    </Col>
                    <Col md="4">
                        <FormGroup className="mb-3">
                            <InputText
                                label="Street"
                                name="street"
                                placeholder="Street"
                                validation={validation}
                            />
                        </FormGroup>
                    </Col>
                    <Col md="4">
                        <FormGroup className="mb-3">
                            <InputText
                                label="Postal Code"
                                name="postal_code"
                                placeholder="Postal Code"
                                validation={validation}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </>
        );
    };

    return (
        <React.Fragment>
            <Title title={getPathTitle(Routes.MANAGE_USER_CREATE)} />
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb
                        parent={{
                            isParent: true,
                            pageTitle: getPathTitle(Routes.MANAGE_USER),
                            pageLink: getPath(Routes.MANAGE_USER),
                        }}
                        childs={[
                            {
                                pageTitle: getPathTitle(
                                    Routes.MANAGE_USER_CREATE
                                ),
                                pageLink: getPath(Routes.MANAGE_USER_CREATE),
                                isActive: true,
                            },
                        ]}
                    />
                    <Row>
                        <Col xs={12}>
                            <CardHeader className="align-items-center d-flex">
                                <h4 className="card-title mb-0 flex-grow-1">
                                    {getPathTitle(Routes.MANAGE_USER_CREATE)}
                                </h4>
                                <div className="flex-shrink-0">
                                    Please Fill up all fields
                                </div>
                            </CardHeader>
                            <Card>
                                <CardBody>
                                    <Form
                                        className="needs-validation"
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            validation.handleSubmit();
                                            return false;
                                        }}>
                                        <ErrorAlert
                                            errors={errors}
                                            validation={validation}
                                        />
                                        {roleSection()}
                                        {personalInformation()}
                                        {contactInformation()}
                                        {addressInformation()}
                                        <div className="border-top py-3 mt-3">
                                            <Button
                                                color="success"
                                                type="submit"
                                                className="float-end"
                                                text="Submit"
                                                isLoading={isLoading}
                                            />
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Create;
