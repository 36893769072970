import {
    AmountFormat,
    AsyncReactSelect,
    CollapsibleTable,
    FlatPickerDate,
    Select,
    Title,
} from "@/components/atoms";
import BreadCrumb from "@/components/common/BreadCrumb";
import {
    Routes,
    balanceCategoryOptions,
    dateFilterOptions,
    getPath,
    getPathTitle,
} from "@/constants";
import {
    getOperatorSelectionList,
    getWinLossByProductReport,
} from "@/store/actions";
import { today } from "@/utils";
import { filterDateFormat } from "@/utils/common";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row,
} from "reactstrap";

// const dataSource = {
//     data: [
//         {
//             game_offering: "Casino",
//             game_provider: [
//                 {
//                     game_game_provider: "Evolution",
//                     turnover: 472014.27,
//                     win_loss: -472014.27,
//                     jackpot: -472014.27,
//                     net_income: 472014.27,
//                     game: [
//                         {
//                             game_game_name: "Dragon Tiger",
//                             turnover: 472014.27,
//                             win_loss: -472014.27,
//                             jackpot: -472014.27,
//                             net_income: 472014.27,
//                         },
//                         {
//                             game_game_name: "Dream Catcher",
//                             turnover: 472014.27,
//                             win_loss: -472014.27,
//                             jackpot: -472014.27,
//                             net_income: 472014.27,
//                         },
//                         {
//                             game_game_name: "Auto Roullete",
//                             turnover: 472014.27,
//                             win_loss: -472014.27,
//                             jackpot: -472014.27,
//                             net_income: 472014.27,
//                         },
//                         {
//                             game_game_name: "Mega Ball",
//                             turnover: 472014.27,
//                             win_loss: -472014.27,
//                             jackpot: -472014.27,
//                             net_income: 472014.27,
//                         },
//                     ],
//                 },
//             ],
//         },
//     ],
//     total: {
//         turnover: 472014.27,
//         win_loss: -472014.27,
//         jackpot: -472014.27,
//         net_income: 472014.27,
//     },
// };

const WinLossReport = () => {
    const isMounted = useRef(false);
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.user);
    const { operatorSelectionList } = useSelector((state) => state.operator);

    const isAdmin =
        user?.role?.slug === "super-admin" || user?.role?.slug === "admin";

    const { winLossByProductReport, isGetWinLossByProductReportLoading } =
        useSelector((state) => state.report);

    const [selectedDate, setSelectedDate] = useState(dateFilterOptions[0]);
    const [selectedOperator, setSelectedOperator] = useState(null);

    const [search, setSearch] = useState("");
    const [sortBy, setSortBy] = useState({
        field: "",
        type: "asc",
    });

    const [operatorExtraData, setOperatorExtraData] = useState({
        key_token: null,
        user_type: "operator",
        role_tag: "all",
    });

    const defaultParams = {
        balance_category: "",
        date_from: today(),
        date_to: today(),
    };

    const [data, setData] = useState(defaultParams);
    const [dataSource, setDataSource] = useState([]);

    const tableTheadData = [
        {
            label: "Game Provider",
            field: "game_provider_name",
            subItemField: "game_name",
            sortable: false,
        },
        {
            label: "Loss",
            field: "turnover",
            subItemField: "turnover",
            sortable: false,
            render: (item) => <AmountFormat amount={item?.turnover} />,
            subItemRender: (item) => <AmountFormat amount={item?.turnover} />,
        },
        {
            label: "Win",
            field: "win_loss",
            subItemField: "win_loss",
            sortable: false,
            render: (item) => <AmountFormat amount={item?.win_loss} />,
            subItemRender: (item) => <AmountFormat amount={item?.win_loss} />,
        },
        {
            label: "Jackpot",
            field: "jackpot",
            subItemField: "jackpot",
            sortable: false,
            render: (item) => <AmountFormat amount={item?.jackpot} />,
            subItemRender: (item) => <AmountFormat amount={item?.jackpot} />,
        },
        {
            label: "GGR",
            field: "net_income",
            subItemField: "net_income",
            sortable: false,
            render: (item) => <AmountFormat amount={item?.net_income} />,
            subItemRender: (item) => <AmountFormat amount={item?.net_income} />,
        },
    ];

    useEffect(() => {
        if (isMounted.current) {
            if (selectedDate?.value && selectedDate?.value.length === 2) {
                setData((data) => ({
                    ...data,
                    date_from: selectedDate.value[0],
                    date_to: selectedDate.value[1],
                }));
            }
        } else isMounted.current = true;
    }, [selectedDate]);

    useEffect(() => {
        dispatch(getWinLossByProductReport(data));
    }, [dispatch, data]);

    useEffect(() => {
        if (user) {
            setOperatorExtraData((data) => ({
                ...data,
                key_token: user.keyToken,
            }));
        }
    }, [user]);

    useEffect(() => {
        if (winLossByProductReport && !isGetWinLossByProductReportLoading) {
            setDataSource(winLossByProductReport);
        }
    }, [winLossByProductReport, isGetWinLossByProductReportLoading]);

    return (
        <React.Fragment>
            <Title title={getPathTitle(Routes.WIN_LOSS_BY_PRODUCT_REPORT)} />
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb
                        parent={{
                            isParent: true,
                            pageTitle: "REPORTS",
                            pageLink: getPath(
                                Routes.WIN_LOSS_BY_PRODUCT_REPORT
                            ),
                        }}
                    />
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <Row>
                                    <Col xs={12}>
                                        <Card>
                                            <CardHeader className="align-items-center d-flex tw-border-0">
                                                <h4 className="card-title mb-0 flex-grow-1">
                                                    {getPathTitle(
                                                        Routes.WIN_LOSS_BY_PRODUCT_REPORT
                                                    )}
                                                </h4>
                                            </CardHeader>
                                            <CardBody>
                                                <Row className="mb-3 tw-flex tw-items-end lg:tw-gap-0 tw-gap-2">
                                                    {isAdmin && (
                                                        <Col md={3}>
                                                            <AsyncReactSelect
                                                                label="Operators"
                                                                name="operatorFilter"
                                                                isMulti={false}
                                                                fetchData={
                                                                    getOperatorSelectionList
                                                                }
                                                                data={
                                                                    operatorSelectionList
                                                                }
                                                                onChange={(
                                                                    options
                                                                ) => {
                                                                    setSelectedOperator(
                                                                        options
                                                                    );
                                                                    setData(
                                                                        (
                                                                            data
                                                                        ) => ({
                                                                            ...data,
                                                                            operator_id:
                                                                                options?.value ??
                                                                                null,
                                                                        })
                                                                    );
                                                                }}
                                                                value={
                                                                    selectedOperator
                                                                }
                                                                dataField={{
                                                                    label: "account_no|name",
                                                                    value: "account_no",
                                                                }}
                                                                extraData={
                                                                    operatorExtraData
                                                                }
                                                                sortBy="name asc"
                                                                isClearable
                                                            />
                                                        </Col>
                                                    )}
                                                    <Col md={3}>
                                                        <Select
                                                            label="Balance Category"
                                                            isClearable={false}
                                                            value={
                                                                data.balance_category
                                                            }
                                                            options={balanceCategoryOptions()}
                                                            onChange={(e) => {
                                                                setData({
                                                                    ...data,
                                                                    balance_category:
                                                                        e.target
                                                                            .value,
                                                                });
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col
                                                        md={12}
                                                        className="mb-3 tw-gap-2 pt-2"
                                                    >
                                                        <div className="tw-flex tw-flex-wrap tw-items-center tw-gap-2">
                                                            {dateFilterOptions.map(
                                                                (
                                                                    item,
                                                                    index
                                                                ) => (
                                                                    <Button
                                                                        key={
                                                                            index
                                                                        }
                                                                        color={
                                                                            selectedDate.label ===
                                                                            item.label
                                                                                ? "primary"
                                                                                : "light"
                                                                        }
                                                                        onClick={() => {
                                                                            setSelectedDate(
                                                                                item
                                                                            );
                                                                        }}
                                                                    >
                                                                        {
                                                                            item.label
                                                                        }
                                                                    </Button>
                                                                )
                                                            )}
                                                            <div className="tw-w-full lg:tw-w-1/5">
                                                                <FlatPickerDate
                                                                    name="dateFilter"
                                                                    mode="range"
                                                                    placeholder="From Date - To Date"
                                                                    maxDate={
                                                                        null
                                                                    }
                                                                    onChange={(
                                                                        date
                                                                    ) => {
                                                                        setSelectedDate(
                                                                            (
                                                                                data
                                                                            ) => ({
                                                                                ...data,
                                                                                label: "Custom",
                                                                                value: date.map(
                                                                                    (
                                                                                        value
                                                                                    ) =>
                                                                                        filterDateFormat(
                                                                                            value
                                                                                        )
                                                                                ),
                                                                            })
                                                                        );
                                                                    }}
                                                                    value={
                                                                        selectedDate.value
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                <CardBody>
                                    <CollapsibleTable
                                        customComponents={
                                            <>
                                                {false && (
                                                    <div className="tw-flex tw-flex-row tw-justify-end tw-items-center">
                                                        <Button
                                                            size="sm"
                                                            color="primary"
                                                        >
                                                            <span className="las la-clipboard-list" />
                                                            Generate Report
                                                        </Button>
                                                    </div>
                                                )}
                                            </>
                                        }
                                        search={search}
                                        sortBy={sortBy}
                                        handlerSortBy={setSortBy}
                                        thead={tableTheadData}
                                        data={data}
                                        collection={dataSource}
                                        isLoading={
                                            isGetWinLossByProductReportLoading
                                        }
                                        isCollapsible={true}
                                        hidePaginate={true}
                                        subItemKey="game_providers"
                                        hasTotal={true}
                                        totalFieldStart="game_provider_name"
                                        hasGroup={true}
                                        groupKey="game_offering"
                                        childItemKey="games"
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default WinLossReport;
