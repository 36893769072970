import * as types from "./actionTypes";

export const getPlayerList = (payload) => ({
    type: types.GET_PLAYER_LIST,
    payload,
});

export const setPlayerList = (payload) => ({
    type: types.SET_PLAYER_LIST,
    payload,
});

export const setPlayerListError = () => ({
    type: types.SET_PLAYER_LIST_ERROR,
});

export const getPlayerProfile = (payload) => ({
    type: types.GET_PLAYER_PROFILE,
    payload,
});

export const setPlayerProfile = (payload) => ({
    type: types.SET_PLAYER_PROFILE,
    payload,
});

export const setPlayerProfileError = () => ({
    type: types.SET_PLAYER_PROFILE_ERROR,
});

export const createPlayer = (payload, validation) => ({
    type: types.CREATE_PLAYER,
    payload,
    validation,
});

export const createPlayerSuccess = (payload) => ({
    type: types.CREATE_PLAYER_SUCCESS,
    payload,
});

export const createPlayerError = (payload) => ({
    type: types.CREATE_PLAYER_ERROR,
    payload,
});

export const updatePlayer = (payload, keyToken) => ({
    type: types.UPDATE_PLAYER,
    payload,
    keyToken,
});

export const updatePlayerSuccess = (payload) => ({
    type: types.UPDATE_PLAYER_SUCCESS,
    payload,
});

export const updatePlayerError = (payload) => ({
    type: types.UPDATE_PLAYER_ERROR,
    payload,
});

export const resetPlayerErrors = () => ({
    type: types.RESET_PLAYER_ERRORS,
});

export const updatePlayerWalletBalance = (payload) => ({
    type: types.UPDATE_PLAYER_WALLET_BALANCE,
    payload,
});

export const updateIdVerificationStatus = (
    payload,
    status,
    keyToken,
    setActiveStep
) => ({
    type: types.UPDATE_ID_VERIFICATION_STATUS,
    payload,
    status,
    keyToken,
    setActiveStep,
});

export const updateIdVerificationStatusSuccess = (payload) => ({
    type: types.UPDATE_ID_VERIFICATION_STATUS_SUCCESS,
    payload,
});

export const updateIdVerificationStatusError = (payload) => ({
    type: types.UPDATE_ID_VERIFICATION_STATUS_ERROR,
    payload,
});

export const getVerificationId = (keyToken) => ({
    type: types.GET_VERIFICATION_ID,
    keyToken,
});

export const getVerificationIdSuccess = (payload) => ({
    type: types.GET_VERIFICATION_ID_SUCCESS,
    payload,
});

export const getVerificationIdError = (payload) => ({
    type: types.GET_VERIFICATION_ID_ERROR,
    payload,
});

export const updateFullyVerificationStatus = (payload, status, keyToken) => ({
    type: types.UPDATE_FULLY_VERIFICATION_STATUS,
    payload,
    status,
    keyToken,
});

export const onUpdateFullyVerificationSuccess = (payload) => ({
    type: types.UPDATE_FULLY_VERIFICATION_STATUS_SUCCESS,
    payload,
});

export const onUpdateFullyVerificationFailed = (payload) => ({
    type: types.UPDATE_FULLY_VERIFICATION_STATUS_FAILED,
    payload,
});

export const getUserProfileDocuments = (payload) => ({
    type: types.GET_USER_PROFILE_DOCUMENTS,
    payload,
});

export const onGetUserProfileDocumentsSuccess = (payload) => ({
    type: types.GET_USER_PROFILE_DOCUMENTS_SUCCESS,
    payload,
});

export const onGetUserProfileDocumentsFailed = () => ({
    type: types.GET_USER_PROFILE_DOCUMENTS_FAILED,
});

export const updatePlayerEmailSuccess = (payload) => ({
    type: types.UPDATE_PLAYER_EMAIL_SUCCESS,
    payload,
});

export const getPlayerSelectionList = (payload) => ({
    type: types.GET_PLAYER_SELECTION_LIST,
    payload,
});

export const onGetPlayerSelectionListSuccess = (payload) => ({
    type: types.GET_PLAYER_SELECTION_LIST_SUCCESS,
    payload,
});

export const onGetPlayerSelectionListFailed = (payload) => ({
    type: types.GET_PLAYER_SELECTION_LIST_FAILED,
    payload,
});

export const clearPlayerSelectionList = (payload) => ({
    type: types.CLEAR_PLAYER_SELECTION_LIST,
    payload,
});
