import {
    Button,
    ErrorAlert,
    InputDate,
    InputPassword,
    InputReactSelect,
    InputSelect,
    InputText,
} from "@/components/atoms";
import { playerValidation, userGenderOptions } from "@/constants";
import { userRole } from "@/hooks";
import {
    createPlayer,
    getBarangays,
    getCities,
    getCountries,
    getProvinces,
    getRegions,
    resetAddressesData,
    resetAgentErrors,
} from "@/store/actions";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Form, FormGroup, Row } from "reactstrap";

const CreatePlayerForm = () => {
    const dispatch = useDispatch();
    const { isLoading, errors } = useSelector((state) => state.player);

    const { isAgent } = userRole();

    const { countries, regions, provinces, cities, barangays } = useSelector(
        (state) => state.address
    );
    const [showPassword, setShowPassword] = useState({
        origin: false,
        confirm: false,
    });

    const showPasswordHandler = (field) => {
        setShowPassword({
            ...showPassword,
            [field]: !showPassword[field],
        });
    };

    const userType = "player";

    const [country, setCountry] = useState(null);
    const [addresses, setAddresses] = useState({
        region: "",
        province: "",
        city: "",
        barangay: "",
    });

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            first_name: "",
            last_name: "",
            middle_name: "",
            extension_name: "",
            birth_date: "",
            gender: "",
            occupation: "",
            email: "",
            mobile_no: "",
            country: "",
            region: "",
            province: "",
            city: "",
            barangay: "",
            building_no: "",
            street: "",
            postal_code: "",
            referred_by: "",
            name: null, //nullable
            password: null, //nullable
            password_confirmation: null, //nullable
            user_type: userType,
        },
        validationSchema: playerValidation(),
        onSubmit: (values) => {
            dispatch(createPlayer(values, validation));
        },
    });

    const addressValueHandler = (option, type) => {
        const { value } = option;
        validation.setFieldValue(type, value);
        if (type === "country") {
            dispatch(getRegions());
            validation.setFieldValue("region", "");
            validation.setFieldValue("province", "");
            validation.setFieldValue("city", "");
            validation.setFieldValue("barangay", "");
        }
        if (type === "region") {
            dispatch(getProvinces(value));
            validation.setFieldValue("province", "");
            validation.setFieldValue("city", "");
            validation.setFieldValue("barangay", "");
        } else if (type === "province") {
            dispatch(getCities(value));
            validation.setFieldValue("city", "");
            validation.setFieldValue("barangay", "");
        } else if (type === "city") {
            dispatch(getBarangays(value));
            validation.setFieldValue("barangay", "");
        }
        setAddresses({
            ...addresses,
            [type]: option,
        });
    };

    useEffect(() => {
        dispatch(resetAddressesData());
        dispatch(getCountries());
        dispatch(resetAgentErrors());
    }, [dispatch]);

    const personalInformation = () => {
        return (
            <>
                <p className="text-muted">Personal Information</p>
                <Row className="mb-3">
                    <Col md="3">
                        <FormGroup className="mb-3">
                            <InputText
                                label="Username"
                                name="name"
                                placeholder="Username"
                                validation={validation}
                            />
                        </FormGroup>
                    </Col>
                    <Col md="3">
                        <FormGroup className="mb-3">
                            <InputText
                                label="First Name"
                                name="first_name"
                                placeholder="First name"
                                validation={validation}
                            />
                        </FormGroup>
                    </Col>
                    <Col md="3">
                        <FormGroup className="mb-3">
                            <InputText
                                label="Last Name"
                                name="last_name"
                                placeholder="Last name"
                                validation={validation}
                            />
                        </FormGroup>
                    </Col>
                    <Col md="3">
                        <FormGroup className="mb-3">
                            <InputText
                                label="Middle Name"
                                name="middle_name"
                                placeholder="Middle name"
                                validation={validation}
                            />
                        </FormGroup>
                    </Col>
                    <Col md="3">
                        <FormGroup className="mb-3">
                            <InputText
                                label="Suffix Name"
                                name="extension_name"
                                placeholder="Ex. Jr, Sr etc..."
                                validation={validation}
                            />
                        </FormGroup>
                    </Col>

                    <Col md="3">
                        <FormGroup className="mb-3">
                            <InputDate
                                label="BirthDate"
                                name="birth_date"
                                validation={validation}
                            />
                        </FormGroup>
                    </Col>
                    <Col md="3">
                        <FormGroup className="mb-3">
                            <InputSelect
                                label="Gender"
                                name="gender"
                                validation={validation}
                                options={userGenderOptions}
                            />
                        </FormGroup>
                    </Col>
                    <Col md="3">
                        <FormGroup className="mb-3">
                            <InputText
                                label="Occupation"
                                name="occupation"
                                placeholder="Occupation"
                                validation={validation}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </>
        );
    };

    const contactInformation = () => {
        return (
            <>
                <p className="text-muted">Contact Information</p>
                <Row className="mb-3">
                    <Col md="6">
                        <FormGroup className="mb-3">
                            <InputText
                                label="Email"
                                name="email"
                                placeholder="Email Address"
                                validation={validation}
                            />
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <FormGroup className="mb-3">
                            <InputText
                                label="Mobile Number"
                                name="mobile_no"
                                placeholder="09xxxxxxxxx"
                                validation={validation}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </>
        );
    };

    const securityInformation = () => {
        return (
            <>
                <p className="text-muted">Security Information (optional)</p>
                <Row className="mb-3">
                    <Col md="6">
                        <FormGroup className="mb-3">
                            <InputPassword
                                label="Password"
                                name="password"
                                placeholder="Enter Password"
                                validation={validation}
                                showPassword={showPassword.origin}
                                showPasswordHandler={() =>
                                    showPasswordHandler("origin")
                                }
                            />
                        </FormGroup>
                    </Col>
                    <Col md="6">
                        <FormGroup className="mb-3">
                            <InputPassword
                                label="Confirm Password"
                                name="password_confirmation"
                                placeholder="Confirm Password"
                                validation={validation}
                                showPassword={showPassword.confirm}
                                showPasswordHandler={() =>
                                    showPasswordHandler("confirm")
                                }
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </>
        );
    };

    const addressInformation = () => {
        return (
            <>
                <p className="text-muted">Address Information</p>
                <Row className="mb-3">
                    <Col md="12">
                        <Row>
                            <Col md="3">
                                <FormGroup className="mb-3">
                                    <InputReactSelect
                                        label="Country"
                                        name="country"
                                        onChange={(option) => {
                                            setCountry(option.label);
                                            addressValueHandler(
                                                option,
                                                "country"
                                            );
                                        }}
                                        value={
                                            validation.values.country
                                                ? addresses.country
                                                : ""
                                        }
                                        options={countries}
                                        validation={validation}
                                        validateOnBlur={false}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </Col>
                    {country === "Philippines" && (
                        <>
                            <Col md="3">
                                <FormGroup className="mb-3">
                                    <InputReactSelect
                                        label="Region"
                                        name="region"
                                        onChange={(option) => {
                                            addressValueHandler(
                                                option,
                                                "region"
                                            );
                                        }}
                                        value={
                                            validation.values.region
                                                ? addresses.region
                                                : ""
                                        }
                                        options={regions}
                                        validation={validation}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="3">
                                <FormGroup className="mb-3">
                                    <InputReactSelect
                                        label="Province"
                                        name="province"
                                        onChange={(option) => {
                                            addressValueHandler(
                                                option,
                                                "province"
                                            );
                                        }}
                                        value={
                                            validation.values.province
                                                ? addresses.province
                                                : ""
                                        }
                                        options={provinces}
                                        validation={validation}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="3">
                                <FormGroup className="mb-3">
                                    <InputReactSelect
                                        label="City"
                                        name="city"
                                        onChange={(option) => {
                                            addressValueHandler(option, "city");
                                        }}
                                        value={
                                            validation.values.city
                                                ? addresses.city
                                                : ""
                                        }
                                        options={cities}
                                        validation={validation}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="3">
                                <FormGroup className="mb-3">
                                    <InputReactSelect
                                        label="Barangay"
                                        name="barangay"
                                        onChange={(option) => {
                                            addressValueHandler(
                                                option,
                                                "barangay"
                                            );
                                        }}
                                        value={
                                            validation.values.barangay
                                                ? addresses.barangay
                                                : ""
                                        }
                                        options={barangays}
                                        validation={validation}
                                    />
                                </FormGroup>
                            </Col>
                        </>
                    )}
                </Row>
                <Row className="mb-3">
                    <Col md="4">
                        <FormGroup className="mb-3">
                            <InputText
                                label="Building No."
                                name="building_no"
                                placeholder="Building No"
                                validation={validation}
                            />
                        </FormGroup>
                    </Col>
                    <Col md="4">
                        <FormGroup className="mb-3">
                            <InputText
                                label="Street"
                                name="street"
                                placeholder="Street"
                                validation={validation}
                            />
                        </FormGroup>
                    </Col>
                    <Col md="4">
                        <FormGroup className="mb-3">
                            <InputText
                                label="Postal Code"
                                name="postal_code"
                                placeholder="Postal Code"
                                validation={validation}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </>
        );
    };

    return (
        <>
            <Form
                className="needs-validation"
                onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}
            >
                <ErrorAlert errors={errors} validation={validation} />
                {!isAgent && (
                    <Row className="mb-3">
                        <Col md="3">
                            <FormGroup className="mb-3">
                                <InputText
                                    label="Referred by"
                                    name="referred_by"
                                    placeholder="Account no."
                                    validation={validation}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                )}
                {personalInformation()}
                {securityInformation()}
                {contactInformation()}
                {addressInformation()}
                <div className="border-top py-3 mt-3">
                    <Button
                        color="success"
                        type="submit"
                        className="float-end"
                        text="Submit"
                        isLoading={isLoading}
                    />
                </div>
            </Form>
        </>
    );
};

export default CreatePlayerForm;
