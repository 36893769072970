import * as types from "./actionTypes";

export const getSystemCountries = (payload) => ({
    type: types.GET_SYSTEM_COUNTRIES,
    payload,
});

export const onGetSystemCountriesSuccess = (payload) => ({
    type: types.GET_SYSTEM_COUNTRIES_SUCCESS,
    payload,
});

export const onGetSystemCountriesFailed = () => ({
    type: types.GET_SYSTEM_COUNTRIES_FAILED,
});

export const updateSystemCountryStatus = (payload) => ({
    type: types.UPDATE_SYSTEM_COUNTRY,
    payload,
});

export const onUpdateSystemCountryStatusSuccess = () => ({
    type: types.UPDATE_SYSTEM_COUNTRY_SUCCESS,
});

export const onUpdateSystemCountryStatusFailed = () => ({
    type: types.UPDATE_SYSTEM_COUNTRY_FAILED,
});
