import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Container, Form, Row } from "reactstrap";
import * as Yup from "yup";
import {
    Button,
    InputPassword,
    InputText,
    Title,
    ErrorAlert,
} from "@/components/atoms";
import { getPath, getPathTitle, Routes } from "@/constants";
import {
    loginUser,
    resetUserErrors,
    getUserDeviceDetails,
} from "@/store/actions";
import { Metabets } from "@/resources/images";

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState(false);

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            name: "",
            password: "",
            login_type: "back_office",
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Please Enter Your Email or Username"),
            password: Yup.string().required("Please Enter Your Password"),
        }),
        onSubmit: (values) => {
            dispatch(loginUser(values, navigate));
        },
    });

    useEffect(() => {
        dispatch(resetUserErrors());
        dispatch(getUserDeviceDetails());
    }, [dispatch]);

    const { errors, isLoading } = useSelector((state) => state.user);
    const showPasswordHandler = () => {
        setShowPassword(!showPassword);
    };
    return (
        <React.Fragment>
            <Title title={getPathTitle(Routes.LOGIN)} />
            <div className="auth-page-content">
                <Container>
                    <Row className="justify-content-center">
                        <Col
                            md={8}
                            lg={6}
                            xl={5}
                            className="tw-h-screen tw-flex tw-flex-col tw-justify-center tw-items-center"
                        >
                            <Card className="tw-min-w-full md:tw-min-w-[400px]">
                                <CardBody className="p-4">
                                    <div className="text-center">
                                        <img
                                            src={Metabets}
                                            className="tw-w-32 mb-4"
                                            alt={process.env.REACT_APP_NAME}
                                        />
                                        <h5 className="text-primary">
                                            Welcome Back !
                                        </h5>
                                        <p className="text-muted">
                                            Sign in to continue to{" "}
                                            {process.env.REACT_APP_NAME}
                                        </p>
                                    </div>
                                    <div className="p-2 mt-4">
                                        <Form
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                validation.handleSubmit();
                                                return false;
                                            }}
                                            className="needs-validation"
                                            action="#"
                                        >
                                            <ErrorAlert
                                                type="error"
                                                color="danger"
                                                errors={errors}
                                            />
                                            <div className="mb-3">
                                                <InputText
                                                    label="Email or Username"
                                                    name="name"
                                                    placeholder="Enter Email or Username"
                                                    validation={validation}
                                                />
                                            </div>
                                            <div className="mb-3">
                                                <div className="float-end">
                                                    <Link
                                                        to={getPath(
                                                            Routes.FORGOT_PASSWORD
                                                        )}
                                                        className="text-muted"
                                                    >
                                                        Forgot password?
                                                    </Link>
                                                </div>

                                                <InputPassword
                                                    label="Password"
                                                    name="password"
                                                    placeholder="Enter Password"
                                                    validation={validation}
                                                    showPassword={showPassword}
                                                    showPasswordHandler={
                                                        showPasswordHandler
                                                    }
                                                />
                                            </div>
                                            <div className="mt-4">
                                                <Button
                                                    isLoading={isLoading}
                                                    color="success"
                                                    className="btn btn-success w-100"
                                                    type="submit"
                                                    text="Sign In"
                                                />
                                            </div>
                                        </Form>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Login;
