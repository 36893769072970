import {
    AmountFormat,
    AsyncReactSelect,
    FlatPickerDate,
    InputReactSelect,
    StatusBadge,
    Table,
    TextLink,
    Title,
} from "@/components/atoms";
import BreadCrumb from "@/components/common/BreadCrumb";
import {
    getPath,
    getPathTitle,
    requestStatus,
    Routes,
    userTypeOptions,
} from "@/constants";
import { getAgentList, getPlayerList, getRequestData } from "@/store/actions";
import { arrayToString, defaultDateTimeFormat, today } from "@/utils";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row,
} from "reactstrap";

const WithdrawalRequestReports = () => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.user);
    const { list: agentList } = useSelector((state) => state.agent);
    const { list: playerList } = useSelector((state) => state.player);
    const { list, isLoading } = useSelector((state) => state.request);

    const [userTypeSelected, setUserTypeSelected] = useState(null);

    const [search, setSearch] = useState("");
    const [sortBy, setSortBy] = useState({
        field: "",
        type: "asc",
    });

    const defaultParams = {
        account_no: "",
        per_page: 10,
        page: 1,
        search: "",
        sort_by:
            sortBy.field && sortBy.type ? `${sortBy.field} ${sortBy.type}` : "",
        "date_range[transaction_requests.created_at][from]": today(),
        "date_range[transaction_requests.created_at][to]": today(),
    };

    const [data, setData] = useState(defaultParams);

    const form = useFormik({
        enableReinitialize: true,
        initialValues: {
            userType: "",
            userFilter: "",
            status: "",
            dateFilter: [],
        },
        onSubmit: (values) => {
            setData({
                ...data,
                "filter_by[user_profiles.account_no]": values.userFilter
                    ? arrayToString(
                          values.userFilter.map((item) => {
                              return item.value;
                          })
                      )
                    : null,
                "filter_by[transaction_requests.transaction_request_status_id]":
                    values.status
                        ? arrayToString(
                              values.status.map((item) => {
                                  return item.value;
                              })
                          )
                        : null,
                "date_range[transaction_requests.created_at][from]":
                    values.dateFilter
                        ? values.dateFilter[0]
                        : data[
                              "date_range[transaction_requests.created_at][from]"
                          ],
                "date_range[transaction_requests.created_at][to]":
                    values.dateFilter
                        ? values.dateFilter[1]
                        : data[
                              "date_range[transaction_requests.created_at][to]"
                          ],
            });
        },
    });

    const handleClear = () => {
        setUserTypeSelected(null);
        setData(defaultParams);
        form.resetForm();
    };

    const [extraData, setExtraData] = useState({
        key_token: user.keyToken,
        user_type: form.values.userFilter,
    });

    const tableTheadData = [
        {
            label: "Request No.",
            field: "transaction_request_no",
            sortable: true,
            render: (item) => (
                <TextLink
                    text={item.transaction_request_no}
                    path={getPath(Routes.DEPOSIT_REQUEST_VIEW).replace(
                        ":transactionRequestNo",
                        item?.transaction_request_no
                    )}
                />
            ),
        },
        {
            label: "Account No.",
            field: "account_no",
            sortable: true,
        },
        {
            label: "Amount",
            field: "amount",
            sortable: true,
            render: (item) => <AmountFormat amount={item?.amount} />,
        },
        {
            label: "Datetime Requested",
            field: "created_at",
            sortable: true,
            render: (item) => defaultDateTimeFormat(item.created_at),
        },
        {
            label: "Status",
            field: "status",
            sortable: false,
            render: (item) => (
                <StatusBadge status={item.status} type="request" />
            ),
        },
    ];

    const changeUserType = (option) => {
        setUserTypeSelected(option);
        form.setFieldValue("userType", option);
        form.setFieldValue("userFilter", "");
        setExtraData({
            ...extraData,
            user_type: option.value,
        });
    };

    useEffect(() => {
        dispatch(getRequestData(data, "withdrawal"));
    }, [dispatch, data]);

    return (
        <React.Fragment>
            <Title title={getPathTitle(Routes.WITHDRAWAL_REQUEST_REPORTS)} />
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb
                        parent={{
                            isParent: true,
                            pageTitle: getPathTitle(
                                Routes.WITHDRAWAL_REQUEST_REPORTS
                            ),
                            pageLink: getPath(
                                Routes.WITHDRAWAL_REQUEST_REPORTS
                            ),
                        }}
                    />
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardHeader className="align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">
                                        {getPathTitle(
                                            Routes.WITHDRAWAL_REQUEST_REPORTS
                                        )}
                                    </h4>
                                </CardHeader>
                                <CardBody>
                                    <Row className="mb-3">
                                        <Col md={4}>
                                            <InputReactSelect
                                                label="User Type"
                                                isClearable={false}
                                                value={form.values.userType}
                                                options={userTypeOptions}
                                                onChange={(option) => {
                                                    changeUserType(option);
                                                }}
                                            />
                                        </Col>
                                        <Col md={4}>
                                            <InputReactSelect
                                                label="Status"
                                                isClearable={true}
                                                options={requestStatus}
                                                value={form.values.status}
                                                onChange={(option) => {
                                                    form.setFieldValue(
                                                        "status",
                                                        option
                                                    );
                                                }}
                                                isMulti={true}
                                            />
                                        </Col>
                                        <Col md={4}>
                                            <FlatPickerDate
                                                label="Date"
                                                name="dateFilter"
                                                form={form}
                                                mode="range"
                                                value={form.values.dateFilter}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col>
                                            {userTypeSelected && (
                                                <AsyncReactSelect
                                                    label={
                                                        userTypeSelected?.label
                                                    }
                                                    name="userFilter"
                                                    isMulti={true}
                                                    fetchData={
                                                        userTypeSelected?.value ===
                                                        "player"
                                                            ? getPlayerList
                                                            : getAgentList
                                                    }
                                                    data={
                                                        userTypeSelected?.value ===
                                                        "player"
                                                            ? playerList
                                                            : agentList
                                                    }
                                                    form={form}
                                                    value={
                                                        form.values.userFilter
                                                    }
                                                    dataField={{
                                                        label: "account_no|name",
                                                        value: "account_no",
                                                    }}
                                                    onChange={(options) => {
                                                        form.setFieldValue(
                                                            "userFilter",
                                                            options
                                                        );
                                                    }}
                                                    extraData={extraData}
                                                    sortBy="name asc"
                                                />
                                            )}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} className="text-center">
                                            <Button
                                                color="primary"
                                                className="mx-1"
                                                onClick={() => handleClear()}>
                                                Clear
                                            </Button>
                                            <Button
                                                color="success"
                                                className="mx-1"
                                                onClick={() =>
                                                    form.handleSubmit()
                                                }>
                                                Filter
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <Table
                                        search={search}
                                        handlerSearch={setSearch}
                                        sortBy={sortBy}
                                        handlerSortBy={setSortBy}
                                        thead={tableTheadData}
                                        handlerChangeData={setData}
                                        data={data}
                                        collection={list}
                                        isLoading={isLoading}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default WithdrawalRequestReports;
