import { removeToast, successToast } from "@/components/constants/toast";
import * as API from "@/network/apis/payment-setting";
import { convert2dErrorsArray } from "@/utils";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import * as TYPES from "./actionTypes";
import * as ACTIONS from "./actions";

function* getPaymentMethodPerProvider({ paymentProvider }) {
    try {
        const response = yield call(
            API.getPaymentMethodPerProvider,
            paymentProvider
        );

        if (response.status === 200) {
            yield put(
                ACTIONS.setPaymentMethodPerProviderData(response.data.response, paymentProvider)
            );
        }
    } catch (error) {
        yield put(ACTIONS.setPaymentMethodPerProviderDataError());
    }
}

function* getPaymentMethods() {
    try {
        const response = yield call(API.getPaymentMethods);

        if (response.status === 200) {
            yield put(ACTIONS.setPaymentMethodsData(response.data.response));
        }
    } catch (error) {
        yield put(ACTIONS.setPaymentMethodsDataError());
    }
}

function* getPaymentProviders() {
    try {
        const response = yield call(API.getPaymentProviders);

        if (response.status === 200) {
            yield put(ACTIONS.setPaymentProvidersData(response.data.response));
        }
    } catch (error) {
        yield put(ACTIONS.setPaymentProvidersDataError());
    }
}

function* getAllowedInWithdrawalPaymentProviders() {
    try {
        const response = yield call(API.getAllowedInWithdrawalPaymentProviders);

        if (response.status === 200) {
            yield put(ACTIONS.setAllowedInWithdrawalPaymentProvidersData(response.data.response));
        }
    } catch (error) {
        yield put(ACTIONS.setAllowedInWithdrawalPaymentProvidersDataError());
    }
}

function* updatePaymentMethod({
    payload,
    paymentProvider,
    keyToken,
    initialize,
}) {
    try {
        const response = yield call(
            API.updatePaymentMethod,
            payload,
            paymentProvider,
            keyToken
        );

        if (response.status === 200) {
            let data = response.data.response;
            yield put(ACTIONS.onUpdatePaymentMethodSuccess());
            removeToast();
            successToast("Successfully Updated.");

            initialize();

            if (data.value_format !== "boolean") {
                document.getElementById(data.key_token).value = data.value;
            }
        }
    } catch (error) {
        let errors = !Array.isArray(error?.response?.data?.response)
            ? convert2dErrorsArray(error?.response?.data?.response)
            : [error.response.data.message];
        yield put(ACTIONS.onUpdatePaymentMethodError(errors));
    }
}

function* updatePaymentMethodConfig({ payload, keyToken, initialize }) {
    try {
        const response = yield call(
            API.updatePaymentMethodConfig,
            payload,
            keyToken
        );

        if (response.status === 200) {
            let data = response.data.response;
            yield put(ACTIONS.onUpdatePaymentMethodConfigSuccess());
            removeToast();
            successToast("Successfully Updated.");

            initialize();

            if (data.value_format !== "boolean") {
                document.getElementById(data.key_token).value = data.value;
            }
        }
    } catch (error) {
        let errors = !Array.isArray(error?.response?.data?.response)
            ? convert2dErrorsArray(error?.response?.data?.response)
            : [error.response.data.message];
        yield put(ACTIONS.onUpdatePaymentMethodConfigError(errors));
    }
}

function* updatePaymentProvider({ payload, keyToken, initialize }) {
    try {
        const response = yield call(
            API.updatePaymentProvider,
            payload,
            keyToken
        );

        if (response.status === 200) {
            let data = response.data.response;
            yield put(ACTIONS.onUpdatePaymentProviderSuccess());
            removeToast();
            successToast("Successfully Updated.");

            initialize();

            if (data.value_format !== "boolean") {
                document.getElementById(data.key_token).value = data.value;
            }
        }
    } catch (error) {
        let errors = !Array.isArray(error?.response?.data?.response)
            ? convert2dErrorsArray(error?.response?.data?.response)
            : [error.response.data.message];
        yield put(ACTIONS.onUpdatePaymentProviderError(errors));
    }
}

function* watchGetPaymentMethodPerProvider() {
    yield takeLatest(TYPES.GET_PAYMENT_METHOD_PER_PROVIDER, getPaymentMethodPerProvider);
}
function* watchGetPaymentMethods() {
    yield takeLatest(TYPES.GET_PAYMENT_METHODS, getPaymentMethods);
}
function* watchGetPaymentProviders() {
    yield takeLatest(TYPES.GET_PAYMENT_PROVIDERS, getPaymentProviders);
}
function* watchGetAllowedInWithdrawalPaymentProviders() {
    yield takeLatest(TYPES.GET_ALLOWED_IN_WITHDRAWAL_PAYMENT_PROVIDERS, getAllowedInWithdrawalPaymentProviders);
}
function* watchUpdatePaymentMethod() {
    yield takeLatest(TYPES.UPDATE_PAYMENT_METHOD, updatePaymentMethod);
}
function* watchUpdatePaymentMethodConfig() {
    yield takeLatest(
        TYPES.UPDATE_PAYMENT_METHOD_CONFIG,
        updatePaymentMethodConfig
    );
}
function* watchUpdatePaymentProvider() {
    yield takeLatest(TYPES.UPDATE_PAYMENT_PROVIDER, updatePaymentProvider);
}

function* paymentSettingSagas() {
    yield all([
        fork(watchGetPaymentMethodPerProvider),
        fork(watchGetPaymentMethods),
        fork(watchGetPaymentProviders),
        fork(watchUpdatePaymentMethod),
        fork(watchUpdatePaymentMethodConfig),
        fork(watchUpdatePaymentProvider),
        fork(watchGetAllowedInWithdrawalPaymentProviders),
    ]);
}

export default paymentSettingSagas;
