import { paymentSettingState } from "@/types/states";
import * as types from "./actionTypes";

const initialState = paymentSettingState;

const paymentSetting = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_PAYMENT_METHOD_PER_PROVIDER:
        case types.GET_PAYMENT_METHODS:
        case types.GET_PAYMENT_PROVIDERS:
        case types.GET_ALLOWED_IN_WITHDRAWAL_PAYMENT_PROVIDERS:
            state = {
                ...state,
                isLoading: true,
            };
            break;
        case types.SET_PAYMENT_METHOD_PER_PROVIDER_DATA:
            var dataKey = null;
            switch (action.paymentProvider) {
                case "swift-pay":
                    dataKey = "paymentMethodSwiftPayList";
                    break;
                case "ak-pay":
                    dataKey = "paymentMethodAkPayList";
                    break;
                case "h2pay":
                    dataKey = "paymentMethodH2PayList";
                    break;
                case "monetix":
                    dataKey = "paymentMethodMonetixList";
                    break;
                case "coins-paid":
                    dataKey = "paymentMethodCoinsPaidList";
                    break;
                default:
                    break;
            }

            state = {
                ...state,
                [dataKey]: action.payload,
                isLoading: false,
            };
            break;
        case types.SET_PAYMENT_METHODS_DATA:
            state = {
                ...state,
                paymentMethods: action.payload,
                isLoading: false,
            };
            break;
        case types.SET_PAYMENT_PROVIDERS_DATA:
            state = {
                ...state,
                paymentProviders: action.payload,
                isLoading: false,
            };
            break;
        case types.SET_ALLOWED_IN_WITHDRAWAL_PAYMENT_PROVIDERS_DATA:
            state = {
                ...state,
                allowedInWithdrawalPaymentProviders: action.payload,
                isLoading: false,
            };
            break;
        case types.SET_PAYMENT_PROVIDERS_DATA_ERROR:
        case types.SET_ALLOWED_IN_WITHDRAWAL_PAYMENT_PROVIDERS_DATA_ERROR:
        case types.SET_PAYMENT_METHODS_DATA_ERROR:
            state = {
                ...state,
                isLoading: false,
            };
            break;
        case types.UPDATE_PAYMENT_METHOD:
        case types.UPDATE_PAYMENT_METHOD_CONFIG:
        case types.UPDATE_PAYMENT_PROVIDER:
            state = {
                ...state,
                isSubmit: true,
            };
            break;
        case types.UPDATE_PAYMENT_METHOD_SUCCESS:
        case types.UPDATE_PAYMENT_METHOD_CONFIG_SUCCESS:
        case types.UPDATE_PAYMENT_PROVIDER_SUCCESS:
            state = {
                ...state,
                isSubmit: false,
            };
            break;
        case types.UPDATE_PAYMENT_METHOD_ERROR:
        case types.UPDATE_PAYMENT_METHOD_CONFIG_ERROR:
        case types.UPDATE_PAYMENT_PROVIDER_ERROR:
            state = {
                ...state,
                isSubmit: false,
                errors: action.payload,
            };
            break;
        case types.RESET_ERRORS:
            state = {
                ...state,
                errors: [],
            };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default paymentSetting;
