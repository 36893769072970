import { errorToast, successToast } from "@/components/constants/toast";
import * as API from "@/network/apis/transaction";
import {
    updateAgentWalletBalance,
    updatePlayerWalletBalance,
    updateOperatorWalletBalance,
    getUserData,
} from "@/store/actions";
import { convert2dErrorsArray } from "@/utils";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import * as ACTIONS from "./actions";
import * as TYPES from "./actionTypes";
import store from "@/store";

function* getTransactionData({ payload, transactionType, betType }) {
    try {
        const response = yield call(
            API.getTransactionData,
            payload,
            transactionType,
            betType
        );

        if (response.status === 200) {
            yield put(ACTIONS.setTransactionData(response.data.response));
        }
    } catch (error) {
        errorToast(error?.response?.data?.message ?? (`${error?.response?.status ?? "Experiencing excessive load, an unexpected"} error has occurred.`));
        yield put(ACTIONS.setTransactionDataError());
    }
}

function* getTransactionInfo({ payload }) {
    try {
        const response = yield call(API.getTransactionInfo, payload);

        if (response.status === 200) {
            yield put(ACTIONS.setTransactionInfo(response.data.response));
        }
    } catch (error) {
        yield put(ACTIONS.setTransactionInfoError());
    }
}

function* userDeposit({ payload, setIsOpen, initialize, userType }) {
    try {
        const response = yield call(API.userDeposit, payload);

        if (response.status === 200) {
            yield put(ACTIONS.userDepositSuccess());
            store.dispatch(getUserData());
            setIsOpen(false);
            initialize();
            successToast("Amount Successfully Deposit.");
        }
    } catch (error) {
        let errors = !Array.isArray(error?.response?.data?.response)
            ? convert2dErrorsArray(error?.response?.data?.response)
            : [error.response.data.message];
        yield put(ACTIONS.userDepositError(errors));
    }
}

function* userDepositRequest({ payload, setIsOpen }) {
    try {
        const response = yield call(API.userDepositRequest, payload);

        if (response.status === 200) {
            yield put(ACTIONS.userDepositRequestSuccess());
            setIsOpen(false);
            successToast("Deposit Request Successfully Submit.");
        }
    } catch (error) {
        let errors = !Array.isArray(error?.response?.data?.response)
            ? convert2dErrorsArray(error?.response?.data?.response)
            : [error.response.data.message];
        yield put(ACTIONS.userDepositRequestError(errors));
        errorToast(error.response.data.message);
    }
}

function* userWithdrawalRequest({ payload, setIsOpen }) {
    try {
        const response = yield call(API.userWithdrawalRequest, payload);

        if (response.status === 200) {
            yield put(ACTIONS.userWithdrawalRequestSuccess());
            setIsOpen(false);
            successToast("Withdrawal Request Successfully Submit.");
        }
    } catch (error) {
        let errors = !Array.isArray(error?.response?.data?.response)
            ? convert2dErrorsArray(error?.response?.data?.response)
            : [error.response.data.message];
        yield put(ACTIONS.userWithdrawalRequestError(errors));
    }
}

function* getPaymentMethodData() {
    try {
        const response = yield call(API.getPaymentMethodData);

        if (response.status === 200) {
            const collection = response.data.response;
            const data = Object.keys(collection).map((key) => ({
                label: key,
                options: collection[key].map(({ key_token, name }) => ({
                    value: key_token,
                    label: name,
                })),
            }));
            yield put(ACTIONS.setPaymentMethodDataSuccess(data));
        }
    } catch (error) {
        let errors = !Array.isArray(error?.response?.data?.response)
            ? convert2dErrorsArray(error?.response?.data?.response)
            : [error.response.data.message];
        yield put(ACTIONS.setPaymentMethodDataError(errors));
    }
}

function* addFund({ payload, setIsOpen, initialize }) {
    try {
        const response = yield call(API.addFund, payload);

        if (response.status === 200) {
            yield put(ACTIONS.addFundSuccess());
            yield put(
                updateOperatorWalletBalance(
                    response.data.response.user.user_profile.balance
                )
            );
            store.dispatch(getUserData());
            setIsOpen(false);
            initialize();
            successToast("Betting Credit Fund Successfully added.");
        }
    } catch (error) {
        let errors = !Array.isArray(error?.response?.data?.response)
            ? convert2dErrorsArray(error?.response?.data?.response)
            : [error.response.data.message];
        yield put(ACTIONS.addFundError(errors));
    }
}

function* processCommissionAsync({payload, initialize}) {
    try {
        const response = yield call(API.processCommission, payload);

        if (response.status === 200) {
            initialize();
            yield put(ACTIONS.onProcessCommissionSuccess());
            successToast("Manual process commission successfully executed.");
        }
    } catch (error) {
        yield put(ACTIONS.onProcessCommissionFailed());
        errorToast(
            error?.response?.data?.message ??
                "Something went wrong, please try again."
        );
    }
}

function* getTipTransaction({ payload }) {
    try {
        const response = yield call(
            API.getTipTransaction,
            payload,
        );

        if (response.status === 200) {
            yield put(ACTIONS.setTipTransaction(response.data.response));
        }
    } catch (error) {
        errorToast(error?.response?.data?.message ?? (`${error?.response?.status ?? "Experiencing excessive load, an unexpected"} error has occurred.`));
        yield put(ACTIONS.setTipTransactionError());
    }
}

function* watchGetTransactionData() {
    yield takeLatest(TYPES.GET_TRANSACTION_DATA, getTransactionData);
}
function* watchGetTransactionInfo() {
    yield takeLatest(TYPES.GET_TRANSACTION_INFO, getTransactionInfo);
}
function* watchUserDeposit() {
    yield takeLatest(TYPES.USER_DEPOSIT, userDeposit);
}
function* watchUserDepositRequest() {
    yield takeLatest(TYPES.USER_DEPOSIT_REQUEST, userDepositRequest);
}
function* watchUserWithdrawalRequest() {
    yield takeLatest(TYPES.USER_WITHDRAWAL_REQUEST, userWithdrawalRequest);
}
function* watchGetPaymentMethodData() {
    yield takeLatest(TYPES.GET_PAYMENT_METHOD_DATA, getPaymentMethodData);
}
function* watchAddFund() {
    yield takeLatest(TYPES.ADD_FUND, addFund);
}
function* watchProcessCommission() {
    yield takeLatest(TYPES.PROCESS_COMMISSION, processCommissionAsync);
}
function* watchGetTipTransaction() {
    yield takeLatest(TYPES.GET_TIP_TRANSACTION, getTipTransaction);
}

function* transactionSagas() {
    yield all([
        fork(watchGetTransactionData),
        fork(watchGetTransactionInfo),
        fork(watchUserDeposit),
        fork(watchUserDepositRequest),
        fork(watchGetPaymentMethodData),
        fork(watchUserWithdrawalRequest),
        fork(watchAddFund),
        fork(watchProcessCommission),
        fork(watchGetTipTransaction),
    ]);
}

export default transactionSagas;
