import { Button, ErrorAlert, InputAmount, TextArea } from "@/components/atoms";
import { deductFundValidation } from "@/constants";
import { addFund, resetTransactionErrors } from "@/store/actions";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button as ButtonComponent,
    Col,
    Form,
    FormGroup,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
} from "reactstrap";

const Index = ({ isOpen, setIsOpen, userProfilekeyToken, initialize }) => {
    const dispatch = useDispatch();
    const { isLoading, errors } = useSelector((state) => state.transaction);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            user_profile_key_token: userProfilekeyToken,
            amount: "",
            credit: "",
            remarks: "",
            type: "debit",
        },
        validationSchema: deductFundValidation(),
        onSubmit: (values) => {
            dispatch(addFund(values, setIsOpen, initialize));
        },
    });

    useEffect(() => {
        dispatch(resetTransactionErrors());
    }, [dispatch]);

    return (
        <Modal id="fundModal" isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
            <ModalHeader>
                <div className="modal-title">BCF Debit</div>
            </ModalHeader>
            <ModalBody>
                <Form
                    className="needs-validation"
                    onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}
                >
                    <ErrorAlert errors={errors} validation={validation} />
                    <Row className="mb-3">
                        <Col md={12}>
                            <FormGroup className="mb-3">
                                <InputAmount
                                    label="Debit fund"
                                    name="credit"
                                    validation={validation}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={12}>
                            <FormGroup className="mb-3">
                                <InputAmount
                                    label="Debit Amount"
                                    name="amount"
                                    validation={validation}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={12}>
                            <FormGroup className="mb-3">
                                <TextArea
                                    label="Remarks"
                                    name="remarks"
                                    validation={validation}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-end">
                            <ButtonComponent
                                color="light"
                                onClick={() => setIsOpen(!isOpen)}
                                className="me-2"
                            >
                                Close
                            </ButtonComponent>
                            <Button
                                color="success"
                                type="submit"
                                text="Submit"
                                isLoading={isLoading}
                            />
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
        </Modal>
    );
};

export default Index;
