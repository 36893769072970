import React from "react";

const ProgressBar = ({ progress = 0 }) => {
    return (
        <div className="progress animated-progress">
            <div
                className="progress-bar progress-bar-striped progress-bar-animated"
                style={{ width: `${progress}%` }}
            >
                <div className="label">{progress}%</div>
            </div>
        </div>
    );
};

export default ProgressBar;
