import { Error404 } from "@/pages";
import { createBrowserHistory as history } from "history";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useAllRoutes } from "@/routes/allRoutes";
import { ProtectedRoute, PublicRoute } from "@/routes/AuthRoutes";

const Index = ({ isAuth }) => {
    const { publicRoutes, authProtectedRoutes } = useAllRoutes();
    return (
        <React.Fragment>
            <Router history={history}>
                <Routes>
                    {publicRoutes.map(({ path, component: Component }, idx) => (
                        <Route
                            path={path}
                            element={
                                <PublicRoute>
                                    <Component />
                                </PublicRoute>
                            }
                            key={idx}
                            exact={true}
                        />
                    ))}
                    {authProtectedRoutes
                        .filter((item) => item.permission === true)
                        .map(({ path, component: Component }, idx) => (
                            <Route
                                path={path}
                                element={
                                    <ProtectedRoute isAuth={isAuth}>
                                        <Component />
                                    </ProtectedRoute>
                                }
                                key={idx}
                                exact={true}
                            />
                        ))}
                    <Route path="*" element={<Error404 />} />
                </Routes>
            </Router>
        </React.Fragment>
    );
};

export default Index;
