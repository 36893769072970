import { InputSwitch, Title } from "@/components/atoms";
import BreadCrumb from "@/components/common/BreadCrumb";
import { confirmation } from "@/components/constants/common";
import { Routes, getPath, getPathTitle } from "@/constants";
import {
    getPromotionCategoryList,
    updatePromotionCategoryStatus,
} from "@/store/actions";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row,
    Table,
} from "reactstrap";

const PromotionCategories = () => {
    const dispatch = useDispatch();
    const { promotionCategoryList, isLoading } = useSelector(
        (state) => state.promotion
    );

    const changeStatus = (item) => {
        confirmation({
            title: item.title,
            text: `Are you sure you want to ${
                item.is_active ? "DEACTIVATE" : "ACTIVE"
            }`,
            icon: "warning",
            confirmButtonText: "Confirm",
            action: () => {
                dispatch(updatePromotionCategoryStatus(item.key_token));
            },
        });
    };

    useEffect(() => {
        dispatch(getPromotionCategoryList());
    }, [dispatch]);

    return (
        <React.Fragment>
            <Title title={getPathTitle(Routes.PROMOTION_CATEGORIES)} />
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb
                        parent={{
                            isParent: true,
                            pageTitle: getPathTitle(
                                Routes.PROMOTION_CATEGORIES
                            ),
                            pageLink: getPath(Routes.PROMOTION_CATEGORIES),
                        }}
                    />
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardHeader className="align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">
                                        {getPathTitle(
                                            Routes.PROMOTION_CATEGORIES
                                        )}
                                    </h4>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <div
                                                className={`table-responsive text-center`}
                                            >
                                                {isLoading && (
                                                    <div className="loading-overlay"></div>
                                                )}
                                                <Table className="table-bordered table-hover align-middle table-nowrap mb-0 table-sm">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th>
                                                                Promotion Name
                                                            </th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {promotionCategoryList?.map(
                                                            (item, key) => (
                                                                <tr key={key}>
                                                                    <td>
                                                                        {
                                                                            item.title
                                                                        }
                                                                    </td>
                                                                    <td className="d-flex justify-content-center">
                                                                        <InputSwitch
                                                                            label={
                                                                                item.is_active
                                                                                    ? "Active"
                                                                                    : "Inactive"
                                                                            }
                                                                            id={`promotion-category-status-${item.slug}`}
                                                                            isChecked={
                                                                                item.is_active
                                                                            }
                                                                            color="success"
                                                                            onChange={() =>
                                                                                changeStatus(
                                                                                    item
                                                                                )
                                                                            }
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            )
                                                        )}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default PromotionCategories;
