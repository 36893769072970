import { AmountFormat, StatusBadge, Table, Select } from "@/components/atoms";
import { commissionCategoryOptions } from "@/constants";
import { getUserCommissions } from "@/store/actions";
import { defaultDateFormat } from "@/utils";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Row } from "reactstrap";
import ProfileLayout from "../Layout";

const Commissions = () => {
    const dispatch = useDispatch();
    const { userCommissionList, user, isLoading } = useSelector(
        (state) => state.user
    );

    const [search, setSearch] = useState("");
    const [sortBy, setSortBy] = useState({
        field: "",
        type: "asc",
    });

    const [data, setData] = useState({
        account_no: user.accountNo,
        per_page: 10,
        page: 1,
        search: "",
        "filter_by[status]": "transferred",
        "filter_by[balance_categories.key_name]": "",
        sort_by:
            sortBy.field && sortBy.type ? `${sortBy.field} ${sortBy.type}` : "",
    });

    const tableTheadData = [
        {
            label: "Commission No.",
            field: "commission_no",
            sortable: true,
        },
        {
            label: "Balance Category",
            field: "balance_categories.name",
            sortable: true,
            render: (item) => `${item.balance_category}`,
        },
        {
            label: "Rate",
            field: "rate",
            sortable: true,
            render: (item) => `${item.rate}%`,
        },
        {
            label: "Amount",
            field: "amount",
            sortable: true,
            render: (item) => <AmountFormat amount={item.amount} />,
        },
        {
            label: "Commission Before",
            field: "commission_before",
            sortable: true,
            render: (item) => <AmountFormat amount={item.commission_before} />,
        },
        {
            label: "Commission After",
            field: "commission_after",
            sortable: true,
            render: (item) => <AmountFormat amount={item.commission_after} />,
        },
        {
            label: "Status",
            field: "status",
            sortable: true,
            render: (item) => (
                <StatusBadge status={item.status} type="commission" />
            ),
        },
        {
            label: "Date",
            field: "created_at",
            sortable: true,
            render: (item) => defaultDateFormat(item.created_at),
        },
    ];

    const changecommissionType = (type) => {
        setSearch("");
        setData({
            ...data,
            "filter_by[status]": type,
            search: "",
            page: 1,
        });
    };

    useEffect(() => {
        dispatch(getUserCommissions(data));
    }, [dispatch, data]);

    return (
        <ProfileLayout>
            <Row className="mb-3">
                <Col>
                    <Button
                        color={
                            data?.["filter_by[status]"] === "transferred"
                                ? "primary"
                                : "light"
                        }
                        onClick={() => changecommissionType("transferred")}
                        className="m-1"
                    >
                        Transferred
                    </Button>
                    <Button
                        color={
                            data?.["filter_by[status]"] === "pending"
                                ? "primary"
                                : "light"
                        }
                        onClick={() => changecommissionType("pending")}
                        className="m-1"
                    >
                        Pending
                    </Button>
                </Col>
                <Col className="d-flex flex-row-reverse">
                    <div className="d-flex">
                        <Select
                            name="balanceCategory"
                            isClearable={false}
                            value={
                                data["filter_by[balance_categories.key_name]"]
                            }
                            options={commissionCategoryOptions}
                            onChange={(e) => {
                                setData((data) => ({
                                    ...data,
                                    "filter_by[balance_categories.key_name]":
                                        e.target.value,
                                    page: 1,
                                }));
                            }}
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <Table
                        search={search}
                        handlerSearch={setSearch}
                        sortBy={sortBy}
                        handlerSortBy={setSortBy}
                        thead={tableTheadData}
                        handlerChangeData={setData}
                        data={data}
                        collection={userCommissionList}
                        isLoading={isLoading}
                        size="sm"
                    />
                </Col>
            </Row>
        </ProfileLayout>
    );
};

export default Commissions;
