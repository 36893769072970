import { AmountFormat } from "@/components/atoms";
import { getSetting } from "@/hooks";
import React, { useMemo, useState } from "react";
import {
    Card,
    CardBody,
    Dropdown,
    DropdownMenu,
    DropdownToggle,
    UncontrolledTooltip,
} from "reactstrap";

const WalletDropdown = ({ user }) => {
    const isAgent =
        user?.role?.slug === "master-agent" || user?.role?.slug === "agent";

    const separateSportsBettingCommission =
        getSetting("separate_sports_betting_commission") === "1";

    const [isWalletDropdown, setIsWalletDropdown] = useState(false);

    const toggleWalletDropdown = () => {
        setIsWalletDropdown(!isWalletDropdown);
    };

    const balances = useMemo(() => {
        return [
            {
                isHeader: true,
                label: "Balances",
                isShow: true,
            },
            {
                label: process.env.REACT_APP_BCF_LABEL,
                value: user?.balance?.fiat?.php?.bcf || "-",
                isShow: true,
            },
            {
                label: "PHP",
                value: user?.balance?.fiat?.php?.merchant || "-",
                isShow: true,
            },
            ...process.env.REACT_APP_LOTTO_ENABLED === "true" ? [
                {
                    label: `${process.env.REACT_APP_BCF_LABEL} (Lotto)`,
                    value: user?.balance?.fiat?.php?.bcf_lotto || "-",
                    isShow: true,
                },
                {
                    label: `${process.env.REACT_APP_DEFAULT_CURRENCY} (Lotto)`,
                    value: user?.balance?.fiat?.php?.merchant_lotto || "-",
                    isShow: true,
                },
            ] : [],
            {
                isHeader: true,
                label: "Commissions",
                isShow: isAgent,
            },
            {
                label: `${process.env.REACT_APP_BCF_LABEL} ${
                    user?.separateSportsBettingCommissionBcf ||
                    separateSportsBettingCommission
                        ? "(E-Games/Slots)"
                        : ""
                }`,
                value: user?.balance?.fiat?.php?.commission_bcf || "-",
                isShow: isAgent,
            },
            {
                label: `PHP ${
                    user?.separateSportsBettingCommissionMerchant ||
                    separateSportsBettingCommission
                        ? "(E-Games/Slots)"
                        : ""
                }`,
                value: user?.balance?.fiat?.php?.commission_merchant || "-",
                isShow: isAgent,
            },
            {
                label: `${process.env.REACT_APP_BCF_LABEL} (Sports/E-Sports/Live Casino)`,
                value: user?.balance?.fiat?.php?.commission_bcf_sports || "-",
                isShow:
                    isAgent &&
                    (separateSportsBettingCommission ||
                        user?.separateSportsBettingCommissionBcf),
            },
            {
                label: "PHP (Sports/E-Sports/Live Casino)",
                value:
                    user?.balance?.fiat?.php?.commission_merchant_sports || "-",
                isShow:
                    isAgent &&
                    (separateSportsBettingCommission ||
                        user?.separateSportsBettingCommissionMerchant),
            },
            ...process.env.REACT_APP_LOTTO_ENABLED === "true" ? [
                {
                    label: `${process.env.REACT_APP_BCF_LABEL} (Lotto)`,
                    value: user?.balance?.fiat?.php?.commission_bcf_lotto || "-",
                    isShow: isAgent ,
                },
                {
                    label: `${process.env.REACT_APP_DEFAULT_CURRENCY} (Lotto)`,
                    value:
                        user?.balance?.fiat?.php?.commission_merchant_lotto || "-",
                    isShow: isAgent,
                },
            ] : []
        ];
    }, [isAgent, separateSportsBettingCommission, user]);

    return (
        <React.Fragment>
            <Dropdown
                isOpen={isWalletDropdown}
                toggle={toggleWalletDropdown}
                className="topbar-head-dropdown ms-1 header-item"
            >
                <DropdownToggle
                    id="wallet-tooltip"
                    type="button"
                    tag="button"
                    className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                >
                    <i className="bx bx-wallet fs-22" />
                </DropdownToggle>
                <DropdownMenu
                    className="dropdown-menu-xl dropdown-menu-end p-0 dropdown-menu-cart"
                    aria-labelledby="page-header-cart-dropdown"
                >
                    <Card className="mb-0 p-0">
                        <CardBody>
                            <div className="table-card">
                                <table className="table mb-0">
                                    <tbody>
                                        {balances.map((item, key) => (
                                            <tr
                                                key={key}
                                                className={
                                                    !item.isShow ? "d-none" : ""
                                                }
                                            >
                                                {item?.label && (
                                                    <td
                                                        className={`${
                                                            item.isHeader &&
                                                            "fw-medium tw-text-primary"
                                                        }`}
                                                    >
                                                        {item.label}
                                                    </td>
                                                )}
                                                <td
                                                    className="tw-text-right"
                                                    colSpan={
                                                        item?.label ? 1 : 2
                                                    }
                                                >
                                                    {item.value && (
                                                        <AmountFormat
                                                            amount={item.value}
                                                        />
                                                    )}
                                                </td>
                                                {/* <td
                                                    className={`fw-medium ${
                                                        item?.isHeader &&
                                                        "text-center"
                                                    }`}
                                                >
                                                    {item.label}
                                                </td>
                                                {!item?.isHeader && (
                                                    <td className="tw-text-right">
                                                        <AmountFormat
                                                            amount={item.value}
                                                        />
                                                    </td>
                                                )} */}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </CardBody>
                    </Card>
                </DropdownMenu>
            </Dropdown>
            <UncontrolledTooltip placement="bottom" target="wallet-tooltip">
                Wallet
            </UncontrolledTooltip>
        </React.Fragment>
    );
};

export default WalletDropdown;
