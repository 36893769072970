import { axiosInstance } from "./index";

export const getWinLossReport = async (params) => {
    return await axiosInstance.get(
        `/bo/report/win-loss/by-agent${
            params.accountNo ? `/${params.accountNo}` : ""
        }`,
        { params }
    );
};

export const getWinLossByProductReport = async (params) => {
    return await axiosInstance.get(`/bo/report/win-loss/by-product`, {
        params,
    });
};

export const generateBatchPayoutReport = async (params) => {
    return await axiosInstance.post(
        `/bo/transaction-request/generate/batch-payout`,
        params
    );
};

export const getGeneratedReports = async (params) => {
    return await axiosInstance.get(`/bo/report-queue/get-paginated`, {
        params,
    });
};

export const getLoginLogs = async (params) => {
    return await axiosInstance.get(`/bo/login-log/get-paginated`, {
        params,
    });
};

export const getMultipleIPDetected = async (params) => {
    return await axiosInstance.get(`/bo/multiple-ip-detected/get-paginated`, {
        params,
    });
};
