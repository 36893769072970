export const GET_PROMOTION_CATEGORY_LIST = "GET_PROMOTION_CATEGORY_LIST";
export const SET_PROMOTION_CATEGORY_LIST = "SET_PROMOTION_CATEGORY_LIST";
export const SET_PROMOTION_CATEGORY_LIST_ERROR = "SET_PROMOTION_CATEGORY_LIST_ERROR";

export const UPDATE_PROMOTION_CATEGORY_STATUS = "UPDATE_PROMOTION_CATEGORY_STATUS";
export const UPDATE_PROMOTION_CATEGORY_STATUS_SUCCESS = "UPDATE_PROMOTION_CATEGORY_STATUS_SUCCESS";
export const UPDATE_PROMOTION_CATEGORY_STATUS_ERROR = "UPDATE_PROMOTION_CATEGORY_STATUS_ERROR";

export const GET_PROMOTION_LIST = "GET_PROMOTION_LIST";
export const SET_PROMOTION_LIST = "SET_PROMOTION_LIST";
export const SET_PROMOTION_LIST_ERROR = "SET_PROMOTION_LIST_ERROR";

export const GET_PROMOTION_CATEGORY_BY_KEY_TOKEN = "GET_PROMOTION_CATEGORY_BY_KEY_TOKEN";
export const SET_PROMOTION_CATEGORY_BY_KEY_TOKEN = "SET_PROMOTION_CATEGORY_BY_KEY_TOKEN";
export const SET_PROMOTION_CATEGORY_BY_KEY_TOKEN_ERROR = "SET_PROMOTION_CATEGORY_BY_KEY_TOKEN_ERROR";

export const CREATE_PROMOTION = "CREATE_PROMOTION";
export const CREATE_PROMOTION_SUCCESS = "CREATE_PROMOTION_SUCCESS";
export const CREATE_PROMOTION_ERROR = "CREATE_PROMOTION_ERROR";

export const GET_PROMOTION_BY_NO = "GET_PROMOTION_BY_NO";
export const SET_PROMOTION_BY_NO = "SET_PROMOTION_BY_NO";
export const SET_PROMOTION_BY_NO_ERROR = "SET_PROMOTION_BY_NO_ERROR";

export const UPDATE_PROMOTION_STATUS = "UPDATE_PROMOTION_STATUS";
export const UPDATE_PROMOTION_STATUS_SUCCESS = "UPDATE_PROMOTION_STATUS_SUCCESS";
export const UPDATE_PROMOTION_STATUS_ERROR = "UPDATE_PROMOTION_STATUS_ERROR";

export const GET_PROMOTION_TRANSACTION_LIST = "GET_PROMOTION_TRANSACTION_LIST";
export const SET_PROMOTION_TRANSACTION_LIST = "SET_PROMOTION_TRANSACTION_LIST";
export const SET_PROMOTION_TRANSACTION_LIST_ERROR = "SET_PROMOTION_TRANSACTION_LIST_ERROR";

export const GET_PROMOTION_TRANSACTION_VIEW = "GET_PROMOTION_TRANSACTION_VIEW";
export const SET_PROMOTION_TRANSACTION_VIEW = "SET_PROMOTION_TRANSACTION_VIEW";
export const SET_PROMOTION_TRANSACTION_VIEW_ERROR = "SET_PROMOTION_TRANSACTION_VIEW_ERROR";

export const UPDATE_PROMOTION = "UPDATE_PROMOTION";
export const UPDATE_PROMOTION_SUCCESS = "UPDATE_PROMOTION_SUCCESS";
export const UPDATE_PROMOTION_ERROR = "UPDATE_PROMOTION_ERROR";