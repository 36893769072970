import { DeleteModal, Table, Title } from "@/components/atoms";
import BreadCrumb from "@/components/common/BreadCrumb";
import { Routes, getPath, getPathTitle } from "@/constants";
import { getGeneratedReports } from "@/store/actions";
import { getStatusColor } from "@/utils";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    Badge,
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row,
} from "reactstrap";

const GeneratedReport = () => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.user);
    const { generatedReports, isGetGeneratedReportsLoading } = useSelector(
        (state) => state.report
    );

    const [showModal, setShowModal] = useState(false);
    const [search, setSearch] = useState("");

    const [sortBy, setSortBy] = useState({
        field: "updated_at",
        type: "desc",
    });

    const [data, setData] = useState({
        per_page: 10,
        page: 1,
        sort_by: "created_at desc",
    });

    const tableTheadData = [
        {
            label: "Report Name",
            field: "report_name",
            render: (item) => item?.report_name,
        },
        {
            label: "Status",
            field: "status",
            render: (item) => (
                <Badge color={getStatusColor(item.status)}>{item.status}</Badge>
            ),
        },
        {
            label: "Expires At",
            field: "expires_at",
        },
        {
            label: "Action",
            field: "action",
            sortable: false,
            render: (item) => (
                <>
                    {item.status === "success" && (
                        <a href={item?.file} target="_blank" rel="noreferrer">
                            <Button color="primary" size="sm">
                                <span className="ri-download-2-line" />
                            </Button>
                        </a>
                    )}
                </>
            ),
        },
    ];

    useEffect(() => {
        dispatch(getGeneratedReports(data));
    }, [dispatch, data]);

    return (
        <React.Fragment>
            <Title title={getPathTitle(Routes.GENERATED_REPORT)} />
            <DeleteModal
                show={showModal}
                onDeleteClick={() => setShowModal(false)}
                onCloseClick={() => setShowModal(false)}
            />
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb
                        parent={{
                            isParent: true,
                            pageTitle: getPathTitle(Routes.GENERATED_REPORT),
                            pageLink: getPath(Routes.GENERATED_REPORT),
                        }}
                    />
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardHeader className="align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">
                                        {getPathTitle(Routes.GENERATED_REPORT)}
                                    </h4>
                                </CardHeader>
                                <CardBody>
                                    <Table
                                        sortBy={sortBy}
                                        handlerSortBy={setSortBy}
                                        thead={tableTheadData}
                                        handlerChangeData={setData}
                                        data={data}
                                        collection={generatedReports}
                                        isLoading={isGetGeneratedReportsLoading}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default GeneratedReport;
