import { getPath, Routes } from "@/constants";
import { UserDefaultPhoto } from "@/resources/images";
import { userLogout } from "@/store/actions";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from "reactstrap";
const ProfileDropdown = ({ user }) => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [isProfileDropdown, setIsProfileDropdown] = useState(false);
    const toggleProfileDropdown = () => {
        setIsProfileDropdown(!isProfileDropdown);
    };
    const isAdmin =
        user?.role?.slug === "super-admin" || user?.role?.slug === "admin";
    return (
        <React.Fragment>
            <Dropdown
                isOpen={isProfileDropdown}
                toggle={toggleProfileDropdown}
                className="ms-sm-3 header-item topbar-user"
            >
                <DropdownToggle tag="button" type="button" className="btn">
                    <span className="d-flex align-items-center">
                        <img
                            className="rounded-circle header-profile-user"
                            src={UserDefaultPhoto}
                            alt="Header Avatar"
                        />
                        <span className="text-start ms-xl-2">
                            <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                                {user.firstName} {user.lastName}
                            </span>
                            <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">
                                {user.role.name}
                            </span>
                        </span>
                    </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                    <h6 className="dropdown-header">
                        Welcome {user.firstName} {user.lastName}
                    </h6>
                    <DropdownItem
                        onClick={() =>
                            navigate(getPath(Routes.ACCOUNT_PROFILE))
                        }
                    >
                        <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
                        <span className="align-middle">Profile</span>
                    </DropdownItem>
                    <div className="dropdown-divider"></div>
                    <DropdownItem href="/">
                        <i className="mdi mdi-lifebuoy text-muted fs-16 align-middle me-1"></i>{" "}
                        <span className="align-middle">Help</span>
                    </DropdownItem>
                    <DropdownItem
                        onClick={() => dispatch(userLogout(navigate))}
                    >
                        <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>{" "}
                        <span className="align-middle" data-key="t-logout">
                            Logout
                        </span>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export default ProfileDropdown;
