import { Button, ErrorAlert, InputText } from "@/components/atoms";
import { updateReferralCodeValidation } from "@/constants";
import {
    resetUpdateReferralCodeErrors,
    updateReferralCode,
} from "@/store/actions";
import { gamelobbyLinks, getAgentReferralLink } from "@/utils";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Button as ButtonComponent,
    Col,
    Form,
    FormGroup,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
} from "reactstrap";

const Index = ({ user, isOpen, setIsOpen }) => {
    const dispatch = useDispatch();
    const { isLoading, updateReferralErrors } = useSelector(
        (state) => state.user
    );

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            referral_code: user?.referralCode.toUpperCase(),
        },
        validationSchema: updateReferralCodeValidation(),
        onSubmit: (values) => {
            dispatch(updateReferralCode(values, setIsOpen));
        },
    });

    useEffect(() => {
        dispatch(resetUpdateReferralCodeErrors());
    }, [dispatch]);

    return (
        <Modal
            id="updateReferralCodeModal"
            isOpen={isOpen}
            toggle={() => setIsOpen(!isOpen)}
        >
            <ModalHeader>
                <div className="modal-title">Update Referral Code</div>
            </ModalHeader>
            <ModalBody>
                <Form
                    className="needs-validation"
                    onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                    }}
                >
                    <ErrorAlert
                        errors={updateReferralErrors}
                        validation={validation}
                    />
                    <Row className="mb-3">
                        <Col md={12}>
                            <FormGroup className="mb-3">
                                <InputText
                                    label="Referral Code"
                                    name="referral_code"
                                    placeholder="Referral Code"
                                    validation={validation}
                                    onKeyUp={(event) => {
                                        event.preventDefault();
                                        validation.setFieldValue(
                                            "referral_code",
                                            event.target.value.toUpperCase()
                                        );
                                    }}
                                />
                                <div className="mt-2 text-muted">
                                    Link preview : <br />
                                    <br />
                                    {process.env.REACT_APP_ENV === "production"
                                        ? gamelobbyLinks.map((item, index) => (
                                              <div key={index}>
                                                  <span>{item.name}</span>
                                                  <p>
                                                      {item.link +
                                                          `/?referralCode=${validation?.values?.referral_code}`}
                                                  </p>
                                              </div>
                                          ))
                                        : getAgentReferralLink(
                                              validation?.values?.referral_code
                                          )}
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-end">
                            <ButtonComponent
                                color="light"
                                onClick={() => setIsOpen(!isOpen)}
                                className="me-2"
                            >
                                Close
                            </ButtonComponent>
                            <Button
                                color="success"
                                type="submit"
                                text="Save"
                                isLoading={isLoading}
                            />
                        </Col>
                    </Row>
                </Form>
            </ModalBody>
        </Modal>
    );
};

export default Index;
