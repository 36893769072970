import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import * as API from "@/network/apis/user";
import * as ACTIONS from "./actions";
import * as TYPES from "./actionTypes";

function* getRegions() {
    try {
        const response = yield call(API.getRegions);

        if (response.status === 200) {
            const regions = response.data.response;
            const data = regions.map(({ key_token, name }) => ({
                value: key_token,
                label: name,
            }));
            yield put(ACTIONS.getRegionsSuccess(data));
        }
    } catch (error) {
        yield put(ACTIONS.getRegionsError(error?.response?.data?.response));
    }
}

function* getProvinces({ regionKeyToken }) {
    try {
        const response = yield call(API.getProvinces, regionKeyToken);

        if (response.status === 200) {
            const provinces = response.data.response;
            const data = provinces.map(({ key_token, name }) => ({
                value: key_token,
                label: name,
            }));
            yield put(ACTIONS.getProvincesSuccess(data));
        }
    } catch (error) {
        yield put(ACTIONS.getProvincesError(error?.response?.data?.response));
    }
}

function* getCities({ provinceKeyToken }) {
    try {
        const response = yield call(API.getCities, provinceKeyToken);

        if (response.status === 200) {
            const cities = response.data.response;
            const data = cities.map(({ key_token, name }) => ({
                value: key_token,
                label: name,
            }));
            yield put(ACTIONS.getCitiesSuccess(data));
        }
    } catch (error) {
        yield put(ACTIONS.getCitiesError(error?.response?.data?.response));
    }
}

function* getBarangays({ cityKeyToken }) {
    try {
        const response = yield call(API.getBarangays, cityKeyToken);

        if (response.status === 200) {
            const barangays = response.data.response;
            const data = barangays.map(({ key_token, name }) => ({
                value: key_token,
                label: name,
            }));
            yield put(ACTIONS.getBarangaysSuccess(data));
        }
    } catch (error) {
        yield put(ACTIONS.getBarangaysError(error?.response?.data?.response));
    }
}

function* getCountriesAsync() {
    try {
        const response = yield call(API.getCountries);

        if (response.status === 200) {
            const countries = response.data.response;
            const data = countries.map(({ key_token, name }) => ({
                value: key_token,
                label: name,
            }));
            yield put(ACTIONS.onGetCountriesSuccess(data));
        }
    } catch (error) {
        yield put(
            ACTIONS.onGetCountriesFailed(error?.response?.data?.response)
        );
    }
}

function* watchGetRegions() {
    yield takeLatest(TYPES.GET_REGIONS, getRegions);
}
function* watchGetProvinces() {
    yield takeLatest(TYPES.GET_PROVINCES, getProvinces);
}
function* watchGetCities() {
    yield takeLatest(TYPES.GET_CITIES, getCities);
}
function* watchGetBarangays() {
    yield takeLatest(TYPES.GET_BARANGAYS, getBarangays);
}
function* watchGetCountries() {
    yield takeLatest(TYPES.GET_COUNTRIES, getCountriesAsync);
}
function* addressSagas() {
    yield all([
        fork(watchGetRegions),
        fork(watchGetProvinces),
        fork(watchGetCities),
        fork(watchGetBarangays),
        fork(watchGetCountries),
    ]);
}

export default addressSagas;
