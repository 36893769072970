import { arrayToString } from "@/utils/common";
import React, { useEffect, useMemo, useRef } from "react";
import { useDispatch } from "react-redux";
import AsyncSelect from "react-select/async";
import { Label } from "reactstrap";
import classNames from "classnames";
const AsyncReactSelect = ({
    label,
    name,
    fetchData,
    data,
    perPage = 20,
    page = 1,
    sortBy = "title asc",
    dataField = { label: "title", value: "key_token" },
    form,
    isMulti = true,
    extraData = {},
    ...rest
}) => {
    const isMounted = useRef(false);
    const dispatch = useDispatch();
    const params = useMemo(() => {
        return {
            per_page: perPage,
            page: page,
            sort_by: sortBy,
            search: null,
            ...extraData,
        };
    }, [perPage, page, sortBy, extraData]);

    const sleep = (ms) => {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve();
            }, ms);
        });
    };

    const promiseOptions = async (inputValue) => {
        sleep(1000);
        let initialData = {
            per_page: perPage,
            page: page,
            sort_by: sortBy,
            search: inputValue,
        };
        await dispatch(
            fetchData({
                ...initialData,
                ...extraData,
            })
        );

        return transformData();
    };

    const handleInputChange = (newValue) => {
        return newValue;
    };

    const transformData = () => {
        return (
            data?.data?.map((option) => {
                let splitLabel = dataField.label.split("|");
                let finalLabel = "";
                if (splitLabel.length > 1) {
                    let arr = splitLabel.map((label) => option[label]);
                    finalLabel = arrayToString(arr);
                } else {
                    finalLabel = option[dataField.label];
                }
                return typeof dataField === "function"
                    ? dataField(option)
                    : {
                          value: option[dataField.value],
                          label: finalLabel,
                      };
            }) ?? []
        );
    };

    const onHandleChange = (option) => {
        if (rest?.onChange) {
            rest.onChange(option);
        } else {
            let data = isMulti
                ? option.map((item) => item.value)
                : option.value;
            form.setFieldValue(name, data);
        }
    };

    useEffect(() => {
        if (isMounted.current) {
            dispatch(fetchData(params));
        } else isMounted.current = true;
    }, [dispatch, params, fetchData]);

    return (
        <>
            <Label>{label}</Label>
            <AsyncSelect
                onMenuClose={false}
                name={name}
                className={
                    form?.errors[name] ? "border border-danger rounded" : ""
                }
                classNames={{
                    control: () =>
                        classNames(
                            "dark:tw-bg-dark-input bg-transparent border border-gray rounded input tw-shadow-none"
                        ),
                    menuList: () => classNames("dark:tw-bg-dark"),
                    option: () =>
                        classNames(
                            "dark:tw-bg-dark dark:hover:tw-bg-primary dark:tw-text-white"
                        ),
                    multiValue: () => classNames("dark:tw-bg-dark"),
                    multiValueLabel: () => classNames("dark:tw-text-white"),
                    singleValue: () => classNames("dark:tw-text-white"),
                    input: () => classNames("dark:tw-text-white"),
                }}
                cacheOptions={false}
                defaultOptions={transformData()}
                isMulti={isMulti}
                loadOptions={promiseOptions}
                onInputChange={handleInputChange}
                onChange={onHandleChange}
                {...rest}
            />
            {form?.errors[name] ? (
                <small className="text-danger text-sm">
                    {form.errors[name]}
                </small>
            ) : null}
        </>
    );
};

export default AsyncReactSelect;
