import { toFormData } from "../../utils/common";
import { axiosInstance } from "./index";

export const getPromotionCategoryList = async () => {
    return await axiosInstance.get(`/bo/promotion-category/get-all`);
};

export const updatePromotionCategoryStatus = async (keyToken) => {
    return await axiosInstance.put(
        `/bo/promotion-category/update-status/${keyToken}`
    );
};

export const getPromotionCategoryByKeyToken = async (keyToken) => {
    return await axiosInstance.get(
        `/bo/promotion-category/get-by-key-token/${keyToken}`
    );
};

export const getPromotionList = async (params) => {
    return await axiosInstance.get(`/bo/promotion/get-paginated`, { params });
};

export const getPromotionTransactionList = async (params) => {
    return await axiosInstance.get(`/bo/promotion-transaction/get-paginated`, { params });
};

export const getPromotionTransactionView = async (params) =>
    await axiosInstance.get(`/bo/promotion-transaction/get-by-reference-no/${params}`);

export const getPromotionByNo = async (promotionNo) => {
    return await axiosInstance.get(
        `/bo/promotion/get-by-promotion-no/${promotionNo}`
    );
};

export const createPromotion = async (params) => {
    const formData = toFormData(params);

    return await axiosInstance({
        method: "post",
        url: "/bo/promotion/create",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
    // return await axiosInstance.post(`/bo/promotion/create`, params);
};

export const updatePromotionStatus = async (keyToken) => {
    return await axiosInstance.put(`/bo/promotion/update-status/${keyToken}`);
};

export const updatePromotion = async (keyToken, payload, requestType) => {
 

    if(requestType === "update-thumbnail" || requestType === "update-banner" ) {
        let formData = toFormData(payload);

        return await axiosInstance({
            method: "post",
            url: `/bo/promotion/${requestType}/${keyToken}`,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
          });

    } else {
        return await axiosInstance.put(`/bo/promotion/${requestType}/${keyToken}`, payload);
    }

    
};
