import {
    AmountFormat,
    StatusBadge,
    Title,
    UserLink,
    InputSwitch,
} from "@/components/atoms";
import BreadCrumb from "@/components/common/BreadCrumb";
import {
    FullyVerificationModal,
    SemiVerificationModal,
} from "@/components/molecules";
import { Routes, getPath, getPathTitle } from "@/constants";
import { UserDefaultPhoto } from "@/resources/images";
import {
    getPlayerProfile,
    updateCrossBettingProtection,
} from "@/store/actions";
import { defaultDateFormat, defaultDateTimeFormat } from "@/utils";
import classnames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { confirmation } from "@/components/constants/common";

import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Input,
    Label,
    Nav,
    NavItem,
    NavLink,
    Row,
} from "reactstrap";

const Layout = ({ children }) => {
    const isMounted = useRef(false);
    const dispatch = useDispatch();
    const nagivate = useNavigate();
    const location = useLocation();
    const { isUserDepositSuccess } = useSelector((state) => state.transaction);
    const { profile, isLoading, userProfileDocuments } = useSelector(
        (state) => state.player
    );
    const { accountNo } = useParams();

    const changeProtectionSettings = () => {
        if (profile?.crossBettingProtectionEditable ?? false) {
            confirmation({
                text: `Are you sure you want to ${
                    profile.crossBettingProtection ? "DEACTIVATE" : "ACTIVATE"
                } cross betting protection of this player`,
                icon: "warning",
                confirmButtonText: "Confirm",
                action: () => {
                    dispatch(
                        updateCrossBettingProtection(
                            { value: profile.crossBettingProtection ? 0 : 1 },
                            profile.userProfilekeyToken
                        )
                    );
                    dispatch(getPlayerProfile(accountNo));
                },
            });
        }
    };

    const details = [
        {
            label: "GP Player ID",
            value: profile.gpUserId,
            isShow: true,
        },
        {
            label: "Username",
            value: profile.name,
            isShow: true,
        },
        {
            label: "Date Joined",
            value: defaultDateFormat(profile.createdAt),
            isShow: true,
        },
        {
            label: "Date Deactivated",
            value: defaultDateFormat(profile.deactivatedAt),
            isShow: profile.status === "deactivated" ? true : false,
        },
        {
            label: "Date Banned",
            value: defaultDateFormat(profile.bannedAt),
            isShow: profile.status === "banned" ? true : false,
        },
        {
            label: "Last Login",
            value: profile.lastLogin
                ? defaultDateTimeFormat(profile.lastLogin?.created_at)
                : "-",
            isShow: true,
        },
        {
            label: "IP Addresses:",
            render: () =>
                profile?.lastLogin?.ip_addresses &&
                profile?.lastLogin?.ip_addresses.map((ip, index) => (
                    <div className="tw-text-right" key={index}>
                        {!index && (
                            <span className="badge tw-bg-[#FF7759] tw-mr-2">
                                Current
                            </span>
                        )}{" "}
                        {ip}
                    </div>
                )),
            isShow: !!profile?.lastLogin?.ip_addresses,
        },
        {
            label: "Cross Betting Protection",
            render: () => (
                <div>
                    <InputSwitch
                        id={`merchant-option-${profile.crossBettingProtection}`}
                        isChecked={profile?.crossBettingProtection ?? false}
                        color="success"
                        disabled={
                            !profile?.crossBettingProtectionEditable ?? false
                        }
                        onChange={() => changeProtectionSettings()}
                    />
                </div>
            ),
            isShow: true,
        },
    ];

    const balances = [
        {
            label: process.env.REACT_APP_BCF_LABEL,
            value: profile?.balance?.fiat?.php?.bcf ? (
                <AmountFormat amount={profile?.balance?.fiat?.php?.bcf} />
            ) : (
                "-"
            ),
            isShow: true,
        },
        {
            label: process.env.REACT_APP_DEFAULT_CURRENCY,
            value: profile?.balance?.fiat?.php?.merchant ? (
                <AmountFormat amount={profile?.balance?.fiat?.php?.merchant} />
            ) : (
                "-"
            ),
            isShow: true,
        },
        {
            label: `Commissions (${process.env.REACT_APP_DEFAULT_CURRENCY})`,
            value: profile?.balance?.fiat?.php?.commission_merchant_player ? (
                <AmountFormat
                    amount={
                        profile?.balance?.fiat?.php?.commission_merchant_player
                    }
                />
            ) : (
                "-"
            ),
            isShow: true,
        },
        {
            label: `Pending commissions (${process.env.REACT_APP_DEFAULT_CURRENCY})`,
            value: profile?.balance?.fiat?.php
                ?.pending_commission_merchant_player ? (
                <AmountFormat
                    amount={
                        profile?.balance?.fiat?.php
                            ?.pending_commission_merchant_player
                    }
                />
            ) : (
                "-"
            ),
            isShow: true,
        },
        ...(process.env.REACT_APP_LOTTO_ENABLED === "true"
            ? [
                  {
                      label: `${process.env.REACT_APP_BCF_LABEL} Lotto`,
                      value: profile?.balance?.fiat?.php?.bcf_lotto ? (
                          <AmountFormat
                              amount={profile?.balance?.fiat?.php?.bcf_lotto}
                          />
                      ) : (
                          "-"
                      ),
                      isShow: true,
                  },
                  {
                      label: "PHP Lotto",
                      value: profile?.balance?.fiat?.php?.merchant_lotto ? (
                          <AmountFormat
                              amount={
                                  profile?.balance?.fiat?.php?.merchant_lotto
                              }
                          />
                      ) : (
                          "-"
                      ),
                      isShow: true,
                  },
              ]
            : []),
        {
            value: (
                <div>
                    <h4 className="card-title text-center mb-0 flex-grow-1">
                        Turnover
                    </h4>
                </div>
            ),
            isShow: true,
        },
        {
            label: (
                <div className="tw-whitespace-nowrap">
                    {process.env.REACT_APP_BCF_LABEL}
                </div>
            ),
            value: profile?.total_data?.fiat?.php?.bcf?.turnover_requirement ? (
                <AmountFormat
                    amount={
                        profile?.total_data?.fiat?.php?.bcf
                            ?.turnover_requirement
                    }
                />
            ) : (
                "-"
            ),
            isShow: true,
        },
        {
            label: <div className="tw-whitespace-nowrap">PHP</div>,
            value: profile?.total_data?.fiat?.php?.merchant
                ?.turnover_requirement ? (
                <AmountFormat
                    amount={
                        profile?.total_data?.fiat?.php?.merchant
                            ?.turnover_requirement
                    }
                />
            ) : (
                "-"
            ),
            isShow: true,
        },
        ...(process.env.REACT_APP_LOTTO_ENABLED === "true"
            ? [
                  {
                      label: (
                          <div className="tw-whitespace-nowrap">
                              {process.env.REACT_APP_BCF_LABEL} Lotto
                          </div>
                      ),
                      value: profile?.total_data?.fiat?.php?.bcf_lotto
                          ?.turnover_requirement ? (
                          <AmountFormat
                              amount={
                                  profile?.total_data?.fiat?.php?.bcf_lotto
                                      ?.turnover_requirement
                              }
                          />
                      ) : (
                          "-"
                      ),
                      isShow: true,
                  },
                  {
                      label: (
                          <div className="tw-whitespace-nowrap">PHP Lotto</div>
                      ),
                      value: profile?.total_data?.fiat?.php?.merchant_lotto
                          ?.turnover_requirement ? (
                          <AmountFormat
                              amount={
                                  profile?.total_data?.fiat?.php?.merchant_lotto
                                      ?.turnover_requirement
                              }
                          />
                      ) : (
                          "-"
                      ),
                      isShow: true,
                  },
              ]
            : []),
    ];

    const nav = [
        {
            label: "Personal Details",
            path: getPath(Routes.PLAYER_PROFILE).replace(
                ":accountNo",
                accountNo
            ),
        },
        {
            label: "Wallet",
            path: getPath(Routes.PLAYER_WALLET).replace(
                ":accountNo",
                accountNo
            ),
        },
        {
            label: "Request",
            path: getPath(Routes.PLAYER_REQUEST).replace(
                ":accountNo",
                accountNo
            ),
        },
        {
            label: "Bet History",
            path: getPath(Routes.PLAYER_BET_HISTORY).replace(
                ":accountNo",
                accountNo
            ),
        },
        {
            label: "Commissions",
            path: getPath(Routes.PLAYER_COMMISSIONS).replace(
                ":accountNo",
                accountNo
            ),
        },
    ];

    const [semiVerifiedModal, setShowSemiVerifiedModal] = useState(false);
    const [fullyVerfiedModal, setShowFullyVerifiedModal] = useState(false);

    useEffect(() => {
        if (accountNo !== ":accountNo") {
            dispatch(getPlayerProfile(accountNo));
        }
    }, [dispatch, accountNo]);

    useEffect(() => {
        if (isMounted.current) {
            dispatch(getPlayerProfile(accountNo));
        } else isMounted.current = true;
    }, [dispatch, accountNo, isUserDepositSuccess]);

    return (
        <React.Fragment>
            <Title title={`Player | ${accountNo}`} />
            {semiVerifiedModal && (
                <SemiVerificationModal
                    isOpen={semiVerifiedModal}
                    profile={profile}
                    setIsOpen={setShowSemiVerifiedModal}
                    isLoading={isLoading}
                    userProfileDocuments={userProfileDocuments}
                />
            )}
            {fullyVerfiedModal && (
                <FullyVerificationModal
                    isOpen={fullyVerfiedModal}
                    profile={profile}
                    setIsOpen={setShowFullyVerifiedModal}
                    isLoading={isLoading}
                    userProfileDocuments={userProfileDocuments}
                />
            )}
            <div className="page-content placeholder-glow">
                <Container fluid>
                    <BreadCrumb
                        parent={{
                            isParent: false,
                            pageTitle: getPathTitle(Routes.PLAYERS),
                            pageLink: getPath(Routes.PLAYERS),
                        }}
                        childs={[
                            {
                                pageTitle: getPathTitle(Routes.PLAYER_PROFILE),
                                pageLink: getPath(Routes.PLAYER_PROFILE),
                                isActive: true,
                            },
                        ]}
                    />
                    <Row>
                        <Col md={3}>
                            <Card>
                                <CardBody className="p-4">
                                    <div className="text-center">
                                        <div className="profile-user position-relative d-inline-block mx-auto  mb-4">
                                            <img
                                                src={UserDefaultPhoto}
                                                className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                                                alt="user-profile"
                                            />
                                            <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                                                <Input
                                                    id="profile-img-file-input"
                                                    type="file"
                                                    className="profile-img-file-input"
                                                />
                                                <Label
                                                    htmlFor="profile-img-file-input"
                                                    className="profile-photo-edit avatar-xs"
                                                >
                                                    <span className="avatar-title rounded-circle bg-light text-body">
                                                        <i className="ri-camera-fill"></i>
                                                    </span>
                                                </Label>
                                            </div>
                                        </div>
                                        <p className="text-muted mb-0">
                                            {profile.accountNo}
                                        </p>
                                        <h5 className="fs-16 mb-1">
                                            {profile.firstName}{" "}
                                            {profile.lastName}
                                        </h5>
                                        <p className="text-muted mb-0">
                                            {profile.role.name}
                                        </p>
                                        <StatusBadge status={profile.status} />
                                        {!isLoading && (
                                            <>
                                                <div className="mt-4">
                                                    <Button
                                                        color={
                                                            profile.verificationStep >
                                                            3
                                                                ? "success"
                                                                : profile.verificationStep ===
                                                                  3
                                                                ? "danger"
                                                                : "primary"
                                                        }
                                                        size="sm"
                                                        className="w-100 d-flex justify-content-center align-items-center gap-1"
                                                        onClick={() =>
                                                            setShowSemiVerifiedModal(
                                                                true
                                                            )
                                                        }
                                                    >
                                                        Semi Verification
                                                        Process
                                                        {profile.verificationStep >
                                                        3 ? (
                                                            <span className="la la-check-circle-o" />
                                                        ) : profile.verificationStep ===
                                                          8 ? (
                                                            <span className="ri-close-circle-line" />
                                                        ) : null}
                                                    </Button>
                                                </div>
                                                {profile.verificationStep >
                                                    3 && (
                                                    <div className="mt-2">
                                                        <Button
                                                            color={
                                                                profile.verificationStep >
                                                                8
                                                                    ? "success"
                                                                    : profile.verificationStep ===
                                                                      8
                                                                    ? "danger"
                                                                    : "primary"
                                                            }
                                                            size="sm"
                                                            className="w-100 d-flex justify-content-center align-items-center gap-1"
                                                            onClick={() =>
                                                                setShowFullyVerifiedModal(
                                                                    true
                                                                )
                                                            }
                                                        >
                                                            Fully Verification
                                                            Process
                                                            {profile.verificationStep >
                                                            8 ? (
                                                                <span className="la la-check-circle-o" />
                                                            ) : profile.verificationStep ===
                                                              8 ? (
                                                                <span className="ri-close-circle-line" />
                                                            ) : null}
                                                        </Button>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <div className="table-card">
                                        <table className="table mb-0">
                                            <tbody>
                                                {details.map((item, key) => (
                                                    <tr
                                                        key={key}
                                                        className={
                                                            !item.isShow
                                                                ? "d-none"
                                                                : ""
                                                        }
                                                    >
                                                        {item?.label && (
                                                            <td className="fw-medium">
                                                                {item.label}
                                                            </td>
                                                        )}
                                                        <td
                                                            colSpan={
                                                                item?.label
                                                                    ? 1
                                                                    : 2
                                                            }
                                                        >
                                                            {item?.render
                                                                ? item?.render()
                                                                : item.value}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardHeader className="align-items-center d-flex">
                                    <h4 className="card-title text-center mb-0 flex-grow-1">
                                        Balances
                                    </h4>
                                </CardHeader>
                                <CardBody>
                                    <div className="table-card">
                                        <table className="table mb-0">
                                            <tbody>
                                                {balances.map((item, key) => (
                                                    <tr
                                                        key={key}
                                                        className={
                                                            !item.isShow
                                                                ? "d-none"
                                                                : ""
                                                        }
                                                    >
                                                        {item?.label && (
                                                            <td className="fw-medium">
                                                                {item.label}
                                                            </td>
                                                        )}
                                                        <td
                                                            className="tw-text-right"
                                                            colSpan={
                                                                item?.label
                                                                    ? 1
                                                                    : 2
                                                            }
                                                        >
                                                            {item.value}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </CardBody>
                            </Card>
                            {profile?.referrer && (
                                <Card>
                                    <CardHeader className="align-items-center d-flex">
                                        <h4 className="card-title text-center mb-0 flex-grow-1">
                                            Uplines
                                        </h4>
                                    </CardHeader>
                                    <CardBody>
                                        {profile?.referrer?.player && (
                                            <div className="flex text-center justify-content-center mb-3">
                                                <h6>Player</h6>
                                                {`${profile?.referrer?.player?.first_name} ${profile?.referrer?.player?.last_name} | `}
                                                <UserLink
                                                    text={
                                                        profile?.referrer
                                                            ?.player?.account_no
                                                    }
                                                    forRole="player"
                                                    path={getPath(
                                                        Routes.AGENT_PROFILE
                                                    ).replace(
                                                        ":accountNo",
                                                        profile?.referrer
                                                            ?.player?.account_no
                                                    )}
                                                />
                                            </div>
                                        )}
                                        {profile?.referrer?.agent && (
                                            <div className="flex text-center justify-content-center mb-3">
                                                <h6>Agent</h6>
                                                {`${profile?.referrer?.agent?.first_name} ${profile?.referrer?.agent?.last_name} | `}
                                                <UserLink
                                                    text={
                                                        profile?.referrer?.agent
                                                            ?.account_no
                                                    }
                                                    forRole="agent"
                                                    path={getPath(
                                                        Routes.AGENT_PROFILE
                                                    ).replace(
                                                        ":accountNo",
                                                        profile?.referrer?.agent
                                                            ?.account_no
                                                    )}
                                                />
                                            </div>
                                        )}
                                        {profile?.referrer?.master_agent && (
                                            <div className="flex text-center justify-content-center mb-3">
                                                <h6>Master Agent</h6>
                                                {`${profile?.referrer?.master_agent?.first_name} ${profile?.referrer?.master_agent?.last_name} | `}
                                                <UserLink
                                                    text={
                                                        profile?.referrer
                                                            ?.master_agent
                                                            ?.account_no
                                                    }
                                                    forRole="master-agent"
                                                    path={getPath(
                                                        Routes.SUPER_AGENT_PROFILE
                                                    ).replace(
                                                        ":accountNo",
                                                        profile?.referrer
                                                            ?.master_agent
                                                            ?.account_no
                                                    )}
                                                />
                                            </div>
                                        )}
                                        {profile?.referrer?.operator && (
                                            <div className="flex text-center justify-content-center mb-3">
                                                <h6>Operator</h6>
                                                {`${profile?.referrer?.operator?.first_name} ${profile?.referrer?.operator?.last_name} | `}
                                                <UserLink
                                                    text={
                                                        profile?.referrer
                                                            ?.operator
                                                            ?.account_no
                                                    }
                                                    forRole="super-admin"
                                                    path={getPath(
                                                        Routes.OPERATOR_PROFILE
                                                    ).replace(
                                                        ":accountNo",
                                                        profile?.referrer
                                                            ?.operator
                                                            ?.account_no
                                                    )}
                                                />
                                            </div>
                                        )}
                                    </CardBody>
                                </Card>
                            )}
                        </Col>

                        <Col md={9}>
                            <Card>
                                <CardHeader>
                                    <Nav
                                        className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                        role="tablist"
                                    >
                                        {nav.map((item, key) => (
                                            <NavItem key={key}>
                                                <NavLink
                                                    className={classnames({
                                                        active:
                                                            item.path ===
                                                            location.pathname,
                                                    })}
                                                    onClick={() => {
                                                        nagivate(item.path);
                                                    }}
                                                    type="button"
                                                >
                                                    <i className="far fa-user"></i>
                                                    {item.label}
                                                </NavLink>
                                            </NavItem>
                                        ))}
                                    </Nav>
                                </CardHeader>
                                <CardBody className="p-4">{children}</CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Layout;
