import { StatusBadge, Title } from "@/components/atoms";
import BreadCrumb from "@/components/common/BreadCrumb";
import { getPath, getPathTitle, Routes } from "@/constants";
import { UserDefaultPhoto } from "@/resources/images";
import { getAdminProfile } from "@/store/actions";
import { defaultDateFormat, defaultDateTimeFormat } from "@/utils";
import classnames from "classnames";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Input,
    Label,
    Nav,
    NavItem,
    NavLink,
    Row,
} from "reactstrap";

const Layout = ({ children }) => {
    const { profile, isLoading } = useSelector((state) => state.admin);
    const { accountNo } = useParams();
    const dispatch = useDispatch();
    const nagivate = useNavigate();
    const location = useLocation();

    const details = [
        {
            label: "Role",
            value: profile?.permissions?.name,
            isShow: true,
        },
        {
            label: "Username",
            value: profile.name,
            isShow: true,
        },
        {
            label: "Date Joined",
            value: defaultDateFormat(profile.createdAt),
            isShow: true,
        },
        {
            label: "Date Deactivated",
            value: defaultDateFormat(profile.deactivatedAt),
            isShow: profile.status === "deactivated" ? true : false,
        },
        {
            label: "Date Banned",
            value: defaultDateFormat(profile.bannedAt),
            isShow: profile.status === "banned" ? true : false,
        },
        {
            label: "Last Login",
            value: profile.lastLogin
                ? defaultDateTimeFormat(profile.lastLogin?.created_at)
                : "-",
            isShow: true,
        },
    ];

    const nav = [
        {
            label: "Personal Details",
            path: getPath(Routes.MANAGE_USER_PROFILE).replace(
                ":accountNo",
                accountNo
            ),
        },
    ];

    useEffect(() => {
        if (accountNo !== ":accountNo") {
            dispatch(getAdminProfile(accountNo));
        }
    }, [dispatch, accountNo]);

    return (
        <React.Fragment>
            <Title title={getPathTitle(Routes.MANAGE_USER_PROFILE)} />
            <div className="page-content placeholder-glow">
                <Container fluid>
                    <BreadCrumb
                        parent={{
                            isParent: false,
                            pageTitle: getPathTitle(Routes.MANAGE_USER),
                            pageLink: getPath(Routes.MANAGE_USER),
                        }}
                        childs={[
                            {
                                pageTitle: `${profile.firstName} ${profile.lastName}`,
                                pageLink: getPath(Routes.MANAGE_USER_PROFILE),
                                isActive: true,
                            },
                        ]}
                    />
                    <Row>
                        <Col md={3}>
                            <Card>
                                <CardBody className="p-4">
                                    <div className="text-center">
                                        <div className="profile-user position-relative d-inline-block mx-auto  mb-4">
                                            <img
                                                src={UserDefaultPhoto}
                                                className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                                                alt="user-profile"
                                            />
                                            <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                                                <Input
                                                    id="profile-img-file-input"
                                                    type="file"
                                                    className="profile-img-file-input"
                                                />
                                                <Label
                                                    htmlFor="profile-img-file-input"
                                                    className="profile-photo-edit avatar-xs">
                                                    <span className="avatar-title rounded-circle bg-light text-body">
                                                        <i className="ri-camera-fill"></i>
                                                    </span>
                                                </Label>
                                            </div>
                                        </div>
                                        <p className="text-muted mb-0">
                                            {profile.accountNo}
                                        </p>
                                        <h5 className="fs-16 mb-1">
                                            {profile.firstName}{" "}
                                            {profile.lastName}
                                        </h5>
                                        <p className="text-muted mb-0">
                                            {profile.role.name}
                                        </p>
                                        <StatusBadge status={profile.status} />
                                    </div>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <div className="table-card">
                                        <table className="table mb-0">
                                            <tbody>
                                                {details.map((item, key) => (
                                                    <tr
                                                        key={key}
                                                        className={
                                                            !item.isShow
                                                                ? "d-none"
                                                                : ""
                                                        }>
                                                        <td className="fw-medium">
                                                            {item.label}
                                                        </td>
                                                        <td>{item.value}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col md={9}>
                            <Card>
                                <CardHeader>
                                    <Nav
                                        className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                        role="tablist">
                                        {nav.map((item, key) => (
                                            <NavItem key={key}>
                                                <NavLink
                                                    className={classnames({
                                                        active:
                                                            item.path ===
                                                            location.pathname,
                                                    })}
                                                    onClick={() => {
                                                        nagivate(item.path);
                                                    }}
                                                    type="button">
                                                    <i className="far fa-user"></i>
                                                    {item.label}
                                                </NavLink>
                                            </NavItem>
                                        ))}
                                    </Nav>
                                </CardHeader>
                                <CardBody className="p-4">{children}</CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Layout;
