import {
    AmountFormat,
    InputSwitch,
    StatusBadge,
    Title,
    UserLink,
} from "@/components/atoms";
import BreadCrumb from "@/components/common/BreadCrumb";
import { confirmation } from "@/components/constants/common";
import { Routes, getPath, getPathTitle } from "@/constants";
import { getSetting, userRole } from "@/hooks";
import { UserDefaultPhoto } from "@/resources/images";
import {
    disableWithdrawalFeeMerchant,
    enableValidTurnoverCommission,
    getAgentProfile,
    updateCrossBettingProtection,
    updateUserProfileSetting,
    updateUserProfileSettingWithBalanceCategory,
} from "@/store/actions";
import {
    copyText,
    defaultDateFormat,
    defaultDateTimeFormat,
    getAgentReferralLink,
} from "@/utils";
import classnames from "classnames";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { ReferralLinksModal } from "@/components/molecules";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Input,
    Label,
    Nav,
    NavItem,
    NavLink,
    Row,
} from "reactstrap";

const Layout = ({ children }) => {
    const isMounted = useRef(false);
    const [isOpen, setIsOpen] = useState(false);
    const { isUserDepositSuccess } = useSelector((state) => state.transaction);
    const { profile, isLoading } = useSelector((state) => state.agent);
    const { accountNo } = useParams();
    const dispatch = useDispatch();
    const nagivate = useNavigate();
    const location = useLocation();
    const { isSuperAdmin, isOperator } = userRole();

    const vtoCommissionRateEnabled =
        getSetting("enable_valid_turnover_commission_rate") === "1";

    const separateSportsBettingCommission =
        getSetting("separate_sports_betting_commission") === "1";

    const initialize = () => {
        dispatch(getAgentProfile(accountNo));
    };

    const changeValidTurnoverCommissionStatus = (balanceCategory, value) => {
        confirmation({
            text: `Are you sure you want to ${
                value ? "DEACTIVATE" : "ACTIVATE"
            } the VTO commission for this agent`,
            icon: "warning",
            confirmButtonText: "Confirm",
            action: () => {
                dispatch(
                    enableValidTurnoverCommission(
                        { value: value ? 0 : 1 },
                        balanceCategory,
                        profile.userProfilekeyToken,
                        initialize
                    )
                );
            },
        });
    };

    const changeProtectionSettings = () => {
        if (profile?.crossBettingProtectionEditable ?? false) {
            confirmation({
                text: `Are you sure you want to ${
                    profile.crossBettingProtection ? "DEACTIVATE" : "ACTIVATE"
                } cross betting protection of this master agent`,
                icon: "warning",
                confirmButtonText: "Confirm",
                action: () => {
                    dispatch(
                        updateCrossBettingProtection(
                            { value: profile.crossBettingProtection ? 0 : 1 },
                            profile.userProfilekeyToken
                        )
                    );
                    dispatch(getAgentProfile(accountNo));
                },
            });
        }
    };

    const changeCommissionTransferSchedule = (gameOffering, scheduleType) => {
        confirmation({
            text: `Are you sure you want to change the transfer schedule of ${gameOffering} to ${scheduleType} of this master agent`,
            icon: "warning",
            confirmButtonText: "Confirm",
            action: () => {
                dispatch(
                    updateUserProfileSetting(
                        `update-commission-transfer-schedule-${gameOffering}`,
                        { value: scheduleType },
                        profile.userProfilekeyToken,
                        initialize
                    )
                );
            },
        });
    };

    const changeSportsBettingCommissionSeparationStatus = (
        balanceCategory,
        value
    ) => {
        const isEditable =
            balanceCategory === "bcf"
                ? profile.separateSportsBettingCommissionBcfEditable ?? false
                : profile.separateSportsBettingCommissionMerchantEditable ??
                  false;

        if (isEditable) {
            confirmation({
                text: `Are you sure you want to ${
                    value ? "DEACTIVATE" : "ACTIVATE"
                } the (sports/e-sports/live casino) commission separation for this agent`,
                icon: "warning",
                confirmButtonText: "Confirm",
                action: () => {
                    dispatch(
                        updateUserProfileSettingWithBalanceCategory(
                            "enable-separate-sports-betting-commission",
                            { value: value ? 0 : 1 },
                            balanceCategory,
                            profile.userProfilekeyToken,
                            initialize
                        )
                    );
                },
            });
        }
    };

    const disableWithdrawalFee = () => {
        confirmation({
            text: `Are you sure you want to ${
                profile.withdrawalFeeMerchantDisabled ? "ENABLE" : "DISABLE"
            } the merchant withdrawal fee of this operator`,
            icon: "warning",
            confirmButtonText: "Confirm",
            action: () => {
                dispatch(
                    disableWithdrawalFeeMerchant(
                        {
                            value: profile.withdrawalFeeMerchantDisabled
                                ? 0
                                : 1,
                        },
                        profile.userProfilekeyToken,
                        initialize
                    )
                );
            },
        });
    };

    const details = [
        {
            label: "Username",
            value: profile.name,
            isShow: true,
        },
        {
            label: "Referral Code",
            value: (
                <span>
                    {profile.referralCode}{" "}
                    <span
                        onClick={() => {
                            copyText(profile.referralCode);
                        }}
                        className="ri-file-copy-line cursor-pointer text-primary"
                    ></span>
                </span>
            ),
            isShow: true,
        },
        {
            label: "Date Joined",
            value: defaultDateFormat(profile.createdAt),
            isShow: true,
        },
        {
            label: "Date Deactivated",
            value: defaultDateFormat(profile.deactivatedAt),
            isShow: profile.status === "deactivated" ? true : false,
        },
        {
            label: "Date Banned",
            value: defaultDateFormat(profile.bannedAt),
            isShow: profile.status === "banned" ? true : false,
        },
        {
            label: "Last Login",
            value: profile.lastLogin
                ? defaultDateTimeFormat(profile.lastLogin?.created_at)
                : "-",
            isShow: true,
        },
        {
            label: "IP Addresses:",
            render: () =>
                profile?.lastLogin?.ip_addresses &&
                profile?.lastLogin?.ip_addresses.map((ip, index) => (
                    <div className="tw-text-right" key={index}>
                        {!index && (
                            <span className="badge tw-bg-[#FF7759] tw-mr-2">
                                Current
                            </span>
                        )}{" "}
                        {ip}
                    </div>
                )),
            isShow: !!profile?.lastLogin?.ip_addresses,
        },
        // {
        // 	label: `Commission Transfer Schedule E-Games`,
        // 	render: () => (
        // 		<div>
        // 			<Select
        // 				name="commissionTransferScheduleCasino"
        // 				isClearable={false}
        // 				value={profile.commissionTransferScheduleCasino}
        // 				options={commissionTransferSchedules}
        // 				onChange={(e) => {
        // 					changeCommissionTransferSchedule("casino", e.target.value);
        // 				}}
        // 			/>
        // 		</div>
        // 	),
        // 	isShow: isSuperAdmin,
        // },
        // {
        // 	label: `Commission Transfer Schedule (Sports/E-Sports/Live Casino)`,
        // 	render: () => (
        // 		<div>
        // 			<Select
        // 				name="commissionTransferScheduleSport"
        // 				isClearable={false}
        // 				value={profile.commissionTransferScheduleSport}
        // 				options={commissionTransferSchedules}
        // 				onChange={(e) => {
        // 					changeCommissionTransferSchedule("sports", e.target.value);
        // 				}}
        // 			/>
        // 		</div>
        // 	),
        // 	isShow: isSuperAdmin,
        // },
        // {
        // 	label: `Enable VTO Commission ${process.env.REACT_APP_BCF_LABEL}`,
        // 	render: () => (
        // 		<div>
        // 			<InputSwitch
        // 				id={`enable-vto-commission-bcf`}
        // 				isChecked={profile?.validTurnoverBCfEnabled ?? false}
        // 				color="success"
        // 				onChange={() =>
        // 					changeValidTurnoverCommissionStatus(
        // 						"bcf",
        // 						profile?.validTurnoverBCfEnabled ?? false
        // 					)
        // 				}
        // 			/>
        // 		</div>
        // 	),
        // 	isShow: (isSuperAdmin || isOperator) && vtoCommissionRateEnabled,
        // },
        // {
        // 	label: `Enable VTO Commission PHP`,
        // 	render: () => (
        // 		<div>
        // 			<InputSwitch
        // 				id={`enable-vto-commission-merchant`}
        // 				isChecked={profile?.validTurnoverMerchantEnabled ?? false}
        // 				color="success"
        // 				onChange={() =>
        // 					changeValidTurnoverCommissionStatus(
        // 						"merchant",
        // 						profile?.validTurnoverMerchantEnabled ?? false
        // 					)
        // 				}
        // 			/>
        // 		</div>
        // 	),
        // 	isShow: isSuperAdmin && vtoCommissionRateEnabled,
        // },
        // {
        // 	label: `Enable (Sports/E-Sports/Live Casino) Separation Commission ${process.env.REACT_APP_BCF_LABEL}`,
        // 	render: () => (
        // 		<div>
        // 			<InputSwitch
        // 				id={`enable-sports-separation-commission-bcf`}
        // 				isChecked={profile?.separateSportsBettingCommissionBcf ?? false}
        // 				color="success"
        // 				disabled={
        // 					!profile?.separateSportsBettingCommissionBcfEditable ?? false
        // 				}
        // 				onChange={() =>
        // 					changeSportsBettingCommissionSeparationStatus(
        // 						"bcf",
        // 						profile?.separateSportsBettingCommissionBcf ?? false
        // 					)
        // 				}
        // 			/>
        // 		</div>
        // 	),
        // 	isShow: isSuperAdmin || isOperator,
        // },
        // {
        // 	label: `Enable (Sports/E-Sports/Live Casino) Separation Commission PHP`,
        // 	render: () => (
        // 		<div>
        // 			<InputSwitch
        // 				id={`enable-sports-separation-commission-merchant`}
        // 				isChecked={
        // 					profile?.separateSportsBettingCommissionMerchant ?? false
        // 				}
        // 				color="success"
        // 				disabled={
        // 					!profile?.separateSportsBettingCommissionMerchantEditable ?? false
        // 				}
        // 				onChange={() =>
        // 					changeSportsBettingCommissionSeparationStatus(
        // 						"merchant",
        // 						profile?.separateSportsBettingCommissionMerchant ?? false
        // 					)
        // 				}
        // 			/>
        // 		</div>
        // 	),
        // 	isShow: isSuperAdmin,
        // },
        {
            label: "Cross Betting Protection",
            render: () => (
                <div>
                    <InputSwitch
                        id={`merchant-option-${profile.crossBettingProtection}`}
                        isChecked={profile?.crossBettingProtection ?? false}
                        color="success"
                        disabled={
                            !profile?.crossBettingProtectionEditable ?? false
                        }
                        onChange={() => changeProtectionSettings()}
                    />
                </div>
            ),
            isShow: true,
        },
        {
            label: "Disable Withdrawal Fee",
            render: () => (
                <div>
                    <InputSwitch
                        id={`disable-withdrawal-fee-merchant-${profile.withdrawalFeeMerchantDisabled}`}
                        isChecked={
                            profile?.withdrawalFeeMerchantDisabled ?? false
                        }
                        disabled={
                            !profile?.withdrawalFeeMerchantDisabledEditable ??
                            false
                        }
                        color="success"
                        onChange={() => disableWithdrawalFee()}
                    />
                </div>
            ),
            isShow: isSuperAdmin,
        },
    ];

    const balances = useMemo(() => {
        return [
            {
                label: process.env.REACT_APP_BCF_LABEL,
                value: profile?.balance?.fiat?.php?.bcf ? (
                    <AmountFormat amount={profile?.balance?.fiat?.php?.bcf} />
                ) : (
                    "-"
                ),
                isShow: true,
            },
            {
                label: "PHP",
                value: profile?.balance?.fiat?.php?.merchant ? (
                    <AmountFormat
                        amount={profile?.balance?.fiat?.php?.merchant}
                    />
                ) : (
                    "-"
                ),
                isShow: true,
            },
            ...process.env.REACT_APP_LOTTO_ENABLED === "true" ? [
                {
                    label: `${process.env.REACT_APP_BCF_LABEL} (Lotto)`,
                    value: profile?.balance?.fiat?.php?.bcf_lotto ? (
                        <AmountFormat amount={profile?.balance?.fiat?.php?.bcf_lotto} />
                    ) : (
                        "-"
                    ),
                    isShow: true,
                },
                {
                    label: `${process.env.REACT_APP_DEFAULT_CURRENCY} (Lotto)`,
                    value: profile?.balance?.fiat?.php?.merchant_lotto ? (
                        <AmountFormat
                            amount={profile?.balance?.fiat?.php?.merchant_lotto}
                        />
                    ) : (
                        "-"
                    ),
                    isShow: true,
                },
            ] : [],
            {
                value: (
                    <div>
                        <h4 className="card-title text-center mb-0 flex-grow-1">
                            Commission
                        </h4>
                    </div>
                ),
                isShow: true,
            },
            {
                label: `${process.env.REACT_APP_BCF_LABEL} ${
                    profile.separateSportsBettingCommissionBcf ||
                    separateSportsBettingCommission
                        ? "(E-Games/Slots)"
                        : ""
                }`,
                value: profile?.balance?.fiat?.php?.commission_bcf ? (
                    <AmountFormat
                        amount={profile?.balance?.fiat?.php?.commission_bcf}
                    />
                ) : (
                    "-"
                ),
                isShow: true,
            },
            {
                label: `PHP ${
                    profile.separateSportsBettingCommissionMerchant ||
                    separateSportsBettingCommission
                        ? "(E-Games/Slots)"
                        : ""
                }`,
                value: profile?.balance?.fiat?.php?.commission_merchant ? (
                    <AmountFormat
                        amount={
                            profile?.balance?.fiat?.php?.commission_merchant
                        }
                    />
                ) : (
                    "-"
                ),
                isShow: true,
            },
            {
                label: `${process.env.REACT_APP_BCF_LABEL} (Sports/E-Sports/Live Casino)`,
                value: profile?.balance?.fiat?.php?.commission_bcf_sports ? (
                    <AmountFormat
                        amount={
                            profile?.balance?.fiat?.php?.commission_bcf_sports
                        }
                    />
                ) : (
                    "-"
                ),
                isShow: profile?.separateSportsBettingCommissionBcf,
            },
            {
                label: "PHP (Sports/E-Sports/Live Casino)",
                value: profile?.balance?.fiat?.php
                    ?.commission_merchant_sports ? (
                    <AmountFormat
                        amount={
                            profile?.balance?.fiat?.php
                                ?.commission_merchant_sports
                        }
                    />
                ) : (
                    "-"
                ),
                isShow: profile?.separateSportsBettingCommissionMerchant,
            },
            ...process.env.REACT_APP_LOTTO_ENABLED === "true" ? [
                {
                    label: `${process.env.REACT_APP_BCF_LABEL} (Lotto)`,
                    value: profile?.balance?.fiat?.php?.commission_bcf_lotto ? (
                        <AmountFormat
                            amount={
                                profile?.balance?.fiat?.php?.commission_bcf_lotto
                            }
                        />
                    ) : (
                        "-"
                    ),
                    isShow: true,
                },
                {
                    label: `${process.env.REACT_APP_DEFAULT_CURRENCY} (Lotto)`,
                    value: profile?.balance?.fiat?.php
                        ?.commission_merchant_lotto ? (
                        <AmountFormat
                            amount={
                                profile?.balance?.fiat?.php
                                    ?.commission_merchant_lotto
                            }
                        />
                    ) : (
                        "-"
                    ),
                    isShow: true,
                },
            ] : [],
            {
                value: (
                    <div>
                        <h4 className="card-title text-center mb-0 flex-grow-1">
                            Pending Commission
                        </h4>
                    </div>
                ),
                isShow: true,
            },
            {
                label: `${process.env.REACT_APP_BCF_LABEL} ${
                    profile.separateSportsBettingCommissionBcf ||
                    separateSportsBettingCommission
                        ? "(E-Games/Slots)"
                        : ""
                }`,
                value: profile?.balance?.fiat?.php?.pending_commission_bcf ? (
                    <AmountFormat
                        amount={
                            profile?.balance?.fiat?.php?.pending_commission_bcf
                        }
                    />
                ) : (
                    "-"
                ),
                isShow: true,
            },
            {
                label: `PHP ${
                    profile.separateSportsBettingCommissionMerchant ||
                    separateSportsBettingCommission
                        ? "(E-Games/Slots)"
                        : ""
                }`,
                value: profile?.balance?.fiat?.php
                    ?.pending_commission_merchant ? (
                    <AmountFormat
                        amount={
                            profile?.balance?.fiat?.php
                                ?.pending_commission_merchant
                        }
                    />
                ) : (
                    "-"
                ),
                isShow: true,
            },
            {
                label: `${process.env.REACT_APP_BCF_LABEL} (Sports/E-Sports/Live Casino)`,
                value: profile?.balance?.fiat?.php
                    ?.pending_commission_bcf_sports ? (
                    <AmountFormat
                        amount={
                            profile?.balance?.fiat?.php
                                ?.pending_commission_bcf_sports
                        }
                    />
                ) : (
                    "-"
                ),
                isShow:
                    profile.separateSportsBettingCommissionBcf ||
                    separateSportsBettingCommission,
            },
            {
                label: "PHP (Sports/E-Sports/Live Casino)",
                value: profile?.balance?.fiat?.php
                    ?.pending_commission_merchant_sports ? (
                    <AmountFormat
                        amount={
                            profile?.balance?.fiat?.php
                                ?.pending_commission_merchant_sports
                        }
                    />
                ) : (
                    "-"
                ),
                isShow:
                    profile.separateSportsBettingCommissionMerchant ||
                    separateSportsBettingCommission,
            },
            ...process.env.REACT_APP_LOTTO_ENABLED === "true" ? [
                {
                    label: `${process.env.REACT_APP_BCF_LABEL} (Lotto)`,
                    value: profile?.balance?.fiat?.php
                        ?.pending_commission_bcf_lotto ? (
                        <AmountFormat
                            amount={
                                profile?.balance?.fiat?.php
                                    ?.pending_commission_bcf_lotto
                            }
                        />
                    ) : (
                        "-"
                    ),
                    isShow: true
                },
                {
                    label: `${process.env.REACT_APP_DEFAULT_CURRENCY} (Lotto)`,
                    value: profile?.balance?.fiat?.php
                        ?.pending_commission_merchant_lotto ? (
                        <AmountFormat
                            amount={
                                profile?.balance?.fiat?.php
                                    ?.pending_commission_merchant_lotto
                            }
                        />
                    ) : (
                        "-"
                    ),
                    isShow: true
                },
            ] : []
        ];
    }, [separateSportsBettingCommission, profile]);

    const nav = [
        {
            label: "Personal Details",
            path: getPath(Routes.AGENT_PROFILE).replace(
                ":accountNo",
                accountNo
            ),
        },
        {
            label: "Commissions Settings",
            path: getPath(Routes.AGENT_COMMISSIONS_SETTINGS).replace(
                ":accountNo",
                accountNo
            ),
        },
        {
            label: "Downlines",
            path: getPath(Routes.AGENT_DOWNLINES).replace(
                ":accountNo",
                accountNo
            ),
        },
        {
            label: "Wallet",
            path: getPath(Routes.AGENT_WALLET).replace(":accountNo", accountNo),
        },
        {
            label: "Request",
            path: getPath(Routes.AGENT_REQUEST).replace(
                ":accountNo",
                accountNo
            ),
        },
        {
            label: "Commissions",
            path: getPath(Routes.AGENT_COMMISSIONS).replace(
                ":accountNo",
                accountNo
            ),
        },
    ];

    useEffect(() => {
        if (accountNo !== ":accountNo") {
            dispatch(getAgentProfile(accountNo));
        }
    }, [dispatch, accountNo]);

    useEffect(() => {
        if (isMounted.current) {
            dispatch(getAgentProfile(accountNo));
        } else isMounted.current = true;
    }, [dispatch, accountNo, isUserDepositSuccess]);

    return (
        <React.Fragment>
            <Title title={`Agent | ${accountNo}`} />
            <div className="page-content placeholder-glow">
                <Container fluid>
                    <BreadCrumb
                        parent={{
                            isParent: false,
                            pageTitle: getPathTitle(Routes.AGENTS),
                            pageLink: getPath(Routes.AGENTS),
                        }}
                        childs={[
                            {
                                pageTitle: `${profile.firstName} ${profile.lastName}`,
                                pageLink: getPath(Routes.AGENT_PROFILE),
                                isActive: true,
                            },
                        ]}
                    />
                    <Row>
                        <Col md={3}>
                            <Card>
                                <CardBody className="p-4">
                                    <div className="text-center">
                                        <div className="profile-user position-relative d-inline-block mx-auto  mb-4">
                                            <img
                                                src={UserDefaultPhoto}
                                                className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                                                alt="user-profile"
                                            />
                                            <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                                                <Input
                                                    id="profile-img-file-input"
                                                    type="file"
                                                    className="profile-img-file-input"
                                                />
                                                <Label
                                                    htmlFor="profile-img-file-input"
                                                    className="profile-photo-edit avatar-xs"
                                                >
                                                    <span className="avatar-title rounded-circle bg-light text-body">
                                                        <i className="ri-camera-fill"></i>
                                                    </span>
                                                </Label>
                                            </div>
                                        </div>
                                        <p className="text-muted mb-0">
                                            {profile.accountNo}
                                        </p>
                                        <h5 className="fs-16 mb-1">
                                            {profile.firstName}{" "}
                                            {profile.lastName}
                                        </h5>
                                        <p className="text-muted mb-0">
                                            {profile.role.name}
                                        </p>
                                        <StatusBadge status={profile.status} />
                                        <div className="mt-4">
                                            {process.env.REACT_APP_ENV ===
                                            "production" ? (
                                                <Button
                                                    color="primary"
                                                    className="m-0 w-100"
                                                    size="sm"
                                                    onClick={() =>
                                                        setIsOpen(true)
                                                    }
                                                >
                                                    <div className="tw-flex tw-items-center tw-justify-center gap-1">
                                                        Referral Links
                                                        <span className="ri-links-line" />
                                                    </div>
                                                </Button>
                                            ) : (
                                                <Button
                                                    color="primary"
                                                    className="m-0 w-100"
                                                    size="sm"
                                                    onClick={() => {
                                                        copyText(
                                                            getAgentReferralLink(
                                                                profile.referralCode
                                                            )
                                                        );
                                                    }}
                                                >
                                                    Copy Referral Link{" "}
                                                    <span className="ri-file-copy-line"></span>
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardHeader className="align-items-center d-flex">
                                    <h4 className="card-title text-center mb-0 flex-grow-1">
                                        User Details
                                    </h4>
                                </CardHeader>
                                <CardBody>
                                    <div className="table-card">
                                        <table className="table mb-0">
                                            <tbody>
                                                {details.map((item, key) => (
                                                    <tr
                                                        key={key}
                                                        className={
                                                            !item.isShow
                                                                ? "d-none"
                                                                : ""
                                                        }
                                                    >
                                                        {item?.label && (
                                                            <td className="fw-medium">
                                                                {item.label}
                                                            </td>
                                                        )}
                                                        <td
                                                            colSpan={
                                                                item?.label
                                                                    ? 1
                                                                    : 2
                                                            }
                                                        >
                                                            {item?.render
                                                                ? item?.render()
                                                                : item.value}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardHeader className="align-items-center d-flex">
                                    <h4 className="card-title text-center mb-0 flex-grow-1">
                                        Balances
                                    </h4>
                                </CardHeader>
                                <CardBody>
                                    <div className="table-card">
                                        <table className="table mb-0">
                                            <tbody>
                                                {balances.map((item, key) => (
                                                    <tr
                                                        key={key}
                                                        className={
                                                            !item.isShow
                                                                ? "d-none"
                                                                : ""
                                                        }
                                                    >
                                                        {item?.label && (
                                                            <td className="fw-medium">
                                                                {item.label}
                                                            </td>
                                                        )}
                                                        <td
                                                            className="tw-text-right"
                                                            colSpan={
                                                                item?.label
                                                                    ? 1
                                                                    : 2
                                                            }
                                                        >
                                                            {item?.render
                                                                ? item?.render()
                                                                : item.value}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </CardBody>
                            </Card>
                            {profile?.referrer && (
                                <Card>
                                    <CardHeader className="align-items-center d-flex">
                                        <h4 className="card-title text-center mb-0 flex-grow-1">
                                            Uplines
                                        </h4>
                                    </CardHeader>
                                    <CardBody>
                                        {profile?.referrer?.master_agent && (
                                            <div className="flex text-center justify-content-center mb-3">
                                                <h6>Master Agent</h6>
                                                {`${profile?.referrer?.master_agent?.first_name} ${profile?.referrer?.master_agent?.last_name} | `}
                                                <UserLink
                                                    text={
                                                        profile?.referrer
                                                            ?.master_agent
                                                            ?.account_no
                                                    }
                                                    forRole="master-agent"
                                                    path={getPath(
                                                        Routes.SUPER_AGENT_PROFILE
                                                    ).replace(
                                                        ":accountNo",
                                                        profile?.referrer
                                                            ?.master_agent
                                                            ?.account_no
                                                    )}
                                                />
                                            </div>
                                        )}
                                        {profile?.referrer?.operator && (
                                            <div className="flex text-center justify-content-center mb-3">
                                                <h6>Operator</h6>
                                                {`${profile?.referrer?.operator?.first_name} ${profile?.referrer?.operator?.last_name} | `}
                                                <UserLink
                                                    text={
                                                        profile?.referrer
                                                            ?.operator
                                                            ?.account_no
                                                    }
                                                    forRole="super-admin"
                                                    path={getPath(
                                                        Routes.OPERATOR_PROFILE
                                                    ).replace(
                                                        ":accountNo",
                                                        profile?.referrer
                                                            ?.operator
                                                            ?.account_no
                                                    )}
                                                />
                                            </div>
                                        )}
                                    </CardBody>
                                </Card>
                            )}
                        </Col>

                        <Col md={9}>
                            <Card>
                                <CardHeader>
                                    <Nav
                                        className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                        role="tablist"
                                    >
                                        {nav.map((item, key) => (
                                            <NavItem key={key}>
                                                <NavLink
                                                    className={classnames({
                                                        active:
                                                            item.path ===
                                                            location.pathname,
                                                    })}
                                                    onClick={() => {
                                                        nagivate(item.path);
                                                    }}
                                                    type="button"
                                                >
                                                    <i className="far fa-user"></i>
                                                    {item.label}
                                                </NavLink>
                                            </NavItem>
                                        ))}
                                    </Nav>
                                </CardHeader>
                                <CardBody className="p-4">{children}</CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            {process.env.REACT_APP_ENV === "production" && (
                <ReferralLinksModal
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    referralCode={profile.referralCode}
                />
            )}
        </React.Fragment>
    );
};

export default Layout;
