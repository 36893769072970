import * as types from "./actionTypes";
import { countryState } from "@/types/states";

const initialState = countryState;

const country = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_SYSTEM_COUNTRIES:
            state = {
                ...state,
                isGetCountryLoading: true,
                isUpdateCountryStatusSuccess: false,
            };
            break;
        case types.GET_SYSTEM_COUNTRIES_SUCCESS:
            state = {
                ...state,
                isGetCountryLoading: false,
                countries: action.payload,
            };
            break;
        case types.GET_SYSTEM_COUNTRIES_FAILED:
            state = {
                ...state,
                isGetCountryLoading: false,
            };
            break;
        case types.UPDATE_SYSTEM_COUNTRY:
            state = {
                ...state,
                isUpdateCountryStatusLoading: true,
            };
            break;
        case types.UPDATE_SYSTEM_COUNTRY_SUCCESS:
            state = {
                ...state,
                isUpdateCountryStatusLoading: false,
                isUpdateCountryStatusSuccess: true,
            };
            break;
        case types.UPDATE_SYSTEM_COUNTRY_FAILED:
            state = {
                ...state,
                isUpdateCountryStatusLoading: false,
                isUpdateCountryStatusSuccess: false,
            };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default country;
