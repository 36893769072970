import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { updateNotificationTypeBanner } from "@/store/actions";
import { FileInputForm } from "@/components/molecules";

const AddNotificationTypeIconModal = ({
  isOpen,
  setIsOpen,
  data,
  initialize,
}) => {
  const { isSubmitting } = useSelector((state) => state.announcement);

  const dispatch = useDispatch();

  const onUpdate = (value) => {
    dispatch(updateNotificationTypeBanner(value, data?.key_token, result));
  };

  const result = (isSuccess) => {
    if(isSuccess) {
      setIsOpen(false);
      initialize();
    }
  }

  return (
    <Modal
      id="updateFeaturedModal"
      isOpen={isOpen}
      toggle={() => setIsOpen(!isOpen)}
    >
      <ModalHeader>
        <div className="modal-title">{data?.name}</div>
      </ModalHeader>
      <ModalBody>
        <FileInputForm
          isLoading={isSubmitting}
          field="icon"
          defaultImage={data?.icon}
          label="Icon"
          isCreate={!data?.icon}
          onSubmit={(values) => onUpdate(values)}
        />
      </ModalBody>
    </Modal>
  );
};

export default AddNotificationTypeIconModal;
