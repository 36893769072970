export const GET_TRANSACTION_DATA = "GET_TRANSACTION_DATA";
export const SET_TRANSACTION_DATA = "SET_TRANSACTION_DATA";
export const SET_TRANSACTION_DATA_ERROR = "SET_TRANSACTION_DATA_ERROR";

export const GET_TRANSACTION_INFO = "GET_TRANSACTION_INFO";
export const SET_TRANSACTION_INFO = "SET_TRANSACTION_INFO";
export const SET_TRANSACTION_INFO_ERROR = "SET_TRANSACTION_INFO_ERROR";

export const GET_TIP_TRANSACTION = "GET_TIP_TRANSACTION";
export const SET_TIP_TRANSACTION = "SET_TIP_TRANSACTION";
export const SET_TIP_TRANSACTION_ERROR = "SET_TIP_TRANSACTION_ERROR";

export const USER_DEPOSIT = "USER_DEPOSIT";
export const USER_DEPOSIT_SUCCESS = "USER_DEPOSIT_SUCCESS";
export const USER_DEPOSIT_ERROR = "USER_DEPOSIT_ERROR";

export const USER_DEPOSIT_REQUEST = "USER_DEPOSIT_REQUEST";
export const USER_DEPOSIT_REQUEST_SUCCESS = "USER_DEPOSIT_REQUEST_SUCCESS";
export const USER_DEPOSIT_REQUEST_ERROR = "USER_DEPOSIT_REQUEST_ERROR";

export const ADD_FUND = "ADD_FUND";
export const ADD_FUND_SUCCESS = "ADD_FUND_SUCCESS";
export const ADD_FUND_ERROR = "ADD_FUND_ERROR";

export const USER_WITHDRAWAL_REQUEST = "USER_WITHDRAWAL_REQUEST";
export const USER_WITHDRAWAL_REQUEST_SUCCESS =
  "USER_WITHDRAWAL_REQUEST_SUCCESS";
export const USER_WITHDRAWAL_REQUEST_ERROR = "USER_WITHDRAWAL_REQUEST_ERROR";

export const GET_PAYMENT_METHOD_DATA = "GET_PAYMENT_METHOD_DATA";
export const SET_PAYMENT_METHOD_DATA = "SET_PAYMENT_METHOD_DATA";
export const SET_PAYMENT_METHOD_DATA_ERROR = "SET_PAYMENT_METHOD_DATA_ERROR";

export const RESET_TRANSACTION_ERRORS = "RESET_TRANSACTION_ERRORS";

export const PROCESS_COMMISSION = "PROCESS_COMMISSION";
export const PROCESS_COMMISSION_SUCCESS = "PROCESS_COMMISSION_SUCCESS";
export const PROCESS_COMMISSION_FAILED = "PROCESS_COMMISSION_FAILED";
