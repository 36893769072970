import React, { useState } from "react";
import {
  ErrorAlert,
  InputActionChecker,
  InputTextGroup,
  EditActionButton,
} from "@/components/atoms";
import { FormGroup } from "reactstrap";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { confirmation } from "@/components/constants/common";
import { commissionRateValidation } from "@/constants";
import { updateAgentCommissionRate } from "@/store/actions";

const CommissionsSettingsInput = ({
  title,
  name,
  type,
  commissionRate,
  userProfilekeyToken,
  showEdit,
  userType,
  errors,
}) => {
  const [isEdit, setIsEdit] = useState(false);

  const onClickEdit = () => {
    setIsEdit(!isEdit);
  };

  const onClickCancel = () => {
    setIsEdit(!isEdit);
    validation.resetForm();
  };

  const dispatch = useDispatch();

  // const showConfirmation = () => {
  //     confirmation({
  //         title: "Confirmation Message",
  //         text: "Are you sure you want to save?",
  //         icon: "warning",
  //         confirmButtonText: "Save",
  //         action: () => validation.handleSubmit(),
  //     });
  // };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      [type]: commissionRate,
    },
    validationSchema: commissionRateValidation(userType, type),
    onSubmit: (values) => {
      dispatch(
        updateAgentCommissionRate(
          {
            commission_rate: values[type],
            user_type: userType,
            type,
          },
          userProfilekeyToken,
          validation
        )
      );
      setIsEdit(false);
    },
  });

  return (
    <div className="card-header p-0 mb-3 pb-2">
      <ErrorAlert errors={errors} validation={validation} />
      <p className="text-uppercase fw-semibold fs-14 text-muted mb-1 tw-w-4/6 tw-overflow-hidden tw-truncate">
        {title}
      </p>

      <div>
        <div className="tw-flex tw-items-center tw-gap-2">
          {isEdit ? (
            <InputTextGroup
              type="number"
              name={type}
              onChange={(e) => validation.setFieldValue(type, e.target.value)}
              validation={validation}
            />
          ) : (
            <div className="text-muted mb-1 tw-w-full">
              {validation?.values?.[type] ?? 0.0}
            </div>
          )}
          
          {showEdit && (
            <EditActionButton
              editIconButton="ri-pencil-line"
              isEdit={isEdit}
              onClickSave={validation.handleSubmit}
              onClickEdit={onClickEdit}
              onClickCancel={onClickCancel}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CommissionsSettingsInput;
