import logoDark from "@/assets/images/logo-dark.png";
import logoLight from "@/assets/images/logo-light.png";
import logoSm from "@/assets/images/logo-sm.png";
import FullScreenDropdown from "@/components/common/FullScreenDropdown";
import LightDark from "@/components/common/LightDark";
import ProfileDropdown from "@/components/common/ProfileDropdown";
import WalletDropdown from "@/components/common/WalletDropdown";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Header = ({ onChangeLayoutMode, layoutModeType, headerClass }) => {
    const user = useSelector((state) => state.user.user);
    const isAdmin =
        user?.role?.slug === "super-admin" || user?.role?.slug === "admin";

    const toogleMenuBtn = () => {
        var windowSize = document.documentElement.clientWidth;

        if (windowSize > 767)
            document.querySelector(".hamburger-icon").classList.toggle("open");

        //For collapse vertical menu
        if (
            document.documentElement.getAttribute("data-layout") === "vertical"
        ) {
            if (windowSize < 1025 && windowSize > 767) {
                document.body.classList.remove("vertical-sidebar-enable");
                document.documentElement.getAttribute("data-sidebar-size") ===
                "sm"
                    ? document.documentElement.setAttribute(
                          "data-sidebar-size",
                          ""
                      )
                    : document.documentElement.setAttribute(
                          "data-sidebar-size",
                          "sm"
                      );
            } else if (windowSize > 1025) {
                document.body.classList.remove("vertical-sidebar-enable");
                document.documentElement.getAttribute("data-sidebar-size") ===
                "lg"
                    ? document.documentElement.setAttribute(
                          "data-sidebar-size",
                          "sm"
                      )
                    : document.documentElement.setAttribute(
                          "data-sidebar-size",
                          "lg"
                      );
            } else if (windowSize <= 767) {
                document.body.classList.add("vertical-sidebar-enable");
                document.documentElement.setAttribute(
                    "data-sidebar-size",
                    "lg"
                );
            }
        }
    };

    return (
        <React.Fragment>
            <header id="page-topbar" className={headerClass}>
                <div className="layout-width">
                    <div className="navbar-header">
                        <div className="d-flex">
                            <div className="navbar-brand-box horizontal-logo">
                                <Link to="/" className="logo logo-dark">
                                    <span className="logo-sm">
                                        <img src={logoSm} alt="" height="22" />
                                    </span>
                                    <span className="logo-lg">
                                        <img
                                            src={logoDark}
                                            alt=""
                                            height="17"
                                        />
                                    </span>
                                </Link>

                                <Link to="/" className="logo logo-light">
                                    <span className="logo-sm">
                                        <img src={logoSm} alt="" height="22" />
                                    </span>
                                    <span className="logo-lg">
                                        <img
                                            src={logoLight}
                                            alt=""
                                            height="17"
                                        />
                                    </span>
                                </Link>
                            </div>

                            <button
                                onClick={toogleMenuBtn}
                                type="button"
                                className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
                                id="topnav-hamburger-icon"
                            >
                                <span className="hamburger-icon">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </span>
                            </button>
                        </div>

                        <div className="d-flex align-items-center">
                            {/* FullScreenDropdown */}
                            <FullScreenDropdown />
                            {/* Dark/Light Mode set */}
                            <LightDark
                                layoutMode={layoutModeType}
                                onChangeLayoutMode={onChangeLayoutMode}
                            />
                            {/* Wallet Dropdown */}
                            {!isAdmin && <WalletDropdown user={user} />}
                            {/* ProfileDropdown */}
                            <ProfileDropdown user={user} />
                        </div>
                    </div>
                </div>
            </header>
        </React.Fragment>
    );
};

export default Header;
