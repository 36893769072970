import { Button, InputSwitch, Title } from "@/components/atoms";
import BreadCrumb from "@/components/common/BreadCrumb";
import { confirmation } from "@/components/constants/common";
import { Routes, getPath, getPathTitle } from "@/constants";
import {
    getCasinoGameProvider,
    syncCasinoGameProvider,
    updateGameProviderOrder,
    updateGameStatus,
} from "@/store/actions";
import React, { useCallback, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row,
    Button as ButtonComponent,
} from "reactstrap";

const GameProvider = () => {
    const dispatch = useDispatch();
    const [isDraggable, setIsDraggable] = useState(false);
    const [providerList, setProviderList] = useState([]);
    const { gameProviderList, isLoading, isSubmit } = useSelector(
        (state) => state.game
    );

    const getCasinoGameProviders = useCallback(() => {
        dispatch(getCasinoGameProvider());
    }, [dispatch]);

    const onClickSync = (slug, title) => {
        confirmation({
            title: title,
            text: "Are you sure you want to Sync this Game Provider?",
            icon: "warning",
            confirmButtonText: "Sync now",
            action: () => dispatch(syncCasinoGameProvider(slug)),
        });
    };

    const changeStatus = (item) => {
        confirmation({
            title: item.title,
            text: `Are you sure you want to ${
                item.is_active ? "DEACTIVATE" : "ACTIVE"
            }`,
            icon: "warning",
            confirmButtonText: "Confirm",
            action: () => {
                dispatch(
                    updateGameStatus(
                        "game-provider",
                        item.key_token,
                        getCasinoGameProviders
                    )
                );
            },
        });
    };

    const handleUpdateArrangement = (result) => {
        if (result?.destination) {
            const newProviderList = providerList;
            let [reorderItem] = newProviderList.splice(result.source.index, 1);
            newProviderList.splice(result.destination.index, 0, reorderItem);
            setProviderList([...newProviderList]);
        }
    };

    const cancelArrangeOrder = () => {
        setProviderList([...gameProviderList]);
        setIsDraggable(false);
    };

    const updateOrder = () => {
        confirmation({
            title: "Game Provider Order",
            text: `Are you sure you want to update the order of the game providers`,
            icon: "warning",
            confirmButtonText: "Confirm",
            action: () => {
                dispatch(
                    updateGameProviderOrder({
                        game_providers: providerList,
                    })
                );
                setIsDraggable(false);
            },
        });
    };

    useEffect(() => {
        getCasinoGameProviders();
    }, [getCasinoGameProviders]);

    useEffect(() => {
        if (gameProviderList) {
            setProviderList(gameProviderList);
        }
    }, [gameProviderList]);

    return (
        <React.Fragment>
            <Title title={getPathTitle(Routes.CASINO_GAME_PROVIDER)} />
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb
                        parent={{
                            isParent: true,
                            pageTitle: getPathTitle(
                                Routes.CASINO_GAME_PROVIDER
                            ),
                            pageLink: getPath(Routes.CASINO_GAME_PROVIDER),
                        }}
                    />
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardHeader className="align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">
                                        {getPathTitle(
                                            Routes.CASINO_GAME_PROVIDER
                                        )}
                                    </h4>
                                    <div className="flex-shrink-0">
                                        {isDraggable ? (
                                            <div>
                                                <ButtonComponent
                                                    color="light"
                                                    onClick={cancelArrangeOrder}
                                                    className="me-2"
                                                    size="sm"
                                                >
                                                    Cancel
                                                </ButtonComponent>
                                                <ButtonComponent
                                                    onClick={() =>
                                                        updateOrder()
                                                    }
                                                    color="primary"
                                                    size="sm"
                                                >
                                                    Update
                                                </ButtonComponent>
                                            </div>
                                        ) : (
                                            <Button
                                                color="success"
                                                type="submit"
                                                text="Arrange Order"
                                                size="sm"
                                                isLoading={isSubmit}
                                                onClick={() =>
                                                    setIsDraggable(true)
                                                }
                                            />
                                        )}
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        <Col>
                                            <div className="tw-flex tw-w-full tw-flex-row p-2">
                                                <div className="tw-flex-1 text-center">
                                                    Provider Name
                                                </div>
                                                <div className="tw-flex-1 text-center">
                                                    Status
                                                </div>
                                                <div className="tw-flex-1 text-center">
                                                    Action
                                                </div>
                                            </div>
                                            {(isLoading || isSubmit) && (
                                                <div className="loading-overlay"></div>
                                            )}
                                            <DragDropContext
                                                onDragEnd={
                                                    handleUpdateArrangement
                                                }
                                            >
                                                <Droppable droppableId="list">
                                                    {(provided) => (
                                                        <div
                                                            {...provided.droppableProps}
                                                            ref={
                                                                provided.innerRef
                                                            }
                                                            className="tw-flex tw-flex-col tw-gap-1"
                                                        >
                                                            {providerList.map(
                                                                (
                                                                    item,
                                                                    index
                                                                ) => (
                                                                    <Draggable
                                                                        isDragDisabled={
                                                                            !isDraggable
                                                                        }
                                                                        key={
                                                                            item.key_token
                                                                        }
                                                                        draggableId={
                                                                            item.key_token
                                                                        }
                                                                        index={
                                                                            index
                                                                        }
                                                                    >
                                                                        {(
                                                                            provided,
                                                                            snapshot
                                                                        ) => (
                                                                            <div
                                                                                className="tw-w-full tw-flex tw-flex-row p-2 border tw-bg-white dark:tw-bg-[#212529]"
                                                                                ref={
                                                                                    provided.innerRef
                                                                                }
                                                                                {...provided.draggableProps}
                                                                                {...provided.dragHandleProps}
                                                                            >
                                                                                <div className="flex-1 tw-flex tw-justify-center tw-items-center">
                                                                                    {
                                                                                        item.label
                                                                                    }
                                                                                </div>
                                                                                <div className="flex-1 tw-flex tw-justify-center tw-items-center">
                                                                                    <InputSwitch
                                                                                        label={
                                                                                            item.is_active
                                                                                                ? "Active"
                                                                                                : "Inactive"
                                                                                        }
                                                                                        id={`game-type-status-${item.slug}`}
                                                                                        isChecked={
                                                                                            item.is_active
                                                                                        }
                                                                                        color="success"
                                                                                        onChange={() =>
                                                                                            changeStatus(
                                                                                                item
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                                <div className="flex-1 tw-flex tw-justify-center tw-items-center">
                                                                                    <ButtonComponent
                                                                                        color="primary"
                                                                                        size="sm"
                                                                                        onClick={() =>
                                                                                            onClickSync(
                                                                                                item.slug,
                                                                                                item.title
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        Sync
                                                                                    </ButtonComponent>
                                                                                </div>
                                                                                {
                                                                                    provided.placeholder
                                                                                }
                                                                            </div>
                                                                        )}
                                                                    </Draggable>
                                                                )
                                                            )}
                                                            {
                                                                provided.placeholder
                                                            }
                                                        </div>
                                                    )}
                                                </Droppable>
                                            </DragDropContext>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default GameProvider;
