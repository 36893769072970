import React, { useState } from "react";
import { FilePond } from "react-filepond";
import { FormikErrorMessage } from "@/components/atoms";
import { Button } from "reactstrap";

const FileInputPreview = ({
  validation,
  name,
  onupdatefiles,
  isUpdate = false,
  onEdit = null,
  ...rest
}) => {
  const [isEdit, setisEdit] = useState(false);
  const isString = typeof validation?.values?.[name] === "string" ;
  return (
    <div className="text-center tw-relative">
      {isString && !isEdit && isUpdate ? (
        <div className="bg-light tw-rounded tw-min-h-[200px] tw-py-3">
          <img
            src={validation?.values?.[name]}
            className="img-thumbnail"
            alt="preview"
          />
        </div>
      ) : (
        <FilePond
          files={[
            ...(!!validation?.values?.[name] && (!isUpdate || !isString)
              ? [validation?.values?.[name] ?? {}]
              : []),
          ]}
          className="border-amber-400"
          server={null}
          allowFileSizeValidation={true}
          maxFileSize="5MB"
          fileSizeBase={1000}
          name={name}
          labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
          onupdatefiles={onupdatefiles}
          {...rest}
        />
      )}
      {isUpdate && (
        <div className="tw-absolute tw-top-0 tw-right-0">
          <div className="tw-pr-3 tw-pt-2">
            <Button
              onClick={() => {
                if (isEdit) {
                  validation.setFieldValue(
                    name,
                    validation?.initialValues?.[name]
                  );
                }
                onEdit && onEdit(!isEdit);
                setisEdit(!isEdit);
              }}
              color={!isEdit ? "primary" : "danger"}
              type="button"
              size="sm"
            >
              {!isEdit ? "Update" : "Close"}
            </Button>
          </div>
        </div>
      )}

      <div className="mt-1">
        {validation.touched?.[name] && (
          <FormikErrorMessage validation={validation} name={name} />
        )}
      </div>
    </div>
  );
};

export default FileInputPreview;
