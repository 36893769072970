import { TablePlaceholder } from "@/components/atoms";
import { numberFormat } from "@/utils/common";
import Pagination from "react-js-pagination";
import { Col, Input, Row, Table as TableComponent } from "reactstrap";

const Table = ({
    thead,
    search,
    handlerSearch,
    sortBy,
    handlerSortBy,
    handlerChangeData,
    data,
    collection,
    isLoading,
    size = "sm",
    tableTextAlign = "center",
    customComponents,
}) => {
    const showPageOptions = [10, 20, 50, 100, 500, 1000];
    return (
        <>
            <Row className="mb-2">
                <Col md={12} className="tw-flex-end">
                    <div className="mx-2">{customComponents}</div>
                </Col>
            </Row>
            <Row className="mb-2">
                <Col md={3} className="pb-1">
                    <div className={`input-group input-group-${size}`}>
                        <label
                            htmlFor="per-page"
                            className="input-group-text form-label"
                        >
                            Show page
                        </label>
                        <select
                            className="form-select"
                            id="per-page"
                            value={data.per_page}
                            onChange={(event) =>
                                handlerChangeData({
                                    ...data,
                                    per_page: event.target.value,
                                    page: 1,
                                })
                            }
                        >
                            {showPageOptions.map((value) => (
                                <option key={value}>{value}</option>
                            ))}
                        </select>
                    </div>
                </Col>
                <Col md={6}></Col>
                <Col md={3}>
                    {handlerSearch && (
                        <div className={`input-group input-group-${size}`}>
                            <Input
                                type="text"
                                className="form-control"
                                placeholder="Search..."
                                value={search}
                                onChange={(event) =>
                                    handlerSearch(event.target.value)
                                }
                            />
                            <button
                                className="btn btn-success me-2 rounded-end"
                                type="button"
                                onClick={() =>
                                    handlerChangeData({
                                        ...data,
                                        search: search,
                                        page: 1,
                                    })
                                }
                            >
                                <span className="ri-search-2-line"></span>
                            </button>
                            {data.search && (
                                <button
                                    className="btn btn-light rounded"
                                    type="button"
                                    onClick={() => {
                                        handlerChangeData({
                                            ...data,
                                            search: "",
                                            page: 1,
                                        });
                                        handlerSearch("");
                                    }}
                                >
                                    Clear
                                </button>
                            )}
                        </div>
                    )}
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className={`table-responsive text-${tableTextAlign}`}>
                        {isLoading && <div className="loading-overlay"></div>}
                        <TableComponent
                            className={`table-bordered table-hover align-middle table-nowrap mb-0 table-${size}`}
                        >
                            <thead className="table-light">
                                <tr>
                                    {thead?.map((item, key) => {
                                        let currentSortField =
                                            item.field === sortBy.field;
                                        let currentSortType =
                                            sortBy.type === "desc"
                                                ? "ri-sort-desc"
                                                : "ri-sort-asc";
                                        return (
                                            !item.isHide && (
                                                <th scope="col" key={key}>
                                                    <div className="tw-flex tw-flex-row tw-justify-between tw-whitespace-nowrap">
                                                        <div className="tw-flex-1 tw-text-center">
                                                            {item.label}
                                                        </div>
                                                        {item.sortable && (
                                                            <span
                                                                onClick={() => {
                                                                    handlerSortBy(
                                                                        {
                                                                            field: item.field,
                                                                            type:
                                                                                sortBy.type ===
                                                                                    "asc" &&
                                                                                currentSortField
                                                                                    ? "desc"
                                                                                    : "asc",
                                                                        }
                                                                    );
                                                                    handlerChangeData(
                                                                        {
                                                                            ...data,
                                                                            sort_by:
                                                                                sortBy.field &&
                                                                                sortBy.type
                                                                                    ? `${sortBy.field} ${sortBy.type}`
                                                                                    : "",
                                                                        }
                                                                    );
                                                                }}
                                                                className={`align-middle float-end cursor-pointer ${
                                                                    !currentSortField
                                                                        ? "text-muted"
                                                                        : ""
                                                                } ${
                                                                    currentSortField
                                                                        ? currentSortType
                                                                        : "ri-filter-line"
                                                                }`}
                                                            ></span>
                                                        )}
                                                    </div>
                                                </th>
                                            )
                                        );
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {collection?.data?.map((item, index) => (
                                    <tr key={index}>
                                        {thead.map(
                                            (
                                                { field, render, isHide },
                                                index
                                            ) =>
                                                !isHide && (
                                                    <td key={index}>
                                                        {render
                                                            ? render(item)
                                                            : item[field]}
                                                    </td>
                                                )
                                        )}
                                    </tr>
                                ))}

                                {!collection?.meta?.total > 0 && (
                                    <TablePlaceholder colSpan={thead.length} />
                                )}
                            </tbody>
                        </TableComponent>
                    </div>
                </Col>
            </Row>

            {collection?.meta?.total > 0 && (
                <Row className="mt-3">
                    <Col md={6}>
                        <div className="float-left">
                            Showing{" "}
                            {numberFormat(collection?.meta?.first_item, false)}{" "}
                            to{" "}
                            {numberFormat(collection?.meta?.last_item, false)}{" "}
                            of {numberFormat(collection?.meta?.total, false)}{" "}
                            results
                        </div>
                    </Col>

                    {collection?.meta?.total > collection?.meta?.per_page && (
                        <Col md={6}>
                            <div className="float-end">
                                <Pagination
                                    activePage={collection.meta.page}
                                    itemsCountPerPage={collection.meta.per_page}
                                    totalItemsCount={collection.meta.total}
                                    onChange={(pageNumber) =>
                                        handlerChangeData({
                                            ...data,
                                            page: pageNumber,
                                        })
                                    }
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    firstPageText="First"
                                    lastPageText="last"
                                />
                            </div>
                        </Col>
                    )}
                </Row>
            )}
        </>
    );
};

export default Table;
