import { AmountFormat, Table } from "@/components/atoms";
import { DepositModal } from "@/components/molecules/";
import { getPath, Routes } from "@/constants";
import { getTransactionData } from "@/store/actions";
import { defaultCurrency, numberFormat, capitalizeFirstLetter } from "@/utils";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Row } from "reactstrap";
import ProfileLayout from "../Layout";
import { userRole } from "@/hooks";
import { Link, useParams } from "react-router-dom";

const Wallet = () => {
    const dispatch = useDispatch();
    const { profile } = useSelector((state) => state.agent);
    const { list, isLoading } = useSelector((state) => state.transaction);
    const { accountNo } = useParams();
    const [search, setSearch] = useState("");
    const [sortBy, setSortBy] = useState({
        field: "",
        type: "asc",
    });
    const [data, setData] = useState({
        account_no: accountNo,
        per_page: 10,
        page: 1,
        search: "",
        sort_by:
            sortBy.field && sortBy.type ? `${sortBy.field} ${sortBy.type}` : "",
    });

    const [depositModalIsOpen, setDepositModalIsOpen] = useState(false);

    const tableTheadData = [
        {
            label: "Transaction No.",
            field: "transaction_no",
            sortable: true,
        },
        {
            label: "Balance Category",
            field: "balance_category",
            sortable: false,
            render: (item) => capitalizeFirstLetter(item.balance_category),
        },
        {
            label: "Type",
            field: "type",
            sortable: false,
        },
        {
            label: "Amount",
            field: "amount",
            sortable: false,
            render: (item) => <AmountFormat amount={item.amount} />,
        },
        {
            label: "Balance Before",
            field: "balance_before",
            sortable: false,
            render: (item) => <AmountFormat amount={item.balance_before} />,
        },
        {
            label: "Balance After",
            field: "balance_after",
            sortable: false,
            render: (item) => <AmountFormat amount={item.balance_after} />,
        },
        {
            label: "Action",
            field: "action",
            sortable: false,
            render: (item) => (
                <Link
                    to={getPath(Routes.TRANSACTION_VIEW).replace(
                        ":transactionNo",
                        item?.transaction_no
                    )}
                    className="link-primary"
                >
                    <Button color="success" size="sm">
                        <i className="ri-eye-line"></i>
                    </Button>
                </Link>
            ),
        },
    ];

    const initialize = useCallback(() => {
        dispatch(getTransactionData(data));
    }, [dispatch, data]);

    useEffect(() => {
        initialize();
    }, [initialize]);

    return (
        <ProfileLayout>
            {depositModalIsOpen && (
                <DepositModal
                    isOpen={depositModalIsOpen}
                    setIsOpen={setDepositModalIsOpen}
                    userProfilekeyToken={profile.userProfilekeyToken}
                    initialize={initialize}
                />
            )}
            <Row>
                <Col>
                    <div className="card-body bg-light rounded">
                        <div className="d-flex align-items-center">
                            <h1 className="display-2 text-success">
                                {defaultCurrency()}
                            </h1>
                            <div className="flex-grow-1 ms-3">
                                <h1>
                                    {numberFormat(
                                        profile?.balance?.fiat?.php?.bcf ?? 0
                                    )}
                                </h1>
                                <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                                    {process.env.REACT_APP_BCF_LABEL}
                                </p>
                            </div>
                            {userRole()?.isOperator && (
                                <div>
                                    <Button
                                        color="success"
                                        onClick={() =>
                                            setDepositModalIsOpen(true)
                                        }
                                    >
                                        <span className="ri-add-fill me-1 align-bottom"></span>
                                        Deposit
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                </Col>
            </Row>
            <hr />
            <Row>
                <Col>
                    <h5>Transactions</h5>
                    <Table
                        search={search}
                        handlerSearch={setSearch}
                        sortBy={sortBy}
                        handlerSortBy={setSortBy}
                        thead={tableTheadData}
                        handlerChangeData={setData}
                        data={data}
                        collection={list}
                        isLoading={isLoading}
                        size="sm"
                    />
                </Col>
            </Row>
        </ProfileLayout>
    );
};

export default Wallet;
