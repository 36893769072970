import { Button, ErrorAlert, InputText } from "@/components/atoms";
import { changePasswordValidation } from "@/constants";
import { resetUserErrors, userChangePassword } from "@/store/actions";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Col, Form, FormGroup, Row } from "reactstrap";
import ProfileLayout from "../Layout";

const Index = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { errors, isLoading } = useSelector((state) => state.user);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            old_password: "",
            new_password: "",
            confirm_password: "",
        },
        validationSchema: changePasswordValidation,
        onSubmit: (values) => {
            dispatch(userChangePassword(values, navigate, validation));
        },
    });

    useEffect(() => {
        dispatch(resetUserErrors());
    }, [dispatch]);

    return (
        <ProfileLayout>
            <Row className="mb-3">
                <Col>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }}
                        className="needs-validation"
                        action="#"
                    >
                        <ErrorAlert
                            type="error"
                            color="danger"
                            errors={errors}
                        />
                        <Row>
                            <Col md="6">
                                <FormGroup className="mb-3">
                                    <InputText
                                        label="Secret key"
                                        name="account_no"
                                        validation={validation}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="6">
                                <FormGroup className="mb-3">
                                    <InputText
                                        label="Public key"
                                        name="account_no"
                                        validation={validation}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="12">
                                <FormGroup className="mb-3">
                                    <InputText
                                        label="Webhook URL"
                                        name="account_no"
                                        validation={validation}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                <Button
                                    isLoading={isLoading}
                                    color="success"
                                    className="btn btn-success float-end"
                                    type="submit"
                                    text="Save"
                                />
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </ProfileLayout>
    );
};

export default Index;
