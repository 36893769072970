import {
    ErrorAlert,
    InputActionChecker,
    InputTextGroup,
} from "@/components/atoms";
import { confirmation } from "@/components/constants/common";
import { mobileNoValidation } from "@/constants";
import { updateUserMobileNo } from "@/store/actions";
import { useFormik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import { FormGroup } from "reactstrap";

const MobileNoUpdateInput = ({ profile, isAdmin, errors }) => {
    const dispatch = useDispatch();

    const showConfirmation = () => {
        confirmation({
            title: "Confirmation Message",
            text: "Are you sure you want to save?",
            icon: "warning",
            confirmButtonText: "Save",
            action: () => validation.handleSubmit(),
        });
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            mobile_no: profile.mobileNo,
        },
        validationSchema: mobileNoValidation,
        onSubmit: (values) => {
            dispatch(updateUserMobileNo(values, profile.keyToken, validation));
        },
    });

    return (
        <>
            <ErrorAlert errors={errors} validation={validation} />
            <InputActionChecker
                label="Mobile Number"
                value={profile.mobileNo}
                isEdit={isAdmin}>
                <FormGroup className="mb-3">
                    <InputTextGroup
                        label="Mobile Number"
                        name="mobile_no"
                        buttonIcon="ri-pencil-line"
                        buttonAction={showConfirmation}
                        validation={validation}
                    />
                </FormGroup>
            </InputActionChecker>
        </>
    );
};

export default MobileNoUpdateInput;
