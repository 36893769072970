import {
    AmountFormat,
    CounterWidget,
    StatusBadge,
    Table,
    Title,
} from "@/components/atoms";
import BreadCrumb from "@/components/common/BreadCrumb";
import {
    BatchPayoutStatusModal,
    ImportPayoutModal,
    ImportPayoutStatusModal,
} from "@/components/molecules";
import { Routes, getPath, getPathTitle } from "@/constants";
import { confirmation } from "@/components/constants/common";
import { walletTransactionCounter } from "@/constants/users";
import {
    bulkPayout,
    getBulkPayoutProgress,
    getRequestData,
    generateBatchPayoutReport,
    getAllowedInWithdrawalPaymentProviders,
} from "@/store/actions";
import { capitalizeFirstLetter, defaultDateTimeFormat } from "@/utils";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Input,
    Row,
} from "reactstrap";
import Swal from "sweetalert2";
import { getPermission, userRole } from "@/hooks";

const WithdrawalRequestList = () => {
    const isMounted = useRef(false);
    const dispatch = useDispatch();

    const { allowedInWithdrawalPaymentProviders } = useSelector(
        (state) => state.paymentSetting
    );

    const {
        list,
        isLoading,
        isBulkReleaseSuccess,
        isBulkPayoutSuccess,
        payoutProgress,
        isImportPayoutSuccess,
        importPayoutResult,
    } = useSelector((state) => state.request);

    const { isGenerateBatchPayoutReportSuccess } = useSelector(
        (state) => state.report
    );

    const { user } = useSelector((state) => state.user);

    const { isSuperAdmin, isLicenseeAdmin } = userRole();
    const isAdmin =
        user?.role.slug === "super-admin" || user?.role.slug === "admin"
            ? true
            : false;

    const [isImportModalOpen, setIsImportModalOpen] = useState(false);
    const [isImportPayoutStatusModalOpen, setIsImportPayoutStatusModalOpen] =
        useState(false);
    const [isBatchPayoutStatusModalOpen, setIsBatchPayoutStatusModalOpen] =
        useState(false);

    const [search, setSearch] = useState("");
    const [sortBy, setSortBy] = useState({
        field: "",
        type: "asc",
    });

    const defaultParams = {
        account_no: "",
        per_page: 10,
        page: 1,
        search: "",
        sort_by:
            sortBy.field && sortBy.type ? `${sortBy.field} ${sortBy.type}` : "",
        "date_range[transaction_requests.created_at][from]": "",
        "date_range[transaction_requests.created_at][to]": "",
        for_payout_release: "swiftpay",
    };

    const [data, setData] = useState(defaultParams);
    const withdrawalCounter = walletTransactionCounter(list);

    const [selectedRow, setSelectedRow] = useState([]);

    const handleBulkPayout = () => {
        Swal.fire({
            text: `Are you sure you want to execute bulk payout?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Confirm",
            reverseButtons: true,
        }).then((result) => {
            if (result.isConfirmed) {
                setSelectedRow([]);
                dispatch(
                    bulkPayout({
                        transaction_requests: selectedRow,
                        payment_provider: data.for_payout_release,
                    })
                );
            }
        });
    };

    const handleGenerateReport = () => {
        confirmation({
            title: "Confirmation Message",
            text: "Are you sure you want to generate batch payout report?",
            icon: "warning",
            confirmButtonText: "Yes",
            action: () =>
                dispatch(
                    generateBatchPayoutReport({
                        payment_provider: data.for_payout_release,
                    })
                ),
        });
    };

    const tableTheadData = useMemo(() => {
        const handleToggle = (e) => {
            const data = e.target.value;
            const isChecked = e.target.checked;
            let rows = selectedRow;

            if (isChecked) {
                setSelectedRow(() => [...rows, data]);
            } else {
                setSelectedRow(() => rows.filter((item) => item !== data));
            }
        };

        let columns = [];
        if (
            ["monetix", "swiftpay", "akpay", "coinspaid"].includes(data.for_payout_release) &&
            allowedInWithdrawalPaymentProviders.filter((item) =>
                ["monetix", "swiftpay", "akpay", "coinspaid"].includes(item.key_name)
            ).length !== 0
        ) {
            columns = [
                {
                    field: "key_token",
                    sortable: false,
                    render: (item) => (
                        <Input
                            className="form-check-input"
                            type="checkbox"
                            id="inlinCheckbox2"
                            value={item.key_token}
                            onChange={handleToggle}
                            checked={selectedRow.includes(item.key_token)}
                        />
                    ),
                },
            ];
        }

        columns = [
            ...columns,
            {
                label: "Request No.",
                field: "transaction_request_no",
                sortable: true,
            },
            {
                label: "Account No.",
                field: "account_no",
                sortable: true,
            },
            {
                label: "Balance Category",
                field: "balance_category",
                sortable: false,
                render: (item) => capitalizeFirstLetter(item.balance_category),
            },
            {
                label: "Fee %",
                field: "fee_percent",
                sortable: true,
                render: (item) => item.fee_percent,
            },
            {
                label: "Fee Amount",
                field: "fee_amount",
                sortable: true,
                render: (item) => <AmountFormat amount={item.fee_amount} />,
            },
            {
                label: "Amount",
                field: "amount",
                sortable: true,
                render: (item) => <AmountFormat amount={item.amount} />,
            },
            {
                label: "Datetime Requested",
                field: "created_at",
                sortable: true,
                render: (item) => defaultDateTimeFormat(item.created_at),
            },
            {
                label: "Status",
                field: "status",
                sortable: true,
                render: (item) => (
                    <StatusBadge status={item.status} type="request" />
                ),
            },
            {
                label: "Action",
                field: "action",
                sortable: false,
                render: (item) => (
                    <Link
                        to={getPath(Routes.FOR_PAYOUT_RELEASE_VIEW).replace(
                            ":transactionRequestNo",
                            item?.transaction_request_no
                        )}
                        className="link-primary"
                    >
                        <Button color="success" size="sm">
                            <i className="ri-eye-line"></i>
                        </Button>
                    </Link>
                ),
            },
        ];
        return columns;
    }, [data, selectedRow, allowedInWithdrawalPaymentProviders]);

    useEffect(() => {
        dispatch(getAllowedInWithdrawalPaymentProviders());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getBulkPayoutProgress());
        dispatch(getRequestData(data, "withdrawal"));
    }, [dispatch, data]);

    useEffect(() => {
        const counter = setTimeout(() => {
            if (payoutProgress && payoutProgress?.progress !== 100) {
                dispatch(getBulkPayoutProgress());
            }
        }, 3000);

        if (payoutProgress?.progress === 100) {
            dispatch(getRequestData(data, "withdrawal"));
        }

        return () => clearTimeout(counter);
    }, [dispatch, payoutProgress, data]);

    useEffect(() => {
        if (isMounted.current) {
            if (
                isBulkReleaseSuccess ||
                isImportPayoutSuccess ||
                isBulkPayoutSuccess ||
                isGenerateBatchPayoutReportSuccess
            ) {
                if (isImportPayoutSuccess) {
                    setIsImportModalOpen(false);
                    setIsImportPayoutStatusModalOpen(true);
                }

                if (isBulkPayoutSuccess) {
                    dispatch(getBulkPayoutProgress());
                    setIsBatchPayoutStatusModalOpen(true);
                }

                setSelectedRow([]);
                dispatch(getRequestData(data, "withdrawal"));
            }
        } else {
            isMounted.current = true;
        }
    }, [
        dispatch,
        data,
        isBulkReleaseSuccess,
        isImportPayoutSuccess,
        isBulkPayoutSuccess,
        isGenerateBatchPayoutReportSuccess,
    ]);

    useEffect(() => {
        if (
            allowedInWithdrawalPaymentProviders.filter(
                (item) => item.key_name === "swiftpay"
            ).length !== 0
        ) {
            setData((data) => ({ ...data, for_payout_release: "swiftpay" }));
        } else if (
            allowedInWithdrawalPaymentProviders.filter((item) => item.key_name === "monetix")
                .length !== 0
        ) {
            setData((data) => ({ ...data, for_payout_release: "monetix" }));
        } else if (
            allowedInWithdrawalPaymentProviders.filter((item) => item.key_name === "h2pay")
                .length !== 0
        ) {
            setData((data) => ({ ...data, for_payout_release: "h2pay" }));
        } else if (
            allowedInWithdrawalPaymentProviders.filter((item) => item.key_name === "akpay")
                .length !== 0
        ) {
            setData((data) => ({ ...data, for_payout_release: "akpay" }));
        } else if (
            allowedInWithdrawalPaymentProviders.filter((item) => item.key_name === "coinspaid")
                .length !== 0
        ) {
            setData((data) => ({ ...data, for_payout_release: "coinspaid" }));
        }
    }, [allowedInWithdrawalPaymentProviders]);

    return (
        <React.Fragment>
            <Title title={getPathTitle(Routes.FOR_PAYOUT_RELEASE)} />
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb
                        parent={{
                            isParent: true,
                            pageTitle: getPathTitle(Routes.FOR_PAYOUT_RELEASE),
                            pageLink: getPath(Routes.FOR_PAYOUT_RELEASE),
                        }}
                    />
                    <Row>
                        {withdrawalCounter.map((item, key) => (
                            <Col key={key}>
                                <CounterWidget item={item} icon="₱" />
                            </Col>
                        ))}
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardHeader className="align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">
                                        {getPathTitle(
                                            Routes.FOR_PAYOUT_RELEASE
                                        )}{" "}
                                        List
                                    </h4>
                                    <div className="flex-shrink-0">
                                        {(isAdmin &&
                                            data.for_payout_release ===
                                                "h2pay" &&
                                            allowedInWithdrawalPaymentProviders.filter(
                                                (item) =>
                                                    item.key_name === "h2pay"
                                            ).length !== 0 && (
                                                <div className="tw-flex tw-flex-row tw-justify-end tw-items-center gap-2">
                                                    <Button
                                                        size="sm"
                                                        color="primary"
                                                        onClick={() =>
                                                            handleGenerateReport()
                                                        }
                                                    >
                                                        <span className="las la-clipboard-list tw-mr-1" />
                                                        Generate Batch Payout
                                                        Report
                                                    </Button>
                                                    <Button
                                                        size="sm"
                                                        color="success"
                                                        className="tw-flex flex-row tw-items-center justify-center gap-1"
                                                        onClick={() =>
                                                            setIsImportModalOpen(
                                                                true
                                                            )
                                                        }
                                                    >
                                                        <span className="ri-money-dollar-circle-line" />
                                                        Import Payout Result
                                                    </Button>
                                                </div>
                                            )) ||
                                            (isAdmin &&
                                                data.for_payout_release ===
                                                    "monetix" &&
                                                allowedInWithdrawalPaymentProviders.filter(
                                                    (item) =>
                                                        item.key_name ===
                                                        "monetix"
                                                ).length !== 0 && (
                                                    <div className="tw-flex tw-flex-row tw-justify-end tw-items-center gap-2">
                                                        {payoutProgress && (
                                                            <Button
                                                                size="sm"
                                                                color="primary"
                                                                className="tw-flex flex-row tw-items-center justify-center gap-1"
                                                                onClick={() =>
                                                                    setIsBatchPayoutStatusModalOpen(
                                                                        true
                                                                    )
                                                                }
                                                            >
                                                                <span className="mdi mdi-briefcase-clock" />
                                                                Batch Payout
                                                                Status
                                                            </Button>
                                                        )}
                                                        <Button
                                                            size="sm"
                                                            color="primary"
                                                            className="tw-flex flex-row tw-items-center justify-center gap-1"
                                                            disabled={
                                                                selectedRow.length ===
                                                                0
                                                            }
                                                            onClick={
                                                                handleBulkPayout
                                                            }
                                                        >
                                                            <span className="ri-money-dollar-circle-line" />
                                                            Bulk Payout Release
                                                        </Button>
                                                        <Button
                                                            size="sm"
                                                            color="primary"
                                                            onClick={() =>
                                                                handleGenerateReport()
                                                            }
                                                        >
                                                            <span className="las la-clipboard-list tw-mr-1" />
                                                            Generate Batch
                                                            Payout Report
                                                        </Button>
                                                        <Button
                                                            size="sm"
                                                            color="success"
                                                            className="tw-flex flex-row tw-items-center justify-center gap-1"
                                                            onClick={() =>
                                                                setIsImportModalOpen(
                                                                    true
                                                                )
                                                            }
                                                        >
                                                            <span className="ri-money-dollar-circle-line" />
                                                            Import Payout Result
                                                        </Button>
                                                    </div>
                                                )) ||
                                            (isAdmin &&
                                                ((data.for_payout_release ===
                                                    "swiftpay" &&
                                                allowedInWithdrawalPaymentProviders.filter(
                                                    (item) =>
                                                        item.key_name ===
                                                        "swiftpay"
                                                ).length !== 0) || (data.for_payout_release ===
                                                "akpay" &&
                                            allowedInWithdrawalPaymentProviders.filter(
                                                (item) =>
                                                    item.key_name ===
                                                    "akpay"
                                            ).length !== 0) || (data.for_payout_release ===
                                                "coinspaid" &&
                                            allowedInWithdrawalPaymentProviders.filter(
                                                (item) =>
                                                    item.key_name ===
                                                    "coinspaid"
                                            ).length !== 0)) && (
                                                    <div className="tw-flex tw-flex-row tw-justify-end tw-items-center gap-2">
                                                        {payoutProgress && (
                                                            <Button
                                                                size="sm"
                                                                color="primary"
                                                                className="tw-flex flex-row tw-items-center justify-center gap-1"
                                                                onClick={() =>
                                                                    setIsBatchPayoutStatusModalOpen(
                                                                        true
                                                                    )
                                                                }
                                                            >
                                                                <span className="mdi mdi-briefcase-clock" />
                                                                Batch Payout
                                                                Status
                                                            </Button>
                                                        )}
                                                        <Button
                                                            size="sm"
                                                            color="primary"
                                                            className="tw-flex flex-row tw-items-center justify-center gap-1"
                                                            disabled={
                                                                selectedRow.length ===
                                                                0
                                                            }
                                                            onClick={
                                                                handleBulkPayout
                                                            }
                                                        >
                                                            <span className="ri-money-dollar-circle-line" />
                                                            Bulk Payout Release
                                                        </Button>
                                                    </div>
                                                ))}
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Row className="mb-3">
                                        {(isAdmin || isLicenseeAdmin) && (
                                            <Col>
                                                {allowedInWithdrawalPaymentProviders.map(
                                                    (item, index) => (
                                                        <Button
                                                            key={index}
                                                            color={
                                                                data.for_payout_release ===
                                                                item.key_name
                                                                    ? "primary"
                                                                    : "light"
                                                            }
                                                            onClick={() => {
                                                                setSelectedRow(
                                                                    []
                                                                );
                                                                setData({
                                                                    ...data,
                                                                    for_payout_release:
                                                                        item.key_name,
                                                                    page: 1,
                                                                });
                                                            }}
                                                            className="me-2"
                                                        >
                                                            {item.name}
                                                        </Button>
                                                    )
                                                )}
                                            </Col>
                                        )}
                                    </Row>
                                    <Table
                                        search={search}
                                        handlerSearch={setSearch}
                                        sortBy={sortBy}
                                        handlerSortBy={setSortBy}
                                        thead={tableTheadData}
                                        handlerChangeData={setData}
                                        data={data}
                                        collection={list}
                                        isLoading={isLoading}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            {isImportModalOpen && (
                <ImportPayoutModal
                    isOpen={isImportModalOpen}
                    setIsOpen={setIsImportModalOpen}
                    paymentProvider={data.for_payout_release}
                />
            )}
            {isBatchPayoutStatusModalOpen && (
                <BatchPayoutStatusModal
                    data={payoutProgress}
                    isOpen={isBatchPayoutStatusModalOpen}
                    setIsOpen={setIsBatchPayoutStatusModalOpen}
                />
            )}
            {isImportPayoutStatusModalOpen && (
                <ImportPayoutStatusModal
                    data={importPayoutResult}
                    isOpen={isImportPayoutStatusModalOpen}
                    setIsOpen={setIsImportPayoutStatusModalOpen}
                />
            )}
        </React.Fragment>
    );
};

export default WithdrawalRequestList;
