import {
    Button,
    InputReactSelect,
    InputSwitch,
    Table,
    Title,
} from "@/components/atoms";
import { AddFeaturedGameModal, UpdateFeaturedBannerModal } from "@/components/molecules";
import BreadCrumb from "@/components/common/BreadCrumb";
import { confirmation } from "@/components/constants/common";
import { getPath, getPathTitle, Routes } from "@/constants";
import {
    getCasinoGameList,
    getCasinoGameProvider,
    getCasinoGameType,
    updateGameStatus,
    getFeaturedGameTypes,
    deleteFeaturedGame,
    updateFeaturedGameOrder,
} from "@/store/actions";
import { arrayToString } from "@/utils";
import { useFormik } from "formik";
import React, {
    useCallback,
    useEffect,
    useState,
    useMemo,
    useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row,
    Input,
    Button as ButtonStrap,
} from "reactstrap";

const GameList = () => {
    const isMounted = useRef(false);
    const isMounted1 = useRef(false);
    const dispatch = useDispatch();
    const {
        gameList,
        gameProviderList,
        gameTypeList,
        isLoading,
        isGameProviderListLoaded,
        isGameTypeListLoaded,
        featuredGameTypes,
        isDeleteFeaturedGameLoading,
        isDeleteFeaturedGameSuccess,
        isUpdateFeaturedGameOrderLoading,
        isUpdateFeaturedGameOrderSuccess,
    } = useSelector((state) => state.game);

    const [search, setSearch] = useState("");
    const [sortBy, setSortBy] = useState({
        field: "",
        type: "asc",
    });

    const [featuredGameType, setFeaturedGameType] = useState(null);
    const [selectedGame, setSelectedGame] = useState(null);
    const [addFeaturedModalIsOpen, setAddFeaturedModalIsOpen] = useState(false);
    const [updateFeaturedModalIsOpen, setUpdateFeaturedModalIsOpen] = useState(false);
    const [featuredGameOrder, setFeaturedGameOrder] = useState({});

    const [data, setData] = useState({
        page: 1,
        per_page: 10,
        search: "",
        "filter_by[game_provider_id]": "",
        "filter_by[game_type_id]": "",
    });

    // Form
    const form = useFormik({
        enableReinitialize: true,
        initialValues: {
            gameProviders: "",
            gameTypes: "",
        },
        onSubmit: (values) => {
            setData({
                ...data,
                "filter_by[game_provider_games.game_provider_id]": values.gameProviders,
                "filter_by[game_provider_games.game_type_id]": values.gameTypes,
            });
        },
    });

    const filterGameProvider = (values) => {
        let collection = values.map((item) => item.value);
        form.setFieldValue("gameProviders", arrayToString(collection));
    };

    const filterGameType = (values) => {
        let collection = values.map((item) => item.value);
        form.setFieldValue("gameTypes", arrayToString(collection));
    };

    const getCasinoGames = useCallback(() => {
        dispatch(getCasinoGameList(data));
    }, [dispatch, data]);

    useEffect(() => {
        dispatch(getFeaturedGameTypes());
    }, [dispatch]);

    useEffect(() => {
        setData((data) => ({
            ...data,
            "filter_by[featured_game_types.key_name]": featuredGameType,
            sort_by: featuredGameType ? "order_by asc" : "",
            per_page: featuredGameType ? 50 : 10,
            page: 1,
        }));
    }, [featuredGameType]);

    useEffect(() => {
        if (isMounted1.current) {
            if (gameList.isFeaturedGames) {
                let gameObj = {};
                gameList.data.forEach((value) => {
                    gameObj = {
                        ...gameObj,
                        [value.featured_key_token]: value?.order_by ?? "",
                    };
                });
                setFeaturedGameOrder(() => ({ ...gameObj }));
            }
        } else isMounted1.current = true;
    }, [gameList]);

    useEffect(() => {
        getCasinoGames();
        if (!isGameProviderListLoaded) dispatch(getCasinoGameProvider());
        if (!isGameTypeListLoaded) dispatch(getCasinoGameType());
    }, [
        dispatch,
        getCasinoGames,
        data,
        isGameProviderListLoaded,
        isGameTypeListLoaded,
    ]);

    useEffect(() => {
        if (isMounted.current) {
            if (
                (!isDeleteFeaturedGameLoading && isDeleteFeaturedGameSuccess) ||
                (!isUpdateFeaturedGameOrderLoading &&
                    isUpdateFeaturedGameOrderSuccess)
            ) {
                getCasinoGames();
            }
        } else isMounted.current = true;
    }, [
        getCasinoGames,
        isDeleteFeaturedGameLoading,
        isDeleteFeaturedGameSuccess,
        isUpdateFeaturedGameOrderLoading,
        isUpdateFeaturedGameOrderSuccess,
    ]);

    const tableTheadData = useMemo(() => {
        const defaultColumns = [
            {
                label: "Game Name",
                field: "game_name",
                sortable: true,
            },
            {
                label: "Game Provider",
                field: "game_provider_name",
                sortable: false,
            },
            {
                label: "Game Type",
                field: "type",
                render: (item) => (item.type ? item.type : "-"),
            },
            {
                label: "Play for fun",
                field: "play_for_fun_supported",
                render: (item) =>
                    item.play_for_fun_supported === "1" ? "Yes" : "No",
            },
            {
                label: "Status",
                field: "is_active",
                render: (item) => (
                    <InputSwitch
                        label={item.is_active ? "Active" : "Inactive"}
                        id={`game-provider-game-${item.key_token}`}
                        className={`game-provider-game-${item.key_token}`}
                        isChecked={item.is_active}
                        color="success"
                        onChange={() => changeStatus(item)}
                    />
                ),
            },
            {
                label: "Action",
                field: "action",
                sortable: false,
                render: (item) => (
                    <ButtonStrap
                        onClick={() => {
                            setAddFeaturedModalIsOpen(true);
                            setSelectedGame(item);
                        }}
                        color="primary"
                        size="sm">
                        <i className="ri-play-list-add-line"></i>
                    </ButtonStrap>
                ),
            },
        ];

        const featuredGamesColumns = [
            {
                label: "Game Name",
                field: "game_name",
                sortable: true,
            },
            {
                label: "Game Provider",
                field: "game_provider_name",
                sortable: false,
            },
            {
                label: "Game Type",
                field: "type",
                render: (item) => (item.type ? item.type : "-"),
            },
            {
                label: "Play for fun",
                field: "play_for_fun_supported",
                render: (item) =>
                    item.play_for_fun_supported === "1" ? "Yes" : "No",
            },
            {
                label: "Order By",
                field: "order_by",
                render: (item) => (
                    <div className="tw-flex tw-flex-row tw-flex-wrap tw-gap-1 tw-justify-center">
                        <Input
                            disabled
                            className="tw-w-12 text-center"
                            onChange={(e) => {
                                setFeaturedGameOrder(() => ({
                                    ...featuredGameOrder,
                                    [item.featured_key_token]: e.target.value,
                                }));
                            }}
                            value={
                                featuredGameOrder[item.featured_key_token] ?? ""
                            }
                            type="number"
                            bsSize="sm"
                        />
                        {/* <ButtonStrap
                            onClick={() =>
                                dispatch(
                                    updateFeaturedGameOrder(
                                        {
                                            order_by:
                                                featuredGameOrder[
                                                    item.featured_key_token
                                                ],
                                        },
                                        item.featured_key_token
                                    )
                                )
                            }
                            color="primary"
                            size="sm">
                            <i className="las la-sort"></i>
                        </ButtonStrap> */}
                    </div>
                ),
            },
            {
                label: "Actions",
                field: "action",
                sortable: false,
                render: (item) => (
                    <div className="tw-flex tw-flex-row tw-flex-wrap tw-gap-1 tw-justify-center">
                          <ButtonStrap
                            onClick={() => {
                                setUpdateFeaturedModalIsOpen(true);
                                console.log(item)
                                setSelectedGame(item);
                            }}
                            color="primary"
                            size="sm">
                            <i className="ri-pencil-line"></i>
                        </ButtonStrap>
                        <ButtonStrap
                            onClick={() => handleDeleteFeaturedGame(item)}
                            color="danger"
                            size="sm">
                            <i className="bx bxs-trash"></i>
                        </ButtonStrap>
                    </div>
                ),
            },
        ];

        const changeStatus = (item) => {
            confirmation({
                title: item.title,
                text: `Are you sure you want to ${
                    item.is_active ? "DEACTIVATE" : "ACTIVE"
                }`,
                icon: "warning",
                confirmButtonText: "Confirm",
                action: () => {
                    dispatch(
                        updateGameStatus(
                            "game-provider-game",
                            item.key_token,
                            getCasinoGames
                        )
                    );
                },
            });
        };

        const handleDeleteFeaturedGame = (item) => {
            confirmation({
                title: "Are you sure?",
                text: `Are you sure you want to delete ${item.game_name} to featured games?`,
                icon: "question",
                confirmButtonText: "Confirm",
                action: () => {
                    dispatch(deleteFeaturedGame(item.featured_key_token));
                },
            });
        };

        return featuredGameType ? featuredGamesColumns : defaultColumns;
    }, [dispatch, getCasinoGames, featuredGameType, featuredGameOrder]);

    return (
        <React.Fragment>
            <Title title={getPathTitle(Routes.CASINO_GAME_LIST)} />
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb
                        parent={{
                            isParent: true,
                            pageTitle: getPathTitle(Routes.CASINO_GAME_LIST),
                            pageLink: getPath(Routes.CASINO_GAME_LIST),
                        }}
                    />
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardHeader className="align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">
                                        {getPathTitle(Routes.CASINO_GAME_LIST)}
                                    </h4>
                                </CardHeader>
                                <CardBody>
                                    <Row className="mb-3">
                                        <Col md={6}>
                                            <InputReactSelect
                                                isMulti
                                                label="Game Providers"
                                                name="gameProviders"
                                                isClearable={true}
                                                options={gameProviderList}
                                                onChange={(option) => {
                                                    filterGameProvider(option);
                                                }}
                                            />
                                        </Col>
                                        <Col md={6}>
                                            <InputReactSelect
                                                isMulti
                                                label="Game Types"
                                                name="gameTypes"
                                                isClearable={true}
                                                options={gameTypeList}
                                                onChange={(option) => {
                                                    filterGameType(option);
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} className="text-center">
                                            <Button
                                                color="success"
                                                onClick={() =>
                                                    form.handleSubmit()
                                                }
                                                text="Filter"
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardHeader className="align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">
                                        {getPathTitle(Routes.CASINO_GAME_LIST)}
                                    </h4>
                                </CardHeader>
                                <CardBody>
                                    <Row className="mb-3">
                                        <Col>
                                            {[
                                                { key_name: null, name: "All" },
                                                ...featuredGameTypes,
                                            ].map((value, index) => (
                                                <ButtonStrap
                                                    key={index}
                                                    color={
                                                        featuredGameType ===
                                                        value?.key_name
                                                            ? "primary"
                                                            : "light"
                                                    }
                                                    onClick={() =>
                                                        setFeaturedGameType(
                                                            value?.key_name
                                                        )
                                                    }
                                                    className="me-2">
                                                    {value?.name}
                                                </ButtonStrap>
                                            ))}
                                        </Col>
                                    </Row>
                                    <Table
                                        search={search}
                                        handlerSearch={setSearch}
                                        sortBy={sortBy}
                                        handlerSortBy={setSortBy}
                                        thead={tableTheadData}
                                        handlerChangeData={setData}
                                        data={data}
                                        collection={gameList}
                                        isLoading={isLoading}
                                        tableTextAlign="center"
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            {addFeaturedModalIsOpen && (
                <AddFeaturedGameModal
                    isOpen={addFeaturedModalIsOpen}
                    setIsOpen={setAddFeaturedModalIsOpen}
                    featuredGameTypes={featuredGameTypes}
                    game={selectedGame}
                />
            )}

            {updateFeaturedModalIsOpen && (
                <UpdateFeaturedBannerModal
                    isOpen={updateFeaturedModalIsOpen}
                    setIsOpen={setUpdateFeaturedModalIsOpen}
                    featuredGameTypes={featuredGameTypes}
                    game={selectedGame}
                    featuredGameType={featuredGameType}
                    getCasinoGames={getCasinoGames}
                />
            )}
        </React.Fragment>
    );
};

export default GameList;
