import { axiosInstance } from "./index";

export const getDashboardAnalyticsMainTotal = async (keyToken = null) => {
    return await axiosInstance.get(
        `/bo/analytics/main-total${keyToken ? `/${keyToken}` : ""}`
    );
};

export const getDashboardAnalyticsOverallTotal = async ({
    params,
    keyToken = null,
}) => {
    return await axiosInstance.get(
        `/bo/analytics/overall-total${keyToken ? `/${keyToken}` : ""}`,
        { params }
    );
};

export const getDailyTotalGgrSummary = async ({ params, keyToken = null }) => {
    return await axiosInstance.get(
        `/bo/analytics/graph-ggr${keyToken ? `/${keyToken}` : ""}`,
        { params }
    );
};

export const getDailyTotalDepositAndWithdrawalSummary = async ({
    params,
    keyToken = null,
}) => {
    return await axiosInstance.get(
        `/bo/analytics/graph-deposit-withdrawal${
            keyToken ? `/${keyToken}` : ""
        }`,
        { params }
    );
};

export const getMonthlyTotalPlayerSummary = async ({
    params,
    keyToken = null,
}) => {
    return await axiosInstance.get(
        `bo/analytics/monthly-total-player-summary${
            keyToken ? `/${keyToken}` : ""
        }`,
        { params }
    );
};
