import React, { useState } from "react";

const Accordion = ({ title, content }) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
        <div className="accordion accordion-border-box" id="accordionlefticon">
            <div className="accordion-item">
                <h2 className="accordion-header" id="accordionlefticonExample1">
                    <button
                        onClick={() => setIsOpen(!isOpen)}
                        className={`accordion-button ${
                            !isOpen ? "collapsed" : ""
                        }`}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#accor_lefticonExamplecollapse1"
                        aria-expanded={isOpen}
                        aria-controls="accor_lefticonExamplecollapse1"
                    >
                        {title}
                    </button>
                </h2>
                <div
                    id="accor_lefticonExamplecollapse1"
                    className={`accordion-collapse collapse ${
                        isOpen ? "show" : ""
                    }`}
                    aria-labelledby="accordionlefticonExample1"
                    data-bs-parent="#accordionlefticon"
                >
                    <div className="accordion-body">{content}</div>
                </div>
            </div>
        </div>
    );
};

export default Accordion;
