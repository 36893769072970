import * as types from "./actionTypes";
import { requestState } from "@/types/states";

const initialState = requestState;

const request = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_REQUEST_DATA:
            state = {
                ...state,
                isLoading: true,
                isBulkPayoutSuccess: false,
                isImportPayoutLoading: false,
                isImportPayoutSuccess: false,
            };
            break;
        case types.SET_REQUEST_DATA:
            state = {
                ...state,
                list: action.payload,
                isLoading: false,
            };
            break;
        case types.SET_REQUEST_DATA_ERROR:
            state = {
                ...state,
                isLoading: false,
            };
            break;
        case types.GET_REQUEST_INFO:
            state = {
                ...state,
                isLoading: true,
            };
            break;
        case types.SET_REQUEST_INFO:
            state = {
                ...state,
                selected: action.payload,
                isLoading: false,
            };
            break;
        case types.SET_REQUEST_INFO_ERROR:
            state = {
                ...state,
                isLoading: false,
            };
            break;
        case types.UPDATE_REQUEST_STATUS:
            state = {
                ...state,
                isLoading: true,
            };
            break;
        case types.UPDATE_REQUEST_STATUS_SUCCESS:
            state = {
                ...state,
                selected: action.payload,
                errors: requestState.errors,
                isLoading: false,
            };
            break;
        case types.UPDATE_REQUEST_STATUS_ERROR:
            state = {
                ...state,
                isLoading: false,
                errors: action.payload,
            };
            break;
        case types.RESET_REQUEST_ERRORS:
            state = {
                ...state,
                errors: requestState.errors,
            };
            break;
        case types.BULK_RELEASE:
            state = {
                ...state,
                isBulkReleaseLoading: true,
                isBulkReleaseSuccess: false,
            };
            break;
        case types.BULK_RELEASE_SUCCESS:
            state = {
                ...state,
                isBulkReleaseLoading: false,
                isBulkReleaseSuccess: true,
            };
            break;
        case types.BULK_RELEASE_FAILED:
            state = {
                ...state,
                isBulkReleaseLoading: false,
                isBulkReleaseSuccess: false,
            };
            break;
        case types.BULK_PAYOUT:
            state = {
                ...state,
                isBulkPayoutLoading: true,
                isBulkPayoutSuccess: false,
            };
            break;
        case types.BULK_PAYOUT_SUCCESS:
            state = {
                ...state,
                isBulkPayoutLoading: false,
                isBulkPayoutSuccess: true,
            };
            break;
        case types.BULK_PAYOUT_FAILED:
            state = {
                ...state,
                isBulkPayoutLoading: false,
                isBulkPayoutSuccess: false,
            };
            break;
        case types.GET_BULK_PAYOUT_PROGRESS_SUCCESS:
            state = {
                ...state,
                payoutProgress: action.payload,
            };
            break;
        case types.GET_BULK_PAYOUT_PROGRESS_FAILED:
            state = {
                ...state,
                payoutProgress: null,
            };
            break;
        case types.IMPORT_PAYOUT:
            state = {
                ...state,
                isImportPayoutLoading: true,
                isImportPayoutSuccess: false,
            };
            break;
        case types.IMPORT_PAYOUT_SUCCESS:
            state = {
                ...state,
                importPayoutResult: action.payload,
                isImportPayoutLoading: false,
                isImportPayoutSuccess: true,
            };
            break;
        case types.IMPORT_PAYOUT_FAILED:
            state = {
                ...state,
                isImportPayoutLoading: false,
                isImportPayoutSuccess: false,
                errors: action.payload,
            };
            break;
        case types.SET_PUBLIC_PAYMENT_METHODS:
            state = {
                ...state,
                paymentMethods: action.payload,
            }
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
};

export default request;
