import {
    loadingToast,
    removeToast,
    successToast,
    errorToast,
} from "@/components/constants/toast";
import * as API from "@/network/apis/promotion";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import * as TYPES from "./actionTypes";
import * as ACTIONS from "./actions";
import { convert2dErrorsArray } from "@/utils";

function* getPromotionCategoryList() {
    try {
        const response = yield call(API.getPromotionCategoryList);

        if (response.status === 200) {
            yield put(ACTIONS.setPromotionCategoryList(response.data.response));
        }
    } catch (error) {
        yield put(ACTIONS.setPromotionCategoryListError());
        if (error?.response?.data?.message) {
            errorToast(error.response.data.message);
        }
    }
}

function* updatePromotionCategoryStatus({ keyToken }) {
    try {
        loadingToast("Updating...");
        const response = yield call(
            API.updatePromotionCategoryStatus,
            keyToken
        );

        if (response.status === 200) {
            let data = response.data.response;
            yield put(ACTIONS.updatePromotionCategoryStatusSuccess());
            removeToast();
            successToast("Successfully Updated.");
        }
    } catch (error) {
        yield put(ACTIONS.updatePromotionCategoryStatusError());
        if (error?.response?.data?.message) {
            errorToast(error.response.data.message);
        }
    }
}

function* getPromotionList({ payload }) {
    try {
        const response = yield call(API.getPromotionList, payload);

        if (response.status === 200) {
            yield put(ACTIONS.setPromotionList(response.data.response));
        }
    } catch (error) {
        yield put(ACTIONS.setPromotionListError());
        if (error?.response?.data?.message) {
            errorToast(error.response.data.message);
        }
    }
}

function* getPromotionTransactionList({ payload }) {
    try {
        const response = yield call(API.getPromotionTransactionList, payload);

        if (response.status === 200) {
            yield put(ACTIONS.setPromotionTransactionList(response.data.response));
        }
    } catch (error) {
        yield put(ACTIONS.setPromotionTransactionListError());
        if (error?.response?.data?.message) {
            errorToast(error.response.data.message);
        }
    }
}

function* getPromotionTransactionView({ payload }) {
    try {
        const response = yield call(API.getPromotionTransactionView, payload);

        if (response.status === 200) {
            yield put(ACTIONS.setPromotionTransactionView(response.data.response));
        }
    } catch (error) {
        yield put(ACTIONS.setPromotionTransactionViewError());
        if (error?.response?.data?.message) {
            errorToast(error.response.data.message);
        }
    }
}

function* getPromotionCategoryByKeyToken({ keyToken }) {
    try {
        const response = yield call(
            API.getPromotionCategoryByKeyToken,
            keyToken
        );

        if (response.status === 200) {
            yield put(
                ACTIONS.setPromotionCategoryByKeyToken(response.data.response)
            );
        }
    } catch (error) {
        yield put(ACTIONS.setPromotionCategoryByKeyTokenError());
        if (error?.response?.data?.message) {
            errorToast(error.response.data.message);
        }
    }
}

function* createPromotion({ payload, initialize }) {
    try {
        const response = yield call(API.createPromotion, payload);
        if (response.status === 200) {
            yield put(ACTIONS.createPromotionSuccess());
            initialize();
            successToast("Successfully Added.");
        }
    } catch (error) {
        document.documentElement.scrollTop = 0;
        let errors = !Array.isArray(error?.response?.data?.response)
            ? convert2dErrorsArray(error?.response?.data?.response)
            : [error.response.data.message];
        yield put(ACTIONS.createPromotionError(errors));
    }
}

function* getPromotionByNo({ promotionNo }) {
    try {
        const response = yield call(API.getPromotionByNo, promotionNo);

        if (response.status === 200) {
            yield put(ACTIONS.setPromotionByNo(response.data.response));
        }
    } catch (error) {
        yield put(ACTIONS.setPromotionByNoError());
        if (error?.response?.data?.message) {
            errorToast(error.response.data.message);
        }
    }
}

function* updatePromotionStatus({ keyToken, initialize }) {
    try {
        loadingToast("Updating...");
        const response = yield call(API.updatePromotionStatus, keyToken);

        if (response.status === 200) {
            yield put(ACTIONS.updatePromotionStatusSuccess());
            initialize();
            removeToast();
            successToast("Successfully Updated.");
        }
    } catch (error) {
        yield put(ACTIONS.updatePromotionStatusError());
        if (error?.response?.data?.message) {
            errorToast(error.response.data.message);
        }
        initialize();
    }
}

function* updatePromotion({ keyToken, payload, requestType, initialize }) {
    try {
        loadingToast("Updating...");
        const response = yield call(API.updatePromotion, keyToken, payload, requestType);

        if (response.status === 200) {
            yield put(ACTIONS.updatePromotionSuccess());
            yield put(ACTIONS.setPromotionByNo(response.data.response));
            // initialize()

            removeToast();
            successToast("Successfully Updated.");
        }
    } catch (error) {
        yield put(ACTIONS.updatePromotionError());
        if (error?.response?.data?.message) {
            errorToast(error.response.data.message);
        }
    }
}

function* watchGetPromotionCategoryList() {
    yield takeLatest(
        TYPES.GET_PROMOTION_CATEGORY_LIST,
        getPromotionCategoryList
    );
    yield takeLatest(
        TYPES.UPDATE_PROMOTION_CATEGORY_STATUS_SUCCESS,
        getPromotionCategoryList
    );
}
function* watchUpdatePromotionCategoryStatus() {
    yield takeLatest(
        TYPES.UPDATE_PROMOTION_CATEGORY_STATUS,
        updatePromotionCategoryStatus
    );
}
function* watchGetPromotionList() {
    yield takeLatest(TYPES.GET_PROMOTION_LIST, getPromotionList);
}

function* watchGetPromotionTransactionList() {
    yield takeLatest(TYPES.GET_PROMOTION_TRANSACTION_LIST, getPromotionTransactionList);
}

function* watchGetPromotionTransactionView() {
    yield takeLatest(TYPES.GET_PROMOTION_TRANSACTION_VIEW, getPromotionTransactionView);
}



function* watchGetPromotionCategoryByKeyToken() {
    yield takeLatest(
        TYPES.GET_PROMOTION_CATEGORY_BY_KEY_TOKEN,
        getPromotionCategoryByKeyToken
    );
}
function* watchCreatePromotion() {
    yield takeLatest(TYPES.CREATE_PROMOTION, createPromotion);
}
function* watchGetPromotionByNo() {
    yield takeLatest(TYPES.GET_PROMOTION_BY_NO, getPromotionByNo);
}
function* watchUpdatePromotionStatus() {
    yield takeLatest(TYPES.UPDATE_PROMOTION_STATUS, updatePromotionStatus);
}
function* watchUpdatePromotion() {
    yield takeLatest(TYPES.UPDATE_PROMOTION, updatePromotion);
}

function* promotionSagas() {
    yield all([
        fork(watchGetPromotionCategoryList),
        fork(watchUpdatePromotionCategoryStatus),
        fork(watchGetPromotionList),
        fork(watchGetPromotionCategoryByKeyToken),
        fork(watchCreatePromotion),
        fork(watchGetPromotionByNo),
        fork(watchUpdatePromotionStatus),
        fork(watchGetPromotionTransactionList),
        fork(watchGetPromotionTransactionView),
        fork(watchUpdatePromotion),
        
    ]);
}

export default promotionSagas;
