import * as types from "./actionTypes";

export const getPromotionCategoryList = (payload) => ({
    type: types.GET_PROMOTION_CATEGORY_LIST,
    payload,
});

export const setPromotionCategoryList = (payload) => ({
    type: types.SET_PROMOTION_CATEGORY_LIST,
    payload,
});

export const setPromotionCategoryListError = () => ({
    type: types.SET_PROMOTION_CATEGORY_LIST_ERROR,
});

export const updatePromotionCategoryStatus = (keyToken) => ({
    type: types.UPDATE_PROMOTION_CATEGORY_STATUS,
    keyToken,
});

export const updatePromotionCategoryStatusSuccess = () => ({
    type: types.UPDATE_PROMOTION_CATEGORY_STATUS_SUCCESS,
});

export const updatePromotionCategoryStatusError = () => ({
    type: types.SET_PROMOTION_CATEGORY_LIST_ERROR,
});

export const getPromotionList = (payload) => ({
    type: types.GET_PROMOTION_LIST,
    payload,
});

export const setPromotionList = (payload) => ({
    type: types.SET_PROMOTION_LIST,
    payload,
});

export const setPromotionListError = () => ({
    type: types.SET_PROMOTION_LIST_ERROR,
});

export const getPromotionCategoryByKeyToken = (keyToken) => ({
    type: types.GET_PROMOTION_CATEGORY_BY_KEY_TOKEN,
    keyToken,
});

export const setPromotionCategoryByKeyToken = (payload) => ({
    type: types.SET_PROMOTION_CATEGORY_BY_KEY_TOKEN,
    payload,
});

export const setPromotionCategoryByKeyTokenError = () => ({
    type: types.SET_PROMOTION_CATEGORY_BY_KEY_TOKEN_ERROR,
});

export const createPromotion = (payload, initialize) => ({
    type: types.CREATE_PROMOTION,
    payload,
    initialize,
});

export const createPromotionSuccess = (payload) => ({
    type: types.CREATE_PROMOTION_SUCCESS,
    payload,
});

export const createPromotionError = (payload) => ({
    type: types.CREATE_PROMOTION_ERROR,
    payload,
});

export const getPromotionByNo = (promotionNo) => ({
    type: types.GET_PROMOTION_BY_NO,
    promotionNo,
});

export const setPromotionByNo = (payload) => ({
    type: types.SET_PROMOTION_BY_NO,
    payload,
});

export const setPromotionByNoError = () => ({
    type: types.SET_PROMOTION_BY_NO_ERROR,
});

export const updatePromotionStatus = (keyToken, initialize) => ({
    type: types.UPDATE_PROMOTION_STATUS,
    keyToken,
    initialize
});

export const updatePromotionStatusSuccess = () => ({
    type: types.UPDATE_PROMOTION_STATUS_SUCCESS,
});

export const updatePromotionStatusError = () => ({
    type: types.UPDATE_PROMOTION_STATUS_ERROR,
});


export const getPromotionTransactionList = (payload) => ({
    type: types.GET_PROMOTION_TRANSACTION_LIST,
    payload,
});

export const setPromotionTransactionList = (payload) => ({
    type: types.SET_PROMOTION_TRANSACTION_LIST,
    payload,
});

export const setPromotionTransactionListError = () => ({
    type: types.SET_PROMOTION_TRANSACTION_LIST_ERROR,
});

export const getPromotionTransactionView = (payload) => ({
    type: types.GET_PROMOTION_TRANSACTION_VIEW,
    payload,
});

export const setPromotionTransactionView = (payload) => ({
    type: types.SET_PROMOTION_TRANSACTION_VIEW,
    payload,
});

export const setPromotionTransactionViewError = () => ({
    type: types.SET_PROMOTION_TRANSACTION_VIEW_ERROR,
});

export const updatePromotion = (keyToken, payload, requestType, initialize) => ({
    type: types.UPDATE_PROMOTION,
    keyToken,
    payload,
    requestType,
    initialize
});

export const updatePromotionSuccess = (payload) => ({
    type: types.UPDATE_PROMOTION_SUCCESS,
    payload,
});

export const updatePromotionError = (payload) => ({
    type: types.UPDATE_PROMOTION_ERROR,
    payload,
});