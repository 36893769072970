import { InputReactSelect } from "@/components/atoms";
import { getRoleList, updateAdminRole } from "@/store/actions";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { FormGroup } from "reactstrap";

const RoleUpdateSelect = ({ profile }) => {
    const dispatch = useDispatch();

    const [roleOptions, setRoleOptions] = useState([]);
    const [selectedRole, setSelectedRole] = useState({});

    const saveAdminRole = (option) => {
        setSelectedRole(option);
        dispatch(
            updateAdminRole(
                {
                    role: option.value,
                },
                profile.userAdminRoleKey
            )
        );
    };

    useEffect(() => {
        dispatch(getRoleList({ per_page: 1000, page: 1 }, setRoleOptions));
        setSelectedRole({
            value: profile?.permissions?.key_token,
            label: profile?.permissions?.name,
        });
    }, [dispatch, setSelectedRole, profile]);

    return (
        <>
            <FormGroup className="mb-3">
                <InputReactSelect
                    label="Role"
                    name="admin_role"
                    onChange={(option) => saveAdminRole(option)}
                    value={profile ? selectedRole : ""}
                    options={roleOptions}
                />
            </FormGroup>
        </>
    );
};

export default RoleUpdateSelect;
