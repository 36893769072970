import { OperatorSettingsTab } from "@/components/molecules";
import React from "react";
import ProfileLayout from "../Layout";

const Settings = () => {
    return (
        <ProfileLayout>
            <OperatorSettingsTab />
        </ProfileLayout>
    );
};

export default Settings;
