import React from "react";
import { Label, Input, FormFeedback } from "reactstrap";

const InputFile = ({ label, labelMuted, validation, name, ...rest }) => {
    return (
        <>
            <Label>
                {label}
                {labelMuted && (
                    <small className="text-muted m-2">{labelMuted}</small>
                )}
            </Label>
            <Input
                type="file"
                className="form-control"
                name={name}
                invalid={
                    validation.touched[name] && validation.errors[name]
                        ? true
                        : false
                }
                onChange={(event) =>
                    validation.setFieldValue(name, event.target.files[0])
                }
                {...rest}
            />
            {validation.touched[name] && validation.errors[name] ? (
                <FormFeedback type="invalid">
                    {validation.errors[name]}
                </FormFeedback>
            ) : null}
        </>
    );
};

export default InputFile;
