import React from "react";
import { defaultCurrency, numberFormat } from "@/utils/common";
const AmountFormat = ({ amount, color = true, prefix = null, hasPrefix = true }) => {
    return (
        <span
            className={
                color
                    ? parseFloat(amount) > 0
                        ? "text-success"
                        : "text-danger"
                    : ""
            }>
            {(hasPrefix ? (prefix ? prefix : defaultCurrency()) : "") + numberFormat(amount)}
        </span>
    );
};

export default AmountFormat;
