import {
  Button,
  Select,
  InputReactSelect,
  FileInputPreview,
  DisplayText,
} from "@/components/atoms";
import { updateFeaturedGameValidation } from "@/constants";
import { useFormik } from "formik";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button as ButtonComponent,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { updateFeaturedGame, resetFeaturedGameError } from "@/store/actions";
import { cleanFromFile } from "@/utils/common"

const UpdateFeaturedBannerModal = ({
  isOpen,
  setIsOpen,
  featuredGameTypes,
  getCasinoGames,
  game,
  featuredGameType,
}) => {
  const isMounted = useRef(false);
  const dispatch = useDispatch();
  const { isUpdateFeaturedGameLoading, isUpdateFeaturedGameSuccess, errors } =
    useSelector((state) => state.game);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      order_by: game?.order_by,
      featured_banner: game?.featured_banner_path,
    },
    validationSchema: updateFeaturedGameValidation(),
    onSubmit: (values) => {
      const payload = cleanFromFile(values, ["featured_banner"]);
      dispatch(
        updateFeaturedGame({
          params: payload,
          uuid: game?.featured_key_token,
        })
      );
    },
  });

  useEffect(() => {
    if (isMounted.current) {
      if (isUpdateFeaturedGameSuccess) {
        getCasinoGames();
        setIsOpen(false);
      }
    }
    isMounted.current = true;
  }, [getCasinoGames, isUpdateFeaturedGameSuccess, setIsOpen]);

  return (
    <Modal
      id="updateFeaturedModal"
      isOpen={isOpen}
      toggle={() => setIsOpen(!isOpen)}
    >
      <ModalHeader>
        <div className="modal-title">Update Banner</div>
      </ModalHeader>
      <ModalBody>
        <Form
          className="needs-validation"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row>
            <Col md={4}>
              <DisplayText label="Game Name" item={game?.game_name} col="8" />
            </Col>
            <Col md={4}>
              <DisplayText
                label="Game Provider"
                item={game?.game_provider_name}
                col="8"
              />
            </Col>
            <Col md={4}>
              <DisplayText label="Game Type" item={game?.type} col="8" />
            </Col>
            <Col md={12}>
              <FormGroup>
                <Select
                  label="Order By"
                  name="order_by"
                  value={validation.values.order_by}
                  options={[...Array(10)].map((item, index) => ({
                    label: index + 1,
                    value: `${index + 1}`,
                  }))}
                  validation={validation}
                  validateOnBlur={false}
                  onChange={validation.handleChange}
                />
              </FormGroup>
            </Col>
            {featuredGameType === "featured" && (
              <Col md={12}>
                <FormGroup>
                  <Label htmlFor="featured_banner">Featured Banner</Label>
                  <FileInputPreview
                    isUpdate={true}
                    defaultImage={game?.featured_banner}
                    name="featured_banner"
                    validation={validation}
                    onupdatefiles={(file) =>
                      validation.setFieldValue(
                        "featured_banner",
                        file?.[0]?.file ?? null
                      )
                    }
                  />
                </FormGroup>
              </Col>
            )}
          </Row>
          <Row>
            <Col className="text-end">
              <ButtonComponent
                color="light"
                onClick={() => setIsOpen(!isOpen)}
                className="me-2"
              >
                Close
              </ButtonComponent>
              <Button
                color="success"
                type="submit"
                text="Submit"
                isLoading={isUpdateFeaturedGameLoading}
              />
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default UpdateFeaturedBannerModal;
