import { StatusBadge, Table, Title } from "@/components/atoms";
import BreadCrumb from "@/components/common/BreadCrumb";
import { getPath, getPathTitle, Routes } from "@/constants";
import { getAdminList } from "@/store/actions";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row,
} from "reactstrap";
const ManageUserList = () => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.user);
    const { list, isLoading } = useSelector((state) => state.admin);

    const [search, setSearch] = useState("");
    const [sortBy, setSortBy] = useState({
        field: "",
        type: "asc",
    });

    const [data, setData] = useState({
        key_token: user.keyToken,
        user_type: "admin",
        per_page: 10,
        page: 1,
        search: "",
        sort_by:
            sortBy.field && sortBy.type ? `${sortBy.field} ${sortBy.type}` : "",
    });

    const tableTheadData = [
        {
            label: "Account No.",
            field: "account_no",
            sortable: true,
            render: (item) => item?.user_profile?.account_no,
        },
        {
            label: "Role",
            field: "admin_roles.name",
            sortable: true,
            render: (item) => item?.admin_role_name,
        },
        {
            label: "First Name",
            field: "first_name",
            sortable: true,
            render: (item) => item?.user_profile?.first_name,
        },
        {
            label: "Last Name",
            field: "last_name",
            sortable: true,
            render: (item) => item?.user_profile?.last_name,
        },
        {
            label: "Email",
            field: "email",
            sortable: true,
        },
        {
            label: "Contact No",
            field: "mobile_no",
            sortable: true,
        },
        {
            label: "Status",
            field: "status",
            sortable: true,
            render: (item) => <StatusBadge status={item.status} />,
        },
        {
            label: "Action",
            field: "action",
            sortable: false,
            render: (item) => (
                <Link
                    to={getPath(Routes.MANAGE_USER_PROFILE).replace(
                        ":accountNo",
                        item?.user_profile?.account_no
                    )}
                    className="link-primary"
                >
                    <Button color="success" size="sm">
                        <i className="ri-eye-line"></i>
                    </Button>
                </Link>
            ),
        },
    ];

    useEffect(() => {
        dispatch(getAdminList(data));
    }, [dispatch, data]);

    return (
        <React.Fragment>
            <Title title={getPathTitle(Routes.MANAGE_USER)} />
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb
                        parent={{
                            isParent: true,
                            pageTitle: getPathTitle(Routes.MANAGE_USER),
                            pageLink: getPath(Routes.MANAGE_USER),
                        }}
                    />
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardHeader className="align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">
                                        {getPathTitle(Routes.MANAGE_USER)} List
                                    </h4>
                                    <div className="flex-shrink-0">
                                        <Link
                                            to={getPath(
                                                Routes.MANAGE_USER_CREATE
                                            )}
                                        >
                                            <Button color="success" size="sm">
                                                <i className="ri-add-fill me-1 align-bottom"></i>
                                                {getPathTitle(
                                                    Routes.MANAGE_USER_CREATE
                                                )}
                                            </Button>
                                        </Link>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Table
                                        search={search}
                                        handlerSearch={setSearch}
                                        sortBy={sortBy}
                                        handlerSortBy={setSortBy}
                                        thead={tableTheadData}
                                        handlerChangeData={setData}
                                        data={data}
                                        collection={list}
                                        isLoading={isLoading}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ManageUserList;
