import React from "react";
import { useSelector } from "react-redux";
import TextLink from "../TextLink";

const UserLink = (props) => {
    const { text, path, forRole } = props;
    const { user } = useSelector((state) => state.user);

    const hasAccess =
        user?.role.slug === "super-admin" ||
        user?.role.slug === "admin" ||
        (forRole === "master-agent" && user?.role.slug === "operator") ||
        (forRole === "agent" &&
            ["master-agent", "operator"].includes(user?.role.slug));

    return hasAccess ? (
        <TextLink text={text} path={path} hasIcon={false} />
    ) : (
        text
    );
};

export default UserLink;
