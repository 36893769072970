import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";

const BreadCrumb = ({ parent, childs }) => {
    return (
        <React.Fragment>
            <Row>
                <Col xs={12}>
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h4 className="mb-sm-0">{parent.pageTitle}</h4>

                        <div className="page-title-right">
                            <ol className="breadcrumb m-0">
                                <li className="breadcrumb-item">
                                    {parent.pageLink && (
                                        <Link
                                            to={
                                                parent.pageLink
                                                    ? parent.pageLink
                                                    : "#"
                                            }>
                                            {parent.pageTitle}
                                        </Link>
                                    )}
                                </li>
                                {childs?.map((item, i) => (
                                    <li
                                        key={i}
                                        className={
                                            item.isActive
                                                ? "breadcrumb-item active"
                                                : "breadcrumb-item"
                                        }>
                                        {item.isActive && <>{item.pageTitle}</>}
                                        {!item.isActive && (
                                            <Link to={item.pageLink}>
                                                {item.pageTitle}
                                            </Link>
                                        )}
                                    </li>
                                ))}
                            </ol>
                        </div>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default BreadCrumb;
