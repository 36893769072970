import { Table, Title } from "@/components/atoms";
import BreadCrumb from "@/components/common/BreadCrumb";
import { getPath, getPathTitle, Routes } from "@/constants";
import { getRoleList } from "@/store/actions";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row,
} from "reactstrap";
const RolesList = () => {
    const dispatch = useDispatch();
    const { roleList, isLoading } = useSelector((state) => state.admin);

    const [search, setSearch] = useState("");
    const [sortBy, setSortBy] = useState({
        field: "",
        type: "asc",
    });

    const [data, setData] = useState({
        per_page: 10,
        page: 1,
        search: "",
        sort_by:
            sortBy.field && sortBy.type ? `${sortBy.field} ${sortBy.type}` : "",
    });

    const tableTheadData = [
        {
            label: "Role Name",
            field: "name",
            sortable: true,
            render: (item) => item?.name,
        },
        {
            label: "Action",
            field: "action",
            sortable: false,
            render: (item) => (
                <Link
                    to={getPath(Routes.MANAGE_ROLE_UPDATE).replace(
                        ":keyToken",
                        item?.key_token
                    )}
                    className="link-primary"
                >
                    <Button color="success" size="sm">
                        <i className="ri-eye-line"></i>
                    </Button>
                </Link>
            ),
        },
    ];

    useEffect(() => {
        dispatch(getRoleList(data));
    }, [dispatch, data]);

    return (
        <React.Fragment>
            <Title title={getPathTitle(Routes.MANAGE_ROLE)} />
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb
                        parent={{
                            isParent: true,
                            pageTitle: getPathTitle(Routes.MANAGE_ROLE),
                            pageLink: getPath(Routes.MANAGE_ROLE),
                        }}
                    />
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardHeader className="align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">
                                        {getPathTitle(Routes.MANAGE_ROLE)} List
                                    </h4>
                                    <div className="flex-shrink-0">
                                        <Link
                                            to={getPath(
                                                Routes.MANAGE_ROLE_CREATE
                                            )}
                                        >
                                            <Button color="success" size="sm">
                                                <i className="ri-add-fill me-1 align-bottom"></i>
                                                {getPathTitle(
                                                    Routes.MANAGE_ROLE_CREATE
                                                )}
                                            </Button>
                                        </Link>
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <Table
                                        search={search}
                                        handlerSearch={setSearch}
                                        sortBy={sortBy}
                                        handlerSortBy={setSortBy}
                                        thead={tableTheadData}
                                        handlerChangeData={setData}
                                        data={data}
                                        collection={roleList}
                                        isLoading={isLoading}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default RolesList;
