import { axiosInstance } from "./index";
import { toFormData } from "../../utils/common";

export const getSportTypeList = async (params) => {
    return await axiosInstance.get(`bo/sport-type/get-paginated`, { params });
};

export const updateSportTypeStatus = async (keyToken) => {
    return await axiosInstance.put(`bo/sport-type/update-status/${keyToken}`);
};

export const updateSportTypeUseChildFilter = async (keyToken, params) => {
    return await axiosInstance.put(
        `bo/sport-type/use-child-filter/${keyToken}`,
        params
    );
};

export const getLeagueTypeList = async (params) => {
    return await axiosInstance.get(`bo/league-type/get-paginated`, { params });
};

export const updateLeagueTypeStatus = async (keyToken) => {
    return await axiosInstance.put(`bo/league-type/update-status/${keyToken}`);
};

export const getGameTypeList = async (params) => {
    return await axiosInstance.get(`bo/game-type/get-paginated`, { params });
};

export const updateGameTypeStatus = async (keyToken) => {
    return await axiosInstance.put(`bo/game-type/update-status/${keyToken}`);
};

export const getTeamList = async (params) => {
    return await axiosInstance.get(`bo/team/get-paginated`, { params });
};

export const uploadTeamPhoto = async (keyToken, params) => {
    const formData = toFormData(params);

    return await axiosInstance({
        method: "post",
        url: `bo/team/upload-photo/${keyToken}`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
};

export const getHotMatchList = async (params) => {
    return await axiosInstance.get(`bo/hot-event/get-paginated`, { params });
};

export const updateHotMatchPostStatus = async (keyToken) => {
    return await axiosInstance.put(
        `bo/hot-event/update-post-status/${keyToken}`
    );
};

export const hotMatchManualPull = async () => {
    return await axiosInstance.post(`/bo/hot-event/manual-pull`);
};
