import {
    errorToast,
    loadingToast,
    removeToast,
    successToast,
} from "@/components/constants/toast";
import * as operatorApi from "@/network/apis/operator";
import { applyDownlinesCommissionTransferSchedule } from "@/network/apis/user";
import { convert2dErrorsArray } from "@/utils";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import * as TYPES from "./actionTypes";
import * as ACTIONS from "./actions";

const API = {
    ...operatorApi,
    applyDownlinesCommissionTransferSchedule,
};

function* operatorList({ type, payload }) {
    // yield delay(500);
    try {
        const response = yield call(API.operatorList, payload);

        if (response.status === 200) {
            switch (type) {
                case "GET_OPERATOR_SELECTION_LIST":
                    yield put(
                        ACTIONS.onGetOperatorSelectionListSuccess(response.data)
                    );
                    break;
                default:
                    yield put(ACTIONS.setOperatorList(response.data));
                    break;
            }
        }
    } catch (error) {
        console.warn(error.response);
        switch (type) {
            case "GET_OPERATOR_SELECTION_LIST":
                yield put(ACTIONS.onGetOperatorSelectionListFailed());
                break;
            default:
                yield put(ACTIONS.setOperatorListError());
                break;
        }
    }
}

function* operatorProfile({ payload }) {
    try {
        const response = yield call(API.operatorProfile, payload);

        if (response.status === 200) {
            yield put(ACTIONS.setOperatorProfile(response.data.response));
        }
    } catch (error) {
        console.warn(error.response);
        yield put(ACTIONS.setOperatorProfileError());
    }
}

function* createOperator({ payload, validation }) {
    try {
        loadingToast("Processing...");
        const response = yield call(API.createOperator, payload);

        if (response.status === 200) {
            yield put(ACTIONS.createOperatorSuccess(response.data));
            validation.resetForm();
            removeToast();
            successToast("Successfully Added.");
        }
    } catch (error) {
        removeToast();
        document.documentElement.scrollTop = 0;
        let errors = !Array.isArray(error?.response?.data?.response)
            ? convert2dErrorsArray(error?.response?.data?.response)
            : [error.response.data.message];
        yield put(ACTIONS.createOperatorError(errors));
    }
}

function* updateOperator({ payload, keyToken }) {
    try {
        const response = yield call(API.updateOperator, payload, keyToken);

        if (response.status === 200) {
            yield put(ACTIONS.updateOperatorSuccess(response.data.response));
            successToast("Successfully Updated.");
        }
    } catch (error) {
        document.documentElement.scrollTop = 0;
        let errors = !Array.isArray(error?.response?.data?.response)
            ? convert2dErrorsArray(error?.response?.data?.response)
            : [error.response.data.message];
        yield put(ACTIONS.updateOperatorError(errors));
    }
}

function* updateMerchantSetting({ payload, keyToken }) {
    try {
        const response = yield call(
            API.updateMerchantSetting,
            payload,
            keyToken
        );

        if (response.status === 200) {
            yield put(ACTIONS.onUpdateMerchantSettingSuccess());
            successToast("Successfully Updated.");
        }
    } catch (error) {
        yield put(ACTIONS.onUpdateMerchantSettingFailed());
    }
}

function* updateCrossBettingProtection({ payload, keyToken }) {
    try {
        const response = yield call(
            API.updateCrossBettingProtection,
            payload,
            keyToken
        );

        if (response.status === 200) {
            yield put(ACTIONS.onUpdateCrossBettingProtectionSuccess());
            successToast("Successfully Updated.");
        }
    } catch (error) {
        yield put(ACTIONS.onUpdateCrossBettingProtectionFailed());
    }
}

function* updateOperatorRoleTag({ payload, keyToken }) {
    try {
        loadingToast("Saving...");
        const response = yield call(
            API.updateOperatorRoleTag,
            payload,
            keyToken
        );

        if (response.status === 200) {
            yield put(
                ACTIONS.updateOperatorRoleTagSuccess(response.data.response)
            );
            removeToast();
            successToast("Successfully Updated.");
        }
    } catch (error) {
        yield put(ACTIONS.updateOperatorRoleTagError([]));
        removeToast();
        errorToast(error.response.data.message);
    }
}

function* applyDownlinesCommissionTransferScheduleOperator({
    payload,
    keyToken,
    initialize,
}) {
    try {
        loadingToast("Saving...");
        const response = yield call(
            API.applyDownlinesCommissionTransferSchedule,
            payload,
            keyToken
        );

        if (response.status === 200) {
            yield put(
                ACTIONS.applyDownlinesCommissionTransferScheduleOperatorSuccess()
            );
            removeToast();
            successToast("Successfully Updated.");
            initialize();
        }
    } catch (error) {
        initialize();
        yield put(
            ACTIONS.applyDownlinesCommissionTransferScheduleOperatorError([])
        );
        removeToast();
        errorToast(error.response.data.message);
    }
}

function* watchCreateOperator() {
    yield takeLatest(TYPES.CREATE_OPERATOR, createOperator);
}
function* watchUpdateOperator() {
    yield takeLatest(TYPES.UPDATE_OPERATOR, updateOperator);
}
function* watchOperatorList() {
    yield takeLatest(TYPES.GET_OPERATOR_LIST, operatorList);
    yield takeLatest(TYPES.GET_OPERATOR_SELECTION_LIST, operatorList);
}
function* watchOperatorProfile() {
    yield takeLatest(TYPES.GET_OPERATOR_PROFILE, operatorProfile);
}

function* watchUpdateMerchantSetting() {
    yield takeLatest(TYPES.UPDATE_MERCHANT_SETTING, updateMerchantSetting);
}

function* watchUpdateCrossBettingProtection() {
    yield takeLatest(
        TYPES.UPDATE_CROSS_BETTING_PROTECTION,
        updateCrossBettingProtection
    );
}

function* watchUpdateOperatorRoleTag() {
    yield takeLatest(TYPES.UPDATE_OPERATOR_ROLE_TAG, updateOperatorRoleTag);
}

function* watchApplyDownlinesCommissionTransferScheduleOperator() {
    yield takeLatest(
        TYPES.APPLY_DOWNLINES_COMMISSIONS_TRANSFER_SCHEDULE_OPERATOR,
        applyDownlinesCommissionTransferScheduleOperator
    );
}

function* OperatorSagas() {
    yield all([
        fork(watchCreateOperator),
        fork(watchUpdateOperator),
        fork(watchOperatorList),
        fork(watchOperatorProfile),
        fork(watchUpdateMerchantSetting),
        fork(watchUpdateCrossBettingProtection),
        fork(watchUpdateOperatorRoleTag),
        fork(watchApplyDownlinesCommissionTransferScheduleOperator),
    ]);
}

export default OperatorSagas;
