import {
    AmountFormat,
    AsyncReactSelect,
    CounterWidget,
    DataCollapse,
    FlatPickerDate,
    InputReactSelect,
    Select,
    Table,
    TextLink,
    Title,
} from "@/components/atoms";
import BreadCrumb from "@/components/common/BreadCrumb";
import { Routes, betStatus, getPath, getPathTitle } from "@/constants";
import {
    balanceCategoryOptions,
    dateFilterOptions,
    totalCounter,
} from "@/constants/users";
import {
    clearPlayerSelectionList,
    getOperatorSelectionList,
    getPlayerSelectionList,
    getTransactionData,
} from "@/store/actions";
import {
    arrayToString,
    defaultDateTimeFormat,
    filterDateFormat,
    getStatusColor,
    getUserProfileLink,
    getDateDiffs,
} from "@/utils";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row,
} from "reactstrap";

const SportsBettingTransactions = () => {
    const dateFilterRef = useRef();
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.user);
    const { list, isLoading } = useSelector((state) => state.transaction);
    const { playerSelectionList } = useSelector((state) => state.player);
    const { operatorSelectionList } = useSelector((state) => state.operator);

    const isAdmin =
        user?.role?.slug === "super-admin" || user?.role?.slug === "admin";

    const [search, setSearch] = useState("");
    const [sortBy, setSortBy] = useState({
        field: "",
        type: "asc",
    });

    const [collapse, setCollapse] = useState([]);

    const [operatorExtraData, setOperatorExtraData] = useState({
        key_token: null,
        user_type: "operator",
        role_tag: "all",
    });

    const [playerExtraData, setPlayerExtraData] = useState({
        key_token: null,
        user_type: "player",
    });

    const defaultParams = {
        account_no: "",
        per_page: 10,
        page: 1,
        search: "",
        sort_by:
            sortBy.field && sortBy.type ? `${sortBy.field} ${sortBy.type}` : "",
        "date_range[transactions.created_at][from]":
            dateFilterOptions[0].value[0],
        "date_range[transactions.created_at][to]":
            dateFilterOptions[0].value[1],
    };

    const [data, setData] = useState(defaultParams);

    const reportTotalCounter = totalCounter(list);

    const [selectedDate, setSelectedDate] = useState(dateFilterOptions[0]);

    const handleClear = () => {
        setData(defaultParams);
        setSelectedDate(dateFilterOptions[0]);
        form.resetForm();
        form.submitForm();
    };

    const tableTheadData = useMemo(() => {
        const handleCollapse = (id) => {
            if (collapse.includes(id)) {
                const selected = collapse.filter((value) => value !== id);
                setCollapse([...selected]);
            } else {
                setCollapse((collapse) => [...collapse, id]);
            }
        };

        return [
            {
                label: "Transaction No.",
                field: "bet_transaction_no",
                sortable: true,
                render: (item) => (
                    <TextLink
                        text={item.bet_transaction_no}
                        path={getPath(Routes.BET_TRANSACTION_VIEW).replace(
                            ":transactionNo",
                            item?.bet_transaction_no
                        )}
                    />
                ),
            },
            {
                label: "Account No.",
                field: "account_no",
                sortable: true,
                render: (item) => (
                    <TextLink
                        text={`${item.account_no} | ${item.name}`}
                        path={getUserProfileLink(item.account_no, "player")}
                    />
                ),
            },
            {
                label: "Status",
                field: "status",
                sortable: false,
                render: (item) => {
                    const status = item.settlement_datetime
                        ? item.status
                        : "unsettled";
                    return (
                        <Badge color={getStatusColor(status)}>{status}</Badge>
                    );
                },
            },
            {
                label: "Bet Amount",
                field: "bet_amount",
                sortable: false,
                render: (item) => <AmountFormat amount={item?.bet_amount} />,
            },
            {
                label: "Win Amount",
                field: "win_amount",
                sortable: false,
                render: (item) => <AmountFormat amount={item?.win_amount} />,
            },
            {
                label: "Preserve Amount",
                field: "preserve_amount",
                sortable: false,
                render: (item) => (
                    <AmountFormat amount={item?.preserve_amount} />
                ),
            },
            {
                label: "Final Balance",
                field: "win_balance_after",
                sortable: false,
                render: (item) =>
                    item?.status === "pending" ? (
                        "-"
                    ) : (
                        <AmountFormat amount={item?.win_balance_after} />
                    ),
            },
            {
                label: "Balance Category",
                field: "balance_category",
            },
            {
                label: "Events",
                field: "events",
                sortable: false,
                render: (item) => (
                    <>
                        {item?.events ? (
                            <DataCollapse
                                collapseKey={item.bet_transaction_no}
                                data={item.events}
                                fields={[
                                    { field: "sports_name" },
                                    {
                                        field: "match_date",
                                        render: (value) =>
                                            defaultDateTimeFormat(value),
                                    },
                                    { field: "match_name" },
                                    {
                                        field: "selected_team",
                                        render: (value) => (
                                            <div className="text-primary">
                                                {value}
                                            </div>
                                        ),
                                    },
                                    {
                                        field: "result",
                                        render: (value) => value ?? "pending",
                                    },
                                    { field: "odds" },
                                ]}
                                collapse={collapse}
                                handleCollapse={handleCollapse}
                            />
                        ) : (
                            "-"
                        )}
                    </>
                ),
            },
            {
                label: "Bet Datetime",
                field: "bet_datetime",
                sortable: true,
                render: (item) => defaultDateTimeFormat(item?.bet_datetime),
            },
            {
                label: "Settlement Datetime",
                field: "settlement_datetime",
                sortable: true,
                render: (item) =>
                    defaultDateTimeFormat(item?.settlement_datetime),
            },
        ];
    }, [collapse]);

    // Form
    const form = useFormik({
        enableReinitialize: true,
        initialValues: {
            gameFilter: "",
            playerFilter: "",
            operatorFilter: "",
            dateFilter: dateFilterOptions[0].value,
            status: "",
            balanceCategory: "",
        },
        onSubmit: (values) => {
            setData({
                ...data,
                "filter_by[user_profiles.account_no]": values.playerFilter
                    ? arrayToString(
                          values.playerFilter.map((item) => {
                              return item.value;
                          })
                      )
                    : null,
                "date_range[transactions.created_at][from]": values.dateFilter
                    ? values.dateFilter[0]
                    : data["date_range[transactions.created_at][from]"],
                "date_range[transactions.created_at][to]": values.dateFilter
                    ? values.dateFilter[1]
                    : data["date_range[transactions.created_at][to]"],
                // "filter_by[balance_categories.id]":
                //     values.balanceCategory,
                balance_category:
                values.balanceCategory,
                operator_id: values?.operatorFilter?.label
                    ? values?.operatorFilter?.label.split("|")[0]
                    : null,
                status: values.status
                    ? arrayToString(
                          values.status.map((item) => {
                              return item.value;
                          })
                      )
                    : null,
                page: 1,
            });
        },
    });

    const onHandleChangeOperator = (options) => {
        dispatch(clearPlayerSelectionList());
        form.setFieldValue("operatorFilter", options);
        form.setFieldValue("playerFilter", null);
        setPlayerExtraData((data) => ({
            ...data,
            key_token: options?.value ?? user.keyToken,
        }));
    };

    useEffect(() => {
        if (user) {
            setOperatorExtraData((data) => ({
                ...data,
                key_token: user.keyToken,
            }));
            setPlayerExtraData((data) => ({
                ...data,
                key_token: user.keyToken,
            }));
        }
    }, [user]);

    useEffect(() => {
        dispatch(getTransactionData(data, "bet", "sportsbetting"));
    }, [dispatch, data]);

    return (
        <React.Fragment>
            <Title title={getPathTitle(Routes.SPORTS_BETTING_TRANSACTION)} />
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb
                        parent={{
                            isParent: true,
                            pageTitle: getPathTitle(Routes.BET_TRANSACTION),
                            pageLink: getPath(Routes.BET_TRANSACTION),
                        }}
                        childs={[
                            {
                                pageTitle: getPathTitle(
                                    Routes.SPORTS_BETTING_TRANSACTION
                                ),
                                pageLink: getPath(
                                    Routes.SPORTS_BETTING_TRANSACTION
                                ),
                                isActive: true,
                            },
                        ]}
                    />
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardHeader className="align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">
                                        {getPathTitle(
                                            Routes.SPORTS_BETTING_TRANSACTION
                                        )}
                                    </h4>
                                </CardHeader>
                                <CardBody>
                                    <Row>
                                        {isAdmin && (
                                            <Col md={3}>
                                                <AsyncReactSelect
                                                    label="Operators"
                                                    name="operatorFilter"
                                                    isMulti={false}
                                                    fetchData={
                                                        getOperatorSelectionList
                                                    }
                                                    data={operatorSelectionList}
                                                    form={form}
                                                    onChange={
                                                        onHandleChangeOperator
                                                    }
                                                    value={
                                                        form.values
                                                            .operatorFilter
                                                    }
                                                    dataField={{
                                                        label: "account_no|name",
                                                        value: "key_token",
                                                    }}
                                                    extraData={
                                                        operatorExtraData
                                                    }
                                                    sortBy="name asc"
                                                    isClearable
                                                />
                                            </Col>
                                        )}
                                        <Col md={isAdmin ? 3 : 4}>
                                            <AsyncReactSelect
                                                label="Players"
                                                name="playerFilter"
                                                isMulti={true}
                                                fetchData={
                                                    getPlayerSelectionList
                                                }
                                                data={playerSelectionList}
                                                form={form}
                                                onChange={(options) => {
                                                    form.setFieldValue(
                                                        "playerFilter",
                                                        options
                                                    );
                                                }}
                                                value={form.values.playerFilter}
                                                dataField={{
                                                    label: "account_no|name",
                                                    value: "account_no",
                                                }}
                                                extraData={playerExtraData}
                                                sortBy="name asc"
                                            />
                                        </Col>
                                        <Col
                                            md={isAdmin ? 3 : 4}
                                            className="tw-mb-3"
                                        >
                                            <InputReactSelect
                                                label="Status"
                                                isClearable={true}
                                                options={betStatus}
                                                value={form.values.status}
                                                onChange={(option) => {
                                                    form.setFieldValue(
                                                        "status",
                                                        option
                                                    );
                                                }}
                                                isMulti={true}
                                            />
                                        </Col>{" "}
                                        <Col md={isAdmin ? 3 : 4}>
                                            <Select
                                                name="balanceCategory"
                                                label="Balance Category"
                                                isClearable={false}
                                                value={
                                                    form.values.balanceCategory
                                                }
                                                options={balanceCategoryOptions()}
                                                onChange={(e) => {
                                                    form.setFieldValue(
                                                        "balanceCategory",
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mb-3">
                                        <Col md={12} className="pt-2">
                                            <div className="w-100 tw-flex tw-gap-1 tw-flex-col lg:tw-flex-row">
                                                {dateFilterOptions.map(
                                                    (item, index) => (
                                                        <Button
                                                            key={index}
                                                            style={{
                                                                display: (item.label === "This Month" || item.label === "Last Month") ? "none" : "block"
                                                            }}
                                                            color={
                                                                selectedDate.label ===
                                                                item.label
                                                                    ? "primary"
                                                                    : "light"
                                                            }
                                                            onClick={() => {
                                                                setSelectedDate(
                                                                    item
                                                                );
                                                                if (
                                                                    item.value
                                                                ) {
                                                                    form.setFieldValue(
                                                                        "dateFilter",
                                                                        item.value
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            {item.label}
                                                        </Button>
                                                    )
                                                )}
                                                <div className="tw-w-full lg:tw-w-1/5">
                                                    <FlatPickerDate
                                                        ref={dateFilterRef}
                                                        name="dateFilter"
                                                        mode="range"
                                                        placeholder="From Date - To Date"
                                                        maxDate={null}
                                                        value={
                                                            selectedDate.value
                                                        }
                                                        onChange={(date) => {
                                                            if (
                                                                date.length >= 2
                                                            ) {
                                                                if(getDateDiffs(date[0], date[1]) > 7) {
                                                                    dateFilterRef.current.flatpickr.clear();
                                                                    form.setFieldValue("dateFilter", dateFilterOptions[0].value);
                                                                    toast.error("Error: Maximum date range exceeded. Please select a date range of up to 7 days.");
                                                                    return;
                                                                }
                                                                form.setFieldValue(
                                                                    "dateFilter",
                                                                    date.map(
                                                                        (
                                                                            value
                                                                        ) =>
                                                                            filterDateFormat(
                                                                                value
                                                                            )
                                                                    )
                                                                );
                                                            }
                                                            // form.setFieldValue(
                                                            //     "dateFilter",
                                                            //     [filterDateFormat(
                                                            //         date
                                                            //     ),
                                                            //     filterDateFormat(
                                                            //         date
                                                            //     )]
                                                            // );
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12} className="text-center">
                                            <Button
                                                color="primary"
                                                className="mx-1"
                                                onClick={() => handleClear()}
                                            >
                                                Clear
                                            </Button>
                                            <Button
                                                color="success"
                                                className="mx-1"
                                                onClick={() =>
                                                    form.handleSubmit()
                                                }
                                            >
                                                Filter
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        {reportTotalCounter.map((item, key) => (
                            <Col sm={12} md={"2-half"} key={key}>
                                <CounterWidget item={item} icon="₱" />
                            </Col>
                        ))}
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardBody>
                                    <Table
                                        search={search}
                                        handlerSearch={setSearch}
                                        sortBy={sortBy}
                                        handlerSortBy={setSortBy}
                                        thead={tableTheadData}
                                        handlerChangeData={setData}
                                        data={data}
                                        collection={list}
                                        isLoading={isLoading}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default SportsBettingTransactions;
