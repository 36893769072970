import * as API from "@/network/apis/country";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import {
    errorToast,
    loadingToast,
    removeToast,
    successToast,
    toastIcon,
} from "@/components/constants/toast";
import * as ACTIONS from "./actions";
import * as TYPES from "./actionTypes";

function* getSystemCountriesAsync(action) {
    try {
        const response = yield call(API.getSystemCountries, action.payload);

        if (response.status === 200) {
            yield put(
                ACTIONS.onGetSystemCountriesSuccess(response.data.response)
            );
        }
    } catch (error) {
        yield put(ACTIONS.onGetSystemCountriesFailed());
    }
}

function* updateSystemCountryStatusAsync(action) {
    try {
        loadingToast("Saving...");
        const response = yield call(
            API.updateSystemCountryStatus,
            action.payload
        );

        if (response.status === 200) {
            yield put(ACTIONS.onUpdateSystemCountryStatusSuccess());
            removeToast();
            successToast("Successfully Updated.");
        }
    } catch (error) {
        yield put(ACTIONS.onUpdateSystemCountryStatusFailed());
        removeToast();
        errorToast(error.response.data.message);
    }
}

function* watchGetSystemCountriesAsync() {
    yield takeLatest(TYPES.GET_SYSTEM_COUNTRIES, getSystemCountriesAsync);
}
function* watchUpdateSystemCountryStatus() {
    yield takeLatest(
        TYPES.UPDATE_SYSTEM_COUNTRY,
        updateSystemCountryStatusAsync
    );
}
function* countrySagas() {
    yield all([
        fork(watchGetSystemCountriesAsync),
        fork(watchUpdateSystemCountryStatus),
    ]);
}

export default countrySagas;
