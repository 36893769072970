import { confirmation } from "@/components/constants/common";
import { userStatus } from "@/constants";
import { userUpdateStatus } from "@/store/actions";
import React from "react";
import { useDispatch } from "react-redux";
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledButtonDropdown,
} from "reactstrap";

const StatusUpdateDropdown = ({ profile, isLoading, disabled = false }) => {
    const dispatch = useDispatch();

    const userStatusOptions = [
        {
            label: "Active",
            status: "active",
            type: "activate",
            isShow: profile?.status === "unverified" ? false : true,
        },
        {
            label: "Deactivated",
            status: "deactivated",
            type: "deactivate",
            isShow: true,
        },
        {
            label: "Banned",
            status: "banned",
            type: "banned",
            isShow: true,
        },
    ];

    let statusData = userStatus.filter(
        (item) => item.value === profile?.status
    )?.[0];

    const updateStatus = (status) => {
        dispatch(userUpdateStatus(status, profile.keyToken));
    };

    const showConfirmation = (status, label) => {
        confirmation({
            title: "Confirmation Message",
            text: `Are you sure you want to ${label} this user?`,
            icon: "warning",
            confirmButtonText: "Confirm",
            action: () => dispatch(userUpdateStatus(status, profile.keyToken)),
        });
    };

    return (
        <>
            <UncontrolledButtonDropdown
                // direction="start"
                size="sm"
                className="me-2"
            >
                <DropdownToggle
                    caret
                    color={statusData?.color}
                    disabled={isLoading || disabled}
                >
                    {statusData?.label}
                </DropdownToggle>
                <DropdownMenu>
                    {userStatusOptions.map((item, key) => (
                        <DropdownItem
                            onClick={() =>
                                showConfirmation(
                                    {
                                        type: item.type,
                                    },
                                    item.label
                                )
                            }
                            key={key}
                            className={
                                profile.status === item.status || !item.isShow
                                    ? "d-none"
                                    : "d-block"
                            }
                        >
                            {item.label}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </UncontrolledButtonDropdown>
        </>
    );
};

export default StatusUpdateDropdown;
