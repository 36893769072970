import { DisplayText, StatusBadge } from "@/components/atoms";
import { AffiliateRequestApproveModal } from "@/components/molecules";
import { getPath, Routes } from "@/constants";
import { updateAffiliateRequestStatus } from "@/store/actions";
import { defaultDateFormat, defaultDateTimeFormat } from "@/utils";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
    Button,
    Card,
    CardBody,
    Col,
    Modal,
    ModalBody,
    ModalHeader,
    Row,
} from "reactstrap";
import Swal from "sweetalert2";

const AffiliateRequestViewModal = ({
    isOpen,
    setIsOpen,
    details,
    setDetails,
    initialize,
}) => {
    const dispatch = useDispatch();
    const [isOpenForm, setIsOpenForm] = useState(false);

    const { isLoading, errors } = useSelector((state) => state.agent);

    const confirmRejectHandler = () => {
        Swal.fire({
            input: "textarea",
            title: "Are you sure you want to reject?",
            inputPlaceholder: "Reject reason...",
            showCancelButton: true,
            confirmButtonText: "Confirm",
            reverseButtons: true,
            preConfirm: (value) => {
                if (!value) {
                    Swal.showValidationMessage(
                        '<i class="fa fa-info-circle"></i> Reject reason is required'
                    );
                }
            },
        }).then((result) => {
            if (result.isConfirmed) {
                let payload = {
                    reject_reason: result.value,
                    status: "reject",
                };
                dispatch(
                    updateAffiliateRequestStatus({
                        data: payload,
                        keyToken: details?.key_token,
                        setDetails,
                        initialize,
                    })
                );
            }
        });
    };

    const personalDetails = [
        {
            label: "First name",
            value: details?.first_name,
        },
        {
            label: "Middle name",
            value: details?.middle_name,
        },
        {
            label: "Last name",
            value: details?.last_name,
        },
        {
            label: "Email Address",
            value: details?.email,
        },
        {
            label: "Mobile Number",
            value: details?.mobile_no,
        },
        {
            label: "Birthdate",
            value: defaultDateFormat(details?.birth_date),
        },
        {
            label: "Gender",
            value: details?.gender,
        },
        {
            label: "FB profile link",
            value: details?.fb_profile_link ? (
                <a
                    target="_blank"
                    rel="noreferrer"
                    href={details?.fb_profile_link}
                    className="link-primary"
                >
                    {details?.fb_profile_link}
                </a>
            ) : (
                "N/A"
            ),
        },
        {
            label: "Do you have a network of players already, or are you starting new?",
            value: details?.network_of_players,
        },
        {
            label: "Have you registered on Metabets as a player? If so, please provide your PL number.",
            value: details?.existing_pl_number ? (
                <div className="tw-mb-3">
                    <Link
                        to={getPath(Routes.PLAYER_PROFILE).replace(
                            ":accountNo",
                            details?.existing_pl_number
                        )}
                        target="_blank"
                        className="link-primary"
                    >
                        {details?.existing_pl_number}
                    </Link>
                </div>
            ) : (
                "N/A"
            ),
        },
        {
            label: "How did you find out about the Metabets affiliate/agent program?",
            value: details?.findout_from,
        },
        {
            label: "Are you a streamer/influencer? If so, please provide your page link",
            value: (
                <div>
                    <div>
                        {details?.is_streamer_or_influencer ? "Yes," : "No"}
                    </div>
                    {details?.is_streamer_or_influencer ? (
                        <div>
                            Page Link:{" "}
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href={details?.page_link}
                                className="link-primary"
                            >
                                {details?.page_link}
                            </a>
                        </div>
                    ) : null}
                </div>
            ),
        },
    ];

    const otherDetails = [
        {
            label: "Status",
            value: (
                <div className="tw-mb-3">
                    <StatusBadge
                        type="affiliateRequest"
                        status={details.status}
                    />
                </div>
            ),
        },
        {
            label: "Created At",
            value: defaultDateTimeFormat(details?.created_at),
        },
        {
            label: "Approved Date",
            value: details?.approved_date
                ? defaultDateTimeFormat(details?.approved_date)
                : "-",
            isHidden:
                details?.status === "pending" || details?.status === "rejected",
        },
        {
            label: "Rejected Date",
            value: details?.rejected_date
                ? defaultDateTimeFormat(details?.rejected_date)
                : "-",
            isHidden:
                details?.status === "pending" || details?.status === "approved",
        },
        {
            label: "Rejected Reason",
            value: details?.reject_reason || "-",
            isHidden:
                details?.status === "pending" || details?.status === "approved",
        },
    ];

    return (
        <Modal
            id="affiliateRequestViewModal"
            isOpen={isOpen}
            toggle={() => setIsOpen(!isOpen)}
            size="lg"
        >
            <ModalHeader>
                <div className="modal-title">
                    Affiliate Request : #{details?.request_no}
                </div>
            </ModalHeader>
            <ModalBody>
                <Card>
                    <CardBody>
                        <Row>
                            <Col md={7}>
                                {personalDetails.map((item, index) => (
                                    <DisplayText
                                        key={index}
                                        label={item.label}
                                        item={item.value}
                                    />
                                ))}
                            </Col>
                            <Col md={5}>
                                {otherDetails
                                    .filter((itm) => !itm.isHidden)
                                    .map((item, index) => (
                                        <DisplayText
                                            key={index}
                                            label={item.label}
                                            item={item.value}
                                        />
                                    ))}
                                {details?.status === "pending" && (
                                    <div className="tw-pt-4">
                                        <Button
                                            color="danger"
                                            className="me-2"
                                            onClick={confirmRejectHandler}
                                            disabled={isLoading}
                                        >
                                            Reject
                                        </Button>
                                        <Button
                                            color="success"
                                            onClick={() => setIsOpenForm(true)}
                                            disabled={isLoading}
                                        >
                                            Approve
                                        </Button>
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <Button
                    color="light"
                    className="float-end"
                    onClick={() => setIsOpen(false)}
                >
                    Close
                </Button>
            </ModalBody>
            <AffiliateRequestApproveModal
                data={{
                    isOpen: isOpenForm,
                    setIsOpen: setIsOpenForm,
                    details,
                    setDetails,
                    initialize,
                }}
            />
        </Modal>
    );
};

export default AffiliateRequestViewModal;
