import { InputSwitch, Table, Title } from "@/components/atoms";
import BreadCrumb from "@/components/common/BreadCrumb";
import { confirmation } from "@/components/constants/common";
import { Routes, getPath, getPathTitle } from "@/constants";
import { getMessageTypes, updateMessageStatus } from "@/store/actions";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardHeader,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import { AddMessageForm } from "@/components/molecules";

const MessageCreateOrUpdate = () => {
  const { messageTypes, isLoading } = useSelector((state) => state.announcement);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMessageTypes());
}, [dispatch]);

  return (
    <React.Fragment>
      <Title title={getPathTitle(Routes.MESSAGE_CREATE_OR_UPDATE)} />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            parent={{
              isParent: true,
              pageTitle: getPathTitle(Routes.MESSAGE_LIST),
              pageLink: getPath(Routes.MESSAGE_LIST),
            }}
            childs={[
              {
                pageTitle: getPathTitle(Routes.MESSAGE_CREATE_OR_UPDATE),
              },
            ]}
          />
          <Row>
            <Col>
              <Card>
                <CardHeader className="align-items-center d-flex">
                  <h4 className="card-title mb-0 flex-grow-1">
                    {getPathTitle(Routes.MESSAGE_CREATE_OR_UPDATE)}
                  </h4>
                  <div className="flex-shrink-0">Please Fill up all fields</div>
                </CardHeader>
                <CardBody>
                  <AddMessageForm messageTypes={messageTypes} isLoading={isLoading}/>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default MessageCreateOrUpdate;
