import { StatusBadge, Table, AmountFormat } from "@/components/atoms";
import { getPath, Routes, roleTag } from "@/constants";
import { getAgentList, getPlayerList } from "@/store/actions";
import React, { useEffect, useRef, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import ProfileLayout from "../Layout";

const Downlines = () => {
    const isMounted = useRef(false);
    const dispatch = useDispatch();
    const { settingList } = useSelector((state) => state.user);
    const {
        list: agentList,
        isLoading: isLoadingAgent,
        profile,
    } = useSelector((state) => state.agent);
    const { list: playerList, isLoading: isLoadingPlayer } = useSelector(
        (state) => state.player
    );
    const [userType, setUserType] = useState("agent");

    const [search, setSearch] = useState("");
    const [sortBy, setSortBy] = useState({
        field: "",
        type: "asc",
    });

    const [agentData, setAgentData] = useState({
        user_type: "agent",
        per_page: 10,
        page: 1,
        search: "",
        sort_by:
            sortBy.field && sortBy.type ? `${sortBy.field} ${sortBy.type}` : "",
    });

    const [affiliateData, setAffiliateData] = useState({
        user_type: "agent",
        per_page: 10,
        page: 1,
        search: "",
        status: "",
        sort_by:
            sortBy.field && sortBy.type ? `${sortBy.field} ${sortBy.type}` : "",
        role_tag: roleTag.AFFILIATE,
    });

    const [playerData, setPlayerData] = useState({
        user_type: "player",
        per_page: 10,
        page: 1,
        search: "",
        sort_by:
            sortBy.field && sortBy.type ? `${sortBy.field} ${sortBy.type}` : "",
    });

    useEffect(() => {
        if (isMounted.current) {
            dispatch(
                userType === "agent"
                    ? getAgentList({
                          ...agentData,
                          key_token: profile.keyToken,
                      })
                    : userType === "affiliate"
                    ? getAgentList({
                          ...affiliateData,
                          key_token: profile.keyToken,
                      })
                    : getPlayerList({
                          ...playerData,
                          key_token: profile.keyToken,
                      })
            );
        } else isMounted.current = true;
    }, [dispatch, agentData, playerData, affiliateData, userType, profile]);

    const tableTheadData = useMemo(() => {
        let balances = [];
        const seperatedCommsSetting = settingList.filter(
            (item) => item.key === "separate_sports_betting_commission"
        );

        if (["agent", "affiliate"].includes(userType)) {
            balances = [
                {
                    label: `Pending Commission ${process.env.REACT_APP_BCF_LABEL}`,
                    field: "user_profile_balance_pending_commission_bcf.amount",
                    sortable: true,
                    render: (item) => (
                        <AmountFormat
                            amount={
                                item?.user_profile?.balance?.fiat?.php
                                    ?.pending_commission_bcf
                            }
                        />
                    ),
                },
                {
                    label: "Pending Commission PHP",
                    field: "user_profile_balance_pending_commission_merchant.amount",
                    sortable: true,
                    render: (item) => (
                        <AmountFormat
                            amount={
                                item?.user_profile?.balance?.fiat?.php
                                    ?.pending_commission_merchant
                            }
                        />
                    ),
                },
                {
                    label: `Pending Commission ${process.env.REACT_APP_BCF_LABEL} (Sports/E-Sports/Live Casino)`,
                    field: "user_profile_balance_pending_commission_bcf_sports.amount",
                    sortable: true,
                    render: (item) => (
                        <AmountFormat
                            amount={
                                item?.user_profile?.balance?.fiat?.php
                                    ?.pending_commission_bcf_sports
                            }
                        />
                    ),
                },
                {
                    label: "Pending Commission PHP (Sports/E-Sports/Live Casino)",
                    field: "user_profile_balance_pending_commission_merchant_sports.amount",
                    sortable: true,
                    render: (item) => (
                        <AmountFormat
                            amount={
                                item?.user_profile?.balance?.fiat?.php
                                    ?.pending_commission_merchant_sports
                            }
                        />
                    ),
                },
            ];

            // if ((seperatedCommsSetting[0]?.value ?? 0) === "1") {
            //     balances[0][
            //         "label"
            //     ] = `Pending Commission ${process.env.REACT_APP_BCF_LABEL} (E-Games/Slots)`;
            //     balances[1]["label"] = "Pending Commission PHP (E-Games/Slots)";

            //     balances = [
            //         ...balances,
            //         {
            //             label: `Pending Commission ${process.env.REACT_APP_BCF_LABEL} (Sports/E-Sports/Live Casino)`,
            //             field: "user_profile_balance_pending_commission_bcf_sports.amount",
            //             sortable: true,
            //             render: (item) => (
            //                 <AmountFormat
            //                     amount={
            //                         item?.user_profile?.balance?.fiat?.php
            //                             ?.pending_commission_bcf_sports
            //                     }
            //                 />
            //             ),
            //         },
            //         {
            //             label: "Pending Commission PHP (Sports/E-Sports/Live Casino)",
            //             field: "user_profile_balance_pending_commission_merchant_sports.amount",
            //             sortable: true,
            //             render: (item) => (
            //                 <AmountFormat
            //                     amount={
            //                         item?.user_profile?.balance?.fiat?.php
            //                             ?.pending_commission_merchant_sports
            //                     }
            //                 />
            //             ),
            //         },
            //     ];
            // }

            balances = [
                ...balances,
                {
                    label: `Commission ${process.env.REACT_APP_BCF_LABEL}`,
                    field: "user_profile_balance_commission_bcf.amount",
                    sortable: true,
                    render: (item) => (
                        <AmountFormat
                            amount={
                                item?.user_profile?.balance?.fiat?.php
                                    ?.commission_bcf
                            }
                        />
                    ),
                },
                {
                    label: "Commission PHP",
                    field: "user_profile_balance_commission_merchant.amount",
                    sortable: true,
                    render: (item) => (
                        <AmountFormat
                            amount={
                                item?.user_profile?.balance?.fiat?.php
                                    ?.commission_merchant
                            }
                        />
                    ),
                },
                {
                    label: `Commission ${process.env.REACT_APP_BCF_LABEL} (Sports/E-Sports/Live Casino)`,
                    field: "user_profile_balance_commission_bcf_sports.amount",
                    sortable: true,
                    render: (item) => (
                        <AmountFormat
                            amount={
                                item?.user_profile?.balance?.fiat?.php
                                    ?.commission_bcf_sports
                            }
                        />
                    ),
                },
                {
                    label: "Commission Php (Sports/E-Sports/Live Casino)",
                    field: "user_profile_balance_commission_merchant_sports.amount",
                    sortable: true,
                    render: (item) => (
                        <AmountFormat
                            amount={
                                item?.user_profile?.balance?.fiat?.php
                                    ?.commission_merchant_sports
                            }
                        />
                    ),
                },
            ];
        }

        balances = [
            ...balances,
            {
                label: process.env.REACT_APP_BCF_LABEL,
                field: "user_profile_balance_bcf.amount",
                sortable: true,
                render: (item) => (
                    <AmountFormat
                        amount={item?.user_profile?.balance?.fiat?.php?.bcf}
                    />
                ),
            },
            {
                label: "PHP",
                field: "user_profile_balance_merchant.amount",
                sortable: true,
                render: (item) => (
                    <AmountFormat
                        amount={
                            item?.user_profile?.balance?.fiat?.php?.merchant
                        }
                    />
                ),
            },
        ];
        return [
            {
                label: "Account No.",
                field: "account_no",
                sortable: true,
                render: (item) => item?.user_profile?.account_no,
            },
            {
                label: "First Name",
                field: "first_name",
                sortable: true,
                render: (item) =>
                    item?.user_profile?.first_name
                        ? item.user_profile.first_name
                        : "-",
            },
            {
                label: "Last Name",
                field: "last_name",
                sortable: true,
                render: (item) =>
                    item?.user_profile?.last_name
                        ? item.user_profile.last_name
                        : "-",
            },
            ...balances,
            {
                label: "Status",
                field: "status",
                sortable: true,
                render: (item) => <StatusBadge status={item.status} />,
            },
            {
                label: "Action",
                field: "action",
                sortable: false,
                render: (item) => (
                    <Link
                        to={getPath(
                            userType === "agent"
                                ? Routes.AGENT_PROFILE
                                : userType === "master_agent"
                                ? Routes.SUPER_AGENT_PROFILE
                                : userType === "affiliate"
                                ? Routes.AFFILIATE_PROFILE
                                : Routes.PLAYER_PROFILE
                        ).replace(":accountNo", item?.user_profile?.account_no)}
                        className="link-primary"
                    >
                        <Button color="success" size="sm">
                            <i className="ri-eye-line"></i>
                        </Button>
                    </Link>
                ),
            },
        ];
    }, [userType, settingList]);

    return (
        <ProfileLayout>
            <Row className="mb-3">
                <Col>
                    <Button
                        color={userType === "agent" ? "primary" : "light"}
                        onClick={() => setUserType("agent")}
                        className="me-2"
                    >
                        Agents
                    </Button>
                    <Button
                        color={userType === "affiliate" ? "primary" : "light"}
                        onClick={() => setUserType("affiliate")}
                        className="me-2"
                    >
                        Affiliates
                    </Button>
                    <Button
                        color={userType === "player" ? "primary" : "light"}
                        onClick={() => setUserType("player")}
                    >
                        Players
                    </Button>
                </Col>
            </Row>

            <Row>
                <Col xs={12}>
                    <Table
                        search={search}
                        handlerSearch={setSearch}
                        sortBy={sortBy}
                        handlerSortBy={setSortBy}
                        thead={tableTheadData}
                        handlerChangeData={
                            userType === "agent"
                                ? setAgentData
                                : userType === "affiliate"
                                ? setAffiliateData
                                : setPlayerData
                        }
                        data={
                            userType === "agent"
                                ? agentData
                                : userType === "affiliate"
                                ? affiliateData
                                : playerData
                        }
                        collection={
                            ["agent", "affiliate"].includes(userType)
                                ? agentList
                                : playerList
                        }
                        isLoading={
                            ["agent", "affiliate"].includes(userType)
                                ? isLoadingAgent
                                : isLoadingPlayer
                        }
                        size="sm"
                    />
                </Col>
            </Row>
        </ProfileLayout>
    );
};

export default Downlines;
