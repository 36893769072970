import { toFormData } from "../../utils/common";
import { axiosInstance } from "./index";

export const getCasinoGameProvider = async () => {
    return await axiosInstance.get(`/game-provider/get-all`);
};

export const getCasinoGameType = async () => {
    return await axiosInstance.get(`/game-type/get-all`);
};

export const syncCasinoGameProvider = async (slug) => {
    return await axiosInstance.post(`/bo/sync-game/${slug}`);
};

export const updateGameStatus = async (gameType, keyToken) => {
    return await axiosInstance.put(`/bo/${gameType}/update-status/${keyToken}`);
};

export const getCasinoGameList = async (data) => {
    return await axiosInstance.get(`/game-provider-game/get-paginated`, {
        params: data,
    });
};

export const getFeaturedGameTypes = async () => {
    return await axiosInstance.get(`/bo/featured-game-type/get-all`);
};

export const addFeaturedGame = async (params) => {
    const formData = toFormData(params);

    return await axiosInstance({
        method: "post",
        url: "/bo/featured-game/create",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
    // return await axiosInstance.post(`/bo/featured-game/create`, params);
};

export const updateFeaturedGame = async (params, uuid) => {
    const formData = toFormData(params);

    return await axiosInstance({
        method: "post",
        url: `/bo/featured-game/update/${uuid}`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
    // return await axiosInstance.post(`/bo/featured-game/create`, params);
};

export const deleteFeaturedGame = async (keyToken) => {
    return await axiosInstance.delete(
        `/bo/featured-game/force-delete/${keyToken}`
    );
};

export const updateFeaturedGameOrder = async (params, keyToken) => {
    return await axiosInstance.post(
        `/bo/featured-game/update/${keyToken}`,
        params
    );
};

export const getGameOfferings = async () => {
    return await axiosInstance.get(`/game-offering/get-all`);
};

export const updateGameProviderOrder = async (params) => {
    return await axiosInstance.put("/bo/game-provider/update-order", params);
};
