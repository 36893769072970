import { axiosInstance } from "./index";

export const getDrawScheduleList = async (data) => {
    return await axiosInstance.get(`/bo/lotto/draw-schedule/get-paginated`, {
        params: data,
    });
};

export const updateDrawScheduleGame = async (params, keyToken) => {
    return await axiosInstance.put(`/bo/lotto/draw-schedule/update-games/${keyToken}`, {
        games: params.games
    });
};

