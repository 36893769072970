import { filterDateFormat, filterDateTimeFormat } from "@/utils/common";
import React, { forwardRef } from "react";
import Flatpickr from "react-flatpickr";
import { Label } from "reactstrap";

const FlatPickerDate = forwardRef(
    (
        {
            label,
            name,
            form,
            mode = "default",
            defaultDate,
            minDate = null,
            maxDate = "today",
            placeholder = "Select...",
            enableTime = false,
            altFormat = "M j, Y",
            ...rest
        },
        ref
    ) => {
        const changeFilterDate = (values) => {
            if (mode === "range") {
                let collection = values.map((value) =>
                    enableTime
                        ? filterDateTimeFormat(value)
                        : filterDateFormat(value)
                );
                form.setFieldValue(name, collection);
            } else {
                form.setFieldValue(
                    name,
                    enableTime
                        ? filterDateTimeFormat(values)
                        : filterDateFormat(values)
                );
            }
        };
        let options = {
            altInput: true,
            altFormat: altFormat,
            minDate: minDate,
            maxDate: maxDate,
            defaultDate: defaultDate ? defaultDate : null,
            enableTime: enableTime,
            enableSeconds: enableTime,
        };
        options =
            mode !== "default"
                ? {
                      ...options,
                      mode: mode,
                  }
                : options;

        return (
            <>
                {label && <Label>{label}</Label>}
                <Flatpickr
                    ref={ref}
                    className={`form-control dark:tw-bg-dark-input bg-transparent border border-gray rounded ${
                        form?.touched[name] &&
                        form?.errors[name] &&
                        "border-danger"
                    }`}
                    name={name}
                    options={options}
                    onChange={(e) => {
                        changeFilterDate(e);
                    }}
                    placeholder={mode === "range" ? "From - To" : placeholder}
                    {...rest}
                />
                {form?.touched[name] && form?.errors[name] && (
                    <small className="text-danger text-sm">
                        {form.errors[name]}
                    </small>
                )}
            </>
        );
    }
);

FlatPickerDate.displayName = "FlatPickerDate";

export default FlatPickerDate;
