import {
  Button,
  ErrorAlert,
  InputText,
  TextArea,
  InputSwitch,
} from "@/components/atoms";
import { promotionUpdateCommonValidation } from "@/constants";
import { updatePromotion } from "@/store/actions";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Col, Form, FormGroup, Label, Row } from "reactstrap";

const UpdatePromotionCommonForm = ({
  selectedPromotion,
  isSubmit,
  initialize,
  errors,
}) => {
  const dispatch = useDispatch();
  const [isPreview, setIsPreview] = useState(false);
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: selectedPromotion?.name,
      description: selectedPromotion?.description,
    },
    validationSchema: promotionUpdateCommonValidation,
    onSubmit: (values) => {
      // console.log(values)
      dispatch(
        updatePromotion(
          selectedPromotion?.key_token,
          values,
          "update-information",
          initialize
        )
      );
    },
  });
  return (
    <div>
      {errors && <ErrorAlert errors={errors} validation={validation} />}

      <Form
        className="needs-validation"
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        <Row>
          <Col>
            <FormGroup className="mb-3">
              <InputText
                label="Name"
                name="name"
                placeholder="Name"
                validation={validation}
              />
            </FormGroup>
          </Col>
          <Col md="12 mb-2">
            <div className="tw-flex tw-flex-row tw-justify-between tw-items-center">
              <Label className="m-0">Description</Label>
             <div>
                <InputSwitch
                  label="Preview Description"
                  id={"preview"}
                  isChecked={isPreview}
                  color="success"
                  onChange={() => setIsPreview(!isPreview)}
                />
              </div>
            </div>
          </Col>
          <Col md="12">
            {isPreview ? (
              <div
                className="tw-p-5 tw-bg-white"
                dangerouslySetInnerHTML={{
                  __html: validation?.values?.description,
                }}
              />
            ) : (
              <TextArea
                rows="10"
                cols="50"
                name="description"
                validation={validation}
              />
            )}
          </Col>
        </Row>
        <div className="border-top py-3 mt-3">
          <Button
            color="success"
            type="submit"
            className="float-end"
            text="Submit"
            isLoading={isSubmit}
          />
        </div>
      </Form>
    </div>
  );
};

export default UpdatePromotionCommonForm;
