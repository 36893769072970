export const GET_REQUEST_DATA = "GET_REQUEST_DATA";
export const SET_REQUEST_DATA = "SET_REQUEST_DATA";
export const SET_REQUEST_DATA_ERROR = "SET_REQUEST_DATA_ERROR";

export const GET_REQUEST_INFO = "GET_REQUEST_INFO";
export const SET_REQUEST_INFO = "SET_REQUEST_INFO";
export const SET_REQUEST_INFO_ERROR = "SET_REQUEST_INFO_ERROR";

export const UPDATE_REQUEST_STATUS = "UPDATE_REQUEST_STATUS";
export const UPDATE_REQUEST_STATUS_SUCCESS = "UPDATE_REQUEST_STATUS_SUCCESS";
export const UPDATE_REQUEST_STATUS_ERROR = "UPDATE_REQUEST_STATUS_ERROR";

export const RESET_REQUEST_ERRORS = "RESET_REQUEST_ERRORS";

export const BULK_RELEASE = "BULK_RELEASE";
export const BULK_RELEASE_SUCCESS = "BULK_RELEASE_SUCCESS";
export const BULK_RELEASE_FAILED = "BULK_RELEASE_FAILED";

export const BULK_PAYOUT = "BULK_PAYOUT";
export const BULK_PAYOUT_SUCCESS = "BULK_PAYOUT_SUCCESS";
export const BULK_PAYOUT_FAILED = "BULK_PAYOUT_FAILED";

export const GET_BULK_PAYOUT_PROGRESS = "GET_BULK_PAYOUT_PROGRESS";
export const GET_BULK_PAYOUT_PROGRESS_SUCCESS =
    "GET_BULK_PAYOUT_PROGRESS_SUCCESS";
export const GET_BULK_PAYOUT_PROGRESS_FAILED =
    "GET_BULK_PAYOUT_PROGRESS_FAILED";
    
export const IMPORT_PAYOUT = "IMPORT_PAYOUT";
export const IMPORT_PAYOUT_SUCCESS = "IMPORT_PAYOUT_SUCCESS";
export const IMPORT_PAYOUT_FAILED = "IMPORT_PAYOUT_FAILED";

export const GET_PUBLIC_PAYMENT_METHODS = "GET_PUBLIC_PAYMENT_METHODS";
export const SET_PUBLIC_PAYMENT_METHODS = "SET_PUBLIC_PAYMENT_METHODS";