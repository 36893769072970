import { commissionRatePerGameOffering } from "@/constants";
export const userState = {
    user: {
        keyToken: "",
        name: "",
        email: "",
        firstName: "",
        lastName: "",
        middleName: "",
        extensionName: "",
        birthDate: "",
        balance: "",
        accountNo: "",
        gender: "",
        occupation: "",
        mobileNo: "",
        userProfilekeyToken: "",
        region: {
            name: "",
            keyToken: "",
        },
        province: {
            name: "",
            keyToken: "",
        },
        city: {
            name: "",
            keyToken: "",
        },
        barangay: {
            name: "",
            keyToken: "",
        },
        buildingNo: "",
        street: "",
        postalCode: "",
        verifiedAt: "",
        role: {},
        lastLogin: {},
    },
    isAuth: false,
    errors: [],
    updateReferralErrors: [],
    isLoading: false,
    userCount: {},
    permissionList: [],
    activityLogList: [],
    commissionList: [],
    userCommissionList: [],
    deviceDetails: null,
    operatorList: [],
    isGetOperatorLoading: false,
    getOperatorErrors: [],
};

export const announcementState = {
    errors: {},
    isLoading: false,
    isSubmitting: false,
    messageList: [],
    messageTypes: [],
    notificationTypeList: [],
    userNotificationList: [],
};

export const lottoSettingState = {
    errors: {},
    isLoading: false,
    isSubmitting: false,
    drawScheduleList: [],
    isUpdating: false
}

export const addressState = {
    regions: [],
    provinces: [],
    cities: [],
    barangays: [],
    countries: [],
    errors: {},
    isLoading: false,
};

export const operatorState = {
    list: [],
    profile: userState.user,
    errors: [],
    isLoading: false,
    message: "",
    userCount: {},
    operatorSelectionList: [],
    isGetOperatorSelectionListLoading: false,
    isUpdateMerchantSettingSuccess: false,
    isUpdateMerchantSettingLoading: false,
    isUpdateCrossBettingProtectionLoading: false,
    isUpdateCrossBettingProtectionSuccess: false,
};

export const agentState = {
    list: [],
    profile: userState.user,
    agentCommissionList: [],
    commissionRateErrors: [],
    errors: [],
    isLoading: false,
    message: "",
    userCount: {},
};

export const adminState = {
    list: [],
    profile: userState.user,
    errors: [],
    isLoading: false,
    message: "",
    permissionList: [],
    roleList: [],
    roleSelected: {},
    userCount: {},
    directWithdrawals: [],
};

export const playerState = {
    list: [],
    profile: userState.user,
    errors: [],
    isLoading: false,
    message: "",
    userCount: {},
    idList: [],
    userProfileDocuments: [],
    isUpdateIdVerificationStatusSuccess: false,
    isUpdateFullyVerificationSuccess: false,
    playerSelectionList: [],
    isGetPlayerSelectionListLoading: false,
};

export const transactionState = {
    list: [],
    selected: {},
    isLoading: false,
    paymentMethod: [],
    errors: [],
    message: "",
    isUserDepositSuccess: false,
    isProcessComissionLoading: false,
};

export const requestState = {
    list: [],
    selected: {},
    isLoading: false,
    errors: [],
    message: "",
    isBulkPayoutLoading: false,
    isBulkPayoutSuccess: false,
    payoutProgress: null,
    importPayoutResult: [],
    isImportPayoutLoading: false,
    isImportPayoutSuccess: false,
    paymentMethods: [],
};

export const settingState = {
    list: [],
    isLoading: false,
    errors: [],
    message: "",
};

export const gameState = {
    gameProviderList: [],
    gameTypeList: [],
    gameList: [],
    featuredGameTypes: [],
    errors: [],
    isGameProviderListLoaded: false,
    isGameTypeListLoaded: false,
    isLoading: false,
    isAddFeaturedGameLoading: false,
    isAddFeaturedGameSuccess: false,
    isDeleteFeaturedGameLoading: false,
    isDeleteFeaturedGameSuccess: false,
    isUpdateFeaturedGameOrderLoading: false,
    isUpdateFeaturedGameOrderSuccess: false,
    gameOfferings: [],
    isGetGameOfferingsLoading: false,
    isSubmit: false,
};

export const reportState = {
    winLossReport: [],
    isGetWinLossReportLoading: false,
    winLossByProductReport: [],
    isGetWinLossByProductReportLoading: false,
    loginLogs: [],
    isGetloginLogsLoading: false,
    multipleIPDetectedData: [],
    isGetmultipleIPDetectedDataLoading: false,
    errors: [],
};

export const dashboardState = {
    dashboardMainTotal: [],
    isGetDashboardMainTotalLoading: false,
    dashboardOverallTotal: [],
    isGetDashboardOverallTotalLoading: false,
    dailyTotalGgrSummary: [],
    isGetDailyTotalGgrSummaryLoading: false,
    isGetDailyTotalDepositAndWithdrawalSummaryLoading: false,
    dailyTotalDepositAndWithdrawalSummary: [],
    isGetMonthlyTotalPlayerSummaryLoading: false,
    monthlyTotalPlayerSummary: [],
};

export const countryState = {
    isGetCountryLoading: false,
    isUpdateCountryStatusSuccess: false,
    countries: [],
};

export const paymentSettingState = {
    isLoading: false,
    isSubmit: false,
    paymentMethods: [],
    paymentProviders: [],
    allowedInWithdrawalPaymentProviders: [],
    paymentMethodMonetixList: [],
    paymentMethodSwiftPayList: [],
    paymentMethodH2PayList: [],
    paymentMethodAkPayList: [],
    paymentMethodCoinsPaidList: [],
    errors: [],
};

export const promotionState = {
    isLoading: false,
    isSubmit: false,
    promotionCategorylist: [],
    promotionlist: [],
    selectedPromotionCategory: null,
    selectedPromotion: null,
    errors: [],
    promotionTransactionlist: [],
    promotionTransactionViewData: null,
};

export const hotMatchesSettingState = {
    isLoading: false,
    isManualPullLoading: false,
    isSubmit: false,
    isUpdateFeaturedGameSuccess: false,
    isLoadingUploadTeamPhoto: false,
    sportTypeList: [],
    leagueTypeList: [],
    gameTypeList: [],
    teamList: [],
    hotMatchList: [],
    errors: [],
};

export const setUserData = (action) => {
    let commissionData = {};
    commissionRatePerGameOffering.forEach((item) => {
        commissionData = {
            ...commissionData,
            [item.value]: action?.payload?.user_profile[item.value] ?? null,
        };
    });

    return {
        gpUserId: action?.payload?.gp_user_id,
        keyToken: action.payload.key_token,
        name: action.payload.name,
        email: action.payload.email,
        firstName: action.payload.user_profile?.first_name,
        lastName: action.payload.user_profile?.last_name,
        middleName: action.payload.user_profile?.middle_name,
        extensionName: action.payload.user_profile?.extension_name,
        birthDate: action.payload.user_profile?.birth_date,
        gender: action.payload.user_profile?.gender,
        occupation: action.payload.user_profile?.occupation,
        balance: action.payload.user_profile?.balance,
        total_data: action.payload.user_profile?.total_data,
        accountNo: action.payload.user_profile?.account_no,
        mobileNo: action.payload.mobile_no,
        userProfilekeyToken: action.payload.user_profile?.key_token,
        status: action.payload.status,
        referralCode: action.payload.user_profile?.referral_code,
        verificationStatus: action.payload.verification_status,
        verificationStep: action?.payload?.user_profile?.verification_step,
        rejectReason: action.payload.reject_reason,
        country: {
            name: action.payload.user_profile?.country?.name,
            keyToken: action.payload.user_profile?.country.key_token,
        },
        region: {
            name: action.payload.user_profile?.barangay?.city?.province?.region
                ?.name,
            keyToken:
                action.payload.user_profile?.barangay?.city?.province?.region
                    ?.key_token,
        },
        province: {
            name: action.payload.user_profile?.barangay?.city?.province?.name,
            keyToken:
                action.payload.user_profile?.barangay?.city?.province
                    ?.key_token,
        },
        city: {
            name: action.payload.user_profile?.barangay?.city?.name,
            keyToken: action.payload.user_profile?.barangay?.city?.key_token,
        },
        barangay: {
            name: action.payload.user_profile?.barangay?.name,
            keyToken: action.payload.user_profile?.barangay?.key_token,
        },
        crossBettingProtection:
            action.payload.user_profile?.cross_betting_protection,
        crossBettingProtectionEditable:
            action.payload.user_profile?.cross_betting_protection_editable,
        allowedInMerchant: action.payload.user_profile?.allowed_in_merchant,
        validTurnoverBCfEnabled:
            action.payload.user_profile?.vto_commission_bcf_enabled,
        validTurnoverMerchantEnabled:
            action.payload.user_profile?.vto_commission_merchant_enabled,
        separateSportsBettingCommissionBcfEditable:
            action.payload.user_profile
                ?.separate_sports_betting_commission_bcf_editable,
        separateSportsBettingCommissionBcf:
            action.payload.user_profile?.separate_sports_betting_commission_bcf,
        separateSportsBettingCommissionMerchantEditable:
            action.payload.user_profile
                ?.separate_sports_betting_commission_merchant_editable,
        separateSportsBettingCommissionMerchant:
            action.payload.user_profile
                ?.separate_sports_betting_commission_merchant,

        commissionTransferScheduleBcf:
            action.payload.user_profile?.commission_transfer_schedule_bcf,
        commissionTransferScheduleBcfLotto:
            action.payload.user_profile?.commission_transfer_schedule_bcf_lotto,
        commissionTransferScheduleMerchant:
            action.payload.user_profile?.commission_transfer_schedule_merchant,
        commissionTransferScheduleMerchantLotto:
            action.payload.user_profile?.commission_transfer_schedule_merchant_lotto,
        commissionTransferScheduleBcfSports:
            action.payload.user_profile
                ?.commission_transfer_schedule_bcf_sports,
        commissionTransferScheduleMerchantSports:
            action.payload.user_profile
                ?.commission_transfer_schedule_merchant_sports,

        commissionTransferScheduleBcfEditable:
            action.payload.user_profile
                ?.commission_transfer_schedule_bcf_editable,
        commissionTransferScheduleMerchantEditable:
            action.payload.user_profile
                ?.commission_transfer_schedule_merchant_editable,
        commissionTransferScheduleBcfSportsEditable:
            action.payload.user_profile
                ?.commission_transfer_schedule_bcf_sports_editable,
        commissionTransferScheduleBcfLottoEditable:
            action.payload.user_profile
                ?.commission_transfer_schedule_bcf_lotto_editable,
        commissionTransferScheduleMerchantSportsEditable:
            action.payload.user_profile
                ?.commission_transfer_schedule_merchant_sports_editable,
        commissionTransferScheduleMerchantLottoEditable:
            action.payload.user_profile
                ?.commission_transfer_schedule_merchant_lotto_editable,
        applyToDownlinesCommissionTransferScheduleBcf:
            action.payload.user_profile
                ?.apply_to_downlines_commission_transfer_schedule_bcf,
        applyToDownlinesCommissionTransferScheduleBcfLotto:
            action.payload.user_profile
                ?.apply_to_downlines_commission_transfer_schedule_bcf_lotto,
        applyToDownlinesCommissionTransferScheduleMerchant:
            action.payload.user_profile
                ?.apply_to_downlines_commission_transfer_schedule_merchant,
        applyToDownlinesCommissionTransferScheduleMerchantLotto:
                action.payload.user_profile
                    ?.apply_to_downlines_commission_transfer_schedule_merchant_lotto,
        applyToDownlinesCommissionTransferScheduleBcfSports:
            action.payload.user_profile
                ?.apply_to_downlines_commission_transfer_schedule_bcf_sports,
        applyToDownlinesCommissionTransferScheduleMerchantSports:
            action.payload.user_profile
                ?.apply_to_downlines_commission_transfer_schedule_merchant_sports,

        applyToDownlinesCommissionTransferScheduleBcfEditable:
            action.payload.user_profile
                ?.apply_to_downlines_commission_transfer_schedule_bcf_editable,
        applyToDownlinesCommissionTransferScheduleBcfLottoEditable:
            action.payload.user_profile
                ?.apply_to_downlines_commission_transfer_schedule_bcf_lotto_editable,
        applyToDownlinesCommissionTransferScheduleMerchantEditable:
            action.payload.user_profile
                ?.apply_to_downlines_commission_transfer_schedule_merchant_editable,
        applyToDownlinesCommissionTransferScheduleMerchantLottoEditable:
            action.payload.user_profile
                ?.apply_to_downlines_commission_transfer_schedule_merchant_lotto_editable,
        applyToDownlinesCommissionTransferScheduleBcfSportsEditable:
            action.payload.user_profile
                ?.apply_to_downlines_commission_transfer_schedule_bcf_sports_editable,
        applyToDownlinesCommissionTransferScheduleMerchantSportsEditable:
            action.payload.user_profile
                ?.apply_to_downlines_commission_transfer_schedule_merchant_sports_editable,

        allowNegativeCarryOverCommissionBcf:
            action.payload.user_profile
                ?.allow_negative_carry_over_commission_bcf,
        allowNegativeCarryOverCommissionMerchant:
            action.payload.user_profile
                ?.allow_negative_carry_over_commission_merchant,
        allowNegativeCarryOverCommissionBcfSports:
            action.payload.user_profile
                ?.allow_negative_carry_over_commission_bcf_sports,
        allowNegativeCarryOverCommissionBcfLotto:
            action.payload.user_profile
                ?.allow_negative_carry_over_commission_bcf_lotto,
        allowNegativeCarryOverCommissionMerchantSports:
            action.payload.user_profile
                ?.allow_negative_carry_over_commission_merchant_sports,
        allowNegativeCarryOverCommissionMerchantLotto:
            action.payload.user_profile
                ?.allow_negative_carry_over_commission_merchant_lotto,

        pendingCommissionRequiredToResetNegativeCommissionBcf:
            action.payload.user_profile
                ?.pending_commission_required_to_reset_negative_commission_bcf,
        pendingCommissionRequiredToResetNegativeCommissionMerchant:
            action.payload.user_profile
                ?.pending_commission_required_to_reset_negative_commission_merchant,
        pendingCommissionRequiredToResetNegativeCommissionBcfSports:
            action.payload.user_profile
                ?.pending_commission_required_to_reset_negative_commission_bcf_sports,
        pendingCommissionRequiredToResetNegativeCommissionMerchantSports:
            action.payload.user_profile
                ?.pending_commission_required_to_reset_negative_commission_merchant_sports,

        noOfCutoffToResetNegativeCommissionBcf:
            action.payload.user_profile
                ?.no_of_cutoff_to_reset_negative_commission_bcf,
        noOfCutoffToResetNegativeCommissionMerchant:
            action.payload.user_profile
                ?.no_of_cutoff_to_reset_negative_commission_merchant,
        noOfCutoffToResetNegativeCommissionBcfSports:
            action.payload.user_profile
                ?.no_of_cutoff_to_reset_negative_commission_bcf_sports,
        noOfCutoffToResetNegativeCommissionMerchantSports:
            action.payload.user_profile
                ?.no_of_cutoff_to_reset_negative_commission_merchant_sports,

        withdrawalFeeMerchantDisabled:
            action.payload.user_profile?.withdrawal_fee_merchant_disabled,
        withdrawalFeeMerchantDisabledEditable:
            action.payload.user_profile
                ?.withdrawal_fee_merchant_disabled_editable,

        buildingNo: action.payload.user_profile?.building_no,
        street: action.payload.user_profile?.street,
        postalCode: action.payload.user_profile?.postal_code,
        createdAt: action.payload.created_at,
        verifiedAt: action.payload.verified_at,
        bannedAt: action.payload.banned_at,
        deactivatedAt: action.payload.deactivated_at,
        role: action.payload.role,
        roleTag: action.payload.role_tag,
        commissionBalance: action.payload.user_profile?.commission_balance,
        commission_rate_bcf: action.payload.user_profile?.commission_rate_bcf,
        commission_rate_merchant:
            action.payload.user_profile?.commission_rate_merchant,
        ...commissionData,
        pendingCommission: action.payload.user_profile?.pending_commission,
        lastLogin: action.payload.last_login,
        settings: action.payload.settings,
        permissions: action.payload.permissions,
        userAdminRoleKey: action.payload.user_admin_role_key,
        referrer: action.payload.referrer,
    };
};
