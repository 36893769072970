import Swal from "sweetalert2";

export const confirmation = ({
    title,
    text,
    icon,
    showCancelButton = true,
    reverseButtons = true,
    confirmButtonText = "Confirm",
    cancelButtonText = "Cancel",
    action,
}) => {
    Swal.fire({
        title: title,
        text: text,
        icon: icon,
        showCancelButton: showCancelButton,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        reverseButtons: reverseButtons,
        confirmButtonText: confirmButtonText,
        cancelButtonText: cancelButtonText,
    }).then((result) => {
        if (result.isConfirmed) {
            action();
        }
    });
};

export const showLoading = (text = "Saving...", titleText = "Please wait") => {
    Swal.fire({
        title: titleText,
        html: `${text}<br /><span class='mdi mdi-spin mdi-loading'></span>`,
        allowOutsideClick: false,
        showCancelButton: false,
        showConfirmButton: false,
    });
};

export const hideLoading = () => {
    Swal.close();
};
