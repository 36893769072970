import { DeleteModal, StatusBadge, Table, Title } from "@/components/atoms";
import BreadCrumb from "@/components/common/BreadCrumb";
import { Routes, getPath, getPathTitle } from "@/constants";
import { getPlayerList } from "@/store/actions";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row,
} from "reactstrap";
const PlayerList = () => {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.user);
    const { list, isLoading } = useSelector((state) => state.player);

    const [showModal, setShowModal] = useState(false);
    const [search, setSearch] = useState("");
    const [sortBy, setSortBy] = useState({
        field: "",
        type: "asc",
    });

    const userType = "player";

    const [data, setData] = useState({
        key_token: user.keyToken,
        user_type: userType,
        per_page: 10,
        page: 1,
        search: "",
        verification_status: "for_approval",
        sort_by:
            sortBy.field && sortBy.type ? `${sortBy.field} ${sortBy.type}` : "",
    });

    const tableTheadData = [
        {
            label: "Account No.",
            field: "account_no",
            sortable: true,
            render: (item) => item?.user_profile?.account_no,
        },
        {
            label: "Username",
            field: "name",
            sortable: true,
            render: (item) => item?.name,
        },
        {
            label: "Email",
            field: "email",
            sortable: true,
        },
        {
            label: "Contact No",
            field: "mobile_no",
            sortable: true,
            render: (item) => item?.mobile_no || "-",
        },
        {
            label: "Status",
            field: "status",
            sortable: false,
            render: (item) => <StatusBadge status={item.status} />,
        },
        {
            label: "Verification Status",
            field: "verification_status",
            sortable: false,
            render: (item) => (
                <StatusBadge
                    type="verification"
                    status={item.verification_status}
                />
            ),
        },
        {
            label: "Action",
            field: "action",
            sortable: false,
            render: (item) => (
                <Link
                    to={getPath(Routes.PLAYER_PROFILE).replace(
                        ":accountNo",
                        item?.user_profile?.account_no
                    )}
                    className="link-primary">
                    <Button color="success" size="sm">
                        <i className="ri-eye-line"></i>
                    </Button>
                </Link>
            ),
        },
    ];

    useEffect(() => {
        dispatch(getPlayerList(data));
    }, [dispatch, data, userType]);

    return (
        <React.Fragment>
            <Title title={getPathTitle(Routes.KYC)} />
            <DeleteModal
                show={showModal}
                onDeleteClick={() => setShowModal(false)}
                onCloseClick={() => setShowModal(false)}
            />
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb
                        parent={{
                            isParent: true,
                            pageTitle: getPathTitle(Routes.KYC),
                            pageLink: getPath(Routes.KYC),
                        }}
                    />
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardHeader className="align-items-center d-flex">
                                    <h4 className="card-title mb-0 flex-grow-1">
                                        {getPathTitle(Routes.KYC)} List
                                    </h4>
                                </CardHeader>
                                <CardBody>
                                    <Row className="mb-3">
                                        <Col>
                                            <Button
                                                color={
                                                    data.verification_status ===
                                                    "for_approval"
                                                        ? "primary"
                                                        : "light"
                                                }
                                                onClick={() =>
                                                    setData({
                                                        ...data,
                                                        verification_status:
                                                            "for_approval",
                                                        page: 1,
                                                    })
                                                }
                                                className="me-2">
                                                For Semi Verification
                                            </Button>
                                            <Button
                                                color={
                                                    data.verification_status ===
                                                    "for_fully_approval"
                                                        ? "primary"
                                                        : "light"
                                                }
                                                onClick={() =>
                                                    setData({
                                                        ...data,
                                                        verification_status:
                                                            "for_fully_approval",
                                                        page: 1,
                                                    })
                                                }>
                                                For Fully Verification
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Table
                                        search={search}
                                        handlerSearch={setSearch}
                                        sortBy={sortBy}
                                        handlerSortBy={setSortBy}
                                        thead={tableTheadData}
                                        handlerChangeData={setData}
                                        data={data}
                                        collection={list}
                                        isLoading={isLoading}
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default PlayerList;
