import { AmountFormat, Table } from "@/components/atoms";
import { AddFundModal, DeductFundModal } from "@/components/molecules/";
import { userRole } from "@/hooks";
import { getTransactionData } from "@/store/actions";
import { defaultCurrency, numberFormat } from "@/utils";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Row } from "reactstrap";
import ProfileLayout from "../Layout";

import { useParams } from "react-router-dom";
const Wallet = () => {
    const dispatch = useDispatch();
    const { profile } = useSelector((state) => state.operator);
    const { list, isLoading } = useSelector((state) => state.transaction);
    const { accountNo } = useParams();
    const [search, setSearch] = useState("");
    const [sortBy, setSortBy] = useState({
        field: "",
        type: "asc",
    });
    const [data, setData] = useState({
        account_no: accountNo,
        per_page: 10,
        page: 1,
        search: "",
        sort_by:
            sortBy.field && sortBy.type ? `${sortBy.field} ${sortBy.type}` : "",
    });

    const [fundModalIsOpen, setFundModalIsOpen] = useState(false);
    const [deductFundModalIsOpen, setDeductFundModalIsOpen] = useState(false);

    const { isSuperAdmin, isLicenseeAdmin } = userRole();

    const canAction = isSuperAdmin;

    const tableTheadData = [
        {
            label: "Transaction No.",
            field: "transaction_no",
            sortable: true,
        },
        {
            label: "Transaction Type",
            field: "type",
            sortable: true,
        },
        {
            label: "Credit/Debit",
            field: "credit",
            sortable: false,
            render: (item) => <AmountFormat amount={item.credit} />,
        },
        {
            label: "Amount",
            field: "amount",
            sortable: false,
            render: (item) => <AmountFormat amount={item.amount} />,
        },
    ];

    const initialize = useCallback(() => {
        dispatch(getTransactionData(data, "bcf"));
    }, [dispatch, data]);

    useEffect(() => {
        initialize();
    }, [initialize]);

    return (
        <ProfileLayout>
            {canAction && fundModalIsOpen && (
                <AddFundModal
                    isOpen={fundModalIsOpen}
                    setIsOpen={setFundModalIsOpen}
                    userProfilekeyToken={profile.userProfilekeyToken}
                    initialize={initialize}
                />
            )}
            {canAction && deductFundModalIsOpen && (
                <DeductFundModal
                    isOpen={deductFundModalIsOpen}
                    setIsOpen={setDeductFundModalIsOpen}
                    userProfilekeyToken={profile.userProfilekeyToken}
                    initialize={initialize}
                />
            )}
            <Row>
                <Col>
                    <div className="card-body bg-light rounded">
                        <div className="d-flex justify-content-center align-items-center flex-wrap">
                            <h1 className="display-2 text-success">
                                {defaultCurrency()}
                            </h1>
                            <div className="flex-grow-1 ms-3">
                                <h1>
                                    {numberFormat(
                                        profile?.balance?.fiat?.php?.bcf ?? 0
                                    )}
                                </h1>
                                <p className="text-uppercase fw-semibold fs-12 text-muted mb-1">
                                    Betting Credit Fund Balance
                                </p>
                            </div>
                            {canAction && (
                                <div className="tw-flex tw-gap-2">
                                    <Button
                                        color="danger"
                                        onClick={() =>
                                            setDeductFundModalIsOpen(true)
                                        }
                                    >
                                        BCF debit
                                    </Button>
                                    <Button
                                        color="success"
                                        onClick={() => setFundModalIsOpen(true)}
                                    >
                                        <span className="ri-add-fill me-1 align-bottom"></span>
                                        Add Betting Credit Fund
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                </Col>
            </Row>
            <hr />
            <Row>
                <Col>
                    <h5>Transactions</h5>
                    <Table
                        search={search}
                        handlerSearch={setSearch}
                        sortBy={sortBy}
                        handlerSortBy={setSortBy}
                        thead={tableTheadData}
                        handlerChangeData={setData}
                        data={data}
                        collection={list}
                        isLoading={isLoading}
                        size="sm"
                    />
                </Col>
            </Row>
        </ProfileLayout>
    );
};

export default Wallet;
