import React, { useState } from "react";
import { InputTextGroup, EditActionButton } from "@/components/atoms";
import { useFormik } from "formik";

const EditInput = ({
  title,
  showEdit,
  type,
  defaultValue,
  onSave,
  validationSchema = null,
  inputType = "number",
  resetOnSubmit = false
}) => {
  const [isEdit, setIsEdit] = useState(false);

  const onClickEdit = () => {
    setIsEdit(!isEdit);
  };

  const onClickCancel = () => {
    setIsEdit(!isEdit);
    validation.resetForm();
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      value: defaultValue,
    },
    validationSchema,
    onSubmit: (values) => {
      onSave(values.value, type);
      setIsEdit(false);
      if(resetOnSubmit) {
        validation.resetForm();
      }
    },
  });

  return (
    <div className="card-header p-0 mb-3 pb-2">
      <p className="text-uppercase fw-semibold fs-14 text-muted mb-1 tw-w-4/6 tw-overflow-hidden tw-truncate">
        {title}
      </p>

      <div>
        <div className="tw-flex tw-items-center tw-gap-2">
          {isEdit ? (
            <InputTextGroup
              type={inputType}
              name={"value"}
              onChange={(e) => validation.setFieldValue("value", e.target.value)}
              validation={validation}
            />
          ) : (
            <div className="text-muted mb-1 tw-w-full">
              {validation?.values?.value ?? 0.0}
            </div>
          )}

          {showEdit && (
            <EditActionButton
              disabledSave={!validation.isValid}
              editIconButton="ri-pencil-line"
              isEdit={isEdit}
              onClickSave={validation.handleSubmit}
              onClickEdit={onClickEdit}
              onClickCancel={onClickCancel}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default EditInput;
